import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FournisseurPages } from 'src/app/models/fournisseur-pages';

@Injectable({
  providedIn: 'root'
})
export class FournisseurPagesService {
  fournisseur: FournisseurPages;
  private readonly url = "api/fournisseurs.json";

  constructor(
    private http: HttpClient
  ) { }

  setFournisseur(fournisseur: FournisseurPages) {
    fournisseur  }

  getFournisseur(): FournisseurPages {
    return this.fournisseur
  }

  getList(): Observable<FournisseurPages[]> {
    return this.http.get<FournisseurPages[]>(this.url).pipe();
  }
}
