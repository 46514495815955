import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-visite-list',
  templateUrl: './visite-list.component.html',
  styleUrls: ['./visite-list.component.scss']
})
export class VisiteListComponent implements OnInit {
  @Input() cardHeaderTitle = false;
  rotate = true;
  // Tout les variables ou tableau ayants pour prefix "default" servent a faire des duplication dans le template.
  defaultRow1 = [
    {
      id: 1,
      motif: "Visite de chantier 1",
      date: "01/11/2021",
      intervenant: 12,
      travaux: 56,
      prochaine: "11/11/2021 10:30",
      dateCreation: "11/11/2021 10:30"
    },
    {
      id: 2,
      motif: "Visite de chantier 2",
      date: "01/11/2021",
      intervenant: 43,
      travaux: 56,
      prochaine: "11/11/2021 10:30",
      dateCreation: "11/11/2021 10:30"
    },
    {
      id: 3,
      motif: "Visite de chantier 3",
      date: "01/11/2021",
      intervenant: 3,
      travaux: 3,
      prochaine: "11/11/2021 10:30",
      dateCreation: "11/11/2021 10:30"
    },
    {
      id: 4,
      motif: "Visite de chantier 4",
      date: "01/11/2021",
      intervenant: 12,
      travaux: 56,
      prochaine: "11/11/2021 10:30",
      dateCreation: "11/11/2021 10:30"
    },
  ];
  
  constructor( ) { }

  ngOnInit() { }
}