import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilOptions } from 'src/app/shared-components/util/util-option';

@Component({
  selector: 'app-chantier-edit',
  templateUrl: './chantier-edit.component.html',
  styleUrls: ['./chantier-edit.component.scss']
})
export class ChantierEditComponent implements OnInit {
  form: FormGroup;
  required = UtilOptions.required;
  soutraitanceRow = UtilOptions.reponseOuiNom;
  submit = false;
  imgUrl: any;

  public mask: Array<string | RegExp>;
  public maskDate: Array<string | RegExp>;
  public maskPhone: Array<string | RegExp>;

  constructor(
    private formBuild: FormBuilder,
  ) {
    this.maskPhone = ['(', '+', '2', '2', '5', ')', ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
    this.maskDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    this.newForm();
    this.imgUrl = [];
  }

  ngOnInit() {
  }

  newForm(){
    this.form = this.formBuild.group({
      projet: [null, [Validators.required]],
      dateDebut: [null, [Validators.required]] ,
      dateFin: [null, [Validators.required]],
      libelle: [null, [Validators.required]] ,
      type: [null, [Validators.required]],
      debutChantier: [null, [Validators.required]],
      finChantier: [null, [Validators.required]],
      longitude: [null, [Validators.required]] ,
      latitude: [null, [Validators.required]],
      soutraitant: [null, [Validators.required]],
      mtnSoutraitance: [null, [Validators.required]],
      soutraitance: ['NON', [Validators.required]],
      chefChantier: [null, [Validators.required]],
      description: [null, [Validators.required]],
      photo: [null, [Validators.required]]
    });
  }
  
  // "onSelectImg" pour la selection des image dans le formulaire et "deleteImg" pour la suppression
  onSelectImg(e){
    if(e.target.files){
      var reader = new FileReader();
      var file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload=(events:any)=>{
        this.imgUrl.push(events.target.result);
      }
    }
  }

  deleteImg(el) {
    this.imgUrl.forEach((value,index) => {
      if(value == el)
        this.imgUrl.splice(index, 1);
    });
  }

  onSubmit() {
    this.submit = true;
    console.log(this.form.value);
    this.form.reset();
  }

  get f() { return this.form.controls; }

}