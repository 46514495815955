import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommandePagesRoutingModule } from './commande-pages-routing.module';
import { CommandePagesComponent } from './commande-pages.component';
import { CommandeDetailComponent } from './commande-detail/commande-detail.component';
import { CommandeEditComponent } from './commande-edit/commande-edit.component';
import { CommandeListComponent } from './commande-list/commande-list.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountUpModule } from 'countup.js-angular2';
import { NgxGaugeModule } from 'ngx-gauge';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule, PaginationModule } from 'ngx-bootstrap';


@NgModule({
  declarations: [
    CommandePagesComponent,
    CommandeDetailComponent,
    CommandeEditComponent,
    CommandeListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CountUpModule,
    NgxGaugeModule,
    FontAwesomeModule,
    ModalModule,
    PaginationModule,
    CommandePagesRoutingModule
  ],
  exports: [
    CommandeDetailComponent,
    CommandeEditComponent,
    CommandeListComponent
  ]
})
export class CommandePagesModule { }
