import { Component } from '@angular/core';
import { AuthPagesService } from './pages/auth-pages/auth-pages.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet><ng-progress></ng-progress>'
})
export class AppComponent {

  constructor(private __authSrv: AuthPagesService){}
  ngOnInit(){
    this.__authSrv.autoLogin();
  }
}
