import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BureauControllePagesRoutingModule } from './bureau-controlle-pages-routing.module';
import { BureauControllePagesComponent } from './bureau-controlle-pages.component';
import { RouterModule } from '@angular/router';
import { BureauControlleDashboardComponent } from './bureau-controlle-dashboard/bureau-controlle-dashboard.component';


@NgModule({
  declarations: [
    BureauControllePagesComponent,
    BureauControlleDashboardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BureauControllePagesRoutingModule
  ],
  exports: [
    BureauControlleDashboardComponent
  ],
})
export class BureauControllePagesModule { }
