import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SituationHseDashboardComponent } from './situation-hse-dashboard/situation-hse-dashboard.component';
import { SituationHseDetailComponent } from './situation-hse-detail/situation-hse-detail.component';
import { SituationHseEditComponent } from './situation-hse-edit/situation-hse-edit.component';
import { SituationHseListComponent } from './situation-hse-list/situation-hse-list.component';
import { SituationHsePagesComponent } from './situation-hse-pages.component';


const routes: Routes = [
  {
    path:'', component: SituationHsePagesComponent,
    children: [
      {
        path: "dashboard",
        component: SituationHseDashboardComponent
      },
      {
        path: "edit",
        component: SituationHseEditComponent
      },
      {
        path: "liste",
        component: SituationHseListComponent
      },
      {
        path: "detail/:id",
        component: SituationHseDetailComponent
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SituationHsePagesRoutingModule { }
