<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-edit display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1 class="font-weight-bold m-0">Détail de rémarque</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description m-0" title="Référence et libellé de la visite">
                    <span><i class="fas fa-file-alt"></i> ( RE0001 )</span>
                    <span class="ml-1">Remarque 1</span>
                </div>
                <p class="m-0" title="Date de création de la visite">
                    <i class="fas fa-calendar-alt"></i>
                    <span class="ml-1">Créer le: 11/11/2021 11:11:11</span>
                </p>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button [routerLink]="['/pages/visite/detail/1']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow" type="tabs">

    <!-- Fiche de remarque -->
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span>Fiche de remarque</span></ng-template>
        <div class="bg-white mt-2 rounded shadow-sm">
            <div class="header p-3 m-0">
                <div class="title font-weight-bold m-0">
                    <i class="fas fa-file-alt mr-1"></i> Informations générales de la reserve
                </div>
            </div>
            <hr class="m-0">
            <div class="body p-3">
                <div class="border rounded p-3">
                    <hr class="m-0">
                    <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Information générales</h4></div>
                    <div class="row">
                        <div class="col-md-6">
                            <p class="mt-2 mb-0"><b>Numero:</b> <span class="ml-2"> 1</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Libellé:</b> <span class="ml-2">31/07/2021</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Status:</b> <span class="ml-2">31/07/2021 11:11:11</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date de résolution:</b> <span class="ml-2">En cours</span></p>
                        </div>
                        <div class="col-md-6">
                            <p class="mt-2 mb-0"><b>Créer par:</b> <span class="ml-2">Kouassi ghislain</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date de création:</b> <span class="ml-2">31/07/2021</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Modifier par:</b> <span class="ml-2">Kouassi ghislain</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date de modification: </b> <span class="ml-2">31/07/2021</span></p>
                        </div>
                    </div>
                    <hr class="my-2">
                    <p class="mb-2"><b>Description:</b></p>
                    <div class="border rounded p-3 bg-light">
                        <span>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi deleniti dicta dolorum reiciendis vitae maxime architecto animi odit, quos nihil quia corrupti odio ipsa rem, at ullam necessitatibus repellat in.
                            Sequi rerum ipsam alias modi, cumque, animi necessitatibus voluptatem dolores corporis delectus voluptatibus provident, iste reiciendis. Similique, temporibus. Quam, autem itaque quis deleniti exercitationem laboriosam. Rem doloremque temporibus nihil cupiditate!
                        </span>
                    </div>
            
                    <!-- Images associés -->
                    <div class="p-0 m-0">
                        <hr class="m-0">
                        <div class="p-2 bg-secondary">
                            <h4 class="m-0 font-weight-bold text-first">Images associés</h4>
                        </div>
                        <hr class="mt-0 mb-2">
                        <div class="row">
                            <div class="col-md-3"  *ngFor="let item of albums; let i=index">
                                <a class="card-img-wrapper rounded border mb-2" style="height: 200px;">
                                    <div class="img-wrapper-overlay">
                                        <div class="overlay-btn-wrapper">
                                            <button class="btn p-0 d-50 rounded-circle btn-outline-secondary border-2 mr-1" type="button" (click)="open(i)">
                                                <i class="fas fa-expand"></i>
                                            </button>
                                            <button class="btn p-0 d-50 rounded-circle btn-outline-secondary border-2" type="button">
                                                <i class="fas fa-pen"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <img [src]="item.src" alt="..." class="card-img-top rounded-sm" style="height: 200px;">
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr class="mt-2 mb-5">
            
                    <!-- Lots de travaux associés -->
                    <div class="p-0 m-0">
                        <hr class="m-0">
                        <div class="p-2 bg-secondary">
                            <h4 class="m-0 font-weight-bold text-first">Lots de travaux associés</h4>
                        </div>
                        <hr class="m-0">
                        <div class="list-group list-group-flush mb-5">
                            <li class="list-group-item list-group-item-action align-box-row">
                                <fa-icon [icon]="['far', 'folder']" class="font-size-lg mr-3"></fa-icon>
                                <div>
                                    <div>Travaux 1</div>
                                </div>
                            </li>
                            <li class="list-group-item list-group-item-action align-box-row">
                                <fa-icon [icon]="['far', 'folder-open']" class="font-size-lg mr-3"></fa-icon>
                                <div>
                                    <div>Travaux 2</div>
                                </div>
                            </li>
                            <li class="list-group-item list-group-item-action align-box-row">
                                <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                                <div>
                                    <div>Travaux 2.1</div>
                                </div>
                            </li>
                            <li class="list-group-item list-group-item-action align-box-row">
                                <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                                <div>
                                    <div>Travaux 2.2</div>
                                </div>
                            </li>
                            <li class="list-group-item list-group-item-action align-box-row">
                                <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                                <div>
                                    <div>Travaux 2.3</div>
                                </div>
                            </li>
                            
                            <li class="list-group-item list-group-item-action align-box-row">
                                <fa-icon [icon]="['far', 'folder']" class="font-size-lg mr-3"></fa-icon>                            <div>
                                    <div>Travaux 3</div>
                                </div>
                            </li>
                        </div>
                    </div>
            
                    <!-- Intervenants associés -->
                    <div class="p-0 m-0">
                        <hr class="m-0">
                        <div class="p-2 bg-secondary">
                            <h4 class="m-0 font-weight-bold text-first">Intervenants associés</h4>
                        </div>
                        <div class="table-responsive m-0">
                            <table class="table m-0">
                                <thead class="bg-white">
                                    <tr>
                                        <th class="bg-white">Intervenant</th>
                                        <th class="bg-white">Nom et prenom</th>
                                        <th class="bg-white">Fonction</th>
                                        <th class="bg-white">Contact</th>
                                        <th class="bg-white">Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="avatar-icon-wrapper mr-2">
                                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                                </div>
                                                <div>
                                                    <a class="font-weight-bold text-black" title="...">Kouassi ghislain</a>
                                                    <span class="text-black-50 d-block">Ingenieur en batiment</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Kouassi ghislain</td>
                                        <td>Ingenieur en batiment</td>
                                        <td>00 00 00 00 00</td>
                                        <td>exemple@gmail.com</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <hr class="mt-2 mb-5">
            
                    <!-- Position sur plan -->
                    <div class="p-0 m-0">
                        <hr class="m-0">
                        <div class="p-2 bg-secondary">
                            <h4 class="m-0 font-weight-bold text-first">Position sur plan</h4>
                        </div>
                        <hr class="mt-0 mb-2">
                        <div class="row">
                            <div class="col-md-3"  *ngFor="let item of albums; let i=index">
                                <a class="card-img-wrapper rounded border mb-2" style="height: 200px;">
                                    <div class="img-wrapper-overlay">
                                        <div class="overlay-btn-wrapper">
                                            <button class="btn p-0 d-50 rounded-circle btn-outline-secondary border-2 mr-1" type="button" (click)="open(i)">
                                                <i class="fas fa-expand"></i>
                                            </button>
                                            <button class="btn p-0 d-50 rounded-circle btn-outline-secondary border-2" type="button">
                                                <i class="fas fa-pen"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <img [src]="item.plan" alt="..." class="card-img-top rounded-sm" style="height: 200px;">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="p-3 text-right">
                    <button class="btn btn-info mr-2"><i class="fas fa-pen"></i> Modifier</button>
                    <button class="btn btn-warning mr-2"><i class="ion-md-print"></i> Imprimer</button>
                    <button class="btn btn-success mr-2"><i class="fas fa-check"></i> Clôturer</button>
                    <button class="btn btn-danger"><i class="ion-md-trash"></i> Supprimer</button>
                </div>
            </div>
        </div>
    </tab>

    <!-- Discussion -->
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span>Discussion</span></ng-template>
        <div class="card mt-2 rounded-sm">
            <div class="no-gutters row">
                <div class="col-md-3">
                    <div class="divider-v divider-v-lg"></div>
                    <div class="m-0 p-3 bg-secondary">
                        <input type="search" class="form-control" placeholder="recherche">
                    </div>
                    <hr class="m-0">

                    <perfect-scrollbar [autoPropagation]="true"  class="scroll-area-lg shadow-overflow">
                        <div class="px-4 pt-4">
                            <ul class="nav nav-neutral-primary nav-pills flex-column">
                                <li class="nav-item pt-1 pb-3">
                                    <div class="text-uppercase font-size-sm text-muted">
                                        Members list
                                    </div>
                                </li>

                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link">
                                        <div class="align-box-row w-100">
                                            <div class="avatar-icon-wrapper avatar-icon-md">
                                                <span class="badge badge-circle badge-success">Online</span>
                                                <div class="avatar-icon rounded-circle"><img alt="..." src="assets/img/av1.png"></div>
                                            </div>
                                            <div>
                                                <span class="d-block">
                                                    Adella Galen
                                                    <small class="d-block text-black-50">(Galen@example.com)</small>
                                                </span>
                                            </div>
                                            <div class="ml-auto font-size-xs">
                                                <fa-icon [icon]="['fas', 'angle-right']" class="d-40 text-right d-block"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link">
                                        <div class="align-box-row w-100">
                                            <div class="avatar-icon-wrapper avatar-icon-md">
                                                <span class="badge badge-circle badge-danger">Offline</span>
                                                <div class="avatar-icon rounded-circle"><img alt="..." src="assets/img/av2.png"></div>
                                            </div>
                                            <div>
                                                <span class="d-block">
                                                    Mandy Erle
                                                    <small class="d-block text-black-50">(Mandyrle@gma.com)</small>
                                                </span>
                                            </div>
                                            <div class="ml-auto font-size-xs">
                                                <fa-icon [icon]="['fas', 'angle-right']" class="d-40 text-right d-block"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link">
                                        <div class="align-box-row w-100">
                                            <div class="avatar-icon-wrapper avatar-icon-md">
                                                <span class="badge badge-circle badge-success">Online</span>
                                                <div class="avatar-icon rounded-circle"><img alt="..." src="assets/img/av3.png"></div>
                                            </div>
                                            <div>
                                                <span class="d-block">
                                                    John Doe
                                                    <small class="d-block text-black-50">(Galen@example.com)</small>
                                                </span>
                                            </div>
                                            <div class="ml-auto font-size-xs">
                                                <fa-icon [icon]="['fas', 'angle-right']" class="d-40 text-right d-block"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link">
                                        <div class="align-box-row w-100">
                                            <div class="avatar-icon-wrapper avatar-icon-md">
                                                <span class="badge badge-circle badge-warning">Idle</span>
                                                <div class="avatar-icon rounded-circle"><img alt="..." src="assets/img/av2.png"></div>
                                            </div>
                                            <div>
                                                <span class="d-block">
                                                    Napoleon Stacey
                                                    <small class="d-block text-black-50">(Napoleon@test.com)</small>
                                                </span>
                                            </div>
                                            <div class="ml-auto font-size-xs">
                                                <fa-icon [icon]="['fas', 'angle-right']" class="d-40 text-right d-block"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link">
                                        <div class="align-box-row w-100">
                                            <div class="avatar-icon-wrapper avatar-icon-md">
                                                <span class="badge badge-circle badge-success">Online</span>
                                                <div class="avatar-icon rounded-circle"><img alt="..." src="assets/img/av1.png"></div>
                                            </div>
                                            <div>
                                                <span class="d-block">
                                                    Adella Galen
                                                    <small class="d-block text-black-50">(Galen@example.com)</small>
                                                </span>
                                            </div>
                                            <div class="ml-auto font-size-xs">
                                                <fa-icon [icon]="['fas', 'angle-right']" class="d-40 text-right d-block"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link">
                                        <div class="align-box-row w-100">
                                            <div class="avatar-icon-wrapper avatar-icon-md">
                                                <span class="badge badge-circle badge-danger">Offline</span>
                                                <div class="avatar-icon rounded-circle"><img alt="..." src="assets/img/av2.png"></div>
                                            </div>
                                            <div>
                                                <span class="d-block">
                                                    Mandy Erle
                                                    <small class="d-block text-black-50">(Mandyrle@gma.com)</small>
                                                </span>
                                            </div>
                                            <div class="ml-auto font-size-xs">
                                                <fa-icon [icon]="['fas', 'angle-right']" class="d-40 text-right d-block"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link">
                                        <div class="align-box-row w-100">
                                            <div class="avatar-icon-wrapper avatar-icon-md">
                                                <span class="badge badge-circle badge-success">Online</span>
                                                <div class="avatar-icon rounded-circle"><img alt="..." src="assets/img/av3.png"></div>
                                            </div>
                                            <div>
                                                <span class="d-block">
                                                    John Doe
                                                    <small class="d-block text-black-50">(Galen@example.com)</small>
                                                </span>
                                            </div>
                                            <div class="ml-auto font-size-xs">
                                                <fa-icon [icon]="['fas', 'angle-right']" class="d-40 text-right d-block"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link">
                                        <div class="align-box-row w-100">
                                            <div class="avatar-icon-wrapper avatar-icon-md">
                                                <span class="badge badge-circle badge-warning">Idle</span>
                                                <div class="avatar-icon rounded-circle"><img alt="..." src="assets/img/av2.png"></div>
                                            </div>
                                            <div>
                                                <span class="d-block">
                                                    Napoleon Stacey
                                                    <small class="d-block text-black-50">(Napoleon@test.com)</small>
                                                </span>
                                            </div>
                                            <div class="ml-auto font-size-xs">
                                                <fa-icon [icon]="['fas', 'angle-right']" class="d-40 text-right d-block"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link">
                                        <div class="align-box-row w-100">
                                            <div class="avatar-icon-wrapper avatar-icon-md">
                                                <span class="badge badge-circle badge-danger">Offline</span>
                                                <div class="avatar-icon rounded-circle"><img alt="..." src="assets/img/av2.png"></div>
                                            </div>
                                            <div>
                                                <span class="d-block">
                                                    Mandy Erle
                                                    <small class="d-block text-black-50">(Mandyrle@gma.com)</small>
                                                </span>
                                            </div>
                                            <div class="ml-auto font-size-xs">
                                                <fa-icon [icon]="['fas', 'angle-right']" class="d-40 text-right d-block"></fa-icon>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </perfect-scrollbar>
                    <hr class="mt-0 mb-2">
                    <div>
                        <ul class="nav nav-neutral-primary nav-pills flex-column">
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link">
                                    <div class="nav-link-icon">
                                        <fa-icon [icon]="['far', 'keyboard']"></fa-icon>
                                    </div>
                                    <span>Messages recent</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link text-danger">
                                    <div class="nav-link-icon">
                                        <fa-icon [icon]="['far', 'trash-alt']" class="text-danger"></fa-icon>
                                    </div>
                                    <span>Nettoyer conversation</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="body">
                        <perfect-scrollbar [autoPropagation]="true" class="scroll-area-lg shadow-overflow">
                            <div class="chat-wrapper p-3 w-100">
                                <div class="chat-item p-2 mb-2">
                                    <div class="align-box-row">
                                        <div class="avatar-icon-wrapper avatar-icon-lg align-self-start">
                                            <div class="avatar-icon rounded border-0">
                                                <img alt="..." src="assets/img/av1.png">
                                            </div>
                                        </div>
                                        <div>
                                            <div class="chat-box bg-first text-white">
                                                <p>Hello, John.</p>
                                                <p>This is Kenny. How are you?</p>
                                            </div>
                                            <small class="mt-2 d-block text-black-50">
                                                <fa-icon [icon]="['far', 'clock']" class="mr-1 opacity-5"></fa-icon>
                                                11:01 AM | Yesterday
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-item chat-item-reverse p-2 mb-2">
                                    <div class="align-box-row flex-row-reverse">
                                        <div class="avatar-icon-wrapper avatar-icon-lg align-self-start">
                                            <div class="avatar-icon rounded border-0">
                                                <img alt="..." src="assets/img/av2.png">
                                            </div>
                                        </div>
                                        <div>
                                            <div class="chat-box bg-first text-white">
                                                <p>Hey, Kate.</p>
                                                <p>I'm attaching the pictures you requested below:</p>
                                                <div class="card mt-3 mb-2 py-2 text-center">
                                                    <div>
                                                        <a [routerLink]=""><img alt="Rounded image" class="img-fluid rounded m-1 shadow-sm" src="assets/images/stock-photos/people-1.jpg" width="54"></a>
                                                        <a [routerLink]=""><img alt="Rounded image" class="img-fluid rounded m-1 shadow-sm" src="assets/images/stock-photos/people-2.jpg" width="54"></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <small class="mt-2 d-block text-black-50">
                                                <fa-icon [icon]="['far', 'clock']" class="mr-1 opacity-5"></fa-icon>
                                                11:01 AM | Yesterday
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-item p-2 mb-2">
                                    <div class="align-box-row">
                                        <div class="avatar-icon-wrapper avatar-icon-lg align-self-start">
                                            <div class="avatar-icon rounded border-0">
                                                <img alt="..." src="assets/img/av1.png">
                                            </div>
                                        </div>
                                        <div>
                                            <div class="chat-box bg-first text-white">
                                                <p>Thanks.</p>
                                                <p>Really appreciate it!</p>
                                            </div>
                                            <small class="mt-2 d-block text-black-50">
                                                <fa-icon [icon]="['far', 'clock']" class="mr-1 opacity-5"></fa-icon>
                                                11:01 AM | Yesterday
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-item p-2 mb-2">
                                    <div class="align-box-row">
                                        <div class="avatar-icon-wrapper avatar-icon-lg align-self-start">
                                            <div class="avatar-icon rounded border-0">
                                                <img alt="..." src="assets/img/av1.png">
                                            </div>
                                        </div>
                                        <div>
                                            <div class="chat-box bg-first text-white">
                                                <p>Bye for now, talk to you later.</p>
                                            </div>
                                            <small class="mt-2 d-block text-black-50">
                                                <fa-icon [icon]="['far', 'clock']" class="mr-1 opacity-5"></fa-icon>
                                                11:01 AM | Yesterday
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-item chat-item-reverse p-2 mb-2">
                                    <div class="align-box-row flex-row-reverse">
                                        <div class="avatar-icon-wrapper avatar-icon-lg align-self-start">
                                            <div class="avatar-icon rounded border-0">
                                                <img alt="..." src="assets/img/av2.png">
                                            </div>
                                        </div>
                                        <div>
                                            <div class="chat-box bg-first text-white">
                                                <p>Almost forgot about your tasks.</p>
                                                <p><b>Check the links below:</b></p>
                                                <div class="card bg-premium-dark p-2 mt-3 mb-2">
                                                    <div class="text-center py-2">
                                                        <a [routerLink]="" class="m-1 btn btn-link p-0 bg-grow-early d-inline-block text-center text-white font-size-xl d-40 rounded-circle card-box-hover-rise-alt" tooltip="Menu Example">
                                                            <fa-icon [icon]="['far', 'building']"></fa-icon>
                                                        </a>
                                                        <a [routerLink]="" class="m-1 btn btn-link p-0 bg-ripe-malin d-inline-block text-center text-white font-size-xl d-40 rounded-circle card-box-hover-rise-alt" tooltip="Menu Example">
                                                            <fa-icon [icon]="['far', 'keyboard']"></fa-icon>
                                                        </a>
                                                        <a [routerLink]="" class="m-1 btn btn-link p-0 bg-plum-plate d-inline-block text-center text-white font-size-xl d-40 rounded-circle card-box-hover-rise-alt" tooltip="Menu Example">
                                                            <fa-icon [icon]="['far', 'object-group']"></fa-icon>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <small class="mt-2 d-block text-black-50">
                                                <fa-icon [icon]="['far', 'clock']" class="mr-1 opacity-5"></fa-icon>
                                                11:03 AM | Yesterday
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </perfect-scrollbar>
                    </div>
                    <hr class="m-0">
                    <div class="footer p-3 m-0 bg-secondary rounded-bottom">
                        <form>
                            <input type="text" class="form-control mb-3" placeholder="Saississez votre commentaire ici">
        
                            <div class="custom-file mb-3">
                                <input type="file" class="custom-file-input" title="Document">
                                <label class="custom-file-label" for="document">Choisir un document</label>
                            </div>
        
                            <button class="btn btn-primary"><i class="fas fa-paper-plane"></i> Envoyer</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </tab>
</tabset>