<div class="rounded">
    <div class="px-3 py-4 text-center bg-secondary rounded-top">
        <h4 class="font-weight-bold m-0">Nouvel intervenant</h4>
    </div>
    <hr class="m-0">
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <perfect-scrollbar [autoPropagation]="true" class="scroll-area scroll-area-sm shadow-none" style="height: 30em;">
                <div class="px-3 pt-1">
                    <div class="form-row">
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="type">Type d'intervenant: <span class="text-danger ml-2">*</span></label>
                                <div class="input-group">
                                    <select id="type" formControlName="type" class="form-control" *ngIf="inputGroup == false" [ngClass]="{ 'is-invalid': submit && f.type.errors, 'is-valid': submit && f.type.valid }">
                                        <option *ngFor="let type of typeIntervenant" [value]="type.value">{{type.label}}</option>
                                    </select>

                                    <input *ngIf="inputGroup == true" id="type" formControlName="type" class="form-control"
                                        placeholder="Type" type="text"
                                        [ngClass]="{ 'is-invalid': submit && f.type.errors, 'is-valid': submit && f.type.valid }"
                                    >
                                    <button tooltip="{{inputGroup == true ? 'Clique pour choisir' : 'Clique pour saisir' }}" placement="auto" (click)="inputGroup = !inputGroup" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button">
                                        <span class="btn-wrapper--icon">
                                            <i class="{{inputGroup == true ? 'fas fa-hand-pointer' : 'fas fa-keyboard'}}"></i>
                                        </span>
                                    </button>
                                </div>

                                <div class="invalid-feedback" *ngIf="submit && f.type.errors">
                                    <div *ngIf="f.type.errors.required">{{required.novide}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="nom">{{f.type.value == 'entreprise' ? 'Raison social' : 'Nom et prenom'}}: <span class="text-danger ml-2">*</span></label>
                                <input type="text" class="form-control" id="nom" formControlName="nom"
                                placeholder="{{f.type.value == 'entreprise' ? 'Raison social' : 'Nom et prenom'}}"
                                [ngClass]="{ 'is-invalid': submit && f.nom.errors, 'is-valid': submit && f.nom.valid }">

                                <div class="invalid-feedback" *ngIf="submit && f.nom.errors">
                                    <div *ngIf="f.nom.errors.required">{{required.novide}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="fonction">{{f.type.value == 'entreprise' ? 'Service' : 'Fonction'}}: <span class="text-danger ml-2">*</span></label>
                                <input type="text" class="form-control" id="fonction" formControlName="fonction"
                                placeholder="{{f.type.value == 'entreprise' ? 'Service' : 'Fonction'}}"
                                [ngClass]="{ 'is-invalid': submit && f.fonction.errors, 'is-valid': submit && f.fonction.valid }">

                                <div class="invalid-feedback" *ngIf="submit && f.fonction.errors">
                                    <div *ngIf="f.fonction.errors.required">{{required.novide}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="tel">Numéro téléphone (Fixe): </label>
                                <input type="text" class="form-control" id="tel" formControlName="tel" placeholder="Numéro téléphone">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="mobile">Contact (Mobile): <span class="text-danger ml-2">*</span></label>
                                <input type="text" class="form-control" id="mobile" formControlName="mobile" placeholder="Contact"
                                [ngClass]="{ 'is-invalid': submit && f.mobile.errors, 'is-valid': submit && f.mobile.valid }">

                                <div class="invalid-feedback" *ngIf="submit && f.mobile.errors">
                                    <div *ngIf="f.mobile.errors.required">{{required.novide}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="email">Email: <span class="text-danger ml-2">*</span></label>
                                <input type="email" class="form-control" id="email" formControlName="email" placeholder="Adresse email"
                                [ngClass]="{ 'is-invalid': submit && f.email.errors, 'is-valid': submit && f.email.valid }">

                                <div class="invalid-feedback" *ngIf="submit && f.email.errors">
                                    <div *ngIf="f.email.errors.required">{{required.novide}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="pays">Pays: </label>
                                <input type="text" class="form-control" id="pays" formControlName="pays" placeholder="Pays">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="ville">Ville: </label>
                                <input type="text" class="form-control" id="ville" formControlName="ville" placeholder="Ville">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="commune">Commune: </label>
                                <input type="text" class="form-control" id="commune" formControlName="commune" placeholder="Commune">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="quartier">Quartier: </label>
                                <input type="text" class="form-control" id="quartier" formControlName="quartier" placeholder="Quartier">
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="f.type.value == 'entreprise'">
                            <div class="position-relative form-group">
                                <label for="rgistreCommerce">Registre de commerce: </label>
                                <input type="text" class="form-control" id="rgistreCommerce" formControlName="rgistreCommerce" placeholder="Registre de commerce">
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="f.type.value == 'entreprise'">
                            <div class="position-relative form-group">
                                <label for="numContribuable">Numero contribuable: </label>
                                <input type="text" class="form-control" id="numContribuable" formControlName="numContribuable" placeholder="Numero contribuable">
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="f.type.value == 'entreprise'">
                            <div class="position-relative form-group">
                                <label for="regime">Régime d'imposition: </label>
                                <input type="text" class="form-control" id="regime" formControlName="regime" placeholder="Régime d'imposition">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="photo">{{f.type.value == 'entreprise' ? 'Logo' : 'Photo'}}: </label>
                                <div class="custom-file">
                                    <input type="file" id="photo" formControlName="photo" class="custom-file-input">
                                    <label class="custom-file-label" for="photo">{{f.type.value == 'entreprise' ? 'Ajouter le logo' : 'Ajouter une photo'}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </perfect-scrollbar>
        </form>
    </div>
    <hr class="m-0">
    <div class="p-3 text-center bg-secondary rounded-bottom">
        <button class="btn btn-first mr-2" type="button" (click)="form.reset()"><i class="fas fa-broom  mr-2"></i> Nettoyer</button>
        <button  class="btn btn-success" type="submit" (click)="onSubmit()"><i class="fas fa-save mr-2"></i> Enregistrer</button>
    </div>
</div>