<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-box2 display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1 class="font-weight-bold m-0">Détail travaux</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description m-0" title="Référence et libellé du travail">
                    <span><i class="fas fa-file-alt"></i> ( TR0001 )</span>
                    <span class="ml-1">Travaux de Terrassement</span>
                </div>
                <p class="m-0" title="Date début et date fin du travail">
                    <i class="fas fa-calendar-alt"></i>
                    <span class="ml-1">Du 01/01/2021 au 11/11/2021</span>
                </p>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button class="btn btn-secondary" type="button" [routerLink]="['/pages/chantier/detail/5']" ><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<accordion [closeOthers]="true" [isAnimated]="true" class="accordion mb-3">
    <accordion-group class="card card-box">
        <button accordion-heading class="btn btn-link btn-lg d-flex align-items-center justify-content-between"
                type="button">
            <span>Cliquer pour dérouler</span>
            <fa-icon [icon]="['fas', 'angle-up']" class="font-size-xl"></fa-icon>
        </button>
        <div class="m-3 border rounded">
            <div class="row">
                <div class="col-md-4">
                    <p class="mt-2 mb-0"><b>Travaux:</b> <span class="ml-2"></span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Sous travaux:</b> <span class="ml-2"></span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date début:</b> <span class="ml-2"></span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date fin:</b> <span class="ml-2"></span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Responsable:</b> <span class="ml-2"></span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Prestaire de service: </b> <span class="ml-2"></span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Sous-traiter: </b> <span class="ml-2">OUI</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Soutraitant: </b> <span class="ml-2">OUI</span></p>
                    <hr class="my-2">
                </div>
                <div class="col-md-4">
                    <p class="mt-2 mb-0"><b>Montant soutraitance:</b> <span class="ml-2"></span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Nombre de tâche:</b> <span class="ml-2">100</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Tâche planifiées:</b> <span class="ml-2">45/100</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Description:</b> <span class="badge badge-info ml-2"></span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Créer par:</b> <span class="ml-2"></span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de création:</b> <span class="ml-2"></span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Modifier par:</b> <span class="ml-2"></span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de modification:</b> <span class="ml-2"></span></p>
                    <hr class="my-2">
                </div>
                <div class="col-md-4">
                    <a [routerLink]="" class="card-img-wrapper rounded mt-2 mr-1">
                        <div class="img-wrapper-overlay">
                            <div class="overlay-btn-wrapper">
                                <button class="btn btn-first p-0 d-50 rounded-circle" type="button" tooltip="Agrandir">
                                    <span class="btn-wrapper--icon">
                                        <i class="fas fa-expand"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <img alt="..." class="card-img-top rounded" src="assets/images/stock-photos/stock-1.jpg"/>
                    </a>
                </div>
            </div>
        </div>
        <hr class="m-0">
        <div class="text-right py-2 px-3">
            <button class="btn btn-info mr-2" type="button"><i class="fas fa-pen mr-1"></i>Modifier</button>
            <button class="btn btn-warning mr-2" type="button"><i class="ion-md-print mr-1"></i>Imprimer</button>
            <button class="btn btn-danger" type="button"><i class="ion-md-trash mr-1"></i>Supprimer</button>
        </div>
    </accordion-group>
</accordion>

<div>
    <app-tache-list></app-tache-list>
</div>