import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-remarque-pages',
  templateUrl: './remarque-pages.component.html',
  styleUrls: ['./remarque-pages.component.scss']
})
export class RemarquePagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
