import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { stringify } from 'querystring';
import { BehaviorSubject,throwError } from 'rxjs';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BureauEtude } from './bureau-etude.model';


interface BureauEtudeData {
  id?: string,
  libelle: string,
  localisation: string,
}

@Injectable({
  providedIn: 'root'
})
export class BureauEtudeService {

  private _bureauEtudes = new BehaviorSubject<BureauEtude[]>([]);
  apiUrl = environment.apiUrl;
  prefix = environment.prefix;

  constructor(private http:HttpClient) { }

  get bureauEtudes(){
    return this._bureauEtudes.asObservable();
  }

  listBureauEtude(){
    return this.http.get<BureauEtudeData[]>(
      `${this.apiUrl}/bureau_etudes`,{
        responseType: 'json'
      }
    ).pipe(
      map((response: any) => response['hydra:member']), 
      catchError(this.handleError)
    )
  }

  addBureauEtude(data:BureauEtudeData){
    let responseData:BureauEtudeData;
        return this.http.post(`${this.apiUrl}/bureau_etudes`,JSON.stringify(data)).pipe(
        tap( resData => {      
          return resData
        }),
        catchError(this.handleError)
      );//pipe   
  }

  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "Une erreur est survenue";
    return throwError(messageError);
  }
}
