import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FournisseurPagesRoutingModule } from './fournisseur-pages-routing.module';
import { FournisseurPagesComponent } from './fournisseur-pages.component';
import { RouterModule } from '@angular/router';
import { FournisseurDashboardComponent } from './fournisseur-dashboard/fournisseur-dashboard.component';
import { FournisseurDetailComponent } from './fournisseur-detail/fournisseur-detail.component';
import { FournisseurEditComponent } from './fournisseur-edit/fournisseur-edit.component';
import { FournisseurListComponent } from './fournisseur-list/fournisseur-list.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AlertModule, BsDatepickerModule, BsDropdownModule, ModalModule, PaginationModule, ProgressbarModule, TabsModule, TooltipModule, TypeaheadModule } from 'ngx-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularTreeGridModule } from 'angular-tree-grid';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TextMaskModule } from 'angular2-text-mask';
import { ArchwizardModule } from 'angular-archwizard';
import { FileUploadModule } from 'ng2-file-upload';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HttpClientModule } from '@angular/common/http';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CountUpModule } from 'countup.js-angular2';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxSpinnerModule } from 'ngx-spinner';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
wheelPropagation: false
};

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'uifort',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
     easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
     easing: 'ease'
    },
    overlap: 150
  }
};


@NgModule({
  declarations: [
    FournisseurPagesComponent,
    FournisseurDashboardComponent,
    FournisseurDetailComponent,
    FournisseurEditComponent,
    FournisseurListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TabsModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgApexchartsModule,
    ModalModule,
    TooltipModule,
    PaginationModule,
    ProgressbarModule,
    CountUpModule,
    PerfectScrollbarModule,
    TypeaheadModule,
    TextMaskModule,
    ArchwizardModule,
    PaginationModule,
    BsDropdownModule,
    BsDatepickerModule,
    NgxGaugeModule,
    Ng2SmartTableModule,
    AngularTreeGridModule,
    AlertModule,
    ImageCropperModule,
    NotifierModule.withConfig(customNotifierOptions),
    FournisseurPagesRoutingModule,
    HttpClientModule,
    NgbModule,
    SweetAlert2Module,
    NgxSpinnerModule
  ],
  entryComponents: [
    FournisseurEditComponent
  ],
  exports: [
    FournisseurDetailComponent,
    FournisseurEditComponent,
    FournisseurListComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class FournisseurPagesModule { }
