import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BudgetPages } from 'src/app/models/budget-pages';
import { BudgetPagesService } from 'src/app/services/budgetPages/budget-pages.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-budget-detail',
  templateUrl: './budget-detail.component.html',
  styleUrls: ['./budget-detail.component.scss']
})
export class BudgetDetailComponent implements OnInit {
  budget: BudgetPages = <BudgetPages>{};
  rotate = true;

  constructor(
    public globals: ThemeOptions,
    private route: ActivatedRoute,
    private budgetService: BudgetPagesService
  ) {
    this.budget = this.budgetService.getBudget();
    // console.log(this.projet)
    const id: number = +this.route.snapshot.paramMap.get('id');
    this.budgetService.getList().subscribe((budgets: BudgetPages[]) => {
      this.budget = budgets.find(budget => budget.id == id);
    });
  }

  ngOnInit() {
  }

}
