<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-attention display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>Tableau de bord HSE</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">
                    Statistique générales
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-4">
        <a [routerLink]="" class="card card-box bg-strong-bliss text-white border-0 mb-5">
            <div class="p-0">
                <div  class="px-4 py-3">
                    <h5>Accident de travail</h5>
                    <h2 class="text-right">
                        <i class="fas fa-car-crash float-left"></i>
                        <span class="font-weight-bold" [countUp]="65"></span>
                    </h2>
                </div>
                <hr class="m-0">
                <div class="d-flex justify-content-between">
                    <div class="text-center p-3">
                        <span>Avec arrêt de travail</span>
                        <h3 class="m-0 font-weight-bold">10</h3>
                    </div>
                    <div class="text-center p-3">
                        <span>Sans arrêt de travail</span>
                        <h3 class="m-0 font-weight-bold">35</h3>
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col-md-4">
        <a [routerLink]="" class="card card-box bg-happy-fisher text-white border-0 mb-5">
            <div class="p-0">
                <div  class="px-4 py-3">
                    <h5>Incident</h5>
                    <h2 class="text-right">
                        <i class="fas fa-skull-crossbones float-left"></i>
                        <span class="font-weight-bold" [countUp]="65"></span>
                    </h2>
                </div>
                <hr class="m-0">
                <div class="d-flex justify-content-between">
                    <div class="text-center p-3">
                        <span>Resolus</span>
                        <h3 class="m-0 font-weight-bold">35</h3>
                    </div>
                    <div class="text-center p-3">
                        <span>Non Resuls</span>
                        <h3 class="m-0 font-weight-bold">10</h3>
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col-md-4">
        <div class="row">
            <div class="col-md-12">
                <a [routerLink]="" class="card card-box border-0 mb-2">
                    <div class="py-2 px-4">
                        <h5 class="text-black">Visite de chantier HSE</h5>
                        <h2 class="text-right">
                            <span class="font-weight-bold text-black float-left" [countUp]="45"></span>
                            <i class="fas fa-list-ul text-info"></i>
                        </h2>
                    </div>
                </a>
            </div>
            <div class="col-md-12">
                <a [routerLink]="" class="card card-box border-0 mb-2">
                    <div class="py-2 px-4">
                        <h5 class="text-black">Réunion HSE</h5>
                        <h2 class="text-right">
                            <span class="font-weight-bold text-black float-left" [countUp]="45"></span>
                            <i class="fas fa-users text-dark"></i>
                        </h2>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
<div class="card card-box border-0 p-0 mb-4">
    <div class="card-header p-3">
        <div class="card-header--title"><b>Diagramme des accident de travail/Incident mensuel</b></div>
    </div>
    <div class="card-body p-0">
        <apx-chart
            [chart]="{
                type: 'line',
                height: '350',
                shadow: {
                    enabled: false,
                    color: '#bbb',
                    top: 3,
                    left: 2,
                    blur: 3,
                    opacity: 1
                }
            }"
            [xaxis]="{
                type: 'date',
                categories: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Juil', 'Aôu', 'Sep', 'Oct', 'Nov', 'Dec']
            }"
            [fill]="{
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    gradientToColors: [ '#FFA41B'],
                    shadeIntensity: 1,
                    type: 'horizontal',
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100, 100, 100]
                }
            }"
            [markers]="{
                size: 6,
                opacity: 0.9,
                colors: ['#FFA41B'],
                strokeColor: '#fff',
                strokeWidth: 2,
                hover: { size: 10}
            }"
            [grid]="{ borderColor: 'rgba(125, 138, 156, 0.4)'}"
            [series]="[
                {
                    name: 'Accident',
                    data: [4, 3, 10, 9, 29, 1, 22, 0, 12, 7, 19, 5]
                },
                {
                    name: 'Incident',
                    data: [0, 0, 1, 1, 1, 15, 5, 0, 12, 0, 19, 0]
                }
            ]"
            [stroke]="{
                width: 7,
                curve: 'smooth'
            }">
        </apx-chart>
    </div>
</div>

<tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow" type="tabs" >
    <!-- Visite de chantier -->
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span>Visite de chantier HSE</span></ng-template>
        <app-visite-list [cardHeaderTitle]="visitCardHeaderTitle"></app-visite-list>
    </tab>

    <!-- Réunion de chantier -->
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span>Réunion HSE</span></ng-template>
        <app-reunion-list [cardHeaderTitle]="reunionCardHeaderTitle"></app-reunion-list>
    </tab>
</tabset>