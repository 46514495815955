import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArchitectPagesRoutingModule } from './architect-pages-routing.module';
import { ArchitectPagesComponent } from './architect-pages.component';
import { ArchitectDashboardComponent } from './architect-dashboard/architect-dashboard.component';


@NgModule({
  declarations: [
    ArchitectPagesComponent,
    ArchitectDashboardComponent,
  ],
  imports: [
    CommonModule,
    ArchitectPagesRoutingModule
  ],
  exports: [
    ArchitectDashboardComponent,
  ],
})
export class ArchitectPagesModule { }
