import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MissionPages } from 'src/app/models/mission-pages';

@Injectable({
  providedIn: 'root'
})
export class MissionPagesService {
  missions: MissionPages[];
  mission: MissionPages;
  private readonly url = "api/missions.json";

  constructor(
    private http: HttpClient
  ) {}

  setMission(mission: MissionPages) {
    this.mission = mission
  }

  getMission(): MissionPages {
    return this.mission
  }

  getList(): Observable<MissionPages[]> {
    return this.http.get<MissionPages[]>(this.url).pipe();
  }
}
