import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomePagesDashboardComponent } from './home-pages-dashboard/home-pages-dashboard.component';
import { HomePagesRoutingModule } from './home-pages-routing.module';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebook, faTwitter, faAngular, faVuejs, faReact, faHtml5, faGoogle, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faChevronRight, faSitemap, faPrint, faMapMarkerAlt, faTachometerAlt, faAlignCenter, faExternalLinkAlt, faShareSquare, faInfoCircle, faSync, faQuoteRight, faStarHalfAlt, faShapes, faCarBattery, faTable, faCubes, faPager, faCameraRetro, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faUnlockAlt, faDownload, faAward, faPlayCircle, faReply, faUpload, faBars, faEllipsisV, faSave, faSlidersH, faCaretRight, faChevronUp, faPlus, faLemon, faChevronLeft, faTimes, faChevronDown, faFilm, faSearch, faEllipsisH, faCog, faArrowsAltH, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLink } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faTimesCircle, faDotCircle, faThumbsUp, faComments, faFolderOpen, faTrashAlt, faFileImage, faFileArchive, faLifeRing, faCommentDots, faFolder, faKeyboard, faCalendarAlt, faEnvelope, faAddressCard, faMap, faObjectGroup, faImages, faUser, faLightbulb, faGem, faClock, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar } from '@fortawesome/free-regular-svg-icons';

import { AccordionModule, PopoverModule, TabsModule, TypeaheadModule } from 'ngx-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AngularTreeGridModule } from 'angular-tree-grid';
import { ModalModule } from 'ngx-bootstrap';
// NGX Bootstrap Datepicker
import { BsDatepickerModule } from 'ngx-bootstrap';
// NGX Bootstrap Tooltip
import { TooltipModule } from 'ngx-bootstrap';
// NGX Pagination for Angular

// NGX Bootstrap Pagination

import { PaginationModule } from 'ngx-bootstrap';

import { AlertModule } from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';
// NGX Bootstrap Progress bar

import { ProgressbarModule } from 'ngx-bootstrap';
// NGX Bootstrap Dropdown

import { BsDropdownModule } from 'ngx-bootstrap';

// AngularEditor

import { AngularEditorModule } from '@kolkov/angular-editor';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { ArchwizardModule } from 'angular-archwizard';
import { FileUploadModule } from 'ng2-file-upload';
import { CountUpModule } from 'countup.js-angular2';
import { NgApexchartsModule } from 'ng-apexcharts';
import { GaugeModule } from 'angular-gauge';
import { NgxGaugeModule } from 'ngx-gauge';
import { FeatherModule } from 'angular-feather';
import { TimepickerModule } from 'ngx-bootstrap';
import { HomePagesComponent } from './home-pages.component';



@NgModule({
  declarations: [HomePagesComponent, HomePagesDashboardComponent],
  imports: [
    CommonModule,
    Ng2SmartTableModule,
    TabsModule,
    FontAwesomeModule,
    AngularTreeGridModule,
    ModalModule,
    BsDatepickerModule,
    TooltipModule,
    AlertModule,
    PaginationModule,
    RouterModule,
    ProgressbarModule,
    BsDropdownModule,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    NgSelectModule,
    TextMaskModule,
    TypeaheadModule,
    ArchwizardModule,
    FileUploadModule,
    CountUpModule,
    NgApexchartsModule,
    GaugeModule,
    NgxGaugeModule,
    FeatherModule,
    PopoverModule,
    AccordionModule,
    TimepickerModule.forRoot(),
    HomePagesRoutingModule,
  ],
  exports: [
    HomePagesDashboardComponent
  ]
})
export class HomePagesModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faInfoCircle, faFacebook, faPrint, faAlignCenter, faMapMarkerAlt, faTachometerAlt, faExternalLinkAlt, faShareSquare, faSitemap, faInfoCircle, faLifeRing, faTwitter, faQuoteRight, faStarHalfAlt, faSync, faShapes, faCarBattery, faTable, faCubes, faPager, faAngular, faVuejs, faReact, faHtml5, faCheckCircle, faTimesCircle, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faThumbsUp, faCameraRetro, faUnlockAlt, faDownload, faUpload, faReply, faGoogle, faFileImage, faFolderOpen, faBars, faTrashAlt, faSave, faPlayCircle, faEllipsisV, faEllipsisH, faSlidersH, faFileArchive, faAward, faCaretRight, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub, faPlus, faFolder, faTimes, faEnvelope, faAddressCard, faMap, faCalendarAlt, faImages, faFilm, faClock, faSearch, faChevronRight, faChevronUp, faChevronLeft, faChevronDown, faLink, faLightbulb, faGem, faCog, faDotCircle, faArrowsAltH, faComments, faCommentDots, faKeyboard, faObjectGroup, faUser, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLemon);
  }
}
