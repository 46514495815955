import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OpportunitePages } from 'src/app/models/opportunite-pages';

@Injectable({
  providedIn: 'root'
})
export class OpportunitePagesService {
  opportunites: OpportunitePages[];
  opportunite: OpportunitePages;
  private readonly url = "api/opportunites.json";

  constructor(
    private http: HttpClient
  ) {}

  setOpportunite(opportunite: OpportunitePages) {
    this.opportunite = opportunite
  }

  getOpportunite(): OpportunitePages {
    return this.opportunite
  }

  getList(): Observable<OpportunitePages[]> {
    return this.http.get<OpportunitePages[]>(this.url).pipe();
  }
}
