import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bureau-etude-pages',
  templateUrl: './bureau-etude-pages.component.html',
  styleUrls: ['./bureau-etude-pages.component.scss']
})
export class BureauEtudePagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
