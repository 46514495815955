import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntervenantPagesRoutingModule } from './intervenant-pages-routing.module';
import { IntervenantEditComponent } from './intervenant-edit/intervenant-edit.component';
import { IntervenantListComponent } from './intervenant-list/intervenant-list.component';
import { IntervenantPagesComponent } from './intervenant-pages.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';


@NgModule({
  declarations: [
    IntervenantPagesComponent,
    IntervenantEditComponent,
    IntervenantListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    HttpClientModule,
    PerfectScrollbarModule,
    IntervenantPagesRoutingModule
  ],
  exports: [
    IntervenantEditComponent,
    IntervenantListComponent
  ]
})
export class IntervenantPagesModule { }
