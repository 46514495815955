import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SituationHse } from 'src/app/models/situation-hse';
import { SituationHseService } from 'src/app/services/situation-hse/situation-hse.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-situation-hse-detail',
  templateUrl: './situation-hse-detail.component.html',
  styleUrls: ['./situation-hse-detail.component.scss']
})
export class SituationHseDetailComponent implements OnInit {
  situation: SituationHse = <SituationHse>{};

  constructor(
    public globals: ThemeOptions,
    private route: ActivatedRoute,
    private situationService: SituationHseService
  ) {
    this.situation = this.situationService.getSituation();
    const id: number = +this.route.snapshot.paramMap.get('id');
    this.situationService.getList().subscribe((situations: SituationHse[]) => {
      this.situation = situations.find(situation => situation.id == id);
    });
  }

  ngOnInit() {
  }

}
