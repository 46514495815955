import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reserve-list',
  templateUrl: './reserve-list.component.html',
  styleUrls: ['./reserve-list.component.scss']
})
export class ReserveListComponent implements OnInit {
  rotate = true;

  // Tout les variables ou tableau ayants pour prefix "default" servent a faire des duplication dans le template.
  defaultRow1 = [
    { line: 1, etatValidation: "validée", etatLivraison: "livrée", fValidateur: "Chef projet" },
    { line: 2, etatValidation: "En attente", etatLivraison: "En attente", fValidateur: "Chef chantier" },
    { line: 3, etatValidation: "non validée", etatLivraison: "livrée", fValidateur: "Chef projet" },
  ];

  constructor() { }

  ngOnInit() {
  }

}
