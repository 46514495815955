<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="p-3">
        <!-- Informations générales s1 -->
        <div class="card card-box shadow-none">
            <div class="card-header p-3 bg-light">
                <div class="card-header--title">Informations générales</div>
            </div>
            <div class="card-body p-3">
                <div class="form-row">
                    <div class="col-md-6">
                        <label for="libelle">Libellé de la remarque: </label>
                        <input type="text" class="form-control" id="libelle" formControlName="libelle" placeholder="Libellé de la remarque">
                    </div>
                    <div class="col-md-3">
                        <div class="position-relative form-group">
                            <label for="status">Status: </label>
                            <select id="status" formControlName="status" class="form-control">
                                <option *ngFor="let item of statusRow" [value]="item.value">{{item.label}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="position-relative form-group">
                            <label for="resolutionDate">Date de résolution:</label>
                            <div class="input-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                </div>
                                <input type="text" id="resolutionDate" formControlName="resolutionDate" class="form-control"
                                [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker placeholder="Date de résolution">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-12">
                        <div class="position-relative form-group">
                            <label for="description">Description: </label>
                            <textarea id="description" formControlName="description" class="form-control" rows="3" placeholder="Description de la remarque"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>

        <!-- Option d'association s2 -->
        <accordion class="accordion shadow-none" [closeOthers]="true" [isAnimated]="true">
            <accordion-group class="card card-box shadow-none">
                <button accordion-heading class="btn btn-link d-flex align-items-center justify-content-between" type="button">
                    <span>
                        <i class="pe-7s-photo nav-icon-wrapper text-primary font-size-lg mr-2"></i>
                        Associer une image
                    </span>
                    <fa-icon [icon]="['fas', 'angle-up']"></fa-icon>
                </button>
                <div class="p-3">
                    <app-images-editor></app-images-editor>
                </div>
            </accordion-group>
            <accordion-group class="card card-box shadow-none">
                <button accordion-heading class="btn btn-link d-flex align-items-center justify-content-between" type="button">
                    <span>
                        <i class="pe-7s-users nav-icon-wrapper text-primary font-size-lg mr-2"></i>
                        Associer un intervenant
                    </span>
                    <fa-icon [icon]="['fas', 'angle-up']"></fa-icon>
                </button>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item py-3">
                        <div class="d-flex justify-content-between">
                            <div>
                                <input type="search" class="form-control" placeholder="Recherche dans la liste des intervenants">
                            </div>
                            <div class="ml-3 text-right">
                                <button (click)="newIntervenant.show()" class="btn btn-transition-none btn-success" placement="auto" type="button">
                                    <i class="fas fa-plus-circle"></i> Ajouter
                                </button>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-3">
                        <div class="d-flex align-items-center flex-column flex-sm-row">
                            <div class="avatar-icon-wrapper mr-1">
                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                            </div>
                            <div class="pl-0 pl-sm-4">
                                <div class="d-block text-center d-sm-flex align-items-center">
                                    <span class="font-size-lg">Kouassi ghislain</span>
                                </div>
                                <p class="text-black-50 mb-0">Chef de chantier</p>
                            </div>
                            <div class="mt-3 mt-sm-0 ml-sm-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="intervenant1" type="checkbox">
                                    <label class="custom-control-label" for="intervenant1">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-3">
                        <div class="d-flex align-items-center flex-column flex-sm-row">
                            <div class="avatar-icon-wrapper mr-1">
                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                            </div>
                            <div class="pl-0 pl-sm-4">
                                <div class="d-block text-center d-sm-flex align-items-center">
                                    <span class="font-size-lg">Kouassi ghislain</span>
                                </div>
                                <p class="text-black-50 mb-0">Chef de chantier</p>
                            </div>
                            <div class="mt-3 mt-sm-0 ml-sm-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="intervenant2" type="checkbox">
                                    <label class="custom-control-label" for="intervenant2">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-3">
                        <div class="d-flex align-items-center flex-column flex-sm-row">
                            <div class="avatar-icon-wrapper mr-1">
                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                            </div>
                            <div class="pl-0 pl-sm-4">
                                <div class="d-block text-center d-sm-flex align-items-center">
                                    <span class="font-size-lg">Kouassi ghislain</span>
                                </div>
                                <p class="text-black-50 mb-0">Chef de chantier</p>
                            </div>
                            <div class="mt-3 mt-sm-0 ml-sm-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="intervenant3" type="checkbox">
                                    <label class="custom-control-label" for="intervenant3">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-3">
                        <div class="d-flex align-items-center flex-column flex-sm-row">
                            <div class="avatar-icon-wrapper mr-1">
                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                            </div>
                            <div class="pl-0 pl-sm-4">
                                <div class="d-block text-center d-sm-flex align-items-center">
                                    <span class="font-size-lg">Kouassi ghislain</span>
                                </div>
                                <p class="text-black-50 mb-0">Chef de chantier</p>
                            </div>
                            <div class="mt-3 mt-sm-0 ml-sm-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="intervenant4" type="checkbox">
                                    <label class="custom-control-label" for="intervenant4">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </accordion-group>
            <accordion-group class="card card-box shadow-none">
                <button accordion-heading class="btn btn-link d-flex align-items-center justify-content-between" type="button">
                    <span>
                        <i class="pe-7s-box2 nav-icon-wrapper text-primary font-size-lg mr-2"></i>
                        Associer un lot de travaux
                    </span>
                    <fa-icon [icon]="['fas', 'angle-up']"></fa-icon>
                </button>
                <div class="list-group list-group-flush">
                    <li class="list-group-item">
                        <input type="search" class="form-control" placeholder="Recherche dans la liste des travaux">
                    </li>
                    <li class="list-group-item list-group-item-action align-box-row">
                        <fa-icon [icon]="['far', 'folder']" class="font-size-lg mr-3"></fa-icon>
                        <div>
                            <div>Travaux 1</div>
                        </div>
                        <div class="ml-auto">
                            <div class="custom-checkbox custom-control align-self-start">
                                <input class="custom-control-input" id="travaux1" type="checkbox">
                                <label class="custom-control-label" for="travaux1">&nbsp;</label>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item list-group-item-action align-box-row">
                        <fa-icon [icon]="['far', 'folder-open']" class="font-size-lg mr-3"></fa-icon>
                        <div>
                            <div>Travaux 2</div>
                        </div>
                        <div class="ml-auto">
                            <div class="custom-checkbox custom-control align-self-start">
                                <input class="custom-control-input" id="travaux2" type="checkbox">
                                <label class="custom-control-label" for="travaux2">&nbsp;</label>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item list-group-item-action align-box-row">
                        <fa-icon [icon]="['far', 'folder-open']" class="font-size-lg ml-4 mr-2"></fa-icon>
                        <div>
                            <div>Travaux 2.1</div>
                        </div>
                        <div class="ml-auto">
                            <div class="custom-checkbox custom-control align-self-start">
                                <input class="custom-control-input" id="travaux2_1" type="checkbox">
                                <label class="custom-control-label" for="travaux2_1">&nbsp;</label>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item list-group-item-action align-box-row">
                        <i class="pe-7s-file font-size-lg ml-5 mr-2"></i>
                        <div>
                            <div>Travaux 2.1.1</div>
                        </div>
                        <div class="ml-auto">
                            <div class="custom-checkbox custom-control align-self-start">
                                <input class="custom-control-input" id="travaux2_1_1" type="checkbox">
                                <label class="custom-control-label" for="travaux2_1_1">&nbsp;</label>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item list-group-item-action align-box-row">
                        <i class="pe-7s-file font-size-lg ml-5 mr-2"></i>
                        <div>
                            <div>Travaux 2.1.2</div>
                        </div>
                        <div class="ml-auto">
                            <div class="custom-checkbox custom-control align-self-start">
                                <input class="custom-control-input" id="travaux2_1_2" type="checkbox">
                                <label class="custom-control-label" for="travaux2_1_2">&nbsp;</label>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item list-group-item-action align-box-row">
                        <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                        <div>
                            <div>Travaux 2.2</div>
                        </div>
                        <div class="ml-auto">
                            <div class="custom-checkbox custom-control align-self-start">
                                <input class="custom-control-input" id="travaux2_2" type="checkbox">
                                <label class="custom-control-label" for="travaux2_2">&nbsp;</label>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item list-group-item-action align-box-row">
                        <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                        <div>
                            <div>Travaux 2.3</div>
                        </div>
                        <div class="ml-auto">
                            <div class="custom-checkbox custom-control align-self-start">
                                <input class="custom-control-input" id="travaux2_3" type="checkbox">
                                <label class="custom-control-label" for="travaux2_3">&nbsp;</label>
                            </div>
                        </div>
                    </li>
                </div>
            </accordion-group>
            <accordion-group class="card card-box shadow-none">
                <button accordion-heading class="btn btn-link d-flex align-items-center justify-content-between" type="button">
                    <span>
                        <i class="pe-7s-map-marker nav-icon-wrapper text-primary font-size-lg mr-2"></i>
                        Positionner sur un plan
                    </span>
                    <fa-icon [icon]="['fas', 'angle-up']"></fa-icon>
                </button>
                <div class="p-3">
                    <div *ngIf="defaultPlan.length > 0">
                        <div class="custom-file">
                            <input type="file" id="plan" formControlName="plan" class="custom-file-input">
                            <label class="custom-file-label" for="plan">Cliquer pour ajouter un plan</label>
                        </div>
                        <hr>
                        <accordion class="accordion shadow-none rounded-sm bg-white mb-2" [closeOthers]="true" [isAnimated]="true" *ngFor="let item of defaultPlan">
                            <accordion-group class="card shadow-sm rounded-sm bg-white">
                                <div accordion-heading>
                                    <span><fa-icon [icon]="['far', 'object-group']" class="nav-icon-wrapper font-size-lg mr-2"></fa-icon>Plan1.pdf</span>
                                    <span class="ml-3">(Cliquer pour derouler)</span>
                                </div>
                                <div class="card card-transparent">
                                    <div class="card-img-wrapper">
                                        <img src="assets/img/pl1.jpg" class="card-img-top" alt="...">
                                    </div>
                                </div>
                            </accordion-group>
                        </accordion>
                    </div>
                    <div *ngIf="defaultPlan.length == 0">
                        <div class="hero-wrapper bg-composed-wrapper bg-plum-plate">
                            <div class="flex-grow-1 w-100 d-flex align-items-center">
                                <div class="bg-composed-wrapper--image opacity-3" style="background-image: url('assets/images/hero-bg/hero-5.jpg');"></div>
                                <div class="bg-composed-wrapper--bg bg-second opacity-1"></div>
                                <div class="bg-composed-wrapper--content">
                                    <div class="container text-center p-5">
                                        <div class="text-white px-2 px-xl-5">
                                            <h1 class="display-3 pb-3 px-2 px-xl-5 font-weight-bold">
                                                Aucun plan disponible.
                                            </h1>
                                            <p class="font-size-lg text-white-50 mt-2 mb-4 d-block px-2 px-xl-5">
                                                Cliquer sur le champ de saisir juste en dessous pour en ajouter un.
                                            </p>
                                            <div class="divider border-2 my-4 border-light opacity-2 mx-auto rounded-circle w-25"></div>
                                            <div>
                                                <div class="custom-file">
                                                    <input type="file" id="plan" formControlName="plan" class="custom-file-input">
                                                    <label class="custom-file-label" for="plan">Cliquer pour ajouter un plan</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </accordion-group>
        </accordion>
    </div>
    <hr class="m-0">
    <div class="text-right px-3 py-2">
        <button class="btn btn-first mr-2" type="button" (click)="form.reset()"><i class="fas fa-broom mr-1"></i> Nettoyer</button>
        <button  class="btn btn-success" type="submit"><i class="fas fa-save mr-1"></i> Enregistrer</button>
    </div>
</form>

<!-- Nouvel intervenant -->
<div #newIntervenant="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded">
        <div class="modal-content rounded border-0">
            <app-intervenant-edit></app-intervenant-edit>
        </div>
    </div>
</div>