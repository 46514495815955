<div class="row mb-3">
    <div class="col">
        <a [routerLink]="" class="card card-box border-0 rounded p-2" title="Total de rapport">
            <div class="d-flex align-items-center mt-1">
                <h1 class="m-0">
                    <i class="fas fa-file-alt img-fluid mx-3 mt-1 text-first"></i>
                </h1>
                <div>
                    <div class="text-black-50">Total de rapport</div>
                    <div class="font-weight-bold display-4 py-1 text-black">
                        <span [countUp]="874"></span>
                    </div>
                    <div class="text-black-50 font-size-sm">
                        <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1 text-warning"></fa-icon>
                        100%
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col">
        <a [routerLink]="" class="card card-box border-0 rounded p-2" title="Total de rapport Validées">
            <div class="d-flex align-items-center mt-1">
                <h1 class="m-0">
                    <i class="fas fa-check img-fluid mx-3 mt-1 text-success"></i>
                </h1>
                <div>
                    <div class="text-black-50">Validée(s)</div>
                    <div class="font-weight-bold display-4 py-1 text-black">
                        <span [countUp]="874"></span>
                    </div>
                    <div class="text-black-50 font-size-sm">
                        <fa-icon [icon]="['far', 'calendar-alt']" class="font-size-sm pr-1 text-warning"></fa-icon>
                        Validé le: 11/11/2021
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col">
        <a [routerLink]="" class="card card-box border-0 rounded p-2" title="Total de rapport Non Validées">
            <div class="d-flex align-items-center mt-1">
                <h1 class="m-0">
                    <i class="fas fa-times img-fluid mx-3 mt-1 text-danger"></i>
                </h1>
                <div>
                    <div class="text-black-50">Non Validée(s)</div>
                    <div class="font-weight-bold display-4 py-1 text-black">
                        <span [countUp]="874"></span>
                    </div>
                    <div class="text-black-50 font-size-sm">
                        <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1 text-warning"></fa-icon>
                        10%
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col">
        <a [routerLink]="" class="card card-box border-0 rounded p-2" title="Total de rapport en attente de validation">
            <div class="d-flex align-items-center mt-1">
                <h1 class="m-0">
                    <i class="fas fa-pause-circle img-fluid mx-3 mt-1 text-warning"></i>
                </h1>
                <div>
                    <div class="text-black-50">En attente</div>
                    <div class="font-weight-bold display-4 py-1 text-black">
                        <span [countUp]="874"></span>
                    </div>
                    <div class="text-black-50 font-size-sm">
                        <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1 text-warning"></fa-icon>
                        10%
                    </div>
                </div>
            </div>
        </a>
    </div>
</div>

<div class="card card-box border-0">
    <div class="card-header p-3">
        <div class="card-header--title">
            <h6 class="font-weight-bold m-0">Liste des rapports journaliers</h6>
        </div>
        <div class="card-header--actions">
            <div class="btn-group" dropdown>
                <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret mr-2"
                    dropdownToggle
                    tooltip="Action"
                    type="button">
                    <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                </button>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                    <ul class="nav nav-pills flex-column p-3">
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="d-flex justify-content-between p-3">
            <input type="searche" class="form-control" style="width: 15em;" placeholder="Recherche dans la liste">
            <button class="btn btn-success" tooltip="Créer le rapport" (click)="newRapportModal.show()">
                <i class="fas fa-plus-circle mr-1"></i>Ajouter
            </button>
        </div>
        <div class="table-responsive m-0">
            <table class="table table-hover text-nowrap m-0">
                <thead>
                    <tr>
                        <th>Référence</th>
                        <th>Travaux</th>
                        <th>Validateur</th>
                        <th>état</th>
                        <th>Créer par</th>
                        <th>Date de creation</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>RP0001</td>
                        <td>SECOND OEUVRE</td>
                        <td><span class="badge bg-neutral-first text-first">Kouassi ghislain</span></td>
                        <td><span class="badge bg-neutral-warning text-warning">En attente</span></td>
                        <td><span class="badge bg-neutral-first text-first">Kouassi ghislain</span></td>
                        <td><span class="badge bg-neutral-dark text-dark">11/11/2021 11:11:11</span></td>
                        <td>
                            <button [routerLink]="['/pages/rapport/detail/1']" class="btn d-40 p-0 btn-pill mr-2 btn-primary" placement="auto" tooltip="Plus d'infos" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                            </button>
                            <button class="btn d-40 p-0 btn-pill mr-2 btn-info" placement="auto" tooltip="Modifier" type="button">
                                <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                            </button>
                            <button class="btn d-40 p-0 btn-pill mr-2 btn-success" placement="auto" tooltip="Valider" type="button">
                                <span class="btn-wrapper--icon"><i class="fas fa-check"></i></span>
                            </button>
                            <button class="btn d-40 p-0 btn-pill mr-2 btn-warning" placement="auto" tooltip="Imprimer" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                            </button>
                            <button  placement="auto"  tooltip="Supprimer"  class="btn d-40 p-0 btn-pill btn-danger" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>RP0001</td>
                        <td>SECOND OEUVRE</td>
                        <td><span class="badge bg-neutral-first text-first">Kouassi ghislain</span></td>
                        <td><span class="badge bg-neutral-success text-success">Valider</span></td>
                        <td><span class="badge bg-neutral-first text-first">Kouassi ghislain</span></td>
                        <td><span class="badge bg-neutral-dark text-dark">11/11/2021 11:11:11</span></td>
                        <td>
                            <button [routerLink]="['/pages/rapport/detail/1']" class="btn d-40 p-0 btn-pill mr-2 btn-primary" placement="auto" tooltip="Plus d'infos" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                            </button>
                            <button class="btn d-40 p-0 btn-pill btn-warning" placement="auto" tooltip="Imprimer" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer p-3">
        <div class="d-flex justify-content-between">
            <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
            <div class="d-flex align-items-center">
                <span>Show</span>
                <select class="mx-1 form-control form-control-sm" id="" name="">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="40">50</option>
                    <option value="40">100</option>
                </select>
                <span>entries</span>
            </div>
        </div>
    </div>
</div>

<!-- Modal de création de rapport de visite de chantier -->
<div #newRapportModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Formulaire de création de rapport</h6>
                <button type="button" class="close" aria-label="Close" (click)="newRapportModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div>
                    <app-rapport-edit></app-rapport-edit>
                </div>
            </div>
        </div>
    </div>
</div>