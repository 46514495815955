import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { stringify } from 'querystring';
import { BehaviorSubject,throwError } from 'rxjs';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

interface typeRessourceData {
  id?: string,
  libelle: string,
}

@Injectable({
  providedIn: 'root'
})
export class TypeRessourceService {

  // private _bureauEtudes = new BehaviorSubject<CategorieRessource[]>([]);
  apiUrl = environment.apiUrl;
  prefix = environment.prefix;

  constructor(private http:HttpClient) { }

  // get bureauEtudes(){
  //   return this._bureauEtudes.asObservable();
  // }

  listTypeRessource(){
    return this.http.get<typeRessourceData[]>(
      `${this.apiUrl}/type_ressources`,{
        responseType: 'json'
      }
    ).pipe(
      map((response: any) => response['hydra:member']),
      catchError(this.handleError)
    )
  }

  addTypeRessource(data:typeRessourceData){
    let responseData:typeRessourceData;
        return this.http.post(`${this.apiUrl}/type_ressources`,JSON.stringify(data)).pipe(
        tap( resData => {      
          return resData
        }),
        catchError(this.handleError)
      );//pipe   
  }

  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "Une erreur est survenue";
    return throwError(messageError);
  }
}
