import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommandeDetailComponent } from './commande-detail/commande-detail.component';
import { CommandeListComponent } from './commande-list/commande-list.component';
import { CommandePagesComponent } from './commande-pages.component';


const routes: Routes = [
  {
    path:'',
    component: CommandePagesComponent,
    children: [
      {
        path: "liste",
        component: CommandeListComponent
      },
      {
        path: "detail/:id",
        component: CommandeDetailComponent
      },

    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommandePagesRoutingModule { }
