<div class="app-sidebar--footer">
    <ul>
        <li>
            <a routerLink="/applications-projects" routerLinkActive="active" class="btn btn-sm btn-transition-none px-2 mx-2" tooltip="Projects Application">
                <i-feather name="activity"></i-feather>
            </a>
        </li>
        <li>
            <a routerLink="/dashboard-helpdesk" routerLinkActive="active" class="btn btn-sm btn-transition-none px-2 mx-2" tooltip="Helpdesk Dashboard">
                <i-feather name="bell"></i-feather>
            </a>
        </li>
        <li>
            <a routerLink="/applications-calendar" routerLinkActive="active" class="btn btn-sm btn-transition-none px-2 mx-2" tooltip="Calendar Application">
                <i-feather name="calendar"></i-feather>
            </a>
        </li>
    </ul>
</div>
