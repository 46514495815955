import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-remarque-list',
  templateUrl: './remarque-list.component.html',
  styleUrls: ['./remarque-list.component.scss']
})
export class RemarqueListComponent implements OnInit {
  // Tout les variables ou tableau ayants pour prefix "default" servent a faire des duplication dans le template.
  defaultRemarque = [
    {
      id: 1,
      libelle: "Remarque 1",
      etat: "valider"
    },
    {
      id: 2,
      libelle: "Remarque 2",
      etat: "en attente"
    },
    {
      id: 3,
      libelle: "Remarque 3",
      etat: "non valider"
    },
    {
      id: 4,
      libelle: "Remarque 4",
      etat: "valider"
    },
  ];

  constructor() { }

  ngOnInit() {
  }

}
