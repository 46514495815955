import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChantierPagesRoutingModule } from './chantier-pages-routing.module';
import { ChantierDetailComponent } from './chantier-detail/chantier-detail.component';
import { ChantierEditComponent } from './chantier-edit/chantier-edit.component';
import { ChantierListComponent } from './chantier-list/chantier-list.component';

import { RouterModule} from '@angular/router';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebook, faTwitter, faAngular, faVuejs, faReact, faHtml5, faGoogle, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub } from '@fortawesome/free-brands-svg-icons';
import { FeatherModule } from 'angular-feather';
import { faChevronRight, faSitemap, faPrint, faMapMarkerAlt, faTachometerAlt, faAlignCenter, faExternalLinkAlt, faShareSquare, faInfoCircle, faSync, faQuoteRight, faStarHalfAlt, faShapes, faCarBattery, faTable, faCubes, faPager,  faCameraRetro, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faUnlockAlt, faDownload, faAward, faPlayCircle, faReply, faUpload, faBars, faEllipsisV, faSave, faSlidersH, faCaretRight, faChevronUp, faPlus, faLemon, faChevronLeft, faTimes, faChevronDown, faFilm, faSearch, faEllipsisH, faCog, faArrowsAltH, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLink } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faTimesCircle, faDotCircle, faThumbsUp, faComments, faFolderOpen, faTrashAlt, faFileImage, faFileArchive, faLifeRing, faCommentDots, faFolder, faKeyboard, faCalendarAlt, faEnvelope, faAddressCard, faMap, faObjectGroup, faImages, faUser, faLightbulb, faGem, faClock, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar } from '@fortawesome/free-regular-svg-icons';
import { TabsModule, ModalModule, TooltipModule, PaginationModule, ProgressbarModule, PopoverModule, BsDatepickerModule, AccordionModule, TypeaheadModule } from 'ngx-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import {GaugeModule} from 'angular-gauge';
import {NgxGaugeModule} from 'ngx-gauge';
import { BsDropdownModule } from 'ngx-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { VisitePagesModule } from '../visite-pages/visite-pages.module';
import { SituationHsePagesModule } from '../situation-hse-pages/situation-hse-pages.module';
import { RapportPagesModule } from '../rapport-pages/rapport-pages.module';
import { ChantierPagesComponent } from './chantier-pages.component';
import { TravauxPagesModule } from '../travaux-pages/travaux-pages/travaux-pages.module';


@NgModule({
  declarations: [
    ChantierPagesComponent,
    ChantierDetailComponent,
    ChantierEditComponent,
    ChantierListComponent
  ],
  imports: [
    CommonModule,
    ChantierPagesRoutingModule,
    RouterModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule,
    FeatherModule,
    ProgressbarModule,
    NgApexchartsModule,
    NgxGaugeModule,
    NgSelectModule,
    GaugeModule,
    ModalModule,
    TooltipModule,
    TypeaheadModule,
    TabsModule,
    TextMaskModule,
    BsDropdownModule,
    BsDatepickerModule,
    PaginationModule,
    AccordionModule,
    HttpClientModule,
    
    TravauxPagesModule,
    VisitePagesModule,
    SituationHsePagesModule,
    RapportPagesModule,
  ],
  exports:[
    ChantierDetailComponent,
    ChantierEditComponent,
    ChantierListComponent
  ]
})
export class ChantierPagesModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFacebook, faPrint, faAlignCenter, faMapMarkerAlt, faTachometerAlt, faExternalLinkAlt, faShareSquare, faSitemap, faInfoCircle, faLifeRing, faTwitter, faQuoteRight, faStarHalfAlt, faSync, faShapes, faCarBattery, faTable, faCubes, faPager,  faAngular, faVuejs, faReact, faHtml5, faCheckCircle, faTimesCircle, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faThumbsUp, faCameraRetro, faUnlockAlt, faDownload, faUpload, faReply, faGoogle, faFileImage, faFolderOpen, faBars, faTrashAlt, faSave, faPlayCircle, faEllipsisV, faEllipsisH, faSlidersH, faFileArchive, faAward, faCaretRight, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub, faPlus, faFolder, faTimes, faEnvelope, faAddressCard, faMap, faCalendarAlt, faImages, faFilm, faClock, faSearch, faChevronRight, faChevronUp, faChevronLeft, faChevronDown, faLink, faLightbulb, faGem, faCog, faDotCircle, faArrowsAltH, faComments, faCommentDots, faKeyboard, faObjectGroup, faUser, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLemon);
 }
}
