import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ChartDataSets, ChartOptions } from 'chart.js';
import {Color} from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { ChartType } from 'ng-apexcharts';
import { Label } from 'ng2-charts';
import { RessourcePages } from 'src/app/models/ressource-pages';
import { RessourcePagesService } from 'src/app/services/ressource-pages/ressource-pages.service';
import { ThemeOptions } from 'src/app/theme-options';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilOptions } from 'src/app/shared-components/util/util-option';

@Component({
  selector: 'app-ressource-detail',
  templateUrl: './ressource-detail.component.html',
  styleUrls: ['./ressource-detail.component.scss']
})
export class RessourceDetailComponent implements OnInit {
  ressource: RessourcePages = <RessourcePages>{};
  value:any;
  inputTrx: boolean = false;
  addedProject: boolean = false;
  addPrice: boolean = false;
  list: any;
  status1 = false;
  rotate = true;
  rating1 = 3;

  public mask: Array<string | RegExp>;
  public maskDate: Array<string | RegExp>;
  public maskPhone: Array<string | RegExp>;

  submit: boolean = false;
  required = UtilOptions.required;
  states: string[] = UtilOptions.pays;
  selected: string;

  etatForm: FormGroup;
  clientForm: FormGroup;
  remiseForm: FormGroup;
  fournisseurForm: FormGroup;

  //Jours
  joursRow = [
    { label: "Lundi", value: "Lundi" },
    { label: "Mardi", value: "Mardi" },
    { label: "Mercredi", value: "Mercredi" },
    { label: "Jeudi", value: "Jeudi" },
    { label: "Vendredi", value: "Vendredi" },
    { label: "Samedi", value: "Samedi" },
    { label: "Dimanche", value: "Dimanche" }
  ]
  // Mois
  moisRow = [
    { label: "Janvier", value: "Janvier" },
    { label: "Fevrier", value: "Fevrier" },
    { label: "Mars", value: "Mars" },
    { label: "Avril", value: "Avril" },
    { label: "Mai", value: "Mai" },
    { label: "Juin", value: "Juin" },
    { label: "Juillet", value: "Juillet" },
    { label: "Aôut", value: "Aôut" },
    { label: "Septembre", value: "Septembre" },
    { label: "Octobre", value: "Octobre" },
    { label: "Novembre", value: "Novembre" },
    { label: "Decembre", value: "Decembre" }
  ]
  // Années
  anneeRow = [
    { label: "1990", value: "1990" },
    { label: "1991", value: "1991" },
    { label: "1992", value: "1992" },
    { label: "1993", value: "1993" },
    { label: "1994", value: "1994" },
    { label: "1995", value: "1995" },
    { label: "1996", value: "1996" },
    { label: "1997", value: "1997" },
    { label: "1998", value: "1998" },
    { label: "1999", value: "1999" },
    { label: "2000", value: "2000" },
    { label: "2001", value: "2001" },
    { label: "2002", value: "2002" },
    { label: "2003", value: "2003" },
    { label: "2004", value: "2004" },
    { label: "2005", value: "2005" },
    { label: "2006", value: "2006" },
    { label: "2007", value: "2007" },
    { label: "2008", value: "2008" },
    { label: "2009", value: "2009" },
    { label: "2010", value: "2010" },
    { label: "2011", value: "2011" },
    { label: "2012", value: "2012" },
    { label: "2013", value: "2013" },
    { label: "2014", value: "2014" },
    { label: "2015", value: "2015" },
    { label: "2016", value: "2016" },
    { label: "2017", value: "2017" },
    { label: "2018", value: "2018" },
    { label: "2019", value: "2019" },
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
  ]

  //Editor
  htmlContent = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(
    public globals: ThemeOptions,
    private route: ActivatedRoute,
    private ressourceService: RessourcePagesService,
    private formBuild: FormBuilder
  ) {
    this.maskDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    this.maskPhone = ['(', '+', '2', '2', '5', ')', ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
    const id: number = +this.route.snapshot.paramMap.get('id');
    this.ressourceService.getList().subscribe((ressources: RessourcePages[]) => {
      this.ressource = ressources.find(ressource => ressource.id == id);
    });
  }

  addProjec() {
    this.addedProject = true;
  }
  cancelAdded() {
    this.addedProject = false;
  }

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    { data: [65, -59, 80, -81, 56, 55, -9, 45, 65, 23, 55, 44], label: 'Prix'},
  ];

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public randomize(): void {
    // Only Change 3 values
    const data = [
      Math.round(Math.random() * 100), 59, 80,
      (Math.random() * 100), 56,
      (Math.random() * 100), 40];
    this.barChartData[0].data = data;
  }

  // chart de la quantité de demande de prix
  gaugeValues: any = {
    1: 100,
    2: 50,
    3: 50
  };

  interval: any;

  ngOnInit(): void {
    const updateValues = (): void => {
      this.gaugeValues = {
        1: Math.round(Math.random() * 100),
        2: Math.round(Math.random() * 100),
        3: Math.round(Math.random() * 100)
      };
      this.etatFormGroup();
      this.clientFormGroup();
      this.remiseFormGroup();
    };

    const INTERVAL: number = 5000;

    this.interval = setInterval(updateValues, INTERVAL);
    updateValues();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  etatFormGroup() {
    this.etatForm = this.formBuild.group({
      etat: [null, [Validators.required]]
    });
  };
  clientFormGroup() {
    this.clientForm = this.formBuild.group({
      nom: [null, [Validators.required]],
      prenom: [null, [Validators.required]],
      fonction: [null, [Validators.required]],
      pays: [null, [Validators.required]],
      contact: [null, [Validators.required]],
      email: [null, [Validators.required]]
    });
  };
  fournisseurFormGroup() {
    this.fournisseurForm = this.formBuild.group({
      nom: [null, [Validators.required]],
      prenom: [null, [Validators.required]],
      fonction: [null, [Validators.required]],
      pays: [null, [Validators.required]],
      contact: [null, [Validators.required]],
      email: [null, [Validators.required]]
    });
  };
  remiseFormGroup() {
    this.remiseForm = this.formBuild.group({
      remise: [null, [Validators.required]]
    });
  };

  get etatF() { return this.etatForm.controls; }
  get clientF() { return this.clientForm.controls; }
  get remiseF() { return this.remiseForm.controls; }
  get fournisseurF() { return this.fournisseurForm.controls; }

  addEtat(){};
  addClient(){};
  addRemise(){};
  addFournisseur(){};

  // Sparklines datasets

  public sparklineDataset1 = [
    {
      data: [65, 59, 80, 81, 55, 38, 59, 80, 46],
      datalabels: {
        display: false,
      },

    }
  ];
  public sparklineDataset2 = [
    {
      data: [80, 81, 55, 65, 38, 53, 59, 77, 48],
      datalabels: {
        display: false,
      },

    }
  ];

  // Graphe de valeur financiere
  public sparklineLabels1 = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'];
  public dangerSparklineColor: Color[] = [
    {
      backgroundColor: 'rgba(248, 50, 69, 0.1)',
      borderCapStyle: 'round',
      borderDash: [],
      borderWidth: 2,
      borderColor: '#f83245',
      borderDashOffset: 0.0,
      borderJoinStyle: 'round',
      pointBorderColor: '#f83245',
      pointBackgroundColor: '#ffffff',
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#f83245',
      pointHoverBorderWidth: 0,
      pointRadius: 0,
      pointHitRadius: 0
    },
  ];
  public successSparklineColor: Color[] = [
    {
      backgroundColor: 'rgba(27, 201, 67, 0.1)',
      borderCapStyle: 'round',
      borderDash: [],
      borderWidth: 2,
      borderColor: '#1bc943',
      borderDashOffset: 0.0,
      borderJoinStyle: 'round',
      pointBorderColor: '#1bc943',
      pointBackgroundColor: '#ffffff',
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#1bc943',
      pointHoverBorderWidth: 0,
      pointRadius: 0,
      pointHitRadius: 0
    },
  ];
  public sparklineOptions1 = {
    layout: {
      padding: {
        left: -10,
        right: 0,
        top: 0,
        bottom: -10
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          display: false,
          beginAtZero: true
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }],
      xAxes: [{
        ticks: {
          display: false,
          beginAtZero: true
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }]
    },
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false
  };

}
