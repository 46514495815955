import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-badge-cell-view',
  templateUrl: './badge-cell-view.component.html',
  styleUrls: ['./badge-cell-view.component.scss']
})
export class BadgeCellViewComponent implements OnInit {

  @Input()
  column: any;

  @Input()
  cell_value: string;
  constructor() { }

  ngOnInit() {
  }

}
