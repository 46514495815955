<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first">
        <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
            <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                <i class="pe-7s-wallet display-3 gradient-icon bg-ripe-malin"></i>
            </div>
        </div>
        <div class="app-page-title--heading">
            <h1>Détails des opportunités </h1>
            <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">
               {{opportunite?.projet}}
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center">
        <div class="card card-box">
            <div class="card-indicator bg-success"></div>
            <div class="card-body px-4 py-3">
                <div class="d-flex justify-content-between">
                    <a [routerLink]="">Attribution du projet</a>
                </div>
                <div class="d-flex align-items-center justify-content-start">
                    <div class="badge badge-{{opportunite?.etat == 'nouveaux' ? 'first': opportunite?.etat == 'qualifiée' ? 'info': opportunite?.etat == 'proposition' ? 'warning': opportunite?.etat == 'gagnée' ? 'success': 'dark'}} px-3">{{opportunite?.etat}}</div>
                </div>
            </div>
        </div>
        <div class="btn-group mx-3" dropdown>
            <button class="btn btn-primary dropdown-toggle" dropdownToggle type="button">
                <span class="btn-wrapper--label pr-2">Actions</span>
            </button>
            <div *dropdownMenu class="dropdown-menu dropdown-menu-xl p-0 dropdown-menu-right">
                <ul class="nav nav-neutral-first nav-pills flex-column pt-1 pb-2">
                    <li class="nav-item">
                        <a [routerLink]="" class="nav-link mx-3 mt-2">
                            <span><i class="ion-md-document mr-2"></i> Nouveau</span>
                            <span class="ml-auto" *ngIf="opportunite?.etat == 'nouveaux'">
                                <fa-icon [icon]="['far', 'check-circle']" class="text-success"></fa-icon>
                            </span>
                        </a>
                    </li>
                    <li class="dropdown-divider"></li>
                    <li class="nav-item">
                        <a [routerLink]="" class="nav-link mx-3">
                            <span><i class="fas fa-clipboard-check mr-2"></i> Proposition</span>
                            <span class="ml-auto" *ngIf="opportunite?.etat == 'proposition'">
                                <fa-icon [icon]="['far', 'check-circle']" class="text-success"></fa-icon>
                            </span>
                        </a>
                    </li>
                    <li class="dropdown-divider"></li>
                    <li class="nav-item">
                        <a [routerLink]="" class="nav-link mx-3">
                            <span><i class="fas fa-file-signature mr-2"></i> Qualifiée</span>
                            <span class="ml-auto" *ngIf="opportunite?.etat == 'qualifiée'">
                                <fa-icon [icon]="['far', 'check-circle']" class="text-success"></fa-icon>
                            </span>
                        </a>
                    </li>
                    <li class="dropdown-divider"></li>
                    <li class="nav-item">
                        <a [routerLink]="" class="nav-link mx-3">
                            <span><i class="ion-md-done-all mr-2"></i> Gagnée</span>
                            <span class="ml-auto" *ngIf="opportunite?.etat == 'gagnée'">
                                <fa-icon [icon]="['far', 'check-circle']" class="text-success"></fa-icon>
                            </span>
                        </a>
                    </li>
                    <li class="dropdown-divider"></li>
                    <li (click)="rapportOpportunitePerduModal.show()" class="nav-item">
                        <a [routerLink]="" class="nav-link mx-3">
                            <span><fa-icon [icon]="['fas', 'times']" class="mr-2"></fa-icon> Perdue</span>
                            <span class="ml-auto" *ngIf="opportunite?.etat == 'perdue'">
                                <fa-icon [icon]="['far', 'check-circle']" class="text-success"></fa-icon>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="button">
            <button [routerLink]="['/pages/opportunite/liste']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<progressbar [value]="72" class="progress-bar-rounded-sm mb-3" type="first">72%</progressbar>

<accordion [closeOthers]="true" [isAnimated]="true" class="accordion mb-4">
    <accordion-group class="card card-box">
        <button accordion-heading class="btn btn-link btn-lg d-flex align-items-center justify-content-between"
                type="button">
            <span>Cliquer pour dérouler</span>
            <fa-icon [icon]="['fas', 'angle-up']" class="font-size-xl"></fa-icon>
        </button>
        <div class="border rounded m-3 p-1">
            <div class="row">
                <div class="col-md-6">
                    <p class="mt-2 mb-0"><b>Numéro d'ordre:</b> <span class="ml-2">{{opportunite?.numOrdre}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Numéro de dossier:</b> <span class="ml-2">{{opportunite?.numDossier}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Numéro appel d'offre:</b> <span class="ml-2">{{opportunite?.numAppelOffre}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Titre du projet:</b> <span class="ml-2">{{opportunite?.projet}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Maitre d' ouvrage:</b> <span class="ml-2">{{opportunite?.maitreOuvrage}}</span></p>
                    <hr class="my-2">
                    <p class="m-0">
                        <b>État:</b>
                        <span class="ml-2 badge bg-{{
                            opportunite?.etat == 'nouveaux' ? 'neutral-first text-first' : 
                            opportunite?.etat == 'qualifiée' ? 'neutral-info text-info' : 
                            opportunite?.etat == 'proposition' ? 'neutral-warning text-warning' : 
                            opportunite?.etat == 'gagnée' ? 'neutral-success text-success': 'neutral-dark text-dark'}}">{{opportunite?.etat}}
                        </span>
                    </p>
                    <hr class="my-2">
                    <p class="m-0"><b>Bailleur:</b> <span class="ml-2">{{opportunite?.bailleur}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Responsable de suivi:</b> <span class="ml-2">{{opportunite?.respoSuivi}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Personne à notifier:</b> <span class="ml-2">{{opportunite?.personneNotifer}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de lancement:</b> <span class="ml-2">{{opportunite?.dateLancement}}</span></p>
                </div>
                <div class="col-md-6">
                    <p class="mt-2 mb-0"><b>Date limite de dépot:</b> <span class="ml-2">{{opportunite?.dateLimiteDossier}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date d'ouverture de plis:</b> <span class="ml-2">{{opportunite?.dateOuverturePlis}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Délais d'execution:</b> <span class="ml-2">{{opportunite?.delaisExecution}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Groupement:</b> <span class="ml-2">{{opportunite?.groupement}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Adresse autorité contratante:</b> <span class="ml-2">{{opportunite?.addAutorite}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Créer par:</b> <span class="ml-2">{{opportunite?.creerPar}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de création:</b> <span class="ml-2">{{opportunite?.dateCreation}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Modifier par:</b> <span class="ml-2">{{opportunite?.modifierPar}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de modification:</b> <span class="ml-2">{{opportunite?.dateModification}}</span></p>
                </div>
                <div class="col-md-12">
                    <div class="bg-secondary p-2 text-right">
                        <h1 class="mb-0">Montant: <span class="text-info mx-3">{{opportunite?.montant | number:'': 'fr-FR'}}</span><span>XOF</span></h1>
                    </div>
                </div>
            </div>
        </div>
    </accordion-group>
</accordion>

<div class="mb-4">
    <tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow" type="tabs" >
        <!-- Tableau de bord -->
        <tab customClass="pl-0 pr-1 py-2">
            <ng-template tabHeading><span><i class="fas fa-tachometer-alt"></i> Tableau de bord</span></ng-template>
            <!-- Les 12 cards de depart -->
            <div class="row mb-3">
                <div class="col-xl-4 mb-3">
                    <div class="card rounded text-left p-4">
                        <div class="d-flex align-items-center">
                            <div class="d-70 rounded-circle bg-success text-white mr-3 text-center">
                                <i class="fas fa-funnel-dollar font-size-lg"></i>
                            </div>
                            <div class="flex-grow-1">
                                <div class="text-black-50 pb-1">Montant opportunité</div>
                                <div><b class="badge badge-first mr-2">500 000 000 000</b><span class="text-dark-50">XOF</span></div>
                            </div>
                            <div class="font-size-sm opacity-5">
                                <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 mb-3">
                    <div class="card rounded text-left p-4">
                        <div class="d-flex align-items-center">
                            <div class="d-70 rounded-circle bg-warning text-white mr-3 text-center">
                                <fa-icon [icon]="['far', 'calendar-alt']" class="font-size-lg"></fa-icon>
                            </div>
                            <div class="flex-grow-1">
                                <div class="text-black-50 pb-1">Délais d'execution</div>
                                <div class="text-warning">6 mois</div>
                            </div>
                            <div class="font-size-sm opacity-5">
                                <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 mb-3">
                    <div class="card rounded text-left p-4">
                        <div class="d-flex align-items-center">
                            <div class="d-70 rounded-circle bg-neutral-first text-first mr-3 text-center">
                                <fa-icon [icon]="['far', 'lightbulb']" class="font-size-lg"></fa-icon>
                            </div>
                            <div class="flex-grow-1">
                                <div class="text-black-50 pb-1">État opportunité</div>
                                <div class="text-danger">
                                    <div class="badge badge-success mr-1">terminer</div>
                                    Il y a 2 jours
                                </div>
                            </div>
                            <div class="font-size-sm opacity-5">
                                <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-l-6 col-xl-4 mb-3">
                    <a [routerLink]="" class="card btn rounded text-left p-3">
                        <div class="text-black-50 pb-2">Nombre de tâche</div>
                        <div class="d-flex justify-content-between align-items-end">
                            <h3 class="display-4 mb-0 text-black">
                                <small class="pr-1 text-black-50">
                                    <i class="fas fa-tasks"></i>
                                </small>
                                <span [countUp]="45" class="text-info"></span>
                            </h3>
                        </div>
                    </a>
                </div>
                <div class="col-l-6 col-xl-4 mb-3">
                    <a [routerLink]="" class="card btn rounded text-left p-3">
                        <div class="text-black-50 pb-2">Tâche (s) en cours</div>
                        <div class="d-flex justify-content-between align-items-end">
                            <h3 class="display-4 mb-0 text-black">
                                <small class="pr-1 text-black-50">
                                    <fa-icon [icon]="['far', 'dot-circle']"></fa-icon>
                                </small>
                                <span [countUp]="10" class="text-first"></span>
                            </h3>
                            <div class="text-warning font-weight-bold">12%</div>
                        </div>
                    </a>
                </div>
                <div class="col-l-6 col-xl-4 mb-3">
                    <a [routerLink]="" class="card btn rounded text-left p-3">
                        <div class="text-black-50 pb-2">Tâche (s) en attente de réalisation</div>
                        <div class="d-flex justify-content-between align-items-end">
                            <h3 class="display-4 mb-0">
                                <small class="pr-1 text-first">
                                    <i class="fas fa-stop-circle"></i>
                                </small>
                                <span [countUp]="15" class="text-warning"></span>
                            </h3>
                            <div class="text-first font-weight-bold">20%</div>
                        </div>
                    </a>
                </div>
                <div class="col-md-6 col-xl-4 mb-3">
                    <a [routerLink]="" class="card btn rounded text-left p-3">
                        <div class="text-dark-50 pb-2">Tâche (s) terminer</div>
                        <div class="d-flex justify-content-between align-items-end">
                            <h3 class="display-4 mb-0">
                                <small class="pr-1">
                                    <fa-icon [icon]="['far', 'check-circle']" class="text-success"></fa-icon>
                                </small>
                                <span [countUp]="30" class="text-dark-50">45</span>
                            </h3>
                            <div class="text-second font-weight-bold">59%</div>
                        </div>
                    </a>
                </div>
                <div class="col-md-6 col-xl-4 mb-3">
                    <a [routerLink]="" class="card btn rounded text-left p-3 bg-night-sky">
                        <div class="text-white-50 pb-2">Tâche (s) en retard</div>
                        <div class="d-flex justify-content-between align-items-end">
                            <h3 class="display-4 mb-0">
                                <small class="pr-1 text-white-50">
                                    <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>
                                </small>
                                <span [countUp]="15" class="text-warning"></span>
                            </h3>
                        </div>
                    </a>
                </div>
                <div class="col-md-6 col-xl-4 mb-3">
                    <a [routerLink]="" class="card btn rounded text-left p-3 bg-vicious-stance">
                        <div class="text-white-50 pb-2">Temps consommés</div>
                        <div class="d-flex justify-content-between align-items-end">
                            <h3 class="display-4 mb-0">
                                <small class="pr-1 text-white-50">
                                    <fa-icon [icon]="['far', 'clock']"></fa-icon>
                                </small>
                                <span [countUp]="30" class="text-white"></span>
                                <span class="text-white-50 ml-2">Jours</span>
                            </h3>
                        </div>
                    </a>
                </div>
            </div>
            <!-- Fin des 12 cards de depart -->

            <!-- Graphe et time-line -->
            <div class="row mb-4">
                <div class="col-xl-8 mb-2">
                    <div class="card card-box" style="height: 500px;">
                        <div class="card-header">
                            <div class="card-header--title font-weight-bold">Progression des tâches</div>
                        </div>
                        <div class="card-body p-3">
                            <apx-chart
                                [chart]="{type: 'bar', height: '400'}"
                                [colors]="['#4191ff', '#f83245']"
                                [plotOptions]="{bar: {horizontal: true}}"
                                [dataLabels]="{enabled: false}"
                                [fill]="{opacity: 0.85, colors: ['#4191ff', '#4191ff']}"
                                [grid]="{strokeDashArray: '5', borderColor: 'rgba(125, 138, 156, 0.3)'}"
                                [series]="[{
                                    data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
                                }]"
                                [xaxis]="{categories: ['Tâche 1', 'Tâche 2', 'Tâche 3', 'Tâche 4', 'Tâche 5', 'Tâche 6', 'Tâche 7', 'Tâche 8', 'Tâche 9', 'Tâche 10']}"
                            ></apx-chart>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 mb-2">
                    <div class="card card-box" style="height: 500px;">
                        <div class="card-header">
                            <div class="card-header--title font-weight-bold">Evenement recent de l'évolution des tâche</div>
                        </div>
                        <div class="card-body p-3">
                            <perfect-scrollbar [autoPropagation]="true" class="scroll-area-lg shadow-overflow">
                                <div class="p-4">
                                    <div class="timeline-list mb-0">
                                        <div class="timeline-item timeline-item-icon">
                                            <div class="timeline-item--content">
                                                <div class="timeline-item--icon-wrapper bg-primary text-white">
                                                    <fa-icon [icon]="['far', 'building']"></fa-icon>
                                                </div>
                                                <h4 class="timeline-item--label mb-2 font-weight-bold">07 Juin 2021 à 18:51:45</h4>
                                                <div class="mt-3 mb-1">
                                                    <a [routerLink]="">
                                                        <img alt="Rounded image" class="img-fluid rounded mr-3 shadow-sm" src="assets/images/stock-photos/people-3.jpg"  width="70">
                                                    </a>
                                                </div>
                                                <p>
                                                    The World Wide Web goes live with its first web page.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="timeline-item timeline-item-icon">
                                            <div class="timeline-item--content">
                                                <div class="timeline-item--icon-wrapper bg-danger text-white">
                                                    <fa-icon [icon]="['far', 'gem']"></fa-icon>
                                                </div>
                                                <h4 class="timeline-item--label mb-2 font-weight-bold">07 Juin 2021 à 18:51:45</h4>
                                                <p>
                                                    Bill Clinton's presidential scandal makes it online.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="timeline-item">
                                            <div class="timeline-item--content">
                                                <div class="timeline-item--icon"></div>
                                                <h4 class="timeline-item--label mb-2 font-weight-bold">07 Juin 2021 à 18:51:45</h4>
                                                <p>
                                                    The World Wide Web goes live with its first web page.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="timeline-item">
                                            <div class="timeline-item--content">
                                                <div class="timeline-item--icon"></div>
                                                <h4 class="timeline-item--label mb-2 font-weight-bold">07 Juin 2021 à 18:51:45</h4>
                                                <div class="mt-3 mb-1">
                                                    <a [routerLink]="">
                                                        <img alt="Rounded image" class="img-fluid rounded mr-3 shadow-sm" src="assets/images/stock-photos/people-3.jpg"  width="70">
                                                    </a>
                                                    <a [routerLink]="">
                                                        <img alt="Rounded image" class="img-fluid rounded shadow-sm" src="assets/images/stock-photos/people-2.jpg" width="70">
                                                    </a>
                                                </div>
                                                <p>
                                                    Bill Clinton's presidential scandal makes it online.
                                                </p>
                                                <div class="avatar-wrapper-overlap mt-2 mb-1">
                                                    <div class="avatar-icon-wrapper avatar-icon-sm">
                                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                                    </div>
                                                    <div class="avatar-icon-wrapper avatar-icon-sm">
                                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                                    </div>
                                                    <div class="avatar-icon-wrapper avatar-icon-sm">
                                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                                    </div>
                                                    <div class="avatar-icon-wrapper avatar-icon-sm">
                                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                                                    </div>
                                                    <div class="avatar-icon-wrapper avatar-icon-sm">
                                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar6.jpg"/></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="timeline-item">
                                            <div class="timeline-item--content">
                                                <div class="timeline-item--icon"></div>
                                                <h4 class="timeline-item--label mb-2 font-weight-bold">
                                                    07 Juin 2021 à 18:51:45
                                                    <span class="badge badge-neutral-info text-info ml-2">New</span>
                                                </h4>
                                                <p>
                                                    Mosaic, the first graphical browser, is introduced to the average consumer.
                                                </p>
                                                <div class="mt-3">
                                                    <a [routerLink]="">
                                                        <img alt="Rounded image" class="img-fluid rounded mr-3 shadow-sm" src="assets/images/stock-photos/people-3.jpg"  width="70">
                                                    </a>
                                                    <a [routerLink]="">
                                                        <img alt="Rounded image" class="img-fluid rounded shadow-sm" src="assets/images/stock-photos/people-2.jpg" width="70">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="timeline-item timeline-item-icon">
                                            <div class="timeline-item--content">
                                                <div class="timeline-item--icon-wrapper bg-amy-crisp text-white">
                                                    <fa-icon [icon]="['far', 'keyboard']"></fa-icon>
                                                </div>
                                                <h4 class="timeline-item--label mb-2 font-weight-bold">07 Juin 2021 à 18:51:45</h4>
                                                <p>
                                                    MySpace becomes the most popular social network.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="timeline-item timeline-item-icon">
                                            <div class="timeline-item--content">
                                                <div class="timeline-item--icon-wrapper bg-primary text-white">
                                                    <fa-icon [icon]="['far', 'building']"></fa-icon>
                                                </div>
                                                <h4 class="timeline-item--label mb-2 font-weight-bold">07 Juin 2021 à 18:51:45</h4>
                                                <p>
                                                    The World Wide Web goes live with its first web page.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="timeline-item timeline-item-icon">
                                            <div class="timeline-item--content">
                                                <div class="timeline-item--icon-wrapper bg-danger text-white">
                                                    <fa-icon [icon]="['far', 'gem']"></fa-icon>
                                                </div>
                                                <h4 class="timeline-item--label mb-2 font-weight-bold">07 Juin 2021 à 18:51:45</h4>
                                                <p>
                                                    Bill Clinton's presidential scandal makes it online.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="timeline-item">
                                            <div class="timeline-item--content">
                                                <div class="timeline-item--icon"></div>
                                                <h4 class="timeline-item--label mb-2 font-weight-bold">07 Juin 2021 à 18:51:45</h4>
                                                <p>
                                                    The World Wide Web goes live with its first web page.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="timeline-item">
                                            <div class="timeline-item--content">
                                                <div class="timeline-item--icon"></div>
                                                <h4 class="timeline-item--label mb-2 font-weight-bold">07 Juin 2021 à 18:51:45</h4>
                                                <div class="mt-3 mb-1">
                                                    <a [routerLink]="">
                                                        <img alt="Rounded image" class="img-fluid rounded mr-3 shadow-sm" src="assets/images/stock-photos/people-3.jpg"  width="70">
                                                    </a>
                                                    <a [routerLink]="">
                                                        <img alt="Rounded image" class="img-fluid rounded shadow-sm" src="assets/images/stock-photos/people-2.jpg" width="70">
                                                    </a>
                                                </div>
                                                <p>
                                                    The World Wide Web goes live with its first web page.
                                                </p>
                                                <div class="avatar-wrapper-overlap mt-2 mb-1">
                                                    <div class="avatar-icon-wrapper avatar-icon-sm">
                                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                                    </div>
                                                    <div class="avatar-icon-wrapper avatar-icon-sm">
                                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                                    </div>
                                                    <div class="avatar-icon-wrapper avatar-icon-sm">
                                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                                    </div>
                                                    <div class="avatar-icon-wrapper avatar-icon-sm">
                                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                                                    </div>
                                                    <div class="avatar-icon-wrapper avatar-icon-sm">
                                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar6.jpg"/></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="timeline-item">
                                            <div class="timeline-item--content">
                                                <div class="timeline-item--icon"></div>
                                                <h4 class="timeline-item--label mb-2 font-weight-bold">07 Juin 2021 à 18:51:45</h4>
                                                <div class="mt-3 mb-1">
                                                    <a [routerLink]="">
                                                        <img alt="Rounded image" class="img-fluid rounded mr-3 shadow-sm" src="assets/images/stock-photos/people-3.jpg"  width="70">
                                                    </a>
                                                    <a [routerLink]="">
                                                        <img alt="Rounded image" class="img-fluid rounded shadow-sm" src="assets/images/stock-photos/people-2.jpg" width="70">
                                                    </a>
                                                </div>
                                                <p>
                                                    Bill Clinton's presidential scandal makes it online.
                                                </p>
                                                <div class="avatar-wrapper-overlap mt-2 mb-1">
                                                    <div class="avatar-icon-wrapper avatar-icon-sm">
                                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                                    </div>
                                                    <div class="avatar-icon-wrapper avatar-icon-sm">
                                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                                    </div>
                                                    <div class="avatar-icon-wrapper avatar-icon-sm">
                                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                                    </div>
                                                    <div class="avatar-icon-wrapper avatar-icon-sm">
                                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                                                    </div>
                                                    <div class="avatar-icon-wrapper avatar-icon-sm">
                                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar6.jpg"/></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </perfect-scrollbar>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Fin graphe et time-line -->
        </tab>
        <!-- Fin tableau de bokrd -->

        <!-- Suivi des tache -->
        <tab customClass="pl-0 pr-1 py-2">
            <ng-template tabHeading><span><i class="fas fa-tasks"></i> Suivi des tâches</span></ng-template>
            <div class="card card-box border-0">
              <div class="card-header p-3">
                  <div class="card-header--title font-weight-bold">Liste des tache</div>
                  <div class="card-header--actions">
                      <div class="btn-group" dropdown>
                          <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                              dropdownToggle
                              tooltip="Action"
                              type="button">
                              <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                          </button>
                          <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                              <ul class="nav nav-pills flex-column p-3">
                                  <li class="nav-item">
                                      <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                  </li>
                                  <li class="nav-item">
                                      <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                  </li>
                                  <li class="nav-item">
                                      <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                  </li>
                                  <li class="nav-item">
                                      <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="card-body p-3">
                  <div class="d-flex justify-content-between mb-3">
                      <div class="search-wrapper">
                        <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                        <input class="form-control" placeholder="Recherche dans la liste" type="search">
                      </div>
                      <div>
                        <button class="btn btn-success ml-2" 
                            tooltip="Planifier une activité" type="button" 
                            (click)="planifierActiviteModal.show()" 
                            (click)="showTable(true)"><i class="fas fa-plus-circle"></i> Ajouter
                        </button>
                    </div>
                  </div>
                  <div class="table-responsive m-0">
                    <table class="table table-hover text-nowrap m-0">
                      <thead>
                        <tr>
                            <th>Responsable</th>
                            <th>Tâches</th>
                            <th>Sous tâche</th>
                            <th>Date echéance</th>
                            <th>Status</th>
                            <th>Evolution</th>
                            <th>Dernière relance</th>
                            <th class="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let tache of taches">
                            <td>
                                <div class="d-flex align-items-center">
                                    <div class="avatar-icon-wrapper mr-2"><div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div></div>
                                    <div>
                                        <a [routerLink]="" class="font-weight-bold text-black" title="Shanelle Wynn">{{tache?.responsable}}</a>
                                        <span class="text-black-50 d-block">{{tache?.dateEcheance}}</span>
                                    </div>
                                </div>
                            </td>
                            <td>{{tache?.tache}}</td>
                            <td>{{tache?.sousTache}}</td>
                            <td>
                                <span class="badge m-1 badge-neutral-second text-second">
                                    {{tache?.dateEcheance}}
                                </span>
                            </td>
                            <td>
                                <span class="badge bg-{{
                                        tache?.status == 'en cours' ? 'neutral-first text-first' : 
                                        tache?.status == 'en attente' ? 'neutral-warning text-warning' : 
                                        tache?.status == 'terminée' ? 'neutral-success text-success' : 'neutral-danger text-danger'
                                    }} h-auto py-0 px-3">{{tache?.status}}
                                </span>
                            </td>
                            <td>
                                <progressbar
                                    [value]="tache?.evolution"
                                    class="progress-bar-rounded"
                                    type="{{
                                        tache?.evolution >= 0 && tache?.evolution <= 24 ? 'danger':
                                        tache?.evolution >= 25 && tache?.evolution <= 49 ? 'warning':
                                        tache?.evolution >= 50 && tache?.evolution <= 64 ? 'info':
                                        tache?.evolution >= 65 && tache?.evolution <= 99 ? 'first':'success'
                                    }}"
                                >
                                    {{tache?.evolution}}%
                                </progressbar>
                            </td>
                            <td>
                                <span class="badge m-1 badge-neutral-second text-second">
                                    {{tache?.derniereRelance}}
                                </span>
                            </td>
                            <td class="text-center">
                                <button placement="auto" tooltip="Détail" 
                                    class="btn d-40 p-0 btn-pill mr-1 btn-primary" 
                                    (click)="modalDetailTache.show()" (click)="detaiTache()"
                                    type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                </button>
                                <button placement="auto" tooltip="Rélancer" 
                                    class="btn d-40 p-0 btn-pill mr-1 btn-first" 
                                    (click)="planifierActiviteModal.show()" (click)="showTable(false)"
                                    type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-refresh"></i></span>
                                </button>
                                <button placement="auto" tooltip="Valider la tâche" 
                                    class="btn d-40 p-0 btn-pill btn-success" 
                                    (click)="modalValideTache.show()"
                                    type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-done-all"></i></span>
                                </button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
              <div class="card-footer p-3">
                  <div class="d-flex justify-content-between">
                      <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                      <div class="d-flex align-items-center">
                          <span>Show</span>
                          <select class="mx-1 form-control form-control-sm" id="" name="">
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="30">30</option>
                              <option value="40">40</option>
                              <option value="40">50</option>
                              <option value="40">100</option>
                          </select>
                          <span>entries</span>
                      </div>
                  </div>
              </div>
            </div>
        </tab>
        <!-- Fin suivi des tache -->

        <!-- Gestion des fichiers -->
        <tab customClass="pl-0 pr-1 py-2">
            <ng-template tabHeading><span><i class="fas fa-file"></i> Gestion des fichiers</span></ng-template>
            <div class="row">
                <div class="col-sm-3">
                    <div class="border bg-white">
                        <div class="d-flex bg-secondary d-lg-none p-4 order-0 justify-content-between align-items-center">
                            <button (click)="toggleMobileSidebar1 = !toggleMobileSidebar1" class="btn btn-sm btn-primary px-3" type="button">
                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                            </button>
                            <button (click)="toggleMobileSidebar2 = !toggleMobileSidebar2" class="btn btn-sm btn-primary px-3" type="button">
                                <fa-icon [icon]="['fas', 'bars']"></fa-icon>
                            </button>
                        </div>
                        <div [ngClass]="{'layout-sidebar-open' : toggleMobileSidebar1}" class="app-inner-content-layout--sidebar bg-white app-inner-content-layout--sidebar__sm">
                            <div class="p-4">
                                <button class="btn btn-block btn-sm btn-success" type="button">
                                      <span class="btn-wrapper--icon">
                                          <fa-icon [icon]="['fas', 'upload']"></fa-icon>
                                      </span>
                                    <span class="btn-wrapper--label">Upload</span>
                                </button>
                            </div>
                            <perfect-scrollbar [autoPropagation]="true" class="px-4">
                                <ul class="nav nav-neutral-primary nav-pills flex-column">

                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link active">
                                            <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'file-alt']" class="mx-auto"></fa-icon></span>
                                            Tous les fichiers
                                        </a>
                                    </li>

                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link">
                                            <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'keyboard']" class="mx-auto"></fa-icon></span>
                                            Fichier récents
                                        </a>
                                    </li>

                                    <li class="nav-header text-uppercase font-size-sm text-muted pb-2">
                                        Type de fichier
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link">
                                            <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'file-image']" class="mx-auto"></fa-icon></span>
                                            Images
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link">
                                            <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'file-alt']" class="mx-auto"></fa-icon></span>
                                            Documents
                                        </a>
                                    </li>
                                </ul>
                            </perfect-scrollbar>
                            <div class="app-content--sidebar__footer px-4 py-2">
                                <ul class="nav nav-neutral-primary nav-pills flex-column">
                                    <li class="nav-header text-uppercase font-size-sm text-muted pb-2">
                                        Available storage
                                    </li>
                                    <li class="nav-item">
                                        <div class="d-flex align-items-center">
                                            <div class="display-3">
                                                <fa-icon [icon]="['far', 'object-group']"></fa-icon>
                                            </div>
                                            <div class="pl-3">
                                                <small class="d-block mb-2"><b>5GB</b> used out of <b>25GB</b></small>
                                                <progressbar [animate]="true" [value]="87" class="progress-bar-rounded progress-animated-alt" type="warning">87%</progressbar>
                                            </div>
                                        </div>
                                        <small class="d-block mt-2">
                                                    <span class="d-block pb-2">
                                                        Obtenez <b>10 GB</b> d'espace de stockage avec le forfait premium
                                                    </span>
                                            <a [routerLink]="" class="btn p-0 font-weight-bold font-size-xs btn-link btn-link-first" title="Learn more">Détails</a>
                                        </small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-9">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card card-box mb-5">
                                <div class="card-body">
                                    <div class="card-img-wrapper">
                                        <div class="card-badges card-badges-top">
                                            <button class="btn btn-link p-1 shadow-none btn-link-primary" type="button">
                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                            </button>
                                        </div>
                                        <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                            <fa-icon [icon]="['far', 'file-word']" class="display-2 text-first mx-auto"></fa-icon>
                                        </div>
                                        <a [routerLink]="">
                                            <b>Example_WordFile.doc</b>
                                        </a>
                                        <div>
                                            <small class="d-block text-black-50 pb-2">15.2MB</small>
                                            <small class="opacity-6">
                                                Modified on: <span class="text-black-50">12.06.2020</span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card card-box mb-5">
                                <div class="card-body">
                                    <div class="card-img-wrapper">
                                        <div class="card-badges card-badges-top">
                                            <button class="btn btn-link p-1 shadow-none btn-link-primary" type="button">
                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                            </button>
                                        </div>
                                        <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                            <fa-icon [icon]="['far', 'file-excel']" class="display-2 text-success mx-auto"></fa-icon>
                                        </div>
                                        <a [routerLink]="">
                                            <b>Excel_2002.xls</b>
                                        </a>
                                        <div>
                                            <small class="d-block text-black-50 pb-2">3.1MB</small>
                                            <small class="opacity-6">
                                                Modified: <span class="text-black-50">5 hours ago</span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card card-box mb-5">
                                <div class="card-body">
                                    <div class="card-img-wrapper">
                                        <div class="card-badges card-badges-top">
                                            <button class="btn btn-link p-1 shadow-none btn-link-primary" type="button">
                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                            </button>
                                        </div>
                                        <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                            <fa-icon [icon]="['far', 'file-pdf']" class="display-2 text-danger mx-auto"></fa-icon>
                                        </div>
                                        <a [routerLink]="">
                                            <b>Documents.PDF</b>
                                        </a>
                                        <div>
                                            <small class="d-block text-black-50 pb-2">15.2MB</small>
                                            <small class="opacity-6">
                                                Modified on: <span class="text-black-50">5 January 2000</span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card card-box mb-5">
                                <div class="card-body">
                                    <div class="card-img-wrapper">
                                        <div class="card-badges card-badges-top">
                                            <button class="btn btn-link p-1 shadow-none btn-link-primary" type="button">
                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                            </button>
                                        </div>
                                        <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                            <i class="fas fa-file-csv display-2 text-warning mx-auto"></i>
                                        </div>
                                        <a [routerLink]="">
                                            <b>File.csv</b>
                                        </a>
                                        <div>
                                            <small class="d-block text-black-50 pb-2">15.2MB</small>
                                            <small class="opacity-6">
                                                Modified on: <span class="text-black-50">5 January 2000</span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card card-box mb-5">
                                <div class="card-body">
                                    <div class="card-img-wrapper">
                                        <div class="card-badges card-badges-top">
                                            <button class="btn btn-link p-1 shadow-none btn-link-primary" type="button">
                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                            </button>
                                        </div>
                                        <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                            <fa-icon [icon]="['far', 'file-image']" class="display-2 text-info mx-auto"></fa-icon>
                                        </div>
                                        <a [routerLink]="">
                                            <b>IMG_063037.jpg</b>
                                        </a>
                                        <div>
                                            <small class="d-block text-black-50 pb-2">2.2mb</small>
                                            <small class="opacity-6">
                                                Modified on: <span class="text-black-50">12.06.2020</span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card card-box mb-5">
                                <div class="card-body">
                                    <div class="card-img-wrapper">
                                        <div class="card-badges card-badges-top">
                                            <button class="btn btn-link p-1 shadow-none btn-link-primary" type="button">
                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                            </button>
                                        </div>
                                        <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                            <fa-icon [icon]="['far', 'file-audio']" class="display-2 text-second mx-auto"></fa-icon>
                                        </div>
                                        <a [routerLink]="">
                                            <b>Audio.mp3</b>
                                        </a>
                                        <div>
                                            <small class="d-block text-black-50 pb-2">745,3 kb</small>
                                            <small class="opacity-6">
                                                Modified on: <span class="text-black-50">22.11.2019</span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card card-box mb-5">
                                <div class="card-body">
                                    <div class="card-img-wrapper">
                                        <div class="card-badges card-badges-top">
                                            <button class="btn btn-link p-1 shadow-none btn-link-primary" type="button">
                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                            </button>
                                        </div>
                                        <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                            <fa-icon [icon]="['far', 'file-archive']" class="display-2 text-danger mx-auto"></fa-icon>
                                        </div>
                                        <a [routerLink]="">
                                            <b>AllPhotos.zip</b>
                                        </a>
                                        <div>
                                            <small class="d-block text-black-50 pb-2">15.2MB</small>
                                            <small class="opacity-6">
                                                Modified on: <span class="text-black-50">5 January 2000</span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div (click)="toggleMobileSidebar1 = !toggleMobileSidebar1" [ngClass]="{'active' : toggleMobileSidebar1}" class="sidebar-inner-layout-overlay"></div>
                <div (click)="toggleMobileSidebar2 = !toggleMobileSidebar2" [ngClass]="{'active' : toggleMobileSidebar2}" class="sidebar-inner-layout-overlay"></div>
            </div>
        </tab>
        <!-- Fin gestion des fichiers -->
    </tabset>
</div>

<!-- LES MODALS -->
<!-- Détail des taches -->
<div #modalDetailTache="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Détail de l'activité <b class="text-uppercase">{{uneTache.tache}}</b></h6>
                <button type="button" class="close" aria-label="Close" (click)="modalDetailTache.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="bg-white rounded-left">
                            <div class="p-2">
                                <!-- Premiere section au fond gris -->
                                <div class="bg-secondary p-4">
                                    <div class="border bg-white mx-5">
                                        <!-- Le ruban pour marquer terminer il s'affiche le status de la tâche terminée-->
                                        <span *ngIf="uneTache.status == 'terminée'" class="ribbon-angle ribbon-angle--top-right ribbon-success">
                                          <small>Terminée</small>
                                        </span>
                                        <!-- Fin du ruban pour marquer terminer -->

                                        <div class="header p-3 d-flex justify-content-between">
                                            <div class="header--title mt-1"><b>{{uneTache.tache}}</b></div>
                                            <div class="header--actions" *ngIf="uneTache.status !== 'terminée'">
                                                <button class="btn m-0 btn-sm btn-success" tooltip="Marquer comme terminer" type="button" (click)="modalValideTache.show()">
                                                    <span class="btn-wrapper--icon"><i class="ion-md-done-all"></i></span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="divider"></div>
                                        <div class="body p-3">
                                            <h3 class="text-first mb-3">Opportunité de l'activité: {{uneTache.tache}}</h3>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <p><b>Responsable: </b> {{uneTache.responsable}}</p>
                                                    <p><b>Tâche: </b> {{uneTache.tache}}</p>
                                                    <p><b>Sous tâche: </b> {{uneTache.sousTache}}</p>
                                                    <p><b>Date échéance: </b> <span class="badge badge-dark">{{uneTache.dateEcheance}}</span></p>
                                                    <p><b>Status: </b> 
                                                        <span 
                                                            class="badge badge-{{
                                                                uneTache?.status == 'en cours' ? 'first':
                                                                uneTache?.status == 'en attente' ? 'warning':
                                                                uneTache?.status == 'terminée' ? 'success': 'danger'
                                                            }} h-auto py-0 px-3"
                                                        >
                                                        {{uneTache.status}}
                                                        </span>
                                                    </p>
                                                    <p><b>Contact liés: </b> {{uneTache.cantactLies}}</p>
                                                    <p><b>Évolution: </b> 
                                                        <progressbar
                                                            [value]="uneTache?.evolution"
                                                            class="progress-bar-rounded-sm"
                                                            type="{{
                                                                uneTache?.evolution >= 0 && uneTache?.evolution <= 24 ? 'danger':
                                                                uneTache?.evolution >= 25 && uneTache?.evolution <= 49 ? 'warning':
                                                                uneTache?.evolution >= 50 && uneTache?.evolution <= 64 ? 'info':
                                                                uneTache?.evolution >= 65 && uneTache?.evolution <= 99 ? 'first':'success'
                                                            }}"
                                                        >
                                                            {{uneTache?.evolution}}%
                                                        </progressbar>
                                                    </p>
                                                </div>
                                                <div class="col-md-6">
                                                    <p><b>Dernière rélance: </b> <span class="badge badge-dark">{{uneTache.derniereRelance}}</span></p>
                                                    <p><b>Autorité contractante: </b> {{uneTache.tache}}</p>
                                                    <p><b>Enrégistrer par: </b> {{uneTache.creerPar}}</p>
                                                    <p><b>Date d'enrégistrement: </b> <span class="badge badge-neutral-dark">{{uneTache.dateCreation}}</span></p>
                                                    <p><b>Modifier par: </b> {{uneTache.modifierPar}}</p>
                                                    <p><b>Date de modification: </b> <span class="badge badge-neutral-dark">{{uneTache.dateModification}}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="divider"></div>
                                        <div class="footer">
                                            <tabset type="tabs" class="nav-tabs-first mb-5 mt-3">
                                                <!-- Note interne -->
                                                <tab><ng-template tabHeading>Notes internes</ng-template>
                                                    <div class="m-3">Aucune note interne</div>
                                                </tab>
                                                <!-- Fin note interne -->
                        
                                                <!-- Informations supplémentaires -->
                                                <tab><ng-template tabHeading>Informations supplémentaires</ng-template>
                                                    <div class="m-3">
                                                        <label for="" class="font-weight-bold">Description: </label>
                                                        <p>{{uneTache.description}}</p>
                                                    </div>
                                                </tab>
                                                <!-- Fin informations supplémentaires -->
                                            </tabset>
                                        </div>
                                    </div>
                                </div>
                                <!-- Premiere section au fond gris -->
                                <hr class="m-0">
                                <!-- Deuxieme section -->
                                <div class="mb-4">
                                    <tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow" type="tabs" >
                                        <tab customClass="pl-0 pr-1 py-2">
                                            <ng-template tabHeading><span><i class="fas fa-paper-plane mr-2"></i>Envoyer un message</span></ng-template>
                                            <div class="border bg-secondary py-2 px-3">
                                                <div class="d-flex align-items-center mb-2">
                                                    <div class="avatar-icon-wrapper mr-3"><div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div></div>
                                                    <div>
                                                        <span class="font-weight-bold text-black" title="Kouassi ghislain">Kouassi ghislain</span>
                                                        <span class="text-black-50 d-block">29/05/2021 11:50:00</span>
                                                    </div>
                                                </div>
                                                <textarea class="form-control" name="message" id="message" cols="30" rows="1" placeholder="Entrer le texte..."></textarea>
                                                <div class="d-flex justify-content-between mt-3">
                                                    <div class="d-flex align-items-center">
                                                        <a [routerLink]="" class="btn btn-link btn-link-primary p-0 text-black-50">
                                                            <i class="fas fa-smile font-size-lg"></i>
                                                        </a>
                                                        <a [routerLink]="" class="btn btn-link btn-link-primary p-0 mr-2 ml-2 text-black-50" tooltip="Joint un fichier">
                                                            <i class="fas fa-paperclip font-size-lg"></i>
                                                        </a>
                                                    </div>
                                                    <button class="btn btn-sm btn-primary" tooltip="Envoyer"><i class="fas fa-paper-plane mx-3"></i></button >
                                                </div>
                                            </div>
                                        </tab>
                                        <tab customClass="pl-0 pr-1 py-2">
                                            <ng-template tabHeading><span><i class="fas fa-save mr-2"></i>Enrégieter une note</span></ng-template>
                                            <div class="border bg-secondary pb-2 pt-3 px-3">
                                                <textarea class="form-control" name="note" id="note" cols="30" rows="1" placeholder="Enter la note..."></textarea>
                                                <div class="d-flex justify-content-between mt-3">
                                                    <div class="d-flex align-items-center">
                                                        <a [routerLink]="" class="btn btn-link btn-link-primary p-0 text-black-50">
                                                            <i class="fas fa-smile font-size-lg"></i>
                                                        </a>
                                                        <a [routerLink]="" class="btn btn-link btn-link-primary p-0 mr-2 ml-2 text-black-50" tooltip="Fichier joint">
                                                            <i class="fas fa-paperclip font-size-lg"></i>
                                                        </a>
                                                    </div>
                                                    <button class="btn btn-sm btn-success" tooltip="Enregistrer"><i class="fas fa-save mx-3"></i></button >
                                                </div>
                                            </div>
                                        </tab>
                                        <tab customClass="pl-0 pr-1 py-2">
                                            <ng-template tabHeading><span (click)="planifierActiviteModal.show()" (click)="showTable(true)"><fa-icon [icon]="['far', 'clock']" class="mr-2"></fa-icon>Planifier une activité</span></ng-template>
                                            <div class="row"></div>
                                        </tab>
                                    </tabset>
                                </div>
                                <!-- Fi deuxieme section -->
                                <!-- Troisieme section -->
                                <div class="mb-4">
                                    <h5 class="btn m-0" (click)="toggleActivite()" tooltip="Cliquez pour dérouler la liste des activités planifiées.">Activité Planifiées <i class="{{activite ? 'fas fa-chevron-down': 'fas fa-chevron-right'}} ml-2"></i></h5><hr class="mt-1 mb-3">
                                    <div class="activite border p-2" *ngIf="activite">
                                        <div>
                                            <div class="d-flex align-items-center mb-3">
                                                <div class="avatar-icon-wrapper mr-3"><div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div></div>
                                                <div>
                                                    <span class="text-black" title="Kouassi ghislain">
                                                        <b>Kouassi ghislain</b> Une description
                                                    </span>
                                                    <span class="text-black-50 d-block">29/05/2021 11:50:00</span>
                                                </div>
                                            </div>
                                            <a [routerLink]=""  (click)="planifierActiviteModal.show()" class="ml-4"><i class="fas fa-pen"></i> Modifier</a>
                                            <a [routerLink]="" class="ml-4"><i class="fas fa-times"></i> Annuler</a>
                                        </div><hr>
                                        <div>
                                            <div class="d-flex align-items-center mb-3">
                                                <div class="avatar-icon-wrapper mr-3"><div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div></div>
                                                <div>
                                                    <span class="text-black" title="Kouassi ghislain">
                                                        <b>Kouassi ghislain</b> Une description
                                                    </span>
                                                    <span class="text-black-50 d-block">29/05/2021 11:50:00</span>
                                                </div>
                                            </div>
                                            <a [routerLink]=""  (click)="planifierActiviteModal.show()" class="ml-4"><i class="fas fa-pen"></i> Modifier</a>
                                            <a [routerLink]="" class="ml-4"><i class="fas fa-times"></i> Annuler</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Fin troisieme section -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button class="btn btn-info mr-2" type="button"><i class="fas fa-edit"></i> Modifier</button>
                    <button class="btn btn-secondary" type="button" (click)="modalDetailTache.hide()"><i class="fas fa-times"></i> Quitter</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin détail des tache -->

<!--Validation de tache-->
<div #modalValideTache="bs-modal" bsModal class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Confirmation de tâche terminée</h4>
                <button type="button" class="close" aria-label="Close" (click)="modalValideTache.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="mx-2">
                    <alert type="warning">
                        <div class="d-flex align-items-center align-content-center">
                            <span class="font-size-lg d-block d-40 mr-3 text-center bg-white-50 rounded-sm">
                                <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
                            </span>
                            <span>
                                <strong class="d-block">Attention!</strong>
                                Etes vous sûr que la tâche1 est terminée ?
                            </span>
                        </div>
                    </alert>
                    
                    <div class="row">
                        <div class="col-md-12">
                            <label for="file">Joindre un fichier </label>
                            <div class="custom-file">
                                <input class="custom-file-input" id="file" name="file" type="file">
                                <label class="custom-file-label" for="file">
                                    <small class="form-text text-muted">Taille du fichier inférieur à 2MB</small>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="modalValideTache.hide()"><i class="fas fa-times"></i> Fermer</button>
                <button type="button" class="btn btn-success ml-auto" (click)="modalValideTache.hide()"><i class="ion-md-done-all"></i> Confirmer</button>
            </div>
        </div>
    </div>
</div>
<!--Fin validation de tache-->

<!-- Planifier une activité -->
<div #planifierActiviteModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">{{table == true ? 'Planifier une activité': 'Rélancer cette activité'}}</h6>
                <button type="button" class="close" aria-label="Close" (click)="planifierActiviteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="bg-white">
                            <div class="p-3">
                                <form [formGroup]="planifierActiviteFrom">
                                    <div class="form-group row mb-2">
                                        <div class="col-md-4 mb-2">
                                            <label for="tache">Tâche</label>
                                            <div class="input-group">
                                                <select class="form-control" *ngIf="inputLotActivite == true"  id="tache" formControlName="tache">
                                                    <option [value]="'Terrassement'">Terrassement</option>
                                                    <option [value]="'Ratissage'">Ratissage</option>
                                                    <option [value]="'Gros oeuvres'">Gros oeuvres</option>
                                                    <option [value]="'Second oeuvre'">Second oeuvre</option>
                                                </select>
                                                <input class="form-control" id="tache" formControlName="tache" type="text" *ngIf="inputLotActivite != true" >
                    
                                                <button (click)="inputLotActivite = !inputLotActivite"
                                                    tooltip="{{inputLotActivite? 'Saisir' : 'Choisir'}}" placement="auto"
                                                    class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button"
                                                >
                                                    <span class="btn-wrapper--icon">
                                                        <fa-icon [icon]="['far', 'keyboard']"></fa-icon>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-2">
                                            <label for="SousTache">Sous tâche </label>
                                            <div class="input-group">
                                                <select class="form-control" *ngIf="inputActivite == true" id="SousTache" formControlName="SousTache">
                                                    <option [value]="'Terrassement'">Terrassement</option>
                                                    <option [value]="'Ratissage'">Ratissage</option>
                                                    <option [value]="'Gros oeuvres'">Gros oeuvres</option>
                                                    <option [value]="'Second oeuvre'">Second oeuvre</option>
                                                </select>
                                                <input class="form-control" id="SousTache" formControlName="SousTache" type="text" *ngIf="inputActivite != true" >
                    
                                                <button (click)="inputActivite = !inputActivite"
                                                    tooltip="{{inputActivite? 'Saisir' : 'Choisir'}}" placement="auto"
                                                    class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button"
                                                >
                                                    <span class="btn-wrapper--icon">
                                                        <fa-icon [icon]="['far', 'keyboard']"></fa-icon>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-md-4 mb-2">
                                            <label for="dateEcheance">Date écheance </label>
                                            <div class="input-group input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                                </div>
                                                <input class="form-control" placeholder="Entre la date" type="date" id="dateEcheance" formControlName="dateEcheance">
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <label for="contact">Contacts liés</label>
                                            <ng-select [multiple]="true" id="contact" formControlName="contact" type="text">
                                                <ng-option value="Kouassi">Kouassi</ng-option>
                                                <ng-option value="Ghislain">Ghislain</ng-option>
                                                <ng-option value="Amond">Amond</ng-option>
                                                <ng-option value="Kader">Kader</ng-option>
                                                <ng-option value="Kouassi">Kouassi</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <label for="responsableTache">Responsable de tâche</label>
                                            <ng-select id="responsableTache" formControlName="responsableTache" type="text">
                                                <ng-option value="Kouassi">Kouassi</ng-option>
                                                <ng-option value="Ghislain">Ghislain</ng-option>
                                                <ng-option value="Amond">Amond</ng-option>
                                                <ng-option value="Kader">Kader</ng-option>
                                                <ng-option value="Kouassi">Kouassi</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-12 mb-2">
                                            <label for="description"> Description </label>
                                            <angular-editor [config]="config" id="description" formControlName="description"></angular-editor>
                                        </div>
                                    </div>
                                </form>
                                <div *ngIf="table == true">
                                    <div class="text-right">
                                        <button class="btn btn-first" tooltip="Nettoyer les champs de saisie " (click)="reset()"><i class="fas fa-broom"></i> Nettoyer</button>&nbsp;
                                        <button class="btn btn-success ml-2" tooltip="Cliquer pour ajouter" (click)="addTravaux()"><i class="fas fa-plus-circle"></i> Ajouter</button>
                                    </div>
                                    <div class="border bg-secondary mt-3">
                                        <div class="m-2">Liste des travaux <span class="badge badge-primary">{{travauxData.length}}</span></div>
                                        <div class="table-responsive mb-0">
                                            <table class="table table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Tâche</th>
                                                        <th>Sous tâche</th>
                                                        <th>Date écheance</th>
                                                        <th>Contact</th>
                                                        <th>Responsable</th>
                                                        <th>Description</th>
                                                        <th class="text-center">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let travaux of travauxData">
                                                        <td>{{travaux.tache}}</td>
                                                        <td>{{travaux.SousTache}}</td>
                                                        <td>{{travaux.dateEcheance}}</td>
                                                        <td>{{travaux.contact}}</td>
                                                        <td>{{travaux.responsableTache}}</td>
                                                        <td>{{travaux.description}}</td>
                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-primary" tooltip="Détail"><i class="ion-md-eye"></i></button>
                                                            <button class="btn btn-sm btn-info ml-2" tooltip="Modifier"><i class="ion-md-create"></i></button>
                                                            <button class="btn btn-sm btn-danger ml-2" tooltip="Supprimer" (click)="removeTravaux(travaux)"><i class="ion-md-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="travauxData.length === 0">
                                                        <td colspan="6" class="p-0">
                                                            <div class="text-center">
                                                                <div class="img"><img src="../../../assets/img/empty3.png" alt="" style="width: 100%;"></div>
                                                                <div><p>Aucune donnée disponible. Renseignez le formulaire juste au dessus puis cliquez sur le boutton AJOUTER pour ajouter.</p></div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button class="btn btn-secondary" (click)="planifierActiviteModal.hide()"><i class="fas fa-times"></i> Annuler</button>
                    <button class="btn btn-success ml-2"><i class="fas fa-save"></i> Enregistrer</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin planifier une activité -->

<!-- Rapport opportunité perdu -->
<div #rapportOpportunitePerduModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Rédiger un rapport</h6>
                <button type="button" class="close" aria-label="Close" (click)="rapportOpportunitePerduModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="bg-white">
                            <form>
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <div class="mb-3">
                                                <angular-editor [config]="config"></angular-editor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <label for="fichier">Joint un fichier </label>
                                        <div class="custom-file">
                                            <input class="custom-file-input" id="fichier" name="fichier" type="file">
                                            <label class="custom-file-label" for="fichier"><small>Format: PDF, jpeg, jpg, DOC, xls</small></label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button class="btn btn-secondary mr-2" type="button" (click)="rapportOpportunitePerduModal.hide()"><i class="fas fa-times"></i> Annuler</button>
                    <button  class="btn btn-success" type="button"><i class="fas fa-saved"></i> Enregistrer</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin rapport opportunité perdu -->

<!--Validation de tache-->
<div #modalTacheFaite="bs-modal" bsModal class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Marquer la tâche comme terminée</h4>
                <button type="button" class="close" aria-label="Close" (click)="modalTacheFaite.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-3">
                <textarea name="" id="" cols="30" rows="3" class="form-control" placeholder="Ajouter un commentaire"></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="modalTacheFaite.hide()"><i class="fas fa-times"></i> Fermer</button>
                <button type="button" class="btn btn-success ml-auto" (click)="modalTacheFaite.hide()"><i class="ion-md-done-all"></i> Marquer</button>
            </div>
        </div>
    </div>
</div>
<!--Fin validation de tache-->
<!-- FIN DES MODALS -->