import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilisateurService } from '../utilisateur.service';
import { Subscription } from 'rxjs';
import { TypeaheadOptions } from 'ngx-bootstrap';
import { Utilisateur } from '../utilisateur.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilisateurEditComponent } from '../utilisateur-edit/utilisateur-edit.component';


@Component({
  selector: 'app-utilisateur-list',
  templateUrl: './utilisateur-list.component.html',
  styleUrls: ['./utilisateur-list.component.scss']
})
export class UtilisateurListComponent implements OnInit, OnDestroy {
  currentPage = 1;
  itemsPerPage = 5;
  rotate = true;
  utilisateurSubs = new Subscription;
  utilisateurDeleteSubs = new Subscription;

  listeUtilisateur: Utilisateur[] = [];
  private notif: NotifierService;

  constructor(
    private modalService: NgbModal,
    public globals: ThemeOptions,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private utilisateurService: UtilisateurService,
    private notifier: NotifierService) {
    this.notif = this.notifier;

  }

  ngOnInit() {
    this.loadUser();
  }

  loadUser() {
    this.listeUtilisateur = [];
    this.utilisateurSubs == this.utilisateurService.listerUtilisateurs().subscribe(
      (resData: Utilisateur) => {
        this.listeUtilisateur.push(resData)
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }



 

  ngOnDestroy(): void {
    this.utilisateurSubs.unsubscribe();
    this.utilisateurDeleteSubs.unsubscribe();
    // this.utilisateurService.setDataForUtilisateur('');
  }
  sendData(donnee, bool) {
    const donneeSend = {
      user: donnee,
      type: bool
    };
    this.utilisateurService.setDataForUtilisateur(donneeSend);
  }

  addUtilisateur(donnee?, action?): any {
    console.log(donnee);
    console.log(donnee);
    
    if(donnee) {
      this.sendData(donnee, action);
    }
    this.modalService.dismissAll();
    // this.chambreService.edit = false;
    this.modal(UtilisateurEditComponent, 'modal-basic-title', 'xl', false, 'static');
  }


  modal(component, type, size, center, backdrop): any {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {
      console.log('result', result);
      if(result == 'success') {
        this.showNotification('success', "L'opération s'est bien déroulé");
        this.loadUser();
      } else if (result == 'update') {
        this.showNotification('success', "L'opération s'est bien déroulé");
        this.loadUser();
      }      
    }, (reason) => { });
  }


   /**
     * Show a notification
     */
    showNotification(type: string, message: string): void {
      this.notif.notify(type, message);
    }

    deleteUtilisateur(id) {
      console.log('id to delete', id);
      this.utilisateurDeleteSubs = this.utilisateurService.deleteUtilisateur(id).subscribe(
        resData => {
          this.showNotification('success', "L'opération s'est bien déroulé");
          this.loadUser();
          // if(resData) {
          //   this.showNotification('success', "L'opération s'est bien déroulé");
          // } 
         
        },
        errorRes => {
          this.spinner.hide("sp6");
          console.log(errorRes, "erreur")
          this.showNotification('danger', "L'opération a échoué");
        }
      );
    }
    pageChanged(event:any) {
      this.currentPage = event.page;
   }
   __onViewDetailsUser(data) {
     console.log("data", data);
      this.utilisateurService.setDataForUtilisateur(data);
   }

}
