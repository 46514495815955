<div class="signature-container">
  <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
</div>

<hr class="m-0">

<div class="buttons text-right px-3 py-2">
  <button class="btn btn-first mr-2" (click)="clearSignature()"><i class="fas fa-broom"></i> Effacer</button>
  <button class="btn btn-success" (click)="savePad()"><i class="fas fa-save"></i> Enregistrer</button>
</div>

<!-- Ce code en commentaire eait afficher la signature effectuer -->

<!-- <div class="signature-image" *ngIf="signatureImg">
  <img src='{{ signatureImg }}' />
</div> -->