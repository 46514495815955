<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-wallet display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>Gestion Utilisateurs </h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">Liste des utilisateurs </div>
            </div>
        </div>
        <div class="flex-fill text-right">
          <button (click)="addUtilisateur('', false)" class="btn btn-success" tooltip="Créer un nouvel utilisateur" type="button"><i class="fas fa-plus-circle mr-2"></i> Ajouter</button>
        </div>
    </div>
  </div>
  
  
  <div class="card card-box mb-5">
    <div class="card-header py-3">
      <div class="card-header--title font-size-lg">
        Liste des utilisateurs
      </div>
    </div>
    <div class="d-flex justify-content-between px-4 py-3">
      <div class="d-flex align-items-center">
        <span>Show</span>
        <select class="mx-1 form-control form-control-sm" id="" name="">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
        </select>
        <span>entries</span>
      </div>
      <div class="search-wrapper">
        <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
        <input class="form-control form-control-sm rounded-pill" placeholder="Recherche utilisateur..." type="search">
      </div>
    </div>
    <div class="divider"></div>
    <div class="table-responsive">
      <table class="table table-hover text-nowrap mb-0">
        <thead>
          <tr>
            <th class="bg-white text-left">Photo</th>
            <th class="bg-white text-left">Nom et prenom</th>
            <th class="bg-white text-left">Nom d'utilisateur</th>
            <th class="bg-white text-left">Email</th>
            <th class="bg-white text-left">Contact</th>
            <th class="bg-white text-left">Type d'utilisateur</th>
            <th class="bg-white text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let utils of listeUtilisateur | paginate: { id: 'module-appli', itemsPerPage: 2, currentPage: currentPage  }"> 
            <td class="font-weight-bold"><img src="../../../assets/images/avatars/avatar1.jpg" alt=""></td>
            <td class="font-weight-bold"><a [routerLink]="['/pages/utilisateur/detail/5']"> {{utils?.nom}} {{utils?.prenom}}</a></td>
            <td class="font-weight-bold"> {{utils?.username}}</td>
            <td class="font-weight-bold"> {{utils?.email}} </td>
            <td class="font-weight-bold"> {{utils?.contact1}} </td>
            <td class="font-weight-bold "><span class="badge bg-neutral-dark text-dark"> {{utils?.roles[0]}}</span></td>
            <td class="text-center">
              <button placement="auto" (click)="__onViewDetailsUser(utils)" [routerLink]="['/pages/utilisateur/detail/', utils.id]"  tooltip="Cliquer pour accéder au compte de cet utilisateur"  class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                <span class="btn-wrapper--icon"><i class="fas fa-eye  "></i></span>
              </button>
              <button placement="auto" tooltip="Modifier" (click)="addUtilisateur(utils, true)"  class="btn d-40 p-0 btn-pill mr-1 btn-info" type="button">
                <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
              </button>
  
              <button [swal]="deleteSwal" (confirm)="deleteUtilisateur(utils.id)"  placement="auto"  tooltip="Attention! vous êtes sur le point de supprimer cet utilisateur"  class="btn d-40 p-0 btn-pill m-2 btn-danger" type="button">
                <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
              </button>
            </td>

            <swal  type="warning" #deleteSwal title="Supprimer" text="Voulez vous supprimer cet utilisateur ?"  [showCancelButton]="true"
            [focusCancel]="true" confirmButtonColor="#d33" cancelButtonColor="#6e7881" cancelButtonText='Annuler <i class="fas fa-times"></i>'  confirmButtonText='Supprimer <i class="fas fa-trash"></i>'>
            </swal>

          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer py-3 d-flex justify-content-between">
      <pagination-controls class="my-pagination" (pageChange)="currentPage = $event" id="module-appli"
                                   responsive="true"
                                   previousLabel="Précédent"
                                   nextLabel="Suivant">
        </pagination-controls>

      <!-- <pagination  [totalItems]="listeUtilisateur.length"   (pageChanged)="pageChanged($event)" class="pagination-icons pagination-second"></pagination> -->
      <div class="d-flex align-items-center">
        <span>Show</span>
        <select class="mx-1 form-control form-control-sm" id="" name="">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
        </select>
        <span>entries</span>
      </div>
    </div>
  </div>
  
  
  <!--MODAL AJOUT UTILISATEUR-->
  <div #openUserModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
      <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
              <h6 class="modal-title">Formulaire de création d'un utilisateur</h6>
              <button type="button" class="close" aria-label="Close" (click)="openUserModal.hide()">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row no-gutters">
                <div class="col-lg-12">
                    <div class="bg-white rounded-left">
                        <!-- <div class="p-4">
                            <app-utilisateur-edit></app-utilisateur-edit>
                        </div> -->
                    </div>
                </div>
            </div>
            </div>
        </div>
      </div>
  </div>
  <!--FIN MODAL AJOUT UTILISATEUR-->

  <notifier-container></notifier-container>
