<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-umbrella display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1 class="font-weight-bold m-0">Détail reserve de travaux</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description m-0" title="Référence et libellé de la reserve">
                    <span><i class="fas fa-file-alt"></i> ( RES0001 )</span>
                    <span class="ml-1">Reserve 1</span>
                </div>
                <p class="m-0" title="Date début et date fin de la reserve">
                    <i class="fas fa-calendar-alt"></i>
                    <span class="ml-1">Du 01/01/2021 au 06/10/2021</span>
                </p>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button [routerLink]="['/pages/projet/detail/5']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow" type="tabs">

    <!-- Fiche de reserve -->
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span>Fiche de reserve</span></ng-template>
        <div class="bg-white mt-2 rounded shadow-sm">
            <div class="header p-3 m-0">
                <div class="title font-weight-bold m-0">
                    <i class="fas fa-file-alt mr-1"></i> Informations générales de la reserve
                </div>
            </div>
            <hr class="m-0">
            <div class="body p-3">
                <div class="border rounded p-3">
                    <progressbar [value]="72" class="progress-bar-rounded-sm mb-2" type="first">72%</progressbar>
                    <div class="row">
                        <div class="col-md-6">
                            <p class="mt-2 mb-0"><b>libellé:</b> <span class="ml-2">Reserve</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Status:</b> <span class="ml-2 bagde bg-neutal-first text-first">En cours de traitement</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date début:</b> <span class="ml-2">01/01/2021</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date fin:</b> <span class="ml-2">01/11/2021</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Chantier:</b> <span class="ml-2">Chantier de constructiin</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Gravité: </b> <span class="ml-2 bagde bg-neutal-danger text-danger">Urgente</span></p>
                            <hr class="my-2">
                        </div>
                        <div class="col-md-6">
                            <p class="mt-2 mb-0"><b>Montant budget:</b> <span class="ml-2">1000 000 000 000</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Financeur:</b> <span class="ml-2">Client</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Créer par:</b> <span class="ml-2"> Kouassi ghislain</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date de création:</b> <span class="ml-2">10/10/2021 10:10:00</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Modifier par:</b> <span class="ml-2"> Kouassi ghislain</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date de modification:</b> <span class="ml-2">10/10/2021 10:10:00</span></p>
                            <hr class="my-2">
                        </div>
                        <div class="col-md-12">
                            <p class="my-0"><b>Description:</b></p>
                            <div class="border rounded p-3 bg-light">
                                <span>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi aliquam neque, accusantium reiciendis non similique? Cumque, natus deserunt, tempora amet nemo architecto error reiciendis vitae cupiditate, at tenetur! Et, ullam!
                                    Esse pariatur numquam voluptas! Blanditiis voluptate officia natus officiis accusantium corporis animi, ipsum vel dignissimos laboriosam tempore doloribus molestiae? Modi voluptatem veniam perspiciatis obcaecati nostrum eligendi facilis suscipit illum eos.
                                    Tempore illo minima, nihil alias quia placeat totam rerum atque ex ipsum, nemo cum ipsam, officia expedita? Cupiditate minima commodi distinctio eos ut error eligendi quis voluptas velit cum. Nulla!
                                    Quidem id rerum beatae consequatur veniam sequi magnam blanditiis velit quaerat. Amet animi eaque possimus, nobis odio corporis a, fugiat modi, nesciunt deserunt dignissimos praesentium accusamus harum sit error maxime.
                                    Excepturi nulla in sequi omnis reprehenderit, quas facere vero nisi commodi quisquam adipisci ipsum porro ex praesentium totam quibusdam iure quidem eum consequatur! Molestiae doloribus dignissimos autem eius dolorem velit.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </tab>

    <!-- Liste de remarque -->
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span>Liste de remarque</span></ng-template>
        <div class="bg-white mt-2 rounded shadow-sm">
            <div class="header p-3 m-0">
                <div class="title font-weight-bold m-0">
                    <i class="fas fa-file-signature mr-1"></i> Liste des remarques
                </div>
            </div>
            <hr class="m-0">
            <div class="body p-3">
                <app-remarque-list></app-remarque-list>
            </div>
        </div>
    </tab>
</tabset>