import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-remarque-detail',
  templateUrl: './remarque-detail.component.html',
  styleUrls: ['./remarque-detail.component.scss']
})
export class RemarqueDetailComponent implements OnInit {
  public albums: Array<any> = [];
  // Tout les variables ou tableau ayants pour prefix "default" servent a faire des duplication dans le template.
  defaultRemarque = [
    { line:1 },
    { line:2 },
    { line:3 },
  ];

  constructor(
    public lightbox: Lightbox,
    public globals: ThemeOptions,
  ) {
    this.lightboxFunction();
  }

  ngOnInit() {
  }

  lightboxFunction(){
    for (let i = 1; i <= 6; i++) {
      const src = 'assets/lightbox/default/i' + i + '.jpg';
      const plan = 'assets/lightbox/plan/p' + i + '.jpg';
      const caption = 'Image ' + i + ' caption here';
      const album = {
        src: src,
        plan: plan,
        caption: caption,
      };
      this.albums.push(album);
    }
  }
 
  open(index: number): void {
    // open lightbox
    this.lightbox.open(this.albums, index);
  }
 
  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

}
