import { Component, OnInit } from '@angular/core';
import { UtilOptions } from 'src/app/shared-components/util/util-option';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-budget-dashboard',
  templateUrl: './budget-dashboard.component.html',
  styleUrls: ['./budget-dashboard.component.scss']
})
export class BudgetDashboardComponent implements OnInit {
  budgetWidgetView = false;
  rotate = true;
  mois = UtilOptions.mois;

  constructor(public globals: ThemeOptions) { }

  ngOnInit() {
  }

}
