<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-glasses display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1 class="font-weight-bold m-0">Détail réunion</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description m-0" title="Référence et libellé de la réunion">
                    <span><i class="fas fa-file-alt"></i> ( {{reunion?.reference}} )</span>
                    <span class="ml-1">{{reunion?.ordre}}</span>
                </div>
                <p class="m-0" title="Date de création de la réunion">
                    <i class="fas fa-calendar-alt"></i>
                    <span class="ml-1">{{reunion?.dateCreation}}</span>
                </p>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button [routerLink]="['/pages/projet/detail/5']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<aw-wizard class="wizard" navBarLocation="top">
    <!-- ETAPE 1: FICHE DE REUNION -->
    <aw-wizard-step stepTitle="Fiche de réunion" [navigationSymbol]="{ symbol: '1' }">
        <div class="bg-white mt-3 rounded-sm shadow-sm">
            <div class="header px-3 py-2 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div class="title font-weight-bold"><i class="fas fa-file-alt mt-3 mr-1"></i> Detail de la réunion</div>
                    <div class="text-right">
                        <button (click)="partageVisite.show()" class="btn btn-transition-none btn-dark" placement="auto" type="button">
                            <i class="fas fa-share-alt mr-1"></i>Partager
                        </button>
                    </div>
                </div>
            </div>
            <hr class="m-0">
            <div class="body p-3">
                <div class="border rounded p-3">
                    <hr class="m-0">
                    <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Information générales</h4></div>
                    <div class="row">
                        <div class="col-md-6">
                            <p class="mt-2 mb-0"><b>Ordre du jour:</b> <span class="ml-2">{{reunion?.ordre}}</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date début:</b> <span class="ml-2">{{reunion?.dateDebut}}</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date fin:</b> <span class="ml-2">{{reunion?.dateFin}}</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Journée entiere:</b> <span class="ml-2">OUI</span></p>
                        </div>
                        <div class="col-md-6">
                            <p class="mt-2 mb-0"><b>Créer par:</b> <span class="ml-2">{{reunion?.creerPar}}</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date de création:</b> <span class="ml-2">{{reunion?.dateCreation}}</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Modifier par:</b> <span class="ml-2">{{reunion?.modifierPar}}</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date de modification: </b> <span class="ml-2">{{reunion?.dateModification}}</span></p>
                        </div>
                    </div>
                    <hr class="my-2">
                    <div class="mb-5">
                        <p class="mb-2"><b>Description:</b></p>
                        <textarea class="form-control" rows="4" disabled>{{reunion?.description}}</textarea>
                    </div>

                    <hr class="m-0">
                    <div class="p-2 bg-secondary">
                        <h4 class="m-0 font-weight-bold text-first">Participant ({{reunion?.participant?.length}})</h4>
                    </div>
                    <div class="table-responsive m-0">
                        <table class="table m-0">
                            <thead class="bg-white">
                                <tr>
                                    <th class="bg-white">Participant</th>
                                    <th class="bg-white">Nom et prenom</th>
                                    <th class="bg-white">Fonction</th>
                                    <th class="bg-white">Contact</th>
                                    <th class="bg-white">Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of reunion?.participant">
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div class="avatar-icon-wrapper mr-2">
                                                <div class="avatar-icon"><img alt="..." src="{{item?.photo}}"/></div>
                                            </div>
                                            <div>
                                                <a class="font-weight-bold text-black" title="...">{{item?.nom}}</a>
                                                <span class="text-black-50 d-block">{{item?.fonction}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{item?.nom}}</td>
                                    <td>{{item?.fonction}}</td>
                                    <td>{{item?.mobile}}</td>
                                    <td>{{item?.email}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr class="m-0">
            <div class="footer px-3 py-2 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div></div>
                    <div>
                        <button class="btn btn-info mr-2"><i class="fas fa-pen mr-1"></i>Modifier</button>
                        <button class="btn btn-warning mr-2"><i class="ion-md-print mr-1"></i>Imprimer</button>
                        <button awNextStep class="btn btn-dark" type="button">Suivant <i class="fas fa-chevron-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </aw-wizard-step>

    <!-- ETAPE 2: REDACTIOIN DU CONTENU DE LA REUNON -->
    <aw-wizard-step stepTitle="Rédaction" [navigationSymbol]="{ symbol: '2' }">
        <div class="bg-white mt-3 rounded-sm shadow-sm">
            <div class="header p-3 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div class="title font-weight-bold">
                        <i class="fas fa-edit mr-1"></i> Rédaction du contenue de la réunion
                    </div>
                </div>
            </div>
            <hr class="m-0">
            <div class="body p-3">
                <div class="border rounded p-3">
                    <form>
                        <div class="form-row">
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="heureOuverture">Heure d'ouverture: </label>
                                    <div class="input-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><fa-icon [icon]="['far', 'clock']" class="mx-auto"></fa-icon></span>
                                        </div>
                                        <input type="time" id="heureOuverture" class="form-control" step="2">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="heureFin">Heure de fin: </label>
                                    <div class="input-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><fa-icon [icon]="['far', 'clock']" class="mx-auto"></fa-icon></span>
                                        </div>
                                        <input type="time" id="heureFin" class="form-control" step="2">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="heureFin">Date de la prochaine réunion: </label>
                                    <div class="input-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                        </div>
                                        <input type="datetime-local" id="heureFin" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <accordion class="accordion rounded-sm" [closeOthers]="true" [isAnimated]="true">
                            <accordion-group class="card card-box rounded-sm">
                                <button accordion-heading class="btn btn-link d-flex align-items-center justify-content-between" type="button">
                                    <span>
                                        <i class="fas fa-newspaper nav-icon-wrapper text-dark font-size-lg mr-1"></i>
                                        Editeur (Clique)
                                    </span>
                                    <fa-icon [icon]="['fas', 'angle-up']"></fa-icon>
                                </button>
                                <div class="p-2">
                                    <angular-editor id="redaction" [config]="config"></angular-editor>
                                </div>
                            </accordion-group>
                        </accordion>
                    </form>
                </div>
            </div>
            <hr class="m-0">
            <div class="footer px-3 py-2 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div>
                        <button awPreviousStep class="btn btn-dark" type="button"><i class="fas fa-chevron-left"></i> Précédent</button>
                    </div>
                    <div class="text-right">
                        <button class="btn btn-success mr-2"><i class="ion-md-print mr-1"></i>Enregistrer</button>
                        <button awNextStep class="btn btn-dark" type="button">Suivant <i class="fas fa-chevron-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </aw-wizard-step>

    <!-- ETAPE 3: LISTE DE PRESENCE -->
    <aw-wizard-step awOptionalStep stepTitle="Liste de présence" [navigationSymbol]="{ symbol: '3' }">
        <div class="bg-white mt-3 rounded-sm shadow-sm">
            <div class="header px-3 py-2 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div class="title font-weight-bold"><i class="fas fa-user-check mt-3 mr-1"></i> Liste des participants à la réunion</div>
                </div>
            </div>
            <hr class="m-0">
            <div class="body p-3">
                <table class="table table-alternate table-hover m-0">
                    <thead>
                        <tr>
                            <th>participants</th>
                            <th class="text-center"><span class="font-weight-bold text-success">P</span>résent</th>
                            <th class="text-center"><span class="font-weight-bold text-danger">A</span>bsent</th>
                            <th class="text-center"><span class="font-weight-bold text-first">E</span>xcusé</th>
                            <th class="text-center"><span class="font-weight-bold text-warning">N</span>on excusé</th>
                            <th class="text-right">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of reunion?.participant">
                            <td>
                                <div class="d-flex align-items-center">
                                    <div class="avatar-icon-wrapper mr-2">
                                        <div class="avatar-icon"><img alt="..." src="{{item?.photo}}"/></div>
                                    </div>
                                    <div>
                                        <a class="font-weight-bold text-black" title="...">{{item?.nom}}</a>
                                        <span class="text-black-50 d-block">{{item?.fonction}}</span>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckbox{{item?.id}}1" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckbox{{item?.id}}1">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckbox{{item?.id}}2" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckbox{{item?.id}}2">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckbox{{item?.id}}3" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckbox{{item?.id}}3">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckbox{{item?.id}}4" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckbox{{item?.id}}4">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-right">
                                <button class="btn p-0 d-40 rounded-circle btn-first mr-1" type="button" tooltip="Signature" (click)="signatureModal.show()">
                                    <i class="fas fa-signature"></i>
                                </button>
                                <button class="btn p-0 d-40 rounded-circle btn-danger" type="button" tooltip="Retirer">
                                    <i class="ion-md-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr class="m-0">
            <div class="footer px-3 py-2 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div>
                        <button awPreviousStep class="btn btn-dark" type="button"><i class="fas fa-chevron-left"></i> Précédent</button>
                    </div>
                    <div class="text-right">
                        <button class="btn btn-warning mr-2"><i class="ion-md-print mr-1"></i>Imprimer</button>
                        <button awNextStep class="btn btn-dark" type="button">Suivant <i class="fas fa-chevron-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </aw-wizard-step>

    <!-- ETAPE 4: COMPTE RENDU DE REUNION -->
    <aw-wizard-step awOptionalStep stepTitle="Compte rendu" [navigationSymbol]="{ symbol: '4' }">
        <div class="bg-white mt-3 rounded-sm shadow-sm">
            <div class="header px-3 py-2 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div class="title font-weight-bold"><i class="fas fa-file-alt mt-3 mr-1"></i> Compte rendu de la réunion du <span class="text-dark">{{reunion?.dateDebut}} au {{reunion?.dateFin}}</span></div>
                    <div class="text-right">
                        <button (click)="partageVisite.show()" class="btn btn-transition-none btn-dark mr-2" placement="auto" type="button">
                            <i class="fas fa-share-alt mr-1"></i>Partager
                        </button>
                        <button class="btn btn-warning"><i class="ion-md-print mr-1"></i>Générer</button>
                    </div>
                </div>
            </div>
            <hr class="m-0">
            <div class="body p-3">
                <div class="border rounded p-3">
                    <div class="d-flex justify-content-between mt-3">
                        <h3 class="mb-1">CR000{{reunion?.id}}</h3>
                        <h3 class="mb-1 text-first">{{reunion?.dateCreation}}</h3>
                    </div>

                    <h3 class="my-4"><b>Ordre du jours: </b><span>{{reunion?.ordre}}</span></h3>

                    <!-- Participants -->
                    <div class="p-0 m-0">
                        <hr class="m-0">
                        <div class="p-2 bg-secondary">
                            <h4 class="m-0 font-weight-bold text-first">Participants ({{reunion?.participant?.length}})</h4>
                        </div>
                        <div class="table-responsive m-0">
                            <table class="table m-0">
                                <thead class="bg-white">
                                    <tr>
                                        <th  class="bg-white">Participants</th>
                                        <th  class="bg-white">Type</th>
                                        <th  class="bg-white">Contact</th>
                                        <th  class="bg-white">Email</th>
                                        <th  class="bg-white">Présence</th>
                                        <th  class="bg-white">Signature</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of reunion?.participant">
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="avatar-icon-wrapper mr-2">
                                                    <div class="avatar-icon"><img alt="..." src="{{item?.photo}}"/></div>
                                                </div>
                                                <div>
                                                    <a class="font-weight-bold text-black" title="...">{{item?.nom}}</a>
                                                    <span class="text-black-50 d-block">{{item?.fonction}}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{{item?.type}}</td>
                                        <td>{{item?.mobile}}</td>
                                        <td>{{item?.email}}</td>
                                        <td>
                                            <span class="font-weight-bold text-{{
                                                    item?.presence == 'présent' ? 'success' : 
                                                    item?.presence == 'absent' ? 'danger' :
                                                    item?.presence == 'excusée' ? 'first' : 'warning'
                                                }}">
                                                {{
                                                    item?.presence == 'présent' ? 'P' : 
                                                    item?.presence == 'absent' ? 'A' :
                                                    item?.presence == 'excusée' ? 'E' : 'N'
                                                }}
                                            </span>
                                        </td>
                                        <td>
                                            <div>
                                                <img src="assets/img/signature.png" alt="" class="shadow-sm" style="height: 50px; width: 50px">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <hr class="mt-0 mb-4">

                    <div class="mb-4">
                        <h3 class="mb-5"><b>Heure d'ouverture: </b><span>10:30</span></h3>
                        <h3 class="mb-0"><b>1- TITRE 1:</b></h3>
                        <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis, perspiciatis eius delectus, voluptatum repellendus maiores asperiores unde, voluptate quibusdam totam rerum eaque cum nulla est neque veniam consequatur sint facere.
                            Magni doloribus consequuntur itaque reprehenderit, dignissimos adipisci ab! Fuga quos, nemo cum accusamus eligendi laborum ea praesentium adipisci voluptatibus magni expedita ipsum doloremque, dolor assumenda iste nihil omnis blanditiis esse.
                            Repellendus sed deserunt facere asperiores libero molestias, illum voluptates reprehenderit beatae provident, eum assumenda nostrum! Velit at similique dolore totam odit nobis quis aspernatur quisquam atque, reprehenderit perferendis. Totam, iure?
                            Vitae porro rerum animi placeat quidem aliquam eos, aliquid, ducimus ullam deleniti voluptates iure numquam, natus maxime tempore! Sint illo molestias odio consectetur repellendus accusantium ut dolores? Quod, maiores pariatur?
                            Quidem, odio! Omnis nam sint vero maiores odit aliquid perspiciatis. Illo numquam natus aliquid voluptates, eligendi eius voluptate enim perferendis sequi nobis, aperiam blanditiis fuga! Cumque, quod. Ipsum, quae error?
                        </p>
                        <h3 class="mt-5 mb-0"><b>2- TITRE 2:</b></h3>
                        <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis, perspiciatis eius delectus, voluptatum repellendus maiores asperiores unde, voluptate quibusdam totam rerum eaque cum nulla est neque veniam consequatur sint facere.
                            Magni doloribus consequuntur itaque reprehenderit, dignissimos adipisci ab! Fuga quos, nemo cum accusamus eligendi laborum ea praesentium adipisci voluptatibus magni expedita ipsum doloremque, dolor assumenda iste nihil omnis blanditiis esse.
                            Repellendus sed deserunt facere asperiores libero molestias, illum voluptates reprehenderit beatae provident, eum assumenda nostrum! Velit at similique dolore totam odit nobis quis aspernatur quisquam atque, reprehenderit perferendis. Totam, iure?
                            Vitae porro rerum animi placeat quidem aliquam eos, aliquid, ducimus ullam deleniti voluptates iure numquam, natus maxime tempore! Sint illo molestias odio consectetur repellendus accusantium ut dolores? Quod, maiores pariatur?
                            Quidem, odio! Omnis nam sint vero maiores odit aliquid perspiciatis. Illo numquam natus aliquid voluptates, eligendi eius voluptate enim perferendis sequi nobis, aperiam blanditiis fuga! Cumque, quod. Ipsum, quae error?
                        </p>
                        <h3 class="mt-5 mb-0"><b>3- TITRE 3:</b></h3>
                        <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis, perspiciatis eius delectus, voluptatum repellendus maiores asperiores unde, voluptate quibusdam totam rerum eaque cum nulla est neque veniam consequatur sint facere.
                            Magni doloribus consequuntur itaque reprehenderit, dignissimos adipisci ab! Fuga quos, nemo cum accusamus eligendi laborum ea praesentium adipisci voluptatibus magni expedita ipsum doloremque, dolor assumenda iste nihil omnis blanditiis esse.
                            Repellendus sed deserunt facere asperiores libero molestias, illum voluptates reprehenderit beatae provident, eum assumenda nostrum! Velit at similique dolore totam odit nobis quis aspernatur quisquam atque, reprehenderit perferendis. Totam, iure?
                            Vitae porro rerum animi placeat quidem aliquam eos, aliquid, ducimus ullam deleniti voluptates iure numquam, natus maxime tempore! Sint illo molestias odio consectetur repellendus accusantium ut dolores? Quod, maiores pariatur?
                            Quidem, odio! Omnis nam sint vero maiores odit aliquid perspiciatis. Illo numquam natus aliquid voluptates, eligendi eius voluptate enim perferendis sequi nobis, aperiam blanditiis fuga! Cumque, quod. Ipsum, quae error?
                        </p>
                        <h3 class="mt-4 mb-0"><b>Heure de fin: </b><span>12:30</span></h3>
                    </div>
                    
                    <!-- Annexe -->
                    <div class="p-0 m-0">
                        <hr class="m-0">
                        <div class="p-2 bg-secondary">
                            <h4 class="m-0 font-weight-bold text-first">Annexe</h4>
                        </div>
                        <hr class="m-0">
                        <div class="p-2 border rounded-sm text-center">
                            <div class="grid-menu grid-menu-2col">
                                <div class="no-gutters row">
                                    <div class="col-sm-6">
                                        <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                    </div>
                                    <div class="col-sm-6">
                                        <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                    </div>
                                    <div class="col-sm-6">
                                        <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                    </div>
                                    <div class="col-sm-6">
                                        <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="m-0">
            <div class="footer px-3 py-2 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div>
                        <button awPreviousStep class="btn btn-dark" type="button"><i class="fas fa-chevron-left"></i> Précédent</button>
                    </div>
                    <div class="text-right">
                        <button class="btn btn-success mr-2"><i class="fas fa-check mr-1"></i>Valider</button>
                        <button class="btn btn-warning"><i class="ion-md-print mr-1"></i>Générer</button>
                    </div>
                </div>
            </div>
        </div>
    </aw-wizard-step>
</aw-wizard>

<!-- MODALS -->
<!-- Modal signature -->
<div #signatureModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header text-dark rounded-top">
                <h6 class="modal-title">Signature <b>Signer en dessous <i class="fas fa-arrow-down text-first"></i></b></h6>
                <button type="button" class="close" aria-label="Close" (click)="signatureModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div>
                    <app-signature></app-signature>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Partage -->
<div #partageVisite="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded">
        <div class="modal-content rounded border-0">

            <div class="p-3 text-center bg-secondary rounded-top">
                <h4 class="font-size-xl font-weight-bold mb-2">Partage de visite</h4>
                <p class="opacity-8 mb-0">Choisissez ceux a qui vous voulez envoyer</p>
            </div>
            <hr class="m-0">
            <div class="m-3">
                <input type="search" class="form-control" placeholder="Recherche dans la liste">
            </div>
            <perfect-scrollbar [autoPropagation]="true" class="scroll-area scroll-area-sm" style="height: 25em;">
                <div class="px-3">
                    <div class="border rounded-sm p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="align-box-row">
                                <div class="avatar-icon-wrapper avatar-icon-md">
                                    <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="pl-2">
                                    <span class="pb-1 d-block">John Doe</span>
                                    <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="custom-checkbox custom-control mt-1">
                                    <input class="custom-control-input" id="inputCheck1" type="checkbox" checked>
                                    <label class="custom-control-label" for="inputCheck1">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border rounded-sm p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="align-box-row">
                                <div class="avatar-icon-wrapper avatar-icon-md">
                                    <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="pl-2">
                                    <span class="pb-1 d-block">John Doe</span>
                                    <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="custom-checkbox custom-control mt-1">
                                    <input class="custom-control-input" id="inputCheck2" type="checkbox" checked>
                                    <label class="custom-control-label" for="inputCheck2">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border rounded-sm p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="align-box-row">
                                <div class="avatar-icon-wrapper avatar-icon-md">
                                    <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="pl-2">
                                    <span class="pb-1 d-block">John Doe</span>
                                    <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="custom-checkbox custom-control mt-1">
                                    <input class="custom-control-input" id="inputCheck3" type="checkbox" checked>
                                    <label class="custom-control-label" for="inputCheck3">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border rounded-sm p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="align-box-row">
                                <div class="avatar-icon-wrapper avatar-icon-md">
                                    <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="pl-2">
                                    <span class="pb-1 d-block">John Doe</span>
                                    <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="custom-checkbox custom-control mt-1">
                                    <input class="custom-control-input" id="inputCheck4" type="checkbox" checked>
                                    <label class="custom-control-label" for="inputCheck4">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border rounded-sm p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="align-box-row">
                                <div class="avatar-icon-wrapper avatar-icon-md">
                                    <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="pl-2">
                                    <span class="pb-1 d-block">John Doe</span>
                                    <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="custom-checkbox custom-control mt-1">
                                    <input class="custom-control-input" id="inputCheck5" type="checkbox" checked>
                                    <label class="custom-control-label" for="inputCheck5">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </perfect-scrollbar>
            <hr class="m-0">
            <div class="p-3 text-center bg-secondary rounded-bottom">
                <button class="btn btn-first"><i class="fas fa-paper-plane"></i> Envoyer</button>
            </div>
        </div>
    </div>
</div>