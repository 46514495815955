import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { ReunionPagesRoutingModule } from './reunion-pages-routing.module';
import { ReunionPagesComponent } from './reunion-pages.component';
import { ReunionDetailComponent } from './reunion-detail/reunion-detail.component';
import { ReunionEditComponent } from './reunion-edit/reunion-edit.component';
import { ReunionListComponent } from './reunion-list/reunion-list.component';
import { RouterModule } from '@angular/router';
import { AccordionModule, BsDatepickerModule, ModalModule, PaginationModule, PopoverModule, TabsModule, TooltipModule } from 'ngx-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CountUpModule } from 'countup.js-angular2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { SharedComponentsModule } from 'src/app/shared-components/shared-components.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { IntervenantPagesModule } from '../intervenant-pages/intervenant-pages.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';


@NgModule({
  declarations: [
    ReunionPagesComponent,
    ReunionDetailComponent,
    ReunionEditComponent,
    ReunionListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TabsModule,
    TooltipModule,
    ModalModule,
    FontAwesomeModule,
    HttpClientModule,
    PaginationModule,
    ReunionPagesRoutingModule,
    CountUpModule,
    AccordionModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule,
    BsDatepickerModule,
    ArchwizardModule,
    PerfectScrollbarModule,
    AngularEditorModule,
    SweetAlert2Module,

    SharedComponentsModule,
    IntervenantPagesModule,
  ],
  exports: [
    ReunionDetailComponent,
    ReunionEditComponent,
    ReunionListComponent
  ]
})
export class ReunionPagesModule { }
