import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BulletinHsePagesRoutingModule } from './bulletin-hse-pages-routing.module';
import { BulletinHseDetailComponent } from './bulletin-hse-detail/bulletin-hse-detail.component';
import { BulletinHseEditComponent } from './bulletin-hse-edit/bulletin-hse-edit.component';
import { BulletinHseListComponent } from './bulletin-hse-list/bulletin-hse-list.component';
import { BulletinHsePagesComponent } from './bulletin-hse-pages.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PaginationModule, PopoverModule, TooltipModule } from 'ngx-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { ArchwizardModule } from 'angular-archwizard';


@NgModule({
  declarations: [
    BulletinHsePagesComponent,
    BulletinHseDetailComponent,
    BulletinHseEditComponent,
    BulletinHseListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    PaginationModule,
    PopoverModule,
    PerfectScrollbarModule,
    NgSelectModule,
    TooltipModule,
    ArchwizardModule,
    BulletinHsePagesRoutingModule
  ],
  exports: [
    BulletinHseDetailComponent,
    BulletinHseEditComponent,
    BulletinHseListComponent
  ],
})
export class BulletinHsePagesModule { }
