import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReserveDetailComponent } from './reserve-detail/reserve-detail.component';
import { ReserveListComponent } from './reserve-list/reserve-list.component';
import { ReservePagesComponent } from './reserve-pages.component';


const routes: Routes = [
  {
    path:'',
    component: ReservePagesComponent,
    children: [
      {
        path: "liste",
        component: ReserveListComponent
      },
      {
        path: "detail/:id",
        component: ReserveDetailComponent
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReservePagesRoutingModule { }
