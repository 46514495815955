import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BureauEtudePagesRoutingModule } from './bureau-etude-pages-routing.module';
import { BureauEtudePagesComponent } from './bureau-etude-pages.component';
import { RouterModule } from '@angular/router';
import { BureauEtudeDashboardComponent } from './bureau-etude-dashboard/bureau-etude-dashboard.component';


@NgModule({
  declarations: [
    BureauEtudePagesComponent,
    BureauEtudeDashboardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BureauEtudePagesRoutingModule
  ],
  exports: [
    BureauEtudeDashboardComponent
  ],
})
export class BureauEtudePagesModule { }
