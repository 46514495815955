import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OpportunitePagesRoutingModule } from './opportunite-pages-routing.module';
import { OpportuniteDashboardComponent } from './opportunite-dashboard/opportunite-dashboard.component';
import { OpportuniteDetailComponent } from './opportunite-detail/opportunite-detail.component';
import { OpportuniteEditComponent } from './opportunite-edit/opportunite-edit.component';
import { OpportuniteListComponent } from './opportunite-list/opportunite-list.component';
import { OpportuniteKanbanComponent } from './opportunite-kanban/opportunite-kanban.component';
import { RouterModule } from '@angular/router';
import { AccordionModule, AlertModule, BsDatepickerModule, BsDropdownModule, ModalModule, PaginationModule, PopoverModule, ProgressbarModule, TabsModule, TooltipModule, TypeaheadModule } from 'ngx-bootstrap';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebook, faTwitter, faAngular, faVuejs, faReact, faHtml5, faGoogle, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faChevronRight, faSitemap, faPrint, faMapMarkerAlt, faTachometerAlt, faAlignCenter, faExternalLinkAlt, faShareSquare, faInfoCircle, faSync, faQuoteRight, faStarHalfAlt, faShapes, faCarBattery, faTable, faCubes, faPager,  faCameraRetro, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faUnlockAlt, faDownload, faAward, faPlayCircle, faReply, faUpload, faBars, faEllipsisV, faSave, faSlidersH, faCaretRight, faChevronUp, faPlus, faLemon, faChevronLeft, faTimes, faChevronDown, faFilm, faSearch, faEllipsisH, faCog, faArrowsAltH, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLink } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faTimesCircle, faDotCircle, faThumbsUp, faComments, faFolderOpen, faTrashAlt, faFileImage, faFileArchive, faLifeRing, faCommentDots, faFolder, faKeyboard, faCalendarAlt, faEnvelope, faAddressCard, faMap, faObjectGroup, faImages, faUser, faLightbulb, faGem, faClock, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar } from '@fortawesome/free-regular-svg-icons';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { ArchwizardModule } from 'angular-archwizard';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { CountUpModule } from 'countup.js-angular2';
import { NgApexchartsModule } from 'ng-apexcharts';
import { GaugeModule } from 'angular-gauge';
import { NgxGaugeModule } from 'ngx-gauge';
import { ChartsModule } from 'ng2-charts';
import { FeatherModule } from 'angular-feather';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DragulaModule } from 'ng2-dragula';
import { registerLocaleData } from '@angular/common';
import LocaleFr from '@angular/common/locales/fr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BadgeCellViewComponent } from 'src/app/shared-components/tree/badge-cell-view/badge-cell-view.component';
import { OpportunitePagesComponent } from './opportunite-pages.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
registerLocaleData(LocaleFr, 'fr')

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'uifort',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
     easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
     easing: 'ease'
    },
    overlap: 150
  }
};


@NgModule({
  declarations: [
    OpportunitePagesComponent,
    OpportuniteDashboardComponent,
    OpportuniteDetailComponent,
    OpportuniteEditComponent,
    OpportuniteListComponent,
    BadgeCellViewComponent,
    OpportuniteKanbanComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    OpportunitePagesRoutingModule,
    NgbModule,
    TabsModule,
    FontAwesomeModule,
    ModalModule,
    BsDatepickerModule,
    TooltipModule,
    AlertModule,
    PaginationModule,
    ProgressbarModule,
    BsDropdownModule,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    NgSelectModule,
    TextMaskModule,
    TypeaheadModule,
    ArchwizardModule,
    NotifierModule.withConfig(customNotifierOptions),
    CountUpModule,
    NgApexchartsModule,
    GaugeModule,
    NgxGaugeModule,
    ChartsModule,
    FeatherModule,
    CalendarModule.forRoot({provide: DateAdapter, useFactory: adapterFactory }),
    PopoverModule,
    AccordionModule,
    DragulaModule.forRoot(),
    SweetAlert2Module,
  ],
  exports: [
    OpportuniteDashboardComponent,
    OpportuniteDetailComponent,
    OpportuniteEditComponent,
    OpportuniteListComponent,
    OpportuniteKanbanComponent,
  ],
  entryComponents: [
    BadgeCellViewComponent,
    OpportuniteEditComponent,
  ],
})
export class OpportunitePagesModule {
  constructor(library: FaIconLibrary){
    library.addIcons(faInfoCircle, faFacebook, faPrint, faAlignCenter, faMapMarkerAlt, faTachometerAlt, faExternalLinkAlt, faShareSquare, faSitemap, faInfoCircle, faLifeRing, faTwitter, faQuoteRight, faStarHalfAlt, faSync, faShapes, faCarBattery, faTable, faCubes, faPager,  faAngular, faVuejs, faReact, faHtml5, faCheckCircle, faTimesCircle, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faThumbsUp, faCameraRetro, faUnlockAlt, faDownload, faUpload, faReply, faGoogle, faFileImage, faFolderOpen, faBars, faTrashAlt, faSave, faPlayCircle, faEllipsisV, faEllipsisH, faSlidersH, faFileArchive, faAward, faCaretRight, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub, faPlus, faFolder, faTimes, faEnvelope, faAddressCard, faMap, faCalendarAlt, faImages, faFilm, faClock, faSearch, faChevronRight, faChevronUp, faChevronLeft, faChevronDown, faLink, faLightbulb, faGem, faCog, faDotCircle, faArrowsAltH, faComments, faCommentDots, faKeyboard, faObjectGroup, faUser, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLemon);
  }
}
