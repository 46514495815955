import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-fichier-detail',
  templateUrl: './fichier-detail.component.html',
  styleUrls: ['./fichier-detail.component.scss']
})
export class FichierDetailComponent implements OnInit {

  constructor(
    public globals: ThemeOptions,
  ) { }

  ngOnInit() {
  }

}
