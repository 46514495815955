import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientPagesRoutingModule } from './client-pages-routing.module';
import { ClientPagesComponent } from './client-pages.component';
import { RouterModule } from '@angular/router';
import { ClientDashboardComponent } from './client-dashboard/client-dashboard.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BsDropdownModule, PaginationModule, TabsModule, ModalModule, TooltipModule, ProgressbarModule } from 'ngx-bootstrap';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';


@NgModule({
  declarations: [
    ClientPagesComponent,
    ClientDashboardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    BsDropdownModule,
    PaginationModule,
    PerfectScrollbarModule,
    ProgressbarModule,
    TabsModule,
    TooltipModule,
    ModalModule,
    NgxGaugeModule,
    NgApexchartsModule,
    NgCircleProgressModule,
    TabsModule,
    ClientPagesRoutingModule,
  ],
  exports: [
    ClientDashboardComponent
  ],
})
export class ClientPagesModule { }
