import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';
import { ThemeOptions } from 'src/app/theme-options';
import { Opportunite } from '../opportunite.model';
import { OpportuniteService } from '../opportunite.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OpportuniteEditComponent } from '../opportunite-edit/opportunite-edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
// import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-opportunite-list',
  templateUrl: './opportunite-list.component.html',
  styleUrls: ['./opportunite-list.component.scss']
})
export class OpportuniteListComponent implements OnInit {
  rotate = true;
  total = 0;
  currentPage = 1;
  itemsPerPage = 5;
  typeList = "liste"
  opportunites: Opportunite[] = [];
  opportuniteSubs = new Subscription; 
  private notifier: NotifierService;

  constructor(
    public route: Router,
    notifier: NotifierService,
    public globals: ThemeOptions,
    private modalService: NgbModal,
    private router: ActivatedRoute,
    private opportuniteService: OpportuniteService,
  ) {
    this.notifier = notifier;
  }

  ngOnInit() {
    this.onCalculator();
    this.loadOppoptunite();
  }

  loadOppoptunite(){
    this.opportunites = [];
    this.opportuniteSubs == this.opportuniteService.getList().subscribe(
      (res) => {
        this.opportunites = res;
      },
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }
  
  ngOnDestroy(): void {
    this.opportuniteSubs.unsubscribe();
    this.opportuniteService.setOpportunite("")
  }

  sendData(data, type) {
    const sender = {
      opportunite: data,
      type: type
    };
    this.opportuniteService.setOpportunite(sender)
  }

  addOpportunite(data, type): any {
    if(data) {
      this.sendData(data, type);
    }
    this.modalService.dismissAll();
    this.modal(OpportuniteEditComponent, 'modal-basic-title', 'xl', true, 'static');
  }

  showOpportunite(opportunite){
    this.opportuniteService.setOpportunite(opportunite);
    this.route.navigate(['/pages/opportunite/detail/' + opportunite.id]);
  }
  
  deleteOpportunite(id) {
    Swal.fire({
      title: 'Supprimer !',
      text: 'Voulez-vous vraiment supprimer cette opportunité ?',
      type: "warning",
      showCancelButton: true,
      showCloseButton: false,
      cancelButtonText: 'Annuler <i class="fas fa-times"></i>',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Supprimer <i class="fas fa-trash"></i>',
    }).then((deleted) => {
      if (deleted.dismiss) {
      } else {
        this.opportuniteService.getDelete(id).subscribe(
          res => {
            this.notifications('success', 'Enrégistrement supprimé avec succès !');
          }
        );
        this.loadOppoptunite()
      }
    });
  }

  pageChanged(event:any) {
    this.currentPage = event.page;
  }

  onCalculator(){
    this.opportunites.forEach( res => {
      this.total += (res.montant ? res.montant : 0)
    })
  }

  onSelectList(list){
    this.typeList = list;
  }

  modal(component, type, size, center, backdrop): any {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {
      if(result == 'success') {
        this.notifications('success', "L'opération s'est bien déroulé");
        this.loadOppoptunite();
      } else if (result == 'update') {
        this.notifications('success', "L'opération s'est bien déroulé");
        this.loadOppoptunite();
      }      
    }, (reason) => { });
  }

  notifications( type: string, message: string ): void {
    if (type == 'primary') {
      this.notifier.notify( type, message );
    } else if (type == 'success') {
      this.notifier.notify( type, message );
    } else if (type == 'warning') {
      this.notifier.notify( type, message );
    } else if (type == 'danger') {
      this.notifier.notify( type, message );
    } else if (type == 'dark') {
      this.notifier.notify( type, message );
    } else if (type == 'second') {
      this.notifier.notify( type, message );
    } else if (type == 'first') {
      this.notifier.notify( type, message );
    }
  }

}