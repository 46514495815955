import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';
import { ProjetPages } from 'src/app/models/projet-pages';
import { ProjetPagesService } from 'src/app/services/projet-pages/projet-pages.service';
import { ThemeOptions } from 'src/app/theme-options';
import Swal from 'sweetalert2';
import { ProjetEditComponent } from '../projet-edit/projet-edit.component';
import { Projet } from '../projet.model';
import { ProjetService } from '../projet.service';

@Component({
  selector: 'app-projet-list',
  templateUrl: './projet-list.component.html',
  styleUrls: ['./projet-list.component.scss']
})
export class ProjetListComponent implements OnInit {
  rotate = true;
  total = 0;
  currentPage = 1;
  itemsPerPage = 5;
  projets: Projet[] = []
  projetSubs = new Subscription; 
  private notifier: NotifierService;

  constructor(
    notifier: NotifierService,
    public route: Router,
    public globals: ThemeOptions,
    private modalService: NgbModal,
    private router: ActivatedRoute,
    private projetService: ProjetService,
  ) {
    this.notifier = notifier;
  }

  ngOnInit() {
    this.loadProjet();
    this.onCalculator();
  }
  
  loadProjet(){
    this.projets = [];
    this.projetSubs == this.projetService.getList().subscribe(
      (res) => {this.projets = res;},
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  sendData(data, type) {
    const sender = {
      projet: data,
      type: type
    };
    this.projetService.setProjet(sender)
  }

  addProjet(data, action): any {
    if(data) {
      this.sendData(data, action);
    }
    this.modalService.dismissAll();
    this.modal(ProjetEditComponent, 'modal-basic-title', 'xl', true, 'static');
  }

  showProjet(projet){
    this.projetService.setProjet(projet);
    this.route.navigate(['/pages/projet/detail/' + projet.id]);
  }
  
  deleteProjet(id) {
    Swal.fire({
      title: 'Supprimer !',
      text: 'Voulez-vous vraiment supprimer ce projet ?',
      type: "warning",
      showCancelButton: true,
      showCloseButton: false,
      cancelButtonText: 'Annuler <i class="fas fa-times"></i>',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Supprimer <i class="fas fa-trash"></i>',
    }).then((deleted) => {
      if (deleted.dismiss) {
      } else {
        this.projetService.onDelete(id).subscribe(
          res => {
            this.notifications('success', 'Enrégistrement supprimé avec succès !');
          }
        );
        this.loadProjet()
      }
    });
  }

  pageChanged(event:any) {
    this.currentPage = event.page;
  }

  onCalculator(){}
  
  ngOnDestroy(): void {
    this.projetSubs.unsubscribe();
    this.projetService.setProjet("")
  }

  modal(component, type, size, center, backdrop): any {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {
      if(result == 'success') {
        this.notifications('success', "L'opération s'est bien déroulé");
        this.loadProjet();
      } else if (result == 'update') {
        this.notifications('success', "L'opération s'est bien déroulé");
        this.loadProjet();
      }      
    }, (reason) => { });
  }
  
  notifications( type: string, message: string ): void {
    if (type == 'primary') {
      this.notifier.notify( type, message );
    } else if (type == 'success') {
      this.notifier.notify( type, message );
    } else if (type == 'warning') {
      this.notifier.notify( type, message );
    } else if (type == 'danger') {
      this.notifier.notify( type, message );
    } else if (type == 'dark') {
      this.notifier.notify( type, message );
    } else if (type == 'second') {
      this.notifier.notify( type, message );
    } else if (type == 'first') {
      this.notifier.notify( type, message );
    }
  }
}
