import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RessourcePagesRoutingModule } from './ressource-pages-routing.module';
import { RessourcePagesComponent } from './ressource-pages.component';
import { RessourceDashboardComponent } from './ressource-dashboard/ressource-dashboard.component';
import { RessourceDetailComponent } from './ressource-detail/ressource-detail.component';
import { RessourceEditComponent } from './ressource-edit/ressource-edit.component';
import { RessourceListComponent } from './ressource-list/ressource-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule, AlertModule, BsDatepickerModule, BsDropdownModule, ModalModule, PaginationModule, PopoverModule, ProgressbarModule, TabsModule, TooltipModule, TypeaheadModule } from 'ngx-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxGaugeModule } from 'ngx-gauge';
import { TextMaskModule } from 'angular2-text-mask';
import { CountUpModule } from 'countup.js-angular2';
import { NgSelectModule } from '@ng-select/ng-select';
import { BarRatingModule } from 'ngx-bar-rating';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AngularTreeGridModule } from 'angular-tree-grid';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'uifort',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
     easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
     easing: 'ease'
    },
    overlap: 150
  }
};
@NgModule({
  declarations: [
    RessourcePagesComponent,
    RessourceDashboardComponent,
    RessourceDetailComponent,
    RessourceEditComponent,
    RessourceListComponent,
  ],
  imports: [
    CommonModule,
    RessourcePagesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule,
    HttpClientModule,
    FontAwesomeModule,
    ModalModule,
    BsDatepickerModule,
    TooltipModule,
    AlertModule,
    PaginationModule,
    RouterModule,
    ProgressbarModule,
    ImageCropperModule,
    NotifierModule.withConfig(customNotifierOptions),
    AngularEditorModule,
    ChartsModule,
    NgApexchartsModule,
    NgxGaugeModule,
    TextMaskModule,
    CountUpModule,
    NgSelectModule,
    BarRatingModule,
    BsDropdownModule,
    PerfectScrollbarModule,
    AccordionModule.forRoot(),
    PopoverModule.forRoot(),
    AngularTreeGridModule,
    TypeaheadModule,
    NgbModule,
    SweetAlert2Module,
    NgxSpinnerModule,
    

  ],
  exports: [
    RessourceDetailComponent,
    RessourceEditComponent,
    RessourceListComponent,
  ],
  entryComponents: [
    RessourceEditComponent
  ]
})
export class RessourcePagesModule { }
