import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SituationHsePagesRoutingModule } from './situation-hse-pages-routing.module';
import { SituationHsePagesComponent } from './situation-hse-pages.component';
import { SituationHseDetailComponent } from './situation-hse-detail/situation-hse-detail.component';
import { SituationHseEditComponent } from './situation-hse-edit/situation-hse-edit.component';
import { SituationHseListComponent } from './situation-hse-list/situation-hse-list.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule, BsDatepickerModule, BsDropdownModule, ModalModule, PaginationModule, PopoverModule, TabsModule, TooltipModule } from 'ngx-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CountUpModule } from 'countup.js-angular2';
import { ArchwizardModule } from 'angular-archwizard';
import { HttpClientModule } from '@angular/common/http';
import { SituationHseDashboardComponent } from './situation-hse-dashboard/situation-hse-dashboard.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ReunionPagesModule } from '../reunion-pages/reunion-pages.module';
import { VisitePagesModule } from '../visite-pages/visite-pages.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { BulletinHsePagesModule } from '../bulletin-hse-pages/bulletin-hse-pages.module';


@NgModule({
  declarations: [
    SituationHsePagesComponent,
    SituationHseDetailComponent,
    SituationHseEditComponent,
    SituationHseListComponent,
    SituationHseDashboardComponent
  ],
  imports: [
    CommonModule,
    SituationHsePagesRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule,
    TooltipModule,
    TabsModule,
    BsDatepickerModule,
    FontAwesomeModule,
    PaginationModule,
    BsDropdownModule,
    CountUpModule,
    ArchwizardModule,
    NgApexchartsModule,
    PerfectScrollbarModule,
    PopoverModule,
    NgSelectModule,
    AccordionModule,

    ReunionPagesModule,
    VisitePagesModule,
    BulletinHsePagesModule,
  ],
  exports: [
    SituationHseDashboardComponent,
    SituationHseDetailComponent,
    SituationHseEditComponent,
    SituationHseListComponent
  ]
})
export class SituationHsePagesModule { }
