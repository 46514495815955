<div class="modal-header modal-dark  bg-plum-plate">
    <h6 class="modal-title">Formulaire de création d'un utilisateur</h6>
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row no-gutters">
        <div class="col-lg-12">
            <div class="bg-white rounded-left">

                <form [formGroup]="utilisateurForm" (ngSubmit)="onSubmit()">

                    <div class="p-4">
                        <ngx-spinner [fullScreen]="false" name="sp6" size="medium" type="timer"></ngx-spinner>
                        <!-- INFORMATION SUR L'UTILISATEUR -->
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <span>INFORMATION SUR L'UTILISATEUR</span>
                            </div>
                        </div>
                        <!-- <span class="title text-center">INFORMATION SUR L'UTILISATEUR</span> -->
                        <div class="divider mt-2 mb-2"></div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="nom">Nom <span class="text-danger">*</span></label>
                                    <input formControlName="nom" class="form-control" id="nom" type="text"
                                        placeholder="Entrez le nom">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="prenom">Prénom <span class="text-danger">*</span></label>
                                    <input formControlName="prenom" class="form-control" id="prenom" type="text"
                                        placeholder="Entrez le prenom">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group"><label> Genre <span
                                            class="text-danger">*</span></label>
                                    <div class="input-group">
                                        <select formControlName="genre" class="form-control">
                                            <option [ngValue]="null" disabled> Choisir le genre</option>
                                            <option *ngFor="let genre of listeGenre" [ngValue]="genre.value">
                                                {{ genre.libelle }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label>Date de naissance </label>
                                    <div class="input-group input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon>
                                            </span>
                                        </div>
                                        <input formControlName="dateNaissance" id="dateNaissance" name="dateNaiss"
                                            [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }"
                                            bsDatepicker class="form-control" placeholder="Date de naissance"
                                            type="text" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="pays"> Pays</label>
                                    <input formControlName="pays" class="form-control" id="pays" type="text"
                                        placeholder="Entrez le pays habité" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="ville"> Ville</label>
                                    <input formControlName="ville" class="form-control" id="ville" type="text"
                                        placeholder="Entrez la ville habité">
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="email"> Email <span class="text-danger">*</span></label>
                                    <div class="input-group mb-2">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">@</div>
                                        </div>
                                        <input formControlName="email" class="form-control" id="email" type="text"
                                            placeholder="Entrez l'adresse email">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="contact1"> Contact 1 <span class="text-danger">*</span></label>
                                    <div class="input-group mb-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-phone-alt"></i></span>
                                        </div>
                                        <input formControlName="contact1" class="form-control" id="contact1"
                                            [textMask]="{mask: maskPhone}" type="text"
                                            placeholder="Entrez un contact" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="contact2"> Contact 2</label>
                                    <div class="input-group mb-2">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-phone-alt"></i></span>
                                        </div>
                                        <input formControlName="contact2" class="form-control" id="contact2"
                                            [textMask]="{mask: maskPhone}" type="text"
                                            placeholder="Un second contact si possible" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="nomUtilisateur">Nom d'utilisateur <span class="text-danger">(6
                                            caractères minimum)*</span></label>
                                    <input formControlName="username" minlength="6" class="form-control"
                                        id="nomUtilisateur" type="text" placeholder="Entrez le Login"
                                        [readonly]="editMode">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for=""> Type d'utilisateur <span class="text-danger">*</span></label>
                                    <div class="input-group">
                                        <select formControlName="type" class="form-control">
                                            <option value="0"> Membre de l'entréprise</option>
                                            <option value="1"> Délégué de bureau d'étude</option>
                                            <option value="2"> Délégué fournisseur</option>
                                        </select>

                                        <!-- <button tooltip="Ajouter" placement="auto"  class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newTypeModal.show()">
                                        <span class="btn-wrapper--icon">
                                            <i class="ion-md-add"></i>
                                        </span>
                                    </button> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="profil">Image de profil </label>
                                    <div class="custom-file">
                                        <input formControlName="avatar" class="custom-file-input" id="singleFileUpload"
                                            ng2FileSelect type="file">
                                        <label class="custom-file-label" for="singleFileUpload">Choisir l'image</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- FIN DES INFORMATIONS SUR L'UTILISATEUR -->

                        <!-- INFORMATION SUR L'ENTREPRISE EMPLOYEUR -->
                        <!-- INFORMATION SUR L'ENTREPRISE EMPLOYEUR -->
                        <div class="divider mt-2 mb-2"></div>
                        <div class="d-flex justify-content-between mb-3">
                            <div>
                                <h4 class="font-weight-bold m-0">Informations sur l'entreprise employeur</h4>
                            </div>
                        </div>
                        <!-- <span class="title mt-4 text-center"> INFORMATION SUR L'ENTREPRISE EMPLOYEUR </span> -->
                        <div class="divider mt-2 mb-2"></div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <div class="position-relative form-group"><label for=""> Tiers</label>
                                    <div class="input-group">
                                        <select formControlName="tiers" class="form-control">
                                            <option [ngValue]="null" disabled>Choisir un tier</option>
                                            <option *ngFor="let tier of listeTiers" [ngValue]="tier['@id']">
                                                {{ tier.libelle }}
                                            </option>

                                        </select>

                                        <button tooltip="Ajouter" placement="auto"
                                            class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill"
                                            type="button" (click)="newTiersModal.show()">
                                            <span class="btn-wrapper--icon">
                                                <i class="ion-md-add"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="service"> Service</label>
                                    <div class="input-group">
                                        <select formControlName="service" class="form-control">
                                            <option [ngValue]="null" disabled>Choisir un service</option>
                                            <option *ngFor="let service of listeService" [ngValue]="service['@id']">
                                                {{ service.libelle }}
                                            </option>
                                        </select>
                                        <button tooltip="Ajouter un service" placement="auto"
                                            class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill"
                                            type="button" (click)="newServiceModal.show()">
                                            <span class="btn-wrapper--icon">
                                                <i class="ion-md-add"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="hierachique"> Supérieur hierachique</label>
                                    <input formControlName="superieur" class="form-control" id="contact1" type="text">
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="adresseGeo"> Adresse geographique</label>
                                    <input formControlName="adresse" type="text" id="adresseGeo" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group"><label for=""> Bureau d'étude</label>
                                    <div class="input-group">
                                        <select formControlName="bureau" class="form-control">
                                            <option [ngValue]="null" disabled>Choisir un bureau d'étude </option>
                                            <option *ngFor="let bureauEtude of listeBureauEtude"
                                                [ngValue]="bureauEtude['@id']">
                                                {{ bureauEtude.libelle }}
                                            </option>
                                        </select>

                                        <button tooltip="Ajouter" placement="auto"
                                            class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill"
                                            type="button" (click)="newBureauModal.show()">
                                            <span class="btn-wrapper--icon">
                                                <i class="ion-md-add"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="fonction"> Fonction occupée</label>
                                    <input formControlName="fonction" class="form-control" id="fonction" type="text">
                                </div>
                            </div>
                        </div>
                        <!-- FIN DES INFORMATIONS SUR L'ENTREPRISE EMPLOYEUR -->
                        <!-- <div class="text-right">
                        <button (click)="onCancel()"  class="btn btn-secondary mr-2" type="button">Annuler</button>
                        <button  [disabled]="!utilisateurForm.valid" class="btn btn-success" type="submit" >Enregistrer</button>
    
                    </div> -->
                    </div>
                    <hr class="m-0">
                    <div class=" px-4 py-2">
                        <div class="row">
                            <div class="col-md-6">
                                <p class="text-danger mt-2">L'étoile (*) signifie que cet champ est obligatoire.</p>
                            </div>
                            <div class="col-md-6 text-right">
                                <button (click)="onCancel()" class="btn btn-secondary mr-2"
                                    type="button">Annuler</button>
                                <button [disabled]="!utilisateurForm.valid" class="btn btn-success"
                                    type="submit">Enregistrer</button>

                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>




<!-- TIERS -->
<div #newTiersModal="bs-modal" backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-none">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter un tier</h6>
                <button type="button" class="close" aria-label="Close" (click)="newTiersModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <form [formGroup]="tier" (ngSubmit)="addTier()">
                        <div class="form-row">
                            <div class="col-md-12">
                                <div class="position-relative form-group">
                                    <label for="nonTiers">Nom <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" id="nonTiers" formControlName="nonTiers"
                                        placeholder="Entrer le nom du tier"
                                        [ngClass]="{ 'is-invalid': submit && tif.nonTiers.errors }">
                                    <div *ngIf="submit && tif.nonTiers.errors" class="invalid-feedback">
                                        <div *ngIf="tif.nom.errors.required">{{required.novide}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="position-relative form-group">
                                    <label for="localisationTiers">Localisation <span
                                            class="text-danger">*</span></label>
                                    <input type="text" class="form-control" id="localisationTiers"
                                        formControlName="localisationTiers" placeholder="Entrer la localisation du tier"
                                        [ngClass]="{ 'is-invalid': submit && tif.localisationTiers.errors }">
                                    <div *ngIf="submit && tif.localisationTiers.errors" class="invalid-feedback">
                                        <div *ngIf="tif.nom.errors.required">{{required.novide}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <hr class="m-0">
                <div class="text-center px-3 py-2">
                    <button class="btn btn-first m-2" type="button" (click)="tier.reset()">
                        <i class="fas fa-broom mr-1"></i>
                        Nettoyer
                    </button>
                    <button class="btn btn-success" type="submit" (click)="addTier()">
                        <i class="fas fa-save mr-1"></i>
                        Enregister
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- BUREAU D'ETUDE -->
<div #newBureauModal="bs-modal" backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-none">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter un bureau d'etude</h6>
                <button type="button" class="close" aria-label="Close" (click)="newBureauModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <form [formGroup]="bureau">
                        <div class="form-row">
                            <div class="col-md-12">
                                <div class="position-relative form-group">
                                    <label for="nonBureau">Nom <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" id="nonBureau" formControlName="nonBureau"
                                        placeholder="Entrer le nom du bureau d'etude"
                                        [ngClass]="{ 'is-invalid': submit && bef.nonBureau.errors }">
                                    <div *ngIf="submit && bef.nonBureau.errors" class="invalid-feedback">
                                        <div *ngIf="bef.nom.errors.required">{{required.novide}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="position-relative form-group">
                                    <label for="localisationBureau">Localisation <span
                                            class="text-danger">*</span></label>
                                    <input type="text" class="form-control" id="localisationBureau"
                                        formControlName="localisationBureau"
                                        placeholder="Entrer la localisation du bureau d'etude"
                                        [ngClass]="{ 'is-invalid': submit && bef.localisationBureau.errors }">
                                    <div *ngIf="submit && bef.localisationBureau.errors" class="invalid-feedback">
                                        <div *ngIf="bef.nom.errors.required">{{required.novide}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <hr class="m-0">
                <div class="text-center px-3 py-2">
                    <button class="btn btn-first m-2" type="button" (click)="bureau.reset()">
                        <i class="fas fa-broom mr-1"></i>
                        Nettoyer
                    </button>
                    <button class="btn btn-success" type="submit" (click)="addBureau()">
                        <i class="fas fa-save mr-1"></i>
                        Enregister
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- SERVICE -->
<div #newServiceModal="bs-modal" backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-none">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter un service</h6>
                <button type="button" class="close" aria-label="Close" (click)="newServiceModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <form [formGroup]="service">
                        <div class="form-row">
                            <div class="col-md-12">
                                <div class="position-relative form-group">
                                    <label for="serviceLibelle">Libellé service <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" id="serviceLibelle" formControlName="serviceLibelle"
                                        placeholder="Entrer le nom du bureau d'etude"
                                        [ngClass]="{ 'is-invalid': submit && servicef.serviceLibelle.errors }">
                                    <div *ngIf="submit && servicef.serviceLibelle.errors" class="invalid-feedback">
                                        <div *ngIf="servicef.serviceLibelle.errors.required">{{required.novide}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <hr class="m-0">
                <div class="text-center px-3 py-2">
                    <button class="btn btn-first m-2" type="button" (click)="service.reset()">
                        <i class="fas fa-broom mr-1"></i>
                        Nettoyer
                    </button>
                    <button class="btn btn-success" type="submit" (click)="addService()">
                        <i class="fas fa-save mr-1"></i>
                        Enregister
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>




<notifier-container></notifier-container>