import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { ModalDirective } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UtilOptions } from 'src/app/shared-components/util/util-option';
import { Architect } from '../../acteur-externe-pages/architect-pages/architect.model';
import { ArchitectService } from '../../acteur-externe-pages/architect-pages/architect.service';
import { ResponsableControl } from '../../acteur-externe-pages/responsable-control-page/responsable-control.model';
import { ResponsableControlService } from '../../acteur-externe-pages/responsable-control-page/responsable-control.service';
import { ResponsableEtude } from '../../acteur-externe-pages/responsable-etude-page/responsable-etude.model';
import { ResponsableEtudeService } from '../../acteur-externe-pages/responsable-etude-page/responsable-etude.service';
import { MaitreService } from '../../maitre/maitre-service';
import { Maitre } from '../../maitre/maitre.model';
import { Banque, Caisse } from '../../tresorerie-pages/tresorerie.model';
import { TresorerieService } from '../../tresorerie-pages/tresorerie.service';
import { Utilisateur } from '../../utilisateur-pages/utilisateur.model';
import { UtilisateurService } from '../../utilisateur-pages/utilisateur.service';
import { ChefProjet, Membre, TypeProjet } from '../projet.model';
import { ProjetService } from '../projet.service';

@Component({
  selector: 'app-projet-edit',
  templateUrl: './projet-edit.component.html',
  styleUrls: ['./projet-edit.component.scss']
})
export class ProjetEditComponent implements OnInit {
  @ViewChild('newType', {static: false})  public newType: ModalDirective;
  @ViewChild('newChef', {static: false})  public newChef: ModalDirective;
  @ViewChild('newMembre', {static: false})  public newMembre: ModalDirective;
  @ViewChild('newArchitect', {static: false})  public newArchitect: ModalDirective;
  @ViewChild('newRespoEtude', {static: false})  public newRespoEtude: ModalDirective;
  @ViewChild('newRespoControl', {static: false})  public newRespoControl: ModalDirective;

  title: string = 'Ajouter nu nouveau projet';
  imgUrl: any;
  projets: any;
  form: FormGroup;
  typeForm: FormGroup;
  chefForm: FormGroup;
  membreForm: FormGroup;
  architectForm: FormGroup;
  respoEtudeForm: FormGroup;
  respoControlForm: FormGroup;
  submit: boolean = false;
  editMode: boolean = false;
  required = UtilOptions.required;
  private notifier: NotifierService;

  //Type de projet
  typeS = new Subscription;
  typeSubs = new Subscription;
  listeTypes: TypeProjet[] = [];

  //Maitre d'ouvrage
  maitre = new Subscription;
  maitres = new Subscription;
  listeMaitre: Maitre[] = [];

  //Caisse
  caisse = new Subscription;
  caisses = new Subscription;
  listeCaisses: Caisse[] = [];

  //Banque
  banque = new Subscription;
  banques = new Subscription;
  listeBanques: Banque[] = [];

  // Chefs de projet
  chefSub = new Subscription;
  chefSubs = new Subscription;
  listeChefs: ChefProjet[] = [];

  //Utilisateur
  user = new Subscription;
  users = new Subscription;
  listeUser: Utilisateur[] = [];

  //Architect
  architectSub = new Subscription;
  architectSubs = new Subscription;
  listeArchitect: Architect[] = [];

  //Responsable d'etude
  responsableEtudeSub = new Subscription;
  responsableEtudeSubs = new Subscription;
  listeResponsableEtude: ResponsableEtude[] = [];

  //Responsable de controlle
  responsableControlSub = new Subscription;
  responsableControlSubs = new Subscription;
  listeResponsableControl: ResponsableControl[] = [];

  //Membre du projet
  membreSub = new Subscription;
  membreSubs = new Subscription;
  listeMembre: Membre[] = [];

  constructor(
    notifier: NotifierService,
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private projetService: ProjetService,
    private maitreService: MaitreService,
    private architectService: ArchitectService,
    private tresorerieService: TresorerieService,
    private utilisateurService: UtilisateurService,
    private responsableEtudeService: ResponsableEtudeService,
    private responsableControlService: ResponsableControlService,
  ) {
    this.getData()
    this.imgUrl = [];
    this.notifier = notifier;
  }

  ngOnInit() {
    this.newTypeForm()
    this.loadType()
    this.newChefForm()
    this.loadChefProjet()
    this.newRespoEtudeForm()
    this.loadRespoEtude()
    this.newRespoControlForm()
    this.loadRespoControl()
    this.newArchitectForm()
    this.loadArchitect()
    this.newMembreForm()
    this.loadMembre()
    this.loadMaitreO()
    this.loadCaisse()
    this.loadBanque()
  }

  // Formulaire de creation de type de projet
  newTypeForm(){
    this.typeForm = this.formBuilder.group({
      id: [null],
      libelle: [null, [Validators.required]]
    })
  }

  // Formulaire de creation de chef de projet
  newChefForm(){
    this.chefForm = this.formBuilder.group({
      id: [null],
      utilisateur: [null, [Validators.required]]
    })
  }

  // Formulaire de creation d'architect'
  newArchitectForm(){
    this.architectForm = this.formBuilder.group({
      id: [null],
      utilisateur: [null, [Validators.required]]
    })
  }

  // Formulaire de creation de responsable d'etude
  newRespoEtudeForm(){
    this.respoEtudeForm = this.formBuilder.group({
      id: [null],
      utilisateur: [null, [Validators.required]]
    })
  }

  // Formulaire de creation de responsable de control
  newRespoControlForm(){
    this.respoControlForm = this.formBuilder.group({
      id: [null],
      utilisateur: [null, [Validators.required]]
    })
  }

  // Formulaire de creation de membre
  newMembreForm(){
    this.membreForm = this.formBuilder.group({
      id: [null],
      utilisateur: [null, [Validators.required]]
    })
  }

  initForm(){
    this.form = new FormGroup({
      "id" : new FormControl(null, [Validators.required]),
      "type": new FormControl("", [Validators.required]),
      "pays": new FormControl("", [Validators.required]),
      "ville": new FormControl("", [Validators.required]),
      "commune": new FormControl("", [Validators.required]),
      "quartier": new FormControl("", [Validators.required]),
      "photo": new FormControl("", [Validators.required]),
      "marche": new FormControl("", [Validators.required]),
      "caisse": new FormControl("", [Validators.required]),
      "banque": new FormControl("", [Validators.required]),
      "numero": new FormControl("", [Validators.required]),
      "libelle": new FormControl("", [Validators.required]),
      "dateDebut": new FormControl("", [Validators.required]),
      "dateFin": new FormControl("", [Validators.required]),
      "longitude": new FormControl("", [Validators.required]),
      "latitude": new FormControl("", [Validators.required]),
      "chefProjet": new FormControl("", [Validators.required]),
      "architecte": new FormControl("", [Validators.required]),
      "description": new FormControl("", [Validators.required]),
      "membreProjet": new FormControl("", [Validators.required]),
      "maitreOuvrage": new FormControl("",[Validators.required]),
      "responsableEtude": new FormControl("", [Validators.required]),
      "responsableControle": new FormControl("", [Validators.required]),
    });
  }

  editForm(data) {
    this.initForm()
    this.title = "Modifier le projet " + data.libelle
    this.form.patchValue({
      id: data.id,
      pays: data.pays,
      ville: data.ville,
      commune: data.commune,
      quartier: data.quartier,
      photo: data.photo,
      marche: data.marche,
      numero: data.numero,
      libelle: data.libelle,
      dateDebut: new Date(data.dateDebut),
      dateFin: new Date(data.dateFin),
      longitude: data.longitude,
      latitude: data.latitude,
      description: data.description,
      type: data.type['@id'] ? data.type['@id'] : '',
      caisse: data.caisse['@id'] ? data.caisse['@id'] : '',
      banque: data.banque['@id'] ? data.banque['@id'] : '',
      chefProjet: data.chefProjet['@id'] ? data.chefProjet['@id'] : '',
      architecte: data.architecte['@id'] ? data.architecte['@id'] : '',
      membreProjet: data.membreProjet['@id'] ? data.membreProjet['@id'] : '',
      maitreOuvrage: data.maitreOuvrage['@id'] ? data.maitreOuvrage['@id'] : '',
      responsableEtude: data.responsableEtude['@id'] ? data.responsableEtude['@id'] : '',
      responsableControle: data.responsableControle['@id'] ? data.responsableControle['@id'] : '',
    });
  }

  // Chargement de type de projet de type de projet
  loadType(){  
    this.typeSubs = this.projetService.getTypeProjet().subscribe(
      (res) => {this.listeTypes = res},
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  loadMaitreO(){  
    this.maitres = this.maitreService.getList().subscribe(
      (res) => {
        this.listeMaitre = res
        console.log("Liste maitre ouvrage", this.listeMaitre)
      },
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  loadCaisse(){  
    this.caisses = this.tresorerieService.getCaisse().subscribe(
      (res) => { 
        this.listeCaisses = res
        console.log("Liste maitre caisse", this.listeCaisses)
      },
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  loadBanque(){  
    this.banques = this.tresorerieService.getBanque().subscribe(
      (res) => {
        this.listeBanques = res
        console.log("Liste maitre banque", this.listeBanques)
      },
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  // Chargement de chef de projet de type de projet
  loadChefProjet(){  
    this.chefSubs = this.projetService.getChefProjet().subscribe(
      (res) => { this.listeChefs = res},
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  // Chargement architect
  loadArchitect(){  
    this.architectSubs = this.architectService.getList().subscribe(
      (res) => { this.listeArchitect = res},
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  // Chargement responsable d'etude
  loadRespoEtude(){  
    this.responsableEtudeSubs = this.responsableEtudeService.getList().subscribe(
      (res) => { this.listeResponsableEtude = res},
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  // Chargement responsable de control
  loadRespoControl(){  
    this.responsableControlSubs = this.responsableControlService.getList().subscribe(
      (res) => { this.listeResponsableControl = res},
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  // Chargement des membre du projet
  loadMembre(){  
    this.membreSubs = this.projetService.getMembre().subscribe(
      (res) => { this.listeMembre = res},
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  loadUser(){  
    this.users = this.utilisateurService.listerUtilisateurs().subscribe(
      (resData:Utilisateur) => {
        this.listeUser.push(resData)
      },
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }
  
  // "onSelectImg" pour la selection des image dans le formulaire et "deleteImg" pour la suppression
  onSelectImg(e){
    if(e.target.files){
      var reader = new FileReader();
      var file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload=(events:any)=>{
        this.imgUrl.push(events.target.result);
      }
    }
  }

  deleteImg(el) {
    this.imgUrl.forEach((value,index) => {
      if(value == el)
        this.imgUrl.splice(index, 1);
    });
  }

  // Ajout type projet
  submitType() {
    this.typeS = this.projetService.createTypeProjet(
      {
        nom: this.typeForm.value.nom,
      }
    ).subscribe(
      (response: TypeProjet) => {
        this.closeTypeModal();
        this.listeTypes = [];
        this.typeForm.reset();
        this.loadType();
      },
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  // Ajout chef projet
  submitChef() {
    const formData = {
      "utilisateur":this.chefForm.value.utilisateur
    }
    this.chefSub = this.projetService.createChefProjet(formData).subscribe(
      (response: TypeProjet) => {
        this.closeChefModal();
        this.listeChefs = [];
        this.chefForm.reset();
        this.loadChefProjet();
      },
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  // Ajout chef projet
  submitArchitect() {
    const formData = {
      "utilisateur":this.architectForm.value.utilisateur
    }
    this.chefSub = this.architectService.create(formData).subscribe(
      (response: Architect) => {
        this.closeArchitectModal();
        this.listeArchitect = [];
        this.architectForm.reset();
        this.loadArchitect();
      },
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  // Ajout responsable d'etude
  submitRespoEtude() {
    const formData = {
      "utilisateur":this.respoEtudeForm.value.utilisateur
    }
    this.responsableEtudeSub = this.responsableEtudeService.create(formData).subscribe(
      (response: Architect) => {
        this.closeRespoEtudeModal();
        this.listeResponsableEtude = [];
        this.respoEtudeForm.reset();
        this.loadRespoEtude();
      },
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  // Ajout responsable de control
  submitRespoControl() {
    const formData = {
      "utilisateur":this.respoControlForm.value.utilisateur
    }
    this.responsableControlSub = this.responsableControlService.create(formData).subscribe(
      (response: Architect) => {
        this.closeRespoControlModal();
        this.listeResponsableControl = [];
        this.respoControlForm.reset();
        this.loadRespoControl();
      },
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  // Ajout membre
  submitMembre() {
    const formData = {
      "utilisateur":this.membreForm.value.utilisateur
    }
    this.membreSub = this.projetService.createMembre(formData).subscribe(
      (response: Membre) => {
        this.closeMembreModal();
        this.listeMembre = [];
        this.membreForm.reset();
        this.loadMembre();
      },
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  onSubmit() {
    this.submit = true;
    console.log(this.form.value);
    this.spinner.show("sp6");
    if (this.editMode) {
      this.projetService.update(
        this.projets.projet.id,
        {
          pays: this.form.value.pays,
          ville: this.form.value.ville,
          commune: this.form.value.commune,
          quartier: this.form.value.quartier,
          photo: this.form.value.photo,
          marche: this.form.value.marche,
          caisse: this.form.value.caisse,
          banque: this.form.value.banque,
          numero: this.form.value.numero,
          libelle: this.form.value.libelle,
          dateDebut: new Date(this.form.value.dateDebut),
          dateFin: new Date(this.form.value.dateFin),
          longitude: this.form.value.longitude,
          latitude: this.form.value.latitude,
          description: this.form.value.description,
          type: this.form.get('type').value,
          chefProjet: this.form.get('chefProjet').value,
          architecte: this.form.get('architecte').value,
          membreProjet: this.form.get('membreProjet').value,
          maitreOuvrage: this.form.get('maitreOuvrage').value,
          responsableEtude: this.form.get('responsableEtude').value,
          responsableControle: this.form.get('responsableControle').value,
        }
      ).subscribe(
        resData => {
          if(resData) {
            this.spinner.hide("sp6");
            this.notifications( 'success', 'Enregistrement effectué avec success.');
            this.modal.close('update');
          }         
        },
        errorRes => {
          this.spinner.hide("sp6");
          this.notifications( 'danger', 'Enregistrement echoué!.');
        }
      );
    }
    else{
      this.projetService.create(
        {
          pays: this.form.value.pays,
          ville: this.form.value.ville,
          commune: this.form.value.commune,
          quartier: this.form.value.quartier,
          photo: this.form.value.photo,
          marche: this.form.value.marche,
          caisse: this.form.value.caisse,
          banque: this.form.value.banque,
          numero: this.form.value.numero,
          libelle: this.form.value.libelle,
          dateDebut: new Date(this.form.value.dateDebut),
          dateFin: new Date(this.form.value.dateFin),
          longitude: this.form.value.longitude,
          latitude: this.form.value.latitude,
          description: this.form.value.description,
          type: this.form.get('type').value,
          chefProjet: this.form.get('chefProjet').value,
          architecte: this.form.get('architecte').value,
          membreProjet: this.form.get('membreProjet').value,
          maitreOuvrage: this.form.get('maitreOuvrage').value,
          responsableEtude: this.form.get('responsableEtude').value,
          responsableControle: this.form.get('responsableControle').value,
        }
      ).subscribe(
        resData => {
          if(resData) {
            this.spinner.hide("sp6");
            this.notifications( 'success', 'Enregistrement effectué avec success.');
            this.modal.close('success');
          }         
        },
        errorRes => {
          this.spinner.hide("sp6");
          this.notifications( 'danger', 'Enregistrement echoué!.');
        }
      );
    }
  }
  getData() {
    this.projets = this.projetService.getProjet();
    if(this.projets) {
      this.editMode = this.projets.type;
    } else {
      this.editMode = false;
    }
    if(this.editMode == true) {
      this.editForm(this.projets.projet);
    } else {
      this.initForm();
    }
  }

  ngOnDestroy(): void {
    this.caisses.unsubscribe();
    this.typeSubs.unsubscribe();
    this.chefSubs.unsubscribe();
    this.banques.unsubscribe();
    this.architectSubs.unsubscribe();
    this.responsableEtudeSubs.unsubscribe();
    this.responsableControlSubs.unsubscribe();
  }

  closeTypeModal(){
    this.newType.hide();
  }

  closeChefModal(){
    this.newChef.hide();
  }

  closeArchitectModal(){
    this.newArchitect.hide();
  }

  closeRespoEtudeModal(){
    this.newRespoEtude.hide();
  }

  closeRespoControlModal(){
    this.newRespoControl.hide();
  }

  closeMembreModal(){
    this.newMembre.hide();
  }

  onClose(): any{
    this.initForm();
    this.form.reset()
    this.modal.close();
    this.editMode = false;
    this.projets = "";
    this.projetService.setProjet("")
  }
  
  notifications( type: string, message: string ): void {
    if (type == 'primary') {
      this.notifier.notify( type, message );
    } else if (type == 'success') {
      this.notifier.notify( type, message );
    } else if (type == 'warning') {
      this.notifier.notify( type, message );
    } else if (type == 'danger') {
      this.notifier.notify( type, message );
    } else if (type == 'dark') {
      this.notifier.notify( type, message );
    } else if (type == 'second') {
      this.notifier.notify( type, message );
    } else if (type == 'first') {
      this.notifier.notify( type, message );
    }
  }

  get f() { return this.form.controls; }
  get type() { return this.typeForm.controls; }
  get chef() { return this.chefForm.controls; }
  get architect() { return this.architectForm.controls; }
}
