
<div class="mb-3" *ngIf="widgetView">
    <div class="row">
        <div class="col">
            <a [routerLink]="" class="card card-box border-0 rounded p-2" title="Total des travaux">
                <div class="d-flex align-items-center mt-1">
                    <h1 class="m-0">
                        <i class="fas fa-briefcase img-fluid mx-3 mt-1 text-first"></i>
                    </h1>
                    <div>
                        <div class="text-black-50">Total</div>
                        <div class="font-weight-bold display-4 py-1 text-black">
                            <span [countUp]="874"></span>
                        </div>
                        <div class="text-black-50 font-size-sm">
                            <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1 text-warning"></fa-icon>
                            100%
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a [routerLink]="" class="card card-box border-0 rounded p-2" title="Les travaux terminés">
                <div class="d-flex align-items-center">
                    <ngx-gauge append="%"
                        backgroundColor="#edeef1"
                        cap="round"
                        class="img-fluid mr-2 text-black"
                        foregroundColor="#1bc943"
                        max="100" min="0" size="84"
                        thick="6"
                        type="full" value="76">
                    </ngx-gauge>
                    <div>
                        <div class="text-black-50">Terminés</div>
                        <div class="font-weight-bold display-4 py-1 text-success">
                            <span [countUp]="90"></span>
                        </div>
                        <div class="text-black-50 font-size-sm">
                            <fa-icon [icon]="['far', 'check-circle']" class="font-size-sm pr-1 text-warning"></fa-icon>
                            90 / 874
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a [routerLink]="" class="card card-box border-0 rounded p-2" title="Les travaux en cours de réalisation">
                <div class="d-flex align-items-center">
                    <ngx-gauge append="%"
                        backgroundColor="#edeef1"
                        cap="round"
                        class="img-fluid mr-2 text-black"
                        foregroundColor="#4191ff"
                        max="100" min="0" size="84"
                        thick="6"
                        type="full" value="76">
                    </ngx-gauge>
                    <div>
                        <div class="text-black-50">En cours</div>
                        <div class="font-weight-bold display-4 py-1 text-first">
                            <span [countUp]="574"></span>
                        </div>
                        <div class="text-black-50 font-size-sm">
                            <i class="fas fa-pause-circle font-size-sm pr-1 text-success"></i>
                            574 / 874
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a [routerLink]="" class="card card-box border-0 rounded p-2" title="Les travaux en attente de réalisation">
                <div class="d-flex align-items-center">
                    <ngx-gauge append="%"
                        backgroundColor="#edeef1"
                        cap="round"
                        class="img-fluid mr-2 text-black"
                        foregroundColor="#f4772e"
                        max="100" min="0" size="84"
                        thick="6"
                        type="full" value="76">
                    </ngx-gauge>
                    <div>
                        <div class="text-black-50">En attente</div>
                        <div class="font-weight-bold display-4 py-1 text-warning">
                            <span [countUp]="210"></span>
                        </div>
                        <div class="text-black-50 font-size-sm">
                            <fa-icon [icon]="['fas', 'play-circle']" class="font-size-sm pr-1 text-first"></fa-icon>
                            210 / 874
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>

<div class="card card-box border-0">
    <div class="card-header p-3">
        <div class="card-header--title"><h6 class="font-weight-bold m-0 text-black">Liste des travaux</h6></div>
        <div class="card-header--actions">
            <div class="btn-group" dropdown>
                <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret mr-2"
                    dropdownToggle
                    tooltip="Action"
                    type="button">
                    <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                </button>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                    <ul class="nav nav-pills flex-column p-3">
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="d-flex justify-content-between p-3">
            <div class="search-wrapper">
              <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
              <input class="form-control" placeholder="Recherche dans la liste" type="search">
            </div>
        </div>
        <div class="table-responsive m-0">
            <table class="table table-hover text-nowrap m-0">
                <thead>
                    <tr>
                        <th class="text-left">Libellé&nbsp;<i class="fas fa-sort mr-1"></i></th>
                        <th class="text-left">Période</th>
                        <th class="text-left">Tâche planifiées</th>
                        <th class="text-left">Evolution</th>
                        <th class="text-left">Status</th>
                        <th class="text-left">Responsable&nbsp;<i class="fas fa-sort mr-1"></i></th>
                        <th class="text-center">Date création&nbsp;<i class="fas fa-sort mr-1"></i></th>
                        <th class="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let travail of travaux">
                        <td><span class="text-first font-weight-bold">{{travail.libelle}}</span></td>
                        <td><span class="badge bg-neutral-warning text-warning">{{travail.dateDebut}} / {{travail.dateFin}}</span></td>
                        <td><span class="badge bg-neutral-first text-first">{{travail.tacheP}} / {{travail.nbTache}}</span></td>
                        <td>
                            <progressbar
                                [value]="travail.evolution"
                                class="progress-bar-rounded-sm"
                                type="{{
                                    travail.evolution >= 0 && travail.evolution <= 24 ? 'danger':
                                    travail.evolution >= 25 && travail.evolution <= 49 ? 'warning':
                                    travail.evolution >= 50 && travail.evolution <= 64 ? 'info':
                                    travail.evolution >= 65 && travail.evolution <= 99 ? 'first':'success'
                                }}"
                            >{{travail.evolution}}%
                            </progressbar>
                        </td>
                        <td>
                            <span class="badge badge-{{
                                    travail.status == 'Terminer' ? 'success' :
                                    travail.status == 'En cours' ? 'first' :
                                    travail.status == 'En attente' ? 'warning' : 'danger'
                                }}">{{travail.status}}
                            </span>
                        </td>
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="avatar-icon-wrapper mr-2">
                                    <div class="avatar-icon"><img alt="{{travail.responsable}}" src="{{travail.respoPh}}"/></div>
                                </div>
                                <div>
                                    <a [routerLink]="" class="font-weight-bold text-black" title="{{travail.responsable}}">{{travail.responsable}}</a>
                                    <span class="text-black-50 d-block">{{travail.respoFonction}}</span>
                                </div>
                            </div>
                        </td>
                        <td><span class="badge bg-neutral-dark text-dark">{{travail.dateCreation}}</span></td>
                        <td class="text-center">
                            <button placement="auto" [routerLink]="['/pages/travaux/detail/5']"  tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill btn-primary mr-2" type="button">
                              <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                            </button>
                            <button placement="auto" tooltip="Modifier"  class="btn d-40 p-0 btn-pill mr-2 btn-info" type="button">
                                <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                            </button>
                            <button  placement="auto"  tooltip="Supprimer"  class="btn d-40 p-0 btn-pill btn-danger" type="button">
                              <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer p-3">
        <div class="d-flex justify-content-between">
            <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
            <div class="d-flex align-items-center">
                <span>Show</span>
                <select class="mx-1 form-control form-control-sm" id="" name="">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="40">50</option>
                    <option value="40">100</option>
                </select>
                <span>entries</span>
            </div>
        </div>
    </div>
</div>