<div class="card card-box border-0">
    <div class="card-header p-3">
        <div class="card-header--title">
            <h6 class="font-weight-bold m-0">Liste des bulletins HSE</h6>
        </div>
        <div class="card-header--actions">
            <div class="btn-group" dropdown>
                <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret mr-2"
                    dropdownToggle
                    tooltip="Action"
                    type="button">
                    <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                </button>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                    <ul class="nav nav-pills flex-column p-3">
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="d-flex justify-content-between p-3">
            <div class="search-wrapper">
                <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                <input class="form-control" placeholder="Recherche dans la liste" type="search">
            </div>
        </div>
        <div class="table-responsive m-0">
            <table class="table table-hover text-nowrap m-0">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Objet</th>
                        <th>Créer par</th>
                        <th>Date de création</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let bulletin of bulletins">
                        <td><span class="text-danger">{{bulletin?.type}}</span></td>
                        <td>{{bulletin?.objet}}</td>
                        <td><span class="badge bg-neutral-dark text-dark">{{bulletin?.creerPar}}</span></td>
                        <td><span class="badge bg-neutral-warning text-warning">{{bulletin?.dateCreation}}</span></td>
                        <td>
                            <button [routerLink]="['/pages/bulletin/detail',bulletin?.id]" class="btn d-40 p-0 btn-pill mr-2 btn-primary" placement="auto" tooltip="Plus d'infos" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                            </button>
                            <button class="btn d-40 p-0 btn-pill mr-2 btn-info" placement="auto" tooltip="Modifier" type="button" *ngIf="bulletin?.etat != 'validé'">
                                <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                            </button>
                            <button class="btn d-40 p-0 btn-pill mr-2 btn-success" placement="auto" tooltip="Valider" type="button" *ngIf="bulletin?.etat != 'validé'">
                                <span class="btn-wrapper--icon"><i class="fas fa-check"></i></span>
                            </button>
                            <button class="btn d-40 p-0 btn-pill mr-2 btn-warning" placement="auto" tooltip="Imprimer" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                            </button>
                            <button class="btn d-40 p-0 btn-pill mr-2 btn-dark" placement="auto" tooltip="Partager" type="button" [popover]="partageBulletin" [outsideClick]="true" container="body" containerClass="popover-custom-wrapper popover-custom-lg">
                                <span class="btn-wrapper--icon"><i class="fas fa-share-alt"></i></span>
                            </button>
                            <button  placement="auto"  tooltip="Supprimer"  class="btn d-40 p-0 btn-pill btn-danger" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer p-3">
        <div class="d-flex justify-content-between">
            <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
            <div class="d-flex align-items-center">
                <span>Show</span>
                <select class="mx-1 form-control form-control-sm">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="40">50</option>
                    <option value="40">100</option>
                </select>
                <span>entries</span>
            </div>
        </div>
    </div>
</div>

<!-- Modal de partage bulletin hse -->
<ng-template #partageBulletin>
    <div class="p-3 text-center bg-secondary">
        <h4 class="font-size-xl font-weight-bold mb-2">Partage de bulletin</h4>
        <p class="opacity-8 mb-0">Choisissez ceux a qui vous voulez envoyer</p>
    </div>
    <hr class="m-0">
    <div class="m-3">
        <input type="search" class="form-control" placeholder="Recherche dans la liste">
    </div>
    <perfect-scrollbar [autoPropagation]="true" class="scroll-area scroll-area-sm" style="height: 25em;">
        <div class="px-3" style="width: 25em;">
            <div class="border rounded-sm p-3 mb-2">
                <div class="d-flex justify-content-between">
                    <div class="align-box-row">
                        <div class="avatar-icon-wrapper avatar-icon-md">
                            <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                        </div>
                        <div class="pl-2">
                            <span class="pb-1 d-block">John Doe</span>
                            <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="custom-checkbox custom-control mt-1">
                            <input class="custom-control-input" id="inputCheck1" type="checkbox" checked>
                            <label class="custom-control-label" for="inputCheck1">&nbsp;</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border rounded-sm p-3 mb-2">
                <div class="d-flex justify-content-between">
                    <div class="align-box-row">
                        <div class="avatar-icon-wrapper avatar-icon-md">
                            <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                        </div>
                        <div class="pl-2">
                            <span class="pb-1 d-block">John Doe</span>
                            <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="custom-checkbox custom-control mt-1">
                            <input class="custom-control-input" id="inputCheck2" type="checkbox" checked>
                            <label class="custom-control-label" for="inputCheck2">&nbsp;</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border rounded-sm p-3 mb-2">
                <div class="d-flex justify-content-between">
                    <div class="align-box-row">
                        <div class="avatar-icon-wrapper avatar-icon-md">
                            <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                        </div>
                        <div class="pl-2">
                            <span class="pb-1 d-block">John Doe</span>
                            <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="custom-checkbox custom-control mt-1">
                            <input class="custom-control-input" id="inputCheck3" type="checkbox" checked>
                            <label class="custom-control-label" for="inputCheck3">&nbsp;</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border rounded-sm p-3 mb-2">
                <div class="d-flex justify-content-between">
                    <div class="align-box-row">
                        <div class="avatar-icon-wrapper avatar-icon-md">
                            <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                        </div>
                        <div class="pl-2">
                            <span class="pb-1 d-block">John Doe</span>
                            <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="custom-checkbox custom-control mt-1">
                            <input class="custom-control-input" id="inputCheck4" type="checkbox" checked>
                            <label class="custom-control-label" for="inputCheck4">&nbsp;</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border rounded-sm p-3 mb-2">
                <div class="d-flex justify-content-between">
                    <div class="align-box-row">
                        <div class="avatar-icon-wrapper avatar-icon-md">
                            <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                        </div>
                        <div class="pl-2">
                            <span class="pb-1 d-block">John Doe</span>
                            <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="custom-checkbox custom-control mt-1">
                            <input class="custom-control-input" id="inputCheck5" type="checkbox" checked>
                            <label class="custom-control-label" for="inputCheck5">&nbsp;</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </perfect-scrollbar>
    <hr class="m-0">
    <div class="p-3 text-center bg-secondary">
        <button class="btn btn-first"><i class="fas fa-paper-plane"></i> Envoyer</button>
    </div>
</ng-template>