<div class="row mb-3" *ngIf="widgetView">
    <div class="col">
        <a [routerLink]="" class="card card-box border-0 rounded p-2" title="Total des travaux">
            <div class="d-flex align-items-center mt-1">
                <h1 class="m-0">
                    <i class="fas fa-briefcase img-fluid mx-3 mt-1 text-first"></i>
                </h1>
                <div>
                    <div class="text-black-50">Total</div>
                    <div class="font-weight-bold display-4 py-1 text-black">
                        <span [countUp]="874"></span>
                    </div>
                    <div class="text-black-50 font-size-sm">
                        <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1 text-warning"></fa-icon>
                        100%
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col">
        <a [routerLink]="" class="card card-box border-0 rounded p-2" title="Les travaux terminés">
            <div class="d-flex align-items-center">
                <ngx-gauge append="%"
                    backgroundColor="#edeef1"
                    cap="round"
                    class="img-fluid mr-2 text-black"
                    foregroundColor="#1bc943"
                    max="100" min="0" size="84"
                    thick="6"
                    type="full" value="76">
                </ngx-gauge>
                <div>
                    <div class="text-black-50">Terminés</div>
                    <div class="font-weight-bold display-4 py-1 text-success">
                        <span [countUp]="90"></span>
                    </div>
                    <div class="text-black-50 font-size-sm">
                        <fa-icon [icon]="['far', 'check-circle']" class="font-size-sm pr-1 text-warning"></fa-icon>
                        90 / 874
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col">
        <a [routerLink]="" class="card card-box border-0 rounded p-2" title="Les travaux en cours de réalisation">
            <div class="d-flex align-items-center">
                <ngx-gauge append="%"
                    backgroundColor="#edeef1"
                    cap="round"
                    class="img-fluid mr-2 text-black"
                    foregroundColor="#4191ff"
                    max="100" min="0" size="84"
                    thick="6"
                    type="full" value="76">
                </ngx-gauge>
                <div>
                    <div class="text-black-50">En cours</div>
                    <div class="font-weight-bold display-4 py-1 text-first">
                        <span [countUp]="574"></span>
                    </div>
                    <div class="text-black-50 font-size-sm">
                        <i class="fas fa-pause-circle font-size-sm pr-1 text-success"></i>
                        574 / 874
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col">
        <a [routerLink]="" class="card card-box border-0 rounded p-2" title="Les travaux en attente de réalisation">
            <div class="d-flex align-items-center">
                <ngx-gauge append="%"
                    backgroundColor="#edeef1"
                    cap="round"
                    class="img-fluid mr-2 text-black"
                    foregroundColor="#f4772e"
                    max="100" min="0" size="84"
                    thick="6"
                    type="full" value="76">
                </ngx-gauge>
                <div>
                    <div class="text-black-50">En attente</div>
                    <div class="font-weight-bold display-4 py-1 text-warning">
                        <span [countUp]="210"></span>
                    </div>
                    <div class="text-black-50 font-size-sm">
                        <fa-icon [icon]="['fas', 'play-circle']" class="font-size-sm pr-1 text-first"></fa-icon>
                        210 / 874
                    </div>
                </div>
            </div>
        </a>
    </div>
</div>

<div class="mt-2 mb-3">
    <a class="btn btn-primary text-white mr-2" type="button" (click)="selectType(true)" [routerLinkActive]="['active']"><i class="fas fa-bars"></i></a>
    <a class="btn btn-primary text-white" type="button" (click)="selectType(false)" [routerLinkActive]="['active']"><i class="fas fa-th"></i></a>
</div>

<div class="card card-box border-0" *ngIf="list == true">
    <div class="card-header p-3">
        <div class="card-header--title"><h6 class="font-weight-bold m-0 text-black">Liste des tâches</h6></div>
        <div class="card-header--actions">
            <div class="btn-group" dropdown>
                <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret mr-2"
                    dropdownToggle
                    tooltip="Action"
                    type="button">
                    <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                </button>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                    <ul class="nav nav-pills flex-column p-3">
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="d-flex justify-content-between p-3">
            <div class="search-wrapper">
              <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
              <input class="form-control" placeholder="Recherche dans la liste" type="search">
            </div>
        </div>
        <div class="table-responsive m-0">
            <table class="table table-hover text-nowrap m-0">
                <thead>
                    <tr>
                        <th>Tâche</th>
                        <th>Sous tâche</th>
                        <th>Date début</th>
                        <th>Date fin</th>
                        <th>Responsable</th>
                        <th>Status</th>
                        <th>Durée</th>
                        <th class="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Terrassement</td>
                        <td>Terrassement</td>
                        <td><div class="badge bg-neutral-warning text-warning ">05-05-2020</div></td>
                        <td><div class="badge bg-neutral-warning text-warning ">05-02-2021</div></td>
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="avatar-icon-wrapper mr-2">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                </div>
                                <div>
                                    <a class="font-weight-bold text-black" title="...">Kouassi ghislain</a>
                                    <span class="text-black-50 d-block">Prestataire</span>
                                </div>
                            </div>
                        </td>
                        <td><div class="badge bg-neutral-success text-success ">Planifiée</div></td>
                        <td><div class="badge bg-neutral-info text-info ">10 Jour(s)</div></td>
                        <td class="text-center">
                            <button class="btn d-40 p-0 btn-pill mr-2 btn-primary" tooltip="Plus d'infos" placement="auto" type="button" [routerLink]="['/pages/tache/detail/5']">
                              <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                            </button>
                            <button class="btn d-40 p-0 btn-pill mr-2 btn-info" tooltip="Modifier" placement="auto"  type="button">
                              <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                            </button>
                            <button class="btn d-40 p-0 btn-pill btn-danger" tooltip="Supprimer" placement="auto" type="button">
                              <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                            </button>
                          </td>
                    </tr>
                    <tr>
                        <td>Terrassement</td>
                        <td>Terrassement</td>
                        <td><div class="badge bg-neutral-warning text-warning ">05-05-2020</div></td>
                        <td><div class="badge bg-neutral-warning text-warning ">05-02-2021</div></td>
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="avatar-icon-wrapper mr-2">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                </div>
                                <div>
                                    <a class="font-weight-bold text-black" title="...">Kouassi ghislain</a>
                                    <span class="text-black-50 d-block">Prestataire</span>
                                </div>
                            </div>
                        </td>
                        <td><div class="badge bg-neutral-warning text-warning ">En attente de planification</div></td>
                        <td><div class="badge bg-neutral-info text-info ">10 Jour(s)</div></td>
                        <td class="text-center">
                            <button class="btn d-40 p-0 btn-pill mr-2 btn-primary" tooltip="Plus d'infos" placement="auto" type="button" [routerLink]="['/pages/tache/detail/5']">
                              <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                            </button>
                            <button class="btn d-40 p-0 btn-pill mr-2 btn-info" tooltip="Modifier" placement="auto"  type="button">
                              <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                            </button>
                            <button class="btn d-40 p-0 btn-pill btn-danger" tooltip="Supprimer" placement="auto" type="button">
                              <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                            </button>
                          </td>
                    </tr>
                    <tr>
                        <td>Terrassement</td>
                        <td>Terrassement</td>
                        <td><div class="badge bg-neutral-warning text-warning ">05-05-2020</div></td>
                        <td><div class="badge bg-neutral-warning text-warning ">05-02-2021</div></td>
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="avatar-icon-wrapper mr-2">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                </div>
                                <div>
                                    <a class="font-weight-bold text-black" title="...">Kouassi ghislain</a>
                                    <span class="text-black-50 d-block">Prestataire</span>
                                </div>
                            </div>
                        </td>
                        <td><div class="badge bg-neutral-success text-success ">Planifiée</div></td>
                        <td><div class="badge bg-neutral-info text-info ">10 Jour(s)</div></td>
                        <td class="text-center">
                            <button class="btn d-40 p-0 btn-pill mr-2 btn-primary" tooltip="Plus d'infos" placement="auto" type="button" [routerLink]="['/pages/tache/detail/5']">
                              <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                            </button>
                            <button class="btn d-40 p-0 btn-pill mr-2 btn-info" tooltip="Modifier" placement="auto"  type="button">
                              <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                            </button>
                            <button class="btn d-40 p-0 btn-pill btn-danger" tooltip="Supprimer" placement="auto" type="button">
                              <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                            </button>
                          </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer p-3">
        <div class="d-flex justify-content-between">
            <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
            <div class="d-flex align-items-center">
                <span>Show</span>
                <select class="mx-1 form-control form-control-sm" id="" name="">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="40">50</option>
                    <option value="40">100</option>
                </select>
                <span>entries</span>
            </div>
        </div>
    </div>
</div>

<div class="rounded" *ngIf="list == false">
    <div class="row">
        <div class="col-md-3">
            <div class="border rounded bg-white mb-3">
                <div class="rounded-top bg-plum-plate px-3 py-2 text-white">
                    <div class="d-flex justify-content-between">
                        <span>Nouvaux <i class="fas fa-tasks"></i></span>
                        <span>5</span>
                    </div>
                </div>
                <hr class="m-0">
                <perfect-scrollbar class="scroll-area-xs shadow-overflow rounded-bottom" [autoPropagation]="true" style="height: 500px;">
                    <div class="p-3" dragula="VAMPIRES" [(dragulaModel)]="vamps">
                        <div class="card card-box border-0 text-center p-1 mb-3" *ngFor="let vamp of vamps">
                            <div class="text-center py-3 px-4">
                                <h4 class="font-size-lg font-weight-bold my-3" title="Responsable de tâche">Kouassi ghislain</h4>
                                <progressbar [value]="20" class="progress-bar-rounded my-3" type="first" title="Evolution">20%</progressbar>
                                <span class="badge badge-pill bg-neutral-warning text-warning my-3" title="Periode">01/07/2021 - 30/07/2021</span>
                                <div class="my-3">
                                    <button class="btn btn-primary d-30 p-0 btn-pill mr-2" type="button" placement="auto" tooltip="Détail"  [routerLink]="['/pages/tache/detail/5']">
                                        <i class="ion-md-eye m-0"></i>
                                    </button>
                                    <button class="btn btn-info d-30 p-0 btn-pill mr-2" type="button" placement="auto" tooltip="Modifier">
                                        <i class="fas fa-pen m-0"></i>
                                    </button>
                                    <button class="btn btn-danger d-30 p-0 btn-pill" type="button" placement="auto" tooltip="Supprimer">
                                        <i class="ion-md-trash m-0"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>

        <div class="col-md-3">
            <div class="border rounded bg-white mb-3">
                <div class="rounded-top bg-arielle-smile px-3 py-2 text-white">
                    <div class="d-flex justify-content-between">
                        <span>En cours <i class="fas fa-pause-circle"></i></span>
                        <span>5</span>
                    </div>
                </div>
                <hr class="m-0">
                <perfect-scrollbar class="scroll-area-xs shadow-overflow rounded-bottom" [autoPropagation]="true" style="height: 500px;">
                    <div class="p-3" dragula="VAMPIRES" [(dragulaModel)]="vamps">
                        <div class="card card-box border-0 text-center p-1 mb-3" *ngFor="let vamp of vamps">
                            <div class="text-center py-3 px-4">
                                <h4 class="font-size-lg font-weight-bold my-3" title="Responsable de tâche">Kouassi ghislain</h4>
                                <progressbar [value]="50" class="progress-bar-rounded my-3" type="info" title="Evolution">50%</progressbar>
                                <span class="badge badge-pill bg-neutral-warning text-warning my-3" title="Periode">01/07/2021 - 30/07/2021</span>
                                <div class="my-3">
                                    <button class="btn btn-primary d-30 p-0 btn-pill mr-2" type="button" placement="auto" tooltip="Détail"  [routerLink]="['/pages/tache/detail/5']">
                                        <i class="ion-md-eye m-0"></i>
                                    </button>
                                    <button class="btn btn-info d-30 p-0 btn-pill mr-2" type="button" placement="auto" tooltip="Modifier">
                                        <i class="fas fa-pen m-0"></i>
                                    </button>
                                    <button class="btn btn-danger d-30 p-0 btn-pill" type="button" placement="auto" tooltip="Supprimer">
                                        <i class="ion-md-trash m-0"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>

        <div class="col-md-3">
            <div class="border rounded bg-white mb-3">
                <div class="rounded-top bg-sunny-morning px-3 py-2 text-white">
                    <div class="d-flex justify-content-between">
                        <span>En attente <i class="fas fa-stop-circle"></i></span>
                        <span>5</span>
                    </div>
                </div>
                <hr class="m-0">
                <perfect-scrollbar class="scroll-area-xs shadow-overflow rounded-bottom" [autoPropagation]="true" style="height: 500px;">
                    <div class="p-3" dragula="VAMPIRES" [(dragulaModel)]="vamps">
                        <div class="card card-box border-0 text-center p-1 mb-3" *ngFor="let vamp of vamps">
                            <div class="text-center py-3 px-4">
                                <h4 class="font-size-lg font-weight-bold my-3" title="Responsable de tâche">Kouassi ghislain</h4>
                                <progressbar [value]="50" class="progress-bar-rounded my-3" type="warning" title="Evolution">50%</progressbar>
                                <span class="badge badge-pill bg-neutral-warning text-warning my-3" title="Periode">01/07/2021 - 30/07/2021</span>
                                <div class="my-3">
                                    <button class="btn btn-primary d-30 p-0 btn-pill mr-2" type="button" placement="auto" tooltip="Détail"  [routerLink]="['/pages/tache/detail/5']">
                                        <i class="ion-md-eye m-0"></i>
                                    </button>
                                    <button class="btn btn-info d-30 p-0 btn-pill mr-2" type="button" placement="auto" tooltip="Modifier">
                                        <i class="fas fa-pen m-0"></i>
                                    </button>
                                    <button class="btn btn-danger d-30 p-0 btn-pill" type="button" placement="auto" tooltip="Supprimer">
                                        <i class="ion-md-trash m-0"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>

        <div class="col-md-3">
            <div class="border rounded bg-white mb-3">
                <div class="rounded-top bg-tempting-azure px-3 py-2 text-white">
                    <div class="d-flex justify-content-between">
                        <span>Terminée <i class="fas fa-check-circle"></i></span>
                        <span>5</span>
                    </div>
                </div>
                <hr class="m-0">
                <perfect-scrollbar class="scroll-area-xs shadow-overflow rounded-bottom" [autoPropagation]="true" style="height: 500px;">
                    <div class="p-3" dragula="VAMPIRES" [(dragulaModel)]="vamps">
                        <div class="card card-box border-0 text-center p-1 mb-3" *ngFor="let vamp of vamps">
                            <div class="text-center py-3 px-4">
                                <h4 class="font-size-lg font-weight-bold my-3" title="Responsable de tâche">Kouassi ghislain</h4>
                                <progressbar [value]="100" class="progress-bar-rounded my-3" type="success" title="Evolution">100%</progressbar>
                                <span class="badge badge-pill bg-neutral-warning text-warning my-3" title="Periode">01/07/2021 - 30/07/2021</span>
                                <div class="my-3">
                                    <button class="btn btn-primary d-30 p-0 btn-pill mr-2" type="button" placement="auto" tooltip="Détail"  [routerLink]="['/pages/tache/detail/5']">
                                        <i class="ion-md-eye m-0"></i>
                                    </button>
                                    <button class="btn btn-info d-30 p-0 btn-pill mr-2" type="button" placement="auto" tooltip="Modifier">
                                        <i class="fas fa-pen m-0"></i>
                                    </button>
                                    <button class="btn btn-danger d-30 p-0 btn-pill" type="button" placement="auto" tooltip="Supprimer">
                                        <i class="ion-md-trash m-0"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</div>
