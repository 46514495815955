<div class="modal-header modal-dark  bg-plum-plate">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="py-3 px-4">
        <div class="form-row">
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="type">Type de trésorerie <span class="text-danger">*</span></label>
                    <select class="form-control" id="type" formControlName="type"
                    [ngClass]="{ 'is-invalid': submit && f.type.errors, 'is-valid': submit && f.type.valid }">
                        <option *ngFor="let type of typeRow" value="{{type.value}}">{{ type.label }}</option>
                    </select>
                    <div class="invalid-feedback" *ngIf="submit && f.type.errors">
                        <div *ngIf="f.type.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="reference">Référence <span class="text-danger">*</span></label>
                    <input class="form-control" id="reference" formControlName="reference"
                    type="text" placeholder="Référence de la trésorerie"
                    [ngClass]="{ 'is-invalid': submit && f.reference.errors, 'is-valid': submit && f.reference.valid }">
                    <div class="invalid-feedback" *ngIf="submit && f.reference.errors">
                        <div *ngIf="f.type.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="libelle">Libellé de la trésorerie <span class="text-danger">*</span></label>
                    <input class="form-control" id="libelle" formControlName="libelle"
                    type="text" placeholder="Libellé de la trésorerie"
                    [ngClass]="{ 'is-invalid': submit && f.libelle.errors, 'is-valid': submit && f.libelle.valid }">
                    <div class="invalid-feedback" *ngIf="submit && f.libelle.errors">
                        <div *ngIf="f.type.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="devise">Dévise </label>
                    <select  class="form-control" id="devise" formControlName="devise">
                        <option value="FCFA">Francs CFA BCEAO (XOF)</option>
                        <option value="dollar">Dollar($)</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="etat">Etat </label>
                    <select  class="form-control" id="etat" formControlName="etat">
                        <option *ngFor="let etat of etatRow" value="{{etat.value}}">{{ etat.label }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="gerant">Gérant <span class="text-danger">*</span></label>
                    <select  class="form-control" id="gerant" formControlName="gerant"
                    [ngClass]="{ 'is-invalid': submit && f.gerant.errors, 'is-valid': submit && f.gerant.valid }">
                        <option *ngFor="let gerant of gerantRow" value="{{gerant.value}}">{{ gerant.label }}</option>
                    </select>
                    <div class="invalid-feedback" *ngIf="submit && f.gerant.errors">
                        <div *ngIf="f.gerant.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="position-relative form-group">
                    <label for="soldeInitial">Solde initial </label>
                    <input class="form-control" type="number" id="soldeInitial" formControlName="soldeInitial" placeholder="Solde initial">
                </div>
            </div>
        </div>
        <div class="form-row" *ngIf="f.type.value == 'CAISSE'">
            <div class="col-md-6">
                <div class="position-relative form-group">
                    <label for="seuilMin">Seuil minimum </label>
                    <input class="form-control" type="number" id="seuilMin" formControlName="seuilMin">
                </div>
            </div>
            <div class="col-md-6">
                <div class="position-relative form-group">
                    <label for="seuilMax">Seuil maximum </label>
                    <input class="form-control" type="number" id="seuilMax" formControlName="seuilMax">
                </div>
            </div>
        </div>
        <div *ngIf="f.type.value == 'BANQUE'">
            <div class="form-row">
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="numCompte">Numéro de compte </label>
                        <input class="form-control" type="text" id="numCompte" formControlName="numCompte">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="codeIban">Code IBAN </label>
                        <input class="form-control" type="text" id="codeIban" formControlName="codeIban">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="codeBS">Code BIC/SWIFT </label>
                        <input class="form-control" type="text" id="codeBS" formControlName="codeBS">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="domiciliation">Domiciliation du compte </label>
                        <input class="form-control" type="text" id="domiciliation" formControlName="domiciliation">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="codeJournal">Code journal comptable </label>
                        <input class="form-control" type="text" id="codeJournal" formControlName="codeJournal">
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="custom-checkbox custom-control mb-3">
                <input class="custom-control-input" id="disponibilite"
                    type="checkbox"
                    [checked]="disponibilite"
                    (change)="changeStatus($event)">
                <label class="custom-control-label" for="disponibilite">Render ma trésorerie disponible</label>
            </div>
        </div>
    </div>
    <hr class="m-0">
    <div class=" px-4 py-2">
        <div class="row">
            <div class="col-md-6">
                <p class="text-danger mt-2">L'étoile (*) signifie que cet champ est obligatoire.</p>
            </div>
            <div class="col-md-6 text-right">
                <button (click)="onClose()" type="button" class="btn btn-secondary mr-2" data-dismiss="modal">
                  <i class="fas fa-times-circle"></i> Fermer
                </button>
                <button class="btn btn-first mr-2" type="button" (click)="form.reset()">
                    <i class="fas fa-broom  mr-2"></i> Nettoyer
                </button>
                <button  class="btn btn-success" type="submit">
                    <i class="fas fa-save mr-2"></i> Enregistrer
                </button>
            </div>
        </div>
    </div>
</form>