<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-file display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1 class="font-weight-bold m-0">Fiche de rapport journaler</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description m-0" title="Référence et libellé du rapport">
                    <span><i class="fas fa-file-alt"></i> ( RP0001 )</span>
                    <span class="ml-1">Rapport 1</span>
                </div>
                <p class="m-0" title="Date de création du rapport">
                    <i class="fas fa-calendar-alt"></i>
                    <span class="ml-1">Créer le: 11/11/2021 11:11:11</span>
                </p>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button [routerLink]="['/pages/projet/detail/1']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<div class="card">
    <div class="border rounded m-3 p-3">
        <div class="d-flex justify-content-between mt-4">
            <h3 class="mb-1">RP0001</h3>
            <h3 class="mb-1 text-first">11/11/2021</h3>
        </div>

        <!-- Information générales -->
        <div class="p-0 m-0">
            <hr class="m-0">
            <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Information générales</h4></div>
            <div class="row">
                <div class="col-md-6">
                    <p class="mt-2 mb-0"><b>Rapport numéro:</b> <span class="ml-2">1</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Référence:</b> <span class="ml-2">RP0001</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Etat:</b> <span class="ml-2">Validé</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Validateur:</b> <span class="ml-2">Kouassi ghislain</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Fonction validateur:</b> <span class="ml-2">Chef projet</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Projet:</b> <span class="ml-2">Projet 1</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Chantier:</b> <span class="ml-2">Chantier 1</span></p>
                </div>
                <div class="col-md-6">
                    <p class="mt-2 mb-0"><b>Temperature:</b> <span class="ml-2">31°C</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Precipitation:</b> <span class="ml-2">31°C</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Vent (km/h):</b> <span class="ml-2">12</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Créer par:</b> <span class="ml-2">Kouassi ghislain</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de création:</b> <span class="ml-2">11/11/2021 11:11:11</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Modifier par:</b> <span class="ml-2">Kouassi ghislain</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de création:</b> <span class="ml-2">11/11/2021 11:11:11</span></p>
                </div>
            </div>
        </div>
        <hr class="mt-2 mb-5">

        <!-- Tâches -->
        <div class="p-0 m-0">
            <hr class="m-0">
            <div class="p-2 bg-secondary">
                <h4 class="m-0 font-weight-bold text-first">Tâches realisées</h4>
            </div>
            <div class="table-responsive mb-0">
                <table class="table m-0">
                    <thead class="bg-white">
                        <tr>
                            <th class="bg-white">Id</th>
                            <th class="bg-white">Libellé</th>
                            <th class="bg-white">Quantité</th>
                            <th class="bg-white">Unité</th>
                            <th class="bg-white">Etat</th>
                            <th class="bg-white">Image</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Ta001</td>
                            <td>L'enduit de Façade</td>
                            <td>400</td>
                            <td>Fft</td>
                            <td>Terminé</td>
                            <td>
                                <div class="d-flex">
                                    <img src="assets/images/stock-photos/stock-1.jpg" alt="" class="mr-2" style="height: 45px; width: 45px;"/>
                                    <img src="assets/images/stock-photos/stock-1.jpg" alt="" class="mr-2" style="height: 45px; width: 45px;"/>
                                    <img src="assets/images/stock-photos/stock-1.jpg" alt="" class="mr-2" style="height: 45px; width: 45px;"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Ta001</td>
                            <td>L'enduit de Façade</td>
                            <td>400</td>
                            <td>Fft</td>
                            <td>Terminé</td>
                            <td>
                                <div class="d-flex">
                                    <img src="assets/images/stock-photos/stock-1.jpg" alt="" class="mr-2" style="height: 45px; width: 45px;"/>
                                    <img src="assets/images/stock-photos/stock-1.jpg" alt="" class="mr-2" style="height: 45px; width: 45px;"/>
                                    <img src="assets/images/stock-photos/stock-1.jpg" alt="" class="mr-2" style="height: 45px; width: 45px;"/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <hr class="mt-0 mb-5">

        <!-- Inventaire des ressouces -->
        <div class="p-0 m-0">
            <hr class="m-0">
            <div class="p-2 bg-secondary">
                <h4 class="m-0 font-weight-bold text-first">Ressources utilisés</h4>
            </div>
            <div class="table-responsive m-0">
                <table class="table m-0">
                    <thead class="bg-white">
                        <tr>
                            <th class="bg-white">Type</th>
                            <th class="bg-white">Ressource</th>
                            <th class="bg-white">Quantité</th>
                            <th class="bg-white">Unité</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Materiel</td>
                            <td>ADAPTATEUR</td>
                            <td>2</td>
                            <td>PCS</td>
                        </tr>
                        <tr>
                            <td>Materiel</td>
                            <td>ADAPTATEUR</td>
                            <td>2</td>
                            <td>PCS</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <hr class="mt-0 mb-5">

        <!-- Ressources techniques -->
        <div class="p-0 m-0">
            <hr class="m-0">
            <div class="p-2 bg-secondary">
                <h4 class="m-0 font-weight-bold text-first">Ressources techniques utilisés</h4>
            </div>
            <div class="table-responsive m-0">
                <table class="table m-0">
                    <thead class="bg-white">
                        <tr>
                            <th class="bg-white">Fonction</th>
                            <th class="bg-white">Nombre de personne</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Ferronerie</td>
                            <td>2</td>
                        </tr>
                        <tr>
                            <td>Marçonnerie</td>
                            <td>8</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <hr class="mt-0 mb-5">

        <!-- Materiel/engin utilisés -->
        <div class="p-0 m-0">
            <hr class="m-0">
            <div class="p-2 bg-secondary">
                <h4 class="m-0 font-weight-bold text-first">Materiel/engin utilisés</h4>
            </div>
            <div class="table-responsive m-0">
                <table class="table m-0">
                    <thead class="bg-white">
                        <tr>
                            <th class="bg-white">Libellé</th>
                            <th class="bg-white">Heure début</th>
                            <th class="bg-white">Heure fin</th>
                            <th class="bg-white">Temps de pause</th>
                            <th class="bg-white">Temps de travail</th>
                            <th class="bg-white">Carburant (L)</th>
                            <th class="bg-white">Coût horaire(FCFA)</th>
                            <th class="bg-white">Montant dépensé (FCFA)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Materiel</td>
                            <td>08:00:00</td>
                            <td>16:30:00</td>
                            <td>01:00:00</td>
                            <td>07:30:00</td>
                            <td>90</td>
                            <td>5 000</td>
                            <td>96 000</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <hr class="mt-0 mb-5">

        <!-- Visiteurs -->
        <div class="p-0 m-0">
            <hr class="m-0">
            <div class="p-2 bg-secondary">
                <h4 class="m-0 font-weight-bold text-first">Visiteurs</h4>
            </div>
            <div class="table-responsive">
                <table class="table m-0">
                    <thead class="bg-white">
                        <tr>
                            <th class="bg-white"></th>
                            <th class="bg-white">Nom et prenom</th>
                            <th class="bg-white">Contact</th>
                            <th class="bg-white">Email</th>
                            <th class="bg-white">Fonction</th>
                            <th class="bg-white">Date de visite</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="avatar-icon-wrapper">
                                    <div class="avatar-icon">
                                        <img alt="..." src="assets/images/avatars/avatar2.jpg"/>
                                    </div>
                                </div>
                            </td>
                            <td>Kouassi ghislain</td>
                            <td>00 00 00 00 00</td>
                            <td>exemple@gmail.com</td>
                            <td>Chef chantier</td>
                            <td>11/11/2021</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="avatar-icon-wrapper">
                                    <div class="avatar-icon">
                                        <img alt="..." src="assets/images/avatars/avatar2.jpg"/>
                                    </div>
                                </div>
                            </td>
                            <td>Kouassi ghislain</td>
                            <td>00 00 00 00 00</td>
                            <td>exemple@gmail.com</td>
                            <td>Chef chantier</td>
                            <td>11/11/2021</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <hr class="mt-2 mb-5">

        <!-- Situation HSE -->
        <div class="p-0 m-0">
            <hr class="m-0">
            <div class="p-2 bg-secondary">
                <h4 class="m-0 font-weight-bold text-first">Situation HSE</h4>
            </div>
            <div class="table-responsive m-0">
                <table class="table m-0">
                    <thead class="bg-white">
                        <tr>
                            <th class="bg-white">Type</th>
                            <th class="bg-white">Libellé</th>
                            <th class="bg-white">Date</th>
                            <th class="bg-white">Images</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span class="badge bg-neutral-warning text-warning">Incident</span></td>
                            <td>Fil electrique denudé</td>
                            <td>A resoudre le :11/11/2021</td>
                            <td>
                                <div class="d-flex">
                                    <img src="assets/images/stock-photos/stock-1.jpg" alt="" class="mr-2" style="height: 45px; width: 45px;"/>
                                    <img src="assets/images/stock-photos/stock-1.jpg" alt="" class="mr-2" style="height: 45px; width: 45px;"/>
                                    <img src="assets/images/stock-photos/stock-1.jpg" alt="" class="mr-2" style="height: 45px; width: 45px;"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><span class="badge bg-neutral-danger text-danger">Accident de travail</span></td>
                            <td>Ecroulement de batiment</td>
                            <td>11/11/2021 11:11:11</td>
                            <td>
                                <div class="d-flex">
                                    <img src="assets/images/stock-photos/stock-1.jpg" alt="" class="mr-2" style="height: 45px; width: 45px;"/>
                                    <img src="assets/images/stock-photos/stock-1.jpg" alt="" class="mr-2" style="height: 45px; width: 45px;"/>
                                    <img src="assets/images/stock-photos/stock-1.jpg" alt="" class="mr-2" style="height: 45px; width: 45px;"/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <hr class="m-0">
    <div class="text-right mx-3 my-2">
        <button class="btn btn-success mr-2" type="button"><i class="fas fa-check mr-1"></i>Valider</button>
        <button class="btn btn-warning mr-2" type="button"><i class="ion-md-print mr-1"></i>Imprimer</button>
        <button class="btn btn-danger" type="button"><i class="ion-md-trash mr-1"></i>Supprimer</button>
    </div>
</div>