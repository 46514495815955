import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReunionPages } from 'src/app/models/reunion-pages';
import { ReunionPagesService } from 'src/app/services/reunion-pages/reunion-pages.service';
import { ThemeOptions } from 'src/app/theme-options';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-reunion-detail',
  templateUrl: './reunion-detail.component.html',
  styleUrls: ['./reunion-detail.component.scss']
})
export class ReunionDetailComponent implements OnInit {
  reunion: ReunionPages = <ReunionPages>{};
  
  constructor(
    public globals: ThemeOptions,
    private route: ActivatedRoute,
    private reunionService: ReunionPagesService
  ) {
    this.reunion = this.reunionService.getReunion();
    // console.log(this.projet)
    const id: number = +this.route.snapshot.paramMap.get('id');
    this.reunionService.getList().subscribe((reunions: ReunionPages[]) => {
      this.reunion = reunions.find(reunion => reunion.id == id);
    });
  }

  ngOnInit() {}

  // Editor
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '35rem',
    minHeight: '5rem',
    placeholder: 'Entrer le texte...',
    translate: 'no',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
}
