import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardGuard } from './auth-pages/auth-guard.guard';


const routes: Routes = [

  {
    path: '',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./home-pages/home-pages.module').then(module => module.HomePagesModule)
  },
  {
    path: 'projet',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./projet-pages/projet-pages.module').then(module => module.ProjetPagesModule)
  },
  {
    path: 'opportunite',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./opportunite-pages/opportunite-pages.module').then(module => module.OpportunitePagesModule)
  },
  {
    path: 'mission',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./mission-pages/mission-pages.module').then(module => module.MissionPagesModule)
  },
  {
    path: 'fournisseur',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./fournisseur-pages/fournisseur-pages.module').then(module => module.FournisseurPagesModule)
  },
  {
    path: 'ressource',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./ressource-pages/ressource-pages.module').then(module => module.RessourcePagesModule)
  },
  {
    path: 'tache',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./tache-pages/tache-pages.module').then(module => module.TachePagesModule)
  },
  {
    path: 'travaux',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./travaux-pages/travaux-pages/travaux-pages.module').then(module => module.TravauxPagesModule)
  },
  {
    path: 'tresorerie',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./tresorerie-pages/tresorerie-pages.module').then(module => module.TresoreriePagesModule)
  },
  {
    path: 'utilisateur',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./utilisateur-pages/utilisateur-pages.module').then(module => module.UtilisateurPagesModule)
  },
  {
    path: 'etude_prix',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./etude-prix-pages/etude-prix-pages.module').then(module => module.EtudePrixPagesModule)
  },
  {
    path: 'visite',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./visite-pages/visite-pages.module').then(module => module.VisitePagesModule)
  },
  {
    path: 'chantier',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./chantier-pages/chantier-pages.module').then(module => module.ChantierPagesModule)
  },
  {
    path: 'situation_hse',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./situation-hse-pages/situation-hse-pages.module').then(module => module.SituationHsePagesModule)
  },
  {
    path: 'proces_verbal',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./proces-verbal-pages/proces-verbal-pages.module').then(module => module.ProcesVerbalPagesModule)
  },
  {
    path: 'rapport',
    canActivate: [AuthGuardGuard],
    loadChildren: () => import('./rapport-pages/rapport-pages.module').then(module => module.RapportPagesModule)
  },
  { path: 'remarque',
    loadChildren: () => import('./remarque-pages/remarque-pages.module').then(module => module.RemarquePagesModule)
  },
  { path: 'intervenant',
    loadChildren: () => import('./intervenant-pages/intervenant-pages.module').then(module => module.IntervenantPagesModule)
  },
  { path: 'fichier',
    loadChildren: () => import('./fichier-pages/fichier-pages.module').then(module => module.FichierPagesModule)
  },
  { path: 'reunion',
    loadChildren: () => import('./reunion-pages/reunion-pages.module').then(module => module.ReunionPagesModule)
  },
  { path: 'commande',
    loadChildren: () => import('./commande-pages/commande-pages.module').then(module => module.CommandePagesModule)
  },
  { path: 'budget',
    loadChildren: () => import('./budget-pages/budget-pages.module').then(module => module.BudgetPagesModule)
  },
  { path: 'bulletin',
    loadChildren: () => import('./bulletin-hse-pages/bulletin-hse-pages.module').then(module => module.BulletinHsePagesModule)
  },
  { path: 'controlle',
    loadChildren: () => import('./acteur-externe-pages/bureau-controlle-pages/bureau-controlle-pages.module').then(module => module.BureauControllePagesModule)
  },
  { path: 'etude',
    loadChildren: () => import('./acteur-externe-pages/bureau-etude-pages/bureau-etude-pages.module').then(module => module.BureauEtudePagesModule)
  },
  { path: 'client',
    loadChildren: () => import('./acteur-externe-pages/client-pages/client-pages.module').then(module => module.ClientPagesModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
