import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VisitePagesRoutingModule } from './visite-pages-routing.module';
import { VisiteDetailComponent } from './visite-detail/visite-detail.component';
import { VisiteEditComponent } from './visite-edit/visite-edit.component';
import { VisiteListComponent } from './visite-list/visite-list.component';
import { VisitePagesComponent } from './visite-pages.component';



import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebook, faTwitter, faAngular, faVuejs, faReact, faHtml5, faGoogle, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faChevronRight, faSitemap, faPrint, faMapMarkerAlt, faTachometerAlt, faAlignCenter, faExternalLinkAlt, faShareSquare, faInfoCircle, faSync, faQuoteRight, faStarHalfAlt, faShapes, faCarBattery, faTable, faCubes, faPager,  faCameraRetro, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faUnlockAlt, faDownload, faAward, faPlayCircle, faReply, faUpload, faBars, faEllipsisV, faSave, faSlidersH, faCaretRight, faChevronUp, faPlus, faLemon, faChevronLeft, faTimes, faChevronDown, faFilm, faSearch, faEllipsisH, faCog, faArrowsAltH, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLink } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faTimesCircle, faDotCircle, faThumbsUp, faComments, faFolderOpen, faTrashAlt, faFileImage, faFileArchive, faLifeRing, faCommentDots, faFolder, faKeyboard, faCalendarAlt, faEnvelope, faAddressCard, faMap, faObjectGroup, faImages, faUser, faLightbulb, faGem, faClock, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar } from '@fortawesome/free-regular-svg-icons';

import { AccordionModule, BsDatepickerModule, BsDropdownModule, ModalModule, PaginationModule, TabsModule, TooltipModule, ProgressbarModule, PopoverModule } from 'ngx-bootstrap';
import { ArchwizardModule } from 'angular-archwizard';
import { LaddaModule } from 'angular2-ladda';
import { ProcesVerbalPagesModule } from '../proces-verbal-pages/proces-verbal-pages.module';
import { RapportPagesModule } from '../rapport-pages/rapport-pages.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SharedComponentsModule } from 'src/app/shared-components/shared-components.module';
import { IntervenantPagesModule } from '../intervenant-pages/intervenant-pages.module';
import { RemarquePagesModule } from '../remarque-pages/remarque-pages.module';
import { CountUpModule } from 'countup.js-angular2';

@NgModule({
  declarations: [
    VisitePagesComponent,
    VisiteDetailComponent,
    VisiteEditComponent,
    VisiteListComponent,
  ],
  imports: [
    CommonModule,
    VisitePagesRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    FontAwesomeModule,
    BsDatepickerModule,
    TooltipModule,
    TabsModule,
    NgSelectModule,
    PaginationModule,
    BsDropdownModule,
    AccordionModule,
    ArchwizardModule,
    LaddaModule,
    ProgressbarModule,
    PerfectScrollbarModule,
    AngularEditorModule,
    AccordionModule,
    AngularEditorModule,
    PopoverModule,
    CountUpModule,

    ProcesVerbalPagesModule,
    RapportPagesModule,
    SharedComponentsModule,
    IntervenantPagesModule,
    RemarquePagesModule,
  ],
  exports: [
    VisiteDetailComponent,
    VisiteEditComponent,
    VisiteListComponent
  ]
})
export class VisitePagesModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFacebook, faPrint, faAlignCenter, faMapMarkerAlt, faTachometerAlt, faExternalLinkAlt, faShareSquare, faSitemap, faInfoCircle, faLifeRing, faTwitter, faQuoteRight, faStarHalfAlt, faSync, faShapes, faCarBattery, faTable, faCubes, faPager,  faAngular, faVuejs, faReact, faHtml5, faCheckCircle, faTimesCircle, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faThumbsUp, faCameraRetro, faUnlockAlt, faDownload, faUpload, faReply, faGoogle, faFileImage, faFolderOpen, faBars, faTrashAlt, faSave, faPlayCircle, faEllipsisV, faEllipsisH, faSlidersH, faFileArchive, faAward, faCaretRight, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub, faPlus, faFolder, faTimes, faEnvelope, faAddressCard, faMap, faCalendarAlt, faImages, faFilm, faClock, faSearch, faChevronRight, faChevronUp, faChevronLeft, faChevronDown, faLink, faLightbulb, faGem, faCog, faDotCircle, faArrowsAltH, faComments, faCommentDots, faKeyboard, faObjectGroup, faUser, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLemon);
 }
}
