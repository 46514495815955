import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BulletinHse } from 'src/app/models/bulletin-hse';
import { BulletinHseService } from 'src/app/services/bulletin-hse/bulletin-hse.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-bulletin-hse-detail',
  templateUrl: './bulletin-hse-detail.component.html',
  styleUrls: ['./bulletin-hse-detail.component.scss']
})
export class BulletinHseDetailComponent implements OnInit {
  bulletin: BulletinHse = <BulletinHse>{};

  constructor(
    public globals: ThemeOptions,
    private route: ActivatedRoute,
    private bulletinService: BulletinHseService,
  ) {
    this.bulletin = this.bulletinService.getBulletin();
    const id: number = +this.route.snapshot.paramMap.get('id');
    this.bulletinService.getList().subscribe((bulletins: BulletinHse[]) => {
      this.bulletin = bulletins.find(bulletin => bulletin.id == id);
    });
  }

  ngOnInit() {
  }

}
