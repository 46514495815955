<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-wallet display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1 class="font-weight-bold m-0">Fiche budgetaire</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description m-0" title="Référence du budget">
                    <span><i class="fas fa-file-alt"></i> ( {{budget?.reference}} )</span>
                    <span class="ml-1 badge bg-{{budget?.etat == 'validé' ? 'neutral-success text-success' : 'neutral-warning text-warning'}}">{{budget?.etat}}</span>
                </div>
                <p class="m-0" title="Date de création du budget">
                    <i class="fas fa-calendar-alt"></i>
                    <span class="ml-1">Créer le: {{budget?.dateCreation}}</span>
                </p>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button class="btn btn-success mr-2" tooltip="Valider le budget" *ngIf="budget?.etat != 'validé'"><i class="fas fa-check mr-2"></i>Valider</button>
            <button [routerLink]="['/pages/budget/liste']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<accordion [closeOthers]="true" [isAnimated]="true" class="accordion mb-5">
    <accordion-group class="card card-box">
        <button accordion-heading class="btn btn-link btn-lg d-flex align-items-center justify-content-between"
                type="button">
            <span>Cliquer pour dérouler</span>
            <fa-icon [icon]="['fas', 'angle-up']" class="font-size-xl"></fa-icon>
        </button>
        <div class="border rounded m-3 p-3">
            <div class="row">
                <div class="col-md-6">
                    <p class="mt-2 mb-0"><b>Référence:</b> <span class="ml-2">{{budget?.reference}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Référence du projet concerné:</b> <span class="ml-2">{{budget?.refProjet}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Libellé du projet concerné:</b> <span class="ml-2">{{budget?.projet}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date début du projet concerné:</b> <span class="ml-2">{{budget?.dateDebut}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date fin du projet concerné:</b> <span class="ml-2">{{budget?.dateFin}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Budget etablir par:</b> <span class="ml-2">{{budget?.etablitPar}}</span></p>
                </div>
                <div class="col-md-6">
                    <p class="mt-2 mb-0"><b>Etat:</b> <span class="ml-2 badge bg-{{budget?.etat == 'validé' ? 'neutral-success text-success' : 'neutral-warning text-warning'}}">{{budget?.etat}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Budget par:</b> <span class="ml-2">{{budget?.validePar}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Créer par:</b> <span class="ml-2">{{budget?.creerPar}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de création:</b> <span class="ml-2">{{budget?.dateCreation}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Modifier par:</b> <span class="ml-2">{{budget?.modifierPar}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de modification:</b> <span class="ml-2">{{budget?.dateModification}}</span></p>
                </div>
            </div>
        </div>
        <hr class="m-0">
        <div class="text-right py-2 px-3">
            <button class="btn btn-info mr-2" type="button"><i class="fas fa-pen mr-1"></i>Modifier</button>
            <button class="btn btn-warning mr-2" type="button"><i class="ion-md-print mr-1"></i>Imprimer</button>
            <button class="btn btn-danger" type="button"><i class="ion-md-trash mr-1"></i>Supprimer</button>
        </div>
    </accordion-group>
</accordion>

<div class="border rounded p-3">
    <h4 class="m-0 font-weight-bold">Liste du budget travaux à valider</h4>
</div>
<div class="mb-4">
    <div class="row mb-3">
        <div class="col-md-4">
            <div class="card">
                <div class="row px-4 py-2">
                    <div class="col-md-4">
                        <i class="fas fa-piggy-bank fa-3x text-first mt-4"></i>
                    </div>
                    <div class="col-md-8 float-right text-right">
                        <p class="announcement-heading">5</p>
                        <p class="announcement-text">Total Budget</p>
                        <h4 class="font-weight-bold text-dark m-0">100 000 000 000</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="row px-4 py-2">
                    <div class="col-md-4">
                        <i class="fas fa-coins fa-3x mt-4"></i>
                    </div>
                    <div class="col-md-8 float-right text-right">
                        <p class="announcement-heading">5</p>
                        <p class="announcement-text">Montant proposé</p>
                        <h4 class="font-weight-bold text-dark m-0">100 000 000 000</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="card">
                <div class="row px-4 py-2">
                    <div class="col-md-4">
                        <i class="fas fa-check-circle fa-3x text-success mt-4"></i>
                    </div>
                    <div class="col-md-8 float-right text-right">
                        <p class="announcement-heading">5</p>
                        <p class="announcement-text">Montant validé</p>
                        <h4 class="font-weight-bold text-dark m-0">100 000 000 000</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card p-0 mb-5">
        <div class="d-flex justify-content-between p-3">
            <div class="search-wrapper">
              <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
              <input class="form-control" placeholder="Recherche dans la liste" type="search">
            </div>
        </div>
        <div class="table-responsive m-0">
            <table class="table table-hover text-nowrap m-0">
                <thead>
                    <tr>
                        <th></th>
                        <th>Travaux</th>
                        <th>Budget proposé</th>
                        <th>Budget validé</th>
                        <th>Etat</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td data-toggle="collapse" data-target="#row1" class="accordion-toggle">
                            <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                        </td>
                        <td><span class="font-weight-bold">GROS OEUVRE</span></td>
                        <td><span class="font-weight-bold text-dark">300 000</span></td>
                        <td><span class="font-weight-bold text-success">200 00</span></td>
                        <td><span class="badge bg-neutral-{{budget?.etat == 'validé' ? 'success text-success' : 'warning text-warning'}}">{{budget?.etat}}</span></td>
                    </tr>
                    <tr>
                        <td colspan="5" class="hiddenRow">
                            <div class="accordian-body collapse p-3" id="row1">
                                <div class="card card-box mb-0">
                                    <div class="card-header p-3">
                                        <div class="card-header--title">
                                            <h6 class="m-0 font-weight-bold">Détail du budget travaux</h6>
                                        </div>
                                    </div>
                                    <div class="card-body p-0">
                                        <div class="table-responsive m-0">
                                            <table class="table table-hover text-nowrap m-0">
                                                <thead>
                                                    <tr>
                                                        <th>Designation</th>
                                                        <th>Budget proposé</th>
                                                        <th>Budget validé</th>
                                                        <th>Ecart</th>
                                                        <th>Mois</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><span>MEULEUSE ANG GWS26-230L VI 2600W D230</span></td>
                                                        <td><span>150 000</span></td>
                                                        <td *ngIf="budget?.etat != 'validé' ">
                                                            <input type="number" min="0" class="form-control" value="100000" style="width: 10em;">
                                                        </td>
                                                        <td *ngIf="budget?.etat == 'validé' "><span>100 00</span></td>
                                                        <td><span>50 000</span></td>
                                                        <td><span>Septembre 2021</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>MEULEUSE ANG GWS26-230L VI 2600W D230</span></td>
                                                        <td><span>150 000</span></td>
                                                        <td *ngIf="budget?.etat != 'validé' ">
                                                            <input type="number" min="0" class="form-control" value="100000" style="width: 10em;">
                                                        </td>
                                                        <td *ngIf="budget?.etat == 'validé' "><span>100 00</span></td>
                                                        <td><span>50 000</span></td>
                                                        <td><span>Septembre 2021</span></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>TOTAL</th>
                                                        <th><span class="font-weight-bold">300 000</span></th>
                                                        <th><span class="font-weight-bold">200 000</span></th>
                                                        <th colspan="2"><span class="font-weight-bold">100 000</span></th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td data-toggle="collapse" data-target="#row2" class="accordion-toggle">
                            <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                        </td>
                        <td><span class="font-weight-bold">SECOND OEUVRE</span></td>
                        <td><span class="font-weight-bold text-dark">300 000</span></td>
                        <td><span class="font-weight-bold text-success">200 00</span></td>
                        <td><span class="badge bg-neutral-{{budget?.etat == 'validé' ? 'success text-success' : 'warning text-warning'}}">{{budget?.etat}}</span></td>
                    </tr>
                    <tr>
                        <td colspan="5" class="hiddenRow">
                            <div class="accordian-body collapse p-3" id="row2">
                                <div class="card card-box mb-0">
                                    <div class="card-header p-3">
                                        <div class="card-header--title">
                                            <h6 class="m-0 font-weight-bold">Détail du budget travaux</h6>
                                        </div>
                                    </div>
                                    <div class="card-body p-0">
                                        <div class="table-responsive m-0">
                                            <table class="table table-hover text-nowrap m-0">
                                                <thead>
                                                    <tr>
                                                        <th>Designation</th>
                                                        <th>Budget proposé</th>
                                                        <th>Budget validé</th>
                                                        <th>Ecart</th>
                                                        <th>Mois</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><span>MEULEUSE ANG GWS26-230L VI 2600W D230</span></td>
                                                        <td><span>150 000</span></td>
                                                        <td *ngIf="budget?.etat != 'validé' ">
                                                            <input type="number" min="0" class="form-control" value="100000" style="width: 10em;">
                                                        </td>
                                                        <td *ngIf="budget?.etat == 'validé' "><span>100 00</span></td>
                                                        <td><span>50 000</span></td>
                                                        <td><span>Septembre 2021</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span>MEULEUSE ANG GWS26-230L VI 2600W D230</span></td>
                                                        <td><span>150 000</span></td>
                                                        <td *ngIf="budget?.etat != 'validé' ">
                                                            <input type="number" min="0" class="form-control" value="100000" style="width: 10em;">
                                                        </td>
                                                        <td *ngIf="budget?.etat == 'validé' "><span>100 00</span></td>
                                                        <td><span>50 000</span></td>
                                                        <td><span>Septembre 2021</span></td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>TOTAL</th>
                                                        <th><span class="font-weight-bold">300 000</span></th>
                                                        <th><span class="font-weight-bold">200 000</span></th>
                                                        <th colspan="2"><span class="font-weight-bold">100 000</span></th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th colspan="2">TOTAL: </th>
                        <th>600 000</th>
                        <th colspan="2">400 000</th>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div class="card-footer px-3 py-2">
            <div class="d-flex justify-content-between">
                <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                <div class="d-flex align-items-center">
                    <span>Show</span>
                    <select class="mx-1 form-control form-control-sm" id="" name="">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="40">50</option>
                        <option value="40">100</option>
                    </select>
                    <span>entries</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="border rounded p-3">
    <h4 class="m-0 font-weight-bold">Résultat compte d'exploitation(Affiche uniquement les travaux planifiés par le chef de projet)</h4>
</div>
<div>
    <tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow p-0 m-0" type="tabs" >
        <!-- Recapitulatif annuel -->
        <tab customClass="px-0 py-2">
            <ng-template tabHeading><span><i class="fas fa-calendar-alt mr-1"></i>Recapitulatif annuel</span></ng-template>
            <accordion class="accordion" [closeOthers]="true" [isAnimated]="true">
                <accordion-group class="card card-box">
                    <button accordion-heading class="btn btn-link d-flex align-items-center justify-content-between" type="button">
                        <span>
                            <i class="fas fa-calendar-alt nav-icon-wrapper text-primary font-size-lg mr-2"></i>
                            Année 1
                        </span>
                        <fa-icon [icon]="['fas', 'angle-up']"></fa-icon>
                    </button>
                    <div class="table-responsive m-0">
                        <table class="table table-hover text-nowrap m-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Travaux</th>
                                    <th>Budget validé</th>
                                    <th>Montant décaissé</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-toggle="collapse" data-target="#part1_1_1" class="accordion-toggle">
                                        <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                    <td>GROS OEUVRE</td>
                                    <td>1050 000</td>
                                    <td>900 000</td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="hiddenRow">
                                        <div class="accordian-body collapse p-3" id="part1_1_1">
                                            <div class="card card-box mb-0">
                                                <div class="card-header p-3">
                                                    <div class="card-header--title">
                                                        <h6 class="m-0 font-weight-bold">Détail du budget établi</h6>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="table-responsive m-0">
                                                        <table class="table table-hover text-nowrap m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Designation</th>
                                                                    <th>Budget validé</th>
                                                                    <th>Montant décaissé</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>Une designation</span></td>
                                                                    <td><span>900 000</span></td>
                                                                    <td><span>800 00</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Une deusieme designation</span></td>
                                                                    <td><span>150 000</span></td>
                                                                    <td><span>100 00</span></td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <th>TOTAL</th>
                                                                    <th>1050 000</th>
                                                                    <th>900 000</th>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td data-toggle="collapse" data-target="#part1_1_2" class="accordion-toggle">
                                        <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                    <td>GROS OEUVRE</td>
                                    <td>1050 000</td>
                                    <td>900 000</td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="hiddenRow">
                                        <div class="accordian-body collapse p-3" id="part1_1_2">
                                            <div class="card card-box mb-0">
                                                <div class="card-header p-3">
                                                    <div class="card-header--title">
                                                        <h6 class="m-0 font-weight-bold">Détail du budget établi</h6>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="table-responsive m-0">
                                                        <table class="table table-hover text-nowrap m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Designation</th>
                                                                    <th>Budget validé</th>
                                                                    <th>Montant décaissé</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>Une designation</span></td>
                                                                    <td><span>900 000</span></td>
                                                                    <td><span>800 00</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Une deusieme designation</span></td>
                                                                    <td><span>150 000</span></td>
                                                                    <td><span>100 00</span></td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <th>TOTAL</th>
                                                                    <th>1000 050 000</th>
                                                                    <th>900 000</th>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colspan="2">TOTAL: </th>
                                    <th>2100 000</th>
                                    <th>1100 000</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </accordion-group>
                <accordion-group class="card card-box">
                    <button accordion-heading class="btn btn-link d-flex align-items-center justify-content-between" type="button">
                        <span>
                            <i class="fas fa-calendar-alt nav-icon-wrapper text-primary font-size-lg mr-2"></i>
                            Année 2
                        </span>
                        <fa-icon [icon]="['fas', 'angle-up']"></fa-icon>
                    </button>
                    <div class="table-responsive m-0">
                        <table class="table table-hover text-nowrap m-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Travaux</th>
                                    <th>Budget validé</th>
                                    <th>Montant décaissé</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-toggle="collapse" data-target="#part1_2_1" class="accordion-toggle">
                                        <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                    <td>GROS OEUVRE</td>
                                    <td>1050 000</td>
                                    <td>900 000</td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="hiddenRow">
                                        <div class="accordian-body collapse p-3" id="part1_2_1">
                                            <div class="card card-box mb-0">
                                                <div class="card-header p-3">
                                                    <div class="card-header--title">
                                                        <h6 class="m-0 font-weight-bold">Détail du budget établi</h6>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="table-responsive m-0">
                                                        <table class="table table-hover text-nowrap m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Designation</th>
                                                                    <th>Budget validé</th>
                                                                    <th>Montant décaissé</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>Une designation</span></td>
                                                                    <td><span>900 000</span></td>
                                                                    <td><span>800 00</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Une deusieme designation</span></td>
                                                                    <td><span>150 000</span></td>
                                                                    <td><span>100 00</span></td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <th>TOTAL</th>
                                                                    <th>1050 000</th>
                                                                    <th>900 000</th>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td data-toggle="collapse" data-target="#part1_2_2" class="accordion-toggle">
                                        <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                    <td>GROS OEUVRE</td>
                                    <td>1050 000</td>
                                    <td>900 000</td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="hiddenRow">
                                        <div class="accordian-body collapse p-3" id="part1_2_2">
                                            <div class="card card-box mb-0">
                                                <div class="card-header p-3">
                                                    <div class="card-header--title">
                                                        <h6 class="m-0 font-weight-bold">Détail du budget établi</h6>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="table-responsive m-0">
                                                        <table class="table table-hover text-nowrap m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Designation</th>
                                                                    <th>Budget validé</th>
                                                                    <th>Montant décaissé</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>Une designation</span></td>
                                                                    <td><span>900 000</span></td>
                                                                    <td><span>800 00</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Une deusieme designation</span></td>
                                                                    <td><span>150 000</span></td>
                                                                    <td><span>100 00</span></td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <th>TOTAL</th>
                                                                    <th>1000 050 000</th>
                                                                    <th>900 000</th>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colspan="2">TOTAL: </th>
                                    <th>2100 000</th>
                                    <th>1100 000</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </accordion-group>
                <accordion-group class="card card-box">
                    <button accordion-heading class="btn btn-link d-flex align-items-center justify-content-between" type="button">
                        <span>
                            <i class="fas fa-calendar-alt nav-icon-wrapper text-primary font-size-lg mr-2"></i>
                            Année 2
                        </span>
                        <fa-icon [icon]="['fas', 'angle-up']"></fa-icon>
                    </button>
                    <div class="table-responsive m-0">
                        <table class="table table-hover text-nowrap m-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Travaux</th>
                                    <th>Budget validé</th>
                                    <th>Montant décaissé</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-toggle="collapse" data-target="#part1_3_1" class="accordion-toggle">
                                        <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                    <td>GROS OEUVRE</td>
                                    <td>1050 000</td>
                                    <td>900 000</td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="hiddenRow">
                                        <div class="accordian-body collapse p-3" id="part1_3_1">
                                            <div class="card card-box mb-0">
                                                <div class="card-header p-3">
                                                    <div class="card-header--title">
                                                        <h6 class="m-0 font-weight-bold">Détail du budget établi</h6>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="table-responsive m-0">
                                                        <table class="table table-hover text-nowrap m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Designation</th>
                                                                    <th>Budget validé</th>
                                                                    <th>Montant décaissé</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>Une designation</span></td>
                                                                    <td><span>900 000</span></td>
                                                                    <td><span>800 00</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Une deusieme designation</span></td>
                                                                    <td><span>150 000</span></td>
                                                                    <td><span>100 00</span></td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <th>TOTAL</th>
                                                                    <th>1050 000</th>
                                                                    <th>900 000</th>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td data-toggle="collapse" data-target="#part1_3_2" class="accordion-toggle">
                                        <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                    <td>GROS OEUVRE</td>
                                    <td>1050 000</td>
                                    <td>900 000</td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="hiddenRow">
                                        <div class="accordian-body collapse p-3" id="part1_3_2">
                                            <div class="card card-box mb-0">
                                                <div class="card-header p-3">
                                                    <div class="card-header--title">
                                                        <h6 class="m-0 font-weight-bold">Détail du budget établi</h6>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="table-responsive m-0">
                                                        <table class="table table-hover text-nowrap m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Designation</th>
                                                                    <th>Budget validé</th>
                                                                    <th>Montant décaissé</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>Une designation</span></td>
                                                                    <td><span>900 000</span></td>
                                                                    <td><span>800 00</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Une deusieme designation</span></td>
                                                                    <td><span>150 000</span></td>
                                                                    <td><span>100 00</span></td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <th>TOTAL</th>
                                                                    <th>1000 050 000</th>
                                                                    <th>900 000</th>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colspan="2">TOTAL: </th>
                                    <th>2100 000</th>
                                    <th>1100 000</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </accordion-group>
            </accordion>
        </tab>

        <!-- Recapitulatif travaux -->
        <tab customClass="px-0 py-2">
            <ng-template tabHeading><span><i class="fas fa-briefcase mr-1"></i>Recapitulatif travaux</span></ng-template>
            <div class="row">
                <div class="col-md-3">
                    <div class="card card-box border-0 bg-night-sky text-white mb-3 p-3">
                        <p class="mb-0">Total Travaux</p>
                        <h4 class="m-0"><i class="fas fa-layer-group"></i></h4>
                        <h5 class="font-size-20 font-weight-bold mt-0 pt-1 mb-0">24</h5>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card card-box border-0 text-white bg-tempting-azure mb-3 p-3">
                        <p class="mb-0 text-black">Total budget validé</p>
                        <h4 class="m-0"><i class="fas fa-check-circle"></i></h4>
                        <h5 class="font-size-20 font-weight-bold mt-0 pt-1 mb-0">200 000 000 000</h5>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card card-box border-0 bg-strong-bliss text-white mb-3 p-3">
                        <p class="mb-0">Montant décaissé</p>
                        <h4 class="m-0"><i class="fas fa-exchange-alt"></i></h4>
                        <h5 class="font-size-20 font-weight-bold mt-0 pt-1 mb-0">200 000 000 000</h5>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card card-box border-0 mb-3 p-3">
                        <p class="mb-0">Ecart budgetaire</p>
                        <h4 class="m-0"><i class="fas fa-arrows-alt-h text-warning"></i></h4>
                        <h5 class="font-size-20 font-weight-bold mt-0 pt-1 mb-0">200 000 000 000</h5>
                    </div>
                </div>
            </div>
            
            <div class="card card-box border-0 p-0 m-0">
                <div class="card-header p-3">
                    <div class="card-header--title"><h6 class="m-0 font-weight-bold">Liste des travaux</h6></div>
                    <div class="card-header--actions">
                        <div class="btn-group" dropdown>
                            <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                                dropdownToggle
                                tooltip="Action"
                                type="button">
                                <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                            </button>
                            <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                                <ul class="nav nav-pills flex-column p-3">
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body p-3">
                    <div class="table-responsive m-0">
                        <table class="table table-hover text-nowrap m-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Designation</th>
                                    <th>Budget validé</th>
                                    <th>Montant décaissé</th>
                                    <th>Ecart</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-toggle="collapse" data-target="#part2_1_1" class="accordion-toggle">
                                        <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                    <td><span class="font-weight-bold">GROS OEUVRE</span></td>
                                    <td><span class="font-weight-bold text-dark">300 000</span></td>
                                    <td><span class="font-weight-bold text-success">200 00</span></td>
                                    <td><span class="badge bg-neutral-success text-success">Validé</span></td>
                                </tr>
                                <tr>
                                    <td colspan="5" class="hiddenRow">
                                        <div class="accordian-body collapse p-3" id="part2_1_1">
                                            <div class="card card-box mb-0">
                                                <div class="card-header p-3">
                                                    <div class="card-header--title">
                                                        <h6 class="m-0 font-weight-bold">Détail du budget travaux</h6>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="table-responsive m-0">
                                                        <table class="table table-hover text-nowrap m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Designation</th>
                                                                    <th>Budget proposé</th>
                                                                    <th>Budget validé</th>
                                                                    <th>Ecart</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>MEULEUSE ANG GWS26-230L VI 2600W D230</span></td>
                                                                    <td><span>150 000</span></td>
                                                                    <td><span>100 00</span></td>
                                                                    <td><span>50 000</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>MEULEUSE ANG GWS26-230L VI 2600W D230</span></td>
                                                                    <td><span>150 000</span></td>
                                                                    <td><span>100 00</span></td>
                                                                    <td><span>50 000</span></td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <th>TOTAL</th>
                                                                    <th><span class="font-weight-bold">300 000</span></th>
                                                                    <th><span class="font-weight-bold">200 000</span></th>
                                                                    <th colspan="2"><span class="font-weight-bold">100 000</span></th>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
        
                                <tr>
                                    <td data-toggle="collapse" data-target="#part2_2_1" class="accordion-toggle">
                                        <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                    <td><span class="font-weight-bold">SECOND OEUVRE</span></td>
                                    <td><span class="font-weight-bold text-dark">300 000</span></td>
                                    <td><span class="font-weight-bold text-success">200 00</span></td>
                                    <td><span class="badge bg-neutral-success text-success">Validé</span></td>
                                </tr>
                                <tr>
                                    <td colspan="5" class="hiddenRow">
                                        <div class="accordian-body collapse p-3" id="part2_2_1">
                                            <div class="card card-box mb-0">
                                                <div class="card-header p-3">
                                                    <div class="card-header--title">
                                                        <h6 class="m-0 font-weight-bold">Détail du budget travaux</h6>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="table-responsive m-0">
                                                        <table class="table table-hover text-nowrap m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Designation</th>
                                                                    <th>Budget proposé</th>
                                                                    <th>Budget validé</th>
                                                                    <th>Ecart</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>MEULEUSE ANG GWS26-230L VI 2600W D230</span></td>
                                                                    <td><span>150 000</span></td>
                                                                    <td><span>100 00</span></td>
                                                                    <td><span>50 000</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>MEULEUSE ANG GWS26-230L VI 2600W D230</span></td>
                                                                    <td><span>150 000</span></td>
                                                                    <td><span>100 00</span></td>
                                                                    <td><span>50 000</span></td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <th>TOTAL</th>
                                                                    <th><span class="font-weight-bold">300 000</span></th>
                                                                    <th><span class="font-weight-bold">200 000</span></th>
                                                                    <th colspan="2"><span class="font-weight-bold">100 000</span></th>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colspan="2">TOTAL: </th>
                                    <th>600 000</th>
                                    <th colspan="2">400 000</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </tab>
        
        <!-- Etat financier -->
        <tab customClass="px-0 py-2">
            <ng-template tabHeading><span><i class="fas fa-university mr-1"></i>Etat financier</span></ng-template>
            <div class="row">
                <div class="col-md-3">
                    <div class="card card-box border-0 bg-night-sky text-white mb-3 p-3">
                        <p class="mb-0">Montant Total Facture</p>
                        <h4 class="m-0"><i class="fas fa-file"></i></h4>
                        <h5 class="font-size-20 font-weight-bold mt-0 pt-1 mb-0">100 000 000 000</h5>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card card-box border-0 text-white bg-tempting-azure mb-3 p-3">
                        <p class="mb-0 text-black">Facture Payées</p>
                        <h4 class="m-0"><i class="fas fa-check-circle"></i></h4>
                        <h5 class="font-size-20 font-weight-bold mt-0 pt-1 mb-0">200 000 000 000</h5>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card card-box border-0 bg-strong-bliss text-white mb-3 p-3">
                        <p class="mb-0">Facture Impayées</p>
                        <h4 class="m-0"><i class="fas fa-times-circle"></i></h4>
                        <h5 class="font-size-20 font-weight-bold mt-0 pt-1 mb-0">200 000 000 000</h5>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card card-box border-0 mb-3 p-3">
                        <p class="mb-0">Ecart budgetaire</p>
                        <h4 class="m-0"><i class="fas fa-arrows-alt-h text-warning"></i></h4>
                        <h5 class="font-size-20 font-weight-bold mt-0 pt-1 mb-0">200 000 000 000</h5>
                    </div>
                </div>
            </div>
            
            <div class="card card-box border-0 mb-4">
                <div class="card-header p-3">
                    <div class="card-header--title"><h5 class="m-0 font-weight-bold">Liste des factures fournisseurs</h5></div>
                    <div class="card-header--actions">
                        <div class="btn-group" dropdown>
                            <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                                dropdownToggle
                                tooltip="Action"
                                type="button">
                                <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                            </button>
                            <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                                <ul class="nav nav-pills flex-column p-3">
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body p-3">
                    <div class="table-responsive m-0">
                        <table class="table table-hover text-nowrap m-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Référence</th>
                                    <th>Désignation</th>
                                    <th>Total facture</th>
                                    <th>Facture payées</th>
                                    <th>Facture impayées</th>
                                    <th>Budget produit validé</th>
                                    <th>Montant décaissé</th>
                                    <th>Ecart budget-total facture</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-toggle="collapse" data-target="#part3_1_1" class="accordion-toggle">
                                        <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                    <td>FAC0001</td>
                                    <td>Facture</td>
                                    <td><span class="text-dark">150 000</span></td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td><span class="text-warning">50 000</span></td>
                                </tr>
                                <tr>
                                    <td colspan="9" class="hiddenRow">
                                        <div class="accordian-body collapse p-3" id="part3_1_1">
                                            <div class="card card-box mb-0">
                                                <div class="card-header p-3">
                                                    <div class="card-header--title">
                                                        <h6 class="m-0 font-weight-bold">Détail facture</h6>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="table-responsive m-0">
                                                        <table class="table table-hover text-nowrap m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Référence</th>
                                                                    <th>Designation</th>
                                                                    <th>Quantité</th>
                                                                    <th>Unité</th>
                                                                    <th>Prix unitaire</th>
                                                                    <th>TVA</th>
                                                                    <th>Remise</th>
                                                                    <th>Total</th>
                                                                    <th>Budget materiel validé</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colspan="9" class="text-center">
                                                                        <span>Aucune donnée</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td data-toggle="collapse" data-target="#part3_1_2" class="accordion-toggle">
                                        <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                    <td>FAC0001</td>
                                    <td>Facture</td>
                                    <td><span class="text-dark">150 000</span></td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td><span class="text-warning">50 000</span></td>
                                </tr>
                                <tr>
                                    <td colspan="9" class="hiddenRow">
                                        <div class="accordian-body collapse p-3" id="part3_1_2">
                                            <div class="card card-box mb-0">
                                                <div class="card-header p-3">
                                                    <div class="card-header--title">
                                                        <h6 class="m-0 font-weight-bold">Détail facture</h6>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="table-responsive m-0">
                                                        <table class="table table-hover text-nowrap m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Référence</th>
                                                                    <th>Designation</th>
                                                                    <th>Quantité</th>
                                                                    <th>Unité</th>
                                                                    <th>Prix unitaire</th>
                                                                    <th>TVA</th>
                                                                    <th>Remise</th>
                                                                    <th>Total</th>
                                                                    <th>Budget materiel validé</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colspan="9" class="text-center">
                                                                        <span>Aucune donnée</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colspan="3">TOTAL: </th>
                                    <th>300 000</th>
                                    <th>200 000</th>
                                    <th>200 000</th>
                                    <th>200 000</th>
                                    <th>200 000</th>
                                    <th>100 000</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            
            <div class="card card-box border-0 mb-4">
                <div class="card-header p-3">
                    <div class="card-header--title"><h5 class="m-0 font-weight-bold">Liste des factures demande de fonds</h5></div>
                    <div class="card-header--actions">
                        <div class="btn-group" dropdown>
                            <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                                dropdownToggle
                                tooltip="Action"
                                type="button">
                                <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                            </button>
                            <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                                <ul class="nav nav-pills flex-column p-3">
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body p-3">
                    <div class="table-responsive m-0">
                        <table class="table table-hover text-nowrap m-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Référence</th>
                                    <th>Désignation</th>
                                    <th>Total facture</th>
                                    <th>Facture payées</th>
                                    <th>Facture impayées</th>
                                    <th>Budget produit validé</th>
                                    <th>Montant décaissé</th>
                                    <th>Ecart budget-total facture</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-toggle="collapse" data-target="#part3_2_1" class="accordion-toggle">
                                        <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                    <td>FAC0001</td>
                                    <td>Facture</td>
                                    <td><span class="text-dark">150 000</span></td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td><span class="text-warning">50 000</span></td>
                                </tr>
                                <tr>
                                    <td colspan="9" class="hiddenRow">
                                        <div class="accordian-body collapse p-3" id="part3_2_1">
                                            <div class="card card-box mb-0">
                                                <div class="card-header p-3">
                                                    <div class="card-header--title">
                                                        <h6 class="m-0 font-weight-bold">Détail facture</h6>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="table-responsive m-0">
                                                        <table class="table table-hover text-nowrap m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Référence</th>
                                                                    <th>Designation</th>
                                                                    <th>Quantité</th>
                                                                    <th>Unité</th>
                                                                    <th>Prix unitaire</th>
                                                                    <th>TVA</th>
                                                                    <th>Remise</th>
                                                                    <th>Total</th>
                                                                    <th>Budget materiel validé</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colspan="9" class="text-center">
                                                                        <span>Aucune donnée</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td data-toggle="collapse" data-target="#part3_2_2" class="accordion-toggle">
                                        <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                    <td>FAC0001</td>
                                    <td>Facture</td>
                                    <td><span class="text-dark">150 000</span></td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td><span class="text-warning">50 000</span></td>
                                </tr>
                                <tr>
                                    <td colspan="9" class="hiddenRow">
                                        <div class="accordian-body collapse p-3" id="part3_2_2">
                                            <div class="card card-box mb-0">
                                                <div class="card-header p-3">
                                                    <div class="card-header--title">
                                                        <h6 class="m-0 font-weight-bold">Détail facture</h6>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="table-responsive m-0">
                                                        <table class="table table-hover text-nowrap m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Référence</th>
                                                                    <th>Designation</th>
                                                                    <th>Quantité</th>
                                                                    <th>Unité</th>
                                                                    <th>Prix unitaire</th>
                                                                    <th>TVA</th>
                                                                    <th>Remise</th>
                                                                    <th>Total</th>
                                                                    <th>Budget materiel validé</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colspan="9" class="text-center">
                                                                        <span>Aucune donnée</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colspan="3">TOTAL: </th>
                                    <th>300 000</th>
                                    <th>200 000</th>
                                    <th>200 000</th>
                                    <th>200 000</th>
                                    <th>200 000</th>
                                    <th>100 000</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            
            <div class="card card-box border-0">
                <div class="card-header p-3">
                    <div class="card-header--title"><h5 class="m-0 font-weight-bold">Liste des factures de mission</h5></div>
                    <div class="card-header--actions">
                        <div class="btn-group" dropdown>
                            <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                                dropdownToggle
                                tooltip="Action"
                                type="button">
                                <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                            </button>
                            <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                                <ul class="nav nav-pills flex-column p-3">
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body p-3">
                    <div class="table-responsive m-0">
                        <table class="table table-hover text-nowrap m-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Référence</th>
                                    <th>Désignation</th>
                                    <th>Total facture</th>
                                    <th>Facture payées</th>
                                    <th>Facture impayées</th>
                                    <th>Budget produit validé</th>
                                    <th>Montant décaissé</th>
                                    <th>Ecart budget-total facture</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-toggle="collapse" data-target="#part3_3_1" class="accordion-toggle">
                                        <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                    <td>FAC0001</td>
                                    <td>Facture</td>
                                    <td><span class="text-dark">150 000</span></td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td><span class="text-warning">50 000</span></td>
                                </tr>
                                <tr>
                                    <td colspan="9" class="hiddenRow">
                                        <div class="accordian-body collapse p-3" id="part3_3_1">
                                            <div class="card card-box mb-0">
                                                <div class="card-header p-3">
                                                    <div class="card-header--title">
                                                        <h6 class="m-0 font-weight-bold">Détail facture</h6>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="table-responsive m-0">
                                                        <table class="table table-hover text-nowrap m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Référence</th>
                                                                    <th>Designation</th>
                                                                    <th>Quantité</th>
                                                                    <th>Unité</th>
                                                                    <th>Prix unitaire</th>
                                                                    <th>TVA</th>
                                                                    <th>Remise</th>
                                                                    <th>Total</th>
                                                                    <th>Budget materiel validé</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colspan="9" class="text-center">
                                                                        <span>Aucune donnée</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td data-toggle="collapse" data-target="#part3_3_2" class="accordion-toggle">
                                        <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                    </td>
                                    <td>FAC0001</td>
                                    <td>Facture</td>
                                    <td><span class="text-dark">150 000</span></td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td>100 000</td>
                                    <td><span class="text-warning">50 000</span></td>
                                </tr>
                                <tr>
                                    <td colspan="9" class="hiddenRow">
                                        <div class="accordian-body collapse p-3" id="part3_3_2">
                                            <div class="card card-box mb-0">
                                                <div class="card-header p-3">
                                                    <div class="card-header--title">
                                                        <h6 class="m-0 font-weight-bold">Détail facture</h6>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <div class="table-responsive m-0">
                                                        <table class="table table-hover text-nowrap m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Référence</th>
                                                                    <th>Designation</th>
                                                                    <th>Quantité</th>
                                                                    <th>Unité</th>
                                                                    <th>Prix unitaire</th>
                                                                    <th>TVA</th>
                                                                    <th>Remise</th>
                                                                    <th>Total</th>
                                                                    <th>Budget materiel validé</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colspan="9" class="text-center">
                                                                        <span>Aucune donnée</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colspan="3">TOTAL: </th>
                                    <th>300 000</th>
                                    <th>200 000</th>
                                    <th>200 000</th>
                                    <th>200 000</th>
                                    <th>200 000</th>
                                    <th>100 000</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </tab>
    </tabset>
</div>