import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-travaux-detail',
  templateUrl: './travaux-detail.component.html',
  styleUrls: ['./travaux-detail.component.scss']
})
export class TravauxDetailComponent implements OnInit {

  constructor(public globals: ThemeOptions) { }

  ngOnInit() {
  }

}
