<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-file display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1 class="font-weight-bold m-0">Détail du document</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description m-0">
                    <span><i class="fas fa-file-alt"></i><span class="font-weight-bold mr-1" title="Categorie"> Autorisation</span></span> | 
                    <span class="font-weight-bold ml-1 text-danger" title="Status">ACO</span>
                </div>
                <p class="m-0" title="Date de création du document">
                    <i class="fas fa-calendar-alt"></i>
                    <span class="ml-1">Créer le: 11/11/2021 11:11:11</span>
                </p>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button [routerLink]="['/pages/projet/detail/5']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<div class="card card-box border-0">
    <div class="card-header p-3">
        <div class="card-header--title font-weight-bold">Information sur le document</div>
        <div class="card-header--actions">
            <div class="btn-group" dropdown>
                <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                    dropdownToggle
                    tooltip="Action"
                    type="button">
                    <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                </button>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg rounded border-0 overflow-hidden p-0">
                    <ul class="nav nav-pills flex-column p-3">
                        <li class="nav-item" (click)="newVersionModal.show()">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 fas fa-plus-circle"></i><span>Nouvelle version</span></a>
                        </li>
                        <li class="nav-item" >
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-eye"></i><span>Dernière version</span></a>
                        </li>
                        <li class="nav-item" (click)="sendMailerModal.show()">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 fas fa-envelope"></i><span>Partage par mail</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>



        <!-- <div class="card-header--actions">
            <button class="btn btn-gradient bg-plum-plate mr-2" tooltip="Voir la dernière version du document">
                <i class="ion-md-eye mr-1"></i> Dernière version
            </button>
            
            <button class="btn btn-gradient bg-grow-early"
                tooltip="Ajouter un nouvelle version au document"
                (click)="newVersionModal.show()"
                ><i class="fas fa-plus-circle mr-1"></i> Nouvelle version
            </button>
        </div> -->

    </div>
    <div class="card-body p-0">
        <div class="border rounded m-3 p-3">
            <div class="row">
                <div class="col-md-4">
                    <p class="mt-2 mb-0"><b>Type:</b> <span class="ml-2">PDF</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Numéro d'ordre:</b> <span class="ml-2">0001</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Numéro d'ordre:</b> <span class="ml-2">1</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Libellé:</b> <span class="ml-2">Document 1</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Catégorie:</b> <span class="ml-2">Autorisation</span></p>
                </div>
                <div class="col-md-4">
                    <p class="mt-2 mb-0"><b>Status:</b> <span class="ml-2">ACO</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Créer par:</b> <span class="ml-2">Kouassi ghislain</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de création:</b> <span class="ml-2">31/07/2021 10:00:00</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Modifier par:</b> <span class="ml-2">Kouassi ghislain</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de modification: </b> <span class="ml-2">31/07/2021 10:00:00</span></p>
                </div>
                <div class="col-md-4">
                    <a [routerLink]="" class="rounded py-5 bg-secondary d-flex align-items-center align-content-center">
                        <fa-icon [icon]="['far', 'file-pdf']" class="display-2 text-danger mx-auto my-4"></fa-icon>
                    </a>
                </div>
                <hr class="my-2">
                <div class="col-md-12">
                    <p class="mb-2"><b>Description:</b></p>
                    <div class="border rounded p-3 bg-light">
                        <span>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi deleniti dicta dolorum reiciendis vitae maxime architecto animi odit, quos nihil quia corrupti odio ipsa rem, at ullam necessitatibus repellat in.
                            Sequi rerum ipsam alias modi, cumque, animi necessitatibus voluptatem dolores corporis delectus voluptatibus provident, iste reiciendis. Similique, temporibus. Quam, autem itaque quis deleniti exercitationem laboriosam. Rem doloremque temporibus nihil cupiditate!
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="m-3">
            <h4 class="my-2">Liste des versions du document</h4>
            <div class="table-responsive m-0">
                <table class="table table-hover table-striped m-0">
                    <thead>
                        <tr>
                            <th>Identifiant</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Date de transmission</th>
                            <th>Mode de transmission</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>30/09/2021 09:37:45</td>
                            <td>ACO</td>
                            <td>30/09/2021 09:37:45</td>
                            <td>Courier</td>
                            <td class="text-center">
                                <button placement="auto"
                                    tooltip="Plus d'infos" 
                                    class="btn d-40 p-0 btn-pill mr-1 btn-primary"
                                    type="button"  (click)="singleVersionModal.show()">
                                    <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                </button>
                                <button placement="auto" tooltip="Modifier"  class="btn d-40 p-0 btn-pill mr-1 btn-info" type="button">
                                    <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                                </button>
                                <button  placement="auto"  tooltip="Supprimer"  class="btn d-40 p-0 btn-pill btn-danger" type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>30/09/2021 09:41:45</td>
                            <td>ACO</td>
                            <td>30/09/2021 09:41:45</td>
                            <td>Mail</td>
                            <td class="text-center">
                                <button placement="auto"
                                    tooltip="Plus d'infos" 
                                    class="btn d-40 p-0 btn-pill mr-1 btn-primary"
                                    type="button"  (click)="singleVersionModal.show()">
                                    <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                </button>
                                <button placement="auto" tooltip="Modifier"  class="btn d-40 p-0 btn-pill mr-1 btn-info" type="button">
                                    <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                                </button>
                                <button  placement="auto"  tooltip="Supprimer"  class="btn d-40 p-0 btn-pill btn-danger" type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="card-footer px-3 p-2 text-right">
        <button class="btn btn-info mr-2"><i class="fas fa-pen mr-1"></i> Modifier</button>
        <button class="btn btn-warning mr-2"><i class="ion-md-print mr-1"></i> Imprimer</button>
        <button class="btn btn-danger"><i class="ion-md-trash mr-1"></i> Supprimer</button>
    </div>
</div>

<!-- Modal de detail de version -->
<div #singleVersionModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Détails de la version 1</h6>
                <button type="button" class="close" aria-label="Close" (click)="singleVersionModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-3">
                <div class="border rounded">
                    <perfect-scrollbar [autoPropagation]="true" class="scroll-area scroll-area-sm shadow-none py-3" style="height: 30em;">
                        <div class="mx-3">
                            <a [routerLink]="" class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                <fa-icon [icon]="['far', 'file-pdf']" class="display-2 text-danger mx-auto"></fa-icon>
                            </a>
        
                            <div>
                                <p class="mt-2 mb-0"><b>Version:</b> <span class="ml-2">1</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Status:</b> <span class="ml-2">ACO</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Date de transmission:</b> <span class="ml-2">30/09/2021 09:41:45</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Mode de transmission:</b> <span class="ml-2">Mail</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Date:</b> <span class="ml-2">30/09/2021 09:41:45</span></p>
                                <hr class="my-2">
                                <p class="mb-2"><b>Réserve :</b> <span class="ml-2">Une reserve</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Enregister par:</b> <span class="ml-2">Kouassi ghislain</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Date de d'enregistrement:</b> <span class="ml-2">31/07/2021</span></p>
                                <hr class="my-2">
                                <p class="mb-2"><b>Commentaire:</b></p>
                                <textarea class="form-control" rows="5" disabled>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus, consectetur quasi perferendis omnis aut repellendus at quos reiciendis odio mollitia ex minima ullam? Cumque nulla neque, perferendis magni beatae tempore!</textarea>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
            <div class="modal-footer px-3 py-2">
                <button class="btn btn-info"><i class="fas fa-pen mr-1"></i> Modifier</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal d'ajout de version -->
<div #newVersionModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter une nouvelle verion</h6>
                <button type="button" class="close" aria-label="Close" (click)="newVersionModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-3">
                <div class="border rounded">
                    <perfect-scrollbar [autoPropagation]="true" class="scroll-area scroll-area-sm shadow-none py-3" style="height: 30em;">
                        <div class="mx-3">
                            <form>
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="identifiant">Identifiant(version): </label>
                                            <input type="text" id="identifiant" class="form-control" placeholder="Identifiant">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="mode">Mode de transmission: </label>
                                            <select id="mode" class="form-control">
                                                <option value="courier">Courier</option>
                                                <option value="mail">Mail</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="document">Document:</label>
                                            <div class="custom-file">
                                                <input type="file" id="document" class="custom-file-input" title="Document">
                                                <label class="custom-file-label" for="document">Choisir un document</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="description">Description:</label>
                                            <textarea id="description" rows="5" class="form-control" title="Description" placeholder="Description du document"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
            <div class="modal-footer px-3 py-2">
                <button class="btn btn-first m-2" type="button"><i class="fas fa-broom mr-1"></i>Nettoyer</button>
                <button class="btn btn-success" type="submit"><i class="fas fa-save mr-1"></i>Enregister</button>
            </div>
        </div>
    </div>
</div>

<!-- Partage par mail -->
<div #sendMailerModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Partager le document par mail</h6>
                <button type="button" class="close" aria-label="Close" (click)="sendMailerModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-3">
                <form>
                    <input type="email" data-role="tagsinput" name="tags" id="mail" class="form-control mb-2" placeholder="A">
                    <input type="text" id="objet" class="form-control mb-2" placeholder="Objet">
                    <textarea id="description" class="form-control" rows="3" placeholder="Description"></textarea>
                    <hr>
                    <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                        <fa-icon [icon]="['far', 'file-pdf']" class="display-2 text-danger mx-auto"></fa-icon>
                    </div>
                </form>
            </div>
            <div class="modal-footer px-3 py-2">
                <button class="btn btn-first" type="submit"><i class="fas fa-paper-plane mr-1"></i>Envoyer</button>
            </div>
        </div>
    </div>
</div>