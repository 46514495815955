<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-umbrella display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1 class="font-weight-bold m-0">Détail projet</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description m-0" title="Référence et libellé du projet">
                    <span><i class="fas fa-file-alt"></i> ( {{projet?.reference}} )</span>
                    <span class="ml-1">{{projet?.nomProjet}}</span>
                </div>
                <p class="m-0" title="Date début et date fin du projet">
                    <i class="fas fa-calendar-alt"></i>
                    <span class="ml-1">Du {{projet?.dateDebut}} au {{projet?.dateFin}}</span>
                </p>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button class="btn btn-success mr-2" (click)="newChantierModal.show()" tooltip="Ajouter un chantier"><i class="fas fa-plus-circle mr-2"></i>Chantier</button>
            <button [routerLink]="['/pages/projet/liste']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<accordion [closeOthers]="true" [isAnimated]="true" class="accordion mb-3">
    <accordion-group class="card card-box">
        <button accordion-heading class="btn btn-link btn-lg d-flex align-items-center justify-content-between"
                type="button">
            <span>Cliquer pour dérouler</span>
            <fa-icon [icon]="['fas', 'angle-up']" class="font-size-xl"></fa-icon>
        </button>
        <div class="border rounded m-3">
            <!-- Information générales -->
            <div class="p-0 m-0">
                <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Information générales</h4></div>
                <hr class="m-0">
                <div class="row">
                    <div class="col-md-4">
                        <p class="mt-2 mb-0"><b>Numéro:</b> <span class="ml-2">{{projet?.reference}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Libellé du projet:</b> <span class="ml-2">{{projet?.nomProjet}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Date début:</b> <span class="ml-2">{{projet?.dateDebut}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Date fin:</b> <span class="ml-2">{{projet?.dateFin}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Banque affiliée(s):</b> <span class="ml-2 badge bg-neutral-success text-success">{{projet?.banqueAffilier}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Caisse affiliée(s):</b> <span class="ml-2 badge bg-neutral-first text-first">{{projet?.caisseAffilier}}</span></p>
                    </div>
                    <div class="col-md-4">
                        <p class="mt-2 mb-0"><b>Description:</b> <span class="ml-2">{{projet?.description}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Client:</b> <span class="ml-2">{{projet?.client}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Créer par:</b> <span class="ml-2">{{projet?.creerPar}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Date de création:</b> <span class="ml-2">{{projet?.dateCreation}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Modifier par:</b> <span class="ml-2">{{projet?.modifierPar}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Date de modification:</b> <span class="ml-2">{{projet?.dateModification}}</span></p>
                    </div>
                    <div class="col-md-4">
                        <a [routerLink]="" class="card-img-wrapper rounded mt-2 mr-1">
                            <div class="img-wrapper-overlay">
                                <div class="overlay-btn-wrapper">
                                    <button class="btn btn-first p-0 d-50 rounded-circle" type="button" tooltip="Agrandir">
                                        <span class="btn-wrapper--icon">
                                            <i class="fas fa-expand"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <img alt="..." class="card-img-top rounded" src="assets/images/stock-photos/stock-1.jpg"/>
                        </a>
                    </div>
                </div>
            </div>
            <hr class="mt-2 mb-5">

            <!-- Localisation du projet -->
            <div class="p-0 m-0">
                <hr class="m-0">
                <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Localisation du projet</h4></div>
                <hr class="m-0">
                <div class="row">
                    <div class="col-md-4">
                        <p class="mt-2 mb-0"><b>Pays:</b> <span class="ml-2">{{projet?.reference}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Ville:</b> <span class="ml-2">{{projet?.nomProjet}}</span></p>
                    </div>
                    <div class="col-md-4">
                        <p class="mt-2 mb-0"><b>Commune:</b> <span class="ml-2">{{projet?.reference}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Quartier:</b> <span class="ml-2">{{projet?.nomProjet}}</span></p>
                    </div>
                    <div class="col-md-4">
                        <p class="mt-2 mb-0"><b>Longitude:</b> <span class="ml-2">{{projet?.longitude}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Latitude</b> <span class="ml-2">{{projet?.latitude}}</span></p>
                    </div>
                </div>
            </div>
            <hr class="mt-2 mb-5">

            <!-- Poste de responsabilités -->
            <div class="p-0 m-0">
                <hr class="m-0">
                <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Poste de responsabilités</h4></div>
                <hr class="m-0">
                <div class="row">
                    <div class="col-md-4">
                        <p class="mt-2 mb-0"><b>Chef du projet:</b> <span class="ml-2">{{projet?.chefProjet}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Architect:</b> <span class="ml-2">{{projet?.architect}}</span></p>
                    </div>
                    <div class="col-md-4">
                        <p class="mt-2 mb-0"><b>Responsable d'étude:</b> <span class="ml-2">{{projet?.responsableEtude}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Responsable de contrôle:</b> <span class="ml-2">{{projet?.responsableControl}}</span></p>
                    </div>
                    <div class="col-md-4">
                        <p class="mt-2 mb-0"><b>Maître d'ouvrage:</b> <span class="ml-2">{{projet?.maitreOuvrage}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Membres</b> <span class="ml-2">{{projet?.membre}}</span></p>
                    </div>
                </div>
            </div>
        </div>
        <hr class="m-0">
        <div class="text-right py-2 px-3">
            <button class="btn btn-info mr-2" type="button"><i class="fas fa-pen mr-1"></i>Modifier</button>
            <button class="btn btn-warning mr-2" type="button"><i class="ion-md-print mr-1"></i>Imprimer</button>
            <button class="btn btn-danger" type="button"><i class="ion-md-trash mr-1"></i>Supprimer</button>
        </div>
    </accordion-group>
</accordion>

<div class="mb-0">
    <tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow" type="tabs" >
        <!-- Debut tableau de bord -->
        <tab customClass="pl-0 pr-1 py-2">
            <ng-template tabHeading><span><i class="fas fa-tachometer-alt mr-1"></i>Tableau de Bord</span></ng-template>
            <div class="row">
                <div class="col-md-3">
                    <div class="card mb-4">
                        <div class="py-4 px-3 d-flex align-items-center">
                            <h1 class="m-0"><i class="fas fa-dollar-sign text-dark opacity-2"></i></h1>
                            <div class="ml-2">
                                <span class="d-block opacity-7">Prix du marché</span>
                                <p class="m-0 font-weight-bold font-size-lg text-first">{{projet?.prixMarche | number:'': 'fr-FR'}} <span class="text-black-50">XOF</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card mb-4">
                        <div class="py-4 px-3 d-flex align-items-center">
                            <h1 class="m-0"><i class="fas fa-hand-holding-usd text-dark opacity-2"></i></h1>
                            <div class="ml-2">
                                <span class="d-block opacity-7">Budget Validé</span>
                                <p class="m-0 font-weight-bold font-size-lg">{{projet?.prixMarche | number:'': 'fr-FR'}} <span class="text-black-50">XOF</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card mb-4">
                        <div class="py-4 px-3 d-flex align-items-center">
                            <h1 class="m-0"><i class="fas fa-coins text-dark opacity-2"></i></h1>
                            <div class="ml-2">
                                <span class="d-block opacity-7">Dépenses</span>
                                <p class="m-0 font-weight-bold font-size-lg text-danger">{{projet?.prixMarche | number:'': 'fr-FR'}} <span class="text-black-50">XOF</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3"><!-- Ecart budgetaire = Budget - Depense -->
                    <div class="card mb-4">
                        <div class="py-4 px-3 d-flex align-items-center">
                            <h1 class="m-0"><i class="fas fa-external-link-alt text-dark opacity-2"></i></h1>
                            <div class="ml-2">
                                <span class="d-block opacity-7">Ecart budgetaire</span>
                                <p class="m-0 font-weight-bold font-size-lg">{{projet?.prixMarche | number:'': 'fr-FR'}} <span class="text-black-50">XOF</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="card bg-secondary mb-4" style="height: 108px;">
                        <div class="py-4 px-3 mt-1 d-flex align-items-center">
                            <h1 class="m-0"><i class="fas fa-file-alt text-first"></i></h1>
                            <div class="ml-2">
                                <span class="d-block opacity-7">Contrat(s) ouvrier(s)</span>
                                <p class="m-0 font-weight-bold font-size-lg">0</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card bg-secondary mb-4" style="height: 108px;">
                        <div class="py-4 px-3 mt-1 d-flex align-items-center">
                            <h1 class="m-0"><i class="fas fa-file-alt text-first"></i></h1>
                            <div class="ml-2">
                                <span class="d-block opacity-7">Contrat(s) prestataire(s)</span>
                                <p class="m-0 font-weight-bold font-size-lg">0</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card mb-4">
                        <div class="no-gutters row">
                            <div class="col-md-6 p-3">
                                <div class="divider-v divider-v-lg"></div>
                                <button class="btn border-0 w-100 btn-transition-none rounded-0 shadow-none btn-outline-gray px-1" type="button">
                                    <h2 class="m-0">
                                        <span class="font-weight-bold text-dark">0</span>
                                    </h2>
                                    <div class="font-size-md text-black-50">Accident(s) avec arrêt de travail</div>
                                </button>
                            </div>
                            <div class="col-md-6 p-3">
                                <button class="btn border-0 w-100 btn-transition-none rounded-0 shadow-none btn-outline-gray px-1" type="button">
                                    <h2 class="m-0">
                                        <span class="font-weight-bold text-warning">0</span>
                                    </h2>
                                    <div class="font-size-md text-black-50">Accident(s) sans arrêt de travail</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="card bg-arielle-smile py-4 px-3 mb-4" style="height: 325px;">
                        <h6 class="text-center font-weight-bold mb-0">Taux evolution projet</h6>
                        <div class="text-center my-4">
                            <ngx-gauge append="%"
                                backgroundColor="#f1f1f1"
                                cap="round" class="img-fluid"
                                foregroundColor="#1bc943" max="100" min="0"
                                size="180" thick="16"
                                type="full" value="{{projet?.evolution}}">
                            </ngx-gauge>
                        </div>
                        <div class="text-center">
                            <h3 class="m-0 font-weight-bold text-white text-uppercase">{{projet?.status}}</h3>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="card mb-4" style="height: 325px;">
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex">
                                    <fa-icon [icon]="['far', 'check-circle']" class="h1 d-block mr-3 text-success line-height-1"></fa-icon>
                                    <div>
                                        <b>Chantier(s) Terminé(s)</b>
                                        <div class="text-black-50">
                                            <span class="text-success px-1">16%</span>
                                        </div>
                                    </div>
                                </div>
                                <div  class="font-weight-bold text-danger font-size-lg">905</div>
                            </div>
                            <div class="divider my-3"></div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex">
                                    <fa-icon [icon]="['far', 'times-circle']" class="h1 d-block mr-3 text-danger line-height-1"></fa-icon>
                                    <div>
                                        <b>Chantier(s) Arreté(s)</b>
                                        <div class="text-black-50">
                                            <span class="text-success px-1">14%</span>
                                        </div>
                                    </div>
                                </div>
                                <div  class="font-weight-bold text-success font-size-lg">786</div>
                            </div>
                            <div class="divider my-3"></div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex">
                                    <i class="fas fa-pause-circle h1 d-block mr-3 text-info line-height-1"></i>
                                    <div>
                                        <b>Chantier(s) (en cours)</b>
                                        <div class="text-black-50">
                                            <span class="text-success px-1">13%</span>
                                        </div>
                                    </div>
                                </div>
                                <div  class="font-weight-bold text-warning font-size-lg">255</div>
                            </div>
                            <div class="divider my-3"></div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex">
                                    <i-feather class="h1 d-block mr-3 text-first line-height-1" name="layers"></i-feather>
                                    <div>
                                        <b>Total chantier(s)</b>
                                        <div class="text-black-50">
                                            <span class="text-success px-1">100%</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="font-weight-bold text-first font-size-lg">917</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card mb-4 mt-3">
                        <div class="grid-menu grid-menu-2col">
                            <div class="no-gutters row">
                                <div class="col-sm-6">
                                    <button class="btn border-0 w-100 btn-transition-none rounded-0 shadow-none btn-outline-gray py-4 px-1"
                                            type="button">
                                        <fa-icon [icon]="['far', 'bell']" class="h2 d-block mb-2 mt-1 text-black-50"></fa-icon>
                                        <div class="font-weight-bold text-black">45</div>
                                        <div class="font-size-md mb-1 text-black-50">Accident de travail</div>
                                    </button>
                                </div>
                                <div class="col-sm-6">
                                    <button class="btn border-0 w-100 btn-transition-none rounded-0 shadow-none btn-outline-gray py-4 px-1"
                                            type="button">
                                        <h2 class="m-0"><i class="fas fa-exclamation-triangle d-block mb-3 mt-1 text-warning"></i></h2>
                                        <div class="font-weight-bold text-black">56</div>
                                        <div class="font-size-md mb-1 text-black-50">Incidents</div>
                                    </button>
                                </div>
                                <div class="col-sm-6">
                                    <button class="btn border-0 w-100 btn-transition-none rounded-0 shadow-none btn-outline-gray py-4 px-1"
                                            type="button">
                                        <fa-icon [icon]="['far', 'check-circle']" class="h2 d-block mb-2 mt-1 text-success"></fa-icon>
                                        <div class="font-weight-bold text-black">45</div>
                                        <div class="font-size-md mb-1 text-black-50">Incident resolu</div>
                                    </button>
                                </div>
                                <div class="col-sm-6">
                                    <button class="btn border-0 w-100 btn-transition-none rounded-0 shadow-none btn-outline-gray py-4 px-1"
                                            type="button">
                                        <fa-icon [icon]="['far', 'times-circle']" class="h2 d-block mb-2 mt-1 text-danger"></fa-icon>
                                        <div class="font-weight-bold text-black">56</div>
                                        <div class="font-size-md mb-1 text-black-50">Incident non resolu</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="mt-0">

            <!-- Section du bas contenant la liste de ressource -->
            <tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow" type="tabs" >
                <!-- Liste des ressources -->
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span>Liste des ressources</span></ng-template>
                    <div class="card card-box border-0 mt-2">
                        <div class="card-header p-3">
                            <div class="card-header--title font-weight-bold">Liste des resources approuvées ( <span class="text-black-50">3</span> )</div>
                            <div class="card-header--actions">
                                <div class="btn-group" dropdown>
                                    <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                                        dropdownToggle
                                        tooltip="Action"
                                        type="button">
                                        <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                                    </button>
                                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                                        <ul class="nav nav-pills flex-column p-3">
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="row p-3">
                                <div class="col-md-3">
                                    <select required name="" id="" class="form-control">
                                        <option value="" disabled selected hidden>Selectionner un chantier</option>
                                        <option value="">Chantier 1</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <select required name="" id="" class="form-control">
                                        <option value="" disabled selected hidden>Selectionner un travail</option>
                                        <option value="">Travaux 1</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <select required name="" id="" class="form-control">
                                        <option value="" disabled selected hidden>Selectionner une tâche</option>
                                        <option value="">Tâche 1</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <select required name="" id="" class="form-control">
                                        <option value="" disabled selected hidden>Selectionner le demandeur</option>
                                        <option value="">Demandeur 1</option>
                                    </select>
                                </div>
                            </div>
                            <hr class="m-0">
                            <div class="d-flex justify-content-between p-3">
                                <div class="search-wrapper">
                                  <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                                  <input class="form-control" placeholder="Recherche dans la liste" type="search">
                                </div>
                                <div class="text-right">
                                    <button class="btn btn-first mr-2" type="button" (click)="validOrRejectModal.show()"><i class="fas fa-check mr-1"></i>Approuver / Rejeter</button>
                                </div>
                            </div>
                            <div class="px-3 pb-3">
                                <div class="table-responsive m-0">
                                    <table class="table table-hover text-nowrap has-checkbox m-0">
                                        <thead class="thead-light">
                                            <tr>
                                                <th class="text-center"><div class="ml-3"></div></th>
                                                <th class="text-center" style="width: 5%;">
                                                    <label class="custom-control custom-checkbox">
                                                        <input class="custom-control-input" type="checkbox" (change)="lock($event)">
                                                        <span class="custom-control-label"></span>
                                                        <span class="custom-control-description sr-only">select all</span>
                                                    </label>
                                                </th>
                                                <th>Type</th>
                                                <th>Resource</th>
                                                <th>Quantité prevue</th>
                                                <th>Quantité commandée</th>
                                                <th>Quantité restante</th>
                                                <th>Unité</th>
                                                <th>Priorité</th>
                                                <th>Date souhaité</th>
                                                <th>Période d'utilisation</th>
                                                <th>Periode travaux</th>
                                                <th>Demandeur</th>
                                                <th>Date demande</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-toggle="collapse" data-target="#row1" class="accordion-toggle">
                                                    <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                                </td>
                                                <td class="text-center">
                                                    <label class="custom-control custom-checkbox">
                                                        <input class="custom-control-input" type="checkbox" [checked]="checks">
                                                        <span class="custom-control-label"></span>
                                                        <span class="custom-control-description sr-only">select row 1</span>
                                                    </label>
                                                </td>
                                                <td>Matériel</td>
                                                <td>Carburant</td>
                                                <td>200</td>
                                                <td>78</td>
                                                <td>122</td>
                                                <td>FFT</td>
                                                <td><span class="badge badge-danger">Urgente</span></td>
                                                <td>12/09/2021</td>
                                                <td><span class="badge bg-warning text-white">12/09/2021</span></td>
                                                <td><span class="badge bg-warning text-white">Du 01/07/2021 au 30/07/2021</span></td>
                                                <td>Kouassi ghislain</td>
                                                <td>12/09/2021 09:22:45</td>
                                            </tr>
                                            <tr>
                                                <td colspan="14" class="hiddenRow p-3">
                                                    <div class="accordian-body collapse" id="row1">Sous element 1</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td  data-toggle="collapse" data-target="#row2" class="accordion-toggle">
                                                    <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                                                </td>
                                                <td class="text-center">
                                                    <label class="custom-control custom-checkbox">
                                                        <input class="custom-control-input" type="checkbox" [checked]="checks">
                                                        <span class="custom-control-label"></span>
                                                        <span class="custom-control-description sr-only">select row 1</span>
                                                    </label>
                                                </td>
                                                <td>Matériel</td>
                                                <td>Carburant</td>
                                                <td>200</td>
                                                <td>78</td>
                                                <td>122</td>
                                                <td>FFT</td>
                                                <td><span class="badge badge-danger">Urgente</span></td>
                                                <td>12/09/2021</td>
                                                <td><span class="badge bg-warning text-white">12/09/2021</span></td>
                                                <td><span class="badge bg-warning text-white">Du 01/07/2021 au 30/07/2021</span></td>
                                                <td>Kouassi ghislain</td>
                                                <td>12/09/2021 09:22:45</td>
                                            </tr>
                                            <tr>
                                                <td colspan="14" class="hiddenRow p-3">
                                                    <div class="accordian-body collapse" id="row2">Sous element 2</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colspan="4">TOTAL</th>
                                                <th>400</th>
                                                <th>156</th>
                                                <th>244</th>
                                                <th colspan="7"></th>
                                                <th></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer p-3">
                            <div class="d-flex justify-content-between">
                                <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                                <div class="d-flex align-items-center">
                                    <span>Show</span>
                                    <select class="mx-1 form-control form-control-sm" id="" name="">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="40">50</option>
                                        <option value="40">100</option>
                                    </select>
                                    <span>entries</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>

                <!-- Rapport journaliers -->
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span>Rapport journaliers</span></ng-template>
                    <div *ngIf="defaultRow1.length > 0">
                        <div class="card card-box border-0 mt-2">
                            <div class="card-header p-3">
                                <div class="card-header--title font-weight-bold">Liste des rapports Journalier ( <span class="text-black-50">{{defaultRow1.length}}</span> )</div>
                                <div class="card-header--actions">
                                    <div class="card-header--actions">
                                        <div class="btn-group" dropdown>
                                            <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                                                dropdownToggle
                                                tooltip="Action"
                                                type="button">
                                                <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                                            </button>
                                            <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                                                <ul class="nav nav-pills flex-column p-3">
                                                    <li class="nav-item">
                                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <div class="row p-3">
                                    <div class="col-md-4">
                                        <select required name="" id="chantier" class="form-control">
                                            <option value="" disabled selected hidden>Selectionner un chantier</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <select required name="" id="chantier" class="form-control">
                                            <option value="" disabled selected hidden>Selectionner un travail</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <select required name="" id="projet" class="form-control">
                                            <option value="" disabled selected hidden>Validateur</option>
                                        </select>
                                    </div>
                                </div>
                                <hr class="m-0">
                                <div class="d-flex justify-content-between p-3">
                                    <div class="search-wrapper">
                                      <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                                      <input class="form-control" placeholder="Recherche dans la liste" type="search">
                                    </div>
                                </div>
                                <div class="px-3 pb-3">
                                    <div class="table-responsive m-0">
                                        <table class="table table-hover text-nowrap m-0">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>Référence</th>
                                                    <th>Libellé</th>
                                                    <th>Travaux</th>
                                                    <th>Date</th>
                                                    <th>Etat</th>
                                                    <th>Validateur</th>
                                                    <th>Créer par</th>
                                                    <th>Date de création&nbsp;<i class="fas fa-sort mr-1"></i></th>
                                                    <th class="text-center">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of defaultRow1">
                                                    <td>Rp0001</td>
                                                    <td>Fin des travaux</td>
                                                    <td>Construction</td>
                                                    <td>111/11/2021</td>
                                                    <td><span class="badge badge-{{item.etatValidation == 'validée' ? 'success' : item.etatValidation == 'En attente' ? 'warning' : 'danger'}}">{{item.etatValidation}}</span></td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="avatar-icon-wrapper">
                                                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                                            </div>
                                                            <div>
                                                                <a [routerLink]="" class="font-weight-bold text-black" title="Kouassi ghislain">Kouassi ghislain</a>
                                                                <span class="text-black-50 d-block badge bg-neutral-dark text-dark">{{item.fValidateur}}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>Kouassi ghislain</td>
                                                    <td>10/07/2021 11:11:11</td>
                                                    <td class="text-center">
                                                        <button class="btn d-40 p-0 btn-pill mr-2 btn-primary" type="button" tooltip="Plus d'infos" placement="auto">
                                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                                        </button>
                                                        <button class="btn d-40 p-0 btn-pill mr-2 btn-warning" type="button" tooltip="Imprimer" placement="auto">
                                                            <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer p-3">
                                <div class="d-flex justify-content-between">
                                    <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                                    <div class="d-flex align-items-center">
                                        <span>Show</span>
                                        <select class="mx-1 form-control form-control-sm" id="" name="">
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="40">40</option>
                                            <option value="40">50</option>
                                            <option value="40">100</option>
                                        </select>
                                        <span>entries</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="defaultRow1.length == 0">
                        <div class="hero-wrapper bg-composed-wrapper bg-white rounded">
                            <div class="flex-grow-1 w-100 d-flex align-items-center">
                                <div class="bg-composed-wrapper--image bg-composed-filter-rm opacity-4 rounded" style="background-image: url('assets/images/hero-bg/hero-2.jpg');"></div>
                                <div class="bg-composed-wrapper--content py-5">
                                    <div class="container py-5">
                                        <div class="row">
                                            <div class="col-xl-6">
                                                <div class="text-black mt-3">
                                                    <h1 class="display-3 mb-3 font-weight-bold">
                                                        Aucun rapport journalier pour ce projet
                                                    </h1>
                                                    <div class="divider border-2 border-dark my-5 border-light opacity-2 rounded-circle w-25"></div>
                                                    <p class="font-size-lg text-black-50">
                                                        Ces rapports sont ceux effectués en fin de journée de travail.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 px-0 d-none d-xl-flex align-items-center">
                                                <img src="assets/img/block_note1.png" class="mx-auto d-block img-fluid" alt="...">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>

                <!-- PV de visite -->
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span>PV de visite</span></ng-template>
                    <div class="mt-2">
                        <app-proces-verbal-list></app-proces-verbal-list>
                    </div>
                </tab>

                <!-- CR de réunion -->
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span>CR de réunion</span></ng-template>
                    <div class="mt-2">
                        <div class="card card-box border-0 mt-2">
                            <div class="card-header p-3">
                                <div class="card-header--title font-weight-bold">Liste des compte rendu de réunion </div>
                                <div class="card-header--actions">
                                    <div class="card-header--actions">
                                        <div class="btn-group" dropdown>
                                            <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                                                dropdownToggle
                                                tooltip="Action"
                                                type="button">
                                                <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                                            </button>
                                            <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                                                <ul class="nav nav-pills flex-column p-3">
                                                    <li class="nav-item">
                                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <div class="d-flex justify-content-between p-3">
                                    <div class="search-wrapper">
                                      <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                                      <input class="form-control" placeholder="Recherche dans la liste" type="search">
                                    </div>
                                </div>
                                <div class="px-3 pb-3">
                                    <div class="table-responsive m-0">
                                        <table class="table table-hover text-nowrap m-0">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>N°</th>
                                                    <th>Objet</th>
                                                    <th>Date de la réunion</th>
                                                    <th>Intervenant</th>
                                                    <th>Date de creation</th>
                                                    <th class="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of defaultRow1">
                                                    <td>CR0001</td>
                                                    <td>Réunion 1</td>
                                                    <td>11/11/2021</td>
                                                    <td>11</td>
                                                    <td>10/07/2021 11:11:11</td>
                                                    <td class="text-center">
                                                        <button class="btn d-40 p-0 btn-pill mr-2 btn-primary" type="button" tooltip="Plus d'infos" placement="auto">
                                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                                        </button>
                                                        <button class="btn d-40 p-0 btn-pill mr-2 btn-warning" type="button" tooltip="Imprimer" placement="auto">
                                                            <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer p-3">
                                <div class="d-flex justify-content-between">
                                    <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                                    <div class="d-flex align-items-center">
                                        <span>Show</span>
                                        <select class="mx-1 form-control form-control-sm" id="" name="">
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="40">40</option>
                                            <option value="40">50</option>
                                            <option value="40">100</option>
                                        </select>
                                        <span>entries</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>

                <!-- Reserve des travaux -->
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span>Reserve des travaux</span></ng-template>
                    <div class="mt-2">
                        <app-reserve-list></app-reserve-list>
                    </div>
                </tab>

                <!-- Worklog -->
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span>Worklog</span></ng-template>
                    <div class="card card-box border-0 mt-2">
                        <div class="card-header p-3">
                            <div class="card-header--title font-weight-bold">Liste worklog ( <span class="text-black-50">2</span> )</div>
                            <div class="card-header--actions">
                                <div class="card-header--actions">
                                    <div class="btn-group" dropdown>
                                        <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                                            dropdownToggle
                                            tooltip="Action"
                                            type="button">
                                            <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                                        </button>
                                        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                                            <ul class="nav nav-pills flex-column p-3">
                                                <li class="nav-item">
                                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="row p-3">
                                <div class="col-md-3">
                                    <select required name="" id="projet" class="form-control">
                                        <option value="" disabled selected hidden selected>Selectionner le projet</option>
                                        <option value="">Projet 1</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <select required name="" id="chantier" class="form-control">
                                        <option value="" disabled selected hidden>Selectionner un chantier</option>
                                        <option value="">Chantier 1</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <select required name="" id="chantier" class="form-control">
                                        <option value="" disabled selected hidden>Selectionner un travail</option>
                                        <option value="">Travaux 1</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <select required name="" id="chantier" class="form-control">
                                        <option value="" disabled selected hidden>Selectionner une tâche</option>
                                        <option value="">Tâche 1</option>
                                    </select>
                                </div>
                            </div>
                            <hr class="m-0">
                            <div class="d-flex justify-content-between p-3">
                                <div class="search-wrapper">
                                  <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                                  <input class="form-control" placeholder="Recherche dans la liste" type="search">
                                </div>
                                <div class="text-right">
                                    <button class="btn btn-success" (click)="newWorklogModal.show()"><i class="fas fa-plus-circle mr-1"></i>Ajouter</button>
                                </div>
                            </div>
                            <div class="px-3 pb-3">
                                <div class="table-responsive m-0">
                                    <table class="table table-hover text-nowrap m-0">
                                        <thead class="thead-light">
                                            <tr>
                                                <th class="text-left">Id</th>
                                                <th class="text-left">Libellé</th>
                                                <th class="text-left">Description</th>
                                                <th class="text-left">Temps mis</th>
                                                <th class="text-left">Enregistrer par</th>
                                                <th class="text-left">Date de création</th>
                                                <th class="text-left">Modifier par</th>
                                                <th class="text-left">Date de modification</th>
                                                <th class="text-left">Date systeme</th>
                                                <th class="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Fin journée</td>
                                                <td>Une description</td>
                                                <td>11 h 56 min 10 s</td>
                                                <td><span class="badge badge-dark">Ghislain</span></td>
                                                <td><span class="badge badge-info">10/07/2021</span></td>
                                                <td><span class="badge badge-dark">Ghislain</span></td>
                                                <td><span class="badge badge-info">10/07/2021</span></td>
                                                <td><span class="badge badge-first">10/07/2021 15:42:23</span></td>
                                                <td class="text-center">
                                                    <button class="btn d-40 p-0 btn-pill mr-2 btn-primary" type="button" tooltip="Plus d'infos" placement="auto" (click)="detailWorklogModal.show()">
                                                        <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                                    </button>
                                                    <button class="btn d-40 p-0 btn-pill mr-2 btn-warning" type="button" tooltip="Imprimer" placement="auto">
                                                        <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                                                    </button>
                                                    <button class="btn d-40 p-0 btn-pill mr-2 btn-danger" type="button" tooltip="Supprimer" placement="auto">
                                                        <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Fin journée</td>
                                                <td>Une description</td>
                                                <td>11 h 56 min 10 s</td>
                                                <td><span class="badge badge-dark">Ghislain</span></td>
                                                <td><span class="badge badge-info">10/07/2021</span></td>
                                                <td><span class="badge badge-dark">Ghislain</span></td>
                                                <td><span class="badge badge-info">10/07/2021</span></td>
                                                <td><span class="badge badge-first">10/07/2021 15:42:23</span></td>
                                                <td class="text-center">
                                                    <button class="btn d-40 p-0 btn-pill mr-2 btn-primary" type="button" tooltip="Plus d'infos" placement="auto"(click)="detailWorklogModal.show()">
                                                        <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                                    </button>
                                                    <button class="btn d-40 p-0 btn-pill mr-2 btn-warning" type="button" tooltip="Imprimer" placement="auto">
                                                        <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                                                    </button>
                                                    <button class="btn d-40 p-0 btn-pill mr-2 btn-danger" type="button" tooltip="Supprimer" placement="auto">
                                                        <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer p-3">
                            <div class="d-flex justify-content-between">
                                <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                                <div class="d-flex align-items-center">
                                    <span>Show</span>
                                    <select class="mx-1 form-control form-control-sm" id="" name="">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="40">50</option>
                                        <option value="40">100</option>
                                    </select>
                                    <span>entries</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
            </tabset>
        </tab>

        <!-- Preparation des chantiers -->
        <tab customClass="pl-0 pr-1 py-2">
            <ng-template tabHeading><span><i class="fas fa-border-all mr-1"></i>Préparation des Chantiers</span></ng-template>
            <div>
                <app-chantier-list></app-chantier-list>
            </div>
        </tab>

        <!-- Suivie et control technique -->
        <tab customClass="pl-0 pr-1 py-2">
            <ng-template tabHeading><span><i class="fas fa-arrows-alt mr-2"></i>Suivi et controle technique</span></ng-template>

            <div class="card px-3 py-2 mb-3">
                <form [formGroup]="filterForm">
                    <div class="form-row">
                        <div class="col-md-4">
                            <select id="mode" formControlName="mode" class="form-control">
                                <option disabled selected>Choisir le mode recherche</option>
                                <option *ngFor="let search of searchRow" [value]="search.value">{{search.label}}</option>
                            </select>
                        </div>
                        <div class="col-md-4" *ngIf="f.mode.value == 'PAR CHANTIER' || f.mode.value == 'PAR TRAVAUX'">
                            <input type="text" class="form-control" id="chantier" formControlName="chantier" list="chantiers" placeholder="Choisir le chantier">
                            <datalist id="chantiers">
                                <option *ngFor="let chantier of chantiers" [value]="chantier.label">
                            </datalist>
                        </div>
                        <div class="col-md-4" *ngIf="f.mode.value == 'PAR TRAVAUX'">
                            <input type="text" class="form-control" id="travaux" formControlName="travaux" list="travaus" placeholder="Choisir le travaux">
                            <datalist id="travaus">
                                <option *ngFor="let travail of travaux" [value]="travail.label">
                            </datalist>
                        </div>
                    </div>
                </form>
            </div>

            <tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow mb-0" type="tabs"  *ngIf="f.chantier.value != '' || f.travaux.value != '' || f.tache.value != ''">
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span>{{ f.mode.value == 'PAR CHANTIER' ? f.chantier.value : f.travaux.value }}</span></ng-template>
                    <!-- Si la recherche est par chantier ##1-->
                    <div class="pt-0" *ngIf="f.mode.value == 'PAR CHANTIER' && f.chantier.value != '' ">
                        <div class="card p-3 mb-3">
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="mb-2"><b>Libellé:</b><span class="ml-2">Chantier 1</span></p>
                                    <p class="mb-2"><b>Date de début:</b><span class="ml-2">06/07/2021</span></p>
                                    <p class="mb-2"><b>Date de fin:</b><span class="ml-2">06/07/2021</span></p>
                                    <p class="mb-2"><b>Durée :</b><span class="ml-2">100 Jours</span></p>
                                    <p class="mb-2"><b>Statut :</b><span class="ml-2 badge bg-neutral-warning text-warning">En attente</span></p>
                                    <p class="mb-2"><b>Longitude:</b><span class="ml-2">300 m²</span></p>
                                    <p class="mb-2"><b>Latitude:</b><span class="ml-2">300 m²</span></p>
                                    <p class="mb-2"><b>Sous-traité:</b><span class="ml-2">OUI</span></p>
                                    <p class="mb-2"><b>Soutraitant :</b><span class="ml-2">Kouassi ghislain</span></p>
                                </div>
                                <div class="col-md-4">
                                    <p class="mb-2"><b>Montant soutraitance:</b><span class="ml-2 badge bg-neutral-first text-first">500 000 000 XOF</span></p>
                                    <p class="mb-2"><b>Chef chantier:</b><span class="ml-2">Kouassi ghislain</span></p>
                                    <p class="mb-2"><b>Budget:</b><span class="ml-2 badge bg-neutral-info text-info">500 000 000 XOF</span></p>
                                    <p class="mb-2"><b>Depenses:</b><span class="ml-2 badge bg-neutral-warning text-warning">500 000 000 XOF</span></p>
                                    <p class="mb-2"><b>Enregistrer par:</b><span class="ml-2">Kouassi ghislain</span></p>
                                    <p class="mb-2"><b>Date d'enregistrement:</b><span class="ml-2">06/07/2021 13:05:12</span></p>
                                    <p class="mb-2"><b>Modifier par:</b><span class="ml-2">Kouassi ghislain</span></p>
                                    <p class="mb-2"><b>Date de modification:</b><span class="ml-2">06/07/2021 13:05:12</span></p>
                                </div>
                                <div class="col-md-4">
                                    <p class="mb-3 text-center"><b>Evolution des travaux</b></p>
                                    <div class="text-center my-4">
                                        <ngx-gauge append="%"
                                            backgroundColor="#f1f1f1"
                                            cap="round" class="img-fluid"
                                            foregroundColor="#1bc943" max="100" min="0"
                                            size="180" thick="16"
                                            type="full" value="0">
                                        </ngx-gauge>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <tabset type="tabs" class="mb-0">
                            <tab>
                                <ng-template tabHeading>Travaux</ng-template>
                                <div class="mt-3">
                                    <app-travaux-list [widgetView]="travauxWidgetView"></app-travaux-list>
                                </div>
                            </tab>
                            <tab>
                                <ng-template tabHeading>Commandes</ng-template>
                                <div class="mt-3">
                                    <app-commande-list></app-commande-list>
                                </div>
                            </tab>
                            <tab>
                                <ng-template tabHeading>Compte d'exploitation</ng-template>
                                <div class="mt-3">
                                    <div class="row mb-3">
                                        <div class="col">
                                            <a [routerLink]="" class="card card-box border-0 rounded p-2" title="Total de Budget">
                                                <div class="d-flex align-items-center mt-1">
                                                    <h1 class="m-0">
                                                        <i class="fas fa-piggy-bank img-fluid mx-3 mt-1 text-first"></i>
                                                    </h1>
                                                    <div>
                                                        <div class="text-black-50">Budgets</div>
                                                        <div class="font-weight-bold display-4 py-1 text-black">
                                                            <span [countUp]="4"></span>
                                                        </div>
                                                        <div class="text-black-50 font-size-sm">
                                                            <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1 text-warning"></fa-icon>
                                                            100%
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col">
                                            <a [routerLink]="" class="card card-box border-0 rounded p-2" title="Total de budge Validées">
                                                <div class="d-flex align-items-center mt-1">
                                                    <h1 class="m-0">
                                                        <i class="fas fa-check img-fluid mx-3 mt-1 text-success"></i>
                                                    </h1>
                                                    <div>
                                                        <div class="text-black-50">Validée(s)</div>
                                                        <div class="font-weight-bold display-4 py-1 text-black">
                                                            <span [countUp]="2"></span>
                                                        </div>
                                                        <div class="text-black-50 font-size-sm">
                                                            <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1 text-warning"></fa-icon>
                                                            50%
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col">
                                            <a [routerLink]="" class="card card-box border-0 rounded p-2" title="Total de budget en attente de validation">
                                                <div class="d-flex align-items-center mt-1">
                                                    <h1 class="m-0">
                                                        <i class="fas fa-pause-circle img-fluid mx-3 mt-1 text-warning"></i>
                                                    </h1>
                                                    <div>
                                                        <div class="text-black-50">En attente</div>
                                                        <div class="font-weight-bold display-4 py-1 text-black">
                                                            <span [countUp]="2"></span>
                                                        </div>
                                                        <div class="text-black-50 font-size-sm">
                                                            <fa-icon [icon]="['fas', 'chevron-down']" class="font-size-sm pr-1 text-warning"></fa-icon>
                                                            50%
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-3">
                                            <a [routerLink]="" class="card card-box border-0 rounded px-3 py-2" title="Montant Proposé">
                                                <div class="mt-1">
                                                    <div class="text-black-50">Montant Proposé (FCFA)</div>
                                                    <div class="font-weight-bold display-4 py-1 text-dark">
                                                        <span>8741 000 000</span>
                                                    </div>
                                                    <div class="text-black-50 font-size-sm">
                                                        <i class="fas fa-percent font-size-sm pr-1 text-first"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-3">
                                            <a [routerLink]="" class="card card-box border-0 rounded rounded px-3 py-2" title="Montant Validé">
                                                <div class="mt-1">
                                                    <div class="text-black-50">Montant Validé (FCFA)</div>
                                                    <div class="font-weight-bold display-4 py-1 text-dark">
                                                        <span>874 000 000</span>
                                                    </div>
                                                    <div class="text-black-50 font-size-sm">
                                                        <i class="fas fa-check-circle font-size-sm pr-1 text-success"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="card card-box border-0">
                                        <div class="card-header p-3">
                                            <div class="card-header--title font-weight-bold">Prevision Budgétaire</div>
                                            <div class="card-header--actions">
                                                <div class="btn-group" dropdown>
                                                    <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret" dropdownToggle tooltip="Action" type="button">
                                                        <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                                                    </button>
                                                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                                                        <ul class="nav nav-pills flex-column p-3">
                                                            <li class="nav-item">
                                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="d-flex justify-content-between p-3">
                                                <div class="search-wrapper">
                                                    <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                                                    <input class="form-control" placeholder="Recherche dans la liste" type="search">
                                                </div>
                                            </div>
                                            <div class="table-responsive m-0">
                                                <table class="table table-hover text-nowrap m-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Travaux</th>
                                                            <th>Montant proposé</th>
                                                            <th>Montant validé</th>
                                                            <th>Etat</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let budget of budgets">
                                                            <td>{{budget.travaux}}</td>
                                                            <td><span class="font-weight-bold text-dark">{{budget.mtnPropose | number:'': 'fr-FR'}}</span></td>
                                                            <td><span class="font-weight-bold text-success">{{budget.mtnValide | number:'': 'fr-FR'}}</span></td>
                                                            <td>
                                                                <span class="badge bg-neutral-{{budget.etat == 'validé' ? 'success text-success' : 'warning text-warning'}}">{{budget.etat}}</span>
                                                            </td>
                                                            <td>
                                                                <button placement="auto" tooltip="Plus d'infos"
                                                                    class="btn d-40 p-0 btn-pill mr-1 btn-primary"
                                                                    type="button" (click)="singleBudgetModal.show()">
                                                                    <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                                                </button>
                                                                <button placement="auto" tooltip="Modifier"
                                                                    class="btn d-40 p-0 btn-pill mr-1 btn-info"
                                                                    type="button" *ngIf="budget.etat != 'validé'">
                                                                    <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                                                                </button>
                                                                <button placement="auto" tooltip="Etablir le budget"
                                                                    class="btn d-40 p-0 btn-pill btn-success"
                                                                    type="button" *ngIf="budget.etat != 'validé'"
                                                                    (click)="etablitBudgetModal.show()">
                                                                    <span class="btn-wrapper--icon"><i class="fas fa-plus"></i></span>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="card-footer p-3">
                                            <div class="d-flex justify-content-between">
                                                <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                                                <div class="d-flex align-items-center">
                                                    <span>Show</span>
                                                    <select class="mx-1 form-control form-control-sm" id="" name="">
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="30">30</option>
                                                        <option value="40">40</option>
                                                        <option value="40">50</option>
                                                        <option value="40">100</option>
                                                    </select>
                                                    <span>entries</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </tab>
                            <tab>
                                <ng-template tabHeading>Réunion de chantier</ng-template>
                                <div class="mt-3">
                                    <app-reunion-list></app-reunion-list>
                                </div>
                            </tab>
                            <tab>
                                <ng-template tabHeading>Visite de chantier</ng-template>
                                <div class="mt-3">
                                    <app-visite-list></app-visite-list>
                                </div>
                            </tab>
                            <tab>
                                <ng-template tabHeading>HSE</ng-template>
                                <div class="mt-3">
                                    <app-situation-hse-list [listView]="listView"></app-situation-hse-list>
                                </div>
                            </tab>
                            <tab>
                                <ng-template tabHeading>Rapport chantier</ng-template>
                                <div class="mt-3">
                                    <app-rapport-list></app-rapport-list>
                                </div>
                            </tab>
                        </tabset>
                    </div>

                    <!-- Si la recherche est par travaux ##2 -->
                    <div class="pt-0" *ngIf="f.mode.value == 'PAR TRAVAUX' && f.travaux.value != '' ">
                        <div>
                            <div class="card p-3 mb-3">
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="mb-2"><b>Libellé:</b><span class="ml-2">Chantier 1</span></p>
                                        <p class="mb-2"><b>Date de début:</b><span class="ml-2">06/07/2021</span></p>
                                        <p class="mb-2"><b>Date de fin:</b><span class="ml-2">06/07/2021</span></p>
                                        <p class="mb-2"><b>Durée :</b><span class="ml-2">100 Jours</span></p>
                                        <p class="mb-2"><b>Statut :</b><span class="ml-2 badge bg-neutral-warning text-warning">En attente</span></p>
                                        <p class="mb-2"><b>Longitude:</b><span class="ml-2">300 m²</span></p>
                                        <p class="mb-2"><b>Latitude:</b><span class="ml-2">300 m²</span></p>
                                        <p class="mb-2"><b>Sous-traité:</b><span class="ml-2">OUI</span></p>
                                        <p class="mb-2"><b>Soutraitant :</b><span class="ml-2">Kouassi ghislain</span></p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="mb-2"><b>Montant soutraitance:</b><span class="ml-2 badge bg-neutral-first text-first">500 000 000 XOF</span></p>
                                        <p class="mb-2"><b>Chef chantier:</b><span class="ml-2">Kouassi ghislain</span></p>
                                        <p class="mb-2"><b>Budget:</b><span class="ml-2 badge bg-neutral-info text-info">500 000 000 XOF</span></p>
                                        <p class="mb-2"><b>Depenses:</b><span class="ml-2 badge bg-neutral-warning text-warning">500 000 000 XOF</span></p>
                                        <p class="mb-2"><b>Enregistrer par:</b><span class="ml-2">Kouassi ghislain</span></p>
                                        <p class="mb-2"><b>Date d'enregistrement:</b><span class="ml-2">06/07/2021 13:05:12</span></p>
                                        <p class="mb-2"><b>Modifier par:</b><span class="ml-2">Kouassi ghislain</span></p>
                                        <p class="mb-2"><b>Date de modification:</b><span class="ml-2">06/07/2021 13:05:12</span></p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="mb-3 text-center"><b>Evolution des tâches</b></p>
                                        <div class="text-center my-4">
                                            <ngx-gauge append="%"
                                                backgroundColor="#f1f1f1"
                                                cap="round" class="img-fluid"
                                                foregroundColor="#1bc943" max="100" min="0"
                                                size="180" thick="16"
                                                type="full" value="0">
                                            </ngx-gauge>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <tabset type="tabs" class="mb-0">
                                <tab>
                                    <ng-template tabHeading>Tâches</ng-template>
                                    <div class="mt-3">
                                        <app-tache-list [widgetView]="tacheWidgetView"></app-tache-list>
                                    </div>
                                </tab>
                                <tab>
                                    <ng-template tabHeading>ressources</ng-template>
                                    <div class="mt-3">
                                        <div class="card card-box border-0">
                                            <div class="card-header p-3">
                                                <div class="card-header--title"><h6 class="font-weight-bold m-0 text-black">Liste des ressources</h6></div>
                                                <div class="card-header--actions">
                                                    <div class="btn-group" dropdown>
                                                        <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret mr-2" dropdownToggle tooltip="Action" type="button">
                                                            <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                                                        </button>
                                                        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                                                            <ul class="nav nav-pills flex-column p-3">
                                                                <li class="nav-item">
                                                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="d-flex justify-content-between mb-3">
                                                    <div class="search-wrapper">
                                                      <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                                                      <input class="form-control" placeholder="Recherche dans la liste" type="search">
                                                    </div>
                                                </div>
                                                <div class="table-responsive m-0">
                                                    <table class="table table-hover text-nowrap m-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Type</th>
                                                                <th>Libellé</th>
                                                                <th>Quantité prevue</th>
                                                                <th>Quantité commandée</th>
                                                                <th>Quantité restante</th>
                                                                <th>Priorité</th>
                                                                <th>Date souhaité</th>
                                                                <th>Date demande</th>
                                                                <th>Période d'utilisation</th>
                                                                <th class="text-center">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Ressource de base</td>
                                                                <td>Ciment</td>
                                                                <td><span class="text-first font-weight-bold">100</span></td>
                                                                <td><span class="text-success font-weight-bold">50</span></td>
                                                                <td><span class="text-warning font-weight-bold">50</span></td>
                                                                <td><span class="badge badge-danger">Urgente</span></td>
                                                                <td><span class="badge bg-neutral-info text-info ">05-05-2020</span></td>
                                                                <td><span class="badge bg-neutral-dark text-dark ">05-05-2020 11:11:11</span></td>
                                                                <td><span class="badge bg-neutral-warning text-warning ">05/05/2020 - 11/11/2021</span></td>
                                                                <td class="text-center">
                                                                    <button class="btn d-40 p-0 btn-pill mr-2 btn-primary" tooltip="Plus d'infos" placement="auto" type="button">
                                                                      <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                                                    </button>
                                                                    <button class="btn d-40 p-0 btn-pill mr-2 btn-warning" tooltip="Imprimer" placement="auto"  type="button">
                                                                      <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                                                                    </button>
                                                                    <button class="btn d-40 p-0 btn-pill btn-danger" tooltip="Supprimer" placement="auto" type="button">
                                                                      <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                                                    </button>
                                                                  </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Ressource de base</td>
                                                                <td>Ciment</td>
                                                                <td><span class="text-first font-weight-bold">100</span></td>
                                                                <td><span class="text-success font-weight-bold">50</span></td>
                                                                <td><span class="text-warning font-weight-bold">50</span></td>
                                                                <td><span class="badge badge-danger">Urgente</span></td>
                                                                <td><span class="badge bg-neutral-info text-info ">05-05-2020</span></td>
                                                                <td><span class="badge bg-neutral-dark text-dark ">05-05-2020 11:11:11</span></td>
                                                                <td><span class="badge bg-neutral-warning text-warning ">05/05/2020 - 11/11/2021</span></td>
                                                                <td class="text-center">
                                                                    <button class="btn d-40 p-0 btn-pill mr-2 btn-primary" tooltip="Plus d'infos" placement="auto" type="button">
                                                                      <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                                                    </button>
                                                                    <button class="btn d-40 p-0 btn-pill mr-2 btn-warning" tooltip="Imprimer" placement="auto"  type="button">
                                                                      <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                                                                    </button>
                                                                    <button class="btn d-40 p-0 btn-pill btn-danger" tooltip="Supprimer" placement="auto" type="button">
                                                                      <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                                                    </button>
                                                                  </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="card-footer p-3">
                                                <div class="d-flex justify-content-between">
                                                    <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                                                    <div class="d-flex align-items-center">
                                                        <span>Show</span>
                                                        <select class="mx-1 form-control form-control-sm" id="" name="">
                                                            <option value="10">10</option>
                                                            <option value="20">20</option>
                                                            <option value="30">30</option>
                                                            <option value="40">40</option>
                                                            <option value="40">50</option>
                                                            <option value="40">100</option>
                                                        </select>
                                                        <span>entries</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </tab>
                            </tabset>
                        </div>
                    </div>
                </tab>
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span>Planing</span></ng-template>
                    <div class="card card-box border-0">
                        <div class="card-header p-3">
                            <div class="card-header--title">Le planing</div>
                            <div class="card-header--actions">
                                <div class="btn-group" dropdown>
                                    <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                                        dropdownToggle
                                        tooltip="Action"
                                        type="button">
                                        <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                                    </button>
                                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                                        <ul class="nav nav-pills flex-column p-3">
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body text-center p-3">le planing</div>
                        <div class="card-footer px-3 py-2">
                            <button class="btn btn-first">Sauvegarder</button>
                        </div>
                    </div>
                </tab>
            </tabset>
        </tab>

        <!-- Matrice contractuelle -->
        <tab customClass="pl-0 pr-1 py-2"><ng-template tabHeading><span><i class="fas fa-file-alt mr-2"></i>Matrice contractuelle</span></ng-template>
            <div>
                <app-fichier-list></app-fichier-list>
            </div>
        </tab>
    </tabset>
</div>

<!-- MODALS -->
<!-- Modal d'ajout de chantier -->
<div #newChantierModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Formulaire d'ajout de chantier pour ce projet</h6>
                <button type="button" class="close" aria-label="Close" (click)="newChantierModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <app-chantier-edit></app-chantier-edit>
            </div>
          </div>
      </div>
  </div>

<!-- Modal d'approbation / rejet de ressource -->
<div #validOrRejectModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate">
                <h6 class="modal-title">Validation ou rejet des ressources </h6>
                <button type="button" class="close" aria-label="Close" (click)="validOrRejectModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="border rounded p-3">
                    <div class="mb-4">
                        <alert type="info" class="text-center">
                            <div class="d-flex align-items-center align-content-center">
                                <span>
                                    <img src="../../../assets/img/info.png" alt="" style="width: 100px;">
                                </span>
                                <span class="text-left ml-3">
                                    <h3 class="font-weight-bold">Voulez-vous valider ou rejeter les ressources selectionnées ?</h3>
                                    <p>
                                        Les ressources validées concernant le matériel seront soumis aux services achat, 
                                        tandis que les ressources concernant les véhicules devront être analysé par le responsable logistique.
                                        Si vous décidez de valider les ressources, veuillez précisez la date de livraison souhaitée s'il vous plait et la mention "bon pour accord" dans le champs commentaire.
                                    </p>
                                </span>
                            </div>
                        </alert>
                    </div>

                    <div class="table-responsive mb-3">
                        <table class="table table-hover table-alternate text-nowrap m-0">
                            <thead>
                                <tr>
                                    <th>Designation</th>
                                    <th>Periode travaux</th>
                                    <th>Quantité prevue</th>
                                    <th>Date livraison souhaitée</th>
                                    <th>Changer date livraison <span class="text-danger">*</span></th>
                                    <th>Quantité à commander <span class="text-danger">*</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Carburant</td>
                                    <td><span class="badge badge-dark">09-07-2021 12:23 - 09-07-2021 12:25</span></td>
                                    <td>15</td>
                                    <td>09-07-2021</td>
                                    <td>
                                        <input type="date" class="form-control" style="width: 13em;">
                                    </td>
                                    <td>
                                        <input type="number" class="form-control" style="width: 13em;" placeholder="Quantité à commandés">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="form-row">
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="comment">Commentaire <span class="text-danger">*</span></label>
                                <textarea rows="3" class="form-control mb-0" placeholder="Veuillez saisir l'expression << bon pour accord >> pour valider la demande"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer text-right px-3 py-2">
                <button class="btn btn-secondary mr-2" type="button" (click)="validOrRejectModal.hide()"><i class="fas fa-times"></i> Annuler</button>
                <button class="btn btn-success mr-2" type="button"><i class="fas fa-check mr-1"></i>Valider</button>
                <button class="btn btn-danger" type="button"><i class="fas fa-times mr-1"></i>Rejeter</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal d'ajout de worklog -->
<div #newWorklogModal="bs-modal" bsModal class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="card bg-secondary shadow-none border-0">
                    <div class="card-header d-block bg-white py-4">
                        <div class="text-center">
                            <a [routerLink]=""><i class="fas fa-times text-black-50" (click)="newWorklogModal.hide()"></i></a>
                            <h5 class="mt-3 mb-0">Ajouter un nouveau worklog</h5>
                        </div>
                    </div><hr class="m-0">
                    <div class="card-body px-lg-5 py-lg-5">
                        <form>
                            <div class="form-group mb-3">
                                <div class="input-group input-group-alternative">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-list"></i></span>
                                    </div>
                                    <input type="text" class="form-control" name="libelle" placeholder="Entrez le libellé">
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="input-group input-group-alternative">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-clock"></i></span>
                                    </div>
                                    <input type="text" class="form-control" name="tempsMis" placeholder="Temps mis (00 h 00 min 00 s)">
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="input-group input-group-alternative">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                                    </div>
                                    <input type="date" class="form-control" name="date" placeholder="Entrez la date">
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="input-group input-group-alternative">
                                    <textarea name="description" rows="3" class="form-control" placeholder="Entrez une description"></textarea>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="fichier">
                                    <label class="custom-file-label" for="fichier">Joindre un fichier</label>
                                </div>
                            </div>

                            <div class="text-center">
                                <button class="btn btn-secondary mr-2" type="button" (click)="newWorklogModal.hide()">
                                    <span class="btn-wrapper--icon"><i class="fas fa-times"></i></span>
                                    <span class="btn-wrapper--label">Annuler</span>
                                </button>
                                <button class="btn btn-first ml-2" type="button">
                                    <span class="btn-wrapper--icon"><i class="fas fa-save"></i></span>
                                    <span class="btn-wrapper--label">Enregistrer</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal detail worklog -->
<div #detailWorklogModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Worklog .....</h6>
                <button type="button" class="close" aria-label="Close" (click)="detailWorklogModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="m-3 p-3 border rounded">
                    <hr class="m-0">
                    <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Worklog....</h4></div>
                    <div class="row">
                        <div class="col-md-4">
                            <p class="mt-2 mb-0"><b>Référence:</b> <span class="ml-2"></span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Projet:</b> <span class="ml-2"></span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Chantier:</b> <span class="ml-2"></span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Libellé:</b> <span class="ml-2"></span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Temps mis:</b> <span class="ml-2"></span></p>
                            <hr class="my-2">
                        </div>
                        <div class="col-md-4">
                            <p class="mt-2 mb-0"><b>Créer par:</b> <span class="ml-2"></span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date de création:</b> <span class="ml-2"></span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Modifier par:</b> <span class="ml-2"></span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date de modification:</b> <span class="ml-2"></span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date systeme:</b> <span class="ml-2"></span></p>
                            <hr class="my-2">
                        </div>
                        <div class="col-md-4">
                            <a [routerLink]="" class="card-img-wrapper rounded mt-2 mr-1">
                                <div class="img-wrapper-overlay">
                                    <div class="overlay-btn-wrapper">
                                        <button class="btn btn-first p-0 d-50 rounded-circle" type="button" tooltip="Agrandir">
                                            <span class="btn-wrapper--icon">
                                                <i class="fas fa-expand"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <img alt="..." class="card-img-top rounded" src="assets/images/stock-photos/stock-1.jpg"/>
                            </a>
                        </div>
                    </div>
                    <div class="">
                        <p class="m-0"><b>Description:</b></p>
                        <hr class="my-2">
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus vitae nisi minus adipisci voluptatibus dolorum laudantium recusandae magni, ipsum maxime praesentium esse fuga sed labore natus eligendi voluptas repellendus. Dolore.
                            Recusandae tempore asperiores soluta quaerat! Voluptatum earum cumque ipsum ad ratione, vero suscipit necessitatibus itaque fugit recusandae! Unde deleniti minus dolor nisi adipisci quae eaque dolores fuga voluptas? Id, numquam.
                            Vitae necessitatibus sequi tempora voluptate rerum amet ut doloremque, recusandae, officia odit possimus cum pariatur eligendi nesciunt dicta similique, voluptatum voluptatibus minima quam. Recusandae, veniam totam ullam hic esse sit?
                        </p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button class="btn btn-warning mr-2"><i class="ion-md-print mr-1"></i> Imprimer</button>
                    <button class="btn btn-info mr-2"><i class="fas fa-pen mr-1"></i> Modifier</button>
                    <button class="btn btn-danger"><i class="ion-md-trash mr-1"></i> Supprimer</button>
                </div>
            </div>
          </div>
      </div>
</div>

<!-- Modal de planification de budget -->
<div #etablitBudgetModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered rounded">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate rounded-top">
                <h6 class="modal-title">Etablir un budget piur le travaux GROS OEUVRE</h6>
                <button type="button" class="close" aria-label="Close" (click)="etablitBudgetModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-4">
                    <form [formGroup]="budgetForm" (ngSubmit)="onSubmit()">
                        <h4 class="font-weight-bold m-0">Informations du budget</h4><hr>
                        <div class="form-row">
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="mois">Mois: </label>
                                    <input type="month" id="mois" formControlName="mois" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="typeRessource">Type de ressource: </label>
                                    <select id="typeRessource" formControlName="typeRessource" class="form-control">
                                        <option *ngFor="let type of typeRessource" [value]="type.value">{{type.label}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="ressource">Ressource: </label>
                                    <select id="ressource" formControlName="ressource" class="form-control">
                                        <option value="Ressource 1">Ressource 1</option>
                                        <option value="Ressource 2">Ressource 2</option>
                                        <option value="Ressource 3">Ressource 3</option>
                                        <option value="Ressource 4">Ressource 4</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="montant">Montant: </label>
                                    <input type="number" min="0" id="montant" formControlName="montant" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="text-right">
                            <button  class="btn btn-success mr-1" type="button" (click)="saveBudget()"><i class="fas fa-plus-circle mr-1"></i> Ajouter</button>
                            <button  class="btn btn-secondary mr-1" type="button"><i class="fas fa-download mr-1"></i> Importer</button>
                            <button  class="btn btn-secondary" type="button" tooltip="Générer le model"><i class="fas fa-upload mr-1"></i> Model</button>
                        </div>
                    </form>
                </div>
            
                <div class="table-responsive m-0">
                    <table  class="table table-hover text-nowrap m-0">
                        <thead>
                            <tr>
                                <th>Mois</th>
                                <th>Libellé ressource</th>
                                <th>Montant proposé</th>
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of Data">
                                <td>{{item.mois}}</td>
                                <td>{{item.ressource}}</td>
                                <td>{{item.montant | number:'': 'fr-FR'}}</td>
                                <td class="text-center">
                                    <button class="btn d-40 p-0 btn-pill btn-danger" placement="auto" tooltip="Supprimer" type="button" (click)="deleteBudget(item)">
                                        <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="Data.length == 0">
                                <td colspan="5">
                                    <div class="text-center">
                                        <h5 class="m-0 text-dark">
                                            Aucune donnée trouvée; Reseigner le formulaire ci-dessus puis cliquer sur le boutton "Ajouter" pour en ajouter.
                                        </h5>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr class="m-0">
                <div class="text-right px-4 py-0">
                    <button class="btn btn-first m-2" type="button"><i class="fas fa-broom mr-1"></i>Nettoyer</button>
                    <button class="btn btn-success" type="submit" (click)="onSubmit()"><i class="fas fa-save mr-1"></i>Enregister</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal de détail Budget -->
<div #singleBudgetModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered rounded">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate rounded-top">
                <h6 class="modal-title">Détail du budget</h6>
                <button type="button" class="close" aria-label="Close" (click)="singleBudgetModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
              <div class="modal-body p-3">
                <div class="border rounded p-3 mb-4">
                    <div class="row">
                        <div class="col">
                            <p class="mt-2 mb-0"><b>Type de ressource:</b><span class="ml-2">Motériel</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Etat:</b> <span class="ml-2 badge bg-neutral-success text-success">Validé</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Validateur:</b> <span class="ml-2">Kouassi ghislain</span></p>
                            <hr class="my-2">
                            <p class="mb-2"><b>Date de validation:</b> <span class="ml-2">15/09/2021 15:54:45</span></p>
                        </div>
                        <div class="col">
                            <p class="mt-2 mb-0"><b>Créer par:</b> <span class="ml-2">Kouassi ghislain</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date de création:</b> <span class="ml-2">15/09/2021 15:54:45</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Modifier par:</b> <span class="ml-2">Kouassi ghislain</span></p>
                            <hr class="my-2">
                            <p class="mb-2"><b>Date de modification:</b> <span class="ml-2">15/09/2021 15:54:45</span></p>
                        </div>
                    </div>
                </div>
                <div class="table-responsive mb-0">
                    <table  class="table table-hover text-nowrap m-0">
                        <thead>
                            <tr>
                                <th>Libellé ressource</th>
                                <th>Montant proposé</th>
                                <th>Montant validé</th>
                                <th>Mois</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Cable</td>
                                <td><span class="text-dark">100 000 000</span></td>
                                <td><span class="text-success">10 000 000</span></td>
                                <td>Septembre 2021</td>
                            </tr>
                            <tr>
                                <td>Designation</td>
                                <td><span class="text-dark">400 000 000</span></td>
                                <td><span class="text-success">40 000 000</span></td>
                                <td>Septembre 2021</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td><span class="font-weight-bold">TOTAL</span></td>
                                <td><span class="font-weight-bold text-dark">500 000 000</span></td>
                                <td colspan="2"><span class="font-weight-bold text-success">50 000 000</span></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
              </div>
              <div class="modal-footer text-right px-3 py-2">
                <button class="btn btn-warning" type="button"><i class="ion-md-print mr-2"></i> Imprimer</button>
            </div>
        </div>
    </div>
</div>
<!-- FIN MODALS -->