<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-wallet display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
              <h1>{{ressource.type == 'Ressource composite' ? 'Détail ressource ressource composite': 'Détail ressource ressource de base'}}</h1>
              <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">{{ressource.famille}}</div>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button [routerLink]="['/pages/ressource/liste']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<div class="bg-white mb-3 col-md-12" id="detail">
    <div class="row p-4">
        <div class="col-md-3 img-square-wrapper shadow-sm p-2 text-center">
            <img src="{{ressource.imageUrl}}" alt="{{ressource.libelle}}" id="ressource">
        </div>
        <div class="col-md-9">
            <div class="divider mb-2"></div>
            <div class="row">
                <div class="col-md-6">
                    <p class="mb-0"><b>Référence: </b>{{ressource.reference}}</p><hr class="my-2">
                    <p class="mb-0"><b>Catégorie: </b>{{ressource.categorie}}</p><hr class="my-2">
                    <p class="mb-0"><b>Famille:  </b>{{ressource.famille}}</p><hr class="my-2">
                    <p class="mb-0"><b>Sous famille:  </b>{{ressource.sousFamille}}</p><hr class="my-2">
                    <p class="mb-0"><b>Libellé:  </b>{{ressource.libelle}}</p><hr class="my-2">
                    <p class="mb-0"><b>Prix de vente TTC:  </b><span class="badge badge-first">{{ressource.prixTTC | number:'': 'fr-FR'}} XOF</span></p><hr class="my-2">
                </div>
                <div class="col-md-6">
                    <p class="mb-0"><b>Fournisseur:  </b>Protic</p><hr class="my-2">
                    <p class="mb-0">
                        <span class="badge badge-{{ressource.etatVente == 'En vente'? 'success' : 'warning'}}">{{ressource.etatVente}}</span>
                        <span class="badge badge-{{ressource.etatAchat == 'En achat'? 'success' : 'warning'}} ml-2">{{ressource.etatAchat}}</span>
                    </p><hr class="my-2">
                    <p class="mb-0"><b>Créer par:  </b>{{ressource.createBy}}</p><hr class="my-2">
                    <p class="mb-0"><b>Date de création:  </b>{{ressource.created}}</p><hr class="my-2">
                    <p class="mb-0"><b>Modifier par:  </b>{{ressource.updateBy}}</p><hr class="my-2">
                    <p class="mb-0"><b>Date de modification:  </b>{{ressource.updated}}</p><hr class="my-2">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-xl-12">
        <div class="mb-5">
            <tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow" type="tabs" ><hr>
                <!-- Debut tableau de bord -->
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span><i class="fas fa-tachometer-alt"></i>Tableau de Bord</span></ng-template>

                    <!-- Filtre -->
                    <div class="bg-secondary col-sm-12 p-3 border">
                        <h4 class="mb-1">Filtres par période</h4>
                        <div class="divider mb-3"></div>
                        <div class="row">
                            <div class="col-md-3">
                                <label for="jours">Jours</label>
                                <select name="" id="" class="form-control">
                                    <option *ngFor="let jour of joursRow" value="{{jour.value}}">{{jour.label}}</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <label for="mois">Mois</label>
                                <select name="" id="" class="form-control">
                                    <option *ngFor="let mois of moisRow" value="{{mois.value}}">{{mois.label}}</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <label for="annee">Année</label>
                                <select name="" id="" class="form-control">
                                    <option *ngFor="let annee of anneeRow" value="{{annee.value}}">{{annee.label}}</option>
                                </select>
                            </div>
                            <div class="col-md-3 mt-3 text-right">
                                <button class="btn btn-sm btn-success mt-2"><i class="fas fa-sync-alt mr-2"></i>Rafraichir</button>
                            </div>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <!-- Fin filtre -->

                    <!-- Valeur financiere -->
                    <div class="row my-3">
                        <div class="col-md-12">
                            <h4>Valeur financières 
                                <span className="btn-wrapper--icon" container="body" triggers="mouseenter:mouseleave" placement="top" popover="Total de tous les factures auquels ce produit apparait en vente et en achat.">
                                    <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
                                </span>
                            </h4>
                        </div>
                        <div class="col-md-6">
                            <div class="card card-box mb-2">
                                <div class="card-header">
                                    <h4 class="font-size-lg mb-0 py-2 font-weight-bold">En vente</h4>
                                </div>
                                <div class="card-body pb-0">
                                    <div class="no-gutters row">
                                        <div class="col-md-6">
                                            <div class="divider-v divider-v-md"></div>
                                            <div class="d-flex align-items-center justify-content-between p-3">
                                                <div>
                                                    <b>Quantité total</b>
                                                    <div class="text-black-50">Quantité total commandée</div>
                                                </div>
                                                <div [countUp]="363" class="font-weight-bold text-danger font-size-xl"></div>
                                            </div>
                                            <div class="divider"></div>
                                            <div class="d-flex align-items-center justify-content-between p-3">
                                                <div>
                                                    <b>Quantité total</b>
                                                    <div class="text-black-50">Quantité total facture</div>
                                                </div>
                                                <div [countUp]="584" class="font-weight-bold text-success font-size-xl"></div>
                                            </div>
                                            <div class="divider"></div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="d-flex align-items-center justify-content-between p-3">
                                                <div>
                                                    <b>Montant total: </b>
                                                    <div class="text-black-50">Commandes</div>
                                                </div>
                                                <div class="font-weight-bold text-warning font-size-xl">100 000</div>
                                            </div>
                                            <div class="divider"></div>
                                            <div class="d-flex align-items-center justify-content-between p-3">
                                                <div>
                                                    <b>Montant total: </b>
                                                    <div class="text-black-50">Factures</div>
                                                </div>
                                                <div class="font-weight-bold text-danger font-size-xl">100 000</div>
                                            </div>
                                            <div class="divider"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sparkline-full-wrapper">
                                    <canvas
                                        [chartType]="'line'"
                                        [colors]="dangerSparklineColor"
                                        [datasets]="sparklineDataset2"
                                        [labels]="sparklineLabels1"
                                        [options]="sparklineOptions1"
                                        baseChart>
                                    </canvas>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="card card-box mb-2">
                                <div class="card-header">
                                    <h4 class="font-size-lg mb-0 py-2 font-weight-bold">En achat</h4>
                                </div>
                                <div class="card-body pb-0">
                                    <div class="no-gutters row">
                                        <div class="col-md-6">
                                            <div class="divider-v divider-v-md"></div>
                                            <div class="d-flex align-items-center justify-content-between p-3">
                                                <div>
                                                    <b>Quantité total</b>
                                                    <div class="text-black-50">Quantité total commandée</div>
                                                </div>
                                                <div [countUp]="363" class="font-weight-bold text-danger font-size-xl"></div>
                                            </div>
                                            <div class="divider"></div>
                                            <div class="d-flex align-items-center justify-content-between p-3">
                                                <div>
                                                    <b>Quantité total</b>
                                                    <div class="text-black-50">Quantité total facture</div>
                                                </div>
                                                <div [countUp]="584" class="font-weight-bold text-success font-size-xl"></div>
                                            </div>
                                            <div class="divider"></div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="d-flex align-items-center justify-content-between p-3">
                                                <div>
                                                    <b>Montant total: </b>
                                                    <div class="text-black-50">Commandes</div>
                                                </div>
                                                <div class="font-weight-bold text-warning font-size-xl">100 000</div>
                                            </div>
                                            <div class="divider"></div>
                                            <div class="d-flex align-items-center justify-content-between p-3">
                                                <div>
                                                    <b>Montant total: </b>
                                                    <div class="text-black-50">Factures</div>
                                                </div>
                                                <div class="font-weight-bold text-danger font-size-xl">100 000</div>
                                            </div>
                                            <div class="divider"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sparkline-full-wrapper">
                                    <canvas [chartType]="'line'"
                                            [colors]="successSparklineColor"
                                            [datasets]="sparklineDataset1"
                                            [labels]="sparklineLabels1"
                                            [options]="sparklineOptions1"
                                            baseChart></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Fin valeur financiere -->

                    <!-- graphique commande clients et fournisseurs -->
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <div class="card card-box p-4 text-center">
                                <h6 class="text-uppercase font-weight-bold mb-1 text-black">Quantités présentes dans les offres clients</h6>
                                <div class="py-3">
                                    <apx-chart
                                        [chart]="{ type: 'bar', height: '325', sparkline: {enabled: false}, stacked: false, toolbar: {show: false}}"
                                        [colors]="['#f83245', '#1bc943']"
                                        [dataLabels]="{enabled: false}"
                                        [fill]="{opacity: 1}"
                                        [labels]="['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']"
                                        [legend]="{ show: false }"
                                        [plotOptions]="{bar: {horizontal: false, endingShape: 'rounded',columnWidth: '50%'}}"
                                        [series]="[ {name: 'Année passée', data: [2.3, 3.1, 4.0, 3.8, 5.1, 3.6,4.0, 3.8, 5.1, 3.6, 3.2, 3.3]}, {name: 'Année en cours', data: [2.1, 2.1, 3.0, 2.8, 4.0, 3.8, 5.1, 3.6, 4.1, 2.6, 1.2, 3.9]}]"
                                        [stroke]="{show: true, width: 0, colors: ['transparent']}"
                                    ></apx-chart>
                                </div>
                    
                                <div class="row no-gutters">
                                    <div class="col-md-6 d-flex justify-content-center">
                                        <div class="divider-v divider-v-lg"></div>
                                        <div>
                                            <div class="d-flex align-items-center justify-content-center text-black-50 pb-3">
                                                <span class="badge badge-circle badge-success mr-2">available</span>
                                                <span>Année en cours</span>
                                            </div>
                                            <ngx-gauge append="%" backgroundColor="#e8e9ef" cap="round" class="img-fluid"
                                                       foregroundColor="#1bc943" max="200" min="0"
                                                       size="160" thick="10"
                                                       type="full" value="34.8">
                                            </ngx-gauge>
                                        </div>
                                    </div>
                                    <div class="col-md-6 d-flex justify-content-center">
                                        <div>
                                            <div class="d-flex align-items-center justify-content-center text-black-50 pb-3">
                                                <span class="badge badge-circle badge-danger mr-2">total</span>
                                                <span>Année passée</span>
                                            </div>
                                            <ngx-gauge append="%" backgroundColor="#e8e9ef" cap="round" class="img-fluid"
                                                       foregroundColor="#f83245" max="200" min="0"
                                                       size="160" thick="10"
                                                       type="full" value="65.3">
                                            </ngx-gauge>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card card-box p-4 text-center">
                                <h6 class="text-uppercase font-weight-bold mb-1 text-black">Quantités présentes dans les commandes fournisseurs</h6>
                                <div class="py-3">
                                    <apx-chart
                                        [chart]="{ type: 'bar', height: '325', sparkline: {enabled: false}, stacked: false, toolbar: {show: false}}"
                                        [colors]="['#f4772e', '#11c5db']"
                                        [dataLabels]="{enabled: false}"
                                        [fill]="{opacity: 1}"
                                        [labels]="['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']"
                                        [legend]="{ show: false }"
                                        [plotOptions]="{bar: {horizontal: false, endingShape: 'rounded',columnWidth: '50%'}}"
                                        [series]="[ {name: 'Année passée', data: [2.3, 3.1, 4.0, 3.8, 5.1, 3.6,4.0, 3.8, 5.1, 3.6, 3.2, 3.3]}, {name: 'Année en cours', data: [2.1, 2.1, 3.0, 2.8, 4.0, 3.8, 5.1, 3.6, 4.1, 2.6, 1.2, 3.9]}]"
                                        [stroke]="{show: true, width: 0, colors: ['transparent']}"
                                    ></apx-chart>
                                </div>
                    
                                <div class="row no-gutters">
                                    <div class="col-md-6 d-flex justify-content-center">
                                        <div class="divider-v divider-v-lg"></div>
                                        <div>
                                            <div class="d-flex align-items-center justify-content-center text-black-50 pb-3">
                                                <span class="badge badge-circle badge-info mr-2">available</span>
                                                <span>Année en cours</span>
                                            </div>
                                            <ngx-gauge append="%" backgroundColor="#e8e9ef" cap="round" class="img-fluid"
                                                foregroundColor="#11c5db" max="200" min="0"
                                                size="160" thick="10"
                                                type="full" value="34.8">
                                            </ngx-gauge>
                                        </div>
                                    </div>
                                    <div class="col-md-6 d-flex justify-content-center">
                                        <div>
                                            <div class="d-flex align-items-center justify-content-center text-black-50 pb-3">
                                                <span class="badge badge-circle badge-warning mr-2">total</span>
                                                <span>Année passée</span>
                                            </div>
                                            <ngx-gauge append="%" backgroundColor="#e8e9ef" cap="round" class="img-fluid"
                                                foregroundColor="#f4772e" max="200" min="0"
                                                size="160" thick="10"
                                                type="full" value="65.3">
                                            </ngx-gauge>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- fin graphique commande clients et fournisseurs -->

                    <!-- graphique facture clients et fournisseurs -->
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <div class="card card-box p-4 text-center">
                                <h6 class="text-uppercase font-weight-bold mb-1 text-black">Quantités présentes dans les factures clients</h6>
                                <apx-chart
                                    [chart]="{type: 'line', height: '375'}"
                                    [colors]="['#0abcce', '#060918']"
                                    [grid]="{strokeDashArray: '5', borderColor: 'rgba(125, 138, 156, 0.3)'}"
                                    [labels]="['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']"
                                    [series]="[ { name: 'Année en cours', type: 'column', data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]}, {name: 'Année passée',type: 'line',data: [231, 442, 335, 227, 433, 222, 117, 316, 242, 252, 162, 176]} ]"
                                    [stroke]="{ curve: 'smooth', width: [0, 4]}"
                                    [xaxis]="{type: 'date'}"
                                ></apx-chart>
                    
                                <div class="row no-gutters">
                                    <div class="col-md-6 d-flex justify-content-center">
                                        <div class="divider-v divider-v-lg"></div>
                                        <div>
                                            <ngx-gauge size="180" type="arch" thick="10" class="img-fluid"
                                                min="0" max="120" value="34.29"
                                                cap="round" label="Année en cours"
                                                foregroundColor="#0abcce" backgroundColor="#e8e8f0">
                                            </ngx-gauge>
                                        </div>
                                    </div>
                                    <div class="col-md-6 d-flex justify-content-center">
                                        <div>
                                            <ngx-gauge size="180" type="arch" thick="10" class="img-fluid"
                                                min="0" max="120" value="76.23"
                                                cap="round" label="Année passée"
                                                foregroundColor="#060918" backgroundColor="#e8e8f0">
                                            </ngx-gauge>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card card-box p-4 text-center">
                                <h6 class="text-uppercase font-weight-bold mb-1 text-black">Quantités présentes dans les factures fournisseurs</h6>
                                <apx-chart
                                    [chart]="{type: 'line', height: '375'}"
                                    [colors]="['#3b3e66', '#ff0303']"
                                    [grid]="{strokeDashArray: '5', borderColor: 'rgba(125, 138, 156, 0.3)'}"
                                    [labels]="['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']"
                                    [series]="[ { name: 'Année en cours', type: 'column', data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]}, {name: 'Année passée',type: 'line',data: [231, 442, 335, 227, 433, 222, 117, 316, 242, 252, 162, 176]} ]"
                                    [stroke]="{ curve: 'smooth', width: [0, 4]}"
                                    [xaxis]="{type: 'date'}"
                                ></apx-chart>
                    
                                <div class="row no-gutters">
                                    <div class="col-md-6 d-flex justify-content-center">
                                        <div class="divider-v divider-v-lg"></div>
                                        <div>
                                            <ngx-gauge size="180" type="arch" thick="10" class="img-fluid"
                                                min="0" max="120" value="34.29"
                                                cap="round" label="Année en cours"
                                                foregroundColor="#3b3e66" backgroundColor="#e8e8f0">
                                            </ngx-gauge>
                                        </div>
                                    </div>
                                    <div class="col-md-6 d-flex justify-content-center">
                                        <div>
                                            <ngx-gauge size="180" type="arch" thick="10" class="img-fluid"
                                                min="0" max="120" value="76.23"
                                                cap="round" label="Année passée"
                                                foregroundColor="#ff0303" backgroundColor="#e8e8f0">
                                            </ngx-gauge>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- fin graphique facture clients et fournisseurs -->
                </tab>
                <!-- Fin tableau de bord -->

                <!-- Debut fiche de produit -->
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span><i class="fas fa-file"></i> Fiche Produit</span></ng-template>
                    <accordion [closeOthers]="true" [isAnimated]="true" class="accordion mb-4">
                        <accordion-group class="card card-box">
                            <button accordion-heading class="btn btn-link btn-lg d-flex align-items-center justify-content-between"
                                    type="button">
                                <span>Détail produit  (Clique pour dérouler)</span>
                                <fa-icon [icon]="['fas', 'angle-up']" class="font-size-xl"></fa-icon>
                            </button>
                            <div class="m-3 border p-1">
                                <div class="row">
                                    <!-- LA ZONE DE GAUCHE -->
                                    <div class="col-md-6">
                                        <p class="mb-0"><b>Type:  </b>{{ressource.type}}</p><hr class="my-2">
                                        <p class="mb-0"><b>Unités:  </b>{{ressource.unite}}</p><hr class="my-2">
                                        <p class="mb-0"><b>Nature:  </b>{{ressource.nature}}</p><hr class="my-2">
                                        <p class="mb-0"><b>Limite stock alert:  </b><span class="badge badge-warning">{{ressource.alertStock}}</span></p><hr class="my-2">
                                        <p class="mb-0"><b>Etat vente:  </b>
                                            <span class="badge badge-{{ressource.etatVente == 'En vente' ? 'success': 'warning'}}">{{ressource.etatVente}}</span>
                                        </p><hr class="my-2">
                                        <p class="mb-0"><b>Etat achat:  </b>
                                            <span class="badge badge-{{ressource.etatAchat == 'En achat' ? 'success': 'warning'}}">{{ressource.etatAchat}}</span>
                                        </p><hr class="my-2">
                                    </div>

                                    <!-- LA ZONE DE DROITE -->
                                    <div class="col-md-6">
                                        <p class="mb-0"><b>Coéfficient de majoration:  </b>{{ressource.CoM}}%</p><hr class="my-2">
                                        <p class="mb-0"><b>Coéfficient de perte:  </b>{{ressource.CoP}}%</p><hr class="my-2">
                                        <p class="mb-0"><b>Prix de vente HT:  </b>{{ressource.prixHT | number:'': 'fr-FR'}} XOF</p><hr class="my-2">
                                        <p class="mb-0"><b>TVA:  </b>{{ressource.TVA}}%</p><hr class="my-2">
                                        <p class="mb-0"><b>Nombre de commandes:  </b>{{ressource.nbCmd}}</p><hr class="my-2">
                                    </div>
                                    <label for="" class="col-md-12 font-weight-bolder mt-2">Description: </label>
                                    <div class="col-md-12">
                                        <p>{{ressource.description}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="divider mt-2"></div>
                            <div class="row m-3">
                                <div class="col-md-12 my-2">
                                    <div class="text-right">
                                        <button  class="btn btn-secondary ml-2" type="button"><i class="fas fa-pen mr-2"></i> Modifier</button>
                                        <button  class="btn btn-secondary ml-2" (click)="openCloneModal.show()" type="button"><i class="fas fa-copy mr-2"></i> Cloner</button>
                                        <button  class="btn btn-outline-danger ml-2" type="button"><i class="fas fa-trash"></i> Supprimer</button>
                                    </div>
                                </div>
                            </div>
                        </accordion-group>
                    </accordion>
                    <!-- Derniers fichiers joints -->
                    <div class="card card-box mb-4 p-3">
                        <div class="border p-1">
                            <div class="row">
                                <div class="col-md-12"><div class="pt-2 pl-2 font-weight-bold">Derniers Fichiers joints</div></div>
                            </div>
                        </div>
                        <div class="border">
                            <div class="table-responsive">
                                <thead>
                                    <tr>
                                        <th>Fichiers</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Aucun</td>
                                    </tr>
                                </tbody>
                            </div>
                        </div>
                    </div>
                    <!-- fin derniers fichiers joints -->
                    
                    <!-- Les derniers évéments liés -->
                    <div class="card card-box mb-3 p-3">
                        <div class="border p-1">
                            <div class="row">
                                <div class="col-md-5"><div class="pt-2 pl-2 font-weight-bold">Les derniers évéments liés</div></div>
                                <div class="col-md-7 text-right">
                                    <button class="btn btn-sm btn-secondary"><i class="fas fa-book-open"></i> Tout voir</button>
                                    <button class="btn btn-sm btn-success ml-2" (click)="openEvenementModal.show()"><i class="fas fa-plus-circle"></i> Créer un événemet</button>
                                </div>
                            </div>
                        </div>
                        <div class="border">
                            <div class="table-responsive">
                            <table class="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th class="bg-white text-left">Référence</th>
                                        <th class="bg-white text-left">Par</th>
                                        <th class="bg-white text-left">Type</th>
                                        <th class="bg-white text-left">Titre</th>
                                        <th class="bg-white text-left">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Aucun</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                    <!-- Fin des derniers évéments liés -->
                    
                    <!-- Les sous ressources -->
                    <div class="card card-box mb-3 p-3" *ngIf="ressource.type == 'Ressource composite'">
                        <div class="border p-1">
                            <div class="pt-2 pl-2 font-weight-bold">Liste des sous ressources</div>
                        </div>
                        <div class="border">
                            <div class="table-responsive">
                            <table class="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th>Référence</th>
                                        <th>Libellé</th>
                                        <th>Prix de vente HT</th>
                                        <th>Unités</th>
                                        <th>stock d'alert</th>
                                        <th>stock physique</th>
                                        <th>Etat vente</th>
                                        <th>Etat achat</th>
                                        <th class="bg-white text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ressource.sousRessource.reference}}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <div class="col-md-5 text-right">
                                                <button class="btn btn-sm btn-primary" tooltip="Détails"><i class="fas fa-eye"></i></button>
                                                <button class="btn btn-sm btn-info ml-2" tooltip="Modifier"><i class="fas fa-pen"></i></button>
                                                <button class="btn btn-sm btn-danger ml-2" tooltip="Supprimer"><i class="fas fa-trash"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                    <!-- Fin des sous ressources -->
                </tab>
                <!-- Fin fiche de produit -->

                <!-- Debut prix de vente -->
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span><i class="fas fa-coins"></i> Prix de Vente</span></ng-template>
                    <div class="card card-box mb-4 mt-3 p-4">
                        <div class="border p-1">
                            <div class="row mt-1">
                                <div class="col-md-4 font-weight-bold">Coéfficient de majoration:</div>
                                <div class="col-md-8">12%</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row">
                                <div class="col-md-4 font-weight-bold">Prix de vente:</div>
                                <div class="col-md-8">120 000 XOF</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row mb-1">
                                <div class="col-md-4 font-weight-bold">Prix de vente TTC:</div>
                                <div class="col-md-8">120 000 XOF</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row mb-1">
                                <div class="col-md-4 font-weight-bold">Créer par: </div>
                                <div class="col-md-8">Ghislain</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row mb-1">
                                <div class="col-md-4 font-weight-bold">Date de création</div>
                                <div class="col-md-8">01/05/2021 13:08:00</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row mb-1">
                                <div class="col-md-4 font-weight-bold">Modifier par: </div>
                                <div class="col-md-8">Ghislain</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row mb-1">
                                <div class="col-md-4 font-weight-bold">Date de modification</div>
                                <div class="col-md-8">01/05/2021 13:08:00</div>
                            </div>
                        </div>
                        <div class="divider mt-2 mb-4"></div>
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <button class="btn btn-secondary"><i class="fas fa-pen mr-2"></i>Modifier prix par defaut</button>
                                <button class="btn btn-secondary ml-2" (click)="openPrixVenteModal.show()"><i class="fas fa-plus-circle mr-2"></i>Ajouter prix par defaut</button>
                            </div>
                        </div>
                        <div class="divider mt-4 mb-2"></div>
                        <div class="row">
                            <div class="col-md-12 mb-2">
                                <h5 class="titre font-weight-bold mb-0">Prix différent pour chaque client (2)</h5>
                            </div>
                        </div>
                        <div class="border">
                            <div class="table-responsive">
                              <table class="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th class="bg-white text-left">Tiers</th>
                                        <th class="bg-white text-left">Pratiqué à partie du</th>
                                        <th class="bg-white text-left">Prix de base</th>
                                        <th class="bg-white text-left">TVA</th>
                                        <th class="bg-white text-left">Prix HT</th>
                                        <th class="bg-white text-left">Prix TTC</th>
                                        <th class="bg-white text-left">Prix de vente minimum HT</th>
                                        <th class="bg-white text-left">Prix de vente minimum TTC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><a href="">Defaut 1</a></td>
                                        <td>17/04/2021</td>
                                        <td>HT</td>
                                        <td>18%</td>
                                        <td>1 000</td>
                                        <td>1 180</td>
                                        <td>0000</td>
                                        <td>000</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th> Total:</th>
                                        <th> </th>
                                        <th> </th>
                                        <th> </th>
                                        <th> 2 000</th>
                                        <th> 2 360</th>
                                        <th> 0000</th>
                                        <th> 0000</th>
                                    </tr>
                                </tfoot>
                              </table>
                            </div>
                        </div>
                    </div>
                </tab>
                <!-- Fin prix de vente -->

                <!-- Debut prix d'achat -->
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span><i class="fas fa-money-bill-alt"></i> Prix d'Achat</span></ng-template>
                    <div class="card card-box mb-4 mt-3 p-4">
                        <div class="border p-1">
                            <div class="row mt-1">
                                <div class="col-md-4 font-weight-bold">
                                    Prix de revient: 
                                    <span className="btn-wrapper--icon" container="body" triggers="mouseenter:mouseleave" placement="top" popover="C'est la somme des coûts supportés pour la production et la distribution d'un bien ou d'un service.">
                                        <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
                                    </span>
                                </div>
                                <div class="col-md-8">123 000 XOF</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row">
                                <div class="col-md-4 font-weight-bold">
                                    Prix moyen pondéré (PMP):
                                    <span className="btn-wrapper--icon" container="body" triggers="mouseenter:mouseleave" placement="top" popover="C'est une valorisation des sorties d'un poste comptable à un coût unitaire qui est la moyenne des coûts unitaires des entrées de la période (en y incluant le coût unitaire du stock initial).">
                                        <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
                                    </span>
                                </div>
                                <div class="col-md-8">123 000 XOF</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row mb-1">
                                <div class="col-md-4 font-weight-bold">
                                    Meilleur prix d'achat:
                                    <span className="btn-wrapper--icon" container="body" triggers="mouseenter:mouseleave" placement="top" popover="Le plus petit prix d'achat">
                                        <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
                                    </span>
                                </div>
                                <div class="col-md-8">123 000 XOF</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row mb-1">
                                <div class="col-md-4 font-weight-bold">Créer par: </div>
                                <div class="col-md-8">Ghislain</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row mb-1">
                                <div class="col-md-4 font-weight-bold">Date de création: </div>
                                <div class="col-md-8">01/05/2021 13:18:00</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row mb-1">
                                <div class="col-md-4 font-weight-bold">Modifier par: </div>
                                <div class="col-md-8">Ghislain</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row mb-1">
                                <div class="col-md-4 font-weight-bold">Date de modification: </div>
                                <div class="col-md-8">01/05/2021 13:18:00</div>
                            </div>
                        </div>
                        <div class="divider mt-2 mb-4"></div>
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <button class="btn btn-secondary ml-2" (click)="openPrixAchatModal.show()"><i class="fas fa-plus-circle mr-2"></i>Ajouter prix d'achat</button>
                            </div>
                        </div>
                        <div class="divider mt-4 mb-2"></div>
                        <div class="row">
                            <div class="col-md-12 mb-2">
                                <h5 class="font-weight-bold mb-0 titre">Prix founisseurs (1)</h5>
                            </div>
                        </div>
                        <div class="border">
                            <div class="table-responsive">
                              <table class="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th>Ref prod fournisseur</th>
                                        <th>Fournisseur</th>
                                        <th>Pratiqué à partie du</th>
                                        <th>TVA(%)</th>
                                        <th>Prix qte min</th>
                                        <th>Prix unitaire HT</th>
                                        <th>Remise par defaut</th>
                                        <th>Delai livraison</th>
                                        <th>Reputation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><a href="">12ABPROD</a></td>
                                        <td>Protic</td>
                                        <td>17/04/2021</td>
                                        <td>18%</td>
                                        <td>1 000</td>
                                        <td>6 000</td>
                                        <td>5%</td>
                                        <td>2 jour</td>
                                        <td>
                                            <bar-rating [(rate)]="rating1" [theme]="'rating-icons-default'" class="rating-primary" [max]="5"></bar-rating>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Total:</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>1 000</th>
                                        <th>6 000</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tfoot>
                              </table>
                            </div>
                        </div>
                    </div>
                </tab>
                <!-- Fin prix d'achat -->

                <!-- Debut stock -->
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span><i class="fas fa-warehouse mr-1"></i>Stocks</span></ng-template>
                    <div class="card card-box mb-4 mt-3 p-4">
                        <div class="border p-1">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row mt-1">
                                        <div class="col-md-6 font-weight-bold">
                                            Prix de revient:
                                            <span className="btn-wrapper--icon" container="body" triggers="mouseenter:mouseleave" placement="top" popover="Ce prix ( net de taxe) peut être utilisé pour stocker le montant moyen du coût de ce produit pour votre entreprise. Il peut etre calculer par exemple à partie du prix moyen majoré des coûts moyens de production et de distributions. Cette valeur peut etre utilisée dans le calcul des marges.">
                                                <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
                                            </span>
                                        </div>
                                        <div class="col-md-6">123 000 XOF</div>
                                    </div>
                                    <div class="divider mt-2 mb-2"></div>
                                    <div class="row">
                                        <div class="col-md-6 font-weight-bold">
                                            Prix moyen pondéré (PMP):
                                            <span className="btn-wrapper--icon" container="body" triggers="mouseenter:mouseleave" placement="top" popover="Le prix unitaire moyen d'entrée que nous avons dû payer aux fournisseurspour intégrer le produit dans notre stock.">
                                                <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
                                            </span>
                                        </div>
                                        <div class="col-md-6">123 000 XOF</div>
                                    </div>
                                    <div class="divider mt-2 mb-2"></div>
                                    <div class="row">
                                        <div class="col-md-6 font-weight-bold">
                                            Meilleur prix d'achat:
                                            <span className="btn-wrapper--icon" container="body" triggers="mouseenter:mouseleave" placement="top" popover="Le plus petit prix d'achat.">
                                                <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
                                            </span>
                                        </div>
                                        <div class="col-md-6">123 000 XOF</div>
                                    </div>
                                    <div class="divider mt-2 mb-2"></div>
                                    <div class="row">
                                        <div class="col-md-6 font-weight-bold">Prix de vente: </div>
                                        <div class="col-md-6">0</div>
                                    </div>
                                    <div class="divider mt-2 mb-2"></div>
                                    <div class="row">
                                        <div class="col-md-6 font-weight-bold">Meilleur prix d'achat: </div>
                                        <div class="col-md-6">0</div>
                                    </div>
                                    <div class="divider mt-2 mb-2"></div>
                                    <div class="row">
                                        <div class="col-md-6 font-weight-bold">
                                            Limite stock pour alert:
                                        </div>
                                        <div class="col-md-6">10</div>
                                    </div>
                                    <div class="divider mt-2 mb-2"></div>
                                    <div class="row">
                                        <div class="col-md-6 font-weight-bold">
                                            Stock déseré optimal:
                                            <span className="btn-wrapper--icon" container="body" triggers="mouseenter:mouseleave" placement="top" popover="Le stock optional sera la valeur utilisée pour remplir le stock avec la fonction de réapprovisionnement.">
                                                <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
                                            </span>
                                        </div>
                                        <div class="col-md-6">12</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mt-1">
                                        <div class="col-md-6 font-weight-bold">Prix physique: </div>
                                        <div class="col-md-6">123 000 XOF</div>
                                    </div>
                                    <div class="divider mt-2 mb-2"></div>
                                    <div class="row">
                                        <div class="col-md-6 font-weight-bold">Prix virtuel: </div>
                                        <div class="col-md-6">1</div>
                                    </div>
                                    <div class="divider mt-2 mb-2"></div>
                                    <div class="row mb-1">
                                        <div class="col-md-6 font-weight-bold">Dernier mouvement: </div>
                                        <div class="col-md-6">Aucun</div>
                                    </div>

                                    <div class="divider mt-2 mb-2"></div>
                                    <div class="row">
                                        <div class="col-md-6 font-weight-bold">Créer par: </div>
                                        <div class="col-md-6">Ghislain</div>
                                    </div>
                                    <div class="divider mt-2 mb-2"></div>
                                    <div class="row">
                                        <div class="col-md-6 font-weight-bold">Date de création: </div>
                                        <div class="col-md-6">01/05/2021 13:37:00</div>
                                    </div>
                                    <div class="divider mt-2 mb-2"></div>
                                    <div class="row">
                                        <div class="col-md-6 font-weight-bold">Modifier par: </div>
                                        <div class="col-md-6">Ghislain</div>
                                    </div>
                                    <div class="divider mt-2 mb-2"></div>
                                    <div class="row">
                                        <div class="col-md-6 font-weight-bold">Date de modification: </div>
                                        <div class="col-md-6">01/05/2021 13:37:00</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="divider mt-2 mb-4"></div>
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <button class="btn btn-secondary ml-2" (click)="openCorrectionnModal.show()"><i class="fas fa-edit mr-2"></i>Corriger le stock</button>
                                <button class="btn btn-secondary ml-2" (click)="openTransfertModal.show()"><i class="fas fa-paper-plane mr-2"></i>Transferer stock</button>
                            </div>
                        </div>
                        <div class="divider mt-4 mb-2"></div>
                        <div class="row">
                            <div class="col-md-12 mb-2">
                                <h5 class="font-weight-bold mb-0 titre">Stocks entrepôts (0)</h5>
                            </div>
                        </div>
                        <div class="border">
                            <div class="table-responsive">
                                <table class="table table-hover text-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>entrepôt</th>
                                            <th>Nombre de pièces</th>
                                            <th>Prix moyen pondéré (PMP)</th>
                                            <th>Valorisation achat (PMP)</th>
                                            <th>Prix de vent unitaire</th>
                                            <th>Valeur à la vente</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><a href="">abcde</a></td>
                                            <td>145</td>
                                            <td>1 000</td>
                                            <td>000</td>
                                            <td>6 000</td>
                                            <td>0000</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Total:</th>
                                            <th></th>
                                            <th>1 000</th>
                                            <th>000</th>
                                            <th>6 000</th>
                                            <th>000</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card card-box mb-5">
                                <div class="card-header bg-white">
                                    <div class="card-header--title">Historique des stocks corrigés et transferés</div>
                                    <div class="card-header--actions"><span class="badge badge-info">2</span></div>
                                </div>
                                <perfect-scrollbar [autoPropagation]="true" class="scroll-area-sm shadow-overflow">
                                    <div class="border m-3">
                                        <div class="table-responsive">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Libellé</th>
                                                        <th>Description</th>
                                                        <th>Quantités</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Stock corrigé</td>
                                                        <td>Correction du 30/07/2021 10:00:00</td>
                                                        <td>67</td>
                                                        <td>
                                                            <button class="btn btn-sm btn-primary" tooltip="Détails" (click)="stockCorrigeDetail.show()"><i class="fas fa-eye"></i></button>
                                                            <button class="btn btn-sm btn-danger ml-2" tooltip="Supprimer"><i class="fas fa-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Stock Transferé</td>
                                                        <td>Transfert du 30/07/2021 10:00:00</td>
                                                        <td>67</td>
                                                        <td>
                                                            <button class="btn btn-sm btn-primary" tooltip="Détails" (click)="stockTransfereDetail.show()"><i class="fas fa-eye"></i></button>
                                                            <button class="btn btn-sm btn-danger ml-2" tooltip="Supprimer"><i class="fas fa-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </perfect-scrollbar>
                                <div class="card-footer d-flex p-4 justify-content-between">
                                    <button class="btn btn-sm btn-first" type="button">Plus d'info</button>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                            <div class="card card-box mb-5">
                                <div class="card-header bg-white">
                                    <div class="card-header--title">Historique des stocks transferé</div>
                                    <div class="card-header--actions"><span class="badge badge-info">3</span></div>
                                </div>
                                <perfect-scrollbar [autoPropagation]="true" class="scroll-area-sm shadow-overflow">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item py-4">
                                            <div class="border p-2">
                                                <div class="row">
                                                    <div class="col-md-7 text-center"><h5 class="mb-0 font-weight-bold">Transfère du 08/07/2021 10:00:00</h5></div>
                                                    <div class="col-md-5 text-right">
                                                        <button class="btn btn-sm btn-primary" tooltip="Détails" (click)="stockTransfereDetail.show()"><i class="fas fa-eye"></i></button>
                                                        <button class="btn btn-sm btn-danger ml-2" tooltip="Supprimer"><i class="fas fa-trash"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="list-group-item py-4">
                                            <div class="border p-2">
                                                <div class="row">
                                                    <div class="col-md-7 text-center"><h5 class="mb-0 font-weight-bold">Transfère du 11/07/2021 10:00:00</h5></div>
                                                    <div class="col-md-5 text-right">
                                                        <button class="btn btn-sm btn-primary" tooltip="Détails" (click)="stockTransfereDetail.show()"><i class="fas fa-eye"></i></button>
                                                        <button class="btn btn-sm btn-danger ml-2" tooltip="Supprimer"><i class="fas fa-trash"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="list-group-item py-4">
                                            <div class="border p-2">
                                                <div class="row">
                                                    <div class="col-md-7 text-center"><h5 class="mb-0 font-weight-bold">Transfère du 15/07/2021 10:00:00</h5></div>
                                                    <div class="col-md-5 text-right">
                                                        <button class="btn btn-sm btn-primary" tooltip="Détails" (click)="stockTransfereDetail.show()"><i class="fas fa-eye"></i></button>
                                                        <button class="btn btn-sm btn-danger ml-2" tooltip="Supprimer"><i class="fas fa-trash"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </perfect-scrollbar>
                                <div class="card-footer d-flex p-4 justify-content-between">
                                    <button class="btn btn-sm btn-first" type="button">Plus d'info</button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </tab>
                <!-- Fin stock -->

                <!-- Debut marge -->
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span><i class="fas fa-sliders-h"></i> Marges</span></ng-template>
                    <div class="card card-box mb-4 mt-3 p-4">
                        <div class="border p-1">
                            <div class="row mt-1">
                                <div class="col-md-4 font-weight-bold">Marge totale:
                                    <span className="btn-wrapper--icon" container="body" triggers="mouseenter:mouseleave" placement="top" popover="La somme de toute les marges.">
                                        <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
                                    </span>
                                </div>
                                <div class="col-md-8">6</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row mb-1">
                                <div class="col-md-4 font-weight-bold">Taux de marge</div>
                                <div class="col-md-8">10%</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row mb-1">
                                <div class="col-md-4 font-weight-bold">Créer par: </div>
                                <div class="col-md-8">Ghislain</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row mb-1">
                                <div class="col-md-4 font-weight-bold">Date de création: </div>
                                <div class="col-md-8">01/05/2021 12:09:56</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row mb-1">
                                <div class="col-md-4 font-weight-bold">Modifier par: </div>
                                <div class="col-md-8">Ghislain</div>
                            </div>
                            <div class="divider mt-2 mb-2"></div>
                            <div class="row mb-1">
                                <div class="col-md-4 font-weight-bold">Date de madification: </div>
                                <div class="col-md-8">01/05/2021 12:09:56</div>
                            </div>
                        </div>
                        <div class="divider mt-2 mb-4"></div>
                        <div class="row">
                            <div class="col-md-12 mb-2">
                                <h5 class="font-weight-bold mb-0 titre">Détail des marges (4)</h5>
                            </div>
                        </div>
                        <div class="border">
                            <div class="table-responsive">
                                <table class="table table-hover text-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>Id Facture</th>
                                            <th>Société</th>
                                            <th>Code client</th>
                                            <th>Date de facturation</th>
                                            <th>Prix de vente</th>
                                            <th>Prix d'achat</th>
                                            <th>Quantité</th>
                                            <th>Marge</th>
                                            <th>Taux de marge</th>
                                            <th>Etat</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><a href="">FAC12345</a></td>
                                            <td>Protic</td>
                                            <td>CLI1234</td>
                                            <td>16/04/2021</td>
                                            <td>000</td>
                                            <td>000</td>
                                            <td>45</td>
                                            <td>0</td>
                                            <td>10%</td>
                                            <td>
                                                <span class="badge badge-success">Payer</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><a href="">FAC12345</a></td>
                                            <td>Protic</td>
                                            <td>CLI1234</td>
                                            <td>16/04/2021</td>
                                            <td>000</td>
                                            <td>000</td>
                                            <td>45</td>
                                            <td>0</td>
                                            <td>10%</td>
                                            <td>
                                                <span class="badge badge-danger">Impayer</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><a href="">FAC12345</a></td>
                                            <td>Protic</td>
                                            <td>CLI1234</td>
                                            <td>16/04/2021</td>
                                            <td>000</td>
                                            <td>000</td>
                                            <td>45</td>
                                            <td>0</td>
                                            <td>10%</td>
                                            <td>
                                                <span class="badge badge-warning">En attente de paiement</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><a href="">FAC12345</a></td>
                                            <td>Protic</td>
                                            <td>CLI1234</td>
                                            <td>16/04/2021</td>
                                            <td>000</td>
                                            <td>000</td>
                                            <td>45</td>
                                            <td>0</td>
                                            <td>10%</td>
                                            <td>
                                                <span class="badge badge-neutral-success">En cours de paiement</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Total:</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th>000</th>
                                            <th>000</th>
                                            <th>45</th>
                                            <th>0</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </tab>
                <!-- Fin marge -->
            </tabset>
        </div>
    </div>
</div>

<!-- DES MODALS -->
<!-- Formulaire d'ajout prix de vente -->
<div #openPrixVenteModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Formulaire d'ajout prix de vente</h6>
                <button type="button" class="close" aria-label="Close" (click)="openPrixVenteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="bg-white rounded-left">
                            <div class="p-4">
                                <form>
                                    <h5 class="mb-0">Dévise</h5>
                                    <div class="divider mt-2 mb-1"></div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="row">
                                                <div class="col-md-12 mb-2">
                                                    <label for="monnaie1">Monnaie n°1 FCFA/XOF</label>
                                                    <input type="number" class="form-control" id="monnaie1" name="monnaie1">
                                                </div>
                                                <div class="col-md-12 mb-2">
                                                    <label for="date1">Date de création</label>
                                                    <div class="input-group input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                                        </div>
                                                        <input [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }"
                                                            id="date1"
                                                            name="date1"
                                                            bsDatepicker class="form-control"
                                                            [textMask]="{mask: maskDate}"
                                                            placeholder="Date de création" type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="row">
                                                <div class="col-md-12 mb-2">
                                                    <label for="monnaie2">Monnaie n°2 euro(£)</label>
                                                    <input type="number" class="form-control" id="monnaie2" name="monnaie2">
                                                </div>
                                                <div class="col-md-12 mb-2">
                                                    <label for="date2">Date de création</label>
                                                    <div class="input-group input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                                        </div>
                                                        <input [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }"
                                                            id="date2"
                                                            name="date2"
                                                            bsDatepicker class="form-control"
                                                            [textMask]="{mask: maskDate}"
                                                            placeholder="Date de création" type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="row">
                                                <div class="col-md-12 mb-2">
                                                    <label for="monnaie3">Monnaie n°3 Dollar($)</label>
                                                    <input type="number" class="form-control" id="monnaie3" name="monnaie3">
                                                </div>
                                                <div class="col-md-12 mb-2">
                                                    <label for="date3">Date de création</label>
                                                    <div class="input-group input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                                        </div>
                                                        <input [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }"
                                                            id="date3"
                                                            name="date3"
                                                            bsDatepicker class="form-control"
                                                            [textMask]="{mask: maskDate}"
                                                            placeholder="Date de création" type="text"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="divider mt-2 mb-3"></div>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="position-relative form-group">
                                                <label for="client">Client</label>
                                                <div class="input-group">
                                                    <select  class="form-control" id="client">
                                                        <option value="Kouassi">Kouassi</option>
                                                        <option value="Kader">Kader</option>
                                                        <option value="Ghislain">Ghislain</option>
                                                    </select>
                                                    <button tooltip="Ajouter" placement="auto"  class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button">
                                                        <span class="btn-wrapper--icon">
                                                            <i class="ion-md-add"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="position-relative form-group">
                                                <label for="CMo">Coéfficient de majoration</label>
                                                <input type="text" class="form-control" id="CMo">
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="position-relative form-group">
                                                <label for="TVA">TVA (%)</label>
                                                <div class="input-group">
                                                    <select  class="form-control" id="TVA">
                                                        <option value="18">18%</option>
                                                        <option value="7.5">7.5%</option>
                                                        <option value="0">0/</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="position-relative form-group">
                                                <label for="prix_ht">Prix de vente HT</label>
                                                <input type="text" class="form-control" id="prix_ht">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="position-relative form-group">
                                                <label for="prix_ttc">Prix de vente TTC</label>
                                                <input type="text" class="form-control" id="prix_ttc">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button class="btn btn-secondary mr-2" type="button" (click)="openPrixVenteModal.hide()"><i class="fas fa-times mr-2"></i> Annuler</button>
                    <button  class="btn btn-success" type="button"><i class="fas fa-save mr-2"></i> Enregistrer</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin formulaire d'ajout prix de vente -->

<!-- Formulaire d'ajout prix d'achat -->
<div #openPrixAchatModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Formulaire d'ajout prix d'achat</h6>
                <button type="button" class="close" aria-label="Close" (click)="openPrixAchatModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="bg-white rounded-left">
                            <div class="p-4">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="position-relative form-group">
                                                <label for="fournisseur">Fournisseurs</label>
                                                <div class="input-group">
                                                    <select  class="form-control" id="fournisseur">
                                                        <option value="Kouassi">Kouassi</option>
                                                        <option value="Kader">Kader</option>
                                                        <option value="Ghislain">Ghislain</option>
                                                    </select>
                                                    <button tooltip="Ajouter" placement="auto" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button">
                                                        <span class="btn-wrapper--icon">
                                                            <i class="ion-md-add"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="position-relative form-group">
                                                <label for="refProd">Référence produit fournisseur</label>
                                                <input type="text" class="form-control" id="refProd">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="position-relative form-group">
                                                <label for="TVA">TVA (%)</label>
                                                <div class="input-group">
                                                    <select  class="form-control" id="TVA">
                                                        <option value="18">18%</option>
                                                        <option value="7.5">7.5%</option>
                                                        <option value="0">0%</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="position-relative form-group">
                                                <label for="perte">Coéficient de perte</label>
                                                <input type="text" class="form-control" id="perte">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="position-relative form-group">
                                                <label for="prix_ht">Prix de vente HT</label>
                                                <input type="text" class="form-control" id="prix_ht">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="position-relative form-group">
                                                <label for="fournisseur">Remise après négociation</label>
                                                <div class="input-group">
                                                    <select  class="form-control" id="fournisseur">
                                                        <option value="5">5%</option>
                                                        <option value="2">2%</option>
                                                    </select>
                                                    <button tooltip="Ajouter" placement="auto" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button">
                                                        <span class="btn-wrapper--icon">
                                                            <i class="ion-md-add"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="position-relative form-group">
                                                <label for="dateRemise">Date de remise </label>
                                                <div class="input-group input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                                    </div>
                                                    <input [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }"
                                                        bsDatepicker class="form-control" id="dateDebut" name="dateDebut"
                                                        [textMask]="{mask: maskDate}"
                                                        placeholder="Date de début" type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="position-relative form-group">
                                                <label for="prix_final">Prix final TTC</label>
                                                <span class="badge badge-dark ml-2" container="body" triggers="mouseenter:mouseleave" placement="top" popover="Prix unitaire + remise"> ?</span>
                                                <input type="text" class="form-control" id="prix_final">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button class="btn btn-secondary mr-2" type="button" (click)="openPrixAchatModal.hide()"><i class="fas fa-times mr-2"></i> Annuler</button>
                    <button  class="btn btn-success" type="button"><i class="fas fa-save mr-2"></i> Enregistrer</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin formulaire d'ajout prix d'achat -->

<!-- Clonage -->
<div #openCloneModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Cloner</h6>
                <button type="button" class="close" aria-label="Close" (click)="openCloneModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="bg-white rounded-left">
                            <div class="p-2">
                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        <h3 class="font-weight-bolder">Choisissez votre option de clonage:</h3>
                                    </div>
                                </div>
                                <div class="rounded border p-4">
                                    <div class="row mb-3 mt-3">
                                        <div class="col-md-8">Référence du nouveau produit/service: </div>
                                        <div class="col-md-4"><input type="text" class="form-control"></div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-8">Cloner les informations générales du produit/service: </div>
                                        <div class="col-md-4"><input class="checkbox" type="checkbox" value=""></div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-8">Cloner les catégorie liées: </div>
                                        <div class="col-md-4"><input class="checkbox" type="checkbox" value=""></div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-8">Êtes vous sûr de vouloir cloner le produit ou service: </div>
                                        <div class="col-md-4 font-weight-bold"> REFERENCE DU PRODUIT</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button  class="btn btn-success" type="button"><i class="fas fa-check-circle mr-2"></i> Oui</button>
                    <button class="btn btn-secondary ml-2" type="button" (click)="openCloneModal.hide()"><i class="fas fa-times mr-2"></i> Non</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Clonage -->

<!-- Formulaire de création d'événement -->
<div #openEvenementModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Formulaire de création d'événement</h6>
                <button type="button" class="close" aria-label="Close" (click)="openEvenementModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12">
                    <div class="bg-white rounded-left">
                        <div class="p-2">
                            <form>
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="libelle">Libellé </label>
                                            <input class="form-control" id="libelle" name="libelle" type="text" placeholder="Entrez le libelle de l'événement">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="dateDebut">Date début </label>
                                            <div class="input-group input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                                </div>
                                                <input [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }"
                                                    bsDatepicker class="form-control" id="dateDebut" name="dateDebut"
                                                    [textMask]="{mask: maskDate}"
                                                    placeholder="Date de début" type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="dateFin">Date  fin </label>
                                            <div class="input-group input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                                </div>
                                                <input [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }"
                                                    bsDatepicker class="form-control" id="dateFin" name="dateFin"
                                                    [textMask]="{mask: maskDate}"
                                                    placeholder="Date dfin" type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group"><label for="etat">Etat/pourcentage </label>
                                            <div class="input-group">
                                                <select  class="form-control" id="etat" name="etat">
                                                    <option value="Applicable">Applicable</option>
                                                    <option value="Nan applicable">Nan applicable</option>
                                                </select>
                                                <button tooltip="Ajouter" placement="auto"  class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newEtatModal.show()">
                                                    <span class="btn-wrapper--icon"><i class="ion-md-add"></i></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="lieu">Lieu </label>
                                            <input class="form-control" id="lieu" name="lieu" type="text" placeholder="Entrez le lieu"/>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="categorie">Catégorie </label>
                                            <input class="form-control" id="categorie" name="categorie" type="text" placeholder="Entrez la categorie"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="divider mt-3"></div>
                            
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group"><label for="ters">Tiers concerné </label>
                                            <div class="input-group">
                                                <select  class="form-control" id="ters" name="ters">
                                                    <option value="Applicable">Applicable</option>
                                                    <option value="Nan applicable">Nan applicable</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group"><label for="ters">Projet </label>
                                            <div class="input-group">
                                                <select  class="form-control" id="ters" name="ters">
                                                    <option value="Applicable">Applicable</option>
                                                    <option value="Nan applicable">Nan applicable</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group"><label for="tache">Tache </label>
                                            <div class="input-group">
                                                <select  class="form-control" id="tache">
                                                    <option> Membre de l'entréprise</option>
                                                    <option> Délégué de bureau d'étude</option>
                                                    <option> Délégué fournisseur</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <label for="contact">Contact concerné</label>
                                        <ng-select [multiple]="true">
                                            <ng-option [value]="'contact1'">Contact1</ng-option>
                                            <ng-option [value]="'contact2'">Contact2</ng-option>
                                            <ng-option [value]="'contact3'">Contact3</ng-option>
                                            <ng-option [value]="'contact4'">Contact4</ng-option>
                                            <ng-option [value]="'contact5'">Contact5</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <div class="position-relative form-group">
                                            <label for="objetLie">Objet lié </label>
                                            <div class="custom-file">
                                                <input class="form-control" id="objetLie" type="text">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="position-relative form-group">
                                            <label for="email">Email </label>
                                            <div class="custom-file">
                                                <input class="form-control" id="email" type="text">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <label for="description">Description</label>
                                        <angular-editor [config]="config"></angular-editor>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button class="btn btn-secondary mr-2" type="button" (click)="openEvenementModal.hide()"><i class="fas fa-times mr-2"></i> Annuler</button>
                    <button  class="btn btn-success" type="button"><i class="fas fa-save mr-2"></i> Enregistrer</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin formulaire de création d'événement -->

<!-- Etat Modal add -->
<div #newEtatModal="bs-modal"  [config]="{backdrop: 'static'}"   backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter un etat </h6>
                <button type="button" class="close" aria-label="Close" (click)="newEtatModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <div class="rounded ">
                        <div class="mx-3">
                            <form [formGroup]="etatForm" (ngSubmit)="addEtat()">
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="type">Etat <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control"
                                            id="etat" formControlName="etat" placeholder="Nom de l'etat" >
                                            <div *ngIf="submit && etatF.etat.errors" class="invalid-feedback">
                                                <div *ngIf="etatF.etat.errors.required">{{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            
                            </form>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="text-center px-3 py-2">
                    <button class="btn btn-first m-2" type="button"><i class="fas fa-broom mr-1"></i>Nettoyer</button>
                    <button class="btn btn-success" type="submit" (click)="addEtat()"><i class="fas fa-save mr-1"></i>Enregister</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin formulaire de création d'événement -->

<!-- Correctionn stock -->
<div #openCorrectionnModal="bs-modal" [config]="{backdrop: 'static'}"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Correctionn de stock</h6>
                <button type="button" class="close" aria-label="Close" (click)="openCorrectionnModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="bg-white rounded-left">
                            <div class="p-2">
                                <div class="rounded border p-2">
                                    <form  btn="">
                                        <div class="form-row">
                                            <div class="col-md-6">
                                                <div class="position-relative form-group">
                                                    <label for="entrepot">Entrepôt</label>
                                                    <div class="input-group">
                                                        <select name="entrepot" id="entrepot" class="form-control">
                                                            <option value=""></option>
                                                        </select>
                                                        <div class="btn-group" dropdown>
                                                            <button dropdownToggle type="button" placement="auto" class="btn btn-primary dropdown-toggle">
                                                              Action
                                                              <span class="caret"></span>
                                                            </button>
                                                            <ul *dropdownMenu class="dropdown-menu" role="menu">
                                                              <li role="menuitem"><a class="dropdown-item btn">Ajouter</a></li>
                                                              <li class="divider dropdown-divider"></li>
                                                              <li role="menuitem"><a class="dropdown-item btn">Modifier</a></li>
                                                            </ul>
                                                          </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="position-relative form-group">
                                                    <label for="nbPiece">Nombre de pièce </label>
                                                    <input class="form-control" type="text" id="nbPiece" name="nbPiece">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-6">
                                                <div class="position-relative form-group">
                                                    <label for="prixUnitaire">Prix d'achat unitaire </label>
                                                    <input class="form-control" type="text" id="prixUnitaire" name="prixUnitaire">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="position-relative form-group">
                                                    <label for="projet">Projet </label>
                                                    <div class="input-group">
                                                        <select name="projet" id="projet" class="form-control">
                                                            <option value=""></option>
                                                        </select>
                                                        <button tooltip="Créer projet" placement="auto"  class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button">
                                                            <span class="btn-wrapper--icon">
                                                                <i class="ion-md-add"></i>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-6">
                                                <div class="position-relative form-group">
                                                    <label for="nbPiece">Libellé du mouvement </label>
                                                    <input class="form-control" type="text" id="nbPiece" name="nbPiece">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="position-relative form-group">
                                                    <label for="prixUnitaire">Code mouvement ou inventaire </label>
                                                    <input class="form-control" type="text" id="prixUnitaire" name="prixUnitaire">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button  class="btn btn-secondary" type="button" (click)="openCorrectionnModal.hide()"><i class="fas fa-times mr-2"></i> Annuler</button>
                    <button class="btn btn-success ml-2" type="button"><i class="fas fa-save mr-2"></i> Enregistrer</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin Correctionn de stock -->

<!-- Transfert de stock -->
<div #openTransfertModal="bs-modal"  [config]="{backdrop: 'static'}"   backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Transfert de stock</h6>
                <button type="button" class="close" aria-label="Close" (click)="openTransfertModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="bg-white rounded-left">
                            <div class="p-2">
                                <div class="rounded border p-2">
                                    <form action="">
                                        <div class="form-row">
                                            <div class="col-md-6">
                                                <div class="position-relative form-group">
                                                    <label for="entrepot">Entrepôt source </label>
                                                    <div class="input-group">
                                                        <select name="entrepot" id="entrepot" class="form-control">
                                                            <option value=""></option>
                                                        </select>
                                                        <button tooltip="Créer entrepôt" placement="auto"  class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button">
                                                            <span class="btn-wrapper--icon">
                                                                <i class="ion-md-add"></i>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="position-relative form-group">
                                                    <label for="entrepot">Entrepôt destination </label>
                                                    <div class="input-group">
                                                        <select name="entrepot" id="entrepot" class="form-control">
                                                            <option value=""></option>
                                                        </select>
                                                        <button tooltip="Créer entrepôt" placement="auto"  class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button">
                                                            <span class="btn-wrapper--icon">
                                                                <i class="ion-md-add"></i>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-6">
                                                <div class="position-relative form-group">
                                                    <label for="nbPiece">Nombre de pièce </label>
                                                    <input class="form-control" type="text" id="nbPiece" name="nbPiece">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="position-relative form-group">
                                                    <label for="nbPiece">Libellé du mouvement </label>
                                                    <input class="form-control" type="text" id="nbPiece" name="nbPiece">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-md-6">
                                                <div class="position-relative form-group">
                                                    <label for="prixUnitaire">Code mouvement ou inventaire </label>
                                                    <input class="form-control" type="text" id="prixUnitaire" name="prixUnitaire">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button  class="btn btn-secondary" type="button" (click)="openTransfertModal.hide()"> <i class="fas fa-times mr-2"></i> Annuler</button>
                    <button class="btn btn-success ml-2" type="button"><i class="fas fa-save mr-2"></i> Enregistrer</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Entrepot source  add modal-->

<!-- Fin transfert de stock -->

<!-- Détail stock corrigés -->
<div #stockCorrigeDetail="bs-modal"  [config]="{backdrop: 'static'}"   backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Détail de stock corrigés: Correction du DATE DE LA CORRECTION</h6>
                <button type="button" class="close" aria-label="Close" (click)="stockCorrigeDetail.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="border p-2 bg-secondary">
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="row mt-1 mb-3">
                                        <div class="col-md-6 font-weight-bold">Action de la correction:</div>
                                        <div class="col-md-6"><span class="badge badge-success">Ajout</span></div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6 font-weight-bold">Référence du stock corrigé:</div>
                                        <div class="col-md-6">5TRFDESZ</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6 font-weight-bold">Entrepôt:</div>
                                        <div class="col-md-6">Entrepôt protic</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6 font-weight-bold">Nombre de pièce:</div>
                                        <div class="col-md-6">12</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6 font-weight-bold">Prix d'achat unitaire:</div>
                                        <div class="col-md-6">120 000</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6 font-weight-bold">Projet:</div>
                                        <div class="col-md-6">Projet protic</div>
                                    </div>
                                </div>
                                <div class="col-md-1 text-center"><div class="divider-v"></div></div>
                                <div class="col-md-5">
                                    <div class="row mt-1 mb-3">
                                        <div class="col-md-6 font-weight-bold">Libellé du mouvement:</div>
                                        <div class="col-md-6">Libelle Libelle Libelle</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6 font-weight-bold">Code mouvement ou inventaire:</div>
                                        <div class="col-md-6">cmbbb&12</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6 font-weight-bold">Corrigé par:</div>
                                        <div class="col-md-6">Ghislain</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6 font-weight-bold">Date de correction:</div>
                                        <div class="col-md-6">06/05/2021 00:00:00</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button  class="btn btn-secondary" type="button" (click)="stockCorrigeDetail.hide()"> <i class="fas fa-times mr-2"></i>Quitter</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin detail stock corrigés -->

<!-- Déttail stock transferés -->
<div #stockTransfereDetail="bs-modal"  [config]="{backdrop: 'static'}"   backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Détail de stock transferés: Transfère du DATE DE DU TRANSFERE</h6>
                <button type="button" class="close" aria-label="Close" (click)="stockTransfereDetail.show()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="border p-2 bg-secondary">
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="row mt-1 mb-3">
                                        <div class="col-md-6 font-weight-bold">Entrepôt source:</div>
                                        <div class="col-md-6">entrepot 1</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6 font-weight-bold">Entrepôt destinataire:</div>
                                        <div class="col-md-6">entrepot 2</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6 font-weight-bold">Nombre de pièce:</div>
                                        <div class="col-md-6">12</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6 font-weight-bold">Libellé du mouvement:</div>
                                        <div class="col-md-6">Libelle Libelle Libelle</div>
                                    </div>
                                </div>
                                <div class="col-md-1 text-center"><div class="divider-v"></div></div>
                                <div class="col-md-5">
                                    <div class="row mt-1 mb-3">
                                        <div class="col-md-6 font-weight-bold">Code mouvement ou inventaire:</div>
                                        <div class="col-md-6">cmbbb&12</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6 font-weight-bold">Transferer par:</div>
                                        <div class="col-md-6">Ghislain</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6 font-weight-bold">Date de transfère:</div>
                                        <div class="col-md-6">06/05/2021 00:00:00</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button  class="btn btn-secondary" type="button" (click)="stockTransfereDetail.hide()"> <i class="fas fa-times mr-2"></i>Quitter</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin detail stock transferés -->
