import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject,throwError } from 'rxjs';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Responsable } from './responsable';
import { Utilisateur } from '../utilisateur-pages/utilisateur.model';

interface RespoData {
  id?: string,
  type?: string,
  nom?: string,
  fonction?: string,
  pays?: string,
  contact?: string,
  email?: string,
  utilisateur?: Array<Utilisateur>,
}

@Injectable({
  providedIn: 'root'
})
export class ResponsableService {
  private _maitre = new BehaviorSubject<Responsable[]>([]);
  apiUrl = environment.apiUrl;
  prefix = environment.prefix;

  constructor(private http:HttpClient) { }

  get tiers(){
    return this._maitre.asObservable();
  }

  getListe(){
    return this.http.get<RespoData[]>(`${this.apiUrl}/responsable_suivis`,{responseType: 'json'}).pipe(
      take(1),
      switchMap( res =>{return res['hydra:member']}), 
      catchError(this.handleError)
    )
  }
  create(data:RespoData){
    return this.http.post<RespoData>(`${this.apiUrl}/responsable_suivis`,JSON.stringify(data)).pipe(
      take(1),
      tap( resData => {return resData}),
      catchError(this.handleError)
    );//pipe   
  }

  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "Une erreur est survenue";
    return throwError(messageError);
  }
}
