import { Utilisateur } from "../utilisateur-pages/utilisateur.model";

export class Caisse {
    id: any;
    typ: string; 
    reference: string; 
    libelle: string;
    devis: string;
    etat: string;
    gerant: string;
    soldeInitial: number;
    seuilMin: number;
    seuilMax: number;
    disponibilite: Boolean;
    published: string;
    auteur: Utilisateur;
    utilisateur: Array<Utilisateur>;
}

export class Banque {
    id: any;
    type: string; 
    reference: string; 
    libelle: string;
    devise: string;
    etat: string;
    gerant: string;
    soldeInitial: number;
    numCompte: string;
    codeIban: string;
    codeBS: string;
    domiciliation: string;
    codeJournal: string;
    disponibilite: Boolean;
    published: string;
    auteur: Utilisateur;
    utilisateur?: Array<Utilisateur>;
}

export class Tresorerie {
    id: any;
    type: string; 
    reference: string; 
    libelle: string;
    devise: string;
    etat: string;
    gerant: string;
    soldeInitial: number;
    seuilMin?: number;
    seuilMax?: number;
    numCompte?: string;
    codeIban?: string;
    codeBS?: string;
    domiciliation?: string;
    codeJournal?: string;
    disponibilite: Boolean;
    published: string;
    auteur: Utilisateur;
    utilisateur?: Array<Utilisateur>;
}

