import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { FournisseurPages } from 'src/app/models/fournisseur-pages';
import { FournisseurPagesService } from 'src/app/services/fournisseur-pages/fournisseur-pages.service';
import { ThemeOptions } from 'src/app/theme-options';
import { FournisseurEditComponent } from '../fournisseur-edit/fournisseur-edit.component';
import { FournisseurService } from '../fournisseur.service';

@Component({
  selector: 'app-fournisseur-list',
  templateUrl: './fournisseur-list.component.html',
  styleUrls: ['./fournisseur-list.component.scss']
})
export class FournisseurListComponent implements OnInit, OnDestroy {
  fournisseurs: FournisseurPages[];
  errMsg = "";

  //select
  value: any;
  inputTrx: boolean = false;
  addedProject: boolean = false;
  list: any;


  status1 = false;
  rotate = true;
  private notif: NotifierService;
  fournisseurDeleteSubs = new Subscription;

  constructor(
    public globals: ThemeOptions,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private notiferService: NotifierService,
    private fournisseurService: FournisseurService,
    private fournisseurServiceOld: FournisseurPagesService
  ) {
    this.notif = this.notiferService;
    this.fournisseurServiceOld.getList().subscribe({
      next: fournisseurs => this.fournisseurs = fournisseurs,
      error: err => this.errMsg = err
    });
  }

  ngOnInit() {
    this.showNotification('success', "L'opération s'est bien déroulé");
  }

  ngOnDestroy(): void {
    this.fournisseurDeleteSubs.unsubscribe();
  }


  /**
     * Show a notification
     */
  showNotification(type: string, message: string): void {
    this.notif.notify(type, message);
  }

  addFournisseur(donnee?, action?): any {
    console.log(donnee);
    console.log(donnee);

    if (donnee) {
      this.sendData(donnee, action);
    }
    this.modalService.dismissAll();
    // this.chambreService.edit = false;
    this.modal(FournisseurEditComponent, 'modal-basic-title', 'xl', false, 'static');
  }


  modal(component, type, size, center, backdrop): any {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {
      console.log('result', result);
      if (result == 'success') {
        this.showNotification('success', "L'opération s'est bien déroulé");
        // this.loadUser();
      } else if (result == 'update') {
        this.showNotification('success', "L'opération s'est bien déroulé");
        // this.loadUser();
      }

    }, (reason) => { });
  }

  sendData(donnee, bool) {
    const donneeSend = {
      user: donnee,
      type: bool
    };
    this.fournisseurService.setDataForFournisseur(donneeSend);
  }


  deleteFournisseur(id) {
    console.log('id to delete', id);
    this.fournisseurDeleteSubs = this.fournisseurService.deleteFournisseur(id).subscribe(
      resData => {
        this.showNotification('success', "L'opération s'est bien déroulé");
        // this.loadFournisseur();
        // if(resData) {
        //   this.showNotification('success', "L'opération s'est bien déroulé");
        // } 

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }

}
