import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilOptions } from 'src/app/shared-components/util/util-option';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-visite-edit',
  templateUrl: './visite-edit.component.html',
  styleUrls: ['./visite-edit.component.scss']
})
export class VisiteEditComponent implements OnInit {
  required = UtilOptions.required;
  typeRow = UtilOptions.typeRessources;
  form: FormGroup;
  rotate = true;
  imgUrl: any;
  submit = false;
  
  constructor(
    private formBuilder: FormBuilder,
    public globals: ThemeOptions,
  ) {
    this.newForm();
    this.imgUrl = [];
  }

  ngOnInit() {
  }

  newForm() {
    this.form = this.formBuilder.group({
      id: [null],
      numeroVisite: [null, [Validators.required]],
      motif: [null, [Validators.required]],
      dateDebut: [null, [Validators.required]],
      dateFin: [null, [Validators.required]],
      fichier: [null, [Validators.required]],
      journee: [null, [Validators.required]],
      description: [null, [Validators.required]],
    })
  }

  onSubmit(){
    this.submit = true;
    console.log(this.form.value);
  }
  
  // "onSelectImg" pour la selection des image dans le formulaire et "deleteImg" pour la suppression
  onSelectImg(e){
    if(e.target.files){
      var reader = new FileReader();
      var file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload=(events:any)=>{
        this.imgUrl.push(events.target.result);
      }
    }
  }
  deleteImg(el) {
    this.imgUrl.forEach((value,index) => {
      if(value == el)
        this.imgUrl.splice(index, 1);
    });
  }
  get f() { return this.form.controls; }

}
