import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { Utilisateur } from '../utilisateur-pages/utilisateur.model';

interface Caisse {
    id?: any;
    type?: string; 
    reference?: string; 
    libelle?: string;
    devise?: string;
    etat?: string;
    gerant?: string;
    soldeInitial?: number;
    seuilMin?: number;
    seuilMax?: number;
    disponibilite?: Boolean;
    published?: string;
    auteur?: Utilisateur;
    utilisateur?: Array<Utilisateur>;
}

interface Banque {
    id?: any;
    type?: string; 
    reference?: string; 
    libelle?: string;
    devise?: string;
    etat?: string;
    gerant?: string;
    soldeInitial?: number;
    numCompte?: string;
    codeIban?: string;
    codeBS?: string;
    domiciliation?: string;
    codeJournal?: string;
    disponibilite?: Boolean;
    published?: string;
    auteur?: Utilisateur;
    utilisateur?: Array<Utilisateur>;
}

@Injectable({
  providedIn: 'root'
})
export class TresorerieService {
    private assignData;
    public type: string = "";
    apiUrl = environment.apiUrl;
    prefix = environment.prefix;

    constructor(private http:HttpClient) { }

    setTresorerie(tresorerie) {
        this.assignData = tresorerie;
    }
    getTresorerie() {
        return this.assignData;
    }

    add(data) {
        if (this.type == "CAISSE") {
            return this.createCaisse(data);
        } else {
            return this.createBanque(data);
        }
    }

    edit(id, data) {
        if (this.type == "CAISSE") {
            return this.updateCaisse(id, data);
        } else {
            return this.updateBanque(id, data);
        }
    }

    onDelete(id, type): Observable<any> {
        if (type == "CAISSE") {
            return this.deleteCaisse(id);
        } else {
            return this.deleteBanque(id);
        }
    }

    createCaisse(data:Caisse): Observable<any> {
        return this.http.post(`${this.apiUrl}/caisses`,JSON.stringify(data)).pipe(
            take(1),
            tap( res => {return res}),
            catchError(this.handleError)
        );   
    }

    createBanque(data:Banque): Observable<any> {
        return this.http.post(`${this.apiUrl}/banques`,JSON.stringify(data)).pipe(
            take(1),
            tap( res => {return res}),
            catchError(this.handleError)
        );   
    }

    updateCaisse(id: number, data:Caisse): Observable<any> {
        return this.http.put(`${this.apiUrl}/caisses/${id}`,JSON.stringify(data)).pipe(
            take(1),
            tap( res => {return res}),
            catchError(this.handleError)
        );   
    }

    updateBanque(id: number, data:Banque): Observable<any> {
        return this.http.put(`${this.apiUrl}/banques/${id}`,JSON.stringify(data)).pipe(
            take(1),
            tap( res => {return res}),
            catchError(this.handleError)
        );   
    }
    
    getCaisse(): Observable<any>{
        return this.http.get<[Caisse]>(`${this.apiUrl}/caisses`,{ responseType: 'json' }).pipe(
            map((response: any) => response['hydra:member']),
            catchError(this.handleError)
        )
    }
    
    getBanque(): Observable<any>{
        return this.http.get<[Banque]>(`${this.apiUrl}/banques`,{ responseType: 'json' }).pipe(
            map((response: any) => response['hydra:member']),
            catchError(this.handleError)
        )
    }

    deleteCaisse(id: number): Observable<Caisse> {
        return this.http.delete(`${this.apiUrl}/caisses/${id}`,{responseType: 'json'}).pipe(
            take(1),
            switchMap( res =>{return res['hydra:member'];}),
            catchError(this.handleError)
        )
    }

    deleteBanque(id: number): Observable<Banque> {
        return this.http.delete(`${this.apiUrl}/banques/${id}`,{responseType: 'json'}).pipe(
            take(1),
            switchMap( res =>{return res['hydra:member'];}),
            catchError(this.handleError)
        )
    }
    
    handleError(errorMessage: HttpErrorResponse) {
        let messageError = "Une erreur est survenue";
        return throwError(messageError);
    }

}

