import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { stringify } from 'querystring';
import { BehaviorSubject,throwError } from 'rxjs';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Famille } from './famille.model';


interface familleData {
  id?: string,
  libelle: string,
}
interface SousFamilleData {
  id?: string,
  libelle: string,
  famille: string,
}

@Injectable({
  providedIn: 'root'
})
export class FamilleService {

  // private _bureauEtudes = new BehaviorSubject<Famille[]>([]);
  apiUrl = environment.apiUrl;
  prefix = environment.prefix;

  constructor(private http:HttpClient) { }

  // get bureauEtudes(){
  //   return this._bureauEtudes.asObservable();
  // }

  listFamille(){
    return this.http.get<familleData[]>(
      `${this.apiUrl}/familles`,{
        responseType: 'json'
      }
    ).pipe(
      map((response: any) => response['hydra:member']),
      catchError(this.handleError)
    )
  }

  addFamille(data:familleData){
    let responseData:familleData;
        return this.http.post(`${this.apiUrl}/familles`,JSON.stringify(data)).pipe(
        tap( resData => {      
          return resData
        }),
        catchError(this.handleError)
      );//pipe   
  }


  ////  ADD SOUS FAMILLE------------------------------------------------------------------------------
  addSousFamille(data:SousFamilleData){
    let responseData:SousFamilleData;
        return this.http.post(`${this.apiUrl}/sous_familles`,JSON.stringify(data)).pipe(
        tap( resData => {      
          return resData
        }),
        catchError(this.handleError)
      );//pipe   
  }

  listSousFamille(){
    return this.http.get<SousFamilleData[]>(
      `${this.apiUrl}/sous_familles`,{
        responseType: 'json'
      }
    ).pipe(
      map((response: any) => response['hydra:member']),
      catchError(this.handleError)
    )
  }

  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "Une erreur est survenue";
    return throwError(messageError);
  }
}
