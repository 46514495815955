import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FichierDetailComponent } from './fichier-detail/fichier-detail.component';
import { FichierListComponent } from './fichier-list/fichier-list.component';
import { FichierPagesComponent } from './fichier-pages.component';


const routes: Routes = [
  {
    path:'',
    component: FichierPagesComponent,
    children: [
      {
        path: "liste",
        component: FichierListComponent
      },
      {
        path: "detail/:id",
        component: FichierDetailComponent
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FichierPagesRoutingModule { }
