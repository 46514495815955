import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-etude-prix-detail',
  templateUrl: './etude-prix-detail.component.html',
  styleUrls: ['./etude-prix-detail.component.scss']
})
export class EtudePrixDetailComponent implements OnInit {
  status1 = false;
  
  constructor(public globals: ThemeOptions) { }

  ngOnInit() {

  }

  //tree view
  dataEtudePrix: any[] = [
    { id: 1, designation: 'Travaux de genie logiciel', quantite: "", unite: "", prix: "2700",parent: 0},
    { id: 2, designation: 'Réamenagement salle HTA1', quantite: 3, unite: "m", prix: "1200",parent: 1},
    { id: 3, designation: 'Réamenagement salle HTA2', quantite: 8, unite: "m", prix: "1500",parent: 1},

    { id: 4, designation: 'Equipement HTA du Poste Extérieur', quantite: "", unite: "", prix: "2700",parent: 20},
    { id: 5, designation: 'Réamenagement salle HTA3', quantite: 3, unite: "m", prix: "1200",parent: 4},
    { id: 6, designation: 'Réamenagement salle HTA4', quantite: 8, unite: "m", prix: "1500",parent: 4},

    { id: 7, designation: 'Equipement HTA du Poste Intérieur', quantite: "", unite: "", prix: "2700",parent: 21},
    { id: 8, designation: 'Réamenagement salle HTA5', quantite: 3, unite: "m", prix: "1200",parent: 7},
    { id: 9, designation: 'Réamenagement salle HTA6', quantite: 8, unite: "m", prix: "1500",parent: 7},
  ];

  configs: any = {
    id_field: 'id',
    parent_id_field: 'parent',
    parent_display_field: 'designation',
    filter: true,
    css: { // Optional
        expand_class: 'ion-md-arrow-dropright',
        collapse_class: 'ion-md-arrow-dropdown',
        add_class: 'card p-2 text-success ion-md-add',
        edit_class: 'ion-md-create',
        delete_class: 'ion-md-trash',
        save_class: 'ion-md-archive',
        cancel_class: 'ion-md-close',
    },
    columns: [

      {
        name: 'designation',
        header: 'Désignation',
        filterFunction: function(row_data, column, column_value, filter_value) {
          return column_value > filter_value;
      }
    },
        {
            name: 'quantite',
            header: 'Quantité',
            filterFunction: function(row_data, column, column_value, filter_value) {
              return column_value > filter_value;
          }
        },
        {
            name: 'unite',
            header: 'Unité',
            filterFunction: function(row_data, column, column_value, filter_value) {
                return column_value > filter_value;
            }
        },
        {
          name: 'prix',
          header: 'Prix',
          filterFunction: function(row_data, column, column_value, filter_value) {
              return column_value > filter_value;
          }
        }
        /*{
          name: 'statut',
          header: 'Statut',
          type: 'custom',
          component: BadgeCellViewComponent,
        },
        {
          name: "taux",
          header: "taux d'élaboration",
          filterFunction: function(row_data, column, column_value, filter_value) {
              return column_value > filter_value;
          },
          renderer: function(value) {
            return value + ' %';
          }
        },
        /*{
            name: 'gender',
            header: 'Gender',
            renderer: function(value) {
                return value ? 'Male' : 'Female';
            }
        },*/

    ]
  };

}
