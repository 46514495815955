<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-umbrella display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>Tableau de bord projet</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">
                    Statistique générales
                </div>
            </div>
        </div>
    </div>
</div>

<!-- La carte de géolocalisation -->
<accordion [closeOthers]="true" [isAnimated]="true" class="accordion mb-3">
    <accordion-group class="card card-box">
        <button accordion-heading class="btn btn-link btn-lg d-flex align-items-center justify-content-between"
                type="button">
            <span><fa-icon [icon]="['far', 'map']" class="mr-2"></fa-icon>Carte de localisation des chantier (Cliquer pour dérouler)</span>
            <fa-icon [icon]="['fas', 'angle-up']" class="font-size-xl"></fa-icon>
        </button>
        <div class="m-3 border">
            <agm-map style="height: 550px;" [latitude]="59.326242" [longitude]="17.8419719" >
                <agm-marker [latitude]="59.326242" [longitude]="17.8419719">
                  <agm-info-window>Abidjan</agm-info-window>
                </agm-marker>
            </agm-map>
        </div>
    </accordion-group>
</accordion>

<!-- Premier section-->
<div class="row">
    <div class="col-md-3">
        <a [routerLink]="['/pages/projet/liste']" class="card card-box bg-arielle-smile text-white border-0 mb-5">
            <div class="px-4 py-3">
                <h5>Total Projets</h5>
                <h2 class="text-right">
                    <i class="fas fa-umbrella float-left"></i>
                    <span class="font-weight-bold" [countUp]="455"></span>
                </h2>
                <p class="mb-0">Demarés: 255</p>
            </div>
        </a>
    </div>
    <div class="col-md-3">
        <a [routerLink]="['']" class="card card-box border-0 mb-5">
            <div class="px-4 py-3">
                <h5 class="text-black">Total Commandes</h5>
                <h2 class="text-right">
                    <i class="fas fa-shopping-cart float-left text-first"></i>
                    <span class="font-weight-bold text-black" [countUp]="45"></span>
                </h2>
                <p class="mb-0 text-black">Validé: 25<span class="float-right">Non Validé: 20</span></p>
            </div>
        </a>
    </div>
    <div class="col-md-3">
        <a [routerLink]="['']" class="card card-box bg-happy-fisher border-0 mb-5">
            <div class="px-4 py-3">
                <h5 class="text-black">Total Chantiers</h5>
                <h2 class="text-right">
                    <i class="fas fa-network-wired float-left text-first"></i>
                    <span class="font-weight-bold text-white" [countUp]="455"></span>
                </h2>
                <p class="mb-0 text-black">Demarés: 255</p>
            </div>
        </a>
    </div>
    <div class="col-md-3">
        <a [routerLink]="['']" class="card card-box bg-strong-bliss text-white border-0 mb-5">
            <div class="px-4 py-3">
                <h5>Rapports Journalier</h5>
                <h2 class="text-right">
                    <i class="fas fa-copy float-left"></i>
                    <span class="font-weight-bold" [countUp]="65"></span>
                </h2>
                <p class="mb-0 font-weight-bold">Validé: 25<span class="float-right">Non Validé: 40</span></p>
            </div>
        </a>
    </div>
</div>

<!-- Deuxieme section (les graphique) -->
<div class="row">
    <div class="col-md-6">
        <div class="card card-box border-0 mb-5" style="height: 355px;">
            <div class="card-header p-3">
                <div class="card-header--title">
                    <h6 class="font-weight-bold mb-0">Évolution des Projets</h6>
                </div>
            </div>
            <div class="card-body p-2">
                <apx-chart
                    [chart]="{ type: 'bar', height: '305'}"
                    [colors]="['#4191ff', '#1bc943']"
                    [plotOptions]="{bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded'}}"
                    [stroke]="{show: true,width: 2,colors: ['transparent']}"
                    [dataLabels]="{enabled: false}"
                    [fill]="{opacity: 0.85, colors: ['#4191ff', '#1bc943']}"
                    [grid]="{ strokeDashArray: '5', borderColor: 'rgba(125, 138, 156, 0.3)'}"
                    [series]="[
                        {name: 'Budgets', data: [44, 55, 57, 56, 61]},
                        {name: 'Dépenses', data: [76, 85, 101, 98, 87]}
                    ]"
                    [xaxis]="{categories: ['Projet 1', 'Projet 2', 'Projet 3', 'Projet 4', 'Projet 5']}"
                    [yaxis]="{title: {text: 'Montant en FCFA'}}"
                ></apx-chart>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="card py-4 px-3 mb-5" style="height: 355px;">
            <h6 class="text-center font-weight-bold mb-0">Évolution des Projets</h6>
            <div class="my-4">
                <apx-chart
                    [chart]="{type: 'donut', height: '208'}"
                    [colors]="['#f4772e', '#4191ff', '#1bc943']"
                    [labels]="['En attente', 'En cours', 'Terminés']"
                    [series]="[33, 44, 55]"
                    [legend]="{
                        show: true,
                        position: 'bottom',
                        horizontalAlign: 'center',
                        floating: false,
                        fontFamily: 'Heebo, sans-serif',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        labels: {
                            colors: 'rgba(59, 62, 102, 0.72)',
                            useSeriesColors: false
                        },
                        offsetX: -20,
                        offsetY: -10,
                        markers: {
                            width: 16,
                            height: 8,
                            strokeWidth: 0,
                            strokeColor: '#fff',
                            radius: 4,
                            offsetX: 0,
                            offsetY: 0
                        },
                        itemMargin: {
                            horizontal: 2,
                            vertical: 3
                        }
                    }"
                ></apx-chart>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="card bg-arielle-smile py-4 px-3 mb-5" style="height: 355px;">
            <h6 class="text-center font-weight-bold mb-0">Évolution des Chantier</h6>
            <div class="my-4">
                <div class="gauge-wrapper">
                    <circle-progress
                        [percent]="74"
                        [radius]="100"
                        [outerStrokeWidth]="15"
                        [innerStrokeWidth]="11"
                        [outerStrokeColor]="'var(--success)'"
                        [innerStrokeColor]="'var(--secondary)'"
                        [animation]="true"
                        [responsive]="true"
                        [showSubtitle]="false"
                        [titleFontSize]="42"
                        [unitsFontSize]="29"
                        [showBackground]="false"
                        [showSubtitle]="false"
                        [animationDuration]="600"
                    ></circle-progress>
                </div>
            </div>
            <div class="row mt-3 text-center">
                <div class="col">
                    <h3 class="m-0 font-weight-bold text-white">10</h3>
                    <span>En Cours</span>
                </div>
                <div class="col">
                    <h3 class="m-0 font-weight-bold text-white">40</h3>
                    <span>Terminés</span>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Troisieme section (Le tableau des 5 derniers projets en cours) -->
<div class="card card-box border-0 mb-5">
    <div class="card-header p-3">
        <div class="card-header--title font-weight-bold">Liste des cinq(5) derniers projets recement demarés</div>
        <div class="card-header--actions">
            <div class="btn-group" dropdown>
                <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                    dropdownToggle
                    tooltip="Action"
                    type="button">
                    <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                </button>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                    <ul class="nav nav-pills flex-column p-3">
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-eye"></i><span>Tout voir</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Importer</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link">
                                <span>TOTAL</span>
                                <div class="ml-auto badge badge-first">102</div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="table-responsive m-0">
            <table class="table table-hover text-nowrap m-0">
                <thead>
                    <tr>
                        <th>Projets</th>
                        <th>Client</th>
                        <th>Chantier</th>
                        <th>Montant du marché</th>
                        <th>Membres</th>
                        <th>Créer par</th>
                        <th>Date de création</th>
                        <th class="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="d-flex align-items-center">
                                <div>
                                    <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                    <span class="text-black-50 d-block">PJ2011-0008</span>
                                </div>
                            </div>
                        </td>
                        <td><span class="badge bg-neutral-dark text-dark">Gerome cesar</span></td>
                        <td><span class="badge bg-neutral-dark text-dark">5</span></td>
                        <td><span class="badge bg-neutral-first text-first">500 000 000 000 XOF</span></td>
                        <td>
                            <div class="avatar-wrapper-overlap d-flex justify-content-center">
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                            </div>
                        </td>
                        <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                        <td><span class="badge badge-success">14 Juin 2021 à 14:35:44</span></td>
                        <td class="text-center">
                            <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="d-flex align-items-center">
                                <div>
                                    <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                    <span class="text-black-50 d-block">PJ2011-0008</span>
                                </div>
                            </div>
                        </td>
                        <td><span class="badge bg-neutral-dark text-dark">Gerome cesar</span></td>
                        <td><span class="badge bg-neutral-dark text-dark">5</span></td>
                        <td><span class="badge bg-neutral-first text-first">500 000 000 000 XOF</span></td>
                        <td>
                            <div class="avatar-wrapper-overlap d-flex justify-content-center">
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                            </div>
                        </td>
                        <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                        <td><span class="badge badge-success">14 Juin 2021 à 14:35:44</span></td>
                        <td class="text-center">
                            <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th colspan="3">Total:</th>
                        <td colspan="9">500 000 000 000 <span class="text-black-50">XOF</span></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>

<!-- Quartrieme section -->
<div class="row">
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-6">
                <div class="card card-box card-box-hover text-black align-box-row align-items-start mb-5 p-3 bg-secondary">
                    <div>
                        <h6 class="font-weight-bold">Commandes Livrée(s)</h6>
                        <div class="display-4 font-weight-bold text-success">18</div>
                        <div class="divider mt-2 mb-3 border-2 w-25 bg-success rounded border-success"></div>
                        <div class="font-weight-bold text-black-50">
                            500 000 000 000 <span class="opacity-6 ml-3">XOF</span>
                        </div>
                    </div>
                    <div class="ml-auto card-hover-indicator align-self-center">
                        <fa-icon [icon]="['fas', 'chevron-right']" class="font-size-xl"></fa-icon>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card card-box card-box-hover text-black align-box-row align-items-start mb-5 p-3 bg-secondary">
                    <div>
                        <h6 class="font-weight-bold">Commandes Non Livrée(s)</h6>
                        <div class="display-4 font-weight-bold text-danger">18</div>
                        <div class="divider mt-2 mb-3 border-2 w-25 bg-danger rounded border-danger"></div>
                        <div class="font-weight-bold text-black-50">
                            500 000 000 000 <span class="opacity-6 ml-3">XOF</span>
                        </div>
                    </div>
                    <div class="ml-auto card-hover-indicator align-self-center">
                        <fa-icon [icon]="['fas', 'chevron-right']" class="font-size-xl"></fa-icon>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card card-box card-box-hover text-black align-box-row align-items-start mb-5 p-3 bg-secondary">
                    <div>
                        <h6 class="font-weight-bold">Facture(s) Payé(s)</h6>
                        <div class="display-4 text-first font-weight-bold">56</div>
                        <div class="divider mt-2 mb-3 border-2 w-25 bg-first rounded border-first"></div>
                        <div class="font-weight-bold text-black-50">
                            500 000 000 000 <span class="opacity-6 ml-3">XOF</span>
                        </div>
                    </div>
                    <div class="ml-auto card-hover-indicator align-self-center">
                        <fa-icon [icon]="['fas', 'chevron-right']" class="font-size-xl"></fa-icon>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card card-box card-box-hover text-black align-box-row align-items-start mb-5 p-3 bg-secondary">
                    <div>
                        <h6 class="font-weight-bold">Facture(s) Non Payé(s)</h6>
                        <div class="display-4 font-weight-bold text-warning">18</div>
                        <div class="divider mt-2 mb-3 border-2 w-25 bg-success rounded border-warning"></div>
                        <div class="font-weight-bold text-black-50">
                            500 000 000 000 <span class="opacity-6 ml-3">XOF</span>
                        </div>
                    </div>
                    <div class="ml-auto card-hover-indicator align-self-center">
                        <fa-icon [icon]="['fas', 'chevron-right']" class="font-size-xl"></fa-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card card-box border-0 mb-5" style="height: 335px;">
            <div class="card-header">
                <div class="card-header--title font-weight-bold">Les 10 projets recent</div>
                <div class="card-header--actions">
                    <div class="btn-group" dropdown>
                        <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                            dropdownToggle
                            tooltip="Action"
                            type="button">
                            <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                        </button>
                        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                            <ul class="nav nav-pills flex-column p-3">
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-eye"></i><span>Tout voir</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Importer</span></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link">
                                        <span>TOTAL</span>
                                        <div class="ml-auto badge badge-first">102</div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-3">
                <perfect-scrollbar [autoPropagation]="true" class="scroll-area">
                    <div class="table-responsive">
                        <table class="table table-hover text-nowrap table-borderless mb-0">
                            <thead>
                                <tr>
                                    <th class="">Projets</th>
                                    <th class="text-center">Montant du marché</th>
                                    <th class="text-center">Créer par</th>
                                    <th class="text-center">Date de création</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody class="border-bottom">
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center"><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td class="text-center"><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td class="text-center"><span class="badge badge-success">14 Juin 2021 à 14:18:06</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center"><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td class="text-center"><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td class="text-center"><span class="badge badge-success">14 Juin 2021 à 14:18:06</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center"><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td class="text-center"><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td class="text-center"><span class="badge badge-success">14 Juin 2021 à 14:18:06</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Total:</th>
                                    <td colspan="4">500 000 000 <span class="text-black-50">XOF</span></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</div>

<!-- Cinquieme section -->
<div class="row">
    <div class="col-md-6">
        <div class="card card-box border-0 mb-5" style="height: 350px;">
            <div class="card-header">
                <div class="card-header--title font-weight-bold">Les 10 derniers chantiers n'ayant pas de budget</div>
                <div class="card-header--actions">
                    <div class="btn-group" dropdown>
                        <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                            dropdownToggle
                            tooltip="Action"
                            type="button">
                            <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                        </button>
                        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                            <ul class="nav nav-pills flex-column p-3">
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-eye"></i><span>Tout voir</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Importer</span></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link">
                                        <span>TOTAL</span>
                                        <div class="ml-auto badge badge-first">102</div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-3">
                <perfect-scrollbar [autoPropagation]="true" class="scroll-area">
                    <div class="table-responsive">
                        <table class="table table-hover text-nowrap table-borderless mb-0">
                            <thead>
                                <tr>
                                    <th>Projets</th>
                                    <th>Montant du marché</th>
                                    <th>Créer par</th>
                                    <th>Date de création</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td><span class="badge badge-success">14 Juin 2021 à 14:35:44</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td><span class="badge badge-success">14 Juin 2021 à 14:35:44</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td><span class="badge badge-success">14 Juin 2021 à 14:35:44</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td><span class="badge badge-success">14 Juin 2021 à 14:35:44</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td><span class="badge badge-success">14 Juin 2021 à 14:35:44</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-6">
                <a [routerLink]=""
                   class="card card-box card-box-hover-rise card-box-hover bg-night-sky text-white align-box-row align-items-start mb-5 p-4">
                    <div>
                        <span>Chantier(s) En Attente</span>
                        <div class="display-4 font-weight-bold">546</div>
                        <div class="divider mt-2 mb-3 border-2 w-25 bg-warning rounded border-success"></div>
                        <div class="font-weight-bold text-white-50 font-size-sm text-uppercase">
                            500 000 000 000 <span class="opacity-6">XOF</span>
                        </div>
                    </div>
                    <div class="ml-auto card-hover-indicator align-self-center">
                        <fa-icon [icon]="['fas', 'chevron-right']" class="font-size-xl"></fa-icon>
                    </div>
                </a>
            </div>
            <div class="col-md-6">
                <a [routerLink]=""
                   class="card card-box card-box-hover-rise card-box-hover bg-premium-dark text-white align-box-row align-items-start mb-5 p-4">
                    <div>
                        <span>Chantier(s) En Cours</span>
                        <div class="display-4 font-weight-bold">56</div>
                        <div class="divider mt-2 mb-3 border-2 w-25 bg-first rounded border-first"></div>
                        <div class="font-weight-bold text-white-50 font-size-sm text-uppercase">
                            500 000 000 000 <span class="opacity-6">XOF</span>
                        </div>
                    </div>
                    <div class="ml-auto card-hover-indicator align-self-center">
                        <fa-icon [icon]="['fas', 'chevron-right']" class="font-size-xl"></fa-icon>
                    </div>
                </a>
            </div>
            <div class="col-md-6">
                <a [routerLink]=""
                   class="card card-box card-box-hover-rise card-box-hover bg-midnight-bloom text-white align-box-row align-items-start mb-5 p-4">
                    <div>
                        <span>Chantier(s) Terminé(s)</span>
                        <div class="display-4 font-weight-bold">29</div>
                        <div class="divider mt-2 mb-3 border-2 w-25 bg-success rounded border-success"></div>
                        <div class="font-weight-bold text-white-50 font-size-sm text-uppercase">
                            500 000 000 000 <span class="opacity-6">XOF</span>
                        </div>
                    </div>
                    <div class="ml-auto card-hover-indicator align-self-center">
                        <fa-icon [icon]="['fas', 'chevron-right']" class="font-size-xl"></fa-icon>
                    </div>
                </a>
            </div>
            <div class="col-md-6">
                <a [routerLink]=""
                   class="card card-box card-box-hover-rise card-box-hover bg-vicious-stance text-white align-box-row align-items-start mb-5 p-4">
                    <div>
                        <span>Chantier(s) Annulé(s)</span>
                        <div class="display-4 font-weight-bold">0</div>
                        <div class="divider mt-2 mb-3 border-2 w-25 bg-danger rounded border-danger"></div>
                        <div class="font-weight-bold text-white-50 font-size-sm text-uppercase">
                            0 <span class="opacity-6">XOF</span>
                        </div>
                    </div>
                    <div class="ml-auto card-hover-indicator align-self-center">
                        <fa-icon [icon]="['fas', 'chevron-right']" class="font-size-xl"></fa-icon>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>

<!-- Sixieme section -->
<div class="row">
    <div class="col-md-6">
        <div class="card card-box border-0 mb-5" style="height: 350px;">
            <div class="card-header">
                <div class="card-header--title font-weight-bold">Les 10 derniers projets modifier</div>
                <div class="card-header--actions">
                    <div class="btn-group" dropdown>
                        <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                            dropdownToggle
                            tooltip="Action"
                            type="button">
                            <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                        </button>
                        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                            <ul class="nav nav-pills flex-column p-3">
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-eye"></i><span>Tout voir</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Importer</span></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link">
                                        <span>TOTAL</span>
                                        <div class="ml-auto badge badge-first">102</div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-3">
                <perfect-scrollbar [autoPropagation]="true" class="scroll-area">
                    <div class="table-responsive">
                        <table class="table table-hover text-nowrap table-borderless mb-0">
                            <thead>
                                <tr>
                                    <th>Projets</th>
                                    <th>Montant du marché</th>
                                    <th>Modifier par</th>
                                    <th>Date de modification</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody class="border-bottom">
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td><span class="badge badge-info">14 Juin 2021 à 14:18:06</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td><span class="badge badge-info">14 Juin 2021 à 14:18:06</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td><span class="badge badge-info">14 Juin 2021 à 14:18:06</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td><span class="badge badge-info">14 Juin 2021 à 14:18:06</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td><span class="badge badge-info">14 Juin 2021 à 14:18:06</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Total:</th>
                                    <td colspan="4">500 000 000 <span class="text-black-50">XOF</span></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card card-box border-0" style="height: 350px;">
            <div class="card-header">
                <div class="card-header--title font-weight-bold">Les 10 projets ayant un écart negatif</div>
                <div class="card-header--actions">
                    <div class="btn-group" dropdown>
                        <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                            dropdownToggle
                            tooltip="Action"
                            type="button">
                            <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                        </button>
                        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                            <ul class="nav nav-pills flex-column p-3">
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-eye"></i><span>Tout voir</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Importer</span></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link">
                                        <span>TOTAL</span>
                                        <div class="ml-auto badge badge-first">102</div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-3">
                <perfect-scrollbar [autoPropagation]="true" class="scroll-area">
                    <div class="table-responsive">
                        <table class="table table-hover text-nowrap table-borderless mb-0">
                            <thead>
                                <tr>
                                    <th>Projets</th>
                                    <th>Montant du marché</th>
                                    <th>Budget d'exécution</th>
                                    <th>Dépense</th>
                                    <th>Écart</th>
                                    <th>Modifier par</th>
                                    <th>Date de modification</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody class="border-bottom">
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">500 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-danger text-danger">500 000 000 XOF</span></td>
                                    <td><span class="badge badge-warning">-567 987 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td><span class="badge badge-info">14 Juin 2021 à 14:18:06</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">500 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-danger text-danger">500 000 000 XOF</span></td>
                                    <td><span class="badge badge-warning">-567 987 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td><span class="badge badge-info">14 Juin 2021 à 14:18:06</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">500 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-danger text-danger">500 000 000 XOF</span></td>
                                    <td><span class="badge badge-warning">-567 987 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td><span class="badge badge-info">14 Juin 2021 à 14:18:06</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">500 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-danger text-danger">500 000 000 XOF</span></td>
                                    <td><span class="badge badge-warning">-567 987 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td><span class="badge badge-info">14 Juin 2021 à 14:18:06</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                                <span class="text-black-50 d-block">PJ2011-0008</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span class="badge badge-info">500 000 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">500 000 000 XOF</span></td>
                                    <td><span class="badge bg-neutral-danger text-danger">500 000 000 XOF</span></td>
                                    <td><span class="badge badge-warning">-567 987 XOF</span></td>
                                    <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                                    <td><span class="badge badge-info">14 Juin 2021 à 14:18:06</span></td>
                                    <td class="text-center">
                                        <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                            <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Total:</th>
                                    <td>500 000 000 <span class="text-black-50">XOF</span></td>
                                    <td colspan="3">500 000 000 <span class="text-black-50">XOF</span></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</div>

<!-- Septieme section -->
<div class="card card-box border-0">
    <div class="card-header">
        <div class="card-header--title font-weight-bold">Les 10 projet n'ayant pas de chantier</div>
        <div class="card-header--actions">
            <div class="btn-group" dropdown>
                <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                    dropdownToggle
                    tooltip="Action"
                    type="button">
                    <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                </button>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                    <ul class="nav nav-pills flex-column p-3">
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-eye"></i><span>Tout voir</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Importer</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link">
                                <span>TOTAL</span>
                                <div class="ml-auto badge badge-first">102</div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="table-responsive mb-0">
            <table class="table table-hover text-nowrap m-0">
                <thead>
                    <tr>
                        <th>Projets</th>
                        <th>Client</th>
                        <th>Montant du marché</th>
                        <th>Membres</th>
                        <th>Créer par</th>
                        <th>Date de création</th>
                        <th class="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="d-flex align-items-center">
                                <div>
                                    <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                    <span class="text-black-50 d-block">PJ2011-0008</span>
                                </div>
                            </div>
                        </td>
                        <td><span class="badge bg-neutral-dark text-dark">Gerome cesar</span></td>
                        <td><span class="badge bg-neutral-first text-first">500 000 000 000 XOF</span></td>
                        <td>
                            <div class="avatar-wrapper-overlap d-flex justify-content-center">
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                            </div>
                        </td>
                        <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                        <td><span class="badge badge-success">14 Juin 2021 à 14:35:44</span></td>
                        <td class="text-center">
                            <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="d-flex align-items-center">
                                <div>
                                    <a [routerLink]="" class="font-weight-bold text-black" title="Projet de contruction R+4">Projet de contruction R+4</a>
                                    <span class="text-black-50 d-block">PJ2011-0008</span>
                                </div>
                            </div>
                        </td>
                        <td><span class="badge bg-neutral-dark text-dark">Gerome cesar</span></td>
                        <td><span class="badge bg-neutral-first text-first">500 000 000 000 XOF</span></td>
                        <td>
                            <div class="avatar-wrapper-overlap d-flex justify-content-center">
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                            </div>
                        </td>
                        <td><span class="badge bg-neutral-dark text-dark">Kouassi ghislain</span></td>
                        <td><span class="badge badge-success">14 Juin 2021 à 14:35:44</span></td>
                        <td class="text-center">
                            <button placement="auto" tooltip="Détail"  class="btn d-40 p-0 btn-pill btn-primary" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th colspan="2">Total:</th>
                        <td colspan="5">500 000 000 000 <span class="text-black-50">XOF</span></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>