<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-attention display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1 class="font-weight-bold m-0">Fiche de bullein HSE</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description m-0" title="Référence et libellé du bullein">
                    <span><i class="fas fa-file-alt"></i> ( {{bulletin?.reference}} )</span>
                    <span class="ml-1">{{bulletin?.objet}}</span>
                </div>
                <p class="m-0" title="Date de cette fiche">
                    <i class="fas fa-calendar-alt"></i>
                    <span class="ml-1">Créer le: {{bulletin?.dateCreation}}</span>
                </p>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button [routerLink]="['/pages/hse/liste']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<div class="card">
    <div class="body p-3">
        <div class="border rounded p-3">
            <hr class="m-0">
            <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Information générales</h4></div>
            <div class="row">
                <div class="col-md-6">
                    <p class="mt-2 mb-0"><b>Référeence:</b> <span class="ml-2">{{bulletin?.reference}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Type de bulletin:</b> <span class="ml-2">{{bulletin?.type}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Objet:</b> <span class="ml-2">{{bulletin?.objet}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date:</b> <span class="ml-2">{{bulletin?.date}}</span></p>
                    <hr class="my-2">
                    <p class="m-0">
                        <b>Gravité:</b>
                        <span class="ml-2 badge bg-{{
                            bulletin?.gravite == 'Urgente' ? 'neutral-danger text-danger' : 
                            bulletin?.gravite == 'Normal' ? 'neutral-first text-first' : 
                            bulletin?.gravite == 'Haute' ? 'neutral-danger text-danger' : 
                            bulletin?.gravite == 'Faible' ? 'neutral-info text-info' : 
                            bulletin?.gravite == 'Moyenne' ? 'neutral-warning text-warning' : 'neutral-secondary' 
                            }}">{{bulletin?.gravite}}
                        </span>
                    </p>
                    <hr class="my-2">
                    <p class="m-0"><b>Projet concerné:</b> <span class="ml-2">{{bulletin?.projet}}</span></p>
                </div>
                <div class="col-md-6">
                    <p class="mt-2 mb-0">
                        <b>Etat:</b>
                        <span class="ml-2 badge bg-{{bulletin?.etat == 'Validé' ? 'nuetral-success text-success' : 'nuetral-warning text-warning'}}">
                            {{bulletin?.etat}}
                        </span>
                    </p>
                    <hr class="my-2">
                    <p class="m-0"><b>Créer par:</b> <span class="ml-2">{{bulletin?.creerPar}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de création:</b> <span class="ml-2">{{bulletin?.dateCreation}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Modifier par:</b> <span class="ml-2">{{bulletin?.modifierPar}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de modification: </b> <span class="ml-2">{{bulletin?.dateModification}}</span></p>
                </div>
            </div>
            <div class="mt-4">
                <div class="mt-2 mb-3">
                    <b>Description:</b><br>
                    <textarea class="form-control" rows="4" disabled>{{bulletin?.description}}</textarea>
                </div>
                <div class="mt-2">
                    <b>Description et action:</b><br>
                    <textarea class="form-control" rows="4" disabled>{{bulletin?.descriptionAction}}</textarea>
                </div>
            </div>

            <hr class="mt-4 mb-0">
            <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Liste des chantiers concernés</h4></div>
            <div class="table-responsive m-0">
                <table class="table table-hover table-alternate m-0">
                    <thead class="font-weight-bold">
                        <tr>
                            <th>Libellé</th>
                            <th>Date début</th>
                            <th>Date fin</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of bulletin?.chantier">
                            <td>{{item.libelle}}</td>
                            <td>{{item.dateDebut}}</td>
                            <td>{{item.dateFin}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-4" *ngFor="let item of bulletin?.photo">
                    <div class="my-2">
                        <a class="card-img-wrapper shadow-sm rounded-sm mr-2" style="height: 250px;">
                            <div class="img-wrapper-overlay">
                                <div class="overlay-btn-wrapper">
                                    <button class="btn p-0 d-50 rounded-circle btn-outline-secondary border-2" type="button" tooltip="Agrandir">
                                        <i class="fas fa-expand"></i>
                                    </button>
                                    <button class="btn p-0 d-50 rounded-circle btn-outline-secondary border-2 ml-1" type="button" tooltip="Editer">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                </div>
                            </div>
                            <img src="{{item?.url}}" alt="..." class="card-img-top rounded-sm" style="height: 250px;">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="m-0">
    <div class="text-right mx-3 my-2">
        <button [popover]="partageVisite" class="btn btn-transition-none btn-dark mr-2" [outsideClick]="true" container="body" containerClass="popover-custom-wrapper popover-custom-lg" placement="auto" type="button">
            <i class="fas fa-share-alt mr-2"></i>Partager
        </button>
        <button class="btn btn-warning mr-2" type="button"><i class="ion-md-print mr-1"></i>Imprimer</button>
        <button class="btn btn-danger" type="button"><i class="ion-md-trash mr-1"></i>Supprimer</button>
    </div>
</div>

<!-- Partage -->
<ng-template #partageVisite>
    <div class="p-3 text-center bg-secondary">
        <h4 class="font-size-xl font-weight-bold mb-2">Partage de visite</h4>
        <p class="opacity-8 mb-0">Choisissez ceux a qui vous voulez envoyer</p>
    </div>
    <hr class="m-0">
    <div class="m-3">
        <input type="search" class="form-control" placeholder="Recherche dans la liste">
    </div>
    <perfect-scrollbar [autoPropagation]="true" class="scroll-area scroll-area-sm" style="height: 25em;">
        <div class="px-3" style="width: 25em;">
            <div class="border rounded-sm p-3 mb-2">
                <div class="d-flex justify-content-between">
                    <div class="align-box-row">
                        <div class="avatar-icon-wrapper avatar-icon-md">
                            <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                        </div>
                        <div class="pl-2">
                            <span class="pb-1 d-block">John Doe</span>
                            <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="custom-checkbox custom-control mt-1">
                            <input class="custom-control-input" id="inputCheck1" type="checkbox" checked>
                            <label class="custom-control-label" for="inputCheck1">&nbsp;</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border rounded-sm p-3 mb-2">
                <div class="d-flex justify-content-between">
                    <div class="align-box-row">
                        <div class="avatar-icon-wrapper avatar-icon-md">
                            <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                        </div>
                        <div class="pl-2">
                            <span class="pb-1 d-block">John Doe</span>
                            <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="custom-checkbox custom-control mt-1">
                            <input class="custom-control-input" id="inputCheck2" type="checkbox" checked>
                            <label class="custom-control-label" for="inputCheck2">&nbsp;</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border rounded-sm p-3 mb-2">
                <div class="d-flex justify-content-between">
                    <div class="align-box-row">
                        <div class="avatar-icon-wrapper avatar-icon-md">
                            <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                        </div>
                        <div class="pl-2">
                            <span class="pb-1 d-block">John Doe</span>
                            <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="custom-checkbox custom-control mt-1">
                            <input class="custom-control-input" id="inputCheck3" type="checkbox" checked>
                            <label class="custom-control-label" for="inputCheck3">&nbsp;</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border rounded-sm p-3 mb-2">
                <div class="d-flex justify-content-between">
                    <div class="align-box-row">
                        <div class="avatar-icon-wrapper avatar-icon-md">
                            <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                        </div>
                        <div class="pl-2">
                            <span class="pb-1 d-block">John Doe</span>
                            <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="custom-checkbox custom-control mt-1">
                            <input class="custom-control-input" id="inputCheck4" type="checkbox" checked>
                            <label class="custom-control-label" for="inputCheck4">&nbsp;</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border rounded-sm p-3 mb-2">
                <div class="d-flex justify-content-between">
                    <div class="align-box-row">
                        <div class="avatar-icon-wrapper avatar-icon-md">
                            <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                        </div>
                        <div class="pl-2">
                            <span class="pb-1 d-block">John Doe</span>
                            <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="custom-checkbox custom-control mt-1">
                            <input class="custom-control-input" id="inputCheck5" type="checkbox" checked>
                            <label class="custom-control-label" for="inputCheck5">&nbsp;</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </perfect-scrollbar>
    <hr class="m-0">
    <div class="p-3 text-center bg-secondary">
        <button class="btn btn-first"><i class="fas fa-paper-plane"></i> Envoyer</button>
    </div>
</ng-template>