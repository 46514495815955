import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntervenantListComponent } from './intervenant-list/intervenant-list.component';
import { IntervenantPagesComponent } from './intervenant-pages.component';


const routes: Routes = [
  {
    path:'',
    component: IntervenantPagesComponent,
    children: [
      {
        path: "liste",
        component: IntervenantListComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntervenantPagesRoutingModule { }
