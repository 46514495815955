import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilOptions } from 'src/app/shared-components/util/util-option';

@Component({
  selector: 'app-fichier-edit',
  templateUrl: './fichier-edit.component.html',
  styleUrls: ['./fichier-edit.component.scss']
})
export class FichierEditComponent implements OnInit {
  form: FormGroup;
  submit = false;
  required = UtilOptions.required;

  constructor(
    private formBuild: FormBuilder,
  ) {
    this.newForm();
  }

  ngOnInit() {
  }
  // Formulaire de creation de projet
  newForm() {
    this.form = this.formBuild.group({
      numero: [null, [Validators.required]],
      libelle: [null, [Validators.required]],
      categorie: [null, [Validators.required]],
      numDoc: [null, [Validators.required]],
      version: [null, [Validators.required]] ,
      mode: [null, [Validators.required]],
      dateTransmission: [null, [Validators.required]],
      document: [null, [Validators.required]],
      chantier: [null, [Validators.required]],
      description: [null, [Validators.required]],
    });
  }

  onSubmit(){}

  get f() { return this.form.controls; }

}
