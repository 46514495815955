import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FournisseurPages } from 'src/app/models/fournisseur-pages';
import { FournisseurPagesService } from 'src/app/services/fournisseur-pages/fournisseur-pages.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-fournisseur-detail',
  templateUrl: './fournisseur-detail.component.html',
  styleUrls: ['./fournisseur-detail.component.scss']
})
export class FournisseurDetailComponent implements OnInit {
  fournisseur: FournisseurPages = <FournisseurPages>{};
  rotate: boolean = true;

  value:any;
  list: any;
  toggleMobileSidebar1: boolean = false;
  toggleMobileSidebar2: boolean = false;
  inputTrx: boolean = false;
  addedProject: boolean = false;
  status1 = false;

  docs = [
    'Document 1',
    'Document 2',
    'Document 3',
    'Document 4',
    'Document 5',
    'Document 6',
    'Document 7',
    'Document 8',
  ]
  
  constructor(
    public globals: ThemeOptions,
    private route: ActivatedRoute,
    private fournisseurService: FournisseurPagesService,
  ) {
    this.fournisseur = this.fournisseurService.getFournisseur();
    // console.log(this.projet)
    const id: number = +this.route.snapshot.paramMap.get('id');
    this.fournisseurService.getList().subscribe((fournisseurs: FournisseurPages[]) => {
      this.fournisseur = fournisseurs.find(fournisseur => fournisseur.id == id);
    });
  }

  ngOnInit() { }

  addProject() {
    this.addedProject = true;
  }
  cancelAdd() {
    this.addedProject = false;
  }

}
