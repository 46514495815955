import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UtilisateurDetailComponent } from './utilisateur-detail/utilisateur-detail.component';
import { UtilisateurListComponent } from './utilisateur-list/utilisateur-list.component';
import { UtilisateurPagesComponent } from './utilisateur-pages.component';


const routes: Routes = [
  {
    path:'',
    component: UtilisateurPagesComponent,
    children: [
      {
        path: "liste",
        component: UtilisateurListComponent
      },
      {
        path: "detail/:id",
        component: UtilisateurDetailComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UtilisateurPagesRoutingModule { }
