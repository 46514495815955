import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { Utilisateur } from '../utilisateur-pages/utilisateur.model';

interface TypeData {
    id?: string;
    nom?: string;
    auteur?: Utilisateur;
    utilisateur?: Array<Utilisateur>;
};

interface ChefData {
  id?: string,
  nom?: string,
  fonction?: string,
  contact?: string,
  email?: string,
  utilisateur?: Array<Utilisateur>,
}

interface MembreData {
  id?: string,
  nom?: string,
  fonction?: string,
  contact?: string,
  email?: string,
  utilisateur?: Array<Utilisateur>,
}

interface ProjetData {
    id?: any;
    numero?: string;
    libelle?: string;
    lieu?: string;
    pays?: string;
    ville?: string;
    avatar?: string;
    photo?: string;
    marche?: number;
    dateFin?: Date;
    dateDebut?: Date;
    commune?: string;
    quartier?: string;
    latitude?: number;
    modified?: string;
    longitude?: number;
    published?: string;
    auteur?: Utilisateur,
    description?: string;
    type?: object | string;
    caisse?: object | string;
    banque?: object | string;
    chantiers?: object | string;
    chefProjet?: object | string;
    architecte?: object | string;
    opportunite?: object | string;
    membreProjet?: object | string;
    maitreOuvrage?: object | string;
    responsableEtude?: object | string;
    responsableControle?: object | string;
}

@Injectable({
  providedIn: 'root'
})
export class ProjetService {
    private assignData;
    apiUrl = environment.apiUrl;
    prefix = environment.prefix;

    constructor(private http:HttpClient) { }
    
    setProjet(projet) {
        this.assignData = projet;
    }

    getProjet() {
        return this.assignData;
    }

    create(data:ProjetData): Observable<any> {
        return this.http.post(`${this.apiUrl}/projets`,JSON.stringify(data)).pipe(
            take(1),
            tap( res => {return res}),
            catchError(this.handleError)
        );   
    }

    createTypeProjet(data:TypeData): Observable<any> {
        return this.http.post(`${this.apiUrl}/type_projets`,JSON.stringify(data)).pipe(
            take(1),
            tap( res => {return res}),
            catchError(this.handleError)
        );   
    }

    createChefProjet(data:TypeData): Observable<any> {
        return this.http.post(`${this.apiUrl}/chef_projets`,JSON.stringify(data)).pipe(
            take(1),
            tap( res => {return res}),
            catchError(this.handleError)
        );   
    }

    createMembre(data:MembreData): Observable<any> {
        return this.http.post(`${this.apiUrl}/membre_projets`,JSON.stringify(data)).pipe(
            take(1),
            tap( res => {return res}),
            catchError(this.handleError)
        );   
    }

    update(id: number, data:ProjetData): Observable<any> {
        return this.http.post(`${this.apiUrl}/projets/${id}`,JSON.stringify(data)).pipe(
            take(1),
            tap( res => {return res}),
            catchError(this.handleError)
        );   
    }
    
    getList(): Observable<any>{
        return this.http.get<[ProjetData]>(`${this.apiUrl}/projets`,{ responseType: 'json' }).pipe(
            map((response: any) => response['hydra:member']),
            catchError(this.handleError)
        )
    }
    
    getTypeProjet(): Observable<any>{
        return this.http.get<[TypeData]>(`${this.apiUrl}/type_projets`,{ responseType: 'json' }).pipe(
            map((response: any) => response['hydra:member']),
            catchError(this.handleError)
        )
    }
    
    getChefProjet(): Observable<any>{
        return this.http.get<[ChefData]>(`${this.apiUrl}/chef_projets`,{ responseType: 'json' }).pipe(
            map((response: any) => response['hydra:member']),
            catchError(this.handleError)
        )
    }
    
    getMembre(): Observable<any>{
        return this.http.get<[MembreData]>(`${this.apiUrl}/membre_projets`,{ responseType: 'json' }).pipe(
            map((response: any) => response['hydra:member']),
            catchError(this.handleError)
        )
    }

    onDelete(id: number): Observable<ProjetData> {
        return this.http.delete(`${this.apiUrl}/projets/${id}`,{responseType: 'json'}).pipe(
            take(1),
            switchMap( res =>{return res['hydra:member'];}),
            catchError(this.handleError)
        )
    }
    
    handleError(errorMessage: HttpErrorResponse) {
        let messageError = "Une erreur est survenue";
        return throwError(messageError);
    }

}

