<div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <aw-wizard class="wizard">

            <!-- ETAPE 1 -->
            <aw-wizard-step stepTitle="Ajout de travaux" [navigationSymbol]="{ symbol: '1' }">
                <div class="p-4">
                    <h5 class="font-size-xl font-weight-bold">Informations sur le travaux</h5>
                    <div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="travaux">Libellé travaux: </label>
                                    <input type="text" class="form-control" id="travaux" formControlName="travaux" placeholder="Libellé travaux">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="debutTravaux">Date début: </label>
                                    <div class="input-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                        </div>
                                        <input type="text" id="debutTravaux" formControlName="debutTravaux" class="form-control"
                                        [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                                        placeholder="Date début du travaux" title="Date début du travaux">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="finTravaux">Date fin: </label>
                                    <div class="input-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                        </div>
                                        <input type="text" id="finTravaux" formControlName="finTravaux" class="form-control"
                                        [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                                        placeholder="Date fin du travaux" title="Date fin du projet">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="responsable">Responsable des travaux:</label>
                                    <select id="responsable" formControlName="responsable" class="form-control">
                                        <option value="Zadi tobi">Zadi tobi</option>
                                        <option value="EJohn Doe">EJohn Doe</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="prestataire">Prestaire de service: </label>
                                    <select id="prestataire" formControlName="prestataire" class="form-control">
                                        <option value="Zadi tobi">Zadi tobi</option>
                                        <option value="EJohn Doe">EJohn Doe</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="soutraitance">Sous-traiter ?</label>
                                    <select id="soutraitance" formControlName="soutraitance" class="form-control">
                                        <option *ngFor="let sousTraiter of sousTraiterRow" [value]="sousTraiter.value">{{sousTraiter.label}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4"  *ngIf="f.soutraitance.value == 'oui'">
                                <div class="position-relative form-group">
                                    <label for="soutraitant">Sous-traitant:</label>
                                    <div class="input-group">
                                        <select id="soutraitant" formControlName="soutraitant" class="form-control">
                                            <option value="Zadi tobi">Zadi tobi</option>
                                            <option value="EJohn Doe">EJohn Doe</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-4"  *ngIf="f.soutraitance.value == 'oui'">
                                <div class="position-relative form-group">
                                    <label for="mtnSoutraitance">Montant sous-traitance: </label>
                                    <input type="number" class="form-control" id="mtnSoutraitance" formControlName="mtnSoutraitance" placeholder="Montant sous-traitance">
                                </div>
                            </div>
                            <div class="form-group col-md-{{f.soutraitance.value == 'oui' ? 4 : 12}}">
                                <div class="position-relative form-group">
                                    <label for="fichier">Joindre un fichier:</label>
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="fichier" formControlName="fichier">
                                        <label class="custom-file-label" for="fichier">Choisir un fichier</label>
                                      </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="description">Description</label>
                            <textarea id="description" formControlName="description" rows="3" class="form-control" placeholder="Description"></textarea>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="d-flex justify-content-between px-4 py-2">
                    <div></div>
                    <div class="text-right">
                        <button awNextStep class="btn btn-dark" type="button">Suivant <i class="fas fa-chevron-right ml-1"></i></button>
                    </div>
                </div>
            </aw-wizard-step>

            <!-- ETAPE 2 -->
            <aw-wizard-step awOptionalStep stepTitle="Ajout de tâche" [navigationSymbol]="{ symbol: '2' }">
                <div class="p-4">
                    <h5 class="font-size-xl font-weight-bold">Informations de la tâche</h5>
                    <div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="tache">Libellé tâche: </label>
                                    <input type="text" id="tache" formControlName="tache" list="listeTache" class="form-control" placeholder="Tâche"/>
                                    <datalist id="listeTache">
                                        <option value="Tache 1">
                                        <option value="Tache 2">
                                        <option value="Tache 3">
                                        <option value="Tache 4">
                                        <option value="Tache 5">
                                    </datalist>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="debutTache">Date début: </label>
                                    <div class="input-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                        </div>
                                        <input type="text" id="debutTache" formControlName="debutTache" class="form-control"
                                        [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                                        placeholder="Date début de la tâche" title="Date début">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="finTache">Date fin: </label>
                                    <div class="input-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                        </div>
                                        <input type="text" id="finTache" formControlName="finTache" class="form-control"
                                        [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                                        placeholder="Date fin de la tâche" title="Date fin">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="responsableTache">Responsable de tâche: </label>
                                    <input type="text" id="responsableTache" formControlName="responsableTache" list="responsableTaches" class="form-control" placeholder="Responsable de tâche"/>
                                    <datalist id="responsableTaches">
                                        <option value="Kouassi ghislain">
                                        <option value="Kone Yves">
                                        <option value="Kader mondesir ghislain">
                                    </datalist>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="dureTâche">Durée probable: </label>
                                    <input type="number" class="form-control" id="dureTâche" formControlName="dureTâche" placeholder="Durée de réalisation">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="descriptionTache">Description: </label>
                                    <textarea id="descriptionTache" formControlName="descriptionTache" class="form-control" rows="1" placeholder="Description de la tâche"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-12">
                                <div class="position-relative form-group">
                                    <label for="contactLies">Contacts liés</label>
                                    <ng-select [multiple]="true" id="contactLies" formControlName="contactLies">
                                        <ng-option value="Kouassi">Kouassi</ng-option>
                                        <ng-option value="Ghislain">Ghislain</ng-option>
                                        <ng-option value="Amond">Amond</ng-option>
                                        <ng-option value="Kader">Kader</ng-option>
                                        <ng-option value="Kouassi">Kouassi</ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="mb-0">
                            <hr>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <h5 class="font-weight-bold mb-0 mt-1 text-black">Information sur la ressource</h5>
                                    <small>Nombre de ligne: <span class="font-weigth-bold">{{dataArray.length}}</span></small>
                                </div>
                            </div>
                            <div *ngFor="let object of dataArray; let i = index">
                                <div class="d-flex">
                                    <select class="form-control mr-1" formControlName="typeRessource">
                                        <option selected disabled>Type</option>
                                        <option value="materiaux">Matériaux</option>
                                        <option value="materiel">Matériel</option>
                                        <option value="services">Services</option>
                                        <option value="fourniture">Fourniture</option>
                                        <option value="technique">Technique</option>
                                    </select>
                                    <select class="form-control mr-1" formControlName="ressource">
                                        <option selected disabled>Libellé</option>
                                        <option value="ressource 1">Ressource 1</option>
                                        <option value="ressource 2">Ressource 2</option>
                                        <option value="ressource 3">Ressource 3</option>
                                        <option value="ressource 4">Ressource 4</option>
                                        <option value="ressource 5">Ressource 5</option>
                                    </select>
                                    <input type="text" class="form-control mr-1" formControlName="quantite" placeholder="Quantité">
                                    <input type="text" class="form-control mr-1" formControlName="unite" placeholder="Unité">
                                    <input type="number" min="0" class="form-control mr-1" formControlName="prix" placeholder="Prix">
                                    <select class="form-control mr-1" formControlName="priorite">
                                        <option value="faible" selected>Faible</option>
                                        <option value="normal">Normal</option>
                                        <option value="urgente">Urgente</option>
                                    </select>
                                    
                                    <button class="btn btn-success m-0" type="button" tooltip="Ajouter une ligne" (click)="addForm()" *ngIf="i==0"><i class="ion-md-add"></i></button>
                                    <button class="btn btn-danger" tooltip="Supprimer cette ligne" type="button" (click)="removeForm(i)" *ngIf="i!=0"><i class="ion-md-trash"></i></button>
                                </div>
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="d-flex justify-content-between px-4 py-2">
                    <div>
                        <button awPreviousStep class="btn btn-dark" type="button"><i class="fas fa-chevron-left mr-1"></i> Precedent</button>
                    </div>
                    <div class="text-right">
                        <button class="btn btn-first mr-2" type="button" (click)="form.reset()"><i class="fas fa-broom mr-1"></i> Nettoyer</button>
                        <button  class="btn btn-success" type="submit"><i class="fas fa-save mr-2"></i> Enregistrer</button>
                    </div>
                </div>
            </aw-wizard-step>
        </aw-wizard>
    </form>
</div>