<!-- TOUT LES LES MOTS "S##1" JUSQU'A S##.... SONTDES REFERENCE DE RECHARCHE DANS LE CODE ILS NE SERVENT A RIEN -->
<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-glasses display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1 class="font-weight-bold m-0">Détail visite</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description m-0" title="Référence et libellé de la visite">
                    <span><i class="fas fa-file-alt"></i> ( VI0001 )</span>
                    <span class="ml-1">Visite de chantier 1</span>
                </div>
                <p class="m-0" title="Date de création de la visite">
                    <i class="fas fa-calendar-alt"></i>
                    <span class="ml-1">Créer le: 11/11/2021 11:11:11</span>
                </p>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button [routerLink]="['/pages/projet/detail/5']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<aw-wizard class="wizard" navBarLocation="top">
    <!-- ETAPE 1: FICHE DE VISITE -->
    <aw-wizard-step stepTitle="Fiche de visite" [navigationSymbol]="{ symbol: '1' }">
        <div class="bg-white mt-3 rounded-sm shadow-sm">
            <div class="header px-3 py-2 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div class="title font-weight-bold"><i class="fas fa-file-alt mt-3 mr-1"></i> Detail de la visite</div>
                    <div class="action">
                        <button (click)="partageVisite.show()" class="btn btn-transition-none btn-dark mr-2" placement="auto" type="button">
                            <i class="fas fa-share-alt mr-1"></i>Partager
                        </button>
                        <button class="btn btn-success" tooltip="Creer la visite" (click)="visiteModal.show()">
                            <i class="fas fa-plus-circle mr-1"></i>Ajouter
                        </button>
                    </div>
                </div>
            </div>
            <hr class="m-0">
            <div class="body p-3">
                <div class="border rounded p-3">
                    <hr class="m-0">
                    <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Information générales</h4></div>
                    <div class="row">
                        <div class="col-md-6">
                            <p class="mt-2 mb-0"><b>Nom:</b> <span class="ml-2">Visite de chantier 1</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date de la visite:</b> <span class="ml-2">31/07/2021</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date de la prochaine réunion:</b> <span class="ml-2">31/07/2021 11:11:11</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Phase du proje:</b> <span class="ml-2">En cours</span></p>
                        </div>
                        <div class="col-md-6">
                            <p class="mt-2 mb-0"><b>Créer par:</b> <span class="ml-2">Kouassi ghislain</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date de création:</b> <span class="ml-2">31/07/2021</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Modifier par:</b> <span class="ml-2">Kouassi ghislain</span></p>
                            <hr class="my-2">
                            <p class="m-0"><b>Date de modification: </b> <span class="ml-2">31/07/2021</span></p>
                        </div>
                    </div>

                    <hr class="mt-2 mb-5">
                    <hr class="m-0">
                    <div class="p-2 bg-secondary">
                        <h4 class="m-0 font-weight-bold text-first">Intervenants associés</h4>
                    </div>
                    <div class="table-responsive m-0">
                        <table class="table m-0">
                            <thead class="bg-white">
                                <tr>
                                    <th class="bg-white">Intervenant</th>
                                    <th class="bg-white">Nom et prenom</th>
                                    <th class="bg-white">Fonction</th>
                                    <th class="bg-white">Contact</th>
                                    <th class="bg-white">Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div class="avatar-icon-wrapper mr-2">
                                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                            </div>
                                            <div>
                                                <a class="font-weight-bold text-black" title="...">Kouassi ghislain</a>
                                                <span class="text-black-50 d-block">Ingenieur en batiment</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Kouassi ghislain</td>
                                    <td>Ingenieur en batiment</td>
                                    <td>00 00 00 00 00</td>
                                    <td>exemple@gmail.com</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div class="avatar-icon-wrapper mr-2">
                                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                            </div>
                                            <div>
                                                <a class="font-weight-bold text-black" title="...">Kouassi ghislain</a>
                                                <span class="text-black-50 d-block">Ingenieur en batiment</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Kouassi ghislain</td>
                                    <td>Ingenieur en batiment</td>
                                    <td>00 00 00 00 00</td>
                                    <td>exemple@gmail.com</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div class="avatar-icon-wrapper mr-2">
                                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                            </div>
                                            <div>
                                                <a class="font-weight-bold text-black" title="...">Kouassi ghislain</a>
                                                <span class="text-black-50 d-block">Ingenieur en batiment</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Kouassi ghislain</td>
                                    <td>Ingenieur en batiment</td>
                                    <td>00 00 00 00 00</td>
                                    <td>exemple@gmail.com</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <hr class="mt-0 mb-5">
                    <hr class="m-0">
                    <div class="p-2 bg-secondary">
                        <h4 class="m-0 font-weight-bold text-first">Lots de travaux associés</h4>
                    </div>
                    <hr class="m-0">
                    <div class="list-group list-group-flush">
                        <li class="list-group-item list-group-item-action align-box-row">
                            <fa-icon [icon]="['far', 'folder']" class="font-size-lg mr-3"></fa-icon>
                            <div>
                                <div>Travaux 1</div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <fa-icon [icon]="['far', 'folder-open']" class="font-size-lg mr-3"></fa-icon>
                            <div>
                                <div>Travaux 2</div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                            <div>
                                <div>Travaux 2.1</div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                            <div>
                                <div>Travaux 2.2</div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                            <div>
                                <div>Travaux 2.3</div>
                            </div>
                        </li>
                        
                        <li class="list-group-item list-group-item-action align-box-row">
                            <fa-icon [icon]="['far', 'folder']" class="font-size-lg mr-3"></fa-icon>                            <div>
                                <div>Travaux 3</div>
                            </div>
                        </li>
                    </div>
                </div>
            </div>
            <hr class="m-0">
            <div class="footer px-3 py-2 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div></div>
                    <div>
                        <button class="btn btn-info mr-2"><i class="fas fa-pen mr-1"></i>Modifier</button>
                        <button class="btn btn-warning mr-2"><i class="ion-md-print mr-1"></i>Imprimer</button>
                        <button awNextStep class="btn btn-dark" type="button">Suivant <i class="fas fa-chevron-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </aw-wizard-step>

    <!-- ETAPE 2: REMARQUE -->
    <aw-wizard-step awOptionalStep stepTitle="Remarque" [navigationSymbol]="{ symbol: '2' }">
        <div class="bg-white mt-3 rounded-sm shadow-sm">
            <div class="header px-3 py-2 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div class="title font-weight-bold"><i class="fas fa-file-signature mt-3 mr-1"></i> Liste des remarques</div>
                    <div class="action">
                        <button class="btn btn-success" tooltip="Ajouter une nouvelle remarque" (click)="newRemarqueModal.show()">
                            <i class="fas fa-plus-circle mr-1"></i>Ajouter
                        </button>
                    </div>
                </div>
            </div>
            <hr class="m-0">
            <div class="body p-3">
                <app-remarque-list></app-remarque-list>
            </div>
            <hr class="m-0">
            <div class="footer px-3 py-2 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div>
                        <button awPreviousStep class="btn btn-dark" type="button"><i class="fas fa-chevron-left"></i> Précédent</button>
                    </div>
                    <div class="text-right">
                        <button class="btn btn-warning mr-2"><i class="ion-md-print mr-1"></i>Imprimer</button>
                        <button awNextStep class="btn btn-dark" type="button">Suivant <i class="fas fa-chevron-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </aw-wizard-step>

    <!-- ETAPE 3: LISTE DE PRESENCE -->
    <aw-wizard-step awOptionalStep stepTitle="Liste de présence" [navigationSymbol]="{ symbol: '3' }">
        <div class="bg-white mt-3 rounded-sm shadow-sm">
            <div class="header px-3 py-2 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div class="title font-weight-bold"><i class="fas fa-user-check mt-3 mr-1"></i> Liste des intervenants selectionner pour le visite</div>
                    <div class="action">
                        <button [popover]="autreIntervenant" class="btn btn-transition-none btn-success" [outsideClick]="true" container="body" containerClass="popover-custom-wrapper popover-custom-lg" placement="auto" type="button">
                            <i class="fas fa-plus-circle"></i> Ajouter
                        </button>
                    </div>
                </div>
            </div>
            <hr class="m-0">
            <div class="body p-3">
                <table class="table table-alternate table-hover m-0">
                    <thead>
                        <tr>
                            <th>Intervenant</th>
                            <th class="text-center"><span class="font-weight-bold text-success">P</span>résent</th>
                            <th class="text-center"><span class="font-weight-bold text-danger">A</span>bsent</th>
                            <th class="text-center"><span class="font-weight-bold text-first">E</span>xcusé</th>
                            <th class="text-center"><span class="font-weight-bold text-warning">N</span>on excusé</th>
                            <th class="text-right">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="d-flex align-items-center">
                                    <div class="avatar-icon-wrapper mr-2">
                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                                    </div>
                                    <div>
                                        <span class="font-weight-bold text-black" title="Shanelle Wynn">Shanelle Wynn</span>
                                        <span class="text-black-50 d-block">UI Engineer, Apple Inc.</span>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckboxA1" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckboxA1">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckboxA2" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckboxA2">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckboxA3" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckboxA3">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckboxA4" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckboxA4">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-right">
                                <button class="btn p-0 d-40 rounded-circle btn-first mr-1" type="button" tooltip="Signature" (click)="signatureModal.show()">
                                    <i class="fas fa-signature"></i>
                                </button>
                                <button class="btn p-0 d-40 rounded-circle btn-danger" type="button" tooltip="Retirer">
                                    <i class="ion-md-trash"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-items-center">
                                    <div class="avatar-icon-wrapper mr-2">
                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                                    </div>
                                    <div>
                                        <span class="font-weight-bold text-black" title="Shanelle Wynn">Shanelle Wynn</span>
                                        <span class="text-black-50 d-block">UI Engineer, Apple Inc.</span>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckboxB1" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckboxB1">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckboxB2" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckboxB2">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckboxB3" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckboxB3">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckboxB4" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckboxB4">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-right">
                                <button class="btn p-0 d-40 rounded-circle btn-first mr-1" type="button" tooltip="Signature" (click)="signatureModal.show()">
                                    <i class="fas fa-signature"></i>
                                </button>
                                <button class="btn p-0 d-40 rounded-circle btn-danger" type="button" tooltip="Retirer">
                                    <i class="ion-md-trash"></i>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-items-center">
                                    <div class="avatar-icon-wrapper mr-2">
                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                                    </div>
                                    <div>
                                        <span class="font-weight-bold text-black" title="Shanelle Wynn">Shanelle Wynn</span>
                                        <span class="text-black-50 d-block">UI Engineer, Apple Inc.</span>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckboxC1" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckboxC1">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckboxC2" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckboxC2">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckboxC3" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckboxC3">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="custom-checkbox custom-control">
                                    <input class="custom-control-input" id="inputCheckboxC4" type="checkbox">
                                    <label class="custom-control-label" for="inputCheckboxC4">&nbsp;</label>
                                </div>
                            </td>
                            <td class="text-right">
                                <button class="btn p-0 d-40 rounded-circle btn-first mr-1" type="button" tooltip="Signature" (click)="signatureModal.show()">
                                    <i class="fas fa-signature"></i>
                                </button>
                                <button class="btn p-0 d-40 rounded-circle btn-danger" type="button" tooltip="Retirer">
                                    <i class="ion-md-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr class="m-0">
            <div class="footer px-3 py-2 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div>
                        <button awPreviousStep class="btn btn-dark" type="button"><i class="fas fa-chevron-left"></i> Précédent</button>
                    </div>
                    <div class="text-right">
                        <button class="btn btn-warning mr-2"><i class="ion-md-print mr-1"></i>Imprimer</button>
                        <button awNextStep class="btn btn-dark" type="button">Suivant <i class="fas fa-chevron-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </aw-wizard-step>

    <!-- ETAPE 4: PROCES VERBAL DE VISITE -->
    <aw-wizard-step awOptionalStep stepTitle="PV de visite" [navigationSymbol]="{ symbol: '4' }">
        <div class="bg-white mt-3 rounded-sm shadow-sm">
            <div class="header px-3 py-2 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div class="title font-weight-bold"><i class="fas fa-file-alt mt-3 mr-1"></i> Proces verbal de visite</div>
                    <div class="text-right">
                        <button (click)="partageVisite.show()" class="btn btn-transition-none btn-dark" placement="auto" type="button">
                            <i class="fas fa-share-alt mr-1"></i>Partager
                        </button>
                        <button class="btn btn-warning"><i class="ion-md-print mr-1"></i>Générer</button>
                    </div>
                </div>
            </div>
            <hr class="m-0">
            <div class="body p-3">
                <div class="border rounded p-3">
                    <div class="d-flex justify-content-between mt-4">
                        <h3 class="mb-1">PV0001</h3>
                        <h3 class="mb-1 text-first">11/11/2021</h3>
                    </div>

                    <!-- Information générales -->
                    <div class="p-0 m-0">
                        <hr class="m-0">
                        <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Information générales</h4></div>
                        <div class="row">
                            <div class="col-md-6">
                                <p class="mt-2 mb-0"><b>PV numéro:</b> <span class="ml-2">1</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Référence:</b> <span class="ml-2">RP0001</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Etat:</b> <span class="ml-2 text-success">Validé</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Validateur:</b> <span class="ml-2">Kouassi ghislain</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Fonction validateur:</b> <span class="ml-2">Chef projet</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Projet:</b> <span class="ml-2">Projet 1</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Chantier:</b> <span class="ml-2">Chantier 1</span></p>
                                <hr class="my-2">
                                <p class="mb-0"><b>Nom de la visite:</b> <span class="ml-2">Visite de chantier 1</span></p>
                            </div>
                            <div class="col-md-6">
                                <p class="mt-2 mb-0"><b>Date de la visite:</b> <span class="ml-2 text-dark">31/07/2021</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Description:</b> <span class="ml-2">Une petite description</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Date de la prochaine réunion:</b> <span class="ml-2 text-dark">31/07/2021 11:11:11</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Phase du proje:</b> <span class="ml-2">En cours</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Créer par:</b> <span class="ml-2">Kouassi ghislain</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Date de création:</b> <span class="ml-2 text-dark">11/11/2021 11:11:11</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Modifier par:</b> <span class="ml-2">Kouassi ghislain</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Date de création:</b> <span class="ml-2 text-dark">11/11/2021 11:11:11</span></p>
                            </div>
                        </div>
                    </div>
                    <hr class="mt-2 mb-5">

                    <!-- Intervenants -->
                    <div class="p-0 m-0">
                        <hr class="m-0">
                        <div class="p-2 bg-secondary">
                            <h4 class="m-0 font-weight-bold text-first">Intervenants (2)</h4>
                        </div>
                        <div class="table-responsive m-0">
                            <table class="table m-0">
                                <thead class="bg-white">
                                    <tr>
                                        <th  class="bg-white">Intervenant</th>
                                        <th  class="bg-white">Type</th>
                                        <th  class="bg-white">Contact</th>
                                        <th  class="bg-white">Email</th>
                                        <th  class="bg-white">Présence</th>
                                        <th  class="bg-white">Signature</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="avatar-icon-wrapper mr-2">
                                                    <div class="avatar-icon">
                                                        <img alt="..." src="assets/images/avatars/avatar2.jpg"/>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span class="font-weight-bold text-black" title="Kouassi ghislain">Kouassi ghislain</span>
                                                    <span class="text-black-50 d-block">Chef chantier</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td><span class="font-weight-bold text-dark">Particulier</span></td>
                                        <td><span class="font-weight-bold">00 00 00 00 00</span></td>
                                        <td><span class="text-warning">exemple@gmail.com</span></td>
                                        <td><span class="font-weight-bold text-success">P</span></td>
                                        <td>
                                            <div>
                                                <img src="assets/img/signature.png" alt="" class="shadow-sm" style="height: 50px; width: 50px">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="avatar-icon-wrapper mr-2">
                                                    <div class="avatar-icon">
                                                        <img alt="..." src="assets/images/avatars/avatar2.jpg"/>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span class="font-weight-bold text-black" title="Kouassi ghislain">Kouassi ghislain</span>
                                                    <span class="text-black-50 d-block">Chef chantier</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td><span class="font-weight-bold text-dark">Particulier</span></td>
                                        <td><span class="font-weight-bold">00 00 00 00 00</span></td>
                                        <td><span class="text-warning">exemple@gmail.com</span></td>
                                        <td><span class="font-weight-bold text-danger">A</span></td>
                                        <td>
                                            <div>
                                                <img src="assets/img/signature.png" alt="" class="shadow-sm" style="height: 50px; width: 50px">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <hr class="mt-0 mb-5">

                    <!-- Travaux concernés -->
                    <div class="p-0 m-0">
                        <hr class="m-0">
                        <div class="p-2 bg-secondary">
                            <h4 class="m-0 font-weight-bold text-first">Travaux concernés (3)</h4>
                        </div>
                        <hr class="m-0">
                        <div class="list-group list-group-flush">
                            <li class="list-group-item list-group-item-action align-box-row">
                                <fa-icon [icon]="['far', 'folder']" class="font-size-lg mr-3"></fa-icon>
                                <div>
                                    <div>Travaux 1</div>
                                </div>
                            </li>
                            <li class="list-group-item list-group-item-action align-box-row">
                                <fa-icon [icon]="['far', 'folder-open']" class="font-size-lg mr-3"></fa-icon>
                                <div>
                                    <div>Travaux 2</div>
                                </div>
                            </li>
                            <li class="list-group-item list-group-item-action align-box-row">
                                <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                                <div>
                                    <div>Travaux 2.1</div>
                                </div>
                            </li>
                            <li class="list-group-item list-group-item-action align-box-row">
                                <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                                <div>
                                    <div>Travaux 2.2</div>
                                </div>
                            </li>
                            <li class="list-group-item list-group-item-action align-box-row">
                                <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                                <div>
                                    <div>Travaux 2.3</div>
                                </div>
                            </li>
                            
                            <li class="list-group-item list-group-item-action align-box-row">
                                <fa-icon [icon]="['far', 'folder']" class="font-size-lg mr-3"></fa-icon>                            <div>
                                    <div>Travaux 3</div>
                                </div>
                            </li>
                        </div>
                    </div>
                    <hr class="mt-0 mb-5">

                    <!-- Remarque lors de la visite -->
                    <div class="p-0 m-0">
                        <hr class="m-0">
                        <div class="p-2 bg-secondary">
                            <h4 class="m-0 font-weight-bold text-first">Remarque lors de la visite (2)</h4>
                        </div>
                        <hr class="m-0">
                        <div class="pt-2">
                            <div class="border rounded-sm p-2">
                                <div class="d-flex align-items-center mb-2">
                                    <div class="d-40 rounded-circle bg-success text-white mr-3 text-center">1</div>
                                    <div class="flex-grow-1">
                                        <div class="font-weight-bold text-success pb-1">Clôturer</div>
                                        <div class="text-dark"><fa-icon [icon]="['far', 'calendar-alt']"></fa-icon> Clôturé le: 11/11/2021 11:11:11</div>
                                    </div>
                                </div>
                                <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0"><b>Intervenant associé:</b> <span class="ml-2">Kouassi ghislain</span></p></div>
                                <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0"><b>Position sur plan:</b> <span class="ml-2">2</span></p></div>
                                <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0"><b>Images associées:</b> <span class="ml-2">1</span></p></div>
                                <div class="border rounded-sm p-2 text-center">
                                    <div class="grid-menu grid-menu-2col">
                                        <div class="no-gutters row">
                                            <div class="col-sm-6">
                                                <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                            <div class="col-sm-6">
                                                <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                            <div class="col-sm-6">
                                                <img src="assets/img/i2.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="my-2">
                            <div class="border rounded-sm p-2">
                                <div class="d-flex align-items-center mb-2">
                                    <div class="d-40 rounded-circle bg-success text-white mr-3 text-center">2</div>
                                    <div class="flex-grow-1">
                                        <div class="font-weight-bold text-warning pb-1">En attente</div>
                                        <div class="text-dark"><fa-icon [icon]="['far', 'calendar-alt']"></fa-icon> En attente depuis: 11/11/2021</div>
                                    </div>
                                </div>
                                <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0"><b>Intervenant associé:</b> <span class="ml-2">Kader mondesir</span></p></div>
                                <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0"><b>Position sur plan:</b> <span class="ml-2">1</span></p></div>
                                <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0"><b>Images associées:</b> <span class="ml-2">1</span></p></div>
                                <div class="border rounded-sm p-2 text-center">
                                    <div class="grid-menu grid-menu-2col">
                                        <div class="no-gutters row">
                                            <div class="col-sm-6">
                                                <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                            <div class="col-sm-6">
                                                <img src="assets/img/i2.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="mt-0 mb-5">

                    <!-- Annexe -->
                    <div class="p-0 m-0">
                        <hr class="m-0">
                        <div class="p-2 bg-secondary">
                            <h4 class="m-0 font-weight-bold text-first">Annexe</h4>
                        </div>
                        <hr class="m-0">
                        <div class="p-2 rounded-sm border mb-3">
                            <div class="p-0 m-0">
                                <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0 font-weight-bold">Images travaux 1</p></div>
                                <div class="p-2 border rounded-sm text-center">
                                    <div class="grid-menu grid-menu-2col">
                                        <div class="no-gutters row">
                                            <div class="col-sm-6">
                                                <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                            <div class="col-sm-6">
                                                <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                            <div class="col-sm-6">
                                                <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                            <div class="col-sm-6">
                                                <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="my-2">
                            <div class="p-0 m-0">
                                <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0 font-weight-bold">Images travaux 2</p></div>
                                <div class="p-2 border rounded-sm text-center">
                                    <div class="grid-menu grid-menu-2col">
                                        <div class="no-gutters row">
                                            <div class="col-sm-6">
                                                <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                            <div class="col-sm-6">
                                                <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                            <div class="col-sm-6">
                                                <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                            <div class="col-sm-6">
                                                <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="my-2">
                            <div class="p-0 m-0">
                                <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0 font-weight-bold">Images travaux 3</p></div>
                                <div class="p-2 border rounded-sm text-center">
                                    <div class="grid-menu grid-menu-2col">
                                        <div class="no-gutters row">
                                            <div class="col-sm-6">
                                                <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                            <div class="col-sm-6">
                                                <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                            <div class="col-sm-6">
                                                <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                            <div class="col-sm-6">
                                                <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="m-0">
            <div class="footer px-3 py-2 m-0">
                <div class="d-flex justify-content-between m-0">
                    <div>
                        <button awPreviousStep class="btn btn-dark" type="button"><i class="fas fa-chevron-left"></i> Précédent</button>
                    </div>
                    <div class="text-right">
                        <button class="btn btn-success mr-2"><i class="fas fa-check mr-1"></i>Valider</button>
                        <button class="btn btn-warning"><i class="ion-md-print mr-1"></i>Générer</button>
                    </div>
                </div>
            </div>
        </div>
    </aw-wizard-step>
</aw-wizard>



<!-- MODALS -->

<!-- Partage -->
<div #partageVisite="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded">
        <div class="modal-content rounded border-0">

            <div class="p-3 text-center bg-secondary rounded-top">
                <h4 class="font-size-xl font-weight-bold mb-2">Partage de visite</h4>
                <p class="opacity-8 mb-0">Choisissez ceux a qui vous voulez envoyer</p>
            </div>
            <hr class="m-0">
            <div class="m-3">
                <input type="search" class="form-control" placeholder="Recherche dans la liste">
            </div>
            <perfect-scrollbar [autoPropagation]="true" class="scroll-area scroll-area-sm" style="height: 25em;">
                <div class="px-3">
                    <div class="border rounded-sm p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="align-box-row">
                                <div class="avatar-icon-wrapper avatar-icon-md">
                                    <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="pl-2">
                                    <span class="pb-1 d-block">John Doe</span>
                                    <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="custom-checkbox custom-control mt-1">
                                    <input class="custom-control-input" id="inputCheck1" type="checkbox" checked>
                                    <label class="custom-control-label" for="inputCheck1">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border rounded-sm p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="align-box-row">
                                <div class="avatar-icon-wrapper avatar-icon-md">
                                    <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="pl-2">
                                    <span class="pb-1 d-block">John Doe</span>
                                    <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="custom-checkbox custom-control mt-1">
                                    <input class="custom-control-input" id="inputCheck2" type="checkbox" checked>
                                    <label class="custom-control-label" for="inputCheck2">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border rounded-sm p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="align-box-row">
                                <div class="avatar-icon-wrapper avatar-icon-md">
                                    <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="pl-2">
                                    <span class="pb-1 d-block">John Doe</span>
                                    <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="custom-checkbox custom-control mt-1">
                                    <input class="custom-control-input" id="inputCheck3" type="checkbox" checked>
                                    <label class="custom-control-label" for="inputCheck3">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border rounded-sm p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="align-box-row">
                                <div class="avatar-icon-wrapper avatar-icon-md">
                                    <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="pl-2">
                                    <span class="pb-1 d-block">John Doe</span>
                                    <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="custom-checkbox custom-control mt-1">
                                    <input class="custom-control-input" id="inputCheck4" type="checkbox" checked>
                                    <label class="custom-control-label" for="inputCheck4">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border rounded-sm p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="align-box-row">
                                <div class="avatar-icon-wrapper avatar-icon-md">
                                    <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="pl-2">
                                    <span class="pb-1 d-block">John Doe</span>
                                    <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="custom-checkbox custom-control mt-1">
                                    <input class="custom-control-input" id="inputCheck5" type="checkbox" checked>
                                    <label class="custom-control-label" for="inputCheck5">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </perfect-scrollbar>
            <hr class="m-0">
            <div class="p-3 text-center bg-secondary rounded-bottom">
                <button class="btn btn-first"><i class="fas fa-paper-plane"></i> Envoyer</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal de création de visite de chantier -->
<div #visiteModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Formulaire de création visite chantier</h6>
                <button type="button" class="close" aria-label="Close" (click)="visiteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-visite-edit></app-visite-edit>
            </div>
        </div>
    </div>
</div>

<!-- Modal d'ajout de remarque -->
<div #newRemarqueModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Formulaire d'ajout de remarque</h6>
                <button type="button" class="close" aria-label="Close" (click)="newRemarqueModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div>
                    <app-remarque-edit></app-remarque-edit>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal signature -->
<div #signatureModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header text-dark rounded-top">
                <h6 class="modal-title">Signature <b>Signer en dessous <i class="fas fa-arrow-down text-first"></i></b></h6>
                <button type="button" class="close" aria-label="Close" (click)="signatureModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div>
                    <app-signature></app-signature>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Autre intervenant -->
<ng-template #autreIntervenant>
    <div class="bg-composed-wrapper bg-midnight-bloom mx-3 mt-3 border-0 text-center rounded-sm">
        <div class="bg-composed-img-3 bg-composed-wrapper--image"></div>
        <div class="bg-composed-wrapper--content text-light px-2 py-4">
            <h4 class="font-size-xl font-weight-bold mb-2">Autres intervenants</h4>
            <p class="opacity-8 mb-0">Choisissez ou cliquez sur créer pour en ajouter</p>
        </div>
    </div>
    <tabset [justified]="true" class="tabs-animated tabs-animated-shadow tabs-bordered" type="tabs">
        <tab>
            <ng-template tabHeading><span><i class="fas fa-check-square"></i> Choisir</span></ng-template>
            <div class="m-3">
                <input type="search" class="form-control" placeholder="Recherche dans la liste">
            </div>
            <perfect-scrollbar [autoPropagation]="true" class="scroll-area scroll-area-sm" style="height: 27em;">
                <div class="px-3" style="width: 25em;">
                    <div class="border rounded-sm p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="align-box-row">
                                <div class="avatar-icon-wrapper avatar-icon-md">
                                    <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="pl-2">
                                    <span class="pb-1 d-block">John Doe</span>
                                    <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="custom-checkbox custom-control mt-1">
                                    <input class="custom-control-input" id="inputCheck1" type="checkbox">
                                    <label class="custom-control-label" for="inputCheck1">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border rounded-sm p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="align-box-row">
                                <div class="avatar-icon-wrapper avatar-icon-md">
                                    <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="pl-2">
                                    <span class="pb-1 d-block">John Doe</span>
                                    <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="custom-checkbox custom-control mt-1">
                                    <input class="custom-control-input" id="inputCheck2" type="checkbox">
                                    <label class="custom-control-label" for="inputCheck2">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border rounded-sm p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="align-box-row">
                                <div class="avatar-icon-wrapper avatar-icon-md">
                                    <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="pl-2">
                                    <span class="pb-1 d-block">John Doe</span>
                                    <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="custom-checkbox custom-control mt-1">
                                    <input class="custom-control-input" id="inputCheck3" type="checkbox">
                                    <label class="custom-control-label" for="inputCheck3">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border rounded-sm p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="align-box-row">
                                <div class="avatar-icon-wrapper avatar-icon-md">
                                    <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="pl-2">
                                    <span class="pb-1 d-block">John Doe</span>
                                    <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="custom-checkbox custom-control mt-1">
                                    <input class="custom-control-input" id="inputCheck4" type="checkbox">
                                    <label class="custom-control-label" for="inputCheck4">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border rounded-sm p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="align-box-row">
                                <div class="avatar-icon-wrapper avatar-icon-md">
                                    <div class="avatar-icon rounded-circle"><img alt="..."src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="pl-2">
                                    <span class="pb-1 d-block">John Doe</span>
                                    <small class="pb-1 text-black-50 d-block">Chef de projet</small>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="custom-checkbox custom-control mt-1">
                                    <input class="custom-control-input" id="inputCheck5" type="checkbox">
                                    <label class="custom-control-label" for="inputCheck5">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </perfect-scrollbar>
            <hr class="m-0">
            <div class="p-3 text-center bg-secondary">
                <button class="btn btn-first"><i class="fas fa-check-circle"></i> Valider</button>
            </div>
        </tab>
        <tab>
            <ng-template tabHeading><span><i class="fas fa-plus-circle"></i> Créer</span></ng-template>
            <app-intervenant-edit></app-intervenant-edit>
        </tab>
    </tabset>
</ng-template>
<!-- FIN MODALS -->