import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Utilisateur } from '../../utilisateur-pages/utilisateur.model';

interface ArchitectData {
    id?: string;
    nom?: string;
    fonction?: string;
    contact?: string;
    email?: string;
    utilisateur?: Array<Utilisateur>;
};

@Injectable({
  providedIn: 'root'
})
export class ArchitectService {
    private assignData;
    apiUrl = environment.apiUrl;
    prefix = environment.prefix;

    constructor(private http:HttpClient) { }
    
    setArchitect(architect) {
        this.assignData = architect;
    }

    getArchitect() {
        return this.assignData;
    }

    create(data:ArchitectData): Observable<any> {
        return this.http.post(`${this.apiUrl}/architectes`,JSON.stringify(data)).pipe(
            take(1),
            tap( res => {return res}),
            catchError(this.handleError)
        );   
    }

    update(id: number, data:ArchitectData): Observable<any> {
        return this.http.post(`${this.apiUrl}/architectes/${id}`,JSON.stringify(data)).pipe(
            take(1),
            tap( res => {return res}),
            catchError(this.handleError)
        );   
    }
    
    getList(): Observable<any>{
        return this.http.get<[ArchitectData]>(`${this.apiUrl}/architectes`,{ responseType: 'json' }).pipe(
            map((response: any) => response['hydra:member']),
            catchError(this.handleError)
        )
    }

    onDelete(id: number): Observable<ArchitectData> {
        return this.http.delete(`${this.apiUrl}/architectes/${id}`,{responseType: 'json'}).pipe(
            take(1),
            switchMap( res =>{return res['hydra:member'];}),
            catchError(this.handleError)
        )
    }
    
    handleError(errorMessage: HttpErrorResponse) {
        let messageError = "Une erreur est survenue";
        return throwError(messageError);
    }
}

