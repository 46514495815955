import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReunionPages } from 'src/app/models/reunion-pages';

@Injectable({
  providedIn: 'root'
})
export class ReunionPagesService {
  reunion: ReunionPages;
  private readonly url = "api/reunion.json";

  constructor(
    private http: HttpClient
  ) { }

  setReunion(reunion: ReunionPages) {
    this.reunion = reunion
  }

  getReunion(): ReunionPages {
    return this.reunion
  }

  getList(): Observable<ReunionPages[]> {
    return this.http.get<ReunionPages[]>(this.url).pipe();
  }
}
