<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-wallet display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>Budgets</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">Liste des budget</div>
            </div>
        </div>
    </div>
</div>

<div class="card card-box border-0">
    <div class="card-header p-3">
        <div class="card-header--title font-weight-bold">Prevision Budgétaire</div>
        <div class="card-header--actions">
            <div class="btn-group" dropdown>
                <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret" dropdownToggle tooltip="Action" type="button">
                    <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                </button>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                    <ul class="nav nav-pills flex-column p-3">
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0">
        <div class="d-flex justify-content-between p-3">
            <div class="search-wrapper">
                <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                <input class="form-control" placeholder="Recherche dans la liste" type="search">
            </div>
        </div>
        <div class="table-responsive m-0">
            <table class="table table-hover text-nowrap m-0">
                <thead>
                    <tr>
                        <th></th>
                        <th>Projet</th>
                        <th>Budget proposé</th>
                        <th>Budget validé</th>
                        <th>Depense réaliséés</th>
                        <th>Ecart budget</th>
                        <th>Etat</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let budget of budgets; let i = index">
                        <td data-toggle="collapse" attr.data-target="#row{{i}}" class="accordion-toggle">
                            <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                        </td>
                        <td>{{budget.projet}}</td>
                        <td><span class="font-weight-bold text-dark">{{budget.mtnPropose | number:'': 'fr-FR'}}</span></td>
                        <td><span class="font-weight-bold text-success">{{budget.mtnValide | number:'': 'fr-FR'}}</span></td>
                        <td><span class="font-weight-bold text-warning">{{budget.depense | number:'': 'fr-FR'}}</span></td>
                        <td><span class="font-weight-bold text-warning">{{budget.ecart | number:'': 'fr-FR'}}</span></td>
                        <td>
                            <span class="badge bg-neutral-{{budget.etat == 'validé' ? 'success text-success' : 'warning text-warning'}}">{{budget.etat}}</span>
                        </td>
                        <td>
                            <button placement="auto" tooltip="Plus d'infos"
                                class="btn d-40 p-0 btn-pill mr-1 btn-primary"
                                type="button" [routerLink]="['/pages/budget/detail',budget?.id]">
                                <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                            </button>
                            <button placement="auto" tooltip="Modifier"
                                class="btn d-40 p-0 btn-pill mr-1 btn-info"
                                type="button" *ngIf="budget.etat != 'validé'">
                                <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                            </button>
                            <button placement="auto" tooltip="Valider"
                                class="btn d-40 p-0 btn-pill mr-1 btn-success"
                                type="button" *ngIf="budget.etat != 'validé'">
                                <span class="btn-wrapper--icon"><i class="fas fa-check"></i></span>
                            </button>
                            <button placement="auto" tooltip="Supprimer"
                                class="btn d-40 p-0 btn-pill btn-danger"
                                type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let budget of budgets; let i = index">
                        <td colspan="8" class="hiddenRow">
                            <div class="accordian-body collapse px-3" id="row{{i}}">Sous element {{i}} </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer p-3">
        <div class="d-flex justify-content-between">
            <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
            <div class="d-flex align-items-center">
                <span>Show</span>
                <select class="mx-1 form-control form-control-sm" id="" name="">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="40">50</option>
                    <option value="40">100</option>
                </select>
                <span>entries</span>
            </div>
        </div>
    </div>
</div>

<!-- Modal de de création d'un nouveau budget modal-xl -->
<div #newBudgetModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate rounded-top">
                <h6 class="modal-title">Formulaire de création de nouveau budget</h6>
                <button type="button" class="close" aria-label="Close" (click)="newBudgetModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <app-budget-edit></app-budget-edit>
            </div>
        </div>
    </div>
</div>