import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-tache-detail',
  templateUrl: './tache-detail.component.html',
  styleUrls: ['./tache-detail.component.scss']
})
export class TacheDetailComponent implements OnInit {
  resourceForm: FormGroup;
  ouvrierForm: FormGroup;
  ouvrierData: any;
  resourceData: any;
  displayOuvrierForm = false;
  displayResourceForm = false;
  list = true;
  qteTotal = 0;
  mtn = 0;
  mtnSup = 0;

  constructor(
    public globals: ThemeOptions,
    private formBuild: FormBuilder,
  ) {
    this.resourceData = [];
    this.ouvrierData = [];
  }

  ngOnInit() {
    this.resourceForm = this.formBuild.group({
      libelle: [null, [Validators.required]],
      quantite: [null, [Validators.required]],
      unite: [null, [Validators.required]]
    });

    return this.newOuvrier();
  }

  newOuvrier(){
    this.ouvrierForm = this.formBuild.group({
      fonction: [null, [Validators.required]],
      dateDebut: [null, [Validators.required]],
      dateFin: [null, [Validators.required]],
      montant: [null, [Validators.required]],
      montantSup: [null, [Validators.required]],
      photo: [null, [Validators.required]]
    });
  }

  // Pour enregistrer une resource
  onSubmit(){
    this.resourceData.push(this.resourceForm.value);
    this.resourceForm.reset();

    // Calcul de quantité total
    this.qteTotal = this.resourceData.reduce((sum, item) => sum + item.quantite, 0);
  }

  // Pour supprimer un resource
  removeResource(element) {
    this.resourceData.forEach((value,index) => {
      if(value == element)
        this.resourceData.splice(index, 1);
    });
  }

  addRessource(){
    this.displayResourceForm = !this.displayResourceForm;
  }

  // Pour enregistrer un ouvrier
  submitOuvrier(){
    this.ouvrierData.push(this.ouvrierForm.value);
    this.ouvrierForm.reset();

    // Calcul du montant journalier et du montant supplemtaire
    this.mtn = this.ouvrierData.reduce((sum, item) => sum + item.montant, 0);
    this.mtnSup = this.ouvrierData.reduce((sum, item) => sum + item.montantSup, 0);
  }

  // Pour supprimer un ouvrier
  removeOuvrier(element) {
    this.ouvrierData.forEach((value,index) => {
      if(value == element)
        this.ouvrierData.splice(index, 1);
    });
  }

  get f() { return this.resourceForm.controls; }
  get field() { return this.ouvrierForm.controls; }

  // Pour afficher le formulaire d'ajout d'ouvrier
  addOuvrier(){
    this.displayOuvrierForm = !this.displayOuvrierForm;
  }

  // Pour afficher la liste sous-forme de liste ou de carte
  selectType(type: boolean){
    this.list = type;
  }
}
