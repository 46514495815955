import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Taches } from 'src/app/models/taches';

@Injectable({
  providedIn: 'root'
})
export class TachesService {
  taches: Taches[];
  tache: Taches;
  private readonly url = "api/taches.json";

  constructor(
    private http: HttpClient
  ) {}

  setTache(tache: Taches) {
    this.tache = tache
  }

  getTache(): Taches {
    return this.tache
  }

  getList(): Observable<Taches[]> {
    return this.http.get<Taches[]>(this.url).pipe(
      tap(taches => console.log('tache:', taches)),
      catchError(this.handleHttpError)
    );
  }
  
  private handleHttpError(err: HttpErrorResponse) {
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${err.status}, ` +
        `body was: ${err.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
