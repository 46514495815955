<div class="modal-header modal-dark  bg-plum-plate">
    <h6 class="modal-title">Formulaire de création d'un nouveau fournisseur</h6>
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-0">
    <div>
        <form [formGroup]="fournisseurForm" (ngSubmit)="onSubmit()">
            <aw-wizard class="wizard" disableNavigationBar [awNavigationMode] browserBackward=" allow "
                naviguerForward="deny">

                <!-- ETAPE 1: INFORMATION GENERALES -->
                <aw-wizard-step awOptionalStep="fournisseurForm.controls['generalForm'].valid"
                    formGroupName="generalForm" stepTitle="Informations générales" [navigationSymbol]="{ symbol: '1' }">
                    <!-- <form [formGroup]="generalForm"> -->
                    <div class="p-4">
                        <h5 class="font-weight-bold m-0">Informations sur le générales fournisseur</h5>
                        <hr class="mt-2">
                        <div>
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="type">Type de fournisseur: <span
                                                class="text-danger mr-2">*</span></label>
                                        <select class="form-control" id="type" formControlName="type"
                                            [ngClass]="{ 'is-invalid': submit && f.generalForm['controls'].type.errors }">
                                            <option *ngFor="let item of type" [value]="item.value">{{item.label}}
                                            </option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="submit && f.generalForm['controls'].type.errors">
                                            <div *ngIf="f.generalForm['controls'].type.errors.required">
                                                {{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="typeFournisseur">Êtes vous fournisseur ou prestataire ? <span
                                                class="text-danger">*</span></label>
                                        <select id="typeFournisseur" formControlName="typeFournisseur"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submit && f.generalForm['controls'].typeFournisseur.errors}">
                                            <option value="1">Fournisseur</option>
                                            <option value="2">Prestataire</option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="submit && f.generalForm['controls'].typeFournisseur.errors">
                                            <div *ngIf="f.generalForm['controls'].typeFournisseur.errors.required">
                                                {{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="corpsMetier">Corps de metier: <span
                                                class="text-danger ml-2">*</span></label>
                                        <div class="input-group">
                                            <select id="corpsMetier" formControlName="corpsMetier" class="form-control"
                                                *ngIf="inputGroup == false"
                                                [ngClass]="{ 'is-invalid': submit && f.generalForm['controls'].corpsMetier.errors }">
                                                <option [ngValue]="null" disabled>Choisir un corps métier </option>
                                                <option *ngFor="let metier of listCorpsMetier"
                                                    [ngValue]="metier['@id']">
                                                    {{ metier.libelle }}
                                                </option>
                                            </select>
                                            <button tooltip="Créer un corps metier" placement="auto"
                                                class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill"
                                                type="button" (click)="newCorpsMetierModal.show()">
                                                <span class="btn-wrapper--icon">
                                                    <i class="ion-md-add"></i>
                                                </span>
                                            </button>
                                            <!-- <input *ngIf="inputGroup == true" id="corpsMetier" formControlName="corpsMetier" class="form-control" placeholder="Corps de metier" type="text"> -->
                                            <!-- <button 
                                                    tooltip="{{inputGroup == true ? 'Clique pour choisir' : 'Clique pour saisir' }}" 
                                                    placement="auto" (click)="inputGroup = !inputGroup" 
                                                    class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button">
                                                    <span class="btn-wrapper--icon">
                                                        <i class="{{inputGroup == true ? 'fas fa-hand-pointer' : 'fas fa-keyboard'}}"></i>
                                                    </span>
                                                </button> -->
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="submit && f.generalForm['controls'].corpsMetier.errors">
                                            <div *ngIf="f.generalForm['controls'].corpsMetier.errors.required">
                                                {{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="f.generalForm['controls'].type.value == 2">
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="civilite">Civilité: <span class="text-danger mr-2">*</span></label>
                                        <select class="form-control" (change)="__onSelectCivilite()" id="civilite"
                                            formControlName="civilite"
                                            [ngClass]="{ 'is-invalid': submit && f.generalForm['controls'].civilite.errors }">
                                            <option [ngValue]="null" disabled>Choisir une Civilité </option>
                                            <option value="M">Monsieur</option>
                                            <option value="Mme">Madame</option>
                                            <option value="Mlle">Mademoiselle</option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="submit && f.generalForm['controls'].civilite.errors">
                                            <div *ngIf="f.generalForm['controls'].civilite.errors.required">
                                                {{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="sexe">Sexe: <span class="text-danger">*</span></label>
                                        <input type="text" placeholder="Sexe" id="sexe" formControlName="sexe"
                                            class="form-control" readonly
                                            [ngClass]="{ 'is-invalid': submit && f.generalForm['controls'].sexe.errors }">
                                        <!-- <select id="sexe" formControlName="sexe" class="form-control"
                                            [ngClass]="{ 'is-invalid': submit && f.generalForm['controls'].sexe.errors, 'is-valid': submit && f.generalForm['controls'].sexe.valid }">
                                            <option value="M">Masculin</option>
                                            <option value="F">Feminin</option>
                                        </select> -->
                                        <div class="invalid-feedback"
                                            *ngIf="submit && f.generalForm['controls'].sexe.errors">
                                            <div *ngIf="f.generalForm['controls'].sexe.errors.required">
                                                {{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="nationalite">Nationalité: <span
                                                class="text-danger ml-2">*</span></label>
                                        <input type="text" id="nationalite" formControlName="nationalite"
                                            class="form-control" required placeholder="Nationalité"
                                            [ngClass]="{ 'is-invalid': submit && f.generalForm['controls'].nationalite.errors}">
                                        <div class="invalid-feedback"
                                            *ngIf="submit && f.generalForm['controls'].nationalite.errors">
                                            <div *ngIf="f.generalForm['controls'].nationalite.errors.required">
                                                {{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="nom">{{f.generalForm['controls'].type.value == 2 ?
                                            'Nom et prenom' : 'Raison sociale'}}: <span
                                                class="text-danger mr-2">*</span></label>
                                        <input type="text"
                                            placeholder="{{f.generalForm['controls'].type.value == 2 ? 'Nom et prenom' : 'Raison sociale'}}"
                                            id="nom" formControlName="nom" class="form-control"
                                            [ngClass]="{ 'is-invalid': submit && f.generalForm['controls'].nom.errors}">
                                        <div class="invalid-feedback"
                                            *ngIf="submit && f.generalForm['controls'].nom.errors">
                                            <div *ngIf="f.generalForm['controls'].nom.errors.required">
                                                {{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="date">{{f.generalForm['controls'].type.value == 2
                                            ? 'Date de naissance' : 'Date de création'}}: <span
                                                class="text-danger mr-2">*</span></label>
                                        <div class="input-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon>
                                                </span>
                                            </div>
                                            <input type="text" id="date" formControlName="date" class="form-control"
                                                [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }"
                                                bsDatepicker
                                                placeholder="{{f.generalForm['controls'].type.value == 2 ? 'Date de naissance' : 'Date de création'}}"
                                                [ngClass]="{ 'is-invalid': submit && f.generalForm['controls'].date.errors }">
                                            <div class="invalid-feedback"
                                                *ngIf="submit && f.generalForm['controls'].date.errors">
                                                <div *ngIf="f.generalForm['controls'].date.errors.required">
                                                    {{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="pays">Pays <span class="text-danger">*</span></label>
                                        <input class="form-control" id="pays" formControlName="pays"
                                            [(ngModel)]="selected" [typeahead]="pays" [adaptivePosition]="true"
                                            placeholder="Entrer le pays" type="text"
                                            [ngClass]="{ 'is-invalid': submit && f.generalForm['controls'].pays.errors }">
                                        <div class="invalid-feedback"
                                            *ngIf="submit && f.generalForm['controls'].pays.errors">
                                            <div *ngIf="f.generalForm['controls'].pays.errors.required">
                                                {{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="ville">Ville: <span class="text-danger mr-2">*</span></label>
                                        <input type="text" placeholder="Ville" id="ville" formControlName="ville"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid': submit && f.generalForm['controls'].ville.errors }">
                                        <div class="invalid-feedback"
                                            *ngIf="submit && f.generalForm['controls'].ville.errors">
                                            <div *ngIf="f.generalForm['controls'].ville.errors.required">
                                                {{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="commune">Commune: </label>
                                        <input class="form-control" id="commune" formControlName="commune"
                                            placeholder="Commune" type="text">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="quartier">{{f.generalForm['controls'].type.value == 2 ? 'Quartier' :
                                            'Siège social'}}: </label>
                                        <input type="text" class="form-control" id="commune" formControlName="quartier"
                                            placeholder="{{f.generalForm['controls'].type.value == 2 ? 'Quartier' : 'Siège social'}}">
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="email">Adresse email: </label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">@</div>
                                            </div>
                                            <input type="email" id="email" formControlName="email" class="form-control"
                                                placeholder="Adresse email">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="postal">Adresse postal: </label>
                                        <input type="text" id="postal" formControlName="postal" class="form-control"
                                            placeholder="Adresse postal">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="contact"> Contact: <span class="text-danger">*</span></label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="fas fa-phone-alt"></i></span>
                                            </div>
                                            <input class="form-control" id="contact" formControlName="contact"
                                                [textMask]="{mask: maskPhone}" type="text"
                                                placeholder="Entrez le contact"
                                                [ngClass]="{ 'is-invalid': submit && f.generalForm['controls'].contact.errors}">
                                            <div class="invalid-feedback"
                                                *ngIf="submit && f.generalForm['controls'].contact.errors">
                                                <div *ngIf="f.generalForm['controls'].contact.errors.required">
                                                    {{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="f.generalForm['controls'].type.value == 2">
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <label for="naturePiece">Nature de la pièce: <span
                                                class="text-danger mr-2">*</span></label>
                                        <select class="form-control" id="naturePiece" formControlName="naturePiece"
                                            [ngClass]="{ 'is-invalid': submit && f.generalForm['controls'].naturePiece.errors }">
                                            <option value="CNI">CNI</option>
                                            <option value="PASSPORT">PASSPORT</option>
                                            <option value="PERMIS DE CONDUITE">PERMIS DE CONDUITE</option>
                                            <option value="ATTESTATION">ATTESTATION</option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="submit && f.generalForm['controls'].naturePiece.errors">
                                            <div *ngIf="f.generalForm['controls'].naturePiece.errors.required">
                                                {{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <label for="numPiece">Numero de la piece<span
                                                class="text-danger">*</span></label>
                                        <input type="text" id="numPiece" formControlName="numPiece" class="form-control"
                                            [ngClass]="{ 'is-invalid': submit && f.generalForm['controls'].numPiece.errors }">
                                        <div class="invalid-feedback"
                                            *ngIf="submit && f.generalForm['controls'].numPiece.errors">
                                            <div *ngIf="f.generalForm['controls'].numPiece.errors.required">
                                                {{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <label for="dateDelivrance">Date de délivrance: </label>
                                        <div class="input-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon>
                                                </span>
                                            </div>
                                            <input type="text" id="dateDelivrance" formControlName="dateDelivrance"
                                                class="form-control"
                                                [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }"
                                                placement="top" bsDatepicker placeholder="Date de délivrance">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="position-relative form-group">
                                        <label for="dateExpiration">Date d'expiration: </label>
                                        <div class="input-group input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon>
                                                </span>
                                            </div>
                                            <input type="text" id="dateExpiration" formControlName="dateExpiration"
                                                class="form-control"
                                                [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }"
                                                placement="top" bsDatepicker placeholder="Date d'expiration">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="m-0 p-0" *ngIf="f.generalForm['controls'].type.value != 2">
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="categorie">Catégorie d'entrepise: </label>
                                            <div class="input-group">
                                                <select class="form-control" id="categorie" formControlName="categorie">
                                                    <option [ngValue]="null" disabled>Choisir une catégorie d'entrepise
                                                    </option>
                                                    <option *ngFor="let categorie of listCategorieFournisseur"
                                                        [ngValue]="categorie['@id']">
                                                        {{ categorie.libelle }}
                                                    </option>
                                                </select>
                                                <button tooltip="Créer une catégorie" placement="auto"
                                                    class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill"
                                                    type="button" (click)="newCategorieFournisseurModal.show()">
                                                    <span class="btn-wrapper--icon">
                                                        <i class="ion-md-add"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="formeJuridique">Forme juridique</label>
                                            <input type="text" class="form-control" id="formeJuridique"
                                                formControlName="formeJuridique" placeholder="Forme juridique">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="registre">Régistre de commerce</label>
                                            <input type="text" class="form-control" id="registre"
                                                formControlName="registre" placeholder="Régistre de commerce">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="regime">Régime d'imposition</label>
                                            <input type="text" class="form-control" id="regime" formControlName="regime"
                                                placeholder="Régime d'imposition">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="compte">Compte contribualbe</label>
                                            <input type="text" class="form-control" id="compte" formControlName="compte"
                                                placeholder="Compte contribualbe">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="geoLocalisation">Adresse géographique: </label>
                                            <input type="text" class="form-control" id="geoLocalisation"
                                                formControlName="geoLocalisation" placeholder="Adresse géographique">
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="form-row"  *ngIf="f.generalForm['controls'].typeFournisseur.value == 2">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="prestation">Prestation: </label>
                                            <select formControlName="prestation" id="prestation" class="form-control">
                                                <option value="Produit">Produit</option>
                                                <option value="Services">Services</option>
                                                <option value="produitServices">Produit et services</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="representant">Nom du representant</label>
                                            <input type="text" class="form-control" id="representant"
                                                formControlName="representant" placeholder="Nom du representant">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="contactRepresentant">Contact: </label>
                                            <input type="text" class="form-control" id="contactRepresentant"
                                                [textMask]="{mask: maskPhone}"
                                                formControlName="contactRepresentant"
                                                placeholder="Contact du representant">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="emailRepresentant">Adresse email: </label>
                                            <input type="text" class="form-control" id="emailRepresentant"
                                                formControlName="emailRepresentant" placeholder="Email du representant">
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="m-0 p-0" *ngIf="f.generalForm['controls'].typeFournisseur.value == 2">

                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="prestation">Prestation: </label>
                                            <div class="input-group">
                                                <select formControlName="prestation" id="prestation"
                                                    class="form-control">
                                                    <option [ngValue]="null" disabled>Choisir une prestation </option>
                                                    <option *ngFor="let prestation of listPrestationFournisseur"
                                                        [ngValue]="prestation['@id']">
                                                        {{ prestation.libelle }}
                                                    </option>
                                                </select>
                                                <button tooltip="Créer une prestation" placement="auto"
                                                    class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill"
                                                    type="button" (click)="newPrestationFournisseurModal.show()">
                                                    <span class="btn-wrapper--icon">
                                                        <i class="ion-md-add"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                            </div>
                            <div class="m-0 p-0" *ngIf="f.generalForm['controls'].type.value != 2">

                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="representant">Nom du representant</label>
                                            <input type="text" class="form-control" id="representant"
                                                formControlName="representant" placeholder="Nom du representant">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="contactRepresentant">Contact: </label>
                                            <input type="text" class="form-control" id="contactRepresentant"
                                                [textMask]="{mask: maskPhone}" formControlName="contactRepresentant"
                                                placeholder="Contact du representant">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="emailRepresentant">Adresse email du representant: </label>
                                            <input type="text" class="form-control" id="emailRepresentant"
                                                formControlName="emailRepresentant" placeholder="Email du representant">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="position-relative form-group">
                                        <label for="photo">{{f.generalForm['controls'].type.value == 2 ? 'Photo' :
                                            'Logo'}}:</label>
                                        <div class="custom-file">
                                            <input type="file" id="photo" formControlName="photo"
                                                class="custom-file-input" multiple>
                                            <label class="custom-file-label"
                                                for="photo">{{f.generalForm['controls'].type.value == 2 ? 'Photo' :
                                                'Logo'}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="m-0">
                    <div class="d-flex justify-content-between px-4 py-2">
                        <div></div>
                        <div class="text-right">
                            <button awNextStep (click)="__submitGeneralForm()" class="btn btn-dark"
                                type="button">Suivant <i class="fas fa-chevron-right ml-1"></i></button>
                            <!-- <button awNextStep  (click)="__submitGeneralForm()" [disabled]="fournisseurForm.controls['generalForm'].invalid"  class="btn btn-dark" type="button">Suivant <i class="fas fa-chevron-right ml-1"></i></button> -->
                        </div>
                    </div>
                    <!-- </form> -->
                </aw-wizard-step>

                <!-- ETAPE 2: INFORMATION FINANCIERES -->
                <aw-wizard-step stepTitle="Information financière" [navigationSymbol]="{ symbol: '2' }"
                    formGroupName="financeForm">
                    <!-- <form [formGroup]="financeForm"> -->
                    <div class="p-4">
                        <h5 class="font-weight-bold m-0">Information financière</h5>
                        <hr class="mt-2">
                        <div>
                            <div class="form-row">
                                <div class="col-md-5">
                                    <div class="position-relative form-group">
                                        <label for="compteBancaire">Compte bancaire: </label>
                                        <input type="text" class="form-control" id="compteBancaire"
                                            formControlName="compteBancaire" placeholder="Compte bancaire">
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="position-relative form-group">
                                        <label for="chiffreAffaire">Chiffre d'affaire (Entrez le nombre d'annéé):
                                        </label>
                                        <input type="number" min="1" max="10" [readonly]="nbreValidChiffreAffaire != 0"
                                            class="form-control" id="chiffreAffaire" formControlName="chiffreAffaire"
                                            placeholder="Chiffre d'affaire" (blur)="onChangeAnnee()">
                                        <div  *ngIf="statutChiffreAffaire == true" style="color: red;">
                                        
                                                La valeur saisir doit être compris entre 1 et 10
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="chffreField.controls['annees'].controls.length > 0 ">
                                <hr class="mb-0">
                                <div class="p-2 bg-secondary">
                                    <h6 class="font-weight-bold m-0">Information chiffre d'affaire</h6>
                                </div>
                                <hr class="mt-0">
                                <div formArrayName="annees">
                                    <div class="form-row">
                                        <div class="col-md-1">
                                            <label for="numero">Numero</label>
                                        </div>
                                        <div class="col">
                                            <label for="annee">Année: </label>
                                        </div>
                                        <div class="col">
                                            <label for="montant">Montant: </label>
                                        </div>
                                        <div class="col">
                                            <label for="etat">Etat financier</label>
                                        </div>
                                    </div>
                                    <div class="form-row" *ngFor="let item of listChiffreAffaire; index as i"
                                        [formGroupName]="i">
                                        <div class="col-md-1">
                                            <div class="position-relative form-group">
                                                <input type="number" id="numero{{i}}" formControlName="numero"
                                                    class="form-control text-center" value="{{i + 1}}">
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="position-relative form-group">
                                                <input type="text" id="annee{{i}}" formControlName="annee"
                                                    class="form-control" placeholder="Entrez l'année">
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="position-relative form-group">
                                                <input type="number" min="0" id="montant{{i}}" formControlName="montant"
                                                    class="form-control" placeholder="Entrez l'année">
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="position-relative form-group">
                                                <input type="text" id="etat{{i}}" formControlName="etat"
                                                    class="form-control" placeholder="Etat financier">
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <button tooltip="Supprimer la ligne"
                                                (click)="__deleteChiffreAffaireLigne(item)" placement="auto"
                                                class="btn d-40 p-0 btn-animated-icon btn-animated-icon--danger btn-pill"
                                                type="button">
                                                <span class="btn-wrapper--icon">
                                                    <i class="fas fa-trash"></i>
                                                </span>
                                            </button>
                                            <button *ngIf="listChiffreAffaire.length === i + 1"
                                                (click)="__addChiffreAffaireLigne()" tooltip="Ajouter une autre ligne"
                                                placement="auto"
                                                class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill"
                                                type="button">
                                                <span class="btn-wrapper--icon">
                                                    <i class="ion-md-add"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                            </div>
                        </div>
                    </div>
                    <hr class="m-0">
                    <div class="d-flex justify-content-between px-4 py-2">
                        <div>
                            <button awPreviousStep class="btn btn-dark" type="button"><i
                                    class="fas fa-chevron-left mr-1"></i> Precedent</button>
                        </div>
                        <div class="text-right">
                            <button awNextStep (click)="__submitFinancierForm()" class="btn btn-dark"
                                type="button">Suivant <i class="fas fa-chevron-right ml-1"></i></button>
                        </div>
                    </div>
                    <!-- </form> -->
                </aw-wizard-step>

                <!-- ETAPE 3: DOCUMENTS -->
                <aw-wizard-step stepTitle="Documents réalisés" [navigationSymbol]="{ symbol: '3' }"
                    formGroupName="documentsForm">
                    <!-- <form [formGroup]="documentsForm"> -->
                    <div class="p-4">
                        <h5 class="font-weight-bold m-0">Documents réalisés</h5>
                        <hr class="mt-2">
                        <div>
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="cnps">Attestaion CNPS:</label>
                                        <div class="custom-file">
                                            <input type="file" id="cnps" formControlName="cnps"
                                                class="custom-file-input">
                                            <label class="custom-file-label" for="cnps">Attestaion CNPS</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="regularite">Attestaion de regularité fiscale:</label>
                                        <div class="custom-file">
                                            <input type="file" id="regularite" formControlName="regularite"
                                                class="custom-file-input">
                                            <label class="custom-file-label" for="regularite">Attestaion de regularité
                                                fiscale</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="assurance">Attestaion d'assurance</label>
                                        <div class="custom-file">
                                            <input type="file" id="assurance" formControlName="assurance"
                                                class="custom-file-input">
                                            <label class="custom-file-label" for="assurance">Attestaion
                                                d'assurance</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="position-relative form-group">
                                        <label for="statusEntreprise">Status de l'enréprise</label>
                                        <div class="custom-file">
                                            <input type="file" id="statusEntreprise" formControlName="statusEntreprise"
                                                class="custom-file-input">
                                            <label class="custom-file-label" for="statusEntreprise">Status de
                                                l'enréprise</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="m-0">
                    <div class="d-flex justify-content-between px-4 py-2">
                        <div>
                            <button awPreviousStep class="btn btn-dark" type="button"><i
                                    class="fas fa-chevron-left mr-1"></i> Precedent</button>
                        </div>
                        <div class="text-right">
                            <button awNextStep class="btn btn-dark" type="button">Suivant <i
                                    class="fas fa-chevron-right ml-1"></i></button>
                        </div>
                    </div>
                    <!-- </form> -->
                </aw-wizard-step>

                <!-- ETAPE 4: PROJET -->
                <aw-wizard-step awOptionalStep stepTitle="Projets réalisés" [navigationSymbol]="{ symbol: '4' }"
                    formGroupName="projetForm">
                    <!-- <form [formGroup]="projetForm"> -->
                    <div class="p-4">
                        <h5 class="font-weight-bold m-0">Projet réalisés</h5>
                        <hr class="mt-2">
                        <div>
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="typeProjet">Type de projet: <span
                                                class="text-danger mr-2">*</span></label>

                                        <div class="input-group">
                                            <select id="typeProjet" formControlName="typeProjet" class="form-control"
                                                *ngIf="inputGroup == false"
                                                [ngClass]="{ 'is-invalid': submit && f.projetForm['controls'].typeProjet.errors }">
                                                <option [ngValue]="null" disabled>Choisir un type de projet </option>
                                                <option *ngFor="let typeProjet of listTypeProjet"
                                                    [ngValue]="typeProjet['@id']">
                                                    {{ typeProjet.libelle }}
                                                </option>
                                            </select>
                                            <button tooltip="Créer un type de projet" placement="auto"
                                                class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill"
                                                type="button" (click)="newTypeProjetModal.show()">
                                                <span class="btn-wrapper--icon">
                                                    <i class="ion-md-add"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="LibelleProjet">Libellé: <span
                                                class="text-danger mr-2">*</span></label>
                                        <input type="text" id="LibelleProjet" formControlName="LibelleProjet"
                                            class="form-control" placeholder="Libellé"
                                            [ngClass]="{ 'is-invalid': submit && f.projetForm['controls'].LibelleProjet.errors}">
                                        <div class="invalid-feedback"
                                            *ngIf="submit && f.projetForm['controls'].LibelleProjet.errors">
                                            <div *ngIf="f.projetForm['controls'].LibelleProjet.errors.required">
                                                {{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="anneeProjet">Année de réalisation: <span
                                                class="text-danger mr-2">*</span></label>
                                        <input type="text" id="anneeProjet" formControlName="anneeProjet"
                                            class="form-control" placeholder="Année de réalisation"
                                            [ngClass]="{ 'is-invalid': submit && f.projetForm['controls'].anneeProjet.errors }">
                                        <div class="invalid-feedback"
                                            *ngIf="submit && f.projetForm['controls'].anneeProjet.errors">
                                            <div *ngIf="f.projetForm['controls'].anneeProjet.errors.required">
                                                {{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="duree">Durée (Nombre de mois): <span
                                                class="text-danger mr-2">*</span></label>
                                        <input type="number" id="duree" formControlName="duree" class="form-control"
                                            placeholder="Durée du projet"
                                            [ngClass]="{ 'is-invalid': submit && f.projetForm['controls'].duree.errors}">
                                        <div class="invalid-feedback"
                                            *ngIf="submit && f.projetForm['controls'].duree.errors">
                                            <div *ngIf="f.projetForm['controls'].duree.errors.required">
                                                {{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="maitreOuvrage">Maitre d'ouvrage: </label>
                                        <input type="text" id="maitreOuvrage" formControlName="maitreOuvrage"
                                            class="form-control" placeholder="Maitre d'ouvrage">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="budget">Budget: </label>
                                        <input type="text" id="budget" formControlName="budget" class="form-control"
                                            placeholder="Budget">
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="position-relative form-group">
                                        <label for="bonneExecution">Attestaion bonne execution:</label>
                                        <div class="custom-file">
                                            <input type="file" id="bonneExecution" formControlName="bonneExecution"
                                                class="custom-file-input">
                                            <label class="custom-file-label" for="bonneExecution">Attestaion bonne
                                                execution</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-right mb-3">
                            <button class="btn btn-success" (click)="__addProjetRealise()" type="button"><i
                                    class="fas fa-plus mr-2"></i> Ajouter un autre projet </button>
                        </div>
                        <hr>
                        <div class="border rounded" *ngIf="listProjetRealises.length != 0">
                            <div class="table-responsive m-0">
                                <table class="table table-alternate text-nowrap m-0">
                                    <thead>
                                        <tr>
                                            <th>Type de projet</th>
                                            <th>Libellé</th>
                                            <th>Année de réalisation</th>
                                            <th>Durée</th>
                                            <th>Maitre d'ouvrage</th>
                                            <th>Budget</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of listProjetRealises">
                                            <td>{{item?.libelleTypeProjet}}</td>
                                            <td>{{item?.libelleProjet}}</td>
                                            <td>{{item?.anneeProjet}}</td>
                                            <td>{{item?.duree}}</td>
                                            <td>{{item?.maitreOuvrage}}</td>
                                            <td>{{item?.budget}}</td>
                                            <td class="text-center">
                                                <button class="btn d-40 p-0 btn-pill btn-danger" placement="auto"
                                                    (click)="__deleteProjetRealise(item)" tooltip="Supprimer"
                                                    type="button">
                                                    <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <hr class="m-0">
                    <div class="d-flex justify-content-between px-4 py-2">
                        <div>
                            <button awPreviousStep class="btn btn-dark" type="button"><i
                                    class="fas fa-chevron-left mr-1"></i> Precedent</button>
                        </div>
                        <div class="text-right">
                            <button class="btn btn-first mr-2" type="button" (click)="form.reset()"><i
                                    class="fas fa-broom mr-1"></i> Nettoyer</button>
                            <button class="btn btn-success" type="submit"><i class="fas fa-save mr-2"></i>
                                Enregistrer</button>
                        </div>
                    </div>
                    <!-- </form> -->
                </aw-wizard-step>
            </aw-wizard>
        </form>
    </div>
</div>

<!--corps metier modal add -->
<div #newCorpsMetierModal="bs-modal" [config]="{backdrop: 'static'}" backdrop="false" bsModal class="modal fade"
    role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter un corps métier</h6>
                <button type="button" class="close" aria-label="Close" (click)="closeCorpsMetierModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <div class="rounded ">
                        <div class="mx-3">
                            <form [formGroup]="corpsMetierForm" (ngSubmit)="addCorpsMetier()">
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="libelleCorpsMetier">Corps Métier <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" class="form-control" id="libelleCorpsMetier"
                                                formControlName="libelleCorpsMetier"
                                                placeholder="Libellé du corps métier"
                                                [ngClass]="{ 'is-invalid': submitCorpsMetier && corpsMetierF.libelleCorpsMetier.errors }">
                                            <div *ngIf="submitCorpsMetier && corpsMetierF.libelleCorpsMetier.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="corpsMetierF.libelleCorpsMetier.errors.required">
                                                    {{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="text-center px-3 py-2">
                    <button class="btn btn-first m-2" (click)="corpsMetierForm.reset()" type="button"><i
                            class="fas fa-broom mr-1"></i>Nettoyer</button>
                    <button class="btn btn-success" (click)="addCorpsMetier()" type="submit"><i
                            class="fas fa-save mr-1"></i>Enregister</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--categorie fournisseur modal add -->
<div #newCategorieFournisseurModal="bs-modal" [config]="{backdrop: 'static'}" backdrop="false" bsModal
    class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter une categorie</h6>
                <button type="button" class="close" aria-label="Close" (click)="closeCategorieFournisseurModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <div class="rounded ">
                        <div class="mx-3">
                            <form [formGroup]="categorieFournisseurForm" (ngSubmit)="addCategorieFournisseur()">
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="libelleCategorieFournisseur">Catégorie d'entrepise <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" class="form-control" id="libelleCategorieFournisseur"
                                                formControlName="libelleCategorieFournisseur"
                                                placeholder="Libellé de la Catégorie d'entreprise"
                                                [ngClass]="{ 'is-invalid': submitCategorieFournisseur && categorieFournisseurF.libelleCategorieFournisseur.errors }">
                                            <div *ngIf="submitCategorieFournisseur && categorieFournisseurF.libelleCategorieFournisseur.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="categorieFournisseurF.libelleCategorieFournisseur.errors.required">
                                                    {{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="text-center px-3 py-2">
                    <button class="btn btn-first m-2" (click)="categorieFournisseurForm.reset()" type="button"><i
                            class="fas fa-broom mr-1"></i>Nettoyer</button>
                    <button class="btn btn-success" (click)="addCategorieFournisseur()" type="submit"><i
                            class="fas fa-save mr-1"></i>Enregister</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--prestation fournisseur modal add -->
<div #newPrestationFournisseurModal="bs-modal" [config]="{backdrop: 'static'}" backdrop="false" bsModal
    class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter une prestation</h6>
                <button type="button" class="close" aria-label="Close" (click)="closePrestationFournisseurModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <div class="rounded ">
                        <div class="mx-3">
                            <form [formGroup]="prestationFournisseurForm" (ngSubmit)="addPrestationFournisseur()">
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="libellePrestationFournisseur">Prestation <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" class="form-control" id="libellePrestationFournisseur"
                                                formControlName="libellePrestationFournisseur"
                                                placeholder="Libellé du corps métier"
                                                [ngClass]="{ 'is-invalid': submitPrestationFournisseur && prestationFournisseurF.libellePrestationFournisseur.errors }">
                                            <div *ngIf="submitPrestationFournisseur && prestationFournisseurF.libellePrestationFournisseur.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="prestationFournisseurF.libellePrestationFournisseur.errors.required">
                                                    {{required.novide}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="text-center px-3 py-2">
                    <button class="btn btn-first m-2" (click)="prestationFournisseurForm.reset()" type="button"><i
                            class="fas fa-broom mr-1"></i>Nettoyer</button>
                    <button class="btn btn-success" (click)="addPrestationFournisseur()" type="submit"><i
                            class="fas fa-save mr-1"></i>Enregister</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--type projet modal add -->
<div #newTypeProjetModal="bs-modal" [config]="{backdrop: 'static'}" backdrop="false" bsModal class="modal fade"
    role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter un type de projet</h6>
                <button type="button" class="close" aria-label="Close" (click)="closeTypeProjetModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <div class="rounded ">
                        <div class="mx-3">
                            <form [formGroup]="typeProjetForm" (ngSubmit)="addTypeProjet()">
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="libelleTypeProjet">Type de projet <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" class="form-control" id="libelleTypeProjet"
                                                formControlName="libelleTypeProjet"
                                                placeholder="Libellé du type de projet"
                                                [ngClass]="{ 'is-invalid': submitTypeProjet && typeProjetF.libelleTypeProjet.errors }">
                                            <div *ngIf="submitTypeProjet && typeProjetF.libelleTypeProjet.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="typeProjetF.libelleTypeProjet.errors.required">
                                                    {{required.novide}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="text-center px-3 py-2">
                    <button class="btn btn-first m-2" (click)="typeProjetForm.reset()" type="button"><i
                            class="fas fa-broom mr-1"></i>Nettoyer</button>
                    <button class="btn btn-success" (click)="addTypeProjet()" type="submit"><i
                            class="fas fa-save mr-1"></i>Enregister</button>
                </div>
            </div>
        </div>
    </div>
</div>