<div class="modal-header modal-dark  bg-plum-plate">
    <h6 class="modal-title">Formulaire d'ajout de démande de mission</h6>
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-0">
    <ngx-spinner [fullScreen]="false" name="spMission" size="medium" type="timer"></ngx-spinner>
    <form [formGroup]="formMission" >
        <aw-wizard class="wizard">

            <!-- ETAPE 1 -->
            <aw-wizard-step formGroupName="missionOnlyForm" stepTitle="Informations générales" [navigationSymbol]="{ symbol: '1' }">
                <div class="p-4">
                    <h5 class="font-size-xl font-weight-bold">Renseigner les informations générales</h5>
                    <div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="motif">Motif: <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" id="motif" formControlName="motif" placeholder="Motif"
                                    [ngClass]="{ 'is-invalid': submit && f.missionOnlyForm['controls'].motif.errors }">
                                    <div class="invalid-feedback" *ngIf="submit && f.missionOnlyForm['controls'].motif.errors">
                                        <div *ngIf="f.missionOnlyForm['controls'].motif.errors.required">{{required.novide}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="demandeur">Nom du demandeur: <span class="text-danger">*</span></label>
                                    <input type="text" id="demandeur" formControlName="demandeur" class="form-control" placeholder="Nom du demandeur"
                                    [ngClass]="{ 'is-invalid': submit && f.missionOnlyForm['controls'].demandeur.errors }">
                                    <div class="invalid-feedback" *ngIf="submit && f.missionOnlyForm['controls'].demandeur.errors">
                                        <div *ngIf="f.missionOnlyForm['controls'].demandeur.errors.required">{{required.novide}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="contactDemandeur">Contact: <span class="text-danger">*</span></label>
                                    <input type="text" id="contactDemandeur" formControlName="contactDemandeur" class="form-control" placeholder="Contact du demandeur"
                                    [ngClass]="{ 'is-invalid': submit && f.missionOnlyForm['controls'].contactDemandeur.errors }">
                                    <div class="invalid-feedback" *ngIf="submit && f.missionOnlyForm['controls'].contactDemandeur.errors">
                                        <div *ngIf="f.missionOnlyForm['controls'].contactDemandeur.errors.required">{{required.novide}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="emailDemandeur">Email: <span class="text-danger">*</span></label>
                                    <input type="email" class="form-control" id="emailDemandeur" formControlName="emailDemandeur" placeholder="Email demandeur"
                                    [ngClass]="{ 'is-invalid': submit && f.missionOnlyForm['controls'].emailDemandeur.errors}">
                                    <div class="invalid-feedback" *ngIf="submit && f.missionOnlyForm['controls'].emailDemandeur.errors">
                                        <div *ngIf="f.missionOnlyForm['controls'].emailDemandeur.errors.required">{{required.novide}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="fonctionDemandeur">Fonction: </label>
                                    <input type="text" id="fonctionDemandeur" formControlName="fonctionDemandeur" class="form-control" placeholder="Fonction du demandeur">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="itineraire">Itineraire: <span class="text-danger">*</span></label>
                                    <input type="text" id="itineraire" formControlName="itineraire" class="form-control" placeholder="Itineraire"
                                    [ngClass]="{ 'is-invalid': submit && f.missionOnlyForm['controls'].itineraire.errors }">
                                    <div class="invalid-feedback" *ngIf="submit && f.missionOnlyForm['controls'].itineraire.errors">
                                        <div *ngIf="f.missionOnlyForm['controls'].itineraire.errors.required">{{required.novide}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="chantier">Chantier: <span class="text-danger">*</span></label>
                                    <!-- <input type="text" id="chantier" formControlName="chantier" class="form-control" placeholder="Chantier"
                                    [ngClass]="{ 'is-invalid': submit && f.missionOnlyForm['controls'].chantier.errors }"> -->
                                    <select id="chantier" formControlName="chantier" class="form-control"
                                                *ngIf="inputGroup == false"
                                                [ngClass]="{ 'is-invalid': submit && f.missionOnlyForm['controls'].chantier.errors }">
                                                <option [ngValue]="null" disabled>Choisir un chantier </option>
                                                <option *ngFor="let chantier of listChantier"
                                                    [ngValue]="chantier['@id']">
                                                    {{ chantier.libelle }}
                                                </option>
                                            </select>
                                    <div class="invalid-feedback" *ngIf="submit && f.missionOnlyForm['controls'].chantier.errors">
                                        <div *ngIf="f.missionOnlyForm['controls'].chantier.errors.required">{{required.novide}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="dateDepart">Date départ: <span class="text-danger">*</span></label>
                                    <div class="input-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                        </div>
                                        <input type="text" id="dateDepart" formControlName="dateDepart" class="form-control" placeholder="Date départ"
                                        [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                                        [ngClass]="{ 'is-invalid': submit && f.missionOnlyForm['controls'].dateDepart.errors }">
                                        <div class="invalid-feedback" *ngIf="submit && f.missionOnlyForm['controls'].dateDepart.errors">
                                            <div *ngIf="f.missionOnlyForm['controls'].dateDepart.errors.required">{{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="dateRetour">Retour prevu: <span class="text-danger">*</span></label>
                                    <div class="input-group input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                        </div>
                                        <input type="text" id="dateRetour" formControlName="dateRetour" class="form-control" placeholder="Date retour"
                                        [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                                        [ngClass]="{ 'is-invalid': submit && f.missionOnlyForm['controls'].dateRetour.errors }">
                                        <div class="invalid-feedback" *ngIf="submit && f.missionOnlyForm['controls'].dateRetour.errors">
                                            <div *ngIf="f.missionOnlyForm['controls'].dateRetour.errors.required">{{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-12">
                                <div class="position-relative form-group">
                                    <label for="description">Description: <span class="text-danger">*</span></label>
                                    <textarea id="description" formControlName="description" class="form-control" rows="3" placeholder="Description"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="px-4 py-2 text-right">
                    <button (click)="submitMissionOnlyForm()" [disabled]="formMission.controls['missionOnlyForm'].invalid"  awNextStep class="btn btn-dark" type="button">Suivant <i class="fas fa-chevron-right ml-1"></i></button>
                </div>
            </aw-wizard-step>

            <!-- ETAPE 2 -->
            <aw-wizard-step formGroupName="participantForm" [awCompletedStep]="participantData.length != 0"  stepTitle="Participants" [navigationSymbol]="{ symbol: '2' }">
                <div class="p-4">
                    <h5 class="font-size-xl font-weight-bold">Informations sur les participants</h5>
                    <div>
                        <div class="p-0 m-0" *ngIf="participantData.length > 0">
                            <div class="form-row">
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="nom">Nom: </label>
                                        <input type="text" id="nom" formControlName="nom" class="form-control" placeholder="Nom du participant"
                                        [ngClass]="{ 'is-invalid': submit && f.participantForm['controls'].nom.errors }">
                                        <div class="invalid-feedback" *ngIf="submit && f.participantForm['controls'].nom.errors">
                                            <div *ngIf="f.participantForm['controls'].nom.errors.required">{{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="prenom">Prenom: </label>
                                        <input type="text" id="prenom" formControlName="prenom" class="form-control" placeholder="Prenom du participant"
                                        [ngClass]="{ 'is-invalid': submit && f.participantForm['controls'].prenom.errors}">
                                        <div class="invalid-feedback" *ngIf="submit && f.participantForm['controls'].prenom.errors">
                                            <div *ngIf="f.participantForm['controls'].prenom.errors.required">{{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="position-relative form-group">
                                        <label for="contact">Contact: </label>
                                        <input type="text" id="contact" formControlName="contact" class="form-control" placeholder="Contact du participant"
                                        [ngClass]="{ 'is-invalid': submit && f.participantForm['controls'].contact.errors}">
                                        <div class="invalid-feedback" *ngIf="submit && f.participantForm['controls'].contact.errors">
                                            <div *ngIf="f.participantForm['controls'].contact.errors.required">{{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-6">
                                    <div class="position-relative form-group">
                                        <label for="email">Email: </label>
                                        <input type="email" id="email" formControlName="email" class="form-control" placeholder="email du participant"
                                        [ngClass]="{ 'is-invalid': submit && f.participantForm['controls'].email.errors }">
                                        <div class="invalid-feedback" *ngIf="submit && f.participantForm['controls'].email.errors">
                                            <div *ngIf="f.participantForm['controls'].email.errors.required">{{required.novide}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="position-relative form-group">
                                        <label for="fonction">Fonction: </label>
                                        <input type="text" id="fonction" formControlName="fonction" class="form-control" placeholder="fonction du participant">
                                    </div>
                                </div>
                            </div>
    
                            <div class="text-right mb-3">
                                <button  class="btn btn-success" [disabled]="formMission.controls['participantForm'].invalid" type="button" (click)="saveParticipant()"><i class="fas fa-save mr-2"></i> Ajouter</button>
                            </div>
                            <div class="border rounded">
                                <div class="table-responsive m-0">
                                    <table class="table table-alternate text-nowrap m-0">
                                        <thead>
                                            <tr>
                                                <th>Nom</th>
                                                <th>Prenom</th>
                                                <th>Contact</th>
                                                <th>Email</th>
                                                <th>Fonction</th>
                                                <th class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of participantData">
                                                <td>{{item.nom}}</td>
                                                <td>{{item.prenom}}</td>
                                                <td>{{item.contact}}</td>
                                                <td>{{item.email}}</td>
                                                <td>{{item.fonction}}</td>
                                                <td class="text-center">
                                                    <button class="btn d-40 p-0 btn-pill btn-danger" placement="auto" tooltip="Supprimer" type="button" (click)="deleteParticipant(item)">
                                                        <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
        
                        <div class="p-0 m-0" *ngIf="participantData.length == 0">
                            <div class="rounded-sm bg-secondary px-5 py-4">
                                <h1>Aucun participant enregistrer</h1>
                                <span>Renseigner les informations du participant puis cliquer sur le boutton "Ajouter" pour en ajouter</span>
                                <hr>
                                <div class="form-row">
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="nom">Nom: </label>
                                            <input type="text" id="nom" formControlName="nom" class="form-control" placeholder="Nom du participant"
                                            [ngClass]="{ 'is-invalid': submit && f.participantForm['controls'].nom.errors }">
                                            <div class="invalid-feedback" *ngIf="submit && f.participantForm['controls'].nom.errors">
                                                <div *ngIf="f.participantForm['controls'].nom.errors.required">{{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="prenom">Prenom: </label>
                                            <input type="text" id="prenom" formControlName="prenom" class="form-control" placeholder="Prenom du participant"
                                            [ngClass]="{ 'is-invalid': submit && f.participantForm['controls'].prenom.errors}">
                                            <div class="invalid-feedback" *ngIf="submit && f.participantForm['controls'].prenom.errors">
                                                <div *ngIf="f.participantForm['controls'].prenom.errors.required">{{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="position-relative form-group">
                                            <label for="contact">Contact: </label>
                                            <input type="text" id="contact" formControlName="contact" class="form-control" placeholder="Contact du participant"
                                            [ngClass]="{ 'is-invalid': submit && f.participantForm['controls'].contact.errors }">
                                            <div class="invalid-feedback" *ngIf="submit && f.participantForm['controls'].contact.errors">
                                                <div *ngIf="f.participantForm['controls'].contact.errors.required">{{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-6">
                                        <div class="position-relative form-group">
                                            <label for="email">Email: </label>
                                            <input type="email" id="email" formControlName="email" class="form-control" placeholder="email du participant"
                                            [ngClass]="{ 'is-invalid': submit && f.participantForm['controls'].email.errors }">
                                            <div class="invalid-feedback" *ngIf="submit && f.participantForm['controls'].email.errors">
                                                <div *ngIf="f.participantForm['controls'].email.errors.required">{{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="position-relative form-group">
                                            <label for="fonction">Fonction: </label>
                                            <input type="text" id="fonction" formControlName="fonction" class="form-control" placeholder="fonction du participant">
                                        </div>
                                    </div>
                                </div>
                                <button  class="btn btn-success" [disabled]="formMission.controls['participantForm'].invalid" type="button" (click)="saveParticipant()"><i class="fas fa-save mr-2"></i> Ajouter</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="d-flex justify-content-between px-4 py-2">
                    <div>
                        <button awPreviousStep class="btn btn-dark" type="button"><i class="fas fa-chevron-left mr-1"></i> Precedent</button>
                    </div>
                    <div class="text-right">
                        <button class="btn btn-first mr-2" type="button" (click)="formMission.reset()"><i class="fas fa-broom mr-1"></i> Nettoyer</button>
                        <button (click)="onSubmit()" class="btn btn-success" type="submit"><i class="fas fa-save mr-2"></i> Enregistrer</button>
                    </div>
                </div>
            </aw-wizard-step>
        </aw-wizard>
    </form>
</div>