import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';
import { MissionPages } from 'src/app/models/mission-pages';
import { MissionPagesService } from 'src/app/services/mission-pages/mission-pages.service';
import { NotificationsService } from 'src/app/shared-components/service/notifications.service';
import { UtilOptions } from 'src/app/shared-components/util/util-option';
import { ThemeOptions } from 'src/app/theme-options';
import { MissionEditComponent } from '../mission-edit/mission-edit.component';
import { MissionService } from '../mission.service';

@Component({
  selector: 'app-mission-list',
  templateUrl: './mission-list.component.html',
  styleUrls: ['./mission-list.component.scss']
})
export class MissionListComponent implements OnInit, OnDestroy {
  required = UtilOptions.required;
  missions: MissionPages[] = [];
  closeForm: FormGroup;
  submit: false;
  rotate = true;
  errMsg = "";
  etatMission = [
    { label: "Cloturée", value: "cloture" },
    { label: "En cours", value: "en cours" },
    { label: "En attente de validation", value: "en attente de validation" },
    { label: "Annuler", value: "annuler" }
  ]
  private notif: NotifierService;
  missionSubs = new Subscription;
  deleteMissionSubs = new Subscription;
  listMissions = [];

  constructor(
    public globals: ThemeOptions,
    private formBulder: FormBuilder,
    private modalService: NgbModal,
    private notifier: NotifierService,
    private missionService: MissionService
  ) {
    this.notif = this.notifier;
    this.newForm();
  }

  newForm() {
    this.closeForm = this.formBulder.group({
      dateRetour: [null, [Validators.required]],
      carburant: [null, [Validators.required]],
      montant: [null, [Validators.required]],
      km: [null, [Validators.required]],
      fichier: [null, [Validators.required]],
      rapport: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    this.loadAllMission();
  }
  ngOnDestroy(): void {
    this.missionSubs.unsubscribe();
    this.deleteMissionSubs.unsubscribe();
  }

  onSubmit() { }

  get f() { return this.closeForm.controls; }

  showNotification(type: string, message: string): void {
    this.notif.notify(type, message);
  }

  addMission(donnee?, action?): any {
    console.log(donnee);
    if (donnee) {
      this.sendData(donnee, action);
    }
    this.modalService.dismissAll();
    // this.chambreService.edit = false;
    this.modal(MissionEditComponent, 'modal-basic-title', 'xl', false, 'static');
  }


  modal(component, type, size, center, backdrop): any {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {
      console.log('result', result);
      if (result == 'success') {
        this.showNotification('success', "L'opération s'est bien déroulé");
        this.loadAllMission();
      } else if (result == 'update') {
        this.showNotification('success', "L'opération s'est bien déroulé");
        this.loadAllMission();
      }

    }, (reason) => { });
  }

  sendData(donnee, bool) {
    const donneeSend = {
      user: donnee,
      type: bool
    };
    // this.fournisseurService.setDataForFournisseur(donneeSend);
  }
  loadAllMission() {
    this.missionSubs = this.missionService.listerMission().subscribe(
      resData => {
        if (resData) {
          this.listMissions = resData;
          return this.listMissions;

        }
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
  deleteFournisseur(id) {
    console.log('id to delete', id);
    this.deleteMissionSubs = this.missionService.deleteMission(id).subscribe(
      resData => {
        this.showNotification('success', "L'opération s'est bien déroulé");
        this.loadAllMission();
        // if(resData) {
        //   this.showNotification('success', "L'opération s'est bien déroulé");
        // } 

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }

}
