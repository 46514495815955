import { Component, OnInit } from '@angular/core';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { setHours, setMinutes } from 'date-fns';
import * as pluginDataLabels from 'chartjs-plugin-datalabels'
import { colors } from 'src/app/example-components/Calendars/calendars-full-width-basic/colors';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-opportunite-dashboard',
  templateUrl: './opportunite-dashboard.component.html',
  styleUrls: ['./opportunite-dashboard.component.scss']
})
export class OpportuniteDashboardComponent implements OnInit {
  bsInlineValue = new Date();
  bsInlineRangeValue: Date[];
  rotate = true;
  maxDate = new Date();
  filterRow = [
    {label: "Une année", value: "1 année"},
    {label: "Trois année", value: "3 année"},
    {label: "Cinq année", value: "5 année"},
    {label: "Dix année", value: "10 année"},
    {label: "Autre", value: "autre"},
  ]
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  // Ratio Gain/perte
  public polarAreaChartLabels: Label[] = ['Espéré', 'Gain', 'Perte'];
  public polarAreaChartData: SingleDataSet = [500, 400, 300];
  public polarAreaLegend = true;
  public polarAreaChartType: ChartType = 'polarArea';
  public polarAreaColors = [
    {
      backgroundColor: ['#4191ff', '#1bc943', '#f83245'],
    },
  ];
  // Ratio moyenne
  public pieChartLabels: Label[] = ['Gain', 'Perte'];
  public pieChartData: number[] = [500, 300];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(0,255,0,0.3)', 'rgba(255,0,0,0.3)'],
    },
  ];
  constructor(
    public globals: ThemeOptions
  ) {
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsInlineRangeValue = [this.bsInlineValue, this.maxDate];
  }

  ngOnInit(): void {
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  // Full calendar

    view: CalendarView = CalendarView.Month;

    CalendarView = CalendarView;

    viewDate: Date = new Date();

    events: CalendarEvent[] = [
        {
            title: 'No event end date',
            start: setHours(setMinutes(new Date(), 0), 3),
            color: colors.blue
        },
        {
            title: 'No event end date',
            start: setHours(setMinutes(new Date(), 0), 5),
            color: colors.yellow
        }
    ];

    setView(view: CalendarView) {
        this.view = view;
    }

}
