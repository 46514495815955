import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tache-pages',
  templateUrl: './tache-pages.component.html',
  styleUrls: ['./tache-pages.component.scss']
})
export class TachePagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
