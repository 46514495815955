import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReservePagesRoutingModule } from './reserve-pages-routing.module';
import { ReservePagesComponent } from './reserve-pages.component';
import { ReserveEditComponent } from './reserve-edit/reserve-edit.component';
import { ReserveDetailComponent } from './reserve-detail/reserve-detail.component';
import { ReserveListComponent } from './reserve-list/reserve-list.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule, BsDatepickerModule, BsDropdownModule, ModalModule, PaginationModule, ProgressbarModule, TabsModule, TooltipModule } from 'ngx-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RemarquePagesModule } from '../../remarque-pages/remarque-pages.module';


@NgModule({
  declarations: [
    ReservePagesComponent,
    ReserveEditComponent,
    ReserveDetailComponent,
    ReserveListComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    PaginationModule,
    TooltipModule,
    ModalModule,
    PerfectScrollbarModule,
    BsDatepickerModule,
    BsDropdownModule,
    ProgressbarModule,
    TabsModule,
    AccordionModule,
    ReservePagesRoutingModule,

    RemarquePagesModule,
  ],
  exports: [
    ReserveEditComponent,
    ReserveDetailComponent,
    ReserveListComponent,
  ]
})
export class ReservePagesModule { }
