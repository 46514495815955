import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'querystring';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from './../../../environments/environment';
import { AuthPagesService } from '../auth-pages/auth-pages.service';
import { catchError, switchMap, take, map, tap } from 'rxjs/operators';
import { Ressource } from './ressource.model';

interface ressourceData {
  id?: number,
  reference: string,
  categorieRessource: string,
  typeRessource: string,
  sousFamille: string,
  libelle: string,
  unite: string,
  etatVente: number,
  etatAchat: number,
  nature: number,
  limiteStock: number,
  physiqueStock: number,
  description: string,
  prixMoyen: number,
  coefficientMajoration: number,
  coefficientPerte: number,
  prixVenteHT: number,
  tva: number,
  published?: string,
  modified?: string,
  sous_famille?: Array<string>,
  travaux_chantier?: Array<string>,
  categorie_ressource?: Array<string>,
  auteur?: Array<string>
}

@Injectable({
  providedIn: 'root'
})
export class RessourceService {

  private _ressources = new BehaviorSubject<Ressource[]>([]);
  apiUrl = environment.apiUrl;
  prefix = environment.prefix;

  private assignData;
  constructor(private authService: AuthPagesService, private http: HttpClient) { }

  get ressources() {
    return this._ressources.asObservable();
  }



  addRessource(ressource: ressourceData) {
    return this.http.post<ressourceData>(`${this.apiUrl}/ressources`, JSON.stringify(ressource)).pipe(
      take(1),
      tap(resData => {
        return resData
      }),
      catchError(this.handleError)
    );//pipe   
  }
  updateRessource(id: number, ressource: ressourceData) {
    return this.http.put<ressourceData>(`${this.apiUrl}/ressources/` + id, JSON.stringify(ressource)).pipe(
      take(1),
      tap(resData => {
        return resData
      }),
      catchError(this.handleError)
    );//pipe   
  }
  deleteRessource(id: number) {
    return this.http.delete<ressourceData>(`${this.apiUrl}/ressources/` + id).pipe(
      take(1),
      tap(resData => {
        return resData
      }),
      catchError(this.handleError)
    );//pipe   
  }

  listerRessource() {
    return this.http.get<ressourceData[]>(
      `${this.apiUrl}/ressources`, {
      responseType: 'json'
    }
    ).pipe(
      map((response: any) => response['hydra:member']),
      catchError(this.handleError)
    )
  }



  getDataForRessource() {
    return this.assignData;
  }
  setDataForRessource(data) {
    this.assignData = data;
  }


  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "Une erreur est survenue";
    return throwError(messageError);
  }
}
