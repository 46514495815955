import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fichier-pages',
  templateUrl: './fichier-pages.component.html',
  styleUrls: ['./fichier-pages.component.scss']
})
export class FichierPagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
