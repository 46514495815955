import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilOptions } from 'src/app/shared-components/util/util-option';

@Component({
  selector: 'app-budget-edit',
  templateUrl: './budget-edit.component.html',
  styleUrls: ['./budget-edit.component.scss']
})
export class BudgetEditComponent implements OnInit {
  form: FormGroup;
  submit = false;
  required = UtilOptions.required;
  
  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.newForms();
  }

  ngOnInit() {
  }

  newForms(){
    this.form = this.formBuilder.group({
      libelle: [null, [Validators.required]],
      montant: [null, [Validators.required]],
      projet: [null, [Validators.required]],
      chantier: [null, [Validators.required]],
      description: [null, [Validators.required]],
    });
  }
  
  onSubmit(){
    this.submit = true;
    console.log(this.form.value);
    this.form.reset();
  }

  get f() { return this.form.controls; }
}
