<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-wallet display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>Trésorerie</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">Liste des tresorerie</div>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button (click)="addTresorerie('', false)" class="btn btn-success ml-2" tooltip="Créer une opportunité" type="button"><i class="fas fa-plus-circle"></i> Ajouter</button>
        </div>
    </div>
</div>
  
<!-- graphe de total trésorerie -->
<div class="bg-white mb-3 border">
    <div class="row">
        <div class="col-md-12 text-center"><h4 class="mb-0  mt-3 text-uppercase">total des montants crédit et débit des comptes trésoreries</h4></div>
        <div class="divider mt-3"></div>
        <div class="col-md-12">
            <apx-chart
                [chart]="{ type: 'bar', height: '325', sparkline: {enabled: false}, stacked: false, toolbar: {show: false}}"
                [colors]="['#f4772e', '#1bc943']"
                [dataLabels]="{enabled: false}"
                [fill]="{opacity: 1}"
                [labels]="['Jan 2021', 'Fev 2021', 'Mar 2021', 'Avr 2021', 'Mai 2021', 'Juin 2021', 'Juil 2021', 'Aou 2021', 'Sep 2021', 'Oct 2021', 'Nov 2021', 'Dec 2021']"
                [legend]="{ show: false }"
                [plotOptions]="{bar: {horizontal: false, columnWidth: '50%'}}"
                [series]="[ {name: 'Banque', data: [0, 0, 4.0, 3.8, 5.1, 3.6,4.0, 3.8, 5.1, 3.6, 3.2, 3.3]}, {name: 'Caisse', data: [2.1, 2.1, 3.0, 2.8, 4.0, 3.8, 5.1, 3.6, 4.1, 2.6, 1.2, 3.9]}]"
                [stroke]="{show: true, width: 0, colors: ['transparent']}"
            ></apx-chart>
        </div>
    </div>
</div>
<!-- Fin graphe de total trésorerie -->

<div class="card card-box border-0">
    <div class="card-header p-3">
        <div class="card-header--title font-weight-bold">Liste des trésoreris</div>
        <div class="card-header--actions">
            <div class="btn-group" dropdown>
                <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                    dropdownToggle
                    tooltip="Action"
                    type="button">
                    <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                </button>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                    <ul class="nav nav-pills flex-column p-3">
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-3">
        <div class="d-flex justify-content-between mb-3">
            <div class="search-wrapper">
                <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                <input class="form-control" placeholder="Recherche dans la liste" type="search">
            </div>
        </div>
        <div class="table-responsive m-0">
            <table class="table table-hover text-nowrap m-0">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Référence</th>
                        <th>Libelle</th>
                        <th>gérant</th>
                        <th>etat</th>
                        <th>Solde initial</th>
                        <th>action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of listTresorerie">
                        <td>
                            <span class="badge badge-{{ item?.type === 'CAISSE' ? 'success': 'warning' }}">{{ item?.type }}</span>
                        </td>
                        <td>{{ item?.reference }}</td>
                        <td>{{ item?.libelle }}</td>
                        <td>{{ item?.gerant }}</td>
                        <td>
                            <span class="badge badge-{{ item?.etat === 'OUVERT' ? 'success': 'warning' }}">{{ item?.etat }}</span>
                        </td>
                        <td><span class="text-first">{{ item?.soldeInitial | number:'': 'fr-FR'}}</span> XOF</td>
                        <td class="">
                            <button placement="auto" [routerLink]="['/pages/tresorerie/detail',item?.id]"  tooltip="Plus d'infos" class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                                <span class="btn-wrapper--icon"><i class="fas fa-eye"></i></span>
                            </button>
                            <button placement="auto" tooltip="Modifier" 
                                class="btn d-40 p-0 btn-pill m-2 btn-info" 
                                type="button" (click)="addTresorerie(item, true)">
                                <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                            </button>
                            <button  placement="auto" tooltip="Supprimer" 
                                class="btn d-40 p-0 btn-pill m-2 btn-danger" 
                                type="button" (click)="deleteTresorerie(item.id, type)">
                                <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="5">TOTAL:</td>
                        <td><span class="text-bold text-first">{{ totalSI }}</span> XOF</td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div class="card-footer p-3">
        <div class="d-flex justify-content-between">
            <pagination 
                (pageChange)="currentPage = $event" id="module-appli"
                [rotate]="rotate"
                responsive="true"
                previousLabel="Précédent"
                nextLabel="Suivant"
                class="pagination-icons pagination-primary">
            </pagination>
            <div class="d-flex align-items-center">
                <span>Show</span>
                <select class="mx-1 form-control form-control-sm" id="" name="">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="40">50</option>
                    <option value="40">100</option>
                </select>
                <span>entries</span>
            </div>
        </div>
    </div>
</div>