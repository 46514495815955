import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proces-verbal-edit',
  templateUrl: './proces-verbal-edit.component.html',
  styleUrls: ['./proces-verbal-edit.component.scss']
})
export class ProcesVerbalEditComponent implements OnInit {

  constructor( ) { }

  ngOnInit() {
  }
}
