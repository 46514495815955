import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MissionDetailComponent } from './mission-detail/mission-detail.component';
import { MissionListComponent } from './mission-list/mission-list.component';
import { MissionPagesComponent } from './mission-pages.component';


const routes: Routes = [
  {
    path:'',
    component: MissionPagesComponent,
    children: [
      {
        path: "liste",
        component: MissionListComponent
      },
      {
        path: "detail/:id",
        component: MissionDetailComponent
      },
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MissionPagesRoutingModule { }
