<div class="card p-3">
    <div class="border rounded p-3">
        <div class="d-flex justify-content-between mt-4">
            <h3 class="mb-1">PV0001</h3>
            <h3 class="mb-1 text-first">11/11/2021</h3>
        </div>

        <!-- Information générales -->
        <div class="p-0 m-0">
            <hr class="m-0">
            <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Information générales</h4></div>
            <div class="row">
                <div class="col-md-6">
                    <p class="mt-2 mb-0"><b>PV numéro:</b> <span class="ml-2">1</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Référence:</b> <span class="ml-2">RP0001</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Etat:</b> <span class="ml-2 text-success">Validé</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Validateur:</b> <span class="ml-2">Kouassi ghislain</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Fonction validateur:</b> <span class="ml-2">Chef projet</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Projet:</b> <span class="ml-2">Projet 1</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Chantier:</b> <span class="ml-2">Chantier 1</span></p>
                    <hr class="my-2">
                    <p class="mb-0"><b>Nom de la visite:</b> <span class="ml-2">Visite de chantier 1</span></p>
                </div>
                <div class="col-md-6">
                    <p class="mt-2 mb-0"><b>Date de la visite:</b> <span class="ml-2 text-dark">31/07/2021</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Description:</b> <span class="ml-2">Une petite description</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de la prochaine réunion:</b> <span class="ml-2 text-dark">31/07/2021 11:11:11</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Phase du proje:</b> <span class="ml-2">En cours</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Créer par:</b> <span class="ml-2">Kouassi ghislain</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de création:</b> <span class="ml-2 text-dark">11/11/2021 11:11:11</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Modifier par:</b> <span class="ml-2">Kouassi ghislain</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de création:</b> <span class="ml-2 text-dark">11/11/2021 11:11:11</span></p>
                </div>
            </div>
        </div>
        <hr class="mt-2 mb-5">

        <!-- Intervenants -->
        <div class="p-0 m-0">
            <hr class="m-0">
            <div class="p-2 bg-secondary">
                <h4 class="m-0 font-weight-bold text-first">Intervenants (2)</h4>
            </div>
            <div class="table-responsive m-0">
                <table class="table m-0">
                    <thead class="bg-white">
                        <tr>
                            <th  class="bg-white">Intervenant</th>
                            <th  class="bg-white">Type</th>
                            <th  class="bg-white">Contact</th>
                            <th  class="bg-white">Email</th>
                            <th  class="bg-white">Présence</th>
                            <th  class="bg-white">Signature</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="d-flex align-items-center">
                                    <div class="avatar-icon-wrapper mr-2">
                                        <div class="avatar-icon">
                                            <img alt="..." src="assets/images/avatars/avatar2.jpg"/>
                                        </div>
                                    </div>
                                    <div>
                                        <span class="font-weight-bold text-black" title="Kouassi ghislain">Kouassi ghislain</span>
                                        <span class="text-black-50 d-block">Chef chantier</span>
                                    </div>
                                </div>
                            </td>
                            <td><span class="font-weight-bold text-dark">Particulier</span></td>
                            <td><span class="font-weight-bold">00 00 00 00 00</span></td>
                            <td><span class="text-warning">exemple@gmail.com</span></td>
                            <td><span class="font-weight-bold text-success">P</span></td>
                            <td>
                                <div>
                                    <img src="assets/img/signature.png" alt="" class="shadow-sm" style="height: 50px; width: 50px">
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-items-center">
                                    <div class="avatar-icon-wrapper mr-2">
                                        <div class="avatar-icon">
                                            <img alt="..." src="assets/images/avatars/avatar2.jpg"/>
                                        </div>
                                    </div>
                                    <div>
                                        <span class="font-weight-bold text-black" title="Kouassi ghislain">Kouassi ghislain</span>
                                        <span class="text-black-50 d-block">Chef chantier</span>
                                    </div>
                                </div>
                            </td>
                            <td><span class="font-weight-bold text-dark">Particulier</span></td>
                            <td><span class="font-weight-bold">00 00 00 00 00</span></td>
                            <td><span class="text-warning">exemple@gmail.com</span></td>
                            <td><span class="font-weight-bold text-danger">A</span></td>
                            <td>
                                <div>
                                    <img src="assets/img/signature.png" alt="" class="shadow-sm" style="height: 50px; width: 50px">
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <hr class="mt-0 mb-5">

        <!-- Travaux concernés -->
        <div class="p-0 m-0">
            <hr class="m-0">
            <div class="p-2 bg-secondary">
                <h4 class="m-0 font-weight-bold text-first">Travaux concernés (3)</h4>
            </div>
            <hr class="m-0">
            <div class="list-group list-group-flush">
                <li class="list-group-item list-group-item-action align-box-row">
                    <fa-icon [icon]="['far', 'folder']" class="font-size-lg mr-3"></fa-icon>
                    <div>
                        <div>Travaux 1</div>
                    </div>
                </li>
                <li class="list-group-item list-group-item-action align-box-row">
                    <fa-icon [icon]="['far', 'folder-open']" class="font-size-lg mr-3"></fa-icon>
                    <div>
                        <div>Travaux 2</div>
                    </div>
                </li>
                <li class="list-group-item list-group-item-action align-box-row">
                    <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                    <div>
                        <div>Travaux 2.1</div>
                    </div>
                </li>
                <li class="list-group-item list-group-item-action align-box-row">
                    <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                    <div>
                        <div>Travaux 2.2</div>
                    </div>
                </li>
                <li class="list-group-item list-group-item-action align-box-row">
                    <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                    <div>
                        <div>Travaux 2.3</div>
                    </div>
                </li>
                
                <li class="list-group-item list-group-item-action align-box-row">
                    <fa-icon [icon]="['far', 'folder']" class="font-size-lg mr-3"></fa-icon>                            <div>
                        <div>Travaux 3</div>
                    </div>
                </li>
            </div>
        </div>
        <hr class="mt-0 mb-5">

        <!-- Remarque lors de la visite -->
        <div class="p-0 m-0">
            <hr class="m-0">
            <div class="p-2 bg-secondary">
                <h4 class="m-0 font-weight-bold text-first">Remarque lors de la visite (2)</h4>
            </div>
            <hr class="m-0">
            <div class="pt-2">
                <div class="border rounded-sm p-2">
                    <div class="d-flex align-items-center mb-2">
                        <div class="d-40 rounded-circle bg-success text-white mr-3 text-center">1</div>
                        <div class="flex-grow-1">
                            <div class="font-weight-bold text-success pb-1">Clôturer</div>
                            <div class="text-dark"><fa-icon [icon]="['far', 'calendar-alt']"></fa-icon> Clôturé le: 11/11/2021 11:11:11</div>
                        </div>
                    </div>
                    <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0"><b>Intervenant associé:</b> <span class="ml-2">Kouassi ghislain</span></p></div>
                    <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0"><b>Position sur plan:</b> <span class="ml-2">2</span></p></div>
                    <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0"><b>Images associées:</b> <span class="ml-2">1</span></p></div>
                    <div class="border rounded-sm p-2 text-center">
                        <div class="grid-menu grid-menu-2col">
                            <div class="no-gutters row">
                                <div class="col-sm-6">
                                    <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                                <div class="col-sm-6">
                                    <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                                <div class="col-sm-6">
                                    <img src="assets/img/i2.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="my-2">
                <div class="border rounded-sm p-2">
                    <div class="d-flex align-items-center mb-2">
                        <div class="d-40 rounded-circle bg-success text-white mr-3 text-center">2</div>
                        <div class="flex-grow-1">
                            <div class="font-weight-bold text-warning pb-1">En attente</div>
                            <div class="text-dark"><fa-icon [icon]="['far', 'calendar-alt']"></fa-icon> En attente depuis: 11/11/2021</div>
                        </div>
                    </div>
                    <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0"><b>Intervenant associé:</b> <span class="ml-2">Kader mondesir</span></p></div>
                    <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0"><b>Position sur plan:</b> <span class="ml-2">1</span></p></div>
                    <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0"><b>Images associées:</b> <span class="ml-2">1</span></p></div>
                    <div class="border rounded-sm p-2 text-center">
                        <div class="grid-menu grid-menu-2col">
                            <div class="no-gutters row">
                                <div class="col-sm-6">
                                    <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                                <div class="col-sm-6">
                                    <img src="assets/img/i2.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="mt-0 mb-5">

        <!-- Annexe -->
        <div class="p-0 m-0">
            <hr class="m-0">
            <div class="p-2 bg-secondary">
                <h4 class="m-0 font-weight-bold text-first">Annexe</h4>
            </div>
            <hr class="m-0">
            <div class="p-2 rounded-sm border mb-3">
                <div class="p-0 m-0">
                    <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0 font-weight-bold">Images travaux 1</p></div>
                    <div class="p-2 border rounded-sm text-center">
                        <div class="grid-menu grid-menu-2col">
                            <div class="no-gutters row">
                                <div class="col-sm-6">
                                    <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                                <div class="col-sm-6">
                                    <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                                <div class="col-sm-6">
                                    <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                                <div class="col-sm-6">
                                    <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="my-2">
                <div class="p-0 m-0">
                    <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0 font-weight-bold">Images travaux 2</p></div>
                    <div class="p-2 border rounded-sm text-center">
                        <div class="grid-menu grid-menu-2col">
                            <div class="no-gutters row">
                                <div class="col-sm-6">
                                    <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                                <div class="col-sm-6">
                                    <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                                <div class="col-sm-6">
                                    <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                                <div class="col-sm-6">
                                    <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="my-2">
                <div class="p-0 m-0">
                    <div class="border rounded-sm p-2 mb-2 bg-light"><p class="m-0 font-weight-bold">Images travaux 3</p></div>
                    <div class="p-2 border rounded-sm text-center">
                        <div class="grid-menu grid-menu-2col">
                            <div class="no-gutters row">
                                <div class="col-sm-6">
                                    <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                                <div class="col-sm-6">
                                    <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                                <div class="col-sm-6">
                                    <img src="assets/img/pl1.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                                <div class="col-sm-6">
                                    <img src="assets/img/pl2.jpg" alt="" style="height: 400px; width: 350px">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-right mt-3">
        <button class="btn btn-success mr-2" type="button"><i class="fas fa-check-circle mr-1"></i>Valider</button>
        <button class="btn btn-warning mr-2" type="button"><i class="ion-md-print mr-1"></i>Imprimer</button>
        <button class="btn btn-danger" type="button"><i class="ion-md-trash mr-1"></i>Supprimer</button>
    </div>
</div>