import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-visite-detail',
  templateUrl: './visite-detail.component.html',
  styleUrls: ['./visite-detail.component.scss']
})
export class VisiteDetailComponent implements OnInit {
  
  constructor(
    public globals: ThemeOptions,
  ) { }

  ngOnInit() {}
}
