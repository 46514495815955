import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { ThemeOptions } from 'src/app/theme-options';
import { TresorerieService } from '../tresorerie.service';
import { Banque, Caisse, Tresorerie } from '../tresorerie.model';
import { TresorerieEditComponent } from '../tresorerie-edit/tresorerie-edit.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tresorerie-list',
  templateUrl: './tresorerie-list.component.html',
  styleUrls: ['./tresorerie-list.component.scss']
})
export class TresorerieListComponent implements OnInit {
  rotate = true;
  errMsg = "";
  type: string = 'CAISSE';
  totalSI = 0;
  totalSMi = 0;
  totalSMa = 0;
  currentPage = 1;
  itemsPerPage = 5;
  tresoreries: any;
  caissesFusion = [];
  banquesFusion = [];
  listTresorerie = [];
  caisses: Caisse[] = [];
  banques: Banque[] = [];
  caisseSubs = new Subscription; 
  banqueSubs = new Subscription; 
  private notifier: NotifierService;

  public constructor(
    public route: Router,
    notifier: NotifierService,
    public globals: ThemeOptions,
    private modalService: NgbModal,
    private tresorerieService: TresorerieService
  ) {
    this.notifier = notifier;
  }

  ngOnInit() {
    this.loadTresorerie();
    this.onCalcul();
  }

  loadTresorerie(){
    this.caisses = [];
    this.banques = [];
    this.caisseSubs = this.tresorerieService.getCaisse().subscribe(
      (res) => {
        this.caisses = res;
        this.caissesFusion =this.fusion(this.caisses, 'CAISSE')
        this.banqueSubs = this.tresorerieService.getBanque().subscribe(
          (res) => {
            this.banques = res;
            this.banquesFusion = this.fusion(this.banques, 'BANQUE')
            this.listTresorerie = this.caissesFusion.concat(this.banquesFusion);
          },
          errorRes => {this.notifications('danger', "L'opération a échoué");}
        );
      },
      errorRes => {this.notifications('danger', "L'opération a échoué");}
    );    
  }

  // Fusionne la liste des caisse et celle des banque
  fusion(data, key): any {
    const listTresorerie = [];
    data.forEach((item) => {
      const treso = new Tresorerie();
      switch (key) {
        case 'CAISSE':
          treso.id = item.id;
          treso.type = item.type;
          treso.reference = item.reference;
          treso.libelle = item.libelle;
          treso.devise = item.devise;
          treso.etat = item.etat;
          treso.gerant = item.gerant;
          treso.soldeInitial = item.soldeInitial;
          treso.seuilMin = item.seuilMin;
          treso.seuilMax = item.seuilMax; 
          treso.disponibilite = item.disponibilite;      
          break;
        case 'BANQUE':
          treso.id = item.id;
          treso.type = item.type;
          treso.reference = item.reference;
          treso.libelle = item.libelle;
          treso.devise = item.devise;
          treso.etat = item.etat;
          treso.gerant = item.gerant;
          treso.soldeInitial = item.soldeInitial;
          treso.numCompte = item.numCompte;  
          treso.codeIban = item.codeIban;  
          treso.codeBS = item.codeBS;  
          treso.codeJournal = item.codeJournal;  
          treso.domiciliation = item.domiciliation;  
          treso.disponibilite = item.disponibilite;  
          break;
        default:
          return;
      }
      listTresorerie.push(treso);
    });
    this.tresoreries = listTresorerie
    return this.tresoreries;
  }

  onCalcul(){
    this.caisses.forEach( res => {
      this.totalSI += (res.soldeInitial ? res.soldeInitial : 0)
      this.totalSMi += (res.seuilMin ? res.seuilMin : 0)
      this.totalSMa += (res.seuilMax ? res.seuilMax : 0)
    })
    this.banques.forEach( res => {
      this.totalSI += (res.soldeInitial ? res.soldeInitial : 0)
    })
  }

  sendData(data, action) {
    const sender = {
      tresorerie: data,
      action: action // Action =  soit creation ou modification
    };
    this.tresorerieService.setTresorerie(sender)
  }
  
  addTresorerie(data, action) {
    if(data) {
      this.sendData(data, action);
    }
    this.modalService.dismissAll();
    this.modal(TresorerieEditComponent, 'modal-basic-title', 'xl', true, 'static');
  }
  
  deleteTresorerie(id, type) {
    type = this.tresorerieService.type
    Swal.fire({
      title: 'Supprimer !',
      text: 'Voulez-vous vraiment supprimer cette tresorerie ?',
      type: "warning",
      showCancelButton: true,
      showCloseButton: false,
      cancelButtonText: 'Annuler <i class="fas fa-times"></i>',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Supprimer <i class="fas fa-trash"></i>',
    }).then((deleted) => {
      if (deleted.dismiss) {
      } else {
        this.tresorerieService.onDelete(id, type).subscribe(
          res => {
            this.notifications('success', 'Enrégistrement supprimé avec succès !');
          }
        );
        this.loadTresorerie()
      }
    });
  }

  pageChanged(event:any) {
    this.currentPage = event.page;
  }
  
  ngOnDestroy(): void {
    this.caisseSubs.unsubscribe();
    this.banqueSubs.unsubscribe();
    this.tresorerieService.setTresorerie("")
  }

  modal(component, type, size, center, backdrop): any {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {
      if(result == 'success') {
        this.notifications('success', "L'opération s'est bien déroulé");
        this.loadTresorerie()
      } else if (result == 'update') {
        this.notifications('success', "L'opération s'est bien déroulé");
        this.loadTresorerie();
      }      
    }, (reason) => { });
  }
  
  notifications( type: string, message: string ): void {
    if (type == 'primary') {
      this.notifier.notify( type, message );
    } else if (type == 'success') {
      this.notifier.notify( type, message );
    } else if (type == 'warning') {
      this.notifier.notify( type, message );
    } else if (type == 'danger') {
      this.notifier.notify( type, message );
    } else if (type == 'dark') {
      this.notifier.notify( type, message );
    } else if (type == 'second') {
      this.notifier.notify( type, message );
    } else if (type == 'first') {
      this.notifier.notify( type, message );
    }
  }
}
