<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first">
        <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
            <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                <i class="pe-7s-wallet display-3 gradient-icon bg-ripe-malin"></i>
            </div>
        </div>
        <div class="app-page-title--heading">
            <h1>Accueil </h1>
            <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">
                Tableau de bord
            </div>
        </div>
    </div>
</div>


<!-- <div class="row">

        <div class=" col-sm-12 col-md-8   card card-box mb-1">

                <div class="card-header">
                    <div class="card-header--title font-size-lg text-dark">
                        Gestions des tâches Opportunités projets
                    </div>
                </div>

                   <div class="table-responsive">
                        <perfect-scrollbar  [autoPropagation]="true" class="scroll-area rounded bg-white shadow-overflow">
                            <table class="table table-hover text-nowrap mb-0">
                            <thead>
                            <tr>
                                <th class="text-left" style="width: 180px;">Projet</th>
                                <th class="text-center">Date d'assignation</th>
                                <th class="text-center">Date d'échéance</th>
                                <th class="text-center">Status</th>
                                <th class="text-center">Temps consommés</th>
                                <th class="text-center">Responsable</th>
                                <th class="text-left">Action</th>
                            </tr>
                            </thead>

                            <tbody>

                                <tr>
                                    <td class="font-weight-bold">
                                        Projet de contruction R+3
                                    </td>
                                    <td class="text-center">
                                        01 Juin 2020
                                    </td>

                                    <td class="text-center">
                                        09 Juin 2020
                                    </td>
                                    <td>
                                        <progressbar [value]="100" class="progress-sm" type="success"></progressbar>
                                        <div class="font-size-sm text-black-50 pt-1">
                                            Terminé
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        4h20mn
                                    </td>
                                    <td class="text-center">
                                        <div>
                                            Gérome saki
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <button (click)="openModalTask.show()" class="btn btn-sm btn-link" tooltip="View details" type="button">
                                            <i class="fas fa-list"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">
                                        Projet de contruction R+3
                                    </td>
                                    <td class="text-center">
                                        01 Juin 2020
                                    </td>

                                    <td class="text-center">
                                        09 Juin 2020
                                    </td>
                                    <td>
                                        <progressbar [value]="18" class="progress-sm" type="danger"></progressbar>
                                        <div class="font-size-sm text-black-50 pt-1">
                                            Terminé
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        4h20mn
                                    </td>
                                    <td class="text-center">
                                        <div>
                                            Gérome saki
                                        </div>
                                    </td>
                                    <td class="text-left">
                                        <button class="btn btn-sm btn-link" tooltip="View details" type="button">
                                            <i class="fas fa-list"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">
                                        Projet de contruction R+3
                                    </td>
                                    <td class="text-center">
                                        01 Juin 2020
                                    </td>

                                    <td class="text-center">
                                        09 Juin 2020
                                    </td>
                                    <td>
                                        <progressbar [value]="70" class="progress-sm" type="warning"></progressbar>
                                        <div class="font-size-sm text-black-50 pt-1">
                                            Terminé
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        4h20mn
                                    </td>
                                    <td class="text-center">
                                        <div>
                                            Gérome saki
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-link" tooltip="View details" type="button">
                                            <i class="fas fa-list"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">
                                        Projet de contruction R+3
                                    </td>
                                    <td class="text-center">
                                        01 Juin 2020
                                    </td>

                                    <td class="text-center">
                                        09 Juin 2020
                                    </td>
                                    <td>
                                        <progressbar [value]="80" class="progress-sm" type="first"></progressbar>
                                        <div class="font-size-sm text-black-50 pt-1">
                                            Terminé
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        4h20mn
                                    </td>
                                    <td class="text-center">
                                        <div>
                                            Gérome saki
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-link" tooltip="View details" type="button">
                                            <i class="fas fa-list"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">
                                        Projet de contruction R+3
                                    </td>
                                    <td class="text-center">
                                        01 Juin 2020
                                    </td>

                                    <td class="text-center">
                                        09 Juin 2020
                                    </td>
                                    <td>
                                        <progressbar [value]="18" class="progress-sm" type="danger"></progressbar>
                                        <div class="font-size-sm text-black-50 pt-1">
                                            Terminé
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        4h20mn
                                    </td>
                                    <td class="text-center">
                                        <div>
                                            Gérome saki
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-link" tooltip="Voir detail" type="button">
                                            <i class="fas fa-list"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">
                                        Projet de contruction R+3
                                    </td>
                                    <td class="text-center">
                                        01 Juin 2020
                                    </td>

                                    <td class="text-center">
                                        09 Juin 2020
                                    </td>
                                    <td>
                                        <progressbar [value]="70" class="progress-sm" type="warning"></progressbar>
                                        <div class="font-size-sm text-black-50 pt-1">
                                            Terminé
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        4h20mn
                                    </td>
                                    <td class="text-center">
                                        <div>
                                            Gérome saki
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-link" tooltip="View details" type="button">
                                            <i class="fas fa-list"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">
                                        Projet de contruction R+3
                                    </td>
                                    <td class="text-center">
                                        01 Juin 2020
                                    </td>

                                    <td class="text-center">
                                        09 Juin 2020
                                    </td>
                                    <td>
                                        <progressbar [value]="80" class="progress-sm" type="first"></progressbar>
                                        <div class="font-size-sm text-black-50 pt-1">
                                            Terminé
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        4h20mn
                                    </td>
                                    <td class="text-center">
                                        <div>
                                            Gérome saki
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-link" tooltip="View details" type="button">
                                            <i class="fas fa-list"></i>
                                        </button>
                                    </td>
                                </tr>

                            </tbody>

                            </table>
                        </perfect-scrollbar>
                    </div>
        </div>

</div> -->

<!--MODAL AJOUT OPPORTUNITE-->
<!-- <div #openModalTask="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
              <h6 class="modal-title">Détail opportunité</h6>
              <button type="button" class="close" aria-label="Close" (click)="openModalTask.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="col-xl-12">
                    <tabset type="tabs" class="nav-tabs-danger mb-2">
                        <tab>
                            <ng-template tabHeading>
                                Tâche
                            </ng-template>
                            <div class="col-lg-12  mt-2">
                                <div class="card  card-box mb-2 p-4">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="d-flex align-items-center mb-3">
                                                <div class="w-100">
                                                    <a [routerLink]="" class="font-weight-bold font-size-lg" title="...">Projet de construction R+8</a>
                                                    <span class="text-black-50 d-block">Tâche:Etude de prix</span>
                                                    <div class="d-flex align-items-center pt-1">
                                                        <progressbar [animate]="true" [value]="56"
                                                                    class="progress-bar-rounded progress-xs progress-animated-alt"
                                                                    type="danger"></progressbar>
                                                        <div class="font-weight-bold text-black-50 pl-2">56%</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span class="font-weight-bold">
                                                Description:
                                            </span>
                                            <p class="text-black-50 font-size-md mb-0">
                                                From its medieval origins to the digital era, learn everything there is to know about the
                                                ubiquitous.From its medieval origins to the digital era, learn everything there is to know about the

                                            </p>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="my-4 font-size-sm p-3 bg-secondary rounded-sm">
                                                <div class="d-flex justify-content-between">
                                                        <span class="font-weight-bold">
                                                            Responsable Opportunité :
                                                        </span>
                                                    <span class="text-black-50">russotry@russo.com</span>
                                                </div>
                                                <div class="d-flex justify-content-between py-2">
                                                        <span class="font-weight-bold">
                                                            Numéro appel offre :
                                                        </span>
                                                    <span class="text-black-50">PJR-852147963</span>
                                                </div>
                                                <div class="d-flex justify-content-between">
                                                        <span class="font-weight-bold">
                                                            Maitre d'ouvrage :
                                                        </span>
                                                    <span class="text-black-50">Gerome Assi</span>
                                                </div>
                                                <div class="d-flex justify-content-between">
                                                    <span class="font-weight-bold">
                                                        Date d'échéance:
                                                    </span>
                                                <span class="text-black-50">12-05-2021</span>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab>
                            <ng-template tabHeading>
                                Contact de la tâche
                            </ng-template>
                            <p class="mb-0 p-3">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.</p>
                        </tab>
                        <tab>
                            <ng-template tabHeading>
                                Temps consommés
                            </ng-template>
                            <div class="row no-gutters">

                              </div>
                              <div class="row no-gutters">
                                <div class="col-sm-12 mb-2 mt-2">
                                    <form>
                                        <div class="form-group row mb-0">
                                            <div class="col-md-2">
                                                <label for="exampleEmail">Date début</label>
                                                <div class="input-group input-group-sm">

                                                    <input id="dateLancement"
                                                        name="dateLancement"
                                                        bsDatepicker class="form-control form-control-sm"
                                                        [textMask]="{mask: maskDate}"
                                                        placeholder="Entrer la date" type="text"
                                                    />
                                                </div>
                                            </div>

                                             <div class="col-md-2">
                                                <label for="exampleEmail">Hr:mn</label>
                                                <div class="input-group input-group-sm">
                                                    <input class="form-control form-control-sm"
                                                            id="heure" formControlName="heure"
                                                            placeholder="heure" type="number">

                                                            <input class="form-control form-control-sm"
                                                            id="heure" formControlName="minute"
                                                            placeholder="minute" type="number">
                                                </div>
                                            </div>


                                            <div class="col-md-2">
                                                <label for="exampleEmail">Date fin</label>
                                                <div class="input-group">

                                                    <input [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }"
                                                        id="dateLancement"
                                                        name="dateLancement"
                                                        bsDatepicker class="form-control form-control-sm"
                                                        [textMask]="{mask: maskDate}"
                                                        placeholder="Entrer la date" type="text"
                                                    />


                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <label for="exampleEmail">Hr:mn</label>
                                                <div class="input-group input-group-sm">
                                                    <input class="form-control form-control-sm"
                                                            id="heure" formControlName="heure"
                                                            placeholder="heure" type="number">

                                                            <input class="form-control form-control-sm"
                                                            id="heure" formControlName="minute"
                                                            placeholder="minute" type="number">
                                                </div>
                                            </div>

                                            <div class="col-md-2">
                                                <label for="numOrdre">Progression<span class="text-danger">*</span></label>
                                                <input class="form-control form-control-sm"
                                                    id="progression" formControlName="progression"
                                                    placeholder="Progression" type="number">
                                            </div>
                                            <div class="col-md-2">
                                                <label for="numOrdre">Note<span class="text-danger">*</span></label>
                                                <input class="form-control form-control-sm"
                                                    id="note" formControlName="note"
                                                    placeholder="note" type="number">
                                            </div>
                                            <div class="col-md-4 button">
                                                <label>.</label>
                                                <div>
                                                    <button class="btn btn-sm btn-success" type="button"><i class="fas fa-save"></i> Enregistrer</button>
                                                    <button class="btn btn-sm btn-secondary ml-2" type="button" ><i class="fas fa-times"></i> Annuler</button>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-sm-12 card card-box mb-1">
                                    <div class="card-header">
                                        <div class="card-header--title font-size-lg text-dark">
                                            Liste du temps consommé sur la tâche
                                            <span class="badge m-1 badge-primary">Durée totale: 16h35mn</span>
                                            <div class="divider mb-4"></div>
                                            <progressbar type="info" [animate]="true" [striped]="true" [value]="55" class="mb-4 progress-bar-rounded">Progression totale 55%</progressbar>
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                            <perfect-scrollbar  [autoPropagation]="true" class="scroll-area rounded bg-white shadow-overflow">
                                                <table class="table table-hover text-nowrap mb-0">
                                                <thead>
                                                <tr>
                                                    <th class="text-center">Date</th>
                                                    <th class="text-center">Durée</th>
                                                    <th class="text-center">Progression</th>
                                                    <th class="text-center">Note</th>
                                                    <th class="text-center">Action</th>
                                                </tr>
                                                </thead>

                                                <tbody>

                                                    <tr>
                                                        <td class="text-center font-weight-bold">
                                                            12-05-2017
                                                        </td>
                                                        <td class="text-center">
                                                            4h20mn
                                                        </td>

                                                        <td class="text-center">
                                                            <div class="badge bg-neutral-first text-first px-4">10%</div>
                                                        </td>
                                                        <td class="text-center">
                                                            4
                                                        </td>

                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-link" tooltip="Supprimer" type="button">
                                                                <i class="fas fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class=" text-center font-weight-bold">
                                                            12-05-2017
                                                        </td>
                                                        <td class="text-center">
                                                            4h20mn
                                                        </td>

                                                        <td class="text-center">
                                                            <div class="badge bg-neutral-first text-first px-4">10%</div>
                                                        </td>
                                                        <td class="text-center">
                                                            4
                                                        </td>

                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-link" tooltip="Supprimer" type="button">
                                                                <i class="fas fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center font-weight-bold">
                                                            12-05-2017
                                                        </td>
                                                        <td class="text-center">
                                                            4h20mn
                                                        </td>

                                                        <td class="text-center">
                                                            <div class="badge bg-neutral-first text-first px-4">10%</div>
                                                        </td>
                                                        <td class="text-center">
                                                            4
                                                        </td>

                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-link" tooltip="Supprimer" type="button">
                                                                <i class="fas fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center font-weight-bold">
                                                            12-05-2017
                                                        </td>
                                                        <td class="text-center">
                                                            4h20mn
                                                        </td>

                                                        <td class="text-center">
                                                            <div class="badge bg-neutral-first text-first px-4">10%</div>
                                                        </td>
                                                        <td class="text-center">
                                                            4
                                                        </td>

                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-link" tooltip="Supprimer" type="button">
                                                                <i class="fas fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center font-weight-bold">
                                                            12-05-2017
                                                        </td>
                                                        <td class="text-center">
                                                            4h20mn
                                                        </td>

                                                        <td class="text-center">
                                                            <div class="badge bg-neutral-first text-first px-4">10%</div>
                                                        </td>
                                                        <td class="text-center">
                                                            4
                                                        </td>

                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-link" tooltip="Supprimer" type="button">
                                                                <i class="fas fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center font-weight-bold">
                                                            12-05-2017
                                                        </td>
                                                        <td class="text-center">
                                                            4h20mn
                                                        </td>

                                                        <td class="text-center">
                                                            <div class="badge bg-neutral-first text-first px-4">10%</div>
                                                        </td>
                                                        <td class="text-center">
                                                            4
                                                        </td>

                                                        <td class="text-center">
                                                            <button class="btn btn-sm btn-link" tooltip="Supprimer" type="button">
                                                                <i class="fas fa-trash"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                                </table>
                                            </perfect-scrollbar>
                                    </div>
                            </div>
                              </div>
                        </tab>
                        <tab>
                            <ng-template tabHeading>
                                Notes
                            </ng-template>
                            <p class="mb-0 p-3">I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents.</p>
                        </tab>
                        <tab>
                            <ng-template tabHeading>
                                Fichier joints
                            </ng-template>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    <div class="card card-box">
                                        <div class="d-flex bg-secondary d-lg-none p-4 order-0 justify-content-between align-items-center">
                                            <button (click)="toggleMobileSidebar1 = !toggleMobileSidebar1" class="btn btn-sm btn-primary px-3" type="button">
                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                            </button>
                                            <button (click)="toggleMobileSidebar2 = !toggleMobileSidebar2" class="btn btn-sm btn-primary px-3" type="button">
                                                <fa-icon [icon]="['fas', 'bars']"></fa-icon>
                                            </button>
                                        </div>
                                        <div [ngClass]="{'layout-sidebar-open' : toggleMobileSidebar1}" class="app-inner-content-layout--sidebar bg-white app-inner-content-layout--sidebar__sm">
                                            <div class="p-4">
                                                <button class="btn btn-block btn-sm btn-success" type="button">
                                                      <span class="btn-wrapper--icon">
                                                          <fa-icon [icon]="['fas', 'upload']"></fa-icon>
                                                      </span>
                                                    <span class="btn-wrapper--label">Upload</span>
                                                </button>
                                            </div>
                                            <perfect-scrollbar [autoPropagation]="true" class="px-4">
                                                <ul class="nav nav-neutral-primary nav-pills flex-column">

                                                    <li class="nav-item">
                                                        <a [routerLink]="" class="nav-link active">
                                                            <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'file-alt']" class="mx-auto"></fa-icon></span>
                                                            Tous les fichiers
                                                        </a>
                                                    </li>

                                                    <li class="nav-item">
                                                        <a [routerLink]="" class="nav-link">
                                                            <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'keyboard']" class="mx-auto"></fa-icon></span>
                                                            Fichier récents
                                                        </a>
                                                    </li>

                                                    <li class="nav-header text-uppercase font-size-sm text-muted pb-2">
                                                        Type de fichier
                                                    </li>
                                                    <li class="nav-item">
                                                        <a [routerLink]="" class="nav-link">
                                                            <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'file-image']" class="mx-auto"></fa-icon></span>
                                                            Images
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a [routerLink]="" class="nav-link">
                                                            <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'file-alt']" class="mx-auto"></fa-icon></span>
                                                            Documents
                                                        </a>
                                                    </li>
                                                </ul>
                                            </perfect-scrollbar>
                                            <div class="app-content--sidebar__footer px-4 py-2">
                                                <ul class="nav nav-neutral-primary nav-pills flex-column">
                                                    <li class="nav-header text-uppercase font-size-sm text-muted pb-2">
                                                        Espace disponible
                                                    </li>
                                                    <li class="nav-item">
                                                        <div class="d-flex align-items-center">
                                                            <div class="display-3">
                                                                <fa-icon [icon]="['far', 'object-group']"></fa-icon>
                                                            </div>
                                                            <div class="pl-3">
                                                                <small class="d-block mb-2"><b>5GB</b> used out of <b>25GB</b></small>
                                                                <progressbar [animate]="true" [value]="87" class="progress-bar-rounded progress-animated-alt" type="warning">87%</progressbar>
                                                            </div>
                                                        </div>
                                                        <small class="d-block mt-2">
                                                                    <span class="d-block pb-2">
                                                                        Obtenez <b>10 GB</b> d'espace de stockage avec le forfait premium
                                                                    </span>
                                                            <a [routerLink]="" class="btn p-0 font-weight-bold font-size-xs btn-link btn-link-first" title="Learn more">Détails</a>
                                                        </small>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="card card-box mb-5">
                                                <div class="card-body">
                                                    <div class="card-img-wrapper">
                                                        <div class="card-badges card-badges-top">
                                                            <button class="btn btn-link p-1 shadow-none btn-link-primary" type="button">
                                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                                            </button>
                                                        </div>
                                                        <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                                            <fa-icon [icon]="['far', 'file-word']" class="display-2 text-first mx-auto"></fa-icon>
                                                        </div>
                                                        <a [routerLink]="">
                                                            <b>Example_WordFile.doc</b>
                                                        </a>
                                                        <div>
                                                            <small class="d-block text-black-50 pb-2">15.2MB</small>
                                                            <small class="opacity-6">
                                                                Modified on: <span class="text-black-50">12.06.2020</span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="card card-box mb-5">
                                                <div class="card-body">
                                                    <div class="card-img-wrapper">
                                                        <div class="card-badges card-badges-top">
                                                            <button class="btn btn-link p-1 shadow-none btn-link-primary" type="button">
                                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                                            </button>
                                                        </div>
                                                        <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                                            <fa-icon [icon]="['far', 'file-excel']" class="display-2 text-warning mx-auto"></fa-icon>
                                                        </div>
                                                        <a [routerLink]="">
                                                            <b>Excel_2002.xls</b>
                                                        </a>
                                                        <div>
                                                            <small class="d-block text-black-50 pb-2">3.1MB</small>
                                                            <small class="opacity-6">
                                                                Modified: <span class="text-black-50">5 hours ago</span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="card card-box mb-5">
                                                <div class="card-body">
                                                    <div class="card-img-wrapper">
                                                        <div class="card-badges card-badges-top">
                                                            <button class="btn btn-link p-1 shadow-none btn-link-primary" type="button">
                                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                                            </button>
                                                        </div>
                                                        <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                                            <fa-icon [icon]="['far', 'file-archive']" class="display-2 text-danger mx-auto"></fa-icon>
                                                        </div>
                                                        <a [routerLink]="">
                                                            <b>AllPhotos.zip</b>
                                                        </a>
                                                        <div>
                                                            <small class="d-block text-black-50 pb-2">15.2MB</small>
                                                            <small class="opacity-6">
                                                                Modified on: <span class="text-black-50">5 January 2000</span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="card card-box mb-5">
                                                <div class="card-body">
                                                    <div class="card-img-wrapper">
                                                        <div class="card-badges card-badges-top">
                                                            <button class="btn btn-link p-1 shadow-none btn-link-primary" type="button">
                                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                                            </button>
                                                        </div>
                                                        <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                                            <fa-icon [icon]="['far', 'file-image']" class="display-2 text-info mx-auto"></fa-icon>
                                                        </div>
                                                        <a [routerLink]="">
                                                            <b>IMG_063037.jpg</b>
                                                        </a>
                                                        <div>
                                                            <small class="d-block text-black-50 pb-2">2.2mb</small>
                                                            <small class="opacity-6">
                                                                Modified on: <span class="text-black-50">12.06.2020</span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="card card-box mb-5">
                                                <div class="card-body">
                                                    <div class="card-img-wrapper">
                                                        <div class="card-badges card-badges-top">
                                                            <button class="btn btn-link p-1 shadow-none btn-link-primary" type="button">
                                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                                            </button>
                                                        </div>
                                                        <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                                            <fa-icon [icon]="['far', 'file-audio']" class="display-2 text-second mx-auto"></fa-icon>
                                                        </div>
                                                        <a [routerLink]="">
                                                            <b>Audio.mp3</b>
                                                        </a>
                                                        <div>
                                                            <small class="d-block text-black-50 pb-2">745,3 kb</small>
                                                            <small class="opacity-6">
                                                                Modified on: <span class="text-black-50">22.11.2019</span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="card card-box mb-5">
                                                <div class="card-body">
                                                    <div class="card-img-wrapper">
                                                        <div class="card-badges card-badges-top">
                                                            <button class="btn btn-link p-1 shadow-none btn-link-primary" type="button">
                                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                                            </button>
                                                        </div>
                                                        <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                                            <fa-icon [icon]="['far', 'file-word']" class="display-2 text-first mx-auto"></fa-icon>
                                                        </div>
                                                        <a [routerLink]="">
                                                            <b>Example_WordFile.doc</b>
                                                        </a>
                                                        <div>
                                                            <small class="d-block text-black-50 pb-2">15.2MB</small>
                                                            <small class="opacity-6">
                                                                Modified on: <span class="text-black-50">12.06.2020</span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="card card-box mb-5">
                                                <div class="card-body">
                                                    <div class="card-img-wrapper">
                                                        <div class="card-badges card-badges-top">
                                                            <button class="btn btn-link p-1 shadow-none btn-link-primary" type="button">
                                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                                            </button>
                                                        </div>
                                                        <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                                            <fa-icon [icon]="['far', 'file-archive']" class="display-2 text-danger mx-auto"></fa-icon>
                                                        </div>
                                                        <a [routerLink]="">
                                                            <b>AllPhotos.zip</b>
                                                        </a>
                                                        <div>
                                                            <small class="d-block text-black-50 pb-2">15.2MB</small>
                                                            <small class="opacity-6">
                                                                Modified on: <span class="text-black-50">5 January 2000</span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="card card-box mb-5">
                                                <div class="card-body">
                                                    <div class="card-img-wrapper">
                                                        <div class="card-badges card-badges-top">
                                                            <button class="btn btn-link p-1 shadow-none btn-link-primary" type="button">
                                                                <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                                            </button>
                                                        </div>
                                                        <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                                            <fa-icon [icon]="['far', 'file-archive']" class="display-2 text-danger mx-auto"></fa-icon>
                                                        </div>
                                                        <a [routerLink]="">
                                                            <b>AllPhotos.zip</b>
                                                        </a>
                                                        <div>
                                                            <small class="d-block text-black-50 pb-2">15.2MB</small>
                                                            <small class="opacity-6">
                                                                Modified on: <span class="text-black-50">5 January 2000</span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>

                    </tabset>
                </div>
            </div>

          </div>
      </div>
  </div> -->
  <!--FIN MODAL AJOUT OPPORTUNITE-->
