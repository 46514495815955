import { Injectable } from '@angular/core';

interface MenuItem {
    title: string;
    type: string;
    badge?: {
        class: string;
        text: string;
    };
    link?: string;
    active?: boolean;
    icon?: string;
    submenus?: MenuItem[];
}

@Injectable({
    providedIn: 'root'
})
export class SidebarMenuService {

    menus: MenuItem[] = [
  {
    "title": "Menu de navigation",
    "type": "header"
  },
  {
    "title": "Accueil",
    "type": "simple",
    "icon": "<i class=\"pe-7s-safe\"> </i>",
    "link": "/home"
  },
  {
    "title": "Opportunités",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-wallet\"> </i>",
    "submenus": [
      {
        "title": "Dashboard",
        "type": "simple",
        "link": "/pages/opportunite/dashboard"
      },
      {
        "title": "Liste ",
        "type": "simple",
        "link": "/pages/opportunite/liste"
      }
    ]
  },
  {
    "title": "Etude de prix",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-safe\"> </i>",
    "submenus": [
      {
        "title": "Dashboard",
        "type": "simple",
        "link": "/pages/etude_prix/dashboard"
      },
      {
        "title": "Liste ",
        "type": "simple",
        "link": "/pages/etude_prix/liste"
      }
    ]
  },
  {
    "title": "Projets",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-umbrella\"> </i>",
    "submenus": [
      {
        "title": "Dashboard",
        "type": "simple",
        "link": "/pages/projet/dashboard"
      },
      {
        "title": "Liste ",
        "type": "simple",
        "link": "/pages/projet/liste"
      },
      {
        "title": "Modele ",
        "type": "simple",
        "link": "/pages/projet/modele"
      }
    ]
  },
  {
    "title": "Compte d'exploitation",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-piggy\"> </i>",
    "submenus": [
      {
        "title": "Dashboard",
        "type": "simple",
        "link": "/pages/budget/dashboard"
      },
      {
        "title": "Liste ",
        "type": "simple",
        "link": "/pages/budget/liste"
      }
    ]
  },
  {
    "title": "HSE",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-attention\"> </i>",
    "submenus": [
      {
        "title": "Dashboard",
        "type": "simple",
        "link": "/pages/hse/dashboard"
      },
      {
        "title": "Liste ",
        "type": "simple",
        "link": "/pages/hse/liste"
      }
    ]
  },
  {
    "title": "Ressources",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-box1\"> </i>",
    "submenus": [
      {
        "title": "Dashboard",
        "type": "simple",
        "link": "/pages/ressource/dashboard"
      },
      {
        "title": "Liste",
        "type": "simple",
        "link": "/pages/ressource/liste"
      }
    ]
  },
  {
    "title": "Utilisateurs",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-safe\"> </i>",
    "submenus": [
      {
        "title": "Dashboard",
        "type": "simple",
        "link": "/pages/utilisateur/dashboard"
      },
      {
        "title": "Liste",
        "type": "simple",
        "link": "/pages/utilisateur/liste"
      }
    ]
  },
  {
    "title": "Fournisseurs",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-add-user\"> </i>",
    "submenus": [
      {
        "title": "Dashboard",
        "type": "simple",
        "link": "/pages/fournisseur/dashboard"
      },
      {
        "title": "Liste",
        "type": "simple",
        "link": "/pages/fournisseur/liste"
      }
    ]
  },
  {
    "title": "Trésorerie",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-safe\"> </i>",
    "submenus": [
      {
        "title": "Dashboard",
        "type": "simple",
        "link": "/pages/tresorerie/dashboard"
      },
      {
        "title": "Liste",
        "type": "simple",
        "link": "/pages/tresorerie/liste"
      }
    ]
  },
  {
    "title": "Missions",
    "type": "dropdown",
    "icon": "<i class=\"pe-7s-safe\"> </i>",
    "submenus": [
      {
        "title": "Dashboard",
        "type": "simple",
        "link": "/pages/mission/dashboard"
      },
      {
        "title": "Liste",
        "type": "simple",
        "link": "/pages/mission/liste"
      }
    ]
  },
];

    constructor() { }

    getMenuList() {
        return this.menus;
    }

    getMenuItemByUrl(aMenus: MenuItem[], aUrl: string): MenuItem {
        for (const theMenu of aMenus) {
            if (theMenu.link && theMenu.link === aUrl) {
                return theMenu;
            }

            if (theMenu.submenus && theMenu.submenus.length > 0) {
                const foundItem = this.getMenuItemByUrl(theMenu.submenus, aUrl);
                if (foundItem) {
                    return foundItem;
                }
            }
        }

        return undefined;
    }

    toggleMenuItem(aMenus: MenuItem[], aCurrentMenu: MenuItem): MenuItem[] {
        return aMenus.map((aMenu: MenuItem) => {
            if (aMenu === aCurrentMenu) {
                aMenu.active = !aMenu.active;
            } else {
                aMenu.active = false;
            }

            if (aMenu.submenus && aMenu.submenus.length > 0) {
                aMenu.submenus = this.toggleMenuItem(aMenu.submenus, aCurrentMenu);

                if (aMenu.submenus.find(aChild => aChild.active)) {
                    aMenu.active = true;
                }
            }

            return aMenu;
        });
    }
}
