
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="p-3">
        <div class="border rounded">
            <perfect-scrollbar [autoPropagation]="true" class="scroll-area scroll-area-sm shadow-none py-3" style="height: 30em;">
                <div class="px-3">
                    <div class="form-row">
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="libelle">Libellé: </label>
                                <input type="text" id="libelle" formControlName="libelle" class="form-control" placeholder="Libellé">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="chantier">Chantier: </label>
                                <select id="chantier" formControlName="chantier" class="form-control">
                                    <option value="chantier 1">Chantier 1</option>
                                    <option value="chantier 2">Chantier 2</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="dateDebut">Date début: </label>
                                <div class="input-group input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                    </div>
                                    <input type="text" id="dateDebut" formControlName="dateDebut" class="form-control"
                                    [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                                    placeholder="Date début">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="dateFin">Date fin: </label>
                                <div class="input-group input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                    </div>
                                    <input type="text" id="dateFin" formControlName="dateFin" class="form-control"
                                    [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                                    placeholder="Date fin">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="gravite">Gravité: </label>
                                <select id="gravite" formControlName="gravite" class="form-control">
                                    <option *ngFor="let item of gravite" value="gravite.value">{{gravite.label}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="budget">Montant du budget: </label>
                                <input type="number" min="0" id="budget" formControlName="gravite" class="form-control" placeholder="Montant du budget">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="financeur">Financeur: </label>
                                <select id="financeur" formControlName="financeur" class="form-control">
                                    <option value="client">Le client</option>
                                    <option value="entreprise">L'entreprise</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="document">Document joint:</label>
                                <div class="custom-file">
                                    <input type="file" id="document" formControlName="document" class="custom-file-input" title="Document">
                                    <label class="custom-file-label" for="document">Choisir un document</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="description">Description:</label>
                                <textarea id="description" formControlName="description" rows="7" class="form-control" title="Description" placeholder="Description du document"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
    <hr class="m-0">
    <div class="text-center py-2 rounded-bottom">
        <button class="btn btn-first mr-2" type="button" (click)="form.reset()"><i class="fas fa-broom mr-1"></i> Nettoyer</button>
        <button  class="btn btn-success" type="submit"><i class="fas fa-save mr-2"></i> Enregistrer</button>
    </div>
</form>