import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-architect-dashboard',
  templateUrl: './architect-dashboard.component.html',
  styleUrls: ['./architect-dashboard.component.scss']
})
export class ArchitectDashboardComponent implements OnInit {

  constructor(
    public globals: ThemeOptions
  ) { }

  ngOnInit() {
  }

}
