import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EtudePrixDetailComponent } from './etude-prix-detail/etude-prix-detail.component';
import { EtudePrixListComponent } from './etude-prix-list/etude-prix-list.component';
import { EtudePrixPagesComponent } from './etude-prix-pages.component';


const routes: Routes = [
  {
    path:'',
    component: EtudePrixPagesComponent,
    children: [
      {
        path: "liste",
        component: EtudePrixListComponent
      },
      {
        path: "detail/:id",
        component: EtudePrixDetailComponent
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EtudePrixPagesRoutingModule { }
