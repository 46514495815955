import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proces-verbal-list',
  templateUrl: './proces-verbal-list.component.html',
  styleUrls: ['./proces-verbal-list.component.scss']
})
export class ProcesVerbalListComponent implements OnInit {
  rotate = true;

  // Tout les variables ou tableau ayants pour prefix "default" servent a faire des duplication dans le template.
  defaultPV = [
    { line: 1},
    { line: 2},
    { line: 3}
  ];

  constructor() { }

  ngOnInit() {
  }

}
