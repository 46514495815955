import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VisiteDetailComponent } from './visite-detail/visite-detail.component';
import { VisiteListComponent } from './visite-list/visite-list.component';
import { VisitePagesComponent } from './visite-pages.component';


const routes: Routes = [
  {
    path:'', component: VisitePagesComponent,
    children: [
      {
        path: "liste",
        component: VisiteListComponent
      },
      {
        path: "detail/:id",
        component: VisiteDetailComponent
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VisitePagesRoutingModule { }
