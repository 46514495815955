<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground"
     [ngClass]="{
    'app-page-title--shadow' : globals.pageTitleShadow
    }">
    <div>
        <div class="app-page-title--first">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-wallet display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>Etude de prix </h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">
                    Projet de construction immeuble R+7
                </div>
            </div>
        </div>
    </div>


    <div class="d-flex align-items-center">
        <button  class="btn btn-success" type="button">Terminer</button>
    </div>
</div>

<div class="card card-box mb-4">
    <div class="px-4 py-3 d-block d-md-flex justify-content-between align-items-center text-center text-md-left">
        <div class="d-flex flex-md-row flex-column align-items-center">
            <div class="font-size-lg font-weight-bold">
                Etude de prix
            </div>
            <div class="mx-3 d-none d-md-block">
                <div class="divider-v position-relative"></div>
                <div class="divider-v position-relative"></div>
            </div>
            <div [ngClass]="status1 ? 'is-active' : ''" class="search-wrapper search-wrapper--grow my-3 my-md-0 ml-0 ml-md-4">
              <span class="icon-wrapper text-black">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
              </span>
                <input (blur)="status1 = false; null" (focus)="status1 = true" class="form-control" placeholder="Rechercher" type="search">
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-xl-12">
        <div class="mb-5">
            <tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow" type="tabs" >
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading>
                        <span>Tableau de bord</span>
                    </ng-template>
                    <div class="row">
                        Tableau de bord
                    </div>
                </tab>

                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading>
                        <span>Détail Etude</span>
                    </ng-template>
                    <div class="table-responsive card card-box">
                        <div class="card-header py-3">
                            <div class="card-header--title font-size-lg">
                              Etude de prix réalisée
                            </div>
                            <div class="card-header--actions">
                                <div class="btn-group mx-3" dropdown>
                                    <button class="btn btn-sm btn-primary dropdown-toggle" dropdownToggle type="button">
                                        <span class="btn-wrapper--label pr-2">Options</span>
                                    </button>
                                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-sm p-0" role="menu">
                                        <li role="menuitem"><a class="dropdown-item" [routerLink]="">Exporter en excel</a></li>
                                        <li class="divider dropdown-divider"></li>
                                        <li   role="menuitem"><a class="dropdown-item" [routerLink]="">Charger un modèle</a></li>
                                        <li class="divider dropdown-divider"></li>
                                    </ul>
                                    <button    class="btn btn-success ml-2" tooltip="Planifier les tâches" type="button"><i class="fas fa-plus-circle"></i>Ajouter Travaux</button>
                                </div>
                            </div>
                          </div>
                          <div>
                            <db-angular-tree-grid
                            [data]="dataEtudePrix"
                            [configs]="configs">
                            </db-angular-tree-grid>
                          </div>
                    </div>
                </tab>
            </tabset>
        </div>
    </div>
</div>