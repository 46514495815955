import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bureau-controlle-pages',
  templateUrl: './bureau-controlle-pages.component.html',
  styleUrls: ['./bureau-controlle-pages.component.scss']
})
export class BureauControllePagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
