<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-wallet display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>Tableau de bord opportunité</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">
                    Statistique générales
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Premiere section: Les 8 premier cards -->
<div class="row mb-4">
    <div class="col-lg-6 col-xl-3 mb-2">
        <a [routerLink]="[]" class="card card-box card-box-hover-rise-alt">
            <div class="card-indicator bg-first"></div>
            <div class="card-body px-4 py-3">
                <div class="pb-3 d-flex justify-content-between">
                    <a [routerLink]="">Nombre d'opportunité</a>
                </div>
                <div class="d-flex align-items-center justify-content-start">
                    <div class="badge badge-first px-3">34</div>
                </div>
            </div>
        </a>
    </div>
    <div class="col-lg-6 col-xl-3 mb-2">
        <a [routerLink]="[]" class="card card-box card-box-hover-rise-alt">
            <div class="card-indicator bg-info"></div>
            <div class="card-body px-4 py-3">
                <div class="pb-3 d-flex justify-content-between">
                    <a [routerLink]="">Opportunité(s) en cours(s)</a>
                </div>
                <div class="d-flex align-items-center justify-content-start">
                    <div class="badge badge-info px-3">34</div>
                </div>
            </div>
        </a>
    </div>
    <div class="col-lg-6 col-xl-3 mb-2">
        <a [routerLink]="[]" class="card card-box card-box-hover-rise-alt">
            <div class="card-indicator bg-success"></div>
            <div class="card-body px-4 py-3">
                <div class="pb-3 d-flex justify-content-between">
                    <a [routerLink]="">Opportunité(s) gagnée(s)</a>
                </div>
                <div class="d-flex align-items-center justify-content-start">
                    <div class="badge badge-success px-3">34</div>
                </div>
            </div>
        </a>
    </div>
    <div class="col-lg-6 col-xl-3 mb-2">
        <a [routerLink]="[]" class="card card-box card-box-hover-rise-alt">
            <div class="card-indicator bg-danger"></div>
            <div class="card-body px-4 py-3">
                <div class="pb-3 d-flex justify-content-between">
                    <a [routerLink]="">Opportunité(s) perdue(s)</a>
                </div>
                <div class="d-flex align-items-center justify-content-start">
                    <div class="badge badge-danger px-3">34</div>
                </div>
            </div>
        </a>
    </div>
</div>

<div class="row mb-4">
    <div class="col-xl-3 col-md-6 mb-2">
        <div class="card card-box card-box-border-bottom border-first">
            <div class="card-body">
                <div class="align-box-row">
                    <div class="text-left">
                        <div class="mt-1">
                            <span class="text-black">Montant des opportunités</span>
                        </div>
                        <div class="mt-2 line-height-sm">
                            <b class="font-size-lg text-black pr-1">500 000 000 000</b>
                            <span class="text-black-50">XOF</span>
                        </div>
                    </div>
                    <div class="ml-auto card-hover-indicator">
                        <fa-icon [icon]="['far', 'clock']" class="text-danger"></fa-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-2">
        <div class="card card-box card-box-border-bottom border-info">
            <div class="card-body">
                <div class="align-box-row">
                    <div class="text-left">
                        <div class="mt-1">
                            <span class="text-black">Opportunités en cours</span>
                        </div>
                        <div class="mt-2 line-height-sm">
                            <b class="font-size-lg text-black pr-1">500 000 000 000</b>
                            <span class="text-black-50">XOF</span>
                        </div>
                    </div>
                    <div class="ml-auto card-hover-indicator">
                        <fa-icon [icon]="['far', 'dot-circle']" class="text-warning"></fa-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-2">
        <div class="card card-box card-box-border-bottom border-success">
            <div class="card-body">
                <div class="align-box-row">
                    <div class="text-left">
                        <div class="mt-1">
                            <span class="text-black">Opportunités gagnéés</span>
                        </div>
                        <div class="mt-2 line-height-sm">
                            <b class="font-size-lg text-black pr-1">500 000 000 000</b>
                            <span class="text-black-50">XOF</span>
                        </div>
                    </div>
                    <div class="ml-auto card-hover-indicator">
                        <span className="btn-wrapper--icon" container="body" triggers="mouseenter:mouseleave" placement="top" popover="L'opportunité gagnée est la différence entre les revenus espérés et les opportunités perdus (Revenu espéré - Opportunité perdu).">
                            <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-2">
        <div class="card card-box card-box-border-bottom border-danger">
            <div class="card-body">
                <div class="align-box-row">
                    <div class="text-left">
                        <div class="mt-1">
                            <span class="text-black">Opportunités perdues</span>
                        </div>
                        <div class="mt-2 line-height-sm">
                            <b class="font-size-lg text-black pr-1">500 000 000 000</b>
                            <span class="text-black-50">XOF</span>
                        </div>
                    </div>
                    <div class="ml-auto card-hover-indicator">
                        <fa-icon [icon]="['far', 'times-circle']" class="text-first"></fa-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row mb-4">
    <div class="col-lg-6 col-xl-3 mb-2">
        <a [routerLink]=""
           class="card card-box card-box-hover-rise card-box-hover bg-night-sky text-white align-box-row align-items-start p-3">
            <div>
                <span class="mr-2">Revenu espéré</span> 
                <div class="divider mt-2 mb-3 border-2 w-25 bg-warning rounded border-success"></div>
                <b class="font-size-lg text-white-50 pr-1">500 000 000 000</b>
                <span class="text-white-50 opacity-8">XOF</span>
            </div>
            <div class="ml-auto card-hover-indicator align-self-center">
                <span className="btn-wrapper--icon" container="body" triggers="mouseenter:mouseleave" placement="top" popover="Le revenu espéré est la somme de tout les montant opportunités.">
                    <fa-icon [icon]="['far', 'question-circle']" class="font-size-xl"></fa-icon>
                </span>
            </div>
        </a>
    </div>
    <div class="col-lg-6 col-xl-3 mb-2">
        <a [routerLink]=""
           class="card card-box card-box-hover-rise card-box-hover bg-midnight-bloom text-white align-box-row align-items-start p-3">
           <div>
                <span class="mr-2">Taille moyenne de transaction</span> 
                <div class="divider mt-2 mb-3 border-2 w-25 bg-success rounded border-success"></div>
                <b class="font-size-lg text-white-50 pr-1">500 000 000 000</b>
                <span class="text-white-50 opacity-8">XOF</span>
            </div>
            <div class="ml-auto card-hover-indicator align-self-center">
                <fa-icon [icon]="['fas', 'chevron-right']" class="font-size-xl"></fa-icon>
            </div>
        </a>
    </div>
    <div class="col-lg-6 col-xl-3 mb-2">
        <a [routerLink]=""
           class="card card-box card-box-hover-rise card-box-hover bg-vicious-stance text-white align-box-row align-items-start p-3">
           <div>
                <span class="mr-2">Tâche en retard</span> 
                <div class="divider mt-2 mb-3 border-2 w-25 bg-danger rounded border-danger"></div>
                <b class="font-size-lg text-white-50 pr-1">50</b>
            </div>
            <div class="ml-auto card-hover-indicator align-self-center">
                <fa-icon [icon]="['far', 'calendar-alt']" class="font-size-xl"></fa-icon>
            </div>
        </a>
    </div>
    <div class="col-lg-6 col-xl-3 mb-2">
        <a [routerLink]=""
           class="card card-box card-box-hover-rise card-box-hover bg-premium-dark text-white align-box-row align-items-start p-3">
           <div>
                <span class="mr-2">Opportunité hors délais</span> 
                <div class="divider mt-2 mb-3 border-2 w-25 bg-first rounded border-first"></div>
                <b class="font-size-lg text-white-50 pr-1">12</b>
            </div>
            <div class="ml-auto card-hover-indicator align-self-center">
                <fa-icon [icon]="['far', 'clock']" class="font-size-xl"></fa-icon>
            </div>
        </a>
    </div>
</div>
<!-- Fin premiere section -->

<!-- Deuxieme section: Les graphe d'opportunite et de montant opportunite -->
<div class="row mb-4">
    <div class="col-xl-6 mb-2">
        <div class="card card-box">
            <div class="card-header">
                <div class="card-header--title">
                    <h4 class="font-size-lg mb-0 py-2 font-weight-bold">Nombre d'opportunité <br><small>Vue semestrielle</small></h4>
                </div>
                <div class="card-header--actions">
                    <div class="btn-group btn-group-sm" dropdown>
                        <button class="btn btn-sm dropdown-toggle no-caret btn-neutral-dark" dropdownToggle type="button">
                            Action
                            <fa-icon [icon]="['fas', 'chevron-down']" class="opacity-8 font-size-xs ml-1"></fa-icon>
                        </button>
                        <div *dropdownMenu class="dropdown-menu dropdown-menu-xl dropdown-menu-right">
                            <ul class="nav nav-neutral-dark nav-pills flex-column px-3">
                                <li class="nav-item nav-item--header px-0">
                                    <span class="text-capitalize text-black font-size-md font-weight-bold">Action menu</span>
                                </li>
                                <li class="dropdown-divider"></li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><span>Vue trimestrielle</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><span>Vue semestrielle</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><span>Vue Annuelle</span></a>
                                </li>
                                <li class="nav-item pt-4 nav-item--header">
                                    <span class="text-black font-weight-bold">Options</span>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link">
                                        <div class="nav-link-icon"><fa-icon [icon]="['fas', 'download']"></fa-icon></div>
                                        <span>Télécharger</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link">
                                        <div class="nav-link-icon"><fa-icon [icon]="['far', 'file-excel']"></fa-icon></div>
                                        <span>Export CSV</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <apx-chart
                    [chart]="{type: 'bar', height: '310', sparkline: {enabled: false}, toolbar: {show: false}}"
                    [colors]="['#4191ff', '#1bc943', '#f83245']"
                    [dataLabels]="{enabled: false}"
                    [fill]="{opacity: 1}"
                    [labels]="['Mois 1', 'Mois 2', 'Mois 3', 'Mois 4', 'Mois 5', 'Mois 6']"
                    [legend]="{ show: false }"
                    [series]="[
                        {
                            name: 'Ouverte',
                            data: [2.3, 3.1, 4.0, 3.8, 5.1, 3.6]
                        },
                        {
                            name: 'Gagnée',
                            data: [3.2, 1.3, 3.0, 1.8, 3.5, 6.0]
                        },
                        {
                            name: 'Perdue',
                            data: [2.1, 2.1, 3.0, 2.8, 4.0, 3.8]
                        }
                    ]"
                    [stroke]="{show: true, width: 2, colors: ['transparent']}"
                ></apx-chart>
            </div>
        </div>
    </div>
    <div class="col-xl-6 mb-2">
        <div class="card card-box">
            <div class="card-header">
                <div class="card-header--title">
                    <h4 class="font-size-lg mb-0 py-2 font-weight-bold">Montant des opportunités <br><small>Vue semestrielle</small></h4>
                </div>
                <div class="card-header--actions">
                    <div class="btn-group btn-group-sm" dropdown>
                        <button class="btn btn-sm dropdown-toggle no-caret btn-neutral-first" dropdownToggle type="button">
                            Action
                            <fa-icon [icon]="['fas', 'chevron-down']" class="opacity-8 font-size-xs ml-1"></fa-icon>
                        </button>
                        <div *dropdownMenu class="dropdown-menu dropdown-menu-xl dropdown-menu-right">
                            <ul class="nav nav-neutral-dark nav-pills flex-column px-3">
                                <li class="nav-item nav-item--header px-0">
                                    <span class="text-capitalize text-black font-size-md font-weight-bold">Action menu</span>
                                </li>
                                <li class="dropdown-divider"></li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><span>Vue trimestrielle</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><span>Vue semestrielle</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><span>Vue Annuelle</span></a>
                                </li>
                                <li class="nav-item pt-4 nav-item--header">
                                    <span class="text-black font-weight-bold">Options</span>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link">
                                        <div class="nav-link-icon"><fa-icon [icon]="['fas', 'download']"></fa-icon></div>
                                        <span>Télécharger</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link">
                                        <div class="nav-link-icon"><fa-icon [icon]="['far', 'file-excel']"></fa-icon></div>
                                        <span>Export CSV</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body pb-1 font-weight-bold">
                <apx-chart
                    [chart]="{type: 'area', height: '320', sparkline: {enabled: false}, toolbar: {show: false}}"
                    [colors]="['#007acc', '#1bc943', '#f83245']"
                    [dataLabels]="{enabled: false}"
                    [fill]="{opacity: 0.85, colors: ['#007acc', '#1bc943', '#f83245']}"
                    [grid]="{strokeDashArray: '5', borderColor: 'rgba(125, 138, 156, 0.3)'}"
                    [labels]="['Mois 1', 'Mois 2', 'Mois 3', 'Mois 4', 'Mois 5', 'Mois 6']"
                    [legend]="{ show: false }"
                    [series]="[
                        {
                            name: 'Ouverte',
                            data: [2.3, 3.1, 4.0, 3.8, 5.1, 3.6]
                        },
                        {
                            name: 'Gagnée',
                            data: [3.3, 3.0, 1.1, 4.8, 5.1, 2.6]
                        },
                        {
                            name: 'Perdue',
                            data: [2.1, 2.1, 3.0, 2.8, 4.0, 3.8]
                        }
                    ]"
                    [stroke]="{show: true, width: 2, colors: ['transparent']}"
                ></apx-chart>
            </div>
        </div>
    </div>
</div>
<!-- Fin deuxieme section -->

<!-- Quatrieme section: Les graphe de ration gain/perte et de moyenne -->
<div class="row mb-4">
    <div class="col-xl-6 mb-2">
        <div class="card card-box">
            <div class="card-header">
                <div class="card-header--title">
                    <h4 class="font-size-lg mb-0 py-2 font-weight-bold">Ratio Gain/perte </h4>
                </div>
            </div>
            <div class="card-body">
                <div class="chart">
                    <canvas baseChart
                        [data]="polarAreaChartData"
                        [labels]="polarAreaChartLabels"
                        [legend]="polarAreaLegend"
                        [colors]="polarAreaColors"
                        [chartType]="polarAreaChartType">
                    </canvas>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-6 mb-2">
        <div class="card card-box">
            <div class="card-header">
                <div class="card-header--title">
                    <h4 class="font-size-lg mb-0 py-2 font-weight-bold">Ratio moyenne </h4>
                </div>
            </div>
            <div class="card-body">
                <div class="chart">
                    <canvas baseChart
                        [data]="pieChartData"
                        [labels]="pieChartLabels"
                        [chartType]="pieChartType"
                        [options]="pieChartOptions"
                        [plugins]="pieChartPlugins"
                        [colors]="pieChartColors"
                        [legend]="pieChartLegend">
                    </canvas>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin quatrieme section -->

<!-- Cinquieme section -->
<div class="card card-box mb-4">
    <div class="card-header pr-2">
        <div class="card-header--title font-weight-bold">Les 10 opportunités récemment crées</div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-hover mb-0 table-borderless">
                <thead>
                    <tr>
                        <th>Nom projet</th>
                        <th>Bailleur</th>
                        <th>Montant</th>
                        <th>Autorité contractante</th>
                        <th>Responsable</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Projet 1</td>
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="avatar-icon-wrapper mr-2">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar4.jpg"/></div>
                                </div>
                                <div>
                                    <a [routerLink]="" class="font-weight-bold text-black" title="...">Kouassi ghislain</a>
                                    <span class="text-black-50 d-block">Chef de projet</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="badge badge-neutral-dark px-4">500 000 000</div><span class="text-dark-50 ml-2">XOF</span>
                        </td>
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="avatar-icon-wrapper mr-2">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar4.jpg"/></div>
                                </div>
                                <div>
                                    <a [routerLink]="" class="font-weight-bold text-black" title="...">Kouassi ghislain</a>
                                    <span class="text-black-50 d-block">Chef de projet</span>
                                </div>
                            </div>
                        </td>
                        <td>Kouassi</td>
                        <td class="text-center">
                            <div class="btn-group btn-group-sm" dropdown placement="bottom right">
                                <button class="btn btn-sm px-2 py-0 dropdown-toggle no-caret btn-neutral-first" dropdownToggle type="button">
                                    <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
                                </button>
                                <div *dropdownMenu class="dropdown-menu dropdown-menu-xl p-0 dropdown-menu-right">
                                    <ul class="nav nav-neutral-first nav-pills flex-column pt-1 pb-2">
                                        <li class="nav-item">
                                            <a [routerLink]="" class="nav-link mx-3 mt-2"><span><i class="ion-md-eye"></i> Détails</span></a>
                                        </li>
                                        <li class="dropdown-divider"></li>
                                        <li class="nav-item">
                                            <a [routerLink]="" class="nav-link mx-3"><span><i class="ion-md-print"></i> Imprimer</span></a>
                                        </li>
                                        <li class="dropdown-divider"></li>
                                        <li class="nav-item">
                                            <a [routerLink]="" class="nav-link text-danger mx-3">
                                                <div class="nav-link-icon"><fa-icon [icon]="['fas', 'times']"></fa-icon></div>
                                                <span>Supprimer</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th colspan="2">TOTAL: </th>
                        <td colspan="4">500 000 000 000 XOF</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>
<!-- Fin cinquieme section -->

<!-- Sixieme section -->
<div class="row"> 
    <div class="col-xl-4">
        <div class="card card-box mb-5 bg-secondary">
            <div class="card-header-alt d-flex justify-content-between p-4">
                <div>
                    <h6 class="font-weight-bold mb-1 text-black">Les 10 derniers projets modifiés</h6>
                </div>
            </div>
            <div class="px-3 pb-3">
                <perfect-scrollbar [autoPropagation]="true" class="scroll-area rounded bg-white shadow-overflow">
                    <div class="p-3">
                        <div class="d-flex justify-content-between">
                            <div>
                                <div class="font-weight-bold">
                                    <a [routerLink]="" class="text-black"> PJ2009-0005</a>
                                    <span class="text-muted ml-5">Par Kouassi ghislain</span>
                                </div>
                                <small class="d-flex pt-2 align-items-center">
                                    <a class="pr-3" [routerLink]="">Projet de construction R+3 </a>
                                    <span class="badge badge-success"> 22-05-2021 à 18h02</span>
                                </small>
                            </div>
                        </div>
                        <div class="divider my-3"></div>
                        <div class="d-flex justify-content-between">
                            <div>
                                <div class="font-weight-bold">
                                    <a [routerLink]="" class="text-black"> PJ2009-0005</a>
                                    <span class="text-muted ml-5">Par Kouassi ghislain</span>
                                </div>
                                <small class="d-flex pt-2 align-items-center">
                                    <a class="pr-3" [routerLink]="">Projet de construction R+3 </a>
                                    <span class="badge badge-success"> 22-05-2021 à 18h02</span>
                                </small>
                            </div>
                        </div>
                        <div class="divider my-3"></div>
                        <div class="d-flex justify-content-between">
                            <div>
                                <div class="font-weight-bold">
                                    <a [routerLink]="" class="text-black"> PJ2009-0005</a>
                                    <span class="text-muted ml-5">Par Kouassi ghislain</span>
                                </div>
                                <small class="d-flex pt-2 align-items-center">
                                    <a class="pr-3" [routerLink]="">Projet de construction R+3 </a>
                                    <span class="badge badge-success"> 22-05-2021 à 18h02</span>
                                </small>
                            </div>
                        </div>
                        <div class="divider my-3"></div>
                        <div class="d-flex justify-content-between">
                            <div>
                                <div class="font-weight-bold">
                                    <a [routerLink]="" class="text-black"> PJ2009-0005</a>
                                    <span class="text-muted ml-5">Par Kouassi ghislain</span>
                                </div>
                                <small class="d-flex pt-2 align-items-center">
                                    <a class="pr-3" [routerLink]="">Projet de construction R+3 </a>
                                    <span class="badge badge-success"> 22-05-2021 à 18h02</span>
                                </small>
                            </div>
                        </div>
                        <div class="divider my-3"></div>
                        <div class="d-flex justify-content-between">
                            <div>
                                <div class="font-weight-bold">
                                    <a [routerLink]="" class="text-black"> PJ2009-0005</a>
                                    <span class="text-muted ml-5">Par Kouassi ghislain</span>
                                </div>
                                <small class="d-flex pt-2 align-items-center">
                                    <a class="pr-3" [routerLink]="">Projet de construction R+3 </a>
                                    <span class="badge badge-success"> 22-05-2021 à 18h02</span>
                                </small>
                            </div>
                        </div>
                        <div class="divider my-3"></div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
    
    <div class="col-xl-3">
        <div class="card card-box mb-5 bg-secondary">
            <div class="card-header-alt d-flex justify-content-between p-4">
                <div>
                    <h6 class="font-weight-bold mb-1 text-black">Les 10 derniers projets ouverts par un tiers</h6>
                </div>
            </div>
            <div class="px-3 pb-3">
                <perfect-scrollbar [autoPropagation]="true" class="scroll-area rounded bg-white shadow-overflow">
                    <div class="p-3">
                        <div class="d-flex justify-content-between">
                            <div>
                                <div class="font-weight-bold">
                                    <a [routerLink]="" class="text-black">
                                        Romeo kouassi
                                    </a>
                                </div>
                                <small class="d-flex pt-2 align-items-center">
                                    <div class="avatar-icon-wrapper avatar-icon-xs mr-2">
                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/>
                                        </div>
                                    </div>
                                    <a class="pr-3" [routerLink]="">Nombre de projets </a>
                                    <span class="badge badge-warning"> 10</span>
                                </small>
                            </div>
                        </div>
                        <div class="divider my-3"></div>
                        <div class="d-flex justify-content-between">
                            <div>
                                <div class="font-weight-bold">
                                    <a [routerLink]="" class="text-black">
                                        Romeo kouassi
                                    </a>
                                </div>
                                <small class="d-flex pt-2 align-items-center">
                                    <div class="avatar-icon-wrapper avatar-icon-xs mr-2">
                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/>
                                        </div>
                                    </div>
                                    <a class="pr-3" [routerLink]="">Nombre de projets </a>
                                    <span class="badge badge-warning"> 10</span>
                                </small>
                            </div>
                        </div>
                        <div class="divider my-3"></div>
                        <div class="d-flex justify-content-between">
                            <div>
                                <div class="font-weight-bold">
                                    <a [routerLink]="" class="text-black">
                                        Romeo kouassi
                                    </a>
                                </div>
                                <small class="d-flex pt-2 align-items-center">
                                    <div class="avatar-icon-wrapper avatar-icon-xs mr-2">
                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/>
                                        </div>
                                    </div>
                                    <a class="pr-3" [routerLink]="">Nombre de projets </a>
                                    <span class="badge badge-warning"> 10</span>
                                </small>
                            </div>
                        </div>
                        <div class="divider my-3"></div>
                        <div class="d-flex justify-content-between">
                            <div>
                                <div class="font-weight-bold">
                                    <a [routerLink]="" class="text-black">
                                        Romeo kouassi
                                    </a>
                                </div>
                                <small class="d-flex pt-2 align-items-center">
                                    <div class="avatar-icon-wrapper avatar-icon-xs mr-2">
                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/>
                                        </div>
                                    </div>
                                    <a class="pr-3" [routerLink]="">Nombre de projets </a>
                                    <span class="badge badge-warning"> 10</span>
                                </small>
                            </div>
                        </div>
                        <div class="divider my-3"></div>
                        <div class="d-flex justify-content-between">
                            <div>
                                <div class="font-weight-bold">
                                    <a [routerLink]="" class="text-black">
                                        Romeo kouassi
                                    </a>
                                </div>
                                <small class="d-flex pt-2 align-items-center">
                                    <div class="avatar-icon-wrapper avatar-icon-xs mr-2">
                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/>
                                        </div>
                                    </div>
                                    <a class="pr-3" [routerLink]="">Nombre de projets </a>
                                    <span class="badge badge-warning"> 10</span>
                                </small>
                            </div>
                        </div>
                        <div class="divider my-3"></div>
                        
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>

    <div class="col-xl-5">
        <div class="card card-box mb-5 bg-secondary">
            <div class="card-header-alt d-flex justify-content-between p-4">
                <div>
                    <h6 class="font-weight-bold mb-1 text-black">Projets Brouillon  
                        <span class="badge badge-success"> 10 </span> 
                    </h6></div>
                <div class="d-flex align-items-center justify-content-start">
                    <div class="badge badge-info px-3">Total: 500 000 000 000</div>
                    <div class="font-size-sm text-dark px-2">XOF</div>
                </div>
            </div>
            <div class="px-3 pb-3">
                <perfect-scrollbar [autoPropagation]="true" class="scroll-area rounded bg-white shadow-overflow">
                    <div class="card card-box">
                        <div class="table-responsive">
                            <table class="table table-hover text-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th class="bg-white text-left">Projets Brouillon </th>
                                        <th class="bg-white">Tiers</th>
                                        <th class="bg-white text-left">Montant opportunité</th>
                                        <th class="bg-white text-left">Tâches</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            PJ2011-0008
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-dark">Gerome cesar</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-danger text-danger">100.000</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-dark">5</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            PJ2011-0008
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-dark">Gerome cesar</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-danger text-danger">100.000</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-dark">5</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            PJ2011-0008
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-dark">Gerome cesar</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-danger text-danger">100.000</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-dark">5</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            PJ2011-0008
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-primary">Gerome cesar</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-danger text-danger">100.000</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-dark">5</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            PJ2011-0008
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-primary">Gerome cesar</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-danger text-danger">100.000</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-dark">5</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            PJ2011-0008
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-primary">Gerome cesar</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-danger text-danger">100.000</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-dark">5</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            PJ2011-0008
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-primary">Gerome cesar</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-danger text-danger">100.000</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-dark">5</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            PJ2011-0008
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-primary">Gerome cesar</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-danger text-danger">100.000</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-dark">5</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            PJ2011-0008
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-primary">Gerome cesar</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-danger text-danger">100.000</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge bg-neutral-dark text-dark">5</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</div>
<!-- Fin sixieme section -->

<!-- Septieme section: Le calendrier de cloture -->
<div class="card card-box">
    <div class="card-header"></div>
    <div class="card-body p-3">
        <div class="row">
            <div class="col-md-4">
                <perfect-scrollbar [autoPropagation]="false" class="scroll-area-xl">
                    <div class="bg-white d-xl-block d-none pb-3">
                        <bs-datepicker-inline [bsConfig]="{ containerClass: 'theme-primary' }" [bsValue]="bsInlineValue" class="datepicker-wrapper-flush"></bs-datepicker-inline>
                    </div>
                    <div class="divider mt-2"></div>
                    <div class="p-3">
                        <ul class="nav nav-neutral-primary nav-pills flex-column">
                            <li class="nav-header d-flex pb-3 align-items-center">
                                <div class="text-primary font-weight-bold">Menu</div>
                            </li>
                            <li class="nav-item mb-2 d-flex justify-content-between">
                                <span class="font-weight-bold">Nombre d'opportunités crée:</span>
                                <span class="badge badge-dark">4</span>
                            </li>
                            <li class="nav-item mb-2 d-flex justify-content-between">
                                <span class="font-weight-bold">Nombre d'opportunités espirée:</span>
                                <span class="badge badge-dark">43</span>
                            </li>
                            <li class="nav-item mb-2 d-flex justify-content-between">
                                <span class="font-weight-bold">Nombre d'opportunités en cours:</span>
                                <span class="badge badge-dark">14</span>
                            </li>
                            <li class="nav-item mb-2 d-flex justify-content-between">
                                <span class="font-weight-bold">Date limite de dossier:</span>
                                <span class="badge badge-dark">14</span>
                            </li>
                        </ul>
                    </div>
                    <div class="divider mt-2"></div>
                    <div class="p-3 bg-white">
                        <ul class="nav nav-neutral-primary nav-pills flex-column">
                            <li class="nav-header d-flex pb-3 align-items-center">
                                <div class="text-primary font-weight-bold">Statistiques</div>
                                <div class="ml-auto font-size-xs">
                                    <a [routerLink]="" class="text-success" placement="left" tooltip="Rafraichir">
                                        <fa-icon [icon]="['fas', 'cog']"></fa-icon>
                                    </a>
                                </div>
                            </li>
                        </ul>
                        <div class="row font-size-xs">
                            <div class="col-lg-6">
                                <div class="card border-1 shadow-none bg-light text-center my-2 p-3">
                                    <div>
                                        <i-feather class="font-size-xxl mb-2 text-success" name="layers"></i-feather>
                                    </div>
                                    <div class="mt-2 line-height-sm">
                                        <b class="font-size-lg">123</b>
                                        <span class="text-black-50 d-block">Tâches en cours</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="card border-1 shadow-none bg-light text-center my-2 p-3">
                                    <div>
                                        <i-feather class="font-size-xxl mb-2 text-danger" name="layers"></i-feather>
                                    </div>
                                    <div class="mt-2 line-height-sm">
                                        <b class="font-size-lg">123</b>
                                        <span class="text-black-50 d-block">Tâches espirées</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="col-md-8">
                <perfect-scrollbar [autoPropagation]="false" class="scroll-area-xl">
                    <div class="app-inner-content-layout--main bg-white">
                        <div class="app-inner-content-layout--main__header rounded-0">
                            <div class="p-0">
                                <div class="rounded-sm bg-secondary mb-3">
                                    <div class="row">
                                        <div class="col-md-4 text-center text-lg-left">
                                            <div class="btn-group btn-group-sm">
                                                <div [(viewDate)]="viewDate"
                                                    [view]="view"
                                                    class="btn btn-outline-primary"
                                                    mwlCalendarPreviousView
                                                >
                                                    <fa-icon [icon]="['fas', 'angle-left']"></fa-icon>
                                                </div>
                                                <div [(viewDate)]="viewDate"
                                                    class="btn d-flex align-items-center btn-primary"
                                                    mwlCalendarToday
                                                >
                                                    <span class="btn-wrapper--label">Aujoud'hui</span>
                                                </div>
                                                <div [(viewDate)]="viewDate"
                                                    [view]="view"
                                                    class="btn btn-outline-primary"
                                                    mwlCalendarNextView
                                                >
                                                    <fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 d-flex text-center justify-content-center align-items-center py-3 py-lg-0">
                                            <h3 class="font-weight-bold font-size-lg mb-0">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                                        </div>
                                        <div class="col-md-4 text-center text-lg-right">
                                            <div class="btn-group btn-group-sm">
                                                <div (click)="setView(CalendarView.Month)"
                                                    [class.active]="view === CalendarView.Month"
                                                    class="btn btn-primary"
                                                >Mois
                                                </div>
                                                <div (click)="setView(CalendarView.Week)"
                                                    [class.active]="view === CalendarView.Week"
                                                    class="btn btn-primary"
                                                >Semaine
                                                </div>
                                                <div (click)="setView(CalendarView.Day)"
                                                    [class.active]="view === CalendarView.Day"
                                                    class="btn btn-primary"
                                                >Jour
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div [ngSwitch]="view">
                                    <mwl-calendar-month-view
                                        *ngSwitchCase="'month'"
                                        [events]="events"
                                        [viewDate]="viewDate"
                                    >
                                    </mwl-calendar-month-view>
                                    <mwl-calendar-week-view
                                        *ngSwitchCase="'week'"
                                        [events]="events"
                                        [viewDate]="viewDate"
                                    >
                                    </mwl-calendar-week-view>
                                    <mwl-calendar-day-view
                                        *ngSwitchCase="'day'"
                                        [events]="events"
                                        [viewDate]="viewDate"
                                    >
                                    </mwl-calendar-day-view>
                                </div>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</div>
<!-- Fin septieme section -->