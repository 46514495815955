<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="p-4">
        <div class="d-flex justify-content-between mb-3">
            <div><h4 class="font-weight-bold m-0">Informations générales</h4></div>
        </div><hr>
        <div class="form-row">
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="numero">Numéro d'ordre: <span class="text-danger mr-2">*</span></label>
                    <input type="number" class="form-control" id="numero" formControlName="numero" placeholder="Numéro d'ordre" title="Numéro d'ordre"
                    [ngClass]="{ 'is-invalid': submit && f.numero.errors, 'is-valid': submit && f.numero.valid }">
                    <div class="invalid-feedback" *ngIf="submit && f.numero.errors">
                        <div *ngIf="f.numero.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="libelle">Libellé: <span class="text-danger mr-2">*</span></label>
                    <input type="text" class="form-control" id="libelle" formControlName="libelle" placeholder="Libellé" title="Libellé"
                    [ngClass]="{ 'is-invalid': submit && f.libelle.errors, 'is-valid': submit && f.libelle.valid }">
                    <div class="invalid-feedback" *ngIf="submit && f.libelle.errors">
                        <div *ngIf="f.libelle.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="categorie">Catégorie: <span class="text-danger mr-2">*</span></label>
                    <select class="form-control" id="categorie" formControlName="categorie" title="Catégorie"
                    [ngClass]="{ 'is-invalid': submit && f.categorie.errors, 'is-valid': submit && f.categorie.valid }">
                        <option value="autorisation">Autorisation</option>
                        <option value="environnement social">Environnement social</option>
                        <option value="etude">Etude</option>
                    </select>
                    <div class="invalid-feedback" *ngIf="submit && f.categorie.errors">
                        <div *ngIf="f.categorie.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="numDoc">Numéro du document: <span class="text-danger mr-2">*</span></label>
                    <input type="text" class="form-control" id="numDoc" formControlName="numDoc" placeholder="Numéro du document" title="Numéro du document"
                    [ngClass]="{ 'is-invalid': submit && f.numDoc.errors, 'is-valid': submit && f.numDoc.valid }">
                    <div class="invalid-feedback" *ngIf="submit && f.numDoc.errors">
                        <div *ngIf="f.numDoc.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="version">Version: <span class="text-danger mr-2">*</span></label>
                    <input type="number" class="form-control" id="version" formControlName="version" placeholder="Version" title="Version"
                    [ngClass]="{ 'is-invalid': submit && f.version.errors, 'is-valid': submit && f.version.valid }">
                    <div class="invalid-feedback" *ngIf="submit && f.version.errors">
                        <div *ngIf="f.version.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="mode">Mode de transmission: <span class="text-danger mr-2">*</span></label>
                    <select class="form-control" id="mode" formControlName="mode" title="Mode de transmission"
                    [ngClass]="{ 'is-invalid': submit && f.mode.errors, 'is-valid': submit && f.mode.valid }">
                        <option value="courier">Courier</option>
                        <option value="mail">Mail</option>
                    </select>
                    <div class="invalid-feedback" *ngIf="submit && f.mode.errors">
                        <div *ngIf="f.mode.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="dateTransmission">Date de transmission: <span class="text-danger mr-2">*</span></label>
                    <div class="input-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                        </div>
                        <input type="text" id="dateTransmission" formControlName="dateTransmission" class="form-control"
                        [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                        placeholder="Date de transmission" title="Date de transmission"
                        [ngClass]="{ 'is-invalid': submit && f.dateTransmission.errors, 'is-valid': submit && f.dateTransmission.valid }">
                        <div class="invalid-feedback" *ngIf="submit && f.dateTransmission.errors">
                            <div *ngIf="f.dateTransmission.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="chantier">Chantier: </label>
                    <select class="form-control" id="chantier" formControlName="chantier" title="Chantier">
                        <option value="chantier 1">Chantier 1</option>
                        <option value="chantier 2">Chantier 2</option>
                        <option value="chantier 3">Chantier 3</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="document">Document:</label>
                    <div class="custom-file">
                        <input type="file" id="document" formControlName="document" class="custom-file-input" title="Document">
                        <label class="custom-file-label" for="document">Choisir un document</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-md-12">
                <div class="position-relative form-group">
                    <label for="description">Description:</label>
                    <textarea formControlName="description" id="description" cols="30" rows="4" class="form-control" title="Description" placeholder="Description du projet"></textarea>
                </div>
            </div>
        </div>
    </div>
    <hr class="m-0">
    <div class=" px-4 py-2">
        <div class="row">
            <div class="col-md-6">
                <p class="text-danger mt-2">L'étoile (*) signifie que cet champ est obligatoire.</p>
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-first mr-2" type="button" (click)="form.reset()"><i class="fas fa-broom  mr-2"></i> Nettoyer</button>
                <button  class="btn btn-success" type="submit"><i class="fas fa-save mr-2"></i> Enregistrer</button>
            </div>
        </div>
    </div>
</form>