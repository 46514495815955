import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FichierPagesRoutingModule } from './fichier-pages-routing.module';
import { FichierListComponent } from './fichier-list/fichier-list.component';
import { FichierDetailComponent } from './fichier-detail/fichier-detail.component';
import { RouterModule } from '@angular/router';
import { FichierPagesComponent } from './fichier-pages.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BsDatepickerModule, BsDropdownModule, ModalModule, PaginationModule, ProgressbarModule, TooltipModule } from 'ngx-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FichierEditComponent } from './fichier-edit/fichier-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    FichierPagesComponent,
    FichierListComponent,
    FichierDetailComponent,
    FichierEditComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    ProgressbarModule,
    PerfectScrollbarModule,
    PaginationModule,
    BsDropdownModule,
    TooltipModule,
    ModalModule,
    FichierPagesRoutingModule
  ],
  exports: [
    FichierListComponent,
    FichierDetailComponent
  ],
})
export class FichierPagesModule { }
