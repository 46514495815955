import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FournisseurDashboardComponent } from './fournisseur-dashboard/fournisseur-dashboard.component';
import { FournisseurDetailComponent } from './fournisseur-detail/fournisseur-detail.component';
import { FournisseurListComponent } from './fournisseur-list/fournisseur-list.component';
import { FournisseurPagesComponent } from './fournisseur-pages.component';


const routes: Routes = [
  {
    path:'',
    component: FournisseurPagesComponent,
    children: [
      {
        path: "dashboard",
        component: FournisseurDashboardComponent
      },
      {
        path: "liste",
        component: FournisseurListComponent
      },
      {
        path: "detail/:id",
        component: FournisseurDetailComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FournisseurPagesRoutingModule { }
