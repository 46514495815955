import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject,throwError } from 'rxjs';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Maitre } from './maitre.model';
import { Utilisateur } from '../utilisateur-pages/utilisateur.model';

interface MaitreData {
  id?: string,
  type?: string,
  nom?: string,
  fonction?: string,
  pays?: string,
  contact?: string,
  email?: string,
  utilisateur?: Array<Utilisateur>,
}

@Injectable({
  providedIn: 'root'
})
export class MaitreService {
  private _maitre = new BehaviorSubject<Maitre[]>([]);
  apiUrl = environment.apiUrl;
  prefix = environment.prefix;

  constructor(private http:HttpClient) { }

  get tiers(){
    return this._maitre.asObservable();
  }

  getList(){
    return this.http.get<MaitreData[]>(`${this.apiUrl}/maitre_ouvrages`,{responseType: 'json'}).pipe(
      map((response: any) => response['hydra:member']),
      catchError(this.handleError)
    )
  }
  create(data:MaitreData){
    return this.http.post<MaitreData>(`${this.apiUrl}/maitre_ouvrages`,JSON.stringify(data)).pipe(
      take(1),
      tap( resData => {return resData}),
      catchError(this.handleError)
    );//pipe   
  }

  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "Une erreur est survenue";
    return throwError(messageError);
  }
}
