import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'querystring';
import { BehaviorSubject,throwError } from 'rxjs';

import { environment } from './../../../environments/environment';
import { AuthPagesService } from '../auth-pages/auth-pages.service';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { Fournisseur } from './fournisseur.model';

interface UtilisateurData {
        id?: string,
        nom: string,
        prenom?: string,
        email: string,
        contact1: string,
        contact2?: string,
        genre?: boolean,
        pays?: string,
        ville?: string,
        username: string,
        type?: number,
        service?: string,
        tiers?: object | string,
        bureauEtude?: object | string,
        superieurHierarchique?: string,
        dateNaissance?: Date,
        fonction?: string,
        adresse?: string,
        enabled?: true,
        avatar?: string,
        published?: string,
        modified?: string,
        roles?: [],
}

@Injectable({
  providedIn: 'root'
})
export class FournisseurService {

  private _fournisseurs = new BehaviorSubject<Fournisseur[]>([]);
  apiUrl = environment.apiUrl;
  prefix = environment.prefix;

  private assignData;
  constructor(private authService:AuthPagesService , private http:HttpClient) { }

  get utilisateurs(){
    return this._fournisseurs.asObservable();
  }


  
  addFournisseur(userData:UtilisateurData){
        return this.http.post<UtilisateurData>(`${this.apiUrl}/fournisseurs`,JSON.stringify(userData)).pipe(
        take(1),
        tap( resData => {
          return resData
        }),
        catchError(this.handleError)
      );//pipe   
  }
  updateFournisseur(id: number, userData:UtilisateurData){
    return this.http.put<UtilisateurData>(`${this.apiUrl}/fournisseurs/` + id ,JSON.stringify(userData)).pipe(
    take(1),
    tap( resData => {
      return resData
    }),
    catchError(this.handleError)
  );//pipe   
}

deleteFournisseur(id: number, userData?:UtilisateurData){
  return this.http.delete<UtilisateurData>(`${this.apiUrl}/fournisseurs/` + id).pipe(
  take(1),
  tap( resData => {
    return resData
  }),
  catchError(this.handleError)
);//pipe   
}

  listerFournisseur(){
    return this.http.get<UtilisateurData[]>(
      `${this.apiUrl}/utilisateurs`,{
        responseType: 'json'
      }
    ).pipe(
      map((response: any) => response['hydra:member']),
      catchError(this.handleError)
    )
  }

  getDataForFournisseur() {
    return this.assignData;
  }
  setDataForFournisseur(data) {
    this.assignData = data;
  }


  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "Une erreur est survenue";
    return throwError(messageError);
  }
}
