import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-etude-prix-edit',
  templateUrl: './etude-prix-edit.component.html',
  styleUrls: ['./etude-prix-edit.component.scss']
})
export class EtudePrixEditComponent implements OnInit {

  constructor(public globals: ThemeOptions) { }

  ngOnInit() {
  }

  settingsLotTrx = {
    columns: {
        designation: {
            title: 'Désignation'
        }
    }
  };

dataLotTrx = [
    {
        designation: "Travaux de Genie-Civil",
    },
    // ... other rows here
    {
      designation: "Equipement HTA du Poste Extérieur",
    },
    {
      designation: "Equipement HTA du Poste Intérieur",
    },
];


settingsData1 = {
  columns: {
      designation: {
          title: 'Désignation'
      },
      quantite: {
        title: "Quantité"
      },
      prix: {
        title: 'Prix U'
      },
      unite: {
        title: 'Unité'
      },
      total: {
        title: 'Total'
      },

  }
};

data1 = [
  {
      designation: "Réamenagement salle HTA1",
      quantite: 4,
      prix: 1300,
      unite: "m",
      total: 2200,
     
  },
  {
    designation: "Réamenagement salle HTA1",
    quantite: 4,
    prix: 1300,
    unite: "m",
    total: 2200,
   
},
{
  designation: "Réamenagement salle HTA1",
  quantite: 4,
  prix: 1300,
  unite: "m",
  total: 2200,
 
},
{
  designation: "Réamenagement salle HTA1",
  quantite: 4,
  prix: 1300,
  unite: "m",
  total: 2200,
 
},
  

  


];
data2 = [
  {
      designation: "Réamenagement salle HTA",
      quantite: 4,
      unite: "m",
      prix: 1300
  },
  {
    designation: "Réamenagement salle HTA",
    quantite: 4,
    unite: "m",
    prix: 1300
  },
  {
    designation: "Réamenagement salle HTA",
    quantite: 4,
    unite: "m",
    prix: 1300
  },
  {
    designation: "Réamenagement salle HTA",
    quantite: 4,
    unite: "m",
    prix: 1300
  },


];

}
