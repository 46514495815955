import { Component, OnInit } from '@angular/core';
import { AuthPagesService } from '../../pages/auth-pages/auth-pages.service';
import { Subscription } from 'rxjs';
import { UserCurrent } from 'src/app/pages/auth-pages/models/userCurrent.model';

@Component({
  selector: 'app-header-userbox',
  templateUrl: './header-userbox.component.html'
})
export class HeaderUserboxComponent implements OnInit {

  infoUser: UserCurrent;
  userSubscript: Subscription;
  constructor(private __authSrv: AuthPagesService) {
  }


  ngOnInit() {
    this.fetchData()
  }




  fetchData() {
    this.__authSrv.getUserCurrent().subscribe(dataUser => {
      console.log(dataUser, "datauser");
      this.infoUser = dataUser;
    })
  }
}
