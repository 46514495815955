import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BulletinHseDetailComponent } from './bulletin-hse-detail/bulletin-hse-detail.component';
import { BulletinHseListComponent } from './bulletin-hse-list/bulletin-hse-list.component';
import { BulletinHsePagesComponent } from './bulletin-hse-pages.component';


const routes: Routes = [
  {
    path:'',
    component: BulletinHsePagesComponent,
    children: [
      {
        path: "liste",
        component: BulletinHseListComponent
      },
      {
        path: "detail/:id",
        component: BulletinHseDetailComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BulletinHsePagesRoutingModule { }
