import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from './../../../theme-options';

@Component({
  selector: 'app-home-pages-dashboard',
  templateUrl: './home-pages-dashboard.component.html',
  styleUrls: ['./home-pages-dashboard.component.scss']
})
export class HomePagesDashboardComponent implements OnInit {
  public maskDate: Array<string | RegExp>;
  toggleMobileSidebar1: boolean = false;
  toggleMobileSidebar2: boolean = false;
  mytime: Date = new Date();

  constructor(
    public globals: ThemeOptions,
  ) { }

  ngOnInit() {
  }
}
