import { Component, Input, OnInit } from '@angular/core';
import { TravauxPagesService } from 'src/app/services/travauxPages/travaux-pages.service';

@Component({
  selector: 'app-travaux-list',
  templateUrl: './travaux-list.component.html',
  styleUrls: ['./travaux-list.component.scss']
})
export class TravauxListComponent implements OnInit {
  @Input() widgetView: boolean = false;
  rotate = true;
  travaux: any;

  constructor(
    private travauxService: TravauxPagesService
  ) {
    this.travaux = this.travauxService.getList();
  }

  ngOnInit() {}
}
