<div class="modal-header modal-dark  bg-plum-plate">
    <h6 class="modal-title">Formulaire de création d'une ressource</h6>
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
      <form [formGroup]="ressoureForm" (ngSubmit)="onSubmit()">
          <div class="p-4">
              <ngx-spinner [fullScreen]="false" name="sp5" size="medium" type="timer"></ngx-spinner>
              <div class="d-flex justify-content-between mb-3">
                  <div><h4 class="font-weight-bold m-0">Informations générales</h4></div>
              </div><hr>
              <div class="form-row">
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="ref">Reférence: <span class="text-danger">*</span></label>
                          <input class="form-control"
                              id="ref" formControlName="ref"
                              type="text"
                              placeholder="Référence de la ressource"
                              title="Référence de la ressource"
                              [ngClass]="{ 'is-invalid': submit && f.ref.errors, 'is-valid': submit && f.ref.valid }"
                          >
                          <div class="invalid-feedback" *ngIf="submit && f.ref.errors">
                              <div *ngIf="f.ref.errors.required">{{required.novide}}</div>
                          </div>
                      </div>  
                  </div>
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="categorie">Catégorie: <span class="text-danger">*</span></label>
                          <div class="input-group">
                              <select class="form-control" id="categorie" formControlName="categorie" title="Catégorie de la ressource">
                                  <option [ngValue]="null" disabled>Choisir une catégorie</option>
                                  <option *ngFor="let categorie of listCategorieRessource" [ngValue]="categorie['@id']">
                                      {{ categorie.libelle }}
                                     </option>
                              </select>
                              <button tooltip="Créer une categorie" placement="auto"  class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newCategorieModal.show()">
                                  <span class="btn-wrapper--icon">
                                      <i class="ion-md-add"></i>
                                  </span>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="type">Type: <span class="text-danger">*</span></label>
                          <div class="input-group">
                              <select class="form-control" id="type" formControlName="type" title="Type de la ressource">
                                  <option [ngValue]="null" disabled>Choisir un type de ressource</option>
                                  <option *ngFor="let ressource of listTypeRessource" [ngValue]="ressource['@id']">
                                      {{ ressource.libelle }}
                                     </option>                                </select>
                              <button tooltip="Créer un type" placement="auto"  class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newTypeRessourceModal.show()">
                                  <span class="btn-wrapper--icon">
                                      <i class="ion-md-add"></i>
                                  </span>
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="form-row">
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="famille">Famille: <span class="text-danger">*</span></label>
                          <div class="input-group">
                              <select class="form-control"  id="famille" (change)="selectFamille($event.target)"   formControlName="famille" title="Famille de la ressource">
                                  <option [ngValue]="null" disabled>Choisir une famille</option>
                                  <option *ngFor="let famille of listFamille"  [ngValue]="famille['@id']" >
                                      {{ famille.libelle }}
                                     </option>
                              </select>
                              <button tooltip="Créer une famille" placement="auto"  class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newFamilleModal.show()">
                                  <span class="btn-wrapper--icon">
                                      <i class="ion-md-add"></i>
                                  </span>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="sousFamille">Sous famille <span class="text-danger">*</span></label>
                          <div class="input-group">
                              <select class="form-control" id="sousFamille" formControlName="sousFamille" title="Sous famille">
                                  <option [ngValue]="null" disabled>Choisir une sous famille </option>
                                  <option *ngFor="let sousFamille of listSousFamilleByFamille"  [ngValue]="sousFamille['@id']" >
                                      {{ sousFamille.libelle }}
                                     </option>
                              </select>
                              <button tooltip="Créer une sous famille" placement="auto"  class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newSousFamilleModal.show()">
                                  <span class="btn-wrapper--icon">
                                      <i class="ion-md-add"></i>
                                  </span>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="libelle">Libellé: <span class="text-danger">*</span></label>
                          <input class="form-control"
                              id="libelle" formControlName="libelle"
                              type="text"
                              placeholder="Libellé de la ressource"
                              title="Libellé de la ressource"
                          />
                      </div>
                  </div>
              </div>
              <div class="form-row">
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="unite">Unités <span class="text-danger">*</span></label>
                          <div class="input-group">
                              <select class="form-control" id="unite" formControlName="unite">
                                  <option [ngValue]="null" disabled>Choisir une unité de ressource</option>
                                  <option *ngFor="let unite of listUniteRessource" [ngValue]="unite['@id']">
                                      {{ unite.libelle }}
                                     </option>
                              </select>
                              <button tooltip="Créer unité" placement="auto"  class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newUniteModal.show()">
                                  <span class="btn-wrapper--icon">
                                      <i class="ion-md-add"></i>
                                  </span>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="etatVente">Etat vente </label>
                          <div class="input-group">
                              <select class="form-control" id="etatVente" formControlName="etatVente" title="Etat vente">
                                  <option *ngFor="let vente of venteRow" [value]="vente.value">{{vente.label}}</option>
                              </select>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="etatAchat">Etat achat </label>
                          <div class="input-group">
                              <select class="form-control" id="etatAchat" formControlName="etatAchat" title="Etat achat">
                                  <option *ngFor="let achat of achatRow" [value]="achat.value">{{achat.label}}</option>
                              </select>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="form-group row">
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="nature">Nature: <span class="text-danger">* </span></label>
                          <span className="btn-wrapper--icon" container="body" triggers="mouseenter:mouseleave" placement="top" popover="Matière première ou produit fini">
                              <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
                          </span>
                          <div class="input-group">
                              <select formControlName="nature" id="nature" class="form-control" title="Nature de la ressource">
                                  <option [ngValue]="null" disabled>Choisir une nature de ressource</option>
                                  <option *ngFor="let nature of listNatureRessource" [ngValue]="nature['@id']">
                                      {{ nature.libelle }}
                                     </option>
                              </select>
                              <button tooltip="Créer nature ressource" placement="auto"  class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newNatureModal.show()">
                                  <span class="btn-wrapper--icon">
                                      <i class="ion-md-add"></i>
                                  </span>
                              </button>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <label for="stockAlert">Limite stock alert: <span class="text-danger">* </span></label>
                      <span className="btn-wrapper--icon" container="body" triggers="mouseenter:mouseleave" placement="top" popover="Saisir un nombre.  0 peut etre saisi en cas de stock nul">
                          <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
                      </span>
                      <input type="number" class="form-control"  min="0"
                          id="stockAlert" formControlName="stockAlert"
                          title="Limite stock alert"
                          placeholder="Limite stock alert"
                      />
                  </div>
                  <div class="col-md-4">
                    <label for="stockPhys">Stock physique: <span class="text-danger">* </span></label>
                    <input type="number" class="form-control"  min="0"
                        id="stockPhys" formControlName="stockPhys"
                        title="Stock physique"
                        placeholder="Stock physique"
                    />
                  </div>
                  <div class="col-md-12">
                    <div class="position-relative form-group">
                        <label for="fichiers">Joindre un fichier </label>
                        <div class="custom-file">
                            <input class="custom-file-input" id="fichiers"  ng2FileSelect type="file">
                            <label class="custom-file-label" for="fichiers">Fichier</label>
                        </div>
                    </div>
                </div>
                  <div class="col-md-12">
                      <label for="description">Description de la ressource</label>
                      <angular-editor [config]="config" formControlName="description"></angular-editor>
                  </div>
              </div>
              <div class="row mt-4">
                  <div class="col-md-12">
                      <span class="mb-2 mt-1 font-weight-bold">Information sur le prix</span>
                  </div>
              </div>
              <hr class="mt-0">
              <div class="form-row">
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="prix">Prix moyen: <span class="text-danger">*</span></label>
                          <input class="form-control" min="0"
                              id="prix" formControlName="prix"
                              type="number" placeholder="Prix moyen"
                              title="Prix moyen"
                          />
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="majoration">Coéfficient de majoration(%)</label>
                          <input class="form-control"
                              id="majoration" formControlName="majoration"  min="0" max="100"
                              type="number" placeholder="Coéfficient de majoration(%)"
                              title="Coéfficient de majoration"
                          />
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="perte">Coéfficient de perte(%)</label>
                          <input class="form-control"
                              id="perte" formControlName="perte"   min="0" max="100"
                              type="number" placeholder="Coéfficient de perte(%)"
                              title="Coéfficient de perte"
                          />
                      </div>
                  </div>
              </div>
              <div class="form-row">
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="prixHT">Prix de vente HT <span class="text-danger">* </span></label>
                          <span className="btn-wrapper--icon" container="body" triggers="mouseenter:mouseleave" placement="top" popover="(( Prix moyen * coefficient de majoration ) / 100 ) + Prix moyen">
                              <fa-icon [icon]="['far', 'question-circle']"></fa-icon>
                          </span>
                          <input type="number" class="form-control"
                              id="prixHT" formControlName="prixHT"   min="0"
                              placeholder="Prix de vente HT"
                              title="Prix de vente HT"
                          />
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="tva">TVA(%)</label>
                          <div class="input-group">
                              <input type="number" class="form-control"
                              id="tva" formControlName="tva"  min="0" max="100"
                              placeholder="Entrer la Tva(%)"
                              title="Entrer la Tva(%)"
                          />
                              <!-- <select formControlName="tva" id="tva" class="form-control">
                                  <option value="18" selected>18%</option>
                                  <option value="7.5">7.5%</option>
                                  <option value="0">0%</option>
                              </select> -->
                              <!-- <button tooltip="Créer TVA" placement="auto"  class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newTvaModal.show()">
                                  <span class="btn-wrapper--icon">
                                      <i class="ion-md-add"></i>
                                  </span>
                              </button> -->
                          </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <div class="position-relative form-group">
                          <label for="prixTTC">Prix de vente TTC</label>
                          <input type="number" class="form-control"
                              id="prixTTC" formControlName="prixTTC"
                              placeholder="Prix de vente TTC"  min="0"
                              title="Prix de vente TTC"
                          />
                      </div>
                  </div>
              </div>
          </div>
          <hr class="m-0">
          <div class=" px-4 py-2">
              <div class="row">
                  <div class="col-md-6">
                      <p class="text-danger mt-2">L'étoile (*) signifie que cet champ est obligatoire.</p>
                  </div>
                  <div class="col-md-6 text-right">
                      <button class="btn btn-first mr-2" type="button" (click)="ressoureForm.reset()"><i class="fas fa-broom  mr-2"></i> Nettoyer</button>
                      <button  class="btn btn-success" type="submit"><i class="fas fa-save mr-2"></i> Enregistrer</button>
                  </div>
              </div>
          </div>
      </form>
  </div>




<!--Type ressource modal add -->
<div #newTypeRessourceModal="bs-modal"  [config]="{backdrop: 'static'}"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter un type de ressource</h6>
                <button type="button" class="close" aria-label="Close" (click)="newTypeRessourceModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <div class="rounded ">
                        <div class="mx-3">
                            <form [formGroup]="typeRessourceForm" (ngSubmit)="addTypeRessource()">
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="type">Type de ressource <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control"
                                            id="type" formControlName="type" placeholder="Nom du responsable de suivi"
                                            [ngClass]="{ 'is-invalid': submit && typeF.type.errors }">
                                            <div *ngIf="submit && typeF.type.errors" class="invalid-feedback">
                                                <div *ngIf="typeF.type.errors.required">{{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            
                            </form>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="text-center px-3 py-2">
                    <button class="btn btn-first m-2" (click)="typeRessourceForm.reset()" type="button"><i class="fas fa-broom mr-1"></i>Nettoyer</button>
                    <button class="btn btn-success" (click)="addTypeRessource()" type="submit" ><i class="fas fa-save mr-1"></i>Enregister</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!--famille  modal add -->
<div #newFamilleModal="bs-modal"  [config]="{backdrop: 'static'}"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter une famille</h6>
                <button type="button" class="close" aria-label="Close" (click)="newFamilleModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <div class="rounded ">
                        <div class="mx-3">
                            <form [formGroup]="familleForm" (ngSubmit)="addFamille()">
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="famille">Famille <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control"
                                            id="famille" formControlName="famille" placeholder="Nom de la famille"
                                            [ngClass]="{ 'is-invalid': submit && familleF.famille.errors }">
                                            <div *ngIf="submit && familleF.famille.errors" class="invalid-feedback">
                                                <div *ngIf="familleF.famille.errors.required">{{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            
                            </form>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="text-center px-3 py-2">
                    <button class="btn btn-first m-2" (click)="familleForm.reset()" type="button"><i class="fas fa-broom mr-1"></i>Nettoyer</button>
                    <button class="btn btn-success" type="submit" (click)="addFamille()"><i class="fas fa-save mr-1"></i>Enregister</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!--sousFamille modal add -->
<div #newSousFamilleModal="bs-modal"  [config]="{backdrop: 'static'}"   backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter une famille</h6>
                <button type="button" class="close" aria-label="Close" (click)="newSousFamilleModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <div class="rounded ">
                        <div class="mx-3">
                            <form [formGroup]="sousFamilleForm" (ngSubmit)="addSousFamille()">
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="sousFamille">Famille <span class="text-danger">*</span></label>
                                            <select class="form-control" id="famille" formControlName="famille" title="Famille de la ressource">
                                                <option [ngValue]="null" disabled>Choisir une famille</option>
                                                <option *ngFor="let famille of listFamille" [ngValue]="famille['@id']">
                                                    {{ famille.libelle }}
                                                   </option>
                                            </select>
                                            <div *ngIf="submit && sousFamilleF.famille.errors" class="invalid-feedback">
                                                <div *ngIf="sousFamilleF.famille.errors.required">{{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="sousFamille">Sous Famille <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control"
                                            id="sousFamille" formControlName="sousFamille" placeholder="Nom de la sous famille"
                                            [ngClass]="{ 'is-invalid': submit && sousFamilleF.sousFamille.errors }">
                                            <div *ngIf="submit && sousFamilleF.sousFamille.errors" class="invalid-feedback">
                                                <div *ngIf="sousFamilleF.sousFamille.errors.required">{{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            
                            </form>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="text-center px-3 py-2">
                    <button class="btn btn-first m-2" (click)="sousFamilleForm.reset()" type="button"><i class="fas fa-broom mr-1"></i>Nettoyer</button>
                    <button class="btn btn-success" type="submit" (click)="addSousFamille()"><i class="fas fa-save mr-1"></i>Enregister</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!--unite modal add -->
<div #newUniteModal="bs-modal"  [config]="{backdrop: 'static'}"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter une unité </h6>
                <button type="button" class="close" aria-label="Close" (click)="newUniteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <div class="rounded ">
                        <div class="mx-3">
                            <form [formGroup]="uniteForm" (ngSubmit)="addUniteRessource()">
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="unite">Unité  <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control"
                                            id="unite" formControlName="unite" placeholder="Nom de l'unité"
                                            [ngClass]="{ 'is-invalid': submit && uniteF.unite.errors }">
                                            <div *ngIf="submit && uniteF.unite.errors" class="invalid-feedback">
                                                <div *ngIf="uniteF.unite.errors.required">{{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            
                            </form>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="text-center px-3 py-2">
                    <button class="btn btn-first m-2" (click)="uniteForm.reset()" type="button"><i class="fas fa-broom mr-1"></i>Nettoyer</button>
                    <button class="btn btn-success" type="submit" (click)="addUniteRessource()"><i class="fas fa-save mr-1"></i>Enregister</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!--nature modal add -->
<div #newNatureModal="bs-modal"  [config]="{backdrop: 'static'}"   backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter une nature </h6>
                <button type="button" class="close" aria-label="Close" (click)="newNatureModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <div class="rounded ">
                        <div class="mx-3">
                            <form [formGroup]="natureForm" (ngSubmit)="addNatureRessource()">
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="nature">Nature  <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control"
                                            id="nature" formControlName="nature" placeholder="Nature "
                                            [ngClass]="{ 'is-invalid': submit && natureF.nature.errors }">
                                            <div *ngIf="submit && natureF.nature.errors" class="invalid-feedback">
                                                <div *ngIf="natureF.nature.errors.required">{{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            
                            </form>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="text-center px-3 py-2">
                    <button class="btn btn-first m-2" (click)="natureForm.reset()" type="button"><i class="fas fa-broom mr-1"></i>Nettoyer</button>
                    <button class="btn btn-success" type="submit" (click)="addNatureRessource()"><i class="fas fa-save mr-1"></i>Enregister</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!--categorie modal add -->
<div #newCategorieModal="bs-modal"  [config]="{backdrop: 'static'}"   backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter une categorie</h6>
                <button type="button" class="close" aria-label="Close" (click)="newCategorieModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <div class="rounded ">
                        <div class="mx-3">
                            <form [formGroup]="categorieForm" (ngSubmit)="addCategorie()">
                                <div class="form-row">
                                    <div class="col-md-12">
                                        <div class="position-relative form-group">
                                            <label for="libelle">Libelle <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control"
                                            id="libelle" formControlName="libelle" placeholder="Nom de la categorie"
                                            [ngClass]="{ 'is-invalid': submit && categorieF.sousFamille.errors }">
                                            <div *ngIf="submit && categorieF.libelle.errors" class="invalid-feedback">
                                                <div *ngIf="categorieF.libelle.errors.required">{{required.novide}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            
                            </form>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="text-center px-3 py-2">
                    <button class="btn btn-first m-2" (click)="categorieForm.reset()" type="button"><i class="fas fa-broom mr-1"></i>Nettoyer</button>
                    <button class="btn btn-success" type="submit" (click)="addCategorie()"><i class="fas fa-save mr-1"></i>Enregister</button>
                </div>
            </div>
        </div>
    </div>
</div>

<notifier-container></notifier-container>