import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RessourcePages } from 'src/app/models/ressource-pages';

@Injectable({
  providedIn: 'root'
})
export class RessourcePagesService {
  ressource: RessourcePages;
  private readonly url = "api/ressources.json";

  constructor(
    private http: HttpClient
  ) { }

  setRessource(ressource: RessourcePages) {
    this.ressource = ressource
  }

  getRessource(): RessourcePages {
    return this.ressource
  }

  getList(): Observable<RessourcePages[]> {
    return this.http.get<RessourcePages[]>(this.url).pipe(
      tap(ressources => console.log('ressource:', ressources)),
      catchError(this.handleHttpError)
    );
  }
  
  private handleHttpError(err: HttpErrorResponse) {
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${err.status}, ` +
        `body was: ${err.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
