import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proces-verbal-pages',
  templateUrl: './proces-verbal-pages.component.html',
  styleUrls: ['./proces-verbal-pages.component.scss']
})
export class ProcesVerbalPagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
