import { Component, OnInit } from '@angular/core';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'app-opportunite-kanban',
  templateUrl: './opportunite-kanban.component.html',
  styleUrls: ['./opportunite-kanban.component.scss']
})
export class OpportuniteKanbanComponent implements OnInit {
  vamps = [
    { name: "Bad Vamp" },
    { name: "Petrovitch the Slain" },
    { name: "Bob of the Everglades" },
    { name: "The Optimistic Reaper" }
  ];

  vamps2 = [
    { name: "Dracula" },
    { name: "Kurz" },
    { name: "Vladislav" },
    { name: "Deacon" }
  ];

  constructor(private dragulaService: DragulaService) {
    this.dragulaService.dropModel("VAMPIRES").subscribe(args => {
        console.log(args);
    });
  }
  ngOnInit() {
  }

ngOnDestroy(){
    console.log("destroy")
    this.dragulaService.remove;
}

}
