<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-piggy display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>Compte d'exploitation</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">
                    Statistique générales des budget
                </div>
            </div>
        </div>
        <div class="flex-fill text-right">
            <form class="form-inline float-right">
                <div class="position-relative form-group"><label class="sr-only" for="mois"></label>
                    <select class="custom-select mr-1" id="mois" name="mois" type="select" tooltip="Filtre par mois" style="width: 145px;">
                        <option value="janvier">Janvier</option>
                        <option value="évrier">Février</option>
                        <option value="mars">Mars</option>
                        <option value="avril">Avril</option>
                        <option value="mai">Mai</option>
                        <option value="juin">Juin</option>
                        <option value="juillet">Juillet</option>
                        <option value="aôut">Aôut</option>
                        <option value="septembre">Septembre</option>
                        <option value="octobre">Octobre</option>
                        <option value="novembre">Novembre</option>
                        <option value="décembre">Décembre</option>
                    </select>
                </div>
                <div class="position-relative form-group"><label class="sr-only" for="annee"></label>
                    <select class="custom-select mr-1" id="annee" name="annee" type="select" tooltip="Filtre par année" style="width: 145px;">
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                    </select>
                </div>
                <div class="position-relative form-group"><label class="sr-only" for="projet"></label>
                    <select class="custom-select mr-1" id="projet" name="projet" type="select" tooltip="Filtre par projet" style="width: 145px;">
                        <option value="Projet 1">Projet 1</option>
                        <option value="Projet 2">Projet 2</option>
                        <option value="projet 3">projet 3</option>
                    </select>
                </div>
                <div class="position-relative form-group"><label class="sr-only" for="chantier"></label>
                    <select class="custom-select mr-1" id="chantier" name="chantier" type="select" tooltip="Filtre par chantier" style="width: 145px;">
                        <option value="chantier 1">Chantier 1</option>
                        <option value="chantier 2">Chantier 2</option>
                        <option value="chantier 3">Chantier 3</option>
                    </select>
                </div>
                <button class="btn btn-primary mr-2" tooltip="Filtrer" type="button"><i class="fas fa-filter"></i></button>
            </form>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-4 mb-4">
        <div class="d-flex bg-neutral-first align-items-center p-3 rounded shadow-sm">
            <ngx-gauge
                backgroundColor="#d5ecff"
                cap="round" class="img-fluid mr-2"
                foregroundColor="#4191ff"
                max="100" min="0" size="84"
                thick="6"
                type="full" value="76">
            </ngx-gauge>
            <div>
                <div class="text-black-50">Budget proposé</div>
                <div class="font-weight-bold display-4 py-1 text-first">
                    <span>887 000 000</span>
                </div>
                <div class="text-black-50 font-size-sm">
                    <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1"></fa-icon>
                    Sommes des budgets proposés
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 mb-4">
        <div class="d-flex bg-secondary align-items-center p-3 rounded shadow-sm">
            <ngx-gauge
                backgroundColor="#edeef1"
                cap="round" class="img-fluid mr-2"
                foregroundColor="#1bc943"
                max="100" min="0" size="84"
                thick="6"
                type="full" value="76">
            </ngx-gauge>
            <div>
                <div class="text-black-50">Budget validés</div>
                <div class="font-weight-bold display-4 py-1 text-success">
                    <span>687 000 000</span>
                </div>
                <div class="text-black-50 font-size-sm">
                    <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1"></fa-icon>
                    Sommes des budgets validés
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 mb-4">
        <div class="d-flex bg-neutral-danger rounded align-items-center text-danger p-3 shadow-sm">
            <ngx-gauge
                backgroundColor="#e8bbbf"
                cap="round" class="img-fluid mr-2"
                foregroundColor="#f83245"
                max="100" min="0" size="84"
                thick="6"
                type="full" value="35">
            </ngx-gauge>
            <div>
                <div>Budget consommé</div>
                <div class="font-weight-bold display-4 py-1">
                    <span>587 000 000</span>
                </div>
                <div class="opacity-7 font-size-sm">
                    <fa-icon [icon]="['fas', 'chevron-down']" class="font-size-sm pr-1"></fa-icon>
                    Sommes des budgets depenses
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card card-box border-0 mb-4">
    <div class="card-header">
        <div class="card-header--title">
            <h6 class="font-size-lg mb-0 py-2 font-weight-bold">Diagramme des budgets et depense</h6>
        </div>
        <div class="card-header--actions">
            <div class="btn-group btn-group-sm" dropdown>
                <button class="btn btn-link btn-link-second p-0 text-darck" dropdownToggle type="button">
                    <fa-icon [icon]="['fas', 'ellipsis-h']" class="font-size-lg"></fa-icon>
                </button>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-xl dropdown-menu-right">
                    <ul class="nav nav-neutral-dark nav-pills flex-column px-3">
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><span><i class="fas fa-calendar-alt mr-1"></i> Semaine</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><span><i class="fas fa-calendar-alt mr-1"></i> Mois</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><span><i class="fas fa-calendar-alt mr-1"></i> Années</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-3">
        <apx-chart class="mb-2"
            [chart]="{
                type: 'area',
                height: '311',
                sparkline: { enabled: false },
                toolbar: { show: false }
            }"
            [colors]="['#4191ff', '#f83245']"
            [dataLabels]="{ enabled: false }"
            [fill]="{
                opacity: 0.85,
                colors: ['#4191ff', '#fec1c8']
            }"
            [grid]="{
                strokeDashArray: '5',
                borderColor: 'rgba(125, 138, 156, 0.3)'
            }"
            [labels]="['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aôut', 'Septembre', 'Octobre','Novembre', 'Décembre']"
            [legend]="{ show: true }"
            [series]="[
                {
                    name: 'Budget validé',
                    data: [3.3, 3.1, 4.0, 5.8, 2.1, 3.6, 3.2, 4.0, 5.8, 2.1, 3.6, 3.2]
                },
                {
                    name: 'Depenses',
                    data: [2.1, 2.1, 2.8, 2.8, 4.3, 2.7, 1.4, 2.8, 2.8, 4.3, 2.7, 1.4]
                }
            ]"

            [stroke]="{
                show: true,
                width: 2,
                colors: ['transparent']
            }"
        >
        </apx-chart>
    </div>
</div>

<div class="row">
    <div class="col-md-6 mb-4">
        <div class="card rounded-0">
            <div class="grid-menu grid-menu-2col">
                <div class="no-gutters row">
                    <div class="col-md-6">
                        <div class="p-3">
                            <h5>Montant Factures</h5>
                            <h2 class="text-right">
                                <i class="fas fa-file-invoice float-left text-primary"></i>
                                <span class="font-weight-bold" [countUp]="455"></span>
                            </h2>
                            <p class="mb-0">1000 000 000</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-3 bg-night-sky text-white">
                            <h5>Facture payées</h5>
                            <h2 class="text-right">
                                <i class="fas fa-file-invoice-dollar float-left"></i>
                                <span class="font-weight-bold" [countUp]="25"></span>
                            </h2>
                            <p class="mb-0">1000 000 000</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-3 bg-midnight-bloom text-white">
                            <h5>Facture Impayées</h5>
                            <h2 class="text-right">
                                <i class="fas fa-times-circle float-left"></i>
                                <span class="font-weight-bold" [countUp]="455"></span>
                            </h2>
                            <p class="mb-0">1000 000 000</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-3" title="Facture en attente de payement">
                            <h5>Facture En attente</h5>
                            <h2 class="text-right">
                                <i class="fas fa-pause-circle float-left text-warning"></i>
                                <span class="font-weight-bold" [countUp]="455"></span>
                            </h2>
                            <p class="mb-0">1000 000 000</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 mb-4">
        <div class="card rounded-0">
            <div class="grid-menu grid-menu-2col">
                <div class="no-gutters row">
                    <div class="col-md-6">
                        <div class="p-3">
                            <h5>Budget validé</h5>
                            <h2 class="text-right">
                                <i class="fas fa-check-circle float-left text-success"></i>
                                <span class="font-weight-bold" [countUp]="45"></span>
                            </h2>
                            <p class="mb-0">1000 000 000</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-3" title="Budget en attente de validation">
                            <h5>Budget en attente</h5>
                            <h2 class="text-right">
                                <i class="fas fa-pause-circle float-left text-warning"></i>
                                <span class="font-weight-bold" [countUp]="25"></span>
                            </h2>
                            <p class="mb-0">1000 000 000</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-3">
                            <h5>Budget consommé</h5>
                            <h2 class="text-right">
                                <i class="fas fa-sign-out-alt float-left text-danger"></i>
                                <span class="font-weight-bold">1000 000 000</span>
                            </h2>
                            <p class="mb-0 text-muted"><i class="fas fa-coins"></i> Montant des depenses</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="p-3 bg-secondary">
                            <h5>Montant restant</h5>
                            <h2 class="text-right">
                                <i class="fas fa-piggy-bank float-left text-primary"></i>
                                <span class="font-weight-bold text-dark">8000 000 000</span>
                            </h2>
                            <p class="mb-0 text-muted">Budget validé - depenses</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card card-box border-0 mb-4">
    <div class="card-header p-3">
        <div class="card-header--title"><b>Liste des chantiers en depassement budgetaire</b></div>
    </div>
    <div class="card-body p-0">
        <div class="table-responsive m-0">
            <table class="table table-hover text-nowrap m-0">
                <thead>
                    <th class="font-weight-bold">Chantier</th>
                    <th class="font-weight-bold">Budget proposé</th>
                    <th class="font-weight-bold">Budget validé</th>
                    <th class="font-weight-bold">Budget consommé</th>
                </thead>
                <tbody>
                    <tr>
                        <td>Chantier 1</td>
                        <td>1500 000 000</td>
                        <td>1300 000 000</td>
                        <td><span class="text-danger">1000 000 000</span></td>
                    </tr>
                    <tr>
                        <td>Chantier 1</td>
                        <td>1500 000 000</td>
                        <td>1300 000 000</td>
                        <td><span class="text-danger">1000 000 000</span></td>
                    </tr>
                    <tr>
                        <td>Chantier 1</td>
                        <td>1500 000 000</td>
                        <td>1300 000 000</td>
                        <td><span class="text-danger">1000 000 000</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer px-3 py-2">
        <div class="d-flex justify-content-between">
            <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
            <div class="d-flex align-items-center">
                <span>Show</span>
                <select class="mx-1 form-control form-control-sm" id="" name="">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="40">50</option>
                    <option value="40">100</option>
                </select>
                <span>entries</span>
            </div>
        </div>
    </div>
</div>

<div class="card card-box border-0">
    <div class="card-header p-3">
        <div class="card-header--title"><b>Liste des chantiers n'ayant pas de budget</b></div>
    </div>
    <div class="card-body p-0">
        <div class="table-responsive m-0">
            <table class="table table-hover text-nowrap m-0">
                <thead>
                    <th class="font-weight-bold">Chantier</th>
                    <th class="font-weight-bold">Budget proposé</th>
                    <th class="font-weight-bold">Date début</th>
                    <th class="font-weight-bold">Date fin</th>
                </thead>
                <tbody>
                    <tr>
                        <td>Chantier 1</td>
                        <td>1500 000 000</td>
                        <td><span>01/09/2021</span></td>
                        <td><span>23/09/2021</span></td>
                    </tr>
                    <tr>
                        <td>Chantier 1</td>
                        <td>1500 000 000</td>
                        <td><span>01/09/2021</span></td>
                        <td><span>23/09/2021</span></td>
                    </tr>
                    <tr>
                        <td>Chantier 1</td>
                        <td>1500 000 000</td>
                        <td><span>01/09/2021</span></td>
                        <td><span>23/09/2021</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer px-3 py-2">
        <div class="d-flex justify-content-between">
            <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
            <div class="d-flex align-items-center">
                <span>Show</span>
                <select class="mx-1 form-control form-control-sm" id="" name="">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="40">50</option>
                    <option value="40">100</option>
                </select>
                <span>entries</span>
            </div>
        </div>
    </div>
</div>