import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BudgetPages } from 'src/app/models/budget-pages';

@Injectable({
  providedIn: 'root'
})
export class BudgetPagesService {
  budget: BudgetPages;
  private readonly url = "api/budgets.json";

  constructor(
    private http: HttpClient
  ) { }

  setBudget(budget: BudgetPages) {
    this.budget = budget
  }

  getBudget(): BudgetPages {
    return this.budget
  }

  getList(): Observable<BudgetPages[]> {
    return this.http.get<BudgetPages[]>(this.url).pipe();
  }
}
