import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChantierPages } from 'src/app/models/chantier-pages';
import { ChantierPagesService } from 'src/app/services/chantier-pages/chantier-pages.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-chantier-detail',
  templateUrl: './chantier-detail.component.html',
  styleUrls: ['./chantier-detail.component.scss']
})
export class ChantierDetailComponent implements OnInit {
  chantier: ChantierPages = <ChantierPages>{};

  constructor(
    public globals: ThemeOptions,
    private route: ActivatedRoute,
    private chantierService: ChantierPagesService
  ) {
    this.chantier = this.chantierService.getChantier();
    // console.log(this.chantiers)
    const id: number = +this.route.snapshot.paramMap.get('id');
    this.chantierService.getList().subscribe((chantiers: ChantierPages[]) => {
      this.chantier = chantiers.find(chantier => chantier.id == id);
    });
  }

  ngOnInit() {
  }

}
