<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-box1 display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
              <h1>Bibliothèque des ressources </h1>
              <div *ngIf="globals.pageTitleDescription" class="app-page-title--description"> Liste des ressources</div>
            </div>
        </div>
        <div class="flex-fill text-right">
          <button  (click)="addRessoure('', false)" class="btn btn-success ml-2" tooltip="Créer une nouvelle ressource" type="button"><i class="fas fa-plus-circle mr-2"></i> Ajouter</button>
        </div>
    </div>
  </div>
  
  <div class="card card-box border-0">
    <div class="card-header p-3">
      <div class="card-header--title font-weight-bold">Liste des ressources</div>
      <div class="card-header--actions">
          <div class="btn-group" dropdown>
              <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                  dropdownToggle
                  tooltip="Action"
                  type="button">
                  <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
              </button>
              <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                  <ul class="nav nav-pills flex-column p-3">
                      <li class="nav-item">
                          <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                      </li>
                      <li class="nav-item">
                          <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                      </li>
                      <li class="nav-item">
                          <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                      </li>
                      <li class="nav-item">
                          <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
    </div>
    <div class="card-body p-3">
      <div class="d-flex justify-content-between mb-3">
          <div class="search-wrapper">
            <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
            <input class="form-control" placeholder="Recherche dans la liste" type="search">
          </div>
      </div>
      <div class="table-responsive m-0">
        <table class="table table-hover text-nowrap m-0">
            <thead class="thead-light">
              <tr>
                <th class="text-center"><div class="ml-3"></div></th>
                <th class="text-left">Ref</th>
                <th class="text-left">Type</th>
                <th class="text-left">Libellé</th>
                <th class="text-left">Famille</th>
                <th class="text-left">Sous famille</th>
                <th class="text-left">Prix de vente (XOF)</th>
                <th class="text-left">Unités</th>
                <th class="text-left">Prix moyen pondéré</th>
                <th class="text-left">stock physique</th>
                <th class="text-left">stock d'alert</th>
                <th class="text-left">Etat vente</th>
                <th class="text-left">Etat achat</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ressource of listRessources; let i = index">
                <td data-toggle="collapse" attr.data-target="#row{{i}}" class="accordion-toggle">
                    <a class="btn btn-sm"><i class="fas fa-expand-arrows-alt"></i></a>
                </td>
                <td class="text-left"><a [routerLink]="['/pages/ressource/detail', ressource?.id]">{{ressource?.reference}}</a></td>
                <td class="text-left">{{ressource?.typeRessource.libelle}}</td>
                <td class="text-left">{{ressource?.libelle}}</td>
                <td class="text-left">{{ressource?.sousFamille.famille.libelle}}</td>
                <td class="text-left">{{ressource?.sousFamille.libelle}}</td>
                <td class="text-left">{{ressource?.prixVenteHT| number:'': 'fr-FR'}}</td>
                <td class="text-left">{{ressource?.unite.libelle}}</td>
                <td class="text-left">{{ressource?.pmp| number:'': 'fr-FR'}}</td>
                <td class="text-left">{{ressource?.physiqueStock}}</td>
                <td class="text-left">{{ressource?.limiteStock}}</td>
                <td class="text-left">
                  <span *ngIf="ressource?.etatVente == 1" class="badge bg-{{ressource?.etatVente == 1 ? 'neutral-success text-success': 'neutral-warning text-warning'}}">En vente</span>
                  <span  *ngIf="ressource?.etatVente == 2" class="badge bg-{{ressource?.etatVente == 1 ? 'neutral-success text-success': 'neutral-warning text-warning'}}">Hors vente</span>
                </td>
                <td class="text-left">
                  <span *ngIf="ressource?.etatAchat == 1" class="badge bg-{{ressource?.etatAchat == 1? 'neutral-success text-success': 'neutral-warning text-warning'}}">En achat</span>
                  <span *ngIf="ressource?.etatAchat == 2" class="badge bg-{{ressource?.etatAchat == 1? 'neutral-success text-success': 'neutral-warning text-warning'}}">Hors achat</span>
                </td>
                <td class="text-center">
                  <button placement="auto" [routerLink]="['/pages/ressource/detail',ressource?.id]"  tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill mr-2 btn-primary" type="button">
                    <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                  </button>
                  <button  placement="auto" (click)="addRessoure(ressource, true)"  tooltip="Modifier"  class="btn d-40 p-0 btn-pill mr-2 btn-info" type="button">
                    <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                  </button>
                  <button [swal]="deleteSwal" (confirm)="deleteRessource(ressource.id)" placement="auto"  tooltip="Supprimer"  class="btn d-40 p-0 btn-pill btn-danger" type="button">
                    <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                  </button>

                  <swal  type="warning" #deleteSwal title="Supprimer" text="Voulez vous supprimer cette ressource ?"  [showCancelButton]="true"
                  [focusCancel]="true" confirmButtonColor="#d33" cancelButtonColor="#6e7881" cancelButtonText='Annuler <i class="fas fa-times"></i>'  confirmButtonText='Supprimer <i class="fas fa-trash"></i>'>
                  </swal>
                </td>
              </tr>
              <tr *ngFor="let ressource of listRessources; let i = index">
                <td colspan="14" class="hiddenRow">
                    <div class="accordian-body collapse p-3" id="row{{i}}">
                      <div class="table-responsive m-0">
                        <table class="table table-hover text-nowrap m-0">
                          <thead>
                            <tr>
                              <th class="text-left">Ref</th>
                              <th class="text-left">Libellé</th>
                              <th class="text-left">Prix de vente</th>
                              <th class="text-left">Unités</th>
                              <th class="text-left">stock d'alert</th>
                              <th class="text-left">stock physique</th>
                              <th class="text-left">Etat vente</th>
                              <th class="text-left">Etat achat</th>
                              <th class="text-center">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td colspan="9"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total:</th>
                <th colspan="5"></th>
                <th>10000000000</th>
                <th></th>
                <th>1000000000</th>
                <th colspan="5"></th>
              </tr>
            </tfoot>
        </table>
      </div>
    </div>
    <div class="card-footer py-3 d-flex justify-content-between">
      <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
      <div class="d-flex align-items-center">
        <span>Show</span>
        <select class="mx-1 form-control form-control-sm" id="" name="">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
        </select>
        <span>entries</span>
      </div>
    </div>
  </div>
  
 <!--MODAL AJOUT DE RESSOURCES-->
 <div #openRessourceModal="bs-modal"  [config]="{backdrop: 'static'}"   backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content ">
        <div class="modal-header modal-dark  bg-plum-plate">
          <h6 class="modal-title">Formulaire de création d'une ressource</h6>
          <button type="button" class="close" aria-label="Close" (click)="openRessourceModal.hide()">
              <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <!-- <app-ressource-edit></app-ressource-edit> -->
        </div>
    </div>
  </div>
</div>
<!--FIN MODAL AJOUT DE RESSOURCE-->
<notifier-container></notifier-container>
