import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TravauxDetailComponent } from './travaux-detail/travaux-detail.component';
import { TravauxListComponent } from './travaux-list/travaux-list.component';
import { TravauxPagesComponent } from './travaux-pages.component';


const routes: Routes = [
  {
    path:'',
    component: TravauxPagesComponent,
    children: [
      {
        path: "liste",
        component: TravauxListComponent
      },
      {
        path: "detail/:id",
        component: TravauxDetailComponent
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TravauxPagesRoutingModule { }
