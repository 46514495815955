import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EtudePrixPagesRoutingModule } from './etude-prix-pages-routing.module';
import { EtudePrixDetailComponent } from './etude-prix-detail/etude-prix-detail.component';
import { EtudePrixEditComponent } from './etude-prix-edit/etude-prix-edit.component';
import { EtudePrixListComponent } from './etude-prix-list/etude-prix-list.component';
import { EtudePrixPagesComponent } from './etude-prix-pages.component';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebook, faTwitter, faAngular, faVuejs, faReact, faHtml5, faGoogle, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faChevronRight, faSitemap, faPrint, faMapMarkerAlt, faTachometerAlt, faAlignCenter, faExternalLinkAlt, faShareSquare, faInfoCircle, faSync, faQuoteRight, faStarHalfAlt, faShapes, faCarBattery, faTable, faCubes, faPager,  faCameraRetro, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faUnlockAlt, faDownload, faAward, faPlayCircle, faReply, faUpload, faBars, faEllipsisV, faSave, faSlidersH, faCaretRight, faChevronUp, faPlus, faLemon, faChevronLeft, faTimes, faChevronDown, faFilm, faSearch, faEllipsisH, faCog, faArrowsAltH, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLink } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faTimesCircle, faDotCircle, faThumbsUp, faComments, faFolderOpen, faTrashAlt, faFileImage, faFileArchive, faLifeRing, faCommentDots, faFolder, faKeyboard, faCalendarAlt, faEnvelope, faAddressCard, faMap, faObjectGroup, faImages, faUser, faLightbulb, faGem, faClock, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar } from '@fortawesome/free-regular-svg-icons';
import { AngularTreeGridModule } from 'angular-tree-grid';
import {RouterModule} from '@angular/router';

import { ProgressbarModule, BsDropdownModule, AlertModule, PaginationModule, TabsModule, ModalModule, BsDatepickerModule, TooltipModule } from 'ngx-bootstrap';

import {AngularEditorModule} from '@kolkov/angular-editor';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BadgeCellViewComponent } from 'src/app/shared-components/tree/badge-cell-view/badge-cell-view.component';

@NgModule({
  declarations: [
    EtudePrixPagesComponent,
    EtudePrixDetailComponent,
    EtudePrixEditComponent,
    EtudePrixListComponent
  ],
  imports: [
    CommonModule,
    EtudePrixPagesRoutingModule,
    Ng2SmartTableModule,
    TabsModule,
    FontAwesomeModule,
    AngularTreeGridModule,
    ModalModule,
    BsDatepickerModule,
    TooltipModule,
    AlertModule,
    PaginationModule,
    RouterModule,
    ProgressbarModule,
    BsDropdownModule,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    ArchwizardModule,
    PerfectScrollbarModule

  ],
  exports: [
    EtudePrixDetailComponent,
    EtudePrixEditComponent,
    EtudePrixListComponent
  ],
  entryComponents: [
    BadgeCellViewComponent,
  ],
})
export class EtudePrixPagesModule {
  constructor(library: FaIconLibrary){
    library.addIcons(faFacebook, faPrint, faAlignCenter, faMapMarkerAlt, faTachometerAlt, faExternalLinkAlt, faShareSquare, faSitemap, faInfoCircle, faLifeRing, faTwitter, faQuoteRight, faStarHalfAlt, faSync, faShapes, faCarBattery, faTable, faCubes, faPager,  faAngular, faVuejs, faReact, faHtml5, faCheckCircle, faTimesCircle, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faThumbsUp, faCameraRetro, faUnlockAlt, faDownload, faUpload, faReply, faGoogle, faFileImage, faFolderOpen, faBars, faTrashAlt, faSave, faPlayCircle, faEllipsisV, faEllipsisH, faSlidersH, faFileArchive, faAward, faCaretRight, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub, faPlus, faFolder, faTimes, faEnvelope, faAddressCard, faMap, faCalendarAlt, faImages, faFilm, faClock, faSearch, faChevronRight, faChevronUp, faChevronLeft, faChevronDown, faLink, faLightbulb, faGem, faCog, faDotCircle, faArrowsAltH, faComments, faCommentDots, faKeyboard, faObjectGroup, faUser, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLemon);
  }
}
