import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilOptions } from 'src/app/shared-components/util/util-option';

@Component({
  selector: 'app-rapport-edit',
  templateUrl: './rapport-edit.component.html',
  styleUrls: ['./rapport-edit.component.scss']
})
export class RapportEditComponent implements OnInit {
  ouvertRow = UtilOptions.reponseOuiNom;
  required = UtilOptions.required;
  form: FormGroup;
  imgUrl: any;
  submit = false;
  saveItem = ""
  inputGroup = false;
  ressourceData: any;
  enginData: any;
  techniqueData: any;

  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.newForm();
    this.imgUrl = [];
    this.ressourceData = [];
    this.enginData = [];
    this.techniqueData = [];
  }

  ngOnInit() {
  }

  newForm() {
    this.form = this.formBuilder.group({
      id: [null],
      travaux: [null, [Validators.required]],
      tache: [null, [Validators.required]],
      fichier: [null, [Validators.required]],
      description: [null, [Validators.required]],
      type: [null, [Validators.required]],
      ressource: [null, [Validators.required]],
      quantite: [null, [Validators.required]],
      unite: [null, [Validators.required]],

      libelle: [null, [Validators.required]],
      heureDebut: [null, [Validators.required]],
      heureFin: [null, [Validators.required]],
      pause: [null, [Validators.required]],
      tempsTavail: [null, [Validators.required]],
      cmptDebut: [null, [Validators.required]],
      cmptFin: [null, [Validators.required]],
      carburant: [null, [Validators.required]],
      puCarburant: [null, [Validators.required]],
      cout: [null, [Validators.required]],
      depense: [null, [Validators.required]],

      fonction: [null, [Validators.required]],
      nbPersonne: [null, [Validators.required]],
      puPersonne: [null, [Validators.required]],
    })
  }

  saveElement(element: string) : void{
    this.saveItem = element;
    if(this.saveItem == "ressources"){
      this.ressourceData.push(this.form.value);
      this.form.reset();
    }
    else if(this.saveItem == "engin"){
      this.enginData.push(this.form.value);
      this.form.reset();
    }
    else if(this.saveItem == "technique"){
      this.techniqueData.push(this.form.value);
      this.form.reset();
    }
    else{
      console.log("Aucune donnée enregistrer");
    }
  }

  deleteElement(elSelect: string, element) {
    this.saveItem = elSelect;
    if(this.saveItem == "ressources"){
      this.ressourceData.forEach((value,index) => {
        if(value == element)
          this.ressourceData.splice(index, 1);
      });
    }
    else if(this.saveItem == "engin"){
      this.enginData.forEach((value,index) => {
        if(value == element)
          this.enginData.splice(index, 1);
      });
    }
    else if(this.saveItem == "technique"){
      this.techniqueData.forEach((value,index) => {
        if(value == element)
          this.techniqueData.splice(index, 1);
      });
    }
    else{
      console.log("Aucune donnée enregistrer pour etre suprimer");
    }



    this.ressourceData.forEach((value,index) => {
      if(value == element)
        this.ressourceData.splice(index, 1);
    });
  }
  
  onSubmit(){
    this.submit = true;
    console.log(this.form.value);
  }
  
  // "onSelectImg" pour la selection des image dans le formulaire et "deleteImg" pour la suppression
  onSelectImg(e){
    if(e.target.files){
      var reader = new FileReader();
      var file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload=(events:any)=>{
        this.imgUrl.push(events.target.result);
      }
    }
  }
  deleteImg(el) {
    this.imgUrl.forEach((value,index) => {
      if(value == el)
        this.imgUrl.splice(index, 1);
    });
  }
  get f() { return this.form.controls; }

}