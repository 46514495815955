import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { Ressource } from 'src/app/models/travaux-ressources';
import { UtilOptions } from 'src/app/shared-components/util/util-option';

@Component({
  selector: 'app-travaux-edit',
  templateUrl: './travaux-edit.component.html',
  styleUrls: ['./travaux-edit.component.scss']
})
export class TravauxEditComponent implements OnInit {
  sousTraiterRow = UtilOptions.reponseOuiNom;
  typeRow = UtilOptions.typeRessources;
  ressource = new Ressource();
  lineTitle: string;
  dataArray = [];
  form: FormGroup;
  inputGroupe: boolean = true;
  changeInput: boolean = true;
  montant:number;
  public mask: Array<string | RegExp>;
  public maskDate: Array<string | RegExp>;

  private notifier: NotifierService;

  unites = [
    { label: 'km', value: 'km' },
    { label: 'hm', value: 'hm' },
    { label: 'dm', value: 'dm' },
    { label: 'm', value: 'm' },
    { label: 'm²', value: 'm²' },
    { label: 'dm', value: 'dm' },
    { label: 'cm', value: 'cm' },
    { label: 'mm', value: 'mm' },
  ];

  selected: string;
  vehicule: string[] = ['Toyota', 'Nissan', 'Peugeo', 'Alpin', 'Renauld', '...'];
  selected1: string;
  travaux: string[] = ['Toyota', 'Nissan', 'Peugeo', 'Alpin', 'Renauld', '...'];
  selected2: string;
  sousTravaux: string[] = ['Toyota', 'Nissan', 'Peugeo', 'Alpin', 'Renauld', '...'];
  

  constructor(
    notifier: NotifierService,
    private formBuild: FormBuilder,
  ) {
    this.notifier = notifier;
    this.maskDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    this.newForm();
  }

  public finishWizard( type: string, message: string ): void {
    this.notifier.notify( type, message );
  }

  ngOnInit(){
    this.dataArray.push(this.ressource);
  }

  newForm(){
    this.form = this.formBuild.group({
      id: [null],
      travaux: [null, [Validators.required]],
      debutTravaux: [null, [Validators.required]],
      finTravaux: [null, [Validators.required]],
      responsable: [null, [Validators.required]],
      prestataire: [null, [Validators.required]],
      soutraitance: ['NON', [Validators.required]],
      soutraitant: [null, [Validators.required]],
      mtnSoutraitance: [null, [Validators.required]],
      fichier: [null, [Validators.required]],
      description: [null, [Validators.required]],

      // Tâche
      tache: [null, [Validators.required]],
      debutTache: [null, [Validators.required]],
      finTache: [null, [Validators.required]],
      responsableTache: [null, [Validators.required]],
      dureTâche: [null, [Validators.required]],
      descriptionTache: [null, [Validators.required]],
      contactLies: [null, [Validators.required]],
      
      typeRessource: [null, [Validators.required]],
      ressource: [null, [Validators.required]],
      quantite: [null, [Validators.required]],
      unite: [null, [Validators.required]],
      priorite: [null, [Validators.required]],
      prix: [null, [Validators.required]]
    });
  }

  onSubmit(){
    console.log(this.form.value);
    this.form.reset();
  }

  reset() {
    this.form.reset();
  }
  addForm(){
    this.ressource = new Ressource();
    this.dataArray.push(this.ressource);
  }
  removeForm(index){
    this.dataArray.splice(index);
  }

  get f() { return this.form.controls; }

}
