import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignatureComponent } from './components/signature/signature.component';
import { RouterModule } from '@angular/router';
import { SignaturePadModule } from 'angular2-signaturepad';
import { LightboxComponent } from './components/lightbox/lightbox.component';
import { ImagesEditorComponent } from './components/images-editor/images-editor.component';
import { ToastUiImageEditorModule } from 'ngx-tui-image-editor';
import { AlertModule } from 'ngx-bootstrap';
import { AlertComponent } from './alert/alert.component';



@NgModule({
  declarations: [
    SignatureComponent,
    LightboxComponent,
    ImagesEditorComponent,
    AlertComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SignaturePadModule,
    ToastUiImageEditorModule,
    AlertModule,
  ],
  exports: [
    SignatureComponent,
    LightboxComponent,
    ImagesEditorComponent
  ],
})
export class SharedComponentsModule { }
