import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilOptions } from 'src/app/shared-components/util/util-option';

@Component({
  selector: 'app-intervenant-edit',
  templateUrl: './intervenant-edit.component.html',
  styleUrls: ['./intervenant-edit.component.scss']
})
export class IntervenantEditComponent implements OnInit {
  form: FormGroup;
  required = UtilOptions.required;
  submit = false;
  inputGroup: boolean = false;

  typeIntervenant = [
    { label: "Acquéreur", value: "acquéreur" },
    { label: "Maître d'ouvrage", value: "maître d'ouvrage" },
    { label: "Maître d'oeuvre", value: "maître d'oeuvre" },
    { label: "Entreprise", value: "entreprise" }
  ];
  
  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.newForm();
  }

  ngOnInit() {
  }
  
  newForm() {
    this.form = this.formBuilder.group({
      id: [null],
      type: [null, [Validators.required]],
      nom: [null, [Validators.required]],
      fonction: [null, [Validators.required]],
      tel: [null, [Validators.required]],
      mobile: [null, [Validators.required]],
      email: [null, [Validators.required]],
      pays: [null, [Validators.required]],
      ville: [null, [Validators.required]],
      commune: [null, [Validators.required]],
      quartier: [null, [Validators.required]],
      rgistreCommerce: [null, [Validators.required]],
      numContribuable: [null, [Validators.required]],
      regime: [null, [Validators.required]],
      photo: [null, [Validators.required]],
    })
  }

  onSubmit(){}
  get f() { return this.form.controls; }
}
