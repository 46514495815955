<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-user display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>Tableau de clients</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">
                    Statistique générales
                </div>
            </div>
        </div>
    </div>
</div>

<tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow" type="tabs" >

    <!-- Tableau de bord -->
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span> Tableau de bord</span></ng-template>
        <div class="mt-2">
            <div class="row">
                <div class="col-md-3">
                    <div class="card p-3 mb-4">
                        <div class="text-black-50 pb-2">Total factures</div>
                        <div class="d-flex justify-content-between align-items-end">
                            <h3 class="display-4 mb-0 text-black">
                                <span class="text-success">4986</span>
                            </h3>
                            <div class="text-success font-weight-bold">657 Payées</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card p-3 mb-4">
                        <div class="text-black-50 pb-2">Total projets</div>
                        <div class="d-flex justify-content-between align-items-end">
                            <h3 class="display-4 mb-0 text-black">
                                <span class="text-warning">4986</span>
                            </h3>
                            <div class="text-first font-weight-bold">56% Terminés</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card p-3 mb-4 bg-night-sky">
                        <div class="text-white-50 pb-2">Total rapport</div>
                        <div class="d-flex justify-content-between align-items-end">
                            <h3 class="display-4 mb-0">
                                <span class="text-white">675</span>
                            </h3>
                            <div class="text-warning font-weight-bold">Tout rapport confondue</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card p-3 mb-5 bg-amy-crisp">
                        <div class="text-white-50 pb-2">Visites de chantier</div>
                        <div class="d-flex justify-content-between align-items-end">
                            <h3 class="display-4 mb-0">
                                <span class="text-white">76</span>
                            </h3>
                            <div class="text-second font-weight-bold">Total des visites</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-box border-0 mb-4">
            <div class="card-header p-3">
                <div class="card-header--title">
                    <h6 class="font-weight-bold m-0">Statistiques de l'etat des factures de l'année en cours</h6>
                </div>
            </div>
            <div class="card-body p-0">
                <apx-chart
                    [chart]="{
                        type: 'bar',
                        height: '365',
                        sparkline: {enabled: false},
                        toolbar: { show: false}
                    }"
                    [colors]="['#1cc943', '#f4772e', '#ff0000']"
                    [dataLabels]="{ enabled: false }"
                    [fill]="{
                        opacity: 0.85,
                        colors: ['#1cc943', '#f4772e', '#ff0000']
                    }"
                    [grid]="{
                        strokeDashArray: '5',
                        borderColor: 'rgba(125, 138, 156, 0.3)'
                    }"
                    [labels]="['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aôut', 'Septembre', 'Octobre', 'Novembre', 'Decembre']"
                    [legend]="{ show: true }"
                    [series]="[
                        {
                            name: 'Payé',
                            data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                        },
                        {
                            name: 'En attente',
                            data: [1, 2, 3, 4, 5, 6, 7, 1, 2, 10, 0, 1.3]
                        },
                        {
                            name: 'Impayé',
                            data: [2.1, 0, 1, 0, 6, 2.7, 1.4, 7, 0, 1, 0, 5]
                        }
                    ]"
                    [stroke]="{
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    }"
                ></apx-chart>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="card py-4 px-3 mb-4" style="height: 355px;">
                    <h6 class="text-center font-weight-bold mb-0">Évolution des Projets</h6>
                    <div class="my-4">
                        <apx-chart
                            [chart]="{type: 'donut', height: '208'}"
                            [colors]="['#f4772e', '#4191ff', '#1bc943']"
                            [labels]="['En attente', 'En cours', 'Terminés']"
                            [series]="[33, 44, 55]"
                            [legend]="{
                                show: true,
                                position: 'bottom',
                                horizontalAlign: 'center',
                                floating: false,
                                fontFamily: 'Heebo, sans-serif',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                labels: {
                                    colors: 'rgba(59, 62, 102, 0.72)',
                                    useSeriesColors: false
                                },
                                offsetX: -20,
                                offsetY: -10,
                                markers: {
                                    width: 16,
                                    height: 8,
                                    strokeWidth: 0,
                                    strokeColor: '#fff',
                                    radius: 4,
                                    offsetX: 0,
                                    offsetY: 0
                                },
                                itemMargin: {
                                    horizontal: 2,
                                    vertical: 3
                                }
                            }"
                        ></apx-chart>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card bg-arielle-smile py-4 px-3 mb-4" style="height: 355px;">
                    <h6 class="text-center font-weight-bold mb-0">Évolution des Chantier</h6>
                    <div class="my-4">
                        <div class="gauge-wrapper">
                            <circle-progress
                                [percent]="74"
                                [radius]="100"
                                [outerStrokeWidth]="15"
                                [innerStrokeWidth]="11"
                                [outerStrokeColor]="'var(--success)'"
                                [innerStrokeColor]="'var(--secondary)'"
                                [animation]="true"
                                [responsive]="true"
                                [showSubtitle]="false"
                                [titleFontSize]="42"
                                [unitsFontSize]="29"
                                [showBackground]="false"
                                [showSubtitle]="false"
                                [animationDuration]="600"
                            ></circle-progress>
                        </div>
                    </div>
                    <div class="row mt-3 text-center">
                        <div class="col">
                            <h3 class="m-0 font-weight-bold text-white">10</h3>
                            <span>En Cours</span>
                        </div>
                        <div class="col">
                            <h3 class="m-0 font-weight-bold text-white">40</h3>
                            <span>Terminés</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card card-box border-0 mb-4" style="height: 355px;">
                    <div class="card-header bg-white">
                        <div class="card-header--title"> <b>Statistiques des rapport</b></div>
                    </div>
                    <perfect-scrollbar [autoPropagation]="true" class="scroll-area shadow-overflow">
                        <div class="p-2">
                            <a [routerLink]="" class="card btn rounded text-left mb-3 p-4">
                                <div class="d-flex align-items-center">
                                    <div class="d-70 rounded-circle bg-success text-white mr-3 text-center">
                                        <i class="fas fa-file-alt font-size-lg"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="text-black-50 pb-1">Rapport validée</div>
                                        <div class="text-success">
                                            <fa-icon [icon]="['far', 'check-circle']"></fa-icon> 
                                            67
                                        </div>
                                    </div>
                                    <div class="font-size-sm opacity-5">
                                        <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                                    </div>
                                </div>
                            </a>

                            <a [routerLink]="" class="card btn rounded text-left mb-3 p-4">
                                <div class="d-flex align-items-center">
                                    <div class="d-70 rounded-circle bg-warning text-white mr-3 text-center">
                                        <i class="fas fa-file-alt font-size-lg"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="text-black-50 pb-1">Rapport en attente de validation</div>
                                        <div class="text-warning">
                                            <i class="fas fa-pause"></i>
                                            67
                                        </div>
                                    </div>
                                    <div class="font-size-sm opacity-5">
                                        <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                                    </div>
                                </div>
                            </a>

                            <a [routerLink]="" class="card btn rounded text-left mb-3 p-4">
                                <div class="d-flex align-items-center">
                                    <div class="d-70 rounded-circle bg-danger text-white mr-3 text-center">
                                        <i class="fas fa-file-alt font-size-lg"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="text-black-50 pb-1">Rapport non validées</div>
                                        <div class="text-danger">
                                            <i class="fas fa-times"></i>
                                            67
                                        </div>
                                    </div>
                                    <div class="font-size-sm opacity-5">
                                        <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
        </div>
        <div class="card card-box border-0">
            <div class="card-header p-3">
                <div class="card-header--title font-weight-bold">Liste des projets non debutés</div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive m-0">
                    <table class="table table-hover text-nowrap m-0">
                        <thead>
                            <th>Libellé</th>
                            <th>Date début</th>
                            <th>Date fin</th>
                            <th>Chef du projet</th>
                            <th>Evolution</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td><span class="fant-weight-bold">Projet 1</span></td>
                                <td><span class="fant-weight-bold text-dark">10/10/2021</span></td>
                                <td><span class="fant-weight-bold text-dark">10/10/2021</span></td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-icon-wrapper">
                                            <div class="avatar-icon"><img alt="..." src="assets/img/av2.png"/></div>
                                        </div>
                                        <div>
                                            <a [routerLink]="" class="font-weight-bold text-black" >Kouassi ghislain</a>
                                            <span class="text-black-50 d-block">Ingenieur batiment</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <ngx-gauge
                                        backgroundColor="rgba(248, 50, 69, 0.15)"
                                        cap="round" class="m-0 img-fluid"
                                        foregroundColor="#f83245"
                                        max="100" min="0" size="55"
                                        thick="4"
                                        type="full" value="0">
                                    </ngx-gauge>
                                </td>
                            </tr>
                            <tr>
                                <td><span class="fant-weight-bold">Projet 1</span></td>
                                <td><span class="fant-weight-bold text-dark">10/10/2021</span></td>
                                <td><span class="fant-weight-bold text-dark">10/10/2021</span></td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-icon-wrapper">
                                            <div class="avatar-icon"><img alt="..." src="assets/img/av2.png"/></div>
                                        </div>
                                        <div>
                                            <a [routerLink]="" class="font-weight-bold text-black" >Kouassi ghislain</a>
                                            <span class="text-black-50 d-block">Ingenieur batiment</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <ngx-gauge
                                        backgroundColor="rgba(248, 50, 69, 0.15)"
                                        cap="round" class="m-0 img-fluid"
                                        foregroundColor="#f83245"
                                        max="100" min="0" size="55"
                                        thick="4"
                                        type="full" value="0">
                                    </ngx-gauge>
                                </td>
                            </tr>
                            <tr>
                                <td><span class="fant-weight-bold">Projet 1</span></td>
                                <td><span class="fant-weight-bold text-dark">10/10/2021</span></td>
                                <td><span class="fant-weight-bold text-dark">10/10/2021</span></td>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-icon-wrapper">
                                            <div class="avatar-icon"><img alt="..." src="assets/img/av2.png"/></div>
                                        </div>
                                        <div>
                                            <a [routerLink]="" class="font-weight-bold text-black" >Kouassi ghislain</a>
                                            <span class="text-black-50 d-block">Ingenieur batiment</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <ngx-gauge
                                        backgroundColor="rgba(248, 50, 69, 0.15)"
                                        cap="round" class="m-0 img-fluid"
                                        foregroundColor="#f83245"
                                        max="100" min="0" size="55"
                                        thick="4"
                                        type="full" value="0">
                                    </ngx-gauge>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer p-3 d-flex justify-content-between">
              <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
              <div class="d-flex align-items-center">
                <span>Show</span>
                <select class="mx-1 form-control form-control-sm" id="" name="">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                </select>
                <span>entries</span>
              </div>
            </div>
        </div>
    </tab>

    <!-- Projets -->
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span> Projets</span></ng-template>
        <div class="mt-2">
            <div class="card card-box mb-4">
                <div class="px-4 py-3 d-block d-md-flex justify-content-between align-items-center text-center text-md-left">
                    <div class="d-flex flex-md-row flex-column align-items-center">
                        <div class="font-size-lg font-weight-bold">Projets</div>
                        <div class="mx-3 d-none d-md-block">
                            <div class="divider-v position-relative"></div>
                            <div class="divider-v position-relative"></div>
                        </div>
                        <span class="text-black-50 font-size-md">56 au total</span>
                        <div [ngClass]="status1 ? 'is-active' : ''" class="search-wrapper search-wrapper--grow my-3 my-md-0 ml-0 ml-md-4">
                          <span class="icon-wrapper text-black">
                            <fa-icon [icon]="['fas', 'search']"></fa-icon>
                          </span>
                            <input (blur)="status1 = false; null" (focus)="status1 = true" class="form-control" placeholder="Recherche de projet..." type="search">
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="card card-box border-0">
                <div class="card-header p-3">
                    <div class="card-header--title"><h6 class="m-0">Liste des projet</h6></div>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive m-0">
                        <table class="table table-hover text-nowrap m-0">
                            <thead>
                                <th>Libellé</th>
                                <th>Date début</th>
                                <th>Date fin</th>
                                <th>Chef du projet</th>
                                <th>Evolution</th>
                                <th class="text-center">Action</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of projets">
                                    <td><span class="fant-weight-bold">{{item.label}}</span></td>
                                    <td><span class="fant-weight-bold text-dark">10/10/2021</span></td>
                                    <td><span class="fant-weight-bold text-dark">10/10/2021</span></td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div class="avatar-icon-wrapper">
                                                <div class="avatar-icon"><img alt="..." src="assets/img/av2.png"/></div>
                                            </div>
                                            <div>
                                                <a [routerLink]="" class="font-weight-bold text-black" >Kouassi ghislain</a>
                                                <span class="text-black-50 d-block">Ingenieur batiment</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <ngx-gauge
                                            backgroundColor="{{
                                                item.value == 0 ? 'rgba(104, 101, 101, 0.15)' : 
                                                item.value >= 0 && item.value <= 30 ? 'rgba(248, 50, 69, 0.15)' : 
                                                item.value >= 31 && item.value <= 90 ? '#edeef1' : '#edeef1'
                                            }}"
                                            foregroundColor="{{
                                                item.value == 0 ? '#222222' : 
                                                item.value >= 0 && item.value <= 30 ? '#f83245' : 
                                                item.value >= 31 && item.value <= 90 ? '#4191ff' : '#1bc943'
                                            }}"
                                            cap="round" class="m-0 img-fluid"
                                            max="100" min="0" size="55"
                                            thick="4"
                                            type="full" value="item.value">
                                        </ngx-gauge>
                                    </td>
                                    <td class="text-center">
                                        <button class="btn d-50 mr-2 p-0 btn-pill btn-outline-primary" tooltip="Plus d'info" type="button" (click)="viewProjetModal.show()">
                                            <span class="btn-wrapper--icon">
                                                <i class="ion-md-list-box font-size-lg"></i>
                                            </span>
                                        </button>
                                        <button class="btn d-50 mr-2 p-0 btn-pill btn-outline-primary" tooltip="Voir les tâches" type="button">
                                            <span class="btn-wrapper--icon">
                                                <fa-icon [icon]="['fas', 'bars']" class="font-size-lg"></fa-icon>
                                            </span>
                                        </button>
                                        <button class="btn d-50 p-0 btn-pill btn-outline-primary" tooltip="Voir les rapport" type="button">
                                            <span class="btn-wrapper--icon">
                                                <fa-icon [icon]="['far', 'chart-bar']" class="font-size-lg"></fa-icon>
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer p-3 d-flex justify-content-between">
                  <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                  <div class="d-flex align-items-center">
                    <span>Show</span>
                    <select class="mx-1 form-control form-control-sm" id="" name="">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                    </select>
                    <span>entries</span>
                  </div>
                </div>
            </div>
        </div>
    </tab>
</tabset>

<!-- Modal d'ajout de projet -->
<div #viewProjetModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered rounded">
        <div class="modal-content rounded border-0">
            <div class="modal-header modal-dark rounded-top p-3 bg-plum-plate">
                <h6 class="modal-title">Formulaire de création d'un projet</h6>
                <button type="button" class="close" aria-label="Close" (click)="viewProjetModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <div class="border rounded">
                        <div class="p-3">
                            <div title="Evolution">
                                <progressbar [value]="85" class="progress" type="first"></progressbar>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="m-0"><b>Numéro:</b> <span class="ml-2">0002</span></p>
                                    <hr class="my-2">
                                    <p class="m-0"><b>Libellé</b> <span class="ml-2">Projet de construction</span></p>
                                    <hr class="my-2">
                                    <p class="m-0"><b>Date début:</b> <span class="ml-2">20/10/2021</span></p>
                                    <hr class="my-2">
                                    <p class="m-0"><b>Date fin:</b> <span class="ml-2">20/10/2022</span></p>
                                    <hr class="my-2">
                                    <p class="m-0"><b>Responsable:</b> <span class="ml-2">Kouassi ghislain</span></p>
                                    <hr class="my-2">
                                    <p class="m-0"><b>Membres:</b> <span class="ml-2">20</span></p>
                                </div>
                                <div class="col-md-4">
                                    <p class="m-0"><b>Tâche:</b> <span class="ml-2">48</span></p>
                                    <hr class="my-2">
                                    <p class="m-0"><b>Budget:</b> <span class="ml-2 badge badge-first">1000000000</span></p>
                                    <hr class="my-2">
                                    <p class="m-0"><b>Créer par:</b> <span class="ml-2">Kouassi</span></p>
                                    <hr class="my-2">
                                    <p class="m-0"><b>Date de création:</b> <span class="ml-2">12/12/2021</span></p>
                                    <hr class="my-2">
                                    <p class="m-0"><b>Modifier par:</b> <span class="ml-2">Kouassi</span></p>
                                    <hr class="my-2">
                                    <p class="m-0"><b>Date de modification:</b> <span class="ml-2">12/12/2021</span></p>
                                </div>
                                <div class="col-md-4">
                                    <a [routerLink]="" class="card-img-wrapper rounded mt-1 mr-1">
                                        <div class="img-wrapper-overlay">
                                            <div class="overlay-btn-wrapper">
                                                <button class="btn btn-first p-0 d-50 rounded-circle" type="button" tooltip="Agrandir">
                                                    <span class="btn-wrapper--icon">
                                                        <i class="fas fa-expand"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <img alt="..." class="card-img-top rounded" src="assets/images/stock-photos/stock-1.jpg"/>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="mt-1">
                            <tabset type="tabs">
                                <tab>
                                    <ng-template tabHeading>Chantier</ng-template>
                                    <p class="mb-0 p-3">I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine.</p>
                                </tab>
                                <tab>
                                    <ng-template tabHeading>Facture</ng-template>
                                    <div class="p-3">
                                        <div class="card card-box border-0">
                                            <div class="card-header p-3">
                                                <div class="card-header--title">
                                                    <h6 class="m-0 font-weight-bold">Liste des factures</h6>
                                                </div>
                                            </div>
                                            <div class="card-body p-0">
                                                <div class="list-group list-group-flush">
                                                    <li class="list-group-item d-flex">
                                                        <div class="search-wrapper">
                                                          <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                                                          <input class="form-control" placeholder="Recherche dans la liste" type="search">
                                                        </div>
                                                        <div class="ml-auto text-right">
                                                            <button class="btn btn-success"><i class="fas fa-plus-circle mr-1"></i> Ajouter</button>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item list-group-item-action align-box-row"  *ngFor="let item of projets">
                                                        <i class="fas fa-file-alt font-size-lg mr-3"></i>
                                                        <div>Visites chantier</div>
                                                        <div class="ml-auto text-right">
                                                            <button class="btn d-50 p-0 btn-pill btn-outline-primary" tooltip="Détail" type="button">
                                                                <span class="btn-wrapper--icon">
                                                                    <i class="ion-md-eye"></i>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </li>
                                                </div>
                                            </div>
                                            <div class="card-footer p-3 d-flex justify-content-between">
                                              <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                                              <div class="d-flex align-items-center">
                                                <span>Show</span>
                                                <select class="mx-1 form-control form-control-sm" id="" name="">
                                                  <option value="10">10</option>
                                                  <option value="20">20</option>
                                                  <option value="30">30</option>
                                                  <option value="40">40</option>
                                                </select>
                                                <span>entries</span>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </tab>
                                <tab>
                                    <ng-template tabHeading>Rapport</ng-template>
                                    <p class="mb-0 p-3">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.</p>
                                </tab>
                                <tab>
                                    <ng-template tabHeading>Visites chantier</ng-template>
                                    <div class="card card-box">
                                        <div class="card-header p-3">
                                            <div class="card-header--title">
                                                <h6 class="m-0 font-weight-bold">Visites chantier chantier</h6>
                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="list-group list-group-flush">
                                                <li class="list-group-item list-group-item-action align-box-row"  *ngFor="let item of projets">
                                                    <i class="fas fa-file-alt font-size-lg mr-3"></i>
                                                    <div>Visites chantier</div>
                                                    <div class="ml-auto text-right">
                                                        <button class="btn d-50 mr-2 p-0 btn-pill btn-outline-primary" tooltip="Détail" type="button">
                                                            <span class="btn-wrapper--icon">
                                                                <i class="ion-md-eye"></i>
                                                            </span>
                                                        </button>
                                                        <button class="btn d-50 p-0 btn-pill btn-outline-warning" tooltip="Voir les remarques" type="button">
                                                            <span class="btn-wrapper--icon">
                                                                <i class="fas fa-file-signature"></i>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div class="card-footer p-3 d-flex justify-content-between">
                                          <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                                          <div class="d-flex align-items-center">
                                            <span>Show</span>
                                            <select class="mx-1 form-control form-control-sm" id="" name="">
                                              <option value="10">10</option>
                                              <option value="20">20</option>
                                              <option value="30">30</option>
                                              <option value="40">40</option>
                                            </select>
                                            <span>entries</span>
                                          </div>
                                        </div>
                                    </div>
                                </tab>
                            </tabset>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer py-2 px-3">
                <button class="btn btn-secondary" (click)="viewProjetModal.hide()">
                    <i class="fas fa-times"></i> Quitter
                </button>
                <button class="btn btn-warning ml-2">
                    <i class="ion-md-print"></i> Imprimer
                </button>
            </div>
        </div>
    </div>
</div>
