import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { stringify } from 'querystring';
import { BehaviorSubject,throwError } from 'rxjs';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

interface natureRessourceData {
  id?: string,
  libelle: string,
}

@Injectable({
  providedIn: 'root'
})
export class NatureRessourceService {

    apiUrl = environment.apiUrl;
    prefix = environment.prefix;
  
    constructor(private http:HttpClient) { }
  
    listNatureRessource(){
      return this.http.get<natureRessourceData[]>(
        `${this.apiUrl}/natures`,{
          responseType: 'json'
        }
      ).pipe(
        map((response: any) => response['hydra:member']),
        catchError(this.handleError)
      )
    }
  
    addNatureRessource(data:natureRessourceData){
      let responseData:natureRessourceData;
          return this.http.post(`${this.apiUrl}/natures`,JSON.stringify(data)).pipe(
          tap( resData => {      
            return resData
          }),
          catchError(this.handleError)
        );//pipe   
    }
  

  
    handleError(errorMessage: HttpErrorResponse) {
      let messageError = "Une erreur est survenue";
      return throwError(messageError);
    }
}
