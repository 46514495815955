import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { stringify } from 'querystring';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, switchMap, take, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

interface uniteRessourceData {
  id?: string,
  libelle: string,
}


@Injectable({
  providedIn: 'root'
})
export class UniteRessourceService {

  // private _bureauEtudes = new BehaviorSubject<CategorieRessource[]>([]);
  apiUrl = environment.apiUrl;
  prefix = environment.prefix;

  constructor(private http: HttpClient) { }

  // get bureauEtudes(){
  //   return this._bureauEtudes.asObservable();
  // }

  listUniteRessource() {
    return this.http.get<uniteRessourceData[]>(
      `${this.apiUrl}/unites`, {
      responseType: 'json'
    }
    ).pipe(
      map((response: any) => response['hydra:member']),
      catchError(this.handleError)
    )
  }

  addUniteRessource(data: uniteRessourceData) {
    let responseData: uniteRessourceData;
    return this.http.post(`${this.apiUrl}/unites`, JSON.stringify(data)).pipe(
      tap(resData => {
        return resData
      }),
      catchError(this.handleError)
    );//pipe   
  }

  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "Une erreur est survenue";
    return throwError(messageError);
  }
}
