import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.scss']
})
export class ClientDashboardComponent implements OnInit {

  status1 = false;
  rotate = true;
  view = 'list';
  projets = [
    {label: 'Projet 1', value: 0 },
    {label: 'Projet 2', value: 25 },
    {label: 'Projet 3', value: 58 },
    {label: 'Projet 4', value: 98 },
    {label: 'Projet 5', value: 100 },
  ]

  constructor(public globals: ThemeOptions) {
    console.log(this.projets)
  }

  ngOnInit() {
  }
  changeView(typeView: string){
    this.view = typeView;
  }

}
