import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UtilOptions } from 'src/app/shared-components/util/util-option';
import { MaitreService } from '../../maitre/maitre-service';
import { Maitre } from '../../maitre/maitre.model';
import { PersonneService } from '../../personne/personne-service';
import { Personne } from '../../personne/personne.model';
import { Responsable } from '../../responsable/responsable';
import { ResponsableService } from '../../responsable/responsable-service';
import { Opportunite } from '../opportunite.model';
import { OpportuniteService } from '../opportunite.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDirective } from 'ngx-bootstrap';
import { UtilisateurService } from '../../utilisateur-pages/utilisateur.service';
import { Utilisateur } from '../../utilisateur-pages/utilisateur.model';


@Component({
  selector: 'app-opportunite-edit',
  templateUrl: './opportunite-edit.component.html',
  styleUrls: ['./opportunite-edit.component.scss']
})
export class OpportuniteEditComponent implements OnInit {
  @ViewChild('newMaitreOuvrage', {static: false})  public newMaitreOuvrage: ModalDirective;
  @ViewChild('newPersonneNotifier', {static: false})  public newPersonneNotifier: ModalDirective;
  @ViewChild('newResponsableSuivie', {static: false})  public newResponsableSuivie: ModalDirective;

  title: string = 'Ajouter une opportunité';
  form: FormGroup;
  selected: string;
  maitre: FormGroup;
  personne: FormGroup;
  opportunites: any;
  responsable: FormGroup;
  opportunite: Opportunite;
  submit: boolean = false;
  editMode: boolean = false;
  inputGroupe: boolean = true;
  typeClient = UtilOptions.type;
  required = UtilOptions.required;
  states: string[] = UtilOptions.pays;
  public mask: Array<string | RegExp>;
  public maskDate: Array<string | RegExp>;
  public maskPhone: Array<string | RegExp>;

  //Maitre d'ouvrage
  maitreO = new Subscription;
  maitreOu = new Subscription;
  listeMaitre: Maitre[] = [];

  //Utilisateur
  user = new Subscription;
  users = new Subscription;
  listeUser: Utilisateur[] = [];

  //Personne à notifiées
  // personneN = new Subscription;
  // personneNo = new Subscription;
  // listePersonne:Personne[] = [];

  // Respponsable de suivie
  responsableS = new Subscription;
  responsableSu = new Subscription;
  listeResponsable: Responsable[] = [];
  ownerType = [
    { label: "PARTICULIER", value: "PARTICULIER" },
    { label: "ENTREPRISE", value: "ENTREPRISE" }
  ]

  constructor(
    private router: Router,
    public modal: NgbActiveModal,
    private route: ActivatedRoute,
    private formBuild: FormBuilder,
    private notifier: NotifierService,
    private spinner: NgxSpinnerService,
    private maitreService: MaitreService,
    private personneService: PersonneService,
    private utilisateurService: UtilisateurService,
    private responsableService: ResponsableService,
    private opportuniteService: OpportuniteService,
  )
  {
    this.getData();
    this.notifier = notifier;
    this.maskDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    this.maskPhone = ['(', '+', '2', '2', '5', ')', ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
  }

  ngOnInit() {
    this.newMaitreForm()
    this.newResponsableForm();
    this.loadUser()
    this.loadMaitreO()
    this.loadResponsable();
  }

  newMaitreForm(){
    this.maitre = this.formBuild.group({
      id: [null],
      utilisateur: [null],
    })
  }
  newResponsableForm() {
    this.responsable = this.formBuild.group({
      id: [null],
      utilisateur: [null],
    });
  }

  initForm(){
    this.form = new FormGroup({
      "libelle" : new FormControl("", [Validators.required]),
      "montant": new FormControl("", [Validators.required]),
      "numeroOrdre": new FormControl(""),
      "numeroDossier": new FormControl("", [Validators.required]),
      "groupement": new FormControl(""),
      "bailleur": new FormControl(""),
      "responsableSuivi": new FormControl(""),
      "adresseAutorite": new FormControl("",[Validators.required]),
      "maitreOuvrage": new FormControl(""),
      "numeroOffre": new FormControl("", [Validators.required]),
      "dateLancement": new FormControl("", [Validators.required]),
      "delaiExecution": new FormControl("", [Validators.required]),
      "dateOuverture": new FormControl(""),
      "dateLimite": new FormControl(""),
    });
  }

  editForm(data) {
    this.initForm();
    this.title = "Modifier l'opportunité " + data.libelle
    this.form.patchValue({
      libelle: data.libelle,
      montant: data.montant,
      numeroOrdre: data.numeroOrdre,
      numeroDossier: data.numeroDossier,
      groupement: data.groupement,
      bailleur: data.bailleur,
      responsableSuivi: data.responsableSuivi['@id'] ? data.responsableSuivi['@id'] : '',
      adresseAutorite: data.adresseAutorite,
      maitreOuvrage: data.maitreOuvrage['@id'] ? data.maitreOuvrage['@id'] : '',
      numeroOffre: data.numeroOffre,
      dateLancement: new Date(data.dateLancement),
      delaiExecution: new Date(data.delaiExecution),
      dateOuverture: new Date(data.dateOuverture),
      dateLimite: new Date(data.dateLimite)
    });
  }

  loadUser(){  
    this.users = this.utilisateurService.listerUtilisateurs().subscribe(
      (resData:Utilisateur) => {
        this.listeUser.push(resData)
      },
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  loadMaitreO(){  
    this.maitreOu = this.maitreService.getList().subscribe(
      (res) => { this.listeMaitre = res},
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }
  
  loadResponsable(){
    this.responsableSu = this.responsableService.getListe().subscribe(
      (resData:Responsable) => {
        this.listeResponsable.push(resData)
      },
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  addMaitres(){
    const formData = {
      "utilisateur":this.maitre.value.utilisateur
    }
    this.maitreO = this.maitreService.create(formData).subscribe(
      ( resData ) => {
        this.closeMaitreOuvrageModal();
        this.listeMaitre = [];
        this.maitre.reset();
        this.loadMaitreO();
      },
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }
  addResponsable(){
    const formData = {
      "utilisateur":this.responsable.value.utilisateur
    }
   this.responsableS = this.responsableService.create(formData).subscribe(
    (resData) => {
      this.closeResponsableSuivieModal();
      this.listeResponsable = [];
      this.responsable.reset();
      this.loadResponsable();
      },
      errorRes => {
        this.notifications('danger', "L'opération a échoué"); 
      }
    );
  }

  onSubmit(){
    this.submit = true
    this.spinner.show("sp6");
    if (this.editMode) {
      this.opportuniteService.update(
        this.opportunites.opportunite.id,
        {
          libelle: this.form.value.libelle,
          montant: this.form.value.montant,
          numeroOrdre: this.form.value.numeroOrdre,
          numeroDossier: this.form.value.numeroDossier,
          groupement: this.form.value.groupement,
          bailleur: this.form.value.bailleur,
          adresseAutorite: this.form.value.adresseAutorite,
          maitreOuvrage: this.form.get('maitreOuvrage').value,
          responsableSuivi: this.form.get('responsableSuivi').value,
          numeroOffre: this.form.value.numeroOffre,
          dateLancement: this.form.value.dateLancement,
          delaiExecution: this.form.value.delaiExecution,
          dateOuverture: new Date(this.form.value.dateOuverture),
          // @ts-ignore
          dateLimite: new Date(this.form.value.dateLimite)
        }
      ).subscribe(
        resData => {
          if(resData) {
            this.spinner.hide("sp6");
            this.notifications( 'success', 'Enregistrement effectué avec success.');
            this.modal.close('update');
          }         
        },
        errorRes => {
          this.spinner.hide("sp6");
          this.notifications( 'danger', 'Enregistrement echoué!.');
        }
      );
    }
    else{
      this.opportuniteService.create(
        {
          libelle: this.form.value.libelle,
          montant: this.form.value.montant,
          numeroOrdre: this.form.value.numeroOrdre,
          numeroDossier: this.form.value.numeroDossier,
          groupement: this.form.value.groupement,
          bailleur: this.form.value.bailleur,
          adresseAutorite: this.form.value.adresseAutorite,
          maitreOuvrage: this.form.get('maitreOuvrage').value,
          responsableSuivi: this.form.get('responsableSuivi').value,
          numeroOffre: this.form.value.numeroOffre,
          dateLancement: this.form.value.dateLancement,
          delaiExecution: this.form.value.delaiExecution,
          dateOuverture: new Date(this.form.value.dateOuverture),
          // @ts-ignore
          dateLimite: new Date(this.form.value.dateLimite)
        }
      ).subscribe(
        resData => {
          if(resData) {
            this.spinner.hide("sp6");
            this.notifications( 'success', 'Enregistrement effectué avec success.');
            this.modal.close('success');
          }         
        },
        errorRes => {
          this.spinner.hide("sp6");
          this.notifications( 'danger', 'Enregistrement echoué!.');
        }
      );
    }
  }
  getData() {
    this.opportunites = this.opportuniteService.getOpportunite();
    if(this.opportunites) {
      this.editMode = this.opportunites.type;
    } else {
      this.editMode = false;
    }
    if(this.editMode == true) {
      this.editForm(this.opportunites.opportunite);
    } else {
      this.initForm();
    }
  }

  ngOnDestroy(): void {
    this.maitreOu.unsubscribe();
    // this.personneNo.unsubscribe();
    this.responsableSu.unsubscribe();
  }

  onClose(): any{
    this.initForm();
    this.form.reset()
    this.modal.close();
    this.editMode = false;
    this.opportunites = "";
    this.opportuniteService.setOpportunite("")
  }

  closeMaitreOuvrageModal(){
    this.newMaitreOuvrage.hide();
  }

  closePersonneNotifierModal(){
    this.newPersonneNotifier.hide();
  }

  closeResponsableSuivieModal(){
    this.newResponsableSuivie.hide();
  }

  notifications( type: string, message: string ): void {
    if (type == 'primary') {
      this.notifier.notify( type, message );
    } else if (type == 'success') {
      this.notifier.notify( type, message );
    } else if (type == 'warning') {
      this.notifier.notify( type, message );
    } else if (type == 'danger') {
      this.notifier.notify( type, message );
    } else if (type == 'dark') {
      this.notifier.notify( type, message );
    } else if (type == 'second') {
      this.notifier.notify( type, message );
    } else if (type == 'first') {
      this.notifier.notify( type, message );
    }
  }

  get f() { return this.form.controls; }
  get of() { return this.maitre.controls; }
}