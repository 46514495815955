import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-remarque-edit',
  templateUrl: './remarque-edit.component.html',
  styleUrls: ['./remarque-edit.component.scss']
})
export class RemarqueEditComponent implements OnInit {
  form: FormGroup;
  submit = false;
  imgUrl: any;
  statusRow = [
    { label: "Programmé", value: "programmé" },
    { label: "En cours", value: "en cours" },
    { label: "En attente", value: "en attente" },
    { label: "En retard", value: "en retard" },
    { label: "Urgente", value: "urgente" },
    { label: "Refusé", value: "refusé" },
    { label: "Date limite", value: "date limite" },
    { label: "Garder pour mémoire", value: "garder pour mémoire" },
    { label: "Cloturé", value: "Cloturé" }
  ];
  
  // Tout les variables ou tableau ayants pour prefix "default" servent a faire des duplication.
  defaultPlan = [
    { line1: "plan1" },
    { line2: "plan2" },
    { line3: "plan3" },
    { line4: "plan4" },
  ]
  
  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.newForm();
    this.imgUrl = [];
  }

  ngOnInit() {}

  onSubmit(){}

  newForm(){
    this.form = this.formBuilder.group({
      libelle: [null, [Validators.required]],
      status: [null, [Validators.required]],
      resolutionDate: [null, [Validators.required]],
      description: [null, [Validators.required]],

      image: [null, [Validators.required]],
      commentaire: [null, [Validators.required]],
      plan: [null, [Validators.required]],
    })
  }
  
  // "onSelectImg" pour la selection des image dans le formulaire et "deleteImg" pour la suppression
  onSelectImg(e){
    if(e.target.files){
      var reader = new FileReader();
      var file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload=(events:any)=>{
        this.imgUrl.push(events.target.result);
      }
    }
  }
  deleteImg(el) {
    this.imgUrl.forEach((value,index) => {
      if(value == el)
        this.imgUrl.splice(index, 1);
    });
  }

  // Editor
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Entrer le texte du commentaire...',
    translate: 'no',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  get f() { return this.form.controls; }
}
