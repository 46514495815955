<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="p-4">
        <!-- Informations générales s1 -->
        <div class="card card-box shadow-none">
            <div class="card-header p-3 bg-light">
                <div class="card-header--title">Informations générales</div>
            </div>
            <div class="card-body p-3">
                <div class="form-row">
                    <div class="col-md-12">
                        <div class="position-relative form-group">
                            <label for="typeHse">Incident ou Accident: <span class="text-danger ml-2">*</span></label>
                            <select id="typeHse" formControlName="typeHse" class="form-control" [ngClass]="{ 'is-invalid': submit && f.typeHse.errors, 'is-valid': submit && f.typeHse.valid }">
                                <option *ngFor="let hse of typeHse" [value]="hse.value">{{hse.label}}</option>
                            </select>
                            <div class="invalid-feedback" *ngIf="submit && f.typeHse.errors">
                                <div *ngIf="f.typeHse.errors.required">{{required.novide}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <label for="projet">Projet: </label>
                            <ng-select id="projet" formControlName="projet">
                                <ng-option value="projet 1">Projet 1</ng-option>
                                <ng-option value="projet 2">Projet 2</ng-option>
                                <ng-option value="projet 3">Projet 3</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="position-relative form-group">
                            <label for="chantier">Cantiers concernés: </label>
                            <ng-select id="chantier" formControlName="chantier" [multiple]="true">
                                <ng-option value="chantier 1">Chantier 1</ng-option>
                                <ng-option value="chantier 2">Chantier 2</ng-option>
                                <ng-option value="chantier 3">Chantier 3</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <label for="type">{{f.typeHse.value == 'Incident' ? 'Type d\'incident' : 'Type de l\'accident'}}: <span class="text-danger ml-2">*</span></label>
                            <div class="input-group">
                                <select id="type" formControlName="type" class="form-control" *ngIf="inputGroup == false" [ngClass]="{ 'is-invalid': submit && f.type.errors, 'is-valid': submit && f.type.valid }">
                                    <option *ngFor="let type of typeIncident" [value]="type.value">{{type.label}}</option>
                                </select>
                                <input *ngIf="inputGroup == true" id="type" formControlName="type" class="form-control" placeholder="{{f.typeHse.value == 'Incident' ? 'Type d\'incident' : 'Type de l\'accident'}}" type="text">
                                <button tooltip="{{inputGroup == true ? 'Clique pour choisir' : 'Clique pour saisir' }}" placement="auto" (click)="inputGroup = !inputGroup" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button">
                                    <span class="btn-wrapper--icon">
                                        <i class="{{inputGroup == true ? 'fas fa-hand-pointer' : 'fas fa-keyboard'}}"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="invalid-feedback" *ngIf="submit && f.type.errors">
                                <div *ngIf="f.type.errors.required">{{required.novide}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <label for="libelle">Libellé: <span class="text-danger ml-2">*</span></label>
                            <input type="text" id="libelle" formControlName="libelle" class="form-control" placeholder="Libellé de l'incident"
                            [ngClass]="{ 'is-invalid': submit && f.libelle.errors, 'is-valid': submit && f.libelle.valid }">
                            <div class="invalid-feedback" *ngIf="submit && f.libelle.errors">
                                <div *ngIf="f.libelle.errors.required">{{required.novide}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="f.typeHse.value == 'Incident'">
                        <div class="position-relative form-group">
                            <label for="localisation">Localisation: <span class="text-danger ml-2">*</span></label>
                            <input type="text" id="localisation" formControlName="localisation" class="form-control" placeholder="Localisation de l'incident"
                            [ngClass]="{ 'is-invalid': submit && f.localisation.errors, 'is-valid': submit && f.localisation.valid }">
                            <div class="invalid-feedback" *ngIf="submit && f.localisation.errors">
                                <div *ngIf="f.localisation.errors.required">{{required.novide}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="f.typeHse.value == 'Accident de travail'">
                        <div class="position-relative form-group">
                            <label for="dateAccident">Date de l'accident: <span class="text-danger ml-1">*</span></label>
                            <div class="input-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                </div>
                                <input type="datetime-local" id="dateAccident" formControlName="dateAccident" class="form-control"
                                placeholder="Date de l'accident" [ngClass]="{'is-invalid': submit && f.dateAccident.errors,'is-valid': submit && f.dateAccident.valid}">
                                <div class="invalid-feedback" *ngIf="submit && f.dateAccident.errors">
                                    <div *ngIf="f.dateAccident.errors.required">{{required.novide}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-{{f.typeHse.value == 'Incident' ? 12 : 4}}">
                        <div class="position-relative form-group">
                            <label for="description">Description: </label>
                            <textarea id="description" formControlName="description" class="form-control" rows="{{f.typeHse.value == 'Incident' ? 2 : 1}}" placeholder="Description"></textarea>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="f.typeHse.value == 'Accident de travail'">
                        <div class="position-relative form-group">
                            <label for="montant">Montant estimé des dommages: </label>
                            <input type="number" id="montant" formControlName="montant" class="form-control" placeholder="Montant estimé des dommages">
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="f.typeHse.value == 'Accident de travail'">
                        <div class="position-relative form-group">
                            <label for="temoin">Y avait-il un témoin lors de l'accident ? </label>
                            <select id="temoin" formControlName="temoin" class="form-control">
                                <option *ngFor="let temoin of temoinRow" [value]="temoin.value">{{temoin.label}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-row" *ngIf="f.typeHse.value == 'Accident de travail' && f.temoin.value == 'OUI'">
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <label for="nom">Nom et prénom <span class="text-danger">*</span></label>
                            <input type="text" id="nom" formControlName="nom" class="form-control" placeholder="Nom et prénom du témoin"
                            [ngClass]="{ 'is-invalid': submit && f.nom.errors, 'is-valid': submit && f.nom.valid }">
                            <div class="invalid-feedback" *ngIf="submit && f.nom.errors">
                                <div *ngIf="f.nom.errors.required">{{required.novide}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <label for="contact">Contact <span class="text-danger">*</span></label>
                            <input type="text" id="contact" formControlName="contact" class="form-control" placeholder="Contact du témoin"
                            [ngClass]="{ 'is-invalid': submit && f.contact.errors, 'is-valid': submit && f.contact.valid }">
                            <div class="invalid-feedback" *ngIf="submit && f.contact.errors">
                                <div *ngIf="f.contact.errors.required">{{required.novide}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <label for="fonction">Fonction: </label>
                            <input type="text" id="fonction" formControlName="fonction" class="form-control" placeholder="Fonction du témoin">
                        </div>
                    </div>
                </div>
            
                <div class="mt-2" *ngIf="f.typeHse.value == 'Accident de travail'">
                    <div class="d-flex justify-content-between mb-3">
                        <div><h4 class="font-weight-bold m-0">Informations sur la victime</h4></div>
                    </div><hr>
                    <div class="form-row">
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <label for="nomVictime">Nom et prénom de la victime:<span class="text-danger">*</span></label>
                                <input type="text" id="nomVictime" formControlName="nomVictime" class="form-control" placeholder="Nom et prénom de la victime"
                                [ngClass]="{ 'is-invalid': submit && f.nomVictime.errors, 'is-valid': submit && f.nomVictime.valid }">
                                <div class="invalid-feedback" *ngIf="submit && f.nomVictime.errors">
                                    <div *ngIf="f.nomVictime.errors.required">{{required.novide}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <label for="contactVictime">Contact <span class="text-danger">*</span></label>
                                <input type="text" id="contactVictime" formControlName="contactVictime" class="form-control" placeholder="Contact de la victime"
                                [ngClass]="{ 'is-invalid': submit && f.contactVictime.errors, 'is-valid': submit && f.contactVictime.valid }">
                                <div class="invalid-feedback" *ngIf="submit && f.contactVictime.errors">
                                    <div *ngIf="f.contactVictime.errors.required">{{required.novide}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <label for="fonctionVictime">Fonction: </label>
                                <input type="text" id="fonctionVictime" formControlName="fonctionVictime" class="form-control" placeholder="Fonction de la victime">
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <label for="entreprise">Est-il de quelle entreprise ?</label>
                                <input type="text" id="entreprise" formControlName="entreprise" class="form-control" placeholder="Entreprise employeur">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <label for="dateAbsence">Etait absent à parti du: </label>
                                <div class="input-group input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                    </div>
                                    <input type="datetime-local" id="dateAbsence" formControlName="dateAbsence" class="form-control" placeholder="Date d'absence">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <label for="dateRetour">Retouner le: </label>
                                <div class="input-group input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                    </div>
                                    <input type="datetime-local" id="dateRetour" formControlName="dateRetour" class="form-control" placeholder="Date de retoure">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="vehicule">Un véhicule est-il associé à l'accident ?</label>
                                <select id="vehicule" formControlName="vehicule" class="form-control">
                                    <option *ngFor="let vehicule of vehiculeRow" [value]="vehicule.value">{{vehicule.label}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" *ngIf="f.vehicule.value == 'OUI'">
                        <div class="col-md-3">
                            <div class="position-relative form-group">
                                <label for="marque">Marque: </label>
                                <input type="text" id="marque" formControlName="marque" class="form-control" placeholder="Marque de véhicule">
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="position-relative form-group">
                                <label for="descriptionVehicule">Description: </label>
                                <textarea type="text" id="descriptionVehicule" formControlName="descriptionVehicule" class="form-control" rows="1" placeholder="Description"></textarea>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <label for="photoVehicule">Photo:</label>
                                <div class="custom-file">
                                    <input type="file" id="photoVehicule" formControlName="photoVehicule" class="custom-file-input" multiple>
                                    <label class="custom-file-label" for="photoVehicule">Choisir une photo</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <label for="lesion">Nature de la lésion: </label>
                                <input type="text" id="lesion" formControlName="lesion" class="form-control" placeholder="Nature de la lésion">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <label for="lieuLesion">Localisation de la lésion: </label>
                                <input type="text" id="lieuLesion" formControlName="lieuLesion" class="form-control" placeholder="Localisation">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <div class="position-relative form-group">
                                    <label for="soins">Soins prodigués par: </label>
                                    <select id="soins" formControlName="soins" class="form-control">
                                        <option *ngFor="let soins of soinsPar" [value]="soins.value">{{soins.label}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" *ngIf="f.soins.value != null">
                        <div class="col-md-3">
                            <div class="position-relative form-group">
                                <label for="nomSoigneur">{{f.soins.value == 'Personne physique' ? 'Nom et prenom' : 'Raison sociale'}}: </label>
                                <input type="text" id="nomSoigneur" formControlName="nomSoigneur" class="form-control" placeholder="{{f.soins.value == 'Personne physique' ? 'Nom et prenom' : 'Raison sociale'}}">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="position-relative form-group">
                                <label for="lieuSoigneur">{{f.soins.value == 'Personne physique' ? 'Lieu d\'habitation' : 'Localisation'}}: </label>
                                <input type="text" id="lieuSoigneur" formControlName="lieuSoigneur" class="form-control" placeholder="{{f.soins.value == 'Personne physique' ? 'Lieu d\'habitation' : 'Localisation'}}">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="position-relative form-group">
                                <label for="contactSoigneur">Contact: </label>
                                <input type="text" id="contactSoigneur" formControlName="contactSoigneur" class="form-control" placeholder="Contact">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="position-relative form-group">
                                <label for="fonctionSoigneur">{{f.soins.value == 'Personne physique' ? 'Fonction' : 'Service'}}: </label>
                                <input type="text" id="fonctionSoigneur" formControlName="fonctionSoigneur" class="form-control" placeholder="{{f.soins.value == 'Personne physique' ? 'Fonction' : 'Service'}}">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4" *ngIf="f.typeHse.value == 'Incident'">
                        <div class="position-relative form-group">
                            <label for="gravite">Gravité: <span class="text-danger ml-2">*</span></label>
                            <select id="gravite" formControlName="gravite" class="form-control" [ngClass]="{ 'is-invalid': submit && f.type.errors, 'is-valid': submit && f.type.valid }">
                                <option *ngFor="let gravite of graviteRow" [value]="gravite.value">{{gravite.label}}</option>
                            </select>
                            <div class="invalid-feedback" *ngIf="submit && f.gravite.errors">
                                <div *ngIf="f.gravite.errors.required">{{required.novide}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-{{f.typeHse.value == 'Incident' ? 4 : 12}}" *ngIf="f.typeHse.value == 'Incident' || f.typeHse.value == 'Accident de travail'">
                        <div class="position-relative form-group">
                            <label for="photo">Photo:</label>
                            <div class="custom-file">
                                <input type="file" id="photo" formControlName="photo" class="custom-file-input" title="Joindre un fichier" multiple>
                                <label class="custom-file-label" for="photo">Choisir une photo</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="f.typeHse.value == 'Incident'">
                        <div class="position-relative form-group">
                            <label for="nbImpliquer">Nombre de personne impliquées: <span class="text-danger ml-2">*</span></label>
                            <input (ngModelChange)="onChangeImpliquers()" type="number" id="nbImpliquer" formControlName="nbImpliquer" class="form-control" placeholder="Nombre de personne impliquées"
                            [ngClass]="{ 'is-invalid': submit && f.nbImpliquer.errors, 'is-valid': submit && f.nbImpliquer.valid }">
                            <div class="invalid-feedback" *ngIf="submit && f.nbImpliquer.errors">
                                <div *ngIf="f.nbImpliquer.errors.required">{{required.novide}}</div>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div *ngIf="impliquer.controls.length > 0 && f.typeHse.value == 'Incident'">
                    <hr class="mb-0">
                    <div class="p-2 bg-secondary">
                        <h6 class="font-weight-bold m-0">Renseignement des personnes impliquées</h6>
                    </div>
                    <hr class="mt-0">
                    <div formArrayName="impliquers">
                        <div class="form-row">
                            <div class="col-md-1">
                                <label for="numero">Numero</label>
                            </div>
                            <div class="col">
                                <label for="nom">Nom et prénom: <span class="text-danger ml-1">*</span></label>
                            </div>
                            <div class="col">
                                <label for="contact">Contact: </label>
                            </div>
                            <div class="col">
                                <label for="lien">Lien avec l'incident</label>
                            </div>
                        </div>
                        <div class="form-row" *ngFor="let item of impliquer.controls; let i=index" [formGroupName]="i">
                            <div class="col-md-1">
                                <div class="position-relative form-group">
                                    <input type="text" id="numero{{i}}" formControlName="numero" class="form-control text-center" placeholder="Nom et prenom">
                                </div>
                            </div>
                            <div class="col">
                                <div class="position-relative form-group">
                                    <input type="text" id="nom{{i}}" formControlName="nom" class="form-control" placeholder="Nom et prenom"
                                    [ngClass]="{'is-invalid': submit && item.get('nom').errors,'is-valid': submit && item.get('nom').valid}">
                                </div>
                            </div>
                            <div class="col">
                                <div class="position-relative form-group">
                                    <input type="text" id="contact{{i}}" formControlName="contact" class="form-control" placeholder="Contact">
                                </div>
                            </div>
                            <div class="col">
                                <div class="position-relative form-group">
                                    <select formControlName="lien" id="lien{{i}}" rows="1" class="form-control">
                                        <option *ngFor="let lien of lienRow" [value]="lien.value">{{lien.label}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
        <hr>
    
        <!-- Option d'association s2 -->
        <accordion class="accordion shadow-none" [closeOthers]="true" [isAnimated]="true">
            <accordion-group class="card card-box shadow-none">
                <button accordion-heading class="btn btn-link d-flex align-items-center justify-content-between" type="button">
                    <span>Definir les causes ou diagnostiques</span>
                    <fa-icon [icon]="['fas', 'angle-up']"></fa-icon>
                </button>
                <div class="p-3 causes">
                    <div class="form-row">
                        <div class="col-md-6">
                            <div class="position-relative form-group">
                                <label for="regleOr">Principale règle d'or applicable:</label>
                                <textarea id="regleOr" formControlName="regleOr" class="form-control" placeholder="Principale règle d'or applicable" rows="4"></textarea>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="position-relative form-group">
                                <label for="actes">Actes dangereuses:</label>
                                <textarea id="actes" formControlName="actes" class="form-control" placeholder="Actes dangereuses" rows="4"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-6">
                            <div class="position-relative form-group">
                                <label for="circonstances">Circonstances dangereuses:</label>
                                <textarea id="circonstances" formControlName="circonstances" class="form-control" placeholder="Circonstances dangereuses" rows="4"></textarea>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="position-relative form-group">
                                <label for="facteurPerson">Facteurs personnels:</label>
                                <textarea id="facteurPerson" formControlName="facteurPerson" class="form-control" placeholder="Facteurs personnels" rows="4"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="facteurOranisation">Facteurs organisationnels:</label>
                                <textarea id="facteurOranisation" formControlName="facteurOranisation" class="form-control" placeholder="Facteurs organisationnels" rows="4"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </accordion-group>
            <accordion-group class="card card-box shadow-none">
                <button accordion-heading class="btn btn-link d-flex align-items-center justify-content-between" type="button">
                    <span>Definir les actions ou mesures prises</span>
                    <fa-icon [icon]="['fas', 'angle-up']"></fa-icon>
                </button>
                <div class="p-3 mesure">
                    <div class="form-row">
                        <div class="col-md-6">
                            <div class="position-relative form-group">
                                <label for="immediat">Mesures prises immediatement:</label>
                                <textarea id="immediat" formControlName="immediat" class="form-control" placeholder="Mesures prises immediatement" rows="4"></textarea>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="position-relative form-group">
                                <label for="ulterieur">Mesures à prendre ulterieurement:</label>
                                <textarea id="ulterieur" formControlName="ulterieur" class="form-control" placeholder="Mesures à prendre ulterieurement" rows="4"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="descriptionAction">Description: </label>
                                <textarea id="descriptionAction" formControlName="descriptionAction" class="form-control" rows="4" placeholder="Description des action"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-7">
                            <div class="position-relative form-group">
                                <label for="prisePar">Mesures prises par:</label>
                                <select id="prisePar" formControlName="prisePar" class="form-control">
                                    <option value="">Kouassi ghislain</option>
                                    <option value="">Kouassi ghislain</option>
                                    <option value="">Kouassi ghislain</option>
                                    <option value="">Kouassi ghislain</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="position-relative form-group">
                                <label for="dateAction">Date:</label>
                                <div class="input-group input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                    </div>
                                    <input type="text" id="dateAction" formControlName="dateAction" class="form-control"
                                    [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker placeholder="Date">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </accordion-group>
        </accordion>
    </div>
    <hr class="m-0">

    <div class="d-flex justify-content-between py-2 px-3 mx-2">
        <p class="text-danger mt-2">L'étoile (*) signifie que cet champ est obligatoire.</p>
        <div class="text-right">
            <button class="btn btn-first mr-2" type="button" (click)="form.reset()"><i class="fas fa-broom  mr-2"></i> Nettoyer</button>
            <button  class="btn btn-success" type="submit"><i class="fas fa-save mr-2"></i> Enregistrer</button>
        </div>
    </div>
</form>