import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rapport-list',
  templateUrl: './rapport-list.component.html',
  styleUrls: ['./rapport-list.component.scss']
})
export class RapportListComponent implements OnInit {
  rotate = true;

  constructor() { }

  ngOnInit() {
  }

}
