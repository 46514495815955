import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';
import {Color} from 'ng2-charts';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TresoreriePages } from 'src/app/models/tresorerie-pages';
import { TresoreriePagesService } from 'src/app/services/tresrerie-pages/tresorerie-pages.service';

@Component({
  selector: 'app-tresorerie-detail',
  templateUrl: './tresorerie-detail.component.html',
  styleUrls: ['./tresorerie-detail.component.scss']
})
export class TresorerieDetailComponent implements OnInit {
  tresorerie: TresoreriePages = <TresoreriePages>{};
  rotate = true;
  fp = true;
  form: FormGroup;
  toggleNewPaiment: boolean = false;
  public mask: Array<string | RegExp>;
  public maskDate: Array<string | RegExp>;

  constructor(
    public globals: ThemeOptions,
    private route: ActivatedRoute,
    private formBuild: FormBuilder,
    private tresorerieService: TresoreriePagesService
  ) 
  {
    this.tresorerie = this.tresorerieService.getTresorerie();
    console.log(this.tresorerie)
    this.maskDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    const id: number = +this.route.snapshot.paramMap.get('id');
    this.tresorerieService.getList().subscribe((tresoreries: TresoreriePages[]) => {
      this.tresorerie = tresoreries.find(tresorerie => tresorerie.id == id);
    });
  }

  newForm() {
    this.form = this.formBuild.group({
      montant: [0],
      remisA: [null, [Validators.required]],
      modePaiement: [null, [Validators.required]],
      nom:[null, [Validators.required]],
      prenom: [null, [Validators.required]],
    });
  }
  moisRow = [
    {label: 'Janvier'},
    {label: 'Février'},
    {label: 'Mars'},
    {label: 'Avril'},
    {label: 'Mai'},
    {label: 'Juin'},
    {label: 'Juillet'},
    {label: 'Août'},
    {label: 'Septembre'},
    {label: 'Octobre'},
    {label: 'Novembre'},
    {label: 'Décembre'}
  ]
  remisA = [
    {label: 'Lui même', value: 'lui meme'},
    {label: 'Autre', value: 'autre'},
  ]

  ngOnInit() {
    return this.newForm();
  }

  newPaiement() {
    this.toggleNewPaiment = true;
  }

  paiementOff() {
    this.toggleNewPaiment = false;
  }

  //Chart de rapport mensuel E/S
  public chartDataset2 = [
    {
      data: [65, 59, 80, 81, 56, 55, 40, 6, 40, 43, 9, 50],
      datalabels: {display: false},
      label: '2019'
    },
    {
      data: [65, 81, 56, 59, 80, 55, 40, 1, 3, 8, 9, 56],
      datalabels: {display: false},
      label: '2020'
    },
    {
      data: [28, 48, 19, 86, 27 ,40, 90, 12, 34, 65, 12, 11],
      datalabels: {display: false},
      label: '2021'
    }
  ];
  public chartLabels2 = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];
  public chartColor2: Color[] = [
    {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      borderCapStyle: 'round',
      borderDash: [],
      borderWidth: 4,
      borderColor: '#7a7b97',
      borderDashOffset: 0.0,
      borderJoinStyle: 'round',
      pointBorderColor: '#7a7b97',
      pointBackgroundColor: '#ffffff',
      pointBorderWidth: 3,
      pointHoverRadius: 6,
      pointHoverBorderWidth: 3,
      pointRadius: 4,
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#7a7b97'
    },
    {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      borderCapStyle: 'round',
      borderDash: [],
      borderWidth: 4,
      borderColor: '#4191ff',
      borderDashOffset: 0.0,
      borderJoinStyle: 'round',
      pointBorderColor: '#4191ff',
      pointBackgroundColor: '#ffffff',
      pointBorderWidth: 3,
      pointHoverRadius: 6,
      pointHoverBorderWidth: 3,
      pointRadius: 4,
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#4191ff'
    },
    {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      borderCapStyle: 'round',
      borderDash: [],
      borderWidth: 4,
      borderColor: '#f4772e',
      borderDashOffset: 0.0,
      borderJoinStyle: 'round',
      pointBorderColor: '#f4772e',
      pointBackgroundColor: '#ffffff',
      pointBorderWidth: 3,
      pointHoverRadius: 6,
      pointHoverBorderWidth: 3,
      pointRadius: 4,
      pointHoverBackgroundColor: '#ffffff',
      pointHoverBorderColor: '#f4772e'
    }
  ];
  public chartOptions2 = {
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          display: true,
          beginAtZero: true
        },
        gridLines: {
          display: true,
          color: '#eeeff8',
          drawBorder: true
        }
      }],
      xAxes: [{
        ticks: {
          display: true,
          beginAtZero: true
        },
        gridLines: {
          display: true,
          color: '#eeeff8',
          drawBorder: true
        }
      }]
    },
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false
  };
  get f() { return this.form.controls; }

}
