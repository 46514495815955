import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RessourceDashboardComponent } from './ressource-dashboard/ressource-dashboard.component';
import { RessourceDetailComponent } from './ressource-detail/ressource-detail.component';
import { RessourceListComponent } from './ressource-list/ressource-list.component';
import { RessourcePagesComponent } from './ressource-pages.component';


const routes: Routes = [
  {
    path:'',
    component: RessourcePagesComponent,
    children: [
      {
        path: "dashboard",
        component: RessourceDashboardComponent
      },
      {
        path: "liste",
        component: RessourceListComponent
      },
      {
        path: "detail/:id",
        component: RessourceDetailComponent
      },
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RessourcePagesRoutingModule { }
