<div *ngIf="defaultPV.length > 0">
    <div class="card card-box border-0">
        <div class="card-header p-3">
            <div class="card-header--title">
                <h6 class="font-weight-bold m-0">Liste de pv de visite ( <span class="text-black-50">{{defaultPV.length}}</span> )</h6>
            </div>
            <div class="card-header--actions">
                <div class="btn-group" dropdown>
                    <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret mr-2"
                        dropdownToggle
                        tooltip="Action"
                        type="button">
                        <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                        <ul class="nav nav-pills flex-column p-3">
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-3">
            <div class="d-flex justify-content-between mb-3">
                <div class="search-wrapper">
                  <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                  <input class="form-control" placeholder="Recherche dans la liste" type="search">
                </div>
            </div>
            <div class="table-responsive m-0">
                <table class="table table-hover text-nowrap m-0">
                    <thead>
                        <tr>
                            <th class="text-left">Référence</th>
                            <th class="text-left">Libellé</th>
                            <th class="text-left">Date de visite</th>
                            <th class="text-left">état</th>
                            <th class="text-left">Créer par</th>
                            <th class="text-left">Date de creation</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of defaultPV">
                            <td>PV0001</td>
                            <td>PV de visite</td>
                            <td><span class="badge bg-neutral-warning text-warning">11/11/2021</span></td>
                            <td><span class="badge bg-neutral-success text-success">Valider</span></td>
                            <td><span class="badge bg-neutral-first text-first">Kouassi ghislain</span></td>
                            <td><span class="badge bg-neutral-dark text-dark">11/11/2021 11:11:11</span></td>
                            <td class="text-center">
                                <button class="btn d-40 p-0 btn-pill mr-2 btn-primary" placement="auto" tooltip="Plus d'infos" type="button" (click)="detailPvModal.show()">
                                    <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                </button>
                                <button class="btn d-40 p-0 btn-pill mr-2 btn-warning" placement="auto" tooltip="Imprimer" type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-footer p-3">
            <div class="d-flex justify-content-between">
                <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                <div class="d-flex align-items-center">
                    <span>Show</span>
                    <select class="mx-1 form-control form-control-sm" id="" name="">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="40">50</option>
                        <option value="40">100</option>
                    </select>
                    <span>entries</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="defaultPV.length == 0">
    <div class="hero-wrapper bg-composed-wrapper bg-white rounded">
        <div class="flex-grow-1 w-100 d-flex align-items-center">
            <div class="bg-composed-wrapper--image bg-composed-filter-rm opacity-4 rounded" style="background-image: url('assets/images/hero-bg/hero-2.jpg');"></div>
            <div class="bg-composed-wrapper--content py-5">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-xl-6">
                            <div class="text-black mt-3">
                                <h1 class="display-3 mb-3 font-weight-bold">
                                    Aucun PV de visite enregistrer
                                </h1>
                                <div class="divider border-2 border-dark my-5 border-light opacity-2 rounded-circle w-25"></div>
                                <p class="font-size-lg text-black-50">
                                    Il n'y a pas PV de visite célà signifie qu'il n'y a pas eu de visite chantier dans ce projet.
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-6 px-0 d-none d-xl-flex align-items-center">
                            <img src="assets/images/illustrations/process.svg" class="mx-auto d-block img-fluid" alt="...">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal de nouveau pv -->
<div #pvVisiteModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Formulaire de création de PV de visite chantier</h6>
                <button type="button" class="close" aria-label="Close" (click)="pvVisiteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-proces-verbal-edit></app-proces-verbal-edit>
            </div>
        </div>
    </div>
</div>
<!-- Fin modal de nouveau pv -->

<!-- Modal detail pv de visite -->
<div bsModal #detailPvModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Détail procès verbal de visite du 11/11/2021</h6>
                <button type="button" class="close" aria-label="Close" (click)="detailPvModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <app-proces-verbal-detail></app-proces-verbal-detail>
            </div>
        </div>
    </div>
</div>
<!-- Fin modal detail pv de visite -->