import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReunionPages } from 'src/app/models/reunion-pages';
import { ReunionPagesService } from 'src/app/services/reunion-pages/reunion-pages.service';

@Component({
  selector: 'app-reunion-list',
  templateUrl: './reunion-list.component.html',
  styleUrls: ['./reunion-list.component.scss']
})
export class ReunionListComponent implements OnInit {
  @Input() cardHeaderTitle = false;
  rotate = true;
  reunions: ReunionPages[]
  errMsg = "";

  constructor(
    private reunionService: ReunionPagesService,
    private router: Router,
  ) {
    this.reunionService.getList().subscribe({
      next: reunion => this.reunions = reunion,
      error: err => this.errMsg = err
    });
  }

  ngOnInit() {
  }

}
