import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visite-pages',
  templateUrl: './visite-pages.component.html',
  styleUrls: ['./visite-pages.component.scss']
})
export class VisitePagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
