import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TravauxPagesService {

  constructor() { }

  getList() {
    return [
      {
        id:1,
        libelle: 'Terrassement',
        dateDebut: '01/01/2020',
        dateFin: '01/01/2021',
        tacheP: 10,
        nbTache: 100,
        evolution: 100,
        status: 'Terminer',
        responsable: 'Kouassi ghislain',
        respoPh: 'assets/images/avatars/avatar7.jpg',
        respoFonction: 'Ingenieur batiment',
        dateCreation: '12/09/2021 09:22:45',
      },
      {
        id:2,
        libelle: 'Carrelage',
        dateDebut: '01/01/2020',
        dateFin: '01/01/2022',
        tacheP: 17,
        nbTache: 100,
        evolution: 67,
        status: 'En cours',
        responsable: 'Maman kader',
        respoPh: 'assets/images/avatars/avatar1.jpg',
        respoFonction: 'Carreleur',
        dateCreation: '12/09/2021 09:22:45',
      },
      {
        id:3,
        libelle: 'Nettoyage',
        dateDebut: '01/01/2020',
        dateFin: '01/01/2023',
        tacheP: 66,
        nbTache: 100,
        evolution: 75,
        status: 'En attente',
        responsable: 'John doe',
        respoPh: 'assets/images/avatars/avatar3.jpg',
        respoFonction: 'Ingenieur batiment',
        dateCreation: '12/09/2021 09:22:45',
      },
      {
        id:4,
        libelle: 'Terrassement',
        dateDebut: '01/01/2020',
        dateFin: '01/01/2023',
        tacheP: 100,
        nbTache: 100,
        evolution: 28,
        status: 'Annuler',
        responsable: 'Kouassi ghislain',
        respoPh: 'assets/images/avatars/avatar5.jpg',
        respoFonction: 'Ingenieur batiment',
        dateCreation: '12/09/2021 09:22:45',
      },
      {
        id:5,
        libelle: 'Terrassement',
        dateDebut: '01/01/2020',
        dateFin: '01/01/2023',
        tacheP: 98,
        nbTache: 100,
        evolution: 49,
        status: 'Annuler',
        responsable: 'Kouassi ghislain',
        respoPh: 'assets/images/avatars/avatar6.jpg',
        respoFonction: 'Ingenieur batiment',
        dateCreation: '12/09/2021 09:22:45',
      },
    ]
  }
}
