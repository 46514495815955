import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'querystring';
import { BehaviorSubject, throwError } from 'rxjs';

import { environment } from './../../../environments/environment';
import { AuthPagesService } from '../auth-pages/auth-pages.service';
import { catchError, switchMap, take, map, tap } from 'rxjs/operators';

interface MissionData {
  id?: string,
  motif: string,
  demandeur: string,
  contact: string,
  itineraire: string,
  description: string,
  fonction: string,
  statut: number,
  email: string,
  dateD: Date,
  chantier: string,
  dateR: Date
}
interface MemberByMissionData {
  nom: string,
  prenom: string,
  email: string,
  fonction: string,
  contact: string,
  mission: string
}

@Injectable({
  providedIn: 'root'
})
export class MissionService {

  apiUrl = environment.apiUrl;
  prefix = environment.prefix;

  private assignData;
  constructor(private authService: AuthPagesService, private http: HttpClient) { }

  addMission(Data: MissionData) {
    return this.http.post<MissionData>(`${this.apiUrl}/missions`, JSON.stringify(Data)).pipe(
      take(1),
      tap(resData => {
        return resData
      }),
      catchError(this.handleError)
    );//pipe   
  }
  updateMission(id: number, Data: MissionData) {
    return this.http.put<MissionData>(`${this.apiUrl}/missions/` + id, JSON.stringify(Data)).pipe(
      take(1),
      tap(resData => {
        return resData
      }),
      catchError(this.handleError)
    );//pipe   
  }

  deleteMission(id: number) {
    return this.http.delete<MissionData>(`${this.apiUrl}/missions/` + id).pipe(
      take(1),
      tap(resData => {
        return resData
      }),
      catchError(this.handleError)
    );//pipe   
  }

  listerMission() {
    return this.http.get<MissionData[]>(
      `${this.apiUrl}/missions`, {
      responseType: 'json'
    }
    ).pipe(
      map((response: any) => response['hydra:member']),
      catchError(this.handleError)
    )
  }


  // API TO MEMBER OF MISSIONS 

  addMemberByMission(Data: MemberByMissionData) {
    return this.http.post<MemberByMissionData>(`${this.apiUrl}/membre_missions`, JSON.stringify(Data)).pipe(
      take(1),
      tap(resData => {
        return resData
      }),
      catchError(this.handleError)
    );//pipe   
  }
  updateMemberByMission(id: number, Data: MemberByMissionData) {
    return this.http.put<MemberByMissionData>(`${this.apiUrl}/membre_missions/` + id, JSON.stringify(Data)).pipe(
      take(1),
      tap(resData => {
        return resData
      }),
      catchError(this.handleError)
    );//pipe   
  }

  deleteMemberByMission(id: number) {
    return this.http.delete<MemberByMissionData>(`${this.apiUrl}/membre_missions/` + id).pipe(
      take(1),
      tap(resData => {
        return resData
      }),
      catchError(this.handleError)
    );//pipe   
  }

  listerMemberByMission() {
    return this.http.get<MemberByMissionData[]>(
      `${this.apiUrl}/membre_missions`, {
      responseType: 'json'
    }
    ).pipe(
      map((response: any) => response['hydra:member']),
      catchError(this.handleError)
    )
  }


  getDataForMission() {
    return this.assignData;
  }
  setDataForMission(data) {
    this.assignData = data;
  }


  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "Une erreur est survenue";
    return throwError(messageError);
  }
}
