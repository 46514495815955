import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilOptions } from 'src/app/shared-components/util/util-option';

@Component({
  selector: 'app-situation-hse-edit',
  templateUrl: './situation-hse-edit.component.html',
  styleUrls: ['./situation-hse-edit.component.scss']
})
export class SituationHseEditComponent implements OnInit {
  form: FormGroup;
  submit: boolean = false;
  inputGroup: boolean = false;
  required = UtilOptions.required;
  graviteRow = UtilOptions.gravite;
  temoinRow = UtilOptions.reponseOuiNom;
  vehiculeRow = UtilOptions.reponseOuiNom;
  sousTraitanceRow = UtilOptions.reponseOuiNom;
  soinsPar = UtilOptions.type;

  typeHse = [
    { label: "Incident", value: "Incident" },
    { label: "Accident de travail", value: "Accident de travail" }
  ];
  typeIncident = [
    { label: "Danger", value: "Danger" },
    { label: "Glissade et chute", value: "Glissade et chute" },
    { label: "Blessure", value: "Blessure" },
    { label: "Mortel", value: "Mortel" },
    { label: "Vol", value: "Vol" },
    { label: "Incendie", value: "Incendie" },
    { label: "Dommage matériel", value: "Dommage matériel" },
    { label: "Fatalité", value: "Fatalité" },
    { label: "Perte de temps", value: "Perte de temps" },
    { label: "A déclarer / à notifier", value: "A déclarer / à notifier" },
    { label: "Autre", value: "Autre" }
  ];
  lienRow = [
    { label: "Rapporteur de l'incident", value: "Rapporteur de l'incident" },
    { label: "Persoone blessées", value: "Persoone blessées" },
    { label: "Ouviers", value: "Ouviers" },
    { label: "Témoin", value: "Témoin" },
    { label: "Principale impliquée", value: "Principale impliquée" },
    { label: "Superviseur en service", value: "Superviseur en service" },
    { label: "Enquêteur", value: "Enquêteur" },
    { label: "Suspect", value: "Suspect" },
    { label: "Autre", value: "Autre" }
  ]
  
  constructor(
    private formBuilder: FormBuilder
  ) {
    this.newForm();
  }

  ngOnInit() {
  }
  newForm(){
    this.form = this.formBuilder.group({
      id: [null],
      typeHse: ["Incident", [Validators.required]],
      libelle: [null, [Validators.required]],
      projet: [null, [Validators.required]],
      chantier: [null, [Validators.required]],
      localisation: [null, [Validators.required]],
      type: [null, [Validators.required]],
      gravite: [null, [Validators.required]],
      photo: [null, [Validators.required]],
      nbImpliquer: [null, [Validators.required]],
      description: [null, [Validators.required]],
      impliquers: this.formBuilder.array([]),
      
      dateAccident: [null, [Validators.required]],
      montant: [null, [Validators.required]],
      temoin: ["NON", [Validators.required]],
      nom: [null, [Validators.required]],
      contact: [null, [Validators.required]],
      fonction: [null, [Validators.required]],
      nomVictime: [null, [Validators.required]],
      contactVictime: [null, [Validators.required]],
      fonctionVictime: [null, [Validators.required]],
      dateAbsence: [null, [Validators.required]],
      dateRetour: [null, [Validators.required]],
      vehicule: ["NON", [Validators.required]],
      marque: [null, [Validators.required]],
      descriptionVehicule: [null, [Validators.required]],
      photoVehicule: [null, [Validators.required]],
      lesion: [null, [Validators.required]],
      lieuLesion: [null, [Validators.required]],
      soins: [null, [Validators.required]],
      nomSoigneur: [null, [Validators.required]],
      lieuSoigneur: [null, [Validators.required]],
      contactSoigneur: [null, [Validators.required]],
      fonctionSoigneur: [null, [Validators.required]],

      // Causes
      regleOr: [null, [Validators.required]],
      actes: [null, [Validators.required]],
      circonstances: [null, [Validators.required]],
      facteurPerson: [null, [Validators.required]],
      facteurOranisation: [null, [Validators.required]],

      // Actions
      immediat: [null, [Validators.required]],
      ulterieur: [null, [Validators.required]],
      prisePar: [null, [Validators.required]],
      dateAction: [null, [Validators.required]],
      descriptionAction: [null, [Validators.required]],
    })
  }
  onSubmit(){}
  onChangeImpliquers(){
    this.impliquer.controls.length = 0;
    var nbr = (this.f.nbImpliquer.value >= 0) ? this.f.nbImpliquer.value : 0;
    if (this.impliquer.controls.length < nbr) {
      for (let i = 0; i < nbr; i++) {
        var num = i + 1;
        this.impliquer.push(
          this.formBuilder.group({
            id: [null],
            numero: [{value: num, disabled: true}, [Validators.required]],
            nom: [null, [Validators.required]],
            contact: [null, [Validators.required]],
            lien: [null, [Validators.required]],
          })
        );
      }
      return this.impliquer;
    } else if (nbr === 0)  {
      let i = this.impliquer.controls.length - (nbr === 0 ? 1 : nbr);
      return this.impliquer.removeAt(i);
    } else {
      return this.impliquer.controls.splice(0, this.impliquer.controls.length);
    }
  }
  get f() { return this.form.controls; }
  get impliquer() { return this.form.get('impliquers') as FormArray; }

}
