<div class="mb-3">
    <div class="row">
        <div class="col">
            <a [routerLink]="" (click)="changeCommandStatus('liste')" class="card card-box-hover-rise border-0 rounded p-2" title="Totale des commandes">
                <div class="d-flex align-items-center mt-1">
                    <h1 class="m-0">
                        <i class="fas fa-shopping-cart img-fluid mx-3 mt-1 text-first"></i>
                    </h1>
                    <div>
                        <div class="text-black-50">Total</div>
                        <div class="font-weight-bold display-4 py-1 text-black">
                            <span [countUp]="874"></span>
                        </div>
                        <div class="text-black-50 font-size-sm">
                            <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1 text-warning"></fa-icon>
                            100%
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a [routerLink]="" (click)="changeCommandStatus('a valider')" class="card card-box-hover-rise border-0 rounded p-2" title="Nombre total des commandes à valider">
                <div class="d-flex align-items-center">
                    <ngx-gauge append="%"
                        backgroundColor="#edeef1"
                        cap="round"
                        class="img-fluid mr-2 text-black"
                        foregroundColor="#1bc943"
                        max="100" min="0" size="84"
                        thick="6"
                        type="full" value="76">
                    </ngx-gauge>
                    <div>
                        <div class="text-black-50">A valider</div>
                        <div class="font-weight-bold display-4 py-1 text-success">
                            <span [countUp]="874"></span>
                        </div>
                        <div class="text-black-50 font-size-sm">
                            <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1 text-warning"></fa-icon>
                            En attente
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a [routerLink]="" (click)="changeCommandStatus('valider')" class="card card-box-hover-rise border-0 rounded p-2" title="Nombre total des commandes valiées">
                <div class="d-flex align-items-center">
                    <ngx-gauge append="%"
                        backgroundColor="#edeef1"
                        cap="round"
                        class="img-fluid mr-2 text-black"
                        foregroundColor="#1bc943"
                        max="100" min="0" size="84"
                        thick="6"
                        type="full" value="76">
                    </ngx-gauge>
                    <div>
                        <div class="text-black-50">Validées</div>
                        <div class="font-weight-bold display-4 py-1 text-success">
                            <span [countUp]="874"></span>
                        </div>
                        <div class="text-black-50 font-size-sm">
                            <fa-icon [icon]="['far', 'check-circle']" class="font-size-sm pr-1 text-first"></fa-icon>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a [routerLink]="" (click)="changeCommandStatus('a livrer')" class="card card-box-hover-rise border-0 rounded p-2" title="Nombre total des commandes livrer">
                <div class="d-flex align-items-center">
                    <ngx-gauge append="%"
                        backgroundColor="#edeef1"
                        cap="round"
                        class="img-fluid mr-2 text-black"
                        foregroundColor="#1bc943"
                        max="100" min="0" size="84"
                        thick="6"
                        type="full" value="76">
                    </ngx-gauge>
                    <div>
                        <div class="text-black-50">A Livrer</div>
                        <div class="font-weight-bold display-4 py-1 text-success">
                            <span [countUp]="874"></span>
                        </div>
                        <div class="text-black-50 font-size-sm">
                            <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1 text-warning"></fa-icon>
                            En attente
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a [routerLink]="" (click)="changeCommandStatus('livrer')" class="card card-box-hover-rise border-0 rounded p-2" title="Nombre total des commandes livrées">
                <div class="d-flex align-items-center">
                    <ngx-gauge append="%"
                        backgroundColor="#edeef1"
                        cap="round"
                        class="img-fluid mr-2 text-black"
                        foregroundColor="#1bc943"
                        max="100" min="0" size="84"
                        thick="6"
                        type="full" value="76">
                    </ngx-gauge>
                    <div>
                        <div class="text-black-50">Livrées</div>
                        <div class="font-weight-bold display-4 py-1 text-success">
                            <span [countUp]="874"></span>
                        </div>
                        <div class="text-black-50 font-size-sm">
                            <fa-icon [icon]="['far', 'check-circle']" class="font-size-sm pr-1 text-first"></fa-icon>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>

<div>
    <div class="card card-box border-0 mt-3" *ngIf="commandStatus == 'liste'">
        <div class="card-header p-3">
            <div class="card-header--title">
                <h6 class="font-weight-bold m-0">Liste de toutes les commandes</h6>
            </div>
            <div class="card-header--actions">
                <div class="btn-group" dropdown>
                    <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret mr-2"
                        dropdownToggle
                        tooltip="Action"
                        type="button">
                        <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                        <ul class="nav nav-pills flex-column p-3">
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="d-flex justify-content-between p-3">
                <div class="search-wrapper">
                  <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                  <input class="form-control" placeholder="Recherche dans la liste" type="search">
                </div>
            </div>
            <div class="table-responsive m-0">
                <table class="table table-hover text-nowrap m-0">
                    <thead>
                        <tr>
                            <th>Libellé</th>
                            <th>Date commande</th>
                            <th>Etat validation</th>
                            <th>Etat Livraison</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr  *ngFor="let item of defaultRow1">
                            <td>Commande 1</td>
                            <td><span class="badge bg-neutral-warning text-warning">01/07/2021</span></td>
                            <td>
                                <span class="badge bg-{{item.etatValidation == 'validée' ? 'neutral-success text-success' : item.etatValidation == 'En attente' ? 'neutral-warning text-warning' : 'neutral-danger text-danger' }}">{{item.etatValidation}}</span>
                            </td>
                            <td>
                                <span class="badge bg-{{item.etatLivraison == 'livrée' ? 'neutral-success text-success' : item.etatLivraison == 'En attente' ? 'neutral-warning text-warning' : 'neutral-danger text-danger' }}">{{item.etatLivraison}}</span>
                            </td>
                            <td class="text-center">
                                <button class="btn btn-pill btn-primary d-40 p-0 mr-2" placement="auto" tooltip="Plus d'infos" type="button" (click)="detailCommandeModal.show()">
                                    <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                </button>
                                <button class="btn btn-pill btn-warning d-40 p-0 mr-2" placement="auto" tooltip="Imprimer" type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                                </button>
                                <button class="btn btn-pill btn-danger d-40 p-0" placement="auto"  tooltip="Supprimer" type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-footer p-3">
            <div class="d-flex justify-content-between">
                <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                <div class="d-flex align-items-center">
                    <span>Show</span>
                    <select class="mx-1 form-control form-control-sm" id="" name="">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="40">50</option>
                        <option value="40">100</option>
                    </select>
                    <span>entries</span>
                </div>
            </div>
        </div>
    </div>
    <div class="card card-box border-0 mt-3" *ngIf="commandStatus == 'a valider'">
        <div class="card-header p-3">
            <div class="card-header--title">
                <h6 class="font-weight-bold m-0">Liste des commandes à validées</h6>
            </div>
            <div class="card-header--actions">
                <div class="btn-group" dropdown>
                    <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret mr-2"
                        dropdownToggle
                        tooltip="Action"
                        type="button">
                        <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                        <ul class="nav nav-pills flex-column p-3">
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="d-flex justify-content-between p-3">
                <div class="search-wrapper">
                  <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                  <input class="form-control" placeholder="Recherche dans la liste" type="search">
                </div>
            </div>
            <div class="table-responsive m-0">
                <table class="table table-hover text-nowrap m-0">
                    <thead>
                        <tr>
                            <th>Libellé</th>
                            <th>Date commande</th>
                            <th>Quantité</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of defaultRow1">
                            <td>Commande 1</td>
                            <td><span class="badge bg-neutral-warning text-warning">31/07/2021</span></td>
                            <td><span class="text-first">86</span></td>
                            <td class="text-center">
                                <button class="btn btn-pill btn-primary d-40 p-0 mr-2" placement="auto" tooltip="Plus d'infos" type="button" (click)="detailCommandeModal.show()">
                                    <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                </button>
                                <button class="btn btn-pill btn-warning d-40 p-0 mr-2" placement="auto" tooltip="Imprimer" type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                                </button>
                                <button class="btn btn-pill btn-danger d-40 p-0" placement="auto"  tooltip="Supprimer" type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="2">TOTAL:</th>
                            <th colspan="2">86</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <div class="card-footer p-3">
            <div class="d-flex justify-content-between">
                <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                <div class="d-flex align-items-center">
                    <span>Show</span>
                    <select class="mx-1 form-control form-control-sm" id="" name="">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="40">50</option>
                        <option value="40">100</option>
                    </select>
                    <span>entries</span>
                </div>
            </div>
        </div>
    </div>
    <div class="card card-box border-0 mt-3" *ngIf="commandStatus == 'valider'">
        <div class="card-header p-3">
            <div class="card-header--title">
                <h6 class="font-weight-bold m-0">Liste des commandes validée</h6>
            </div>
            <div class="card-header--actions">
                <div class="btn-group" dropdown>
                    <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret mr-2"
                        dropdownToggle
                        tooltip="Action"
                        type="button">
                        <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                        <ul class="nav nav-pills flex-column p-3">
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="d-flex justify-content-between p-3">
                <div class="search-wrapper">
                  <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                  <input class="form-control" placeholder="Recherche dans la liste" type="search">
                </div>
            </div>
            <div class="table-responsive m-0">
                <table class="table table-hover text-nowrap m-0">
                    <thead>
                        <tr>
                            <th>Libellé</th>
                            <th>Date commande</th>
                            <th>Date validation</th>
                            <th>Quantité</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of defaultRow1">
                            <td>Commande 1</td>
                            <td><span class="badge bg-neutral-warning text-warning">01/07/2021</span></td>
                            <td><span class="badge bg-neutral-warning text-warning">31/07/2021</span></td>
                            <td><span class="text-first">86</span></td>
                            <td class="text-center">
                                <button class="btn btn-pill btn-primary d-40 p-0 mr-2" placement="auto" tooltip="Plus d'infos" type="button" (click)="detailCommandeModal.show()">
                                    <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                </button>
                                <button class="btn btn-pill btn-warning d-40 p-0 mr-2" placement="auto" tooltip="Imprimer" type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                                </button>
                                <button class="btn btn-pill btn-danger d-40 p-0" placement="auto"  tooltip="Supprimer" type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="3">TOTAL:</th>
                            <th colspan="2">86</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <div class="card-footer p-3">
            <div class="d-flex justify-content-between">
                <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                <div class="d-flex align-items-center">
                    <span>Show</span>
                    <select class="mx-1 form-control form-control-sm" id="" name="">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="40">50</option>
                        <option value="40">100</option>
                    </select>
                    <span>entries</span>
                </div>
            </div>
        </div>
    </div>
    <div class="card card-box border-0 mt-3" *ngIf="commandStatus == 'a livrer'">
        <div class="card-header p-3">
            <div class="card-header--title">
                <h6 class="font-weight-bold m-0">Liste des commandes à livrée</h6>
            </div>
            <div class="card-header--actions">
                <div class="btn-group" dropdown>
                    <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret mr-2"
                        dropdownToggle
                        tooltip="Action"
                        type="button">
                        <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                        <ul class="nav nav-pills flex-column p-3">
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="d-flex justify-content-between p-3">
                <div class="search-wrapper">
                  <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                  <input class="form-control" placeholder="Recherche dans la liste" type="search">
                </div>
            </div>
            <div class="table-responsive m-0">
                <table class="table table-hover text-nowrap m-0">
                    <thead>
                        <tr>
                            <th>Libellé</th>
                            <th>Date commande</th>
                            <th>Quantité</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of defaultRow1">
                            <td>Commande 1</td>
                            <td><span class="badge bg-neutral-warning text-warning">31/07/2021</span></td>
                            <td><span class="text-first">86</span></td>
                            <td class="text-center">
                                <button class="btn btn-pill btn-primary d-40 p-0 mr-2" placement="auto" tooltip="Plus d'infos" type="button" (click)="detailCommandeModal.show()">
                                    <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                </button>
                                <button class="btn btn-pill btn-warning d-40 p-0 mr-2" placement="auto" tooltip="Imprimer" type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                                </button>
                                <button class="btn btn-pill btn-danger d-40 p-0" placement="auto"  tooltip="Supprimer" type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="2">TOTAL:</th>
                            <th colspan="2">86</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <div class="card-footer p-3">
            <div class="d-flex justify-content-between">
                <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                <div class="d-flex align-items-center">
                    <span>Show</span>
                    <select class="mx-1 form-control form-control-sm" id="" name="">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="40">50</option>
                        <option value="40">100</option>
                    </select>
                    <span>entries</span>
                </div>
            </div>
        </div>
    </div>
    <div class="card card-box border-0 mt-3" *ngIf="commandStatus == 'livrer'">
        <div class="card-header p-3">
            <div class="card-header--title">
                <h6 class="font-weight-bold m-0">Liste des commandes livrée</h6>
            </div>
            <div class="card-header--actions">
                <div class="btn-group" dropdown>
                    <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret mr-2"
                        dropdownToggle
                        tooltip="Action"
                        type="button">
                        <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                        <ul class="nav nav-pills flex-column p-3">
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="d-flex justify-content-between p-3">
                <div class="search-wrapper">
                  <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                  <input class="form-control" placeholder="Recherche dans la liste" type="search">
                </div>
            </div>
            <div class="table-responsive m-0">
                <table class="table table-hover text-nowrap m-0">
                    <thead>
                        <tr>
                            <th>Libellé</th>
                            <th>Date commande</th>
                            <th>Date validation</th>
                            <th>Quantité</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of defaultRow1">
                            <td>Commande 1</td>
                            <td><span class="badge bg-neutral-warning text-warning">01/07/2021</span></td>
                            <td><span class="badge bg-neutral-warning text-warning">31/07/2021</span></td>
                            <td><span class="text-first">86</span></td>
                            <td class="text-center">
                                <button class="btn btn-pill btn-primary d-40 p-0 mr-2" placement="auto" tooltip="Plus d'infos" type="button" (click)="detailCommandeModal.show()">
                                    <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                </button>
                                <button class="btn btn-pill btn-warning d-40 p-0 mr-2" placement="auto" tooltip="Imprimer" type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                                </button>
                                <button class="btn btn-pill btn-danger d-40 p-0" placement="auto"  tooltip="Supprimer" type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="3">TOTAL:</th>
                            <th colspan="2">86</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <div class="card-footer p-3">
            <div class="d-flex justify-content-between">
                <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                <div class="d-flex align-items-center">
                    <span>Show</span>
                    <select class="mx-1 form-control form-control-sm" id="" name="">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="40">50</option>
                        <option value="40">100</option>
                    </select>
                    <span>entries</span>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal detail commandes -->
<div #detailCommandeModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Détail de la commande ...</h6>
                <button type="button" class="close" aria-label="Close" (click)="detailCommandeModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-3">
                <div class="border rounded p-3">
                    <div class="row my-2">
                        <div class="col-lg-6">
                            <div class="invoice-logo"><img width="100" src="assets/img/av2.png" alt="Kouassi ghislain"></div>
                        </div>
                        <div class="col-lg-6">
                            <div class="invoice-from">
                                <ul class="list-unstyled text-right">
                                    <li>Commande: CMD0001</li>
                                    <li>Commande de fil electrique le 10/10/2021</li>
                                    <li>Fournisseur: PROTIC</li>
                                    <li>Contact: 00 00 00 00 00</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="border rounded-sm bg-light p-3">
                                <div class="well">
                                    <ul class="list-unstyled mb-0">
                                        <li><strong>Facture </strong> #936988</li>
                                        <li><strong>Date de facturation:</strong>  lundi 20 octobre 2021</li>
                                        <li><strong>Date limite:</strong>  jeudi 1er décembre 2021</li>
                                        <li><strong>Status:</strong> <span class="badge badge-danger">IMPAYER</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="invoice-to mt25">
                                <ul class="list-unstyled">
                                    <li><strong>Facturé à</strong></li>
                                    <li>Kouassi ghislain</li>
                                    <li>Angré Terminus 81/82</li>
                                    <li>Cocody (Abidjan)</li>
                                    <li>Côte d'Ivoire</li>
                                </ul>
                            </div>
                            <div class="table-responsive" style="overflow: hidden; outline: none;" tabindex="0">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="per70 text-center">Description</th>
                                            <th class="per5 text-center">Qté</th>
                                            <th class="per5 text-center">Prix unitaire</th>
                                            <th class="per5 text-center">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Contact electrique</td>
                                            <td class="text-center">1</td>
                                            <td class="text-center">1000 FCFA</td>
                                            <td class="text-center">1000 FCFA</td>
                                        </tr>
                                        <tr>
                                            <td>ballasts</td>
                                            <td class="text-center">4</td>
                                            <td class="text-center">1000 FCFA</td>
                                            <td class="text-center">4000 FCFA</td>
                                        </tr>
                                        <tr>
                                            <td>Fil electrique</td>
                                            <td class="text-center">12</td>
                                            <td class="text-center">5000 FCFA</td>
                                            <td class="text-center">60 000 FCFA</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colspan="3" class="text-right">Montant HT:</th>
                                            <th class="text-center">65 000 FCFA</th>
                                        </tr>
                                        <tr>
                                            <th colspan="3" class="text-right">TVA 18%:</th>
                                            <th class="text-center">11 700 FCFA</th>
                                        </tr>
                                        <tr>
                                            <th colspan="3" class="text-right">Rémise 5%:</th>
                                            <th class="text-center">3 250 FCFA</th>
                                        </tr>
                                        <tr>
                                            <th colspan="3" class="text-right">Montant TTC:</th>
                                            <th class="text-center text-first">73 450 FCFA</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer px-3 py-2">
                <div class="text-right">
                    <button class="btn btn-success mr-2" type="button"><i class="fas fa-check mr-1"></i>Valider</button>
                    <button class="btn btn-warning mr-2" type="button"><i class="ion-md-print mr-1"></i>Imprimer</button>
                    <button class="btn btn-danger" type="button"><i class="ion-md-trash mr-1"></i>Supprimer</button>
                </div>
            </div>
          </div>
      </div>
</div>