<div *ngIf="defaultRow1.length > 0">
    <div class="card card-box border-0 mt-2">
        <div class="card-header p-3">
            <div class="card-header--title font-weight-bold">Liste des reservers travaux ( <span class="text-black-50">{{defaultRow1.length}}</span> )</div>
            <div class="card-header--actions">
                <div class="card-header--actions">
                    <div class="btn-group" dropdown>
                        <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                            dropdownToggle
                            tooltip="Action"
                            type="button">
                            <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                        </button>
                        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                            <ul class="nav nav-pills flex-column p-3">
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="row p-3">
                <div class="col-md-3">
                    <select required name="" id="projet" class="form-control">
                        <option value="" disabled selected hidden>Selectionner le projet</option>
                        <option value="">Projet 1</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <select required name="" id="chantier" class="form-control">
                        <option value="" disabled selected hidden>Selectionner un chantier</option>
                        <option value="">Chantier</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <select required name="" id="chantier" class="form-control">
                        <option value="" disabled selected hidden>Selectionner un travail</option>
                        <option value="">Travaux 1</option>
                    </select>
                </div>
                <div class="col-md-3">
                    <select required name="" id="chantier" class="form-control">
                        <option value="" disabled selected hidden>Selectionner un etat</option>
                        <option value="en cours">Travaux en cours</option>
                        <option value="en attente CP">En attente de validation chef projet</option>
                        <option value="En attente CC">En attente de validation chef chantier</option>
                        <option value="valider">Valider</option>
                    </select>
                </div>
            </div>
            <hr class="m-0">
            <div class="d-flex justify-content-between p-3">
                <div class="search-wrapper">
                  <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                  <input class="form-control" placeholder="Recherche dans la liste" type="search">
                </div>
                <button class="btn btn-success" tooltip="Ajouter" (click)="newReserveModal.show()"><i class="fas fa-plus-circle mr-1"></i> Ajouter</button>
            </div>
            <div class="px-3 pb-3">
                <div class="table-responsive m-0">
                    <table class="table table-hover text-nowrap m-0">
                        <thead class="thead-light">
                            <tr>
                                <th>Reserve</th>
                                <th>Date de la visite</th>
                                <th>Budget</th>
                                <th>Date fin</th>
                                <th>Status</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of defaultRow1">
                                <td>Reservers</td>
                                <td>10/07/2021</td>
                                <td>500 000<span class="text-black-50 ml-2">XOF</span></td>
                                <td>10/07/2021</td>
                                <td><span class="badge bg-neutral-success text-success">Traiter</span></td>
                                <td class="text-center">
                                    <button class="btn d-40 p-0 btn-pill mr-2 btn-primary" type="button" tooltip="Plus d'infos" placement="auto" [routerLink]="['/pages/reserve/detail/1']">
                                        <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                    </button>
                                    <button class="btn d-40 p-0 btn-pill mr-2 btn-info" type="button" tooltip="Modifier" placement="auto">
                                        <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                                    </button>
                                    <button class="btn d-40 p-0 btn-pill mr-2 btn-warning" type="button" tooltip="Imprimer" placement="auto">
                                        <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                                    </button>
                                    <button class="btn d-40 p-0 btn-pill btn-danger" type="button" tooltip="Supprimer" placement="auto">
                                        <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colspan="3">TOTAL</th>
                                <th colspan="4">1 000 000<span class="text-black-50 ml-2">XOF</span></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
        <div class="card-footer p-3">
            <div class="d-flex justify-content-between">
                <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                <div class="d-flex align-items-center">
                    <span>Show</span>
                    <select class="mx-1 form-control form-control-sm" id="" name="">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="40">50</option>
                        <option value="40">100</option>
                    </select>
                    <span>entries</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="defaultRow1.length == 0">
    <div class="hero-wrapper bg-composed-wrapper bg-white rounded">
        <div class="flex-grow-1 w-100 d-flex align-items-center">
            <div class="bg-composed-wrapper--image bg-composed-filter-rm opacity-4 rounded" style="background-image: url('assets/images/hero-bg/hero-2.jpg');"></div>
            <div class="bg-composed-wrapper--content py-5">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-xl-6">
                            <div class="text-black mt-3">
                                <h1 class="display-3 mb-3 font-weight-bold">
                                    Aucune reserve de travaux
                                </h1>
                                <div class="divider border-2 border-dark my-5 border-light opacity-2 rounded-circle w-25"></div>
                                <p class="font-size-lg text-black-50">
                                    Aucun travail reservé.
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-6 px-0 d-none d-xl-flex align-items-center">
                            <img src="assets/img/reserve.png" class="mx-auto d-block img-fluid" alt="..." style="height: 200px; width: 200px">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal d'ajout de version -->
<div #newReserveModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Ajouter une reserve de travaux</h6>
                <button type="button" class="close" aria-label="Close" (click)="newReserveModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <app-reserve-edit></app-reserve-edit>
            </div>
        </div>
    </div>
</div>