import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-travaux-pages',
  templateUrl: './travaux-pages.component.html',
  styleUrls: ['./travaux-pages.component.scss']
})
export class TravauxPagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
