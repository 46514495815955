<div class="row">
    <div class="col-sm-12">
        <div [class]=" 'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground"
        [ngClass]="{
       'app-page-title--shadow' : globals.pageTitleShadow
       }">
       <div>
           <div class="app-page-title--first">
               <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                   <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                       <i class="pe-7s-wallet display-3 gradient-icon bg-ripe-malin"></i>
                   </div>
               </div>
               <div class="app-page-title--heading">
                   <h1>Etude de prix</h1>
                   <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">
                       Projet de construction villa R+6
                   </div>
               </div>
           </div>
       </div>
   </div>

   <div class="col-sm-12" >
    <aw-wizard class="wizard">
        <aw-wizard-step stepTitle="Lots de travaux" [navigationSymbol]="{ symbol: '1' }">
            <div class="p-4">
              <h5 class="font-size-xl font-weight-bold">Détails étude de prix</h5>
              <!--<p class="text-black-50 mb-4">Try the keyboard navigation by clicking arrow left or right!</p>-->

                <div class="table-responsive">
                  <ng2-smart-table class="table text-nowrap table-bordered" [settings]="settingsLotTrx" [source]="dataLotTrx"></ng2-smart-table>
                </div>

            </div>
            <div class="p-2"  style="text-align: right !important;">
                <button awNextStep class="btn btn-primary" type="button">Continuer</button>
            </div>
        </aw-wizard-step>
        <aw-wizard-step awOptionalStep stepTitle="Détails travaux" [navigationSymbol]="{ symbol: '2' }">
            <div class="p-4">
                <h5 class="font-size-xl font-weight-bold">Lots des travaux</h5>
                <div class="row">
                    <div class="col-sm-12">
                      <tabset type="tabs" class="nav-tabs-danger mb-5" >
                          <tab class="mt-2" >
                              <ng-template tabHeading>
                                  Travaux de Genie-Civil
                              </ng-template>
                              <div class="table-responsive">
                                  <ng2-smart-table class="table text-nowrap table-bordered" [settings]="settingsData1" [source]="data1"></ng2-smart-table>
                              </div>
                          </tab>
                          <tab class="mt-2" >
                              <ng-template tabHeading>
                                  Equipement HTA du Poste Extérieur
                              </ng-template>
                              <div class="table-responsive">
                                  <ng2-smart-table class="table text-nowrap table-bordered" [settings]="settingsData1" [source]="data2"></ng2-smart-table>
                              </div>
                          </tab>
                          <tab class="mt-2" >
                              <ng-template tabHeading>
                                  Equipement HTA du Poste Intérieur
                              </ng-template>
                              <div class="table-responsive">
                                  <ng2-smart-table class="table text-nowrap table-bordered" [settings]="settingsData1" [source]="data1"></ng2-smart-table>
                              </div>
                          </tab>
                      </tabset>
                    </div>
                    <div class="col-sm-12 actions px-4 pb-4">
                        <button awPreviousStep class="btn btn-primary" type="button">Précédent</button>
                        <button awNextStep class="btn btn-success" type="button">Enregistrer</button>
                    </div>
                </div>

            </div>

        </aw-wizard-step>

    </aw-wizard>
   </div>
</div>