import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChantierDetailComponent } from './chantier-detail/chantier-detail.component';
import { ChantierListComponent } from './chantier-list/chantier-list.component';
import { ChantierPagesComponent } from './chantier-pages.component';


const routes: Routes = [
  {
    path:'',
    component: ChantierPagesComponent,
    children: [
      {
        path: "liste",
        component: ChantierListComponent
      },
      {
        path: "detail/:id",
        component: ChantierDetailComponent
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChantierPagesRoutingModule { }
