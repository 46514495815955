import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { ModalDirective } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UtilOptions } from 'src/app/shared-components/util/util-option';
import { CategorieRessourceService } from '../../categorie-ressource/categorie-ressource.service';
import { CategorieRessource } from '../../categorie-ressource/categorie-ressoure.model';
import { Famille } from '../../famille/famille.model';
import { FamilleService } from '../../famille/famille.service';
import { SousFamille } from '../../famille/sous-famille.model';
import { NatureRessource } from '../../nature-ressource/nature-ressource.model';
import { NatureRessourceService } from '../../nature-ressource/nature-ressource.service';
import { TypeRessource } from '../../type-ressource/type-ressource.model';
import { TypeRessourceService } from '../../type-ressource/type-ressource.service';
import { UniteRessource } from '../../unite-ressource/unite-ressource.model';
import { UniteRessourceService } from '../../unite-ressource/unite-ressource.service';
import { RessourceService } from '../ressource.service';

@Component({
  selector: 'app-ressource-edit',
  templateUrl: './ressource-edit.component.html',
  styleUrls: ['./ressource-edit.component.scss']
})
export class RessourceEditComponent implements OnInit, OnDestroy {
  @ViewChild('newCategorieModal', { static: false }) public newCategorieModal: ModalDirective;
  @ViewChild('newNatureModal', { static: false }) public newNatureModal: ModalDirective;
  @ViewChild('newTvaModal', { static: false }) public newTvaModal: ModalDirective;
  @ViewChild('newUniteModal', { static: false }) public newUniteModal: ModalDirective;
  @ViewChild('newSousFamilleModal', { static: false }) public newSousFamilleModal: ModalDirective;
  @ViewChild('newFamilleModal', { static: false }) public newFamilleModal: ModalDirective;
  @ViewChild('newTypeRessourceModal', { static: false }) public newTypeRessourceModal: ModalDirective;


  ressoureForm: FormGroup;
  typeRessourceForm: FormGroup;
  familleForm: FormGroup;
  sousFamilleForm: FormGroup;
  uniteForm: FormGroup;
  tvaForm: FormGroup;
  natureForm: FormGroup;
  categorieForm: FormGroup;
  submit: boolean = false;
  inputGroupe: boolean = true;
  categorieRow = UtilOptions.typeRessources;
  required = UtilOptions.required;

  categorieRessourceSubs = new Subscription;
  categorieRessourcesSubs = new Subscription;
  familleSubs = new Subscription;
  famillesSubs = new Subscription;
  listCategorieRessource: CategorieRessource[] = [];
  listFamille: Famille[] = [];
  typeRessourceSubs = new Subscription;
  typeRessourcesSubs = new Subscription;
  listTypeRessource: TypeRessource[] = [];
  natureRessourceSubs = new Subscription;
  natureRessourcesSubs = new Subscription;
  listNatureRessource: NatureRessource[] = [];
  uniteRessourceSubs = new Subscription;
  uniteRessourcesSubs = new Subscription;
  listUniteRessource: UniteRessource[] = [];

  sousFamilleSubs = new Subscription;
  sousFamillesSubs = new Subscription;
  listSousFamille: SousFamille[] = [];
  listSousFamilleByFamille = [];
  ressourceDetails: any;
  editMode: boolean = false;
  public maskPercent: Array<string | RegExp>;

  

  typeRow = [
    { label: 'Ressource de base', value: 'ressource de base' },
    { label: 'Ressource composite', value: 'ressource composite' }
  ];
  venteRow = [
    { label: 'En vente', value: '1' },
    { label: 'Hors vente', value: '2' }
  ];
  achatRow = [
    { label: 'En achat', value: '1' },
    { label: 'Hors achat', value: '2' }
  ];

  //Editeur
  htmlContent = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  notif: NotifierService;

  constructor(
    private formBuild: FormBuilder,
    public modal: NgbActiveModal,
    private notifier: NotifierService,
    private categorieRessourceService: CategorieRessourceService,
    private familleService: FamilleService,
    private typeRessourceServie: TypeRessourceService,
    private natureRessourceServie: NatureRessourceService,
    private uniteRessourceServie: UniteRessourceService,
    private ressourceService: RessourceService,
    private spinner: NgxSpinnerService,
  ) {
    this.maskPercent = [ /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
    this.notif = notifier;
   
    // this.initRessourceForm();

    this.typeRessourceFormGroup();
    this.familleFormGroup();
    this.sousFamilleFormGroup();
    this.uniteFormGroup();
    this.tvaFormGroup();
    this.natureFormGroup();
    this.categorieFormGroup();
  }

  ngOnInit() {
    // this.showNotification('danger', "L'opération a échoué");
    this.__onGetData();
  }
  ngOnDestroy(): void {
    this.categorieRessourceSubs.unsubscribe();
    this.categorieRessourcesSubs.unsubscribe();
    this.familleSubs.unsubscribe();
    this.famillesSubs.unsubscribe();
    this.typeRessourceSubs.unsubscribe();
    this.typeRessourcesSubs.unsubscribe();
    this.natureRessourceSubs.unsubscribe();
    this.natureRessourcesSubs.unsubscribe();
    this.uniteRessourceSubs.unsubscribe();
    this.uniteRessourcesSubs.unsubscribe();
    this.sousFamilleSubs.unsubscribe();
    this.sousFamillesSubs.unsubscribe();
    this.ressourceService.setDataForRessource('');
  }

  initRessourceForm() {
    this.ressoureForm = this.formBuild.group({
      ref: [''],
      categorie: [null, [Validators.required]],
      type: [null, [Validators.required]],
      famille: [null, [Validators.required]],
      sousFamille: [null, [Validators.required]],
      libelle: [null, [Validators.required]],
      unite: [null, [Validators.required]],
      etatVente: [null, [Validators.required]],
      etatAchat: [null, [Validators.required]],
      description: [null, [Validators.required]],
      nature: [null, [Validators.required]],
      stockAlert: [null, [Validators.required]],
      prix: [null, [Validators.required]],
      majoration: [null, [Validators.required]],
      perte: [null, [Validators.required]],
      prixHT: [null, [Validators.required]],
      tva: [null, [Validators.required]],
      prixTTC: [null, [Validators.required]],
      stockPhys: [null, [Validators.required]],
    });
  }

  typeRessourceFormGroup() {
    this.typeRessourceForm = this.formBuild.group({
      type: [null, [Validators.required]]
    });
  }
  familleFormGroup() {
    this.familleForm = this.formBuild.group({
      famille: [null, [Validators.required]]
    });
  }
  sousFamilleFormGroup() {
    this.sousFamilleForm = this.formBuild.group({
      famille: [null, [Validators.required]],
      sousFamille: [null, [Validators.required]]
    });
  }
  uniteFormGroup() {
    this.uniteForm = this.formBuild.group({
      unite: [null, [Validators.required]]
    });
  }
  tvaFormGroup() {
    this.tvaForm = this.formBuild.group({
      tva: [null, [Validators.required]]
    });
  }
  natureFormGroup() {
    this.natureForm = this.formBuild.group({
      nature: [null, [Validators.required]]
    });
  }
  categorieFormGroup() {
    this.categorieForm = this.formBuild.group({
      libelle: [null, [Validators.required]]
    });
  }
  get f() { return this.ressoureForm.controls; }
  get typeF() { return this.typeRessourceForm.controls; }
  get familleF() { return this.familleForm.controls; }
  get sousFamilleF() { return this.sousFamilleForm.controls; }
  get uniteF() { return this.uniteForm.controls; }
  get tvaF() { return this.tvaForm.controls; }
  get natureF() { return this.natureForm.controls; }
  get categorieF() { return this.categorieForm.controls; }



  addUnite() { }



  loadCategorieRessource() {
    this.categorieRessourcesSubs = this.categorieRessourceService.listCategorieRessource().subscribe(
      (resData) => {
        this.listCategorieRessource = resData;
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }

  addCategorie() {
    this.categorieRessourceSubs = this.categorieRessourceService.addCategorieRessource(
      {
        libelle: this.categorieForm.value.libelle,
      }
    ).subscribe(
      (resData: CategorieRessource) => {
        this.closeCategorieRessourceModal();
        this.listCategorieRessource = [];
        this.categorieForm.reset();
        this.loadCategorieRessource();

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }

  loadFamille() {
    this.famillesSubs = this.familleService.listFamille().subscribe(
      (resData) => {
        this.listFamille = resData;
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }

  addFamille() {
    this.familleSubs = this.familleService.addFamille(
      {
        libelle: this.familleForm.value.famille,
      }
    ).subscribe(
      (resData: Famille) => {
        this.closeFamilleModal();
        this.listFamille = [];
        this.familleForm.reset();
        this.loadFamille();

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }

  loadTypeRessoure() {
    this.typeRessourcesSubs = this.typeRessourceServie.listTypeRessource().subscribe(
      (resData) => {
        this.listTypeRessource = resData;
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
  addTypeRessource() {
    this.typeRessourceSubs = this.typeRessourceServie.addTypeRessource(
      {
        libelle: this.typeRessourceForm.value.type,
      }
    ).subscribe(
      (resData: TypeRessource) => {
        this.closeTypeRessourceModal();
        this.listTypeRessource = [];
        this.typeRessourceForm.reset();
        this.loadTypeRessoure();

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }

  loadNatureRessoure() {
    this.natureRessourcesSubs = this.natureRessourceServie.listNatureRessource().subscribe(
      (resData) => {
        this.listNatureRessource = resData;
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }

  addNatureRessource() {
    this.natureRessourceSubs = this.natureRessourceServie.addNatureRessource(
      {
        libelle: this.natureForm.value.nature,
      }
    ).subscribe(
      (resData: NatureRessource) => {
        this.closeNatureModal();
        this.listNatureRessource = [];
        this.natureForm.reset();
        this.loadNatureRessoure();

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }

  loadUniteRessoure() {
    this.natureRessourcesSubs = this.uniteRessourceServie.listUniteRessource().subscribe(
      (resData) => {
        this.listUniteRessource = resData;
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }

  addUniteRessource() {
    this.uniteRessourceSubs = this.uniteRessourceServie.addUniteRessource(
      {
        libelle: this.uniteForm.value.unite,
      }
    ).subscribe(
      (resData: UniteRessource) => {
        this.closeUniteModal();
        this.listUniteRessource = [];
        this.uniteForm.reset();
        this.loadUniteRessoure();

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }

  loadSousFamille(data?) {
    this.sousFamillesSubs = this.familleService.listSousFamille().subscribe(
      (resData) => {
       if(resData) {
        this.listSousFamille = resData;
        if (data) {
            this.filterSousFamilleByFamille(this.listSousFamille, data);
        }
        console.log('this.listSousFamille', this.listSousFamille);
        
        return this.listSousFamille;
       }
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }

  addSousFamille() {
    this.sousFamilleSubs = this.familleService.addSousFamille(
      {
        libelle: this.sousFamilleForm.value.sousFamille,
        famille: this.sousFamilleForm.value.famille,
      }
    ).subscribe(
      (resData) => {
        this.closeSousFamilleModal();
        this.listSousFamille = [];
        this.sousFamilleForm.reset();
        this.loadSousFamille();

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }


  onClose(): any {
    this.modal.close('ferme');
  }

  onSubmit() {
    this.spinner.show("sp5");
    if (this.editMode) {
      const data = {
        reference: this.ressoureForm.value.ref,
        categorieRessource: this.ressoureForm.get('categorie').value,
        typeRessource: this.ressoureForm.get('type').value,
        sousFamille: this.ressoureForm.get('sousFamille').value,
        libelle: this.ressoureForm.value.libelle,
        unite: this.ressoureForm.value.unite,
        etatVente: parseInt(this.ressoureForm.value.etatVente, 10),
        etatAchat: parseInt(this.ressoureForm.value.etatAchat, 10),
        description: this.ressoureForm.value.description,
        nature: this.ressoureForm.value.nature,
        limiteStock: parseFloat(this.ressoureForm.value.stockAlert),
        physiqueStock: parseFloat(this.ressoureForm.value.stockPhys),
        prixMoyen: parseFloat(this.ressoureForm.value.prix),
        coefficientMajoration: parseFloat(this.ressoureForm.value.majoration),
        coefficientPerte: parseFloat(this.ressoureForm.value.perte),
        prixVenteHT: parseFloat(this.ressoureForm.value.prixHT),
        tva: parseFloat(this.ressoureForm.value.tva),
       };
       this.ressourceService.updateRessource(this.ressourceDetails.ressource.id,data).subscribe(
         resData => {
           if(resData) {
             this.spinner.hide("sp5");
           //  this.notifier.notify('success', 'You are awesome! I mean it!', 'THAT_NOTIFICATION_ID');
            // this.showNotification('success', "L'opération s'est bien déroulé");
           this.modal.close('update');
           }
           // this.showNotification('success', "L'opération s'est bien déroulé");
           console.log(resData);
           // if(resData)
          
          
         },
         errorRes => {
           this.spinner.hide("sp5");
           console.log(errorRes, "erreur")
           this.showNotification('danger', "L'opération a échoué");
         }
       );
     }
     else {
       const data = {
        reference: this.ressoureForm.value.ref,
        categorieRessource: this.ressoureForm.value.categorie,
        typeRessource: this.ressoureForm.value.type,
        sousFamille: this.ressoureForm.value.sousFamille,
        libelle: this.ressoureForm.value.libelle,
        unite: this.ressoureForm.value.unite,
        etatVente: parseInt(this.ressoureForm.value.etatVente, 10),
        etatAchat: parseInt(this.ressoureForm.value.etatAchat, 10),
        description: this.ressoureForm.value.description,
        nature: this.ressoureForm.value.nature,
        limiteStock: parseFloat(this.ressoureForm.value.stockAlert),
        physiqueStock: parseFloat(this.ressoureForm.value.stockPhys),
        prixMoyen: parseFloat(this.ressoureForm.value.prix),
        coefficientMajoration: parseFloat(this.ressoureForm.value.majoration),
        coefficientPerte: parseFloat(this.ressoureForm.value.perte),
        prixVenteHT: parseFloat(this.ressoureForm.value.prixHT),
        tva: parseFloat(this.ressoureForm.value.tva),
       }
       this.ressourceService.addRessource(data).subscribe(
         resData => {
           if(resData) {
             this.spinner.hide("sp5");
           //  this.notifier.notify('success', 'You are awesome! I mean it!', 'THAT_NOTIFICATION_ID');
            // this.showNotification('success', "L'opération s'est bien déroulé");
           this.modal.close('success');
           }
           // this.showNotification('success', "L'opération s'est bien déroulé");
           console.log(resData);
           // if(resData)
          
          
         },
         errorRes => {
           this.spinner.hide("sp5");
           console.log(errorRes, "erreur")
           this.showNotification('danger', "L'opération a échoué");
         }
       );
     }

    // this.onCancel();

  }

  showNotification(type: string, message: string): void {
    this.notif.notify(type, message);
  }
  closeCategorieRessourceModal() {
    this.newCategorieModal.hide();
    this.categorieForm.reset();
  }
  closeTypeRessourceModal() {
    this.newTypeRessourceModal.hide();
    this.typeRessourceForm.reset();
  }
  closeFamilleModal() {
    this.newFamilleModal.hide();
    this.familleForm.reset();
  }
  closeSousFamilleModal() {
    this.newSousFamilleModal.hide();
    this.sousFamilleForm.reset();
  }
  closeUniteModal() {
    this.newUniteModal.hide();
    this.uniteForm.reset();
  }
  closeTvaModal() {
    this.newTvaModal.hide();
  }
  closeNatureModal() {
    this.newNatureModal.hide();
    this.natureForm.reset();
  }
  selectFamille(event) {

    if (this.editMode == false) {
      const familleSelect = this.ressoureForm.get('famille').value;
      const sousFamille = this.listSousFamille.filter(result => result.famille['@id'] == familleSelect);
      this.listSousFamilleByFamille = sousFamille;
    } else {
      const familleSelect = this.ressoureForm.get('famille').value; 
      if (familleSelect != this.ressourceDetails.ressource.sousFamille.famille['@id']) {
        this.ressoureForm.patchValue({
          sousFamille: null,
        })
      } else {
        this.ressoureForm.patchValue({
          sousFamille: this.ressourceDetails.ressource.sousFamille.famille['@id'],
        })
      }
      const sousFamille = this.listSousFamille.filter(result => result.famille['@id'] == familleSelect);
      this.listSousFamilleByFamille = sousFamille;
      
    }
   
  }

  __onGetData() {
    this.ressourceDetails = this.ressourceService.getDataForRessource();
    
    this.loadCategorieRessource();
    this.loadFamille();
    this.loadTypeRessoure();
    this.loadNatureRessoure();
    this.loadUniteRessoure();
    if(this.ressourceDetails) {
      this.loadSousFamille(this.ressourceDetails.ressource);
    } else {
      this.loadSousFamille();
    }
   
    this.initRessourceForm();
   
     if(this.ressourceDetails) {
       this.editMode = this.ressourceDetails.type;
     } else {
       this.editMode = false;
     }
     if(this.editMode == true && this.ressourceDetails) {
      this.pathValueForm(this.ressourceDetails.ressource);
     } else {
       this.initRessourceForm();
     }
  }

  pathValueForm(data) {
    this.spinner.show("sp5");
    console.log('this.listSousFamille path', this.listSousFamille);
    this.initRessourceForm();
    this.ressoureForm.patchValue({
      ref: data.reference,
      categorie: data.categorieRessource['@id'],
      type: data.typeRessource['@id'],
      famille: data.sousFamille.famille['@id'],
      sousFamille: data.sousFamille['@id'],
      libelle: data.libelle,
      unite: data.unite['@id'],
      etatVente: data.etatVente,
      etatAchat: data.etatVente,
      description: data.description,
      nature: data.nature['@id'],
      stockAlert: data.limiteStock,
      stockPhys: data.physiqueStock,
      prix: data.prixMoyen,
      majoration: data.coefficientMajoration,
      perte: data.coefficientPerte,
      prixHT: data.prixVenteHT,
      tva: data.tva,
      // prixTTC: [null, [Validators.required]]
    });
  }
  __calculTva(tva, prixHT) {
      return prixHT + (prixHT )
  }

  filterSousFamilleByFamille(sousFamilleList: SousFamille[], ressourceSelected) {
    const listSousFamilleByFamilleFilter =  sousFamilleList.filter(x => x.famille['@id'] == ressourceSelected.sousFamille.famille['@id'] )
    this.listSousFamilleByFamille = listSousFamilleByFamilleFilter;
    this.listSousFamilleByFamille.forEach(result => console.log('result', result));
    this.spinner.hide("sp5");
  }

}
