import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ChantierPages } from 'src/app/models/chantier-pages';

@Injectable({
  providedIn: 'root'
})
export class ChantierPagesService {
  chantier: ChantierPages;
  private readonly url = "api/chantiers.json";

  constructor(
    private http: HttpClient
  ) { }

  setChantier(chantier: ChantierPages) {
    this.chantier = chantier
  }

  getChantier(): ChantierPages {
    return this.chantier
  }

  getList(): Observable<ChantierPages[]> {
    return this.http.get<ChantierPages[]>(this.url).pipe();
  }
}
