import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilOptions } from 'src/app/shared-components/util/util-option';

@Component({
  selector: 'app-bulletin-hse-edit',
  templateUrl: './bulletin-hse-edit.component.html',
  styleUrls: ['./bulletin-hse-edit.component.scss']
})
export class BulletinHseEditComponent implements OnInit {
  form: FormGroup;
  submit = false;
  inputGroup = false;
  required = UtilOptions.required;
  graviteRow = UtilOptions.gravite;
  imgUrl: any;
  typeBulletin = [
    { label: "Flash securité", value: "flash securité" },
    { label: "Flash santé", value: "flash santé" },
    { label: "Flash environnement", value: "flash environnement" },
    { label: "Flash qualité", value: "flash qualité" }
  ];

  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.newForm();
    this.imgUrl = [];
  }

  ngOnInit() {
  }

  newForm(){
    this.form = this.formBuilder.group({
      id: [null],
      type: ["Flash securité", [Validators.required]],
      objet: [null, [Validators.required]],
      date: [null, [Validators.required]],
      gravite: [null, [Validators.required]],
      projet: [null, [Validators.required]],
      chantier: [null, [Validators.required]],
      photo: [null, [Validators.required]],
      description: [null, [Validators.required]],
      descriptionAction: [null, [Validators.required]]
    })
  }

  onSubmit(){}
  
  // "onSelectImg" pour la selection des image dans le formulaire et "deleteImg" pour la suppression
  onSelectImg(e){
    if(e.target.files){
      var reader = new FileReader();
      var file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onload=(events:any)=>{
        this.imgUrl.push(events.target.result);
      }
    }
  }
  deleteImg(el) {
    this.imgUrl.forEach((value,index) => {
      if(value == el)
        this.imgUrl.splice(index, 1);
    });
  };
  
  get f() { return this.form.controls; }
}
