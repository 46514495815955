import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-situation-hse-pages',
  templateUrl: './situation-hse-pages.component.html',
  styleUrls: ['./situation-hse-pages.component.scss']
})
export class SituationHsePagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
