import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';

import { MissionPagesRoutingModule } from './mission-pages-routing.module';
import { MissionDashboardComponent } from './mission-dashboard/mission-dashboard.component';
import { MissionDetailComponent } from './mission-detail/mission-detail.component';
import { MissionEditComponent } from './mission-edit/mission-edit.component';
import { MissionListComponent } from './mission-list/mission-list.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule, BsDropdownModule, ModalModule, PaginationModule, TabsModule, TooltipModule } from 'ngx-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TextMaskModule } from 'angular2-text-mask';
import { ArchwizardModule } from 'angular-archwizard';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import LocaleFr from '@angular/common/locales/fr';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MissionPagesComponent } from './mission-pages.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxSpinnerModule } from 'ngx-spinner';
registerLocaleData(LocaleFr, 'fr')

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'uifort',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
     easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
     easing: 'ease'
    },
    overlap: 150
  }
};


@NgModule({
  declarations: [
    MissionPagesComponent,
    MissionDashboardComponent,
    MissionDetailComponent,
    MissionEditComponent,
    MissionListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    TooltipModule,
    HttpClientModule,
    FontAwesomeModule,
    TextMaskModule,
    ArchwizardModule,
    NotifierModule.withConfig(customNotifierOptions),
    BsDatepickerModule,
    PaginationModule,
    AngularEditorModule,
    TabsModule,
    BsDropdownModule,
    MissionPagesRoutingModule,
    NgbModule,
    SweetAlert2Module,
    NgxSpinnerModule
  ],
  exports: [
    MissionDetailComponent,
    MissionEditComponent,
    MissionListComponent
  ],
  entryComponents:[
    MissionEditComponent
  ]
})
export class MissionPagesModule { }
