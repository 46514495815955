import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UtilOptions } from 'src/app/shared-components/util/util-option';
import { Chantier } from '../../chantier/chantier.model';
import { ChantierService } from '../../chantier/chantier.service';
import { MembreMission } from '../membre-mission.model';
import { MissionService } from '../mission.service';

@Component({
  selector: 'app-mission-edit',
  templateUrl: './mission-edit.component.html',
  styleUrls: ['./mission-edit.component.scss']
})
export class MissionEditComponent implements OnInit, OnDestroy {
  formMission: FormGroup;
  required = UtilOptions.required;
  participantData = [];
  participantSubmited: any;
  inputGroup: boolean = false;
  submit: boolean = false;
  inputGroupe: boolean = true;
  missionOnlyForm: FormGroup;
  participantForm: FormGroup;
  dataOnlyMission: any;
  editMode: boolean = false;
  private notif: NotifierService;
  listChantier: Chantier[] = [];
  chantiersSubs = new Subscription;

  constructor(
    private formBuild: FormBuilder,
    private modal: NgbActiveModal,
    private missionService: MissionService,
    private spinner: NgxSpinnerService,
    private notifier: NotifierService,
    private chantierService: ChantierService

  ) {
    this.notif = this.notifier;
    this.participantData = [];
    this.newForm();
  }

  ngOnInit() {
    this.loadChantier();
  }
  ngOnDestroy(): void {
    this.chantiersSubs.unsubscribe();
  }

  newForm() {
    this.formMission = this.formBuild.group({
      missionOnlyForm: this.formBuild.group({
        id: [null],
        motif: [null, [Validators.required]],
        demandeur: [null, [Validators.required]],
        contactDemandeur: [null, [Validators.required]],
        emailDemandeur: [null, [Validators.required]],
        fonctionDemandeur: [null, [Validators.required]],
        itineraire: [null, [Validators.required]],
        chantier: [null, [Validators.required]],
        dateDepart: [null, [Validators.required]],
        dateRetour: [null, [Validators.required]],
        description: [null, [Validators.required]],
      }),

      // Participants
      participantForm: this.formBuild.group({
        nom: [null, [Validators.required]],
        prenom: [null, [Validators.required]],
        contact: [null, [Validators.required]],
        email: [null, [Validators.required]],
        fonction: [null, [Validators.required]]
      })
    });
  }


  submitMissionOnlyForm() {
    const missionForm = this.formMission.controls['missionOnlyForm'].value;
    this.dataOnlyMission = {
      motif: missionForm['motif'],
      demandeur: missionForm['demandeur'],
      contact: missionForm['contactDemandeur'],
      email: missionForm['emailDemandeur'],
      fonction: missionForm['fonctionDemandeur'],
      itineraire: missionForm['itineraire'],
      chantier: missionForm['chantier'],
      dateD: new Date(missionForm['dateDepart']),
      dateR: new Date(missionForm['dateRetour']),
      description: missionForm['description']
    }
  }

  saveParticipant(): void {
    this.participantData.push(this.formMission.controls['participantForm'].value);
    this.formMission.reset();
  }
  submitAllParticipant(idMission) {
    this.participantData.forEach(item => {
      const objMembreMission = new MembreMission;
      objMembreMission.nom = item.nom;
      objMembreMission.prenom = item.prenom;
      objMembreMission.email = item.email;
      objMembreMission.contact = item.contact;
      objMembreMission.fonction = item.fonction;
      objMembreMission.mission = idMission;
      this.addMembreMission(objMembreMission);
    });
  }

  deleteParticipant(element) {
    this.participantData.forEach((value, index) => {
      if (value == element)
        this.participantData.splice(index, 1);
    });
  }

  onSubmit() {
    this.addMissionOnly(this.dataOnlyMission);
  }

  onClose(): any {
    this.modal.close();
  }

  addMembreMission(membre) {
    this.missionService.addMemberByMission(membre).subscribe(
      resData => {
        if (resData) {
          console.log('resData add mission', resData);
          if (this.participantData.indexOf(this.participantData[this.participantData.length - 1]) + 1 == this.participantData.length) {
            this.spinner.hide("spMission");
            this.modal.close('success');
            this.participantData = [];
            this.formMission.reset();
          }
          // this.spinner.hide("spMission");
          //  this.notifier.notify('success', 'You are awesome! I mean it!', 'THAT_NOTIFICATION_ID');
          // this.showNotification('success', "L'opération s'est bien déroulé");
          // this.modal.close('success');
        }
      },
      errorRes => {
        this.spinner.hide("spMission");
        console.log(errorRes, "erreur")
        this.showNotification('danger', "L'opération a échoué");
      }
    );

  }
  addMissionOnly(missionData, idMission?) {

    this.spinner.show("spMission");
    if (this.editMode) {
      this.missionService.updateMission(idMission, missionData).subscribe(
        resData => {
          if (resData) {
            this.spinner.hide("spMission");
            //  this.notifier.notify('success', 'You are awesome! I mean it!', 'THAT_NOTIFICATION_ID');
            // this.showNotification('success', "L'opération s'est bien déroulé");
            this.modal.close('update');
          }
          // this.showNotification('success', "L'opération s'est bien déroulé");
          console.log(resData);
          // if(resData)

        },
        errorRes => {
          this.spinner.hide("spMission");
          console.log(errorRes, "erreur")
          this.showNotification('danger', "L'opération a échoué");
        }
      );
    }
    else {
      this.missionService.addMission(missionData).subscribe(
        resData => {
          if (resData) {
            console.log('resData add mission', resData['@id']);
            if (this.participantData.length != 0) {
              this.submitAllParticipant(resData['@id']);
            }
          }
        },
        errorRes => {
          this.spinner.hide("spMission");
          console.log(errorRes, "erreur")
          this.showNotification('danger', "L'opération a échoué");
        }
      );
    }

  }

  showNotification(type: string, message: string): void {
    this.notif.notify(type, message);
  }
  loadChantier() {
    this.chantiersSubs = this.chantierService.listChantier().subscribe(
      (resData) => {
        this.listChantier = resData
        console.log(this.listChantier, "data listChantier")
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }

  get f() { return this.formMission.controls; }

}
