import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BudgetPagesComponent } from './budget-pages.component';
import { BudgetPagesRoutingModule } from './budget-pages-routing.module';
import { BudgetDashboardComponent } from './budget-dashboard/budget-dashboard.component';
import { BudgetDetailComponent } from './budget-detail/budget-detail.component';
import { BudgetEditComponent } from './budget-edit/budget-edit.component';
import { BudgetListComponent } from './budget-list/budget-list.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CountUpModule } from 'countup.js-angular2';
import { AccordionModule, BsDropdownModule, ModalModule, PaginationModule, ProgressbarModule, TabsModule, TooltipModule } from 'ngx-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgApexchartsModule } from 'ng-apexcharts';


@NgModule({
  declarations: [
    BudgetPagesComponent,
    BudgetDashboardComponent,
    BudgetDetailComponent,
    BudgetEditComponent,
    BudgetListComponent
  ],
  imports: [
    CommonModule,
    BudgetPagesRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    CountUpModule,
    AccordionModule,
    BsDropdownModule,
    PaginationModule,
    ProgressbarModule,
    ModalModule,
    HttpClientModule,
    TabsModule,
    FontAwesomeModule,
    TooltipModule,
    AccordionModule,
    NgxGaugeModule,
    NgApexchartsModule,
  ],
  exports: [
    BudgetDashboardComponent,
    BudgetDetailComponent,
    BudgetEditComponent,
    BudgetListComponent
  ],
})
export class BudgetPagesModule { }
