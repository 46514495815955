import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProcesVerbalDetailComponent } from './proces-verbal-detail/proces-verbal-detail.component';
import { ProcesVerbalListComponent } from './proces-verbal-list/proces-verbal-list.component';
import { ProcesVerbalPagesComponent } from './proces-verbal-pages.component';


const routes: Routes = [
  {
    path:'', component: ProcesVerbalPagesComponent,
    children: [
      {
        path: "liste",
        component: ProcesVerbalListComponent
      },
      {
        path: "detail/:id",
        component: ProcesVerbalDetailComponent
      },

    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProcesVerbalPagesRoutingModule { }
