import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OpportuniteDashboardComponent } from './opportunite-dashboard/opportunite-dashboard.component';
import { OpportuniteDetailComponent } from './opportunite-detail/opportunite-detail.component';
import { OpportuniteKanbanComponent } from './opportunite-kanban/opportunite-kanban.component';
import { OpportuniteListComponent } from './opportunite-list/opportunite-list.component';
import { OpportunitePagesComponent } from './opportunite-pages.component';


const routes: Routes = [
  {
    path:'',
    component: OpportunitePagesComponent,
    children: [
      {
        path: "dashboard",
        component: OpportuniteDashboardComponent
      },
      {
        path: "liste",
        component: OpportuniteListComponent
      },
      {
        path: "kanban",
        component: OpportuniteKanbanComponent
      },
      {
        path: "detail/:id",
        component: OpportuniteDetailComponent
      },

    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OpportunitePagesRoutingModule { }
