import { Component, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UtilisateurService } from '../utilisateur.service';
import { NotificationsService } from 'src/app/shared-components/service/notifications.service';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { generate } from 'rxjs';
import { Utilisateur } from '../utilisateur.model';
import { TiersService } from '../../tiers-pages/tiers-service';
import { Subscription } from 'rxjs';
import { Tiers } from '../../tiers-pages/tiers.model';
import { UtilOptions } from 'src/app/shared-components/util/util-option';
import { ModalDirective } from 'ngx-bootstrap';
import { EventEmitter } from 'protractor';

import { BureauEtudeService } from '../../bureau-etude/bureau-etude.service';
import { BureauEtude } from '../../bureau-etude/bureau-etude.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceService } from '../../service/service.service';
import { Service } from '../../service/service.model';

const URL = 'https://example.com';
declare var $;

@Component({
  selector: 'app-utilisateur-edit',
  templateUrl: './utilisateur-edit.component.html',
  styleUrls: ['./utilisateur-edit.component.scss']
})
export class UtilisateurEditComponent implements OnInit, OnDestroy {
  @ViewChild('newTiersModal', {static: false})  public newTiersModal: ModalDirective;
  @ViewChild('newBureauModal', {static: false})  public newBureauModal: ModalDirective;serviceLibelle
  @ViewChild('newServiceModal', {static: false})  public newServiceModal: ModalDirective;
  // @Output() test = new EventEmitter();
  inputGroupe: boolean = true;
  response: string;
  notif: NotifierService;
  utilisateurForm: FormGroup;
  editMode = false;
  form: FormGroup;
  typeForm: FormGroup;
  tier: FormGroup;
  bureau: FormGroup;
  service: FormGroup;
  submit: boolean = false;
  required = UtilOptions.required;
  userDetails: any;
  listeGenre = [
    {
      libelle: "Masculin",
      value: true
    },
    {
      libelle: "Feminin",
      value: false
    }
  ]
  listeType = [
    {
      libelle: "Membre de l'entreprise",
      value: 0
    },
    {
      libelle: "Délégué de bureau d'étude",
      value: 1
    },
    {
      libelle: "Délégué fournisseur",
      value: 2
    }
  ]

  public mask: Array<string | RegExp>;
  public maskDate: Array<string | RegExp>;
  public maskPhone: Array<string | RegExp>;

  //tiers
  tiersSubs = new Subscription;
  bureauEtudeSubs = new Subscription;
  tierSubs = new Subscription;
  bureauxEtudeSubs = new Subscription;
  listeTiers: Tiers[] = [];
  listeBureauEtude: BureauEtude[] = [];

  serviceSubs = new Subscription;
  servicesSubs = new Subscription;
  listeService: Service[] = [];
  constructor(
    public modal: NgbActiveModal,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private utilisateurService: UtilisateurService,
    private tersService: TiersService,
    private bureauEtudeService: BureauEtudeService,
    private serviceService: ServiceService,
    private formBuilder: FormBuilder,
    private notifier: NotifierService) {
    this.notif = notifier;
    this.maskPhone = ['(', '+', '2', '2', '5', ')', ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
    this.maskDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    this.__onGetData();
  }

  newForm() { }

  // Formulaire d'ajout d'un type utilisateur
  typeUserForm() {
    this.typeForm = this.formBuilder.group({
      id: [null],
      libelle: [null, [Validators.required]]
    })
  }

  // Formulaire d'ajout de tiers
  tierForm() {
    this.tier = this.formBuilder.group({
      id: [null],
      nonTiers: [null, [Validators.required]],
      localisationTiers: [null, [Validators.required]]
    })
  }

  // Formulaire d'ajout d'un bureau d'etude
  bureauForm() {
    this.bureau = this.formBuilder.group({
      id: [null],
      nonBureau: [null, [Validators.required]],
      localisationBureau: [null, [Validators.required]]
    })
  }
   // Formulaire d'ajout d'un service
   serviceForm() {
    this.service = this.formBuilder.group({
      id: [null],
      serviceLibelle: [null, [Validators.required]]
    })
  }

  get f() { return this.form.controls; }
  get tf() { return this.typeForm.controls; }
  get tif() { return this.tier.controls; }
  get bef() { return this.bureau.controls; }
  get servicef() { return this.service.controls; }

  ngOnInit() {
    this.loadTiers();
    this.loadBureauEtude();
    this.loadService();
    this.newForm();
    this.typeUserForm();
    this.tierForm();
    this.bureauForm();
    this.serviceForm();
    

  }
  ngOnDestroy(): void {
    this.tiersSubs.unsubscribe();
    this.tierSubs.unsubscribe();
    this.bureauEtudeSubs.unsubscribe();
    this.bureauxEtudeSubs.unsubscribe();
    this.servicesSubs.unsubscribe();
    this.serviceSubs.unsubscribe();
    this.utilisateurService.setDataForUtilisateur('');
  }

  loadTiers() {
    this.tiersSubs = this.tersService.listerTiers().subscribe(
      (resData) => {
        this.listeTiers = resData
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
  loadBureauEtude() {
    this.bureauEtudeSubs = this.bureauEtudeService.listBureauEtude().subscribe(
      (resData) => {
        this.listeBureauEtude = resData
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
  addTier() {
    const data =  {
      libelle: this.tier.value.nonTiers,
      localisation: this.tier.value.localisationTiers
    };
    this.tierSubs = this.tersService.addTiers(data).subscribe(
      (resData: Tiers) => {
        this.closeTiersModal();
        this.listeTiers = [];
        this.tier.reset();
        this.loadTiers();

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
  addBureau() { 
    const data = {
      libelle: this.bureau.value.nonBureau,
      localisation: this.bureau.value.localisationBureau
    };
    this.bureauxEtudeSubs = this.bureauEtudeService.addBureauEtude(data).subscribe(
      (resData: Tiers) => {
        this.closeBureauEtudeModal();
        this.listeBureauEtude = [];
        this.bureau.reset();
        this.loadBureauEtude();

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
 

  loadService() {
    this.servicesSubs = this.serviceService.listService().subscribe(
      (resData) => {
        this.listeService = resData
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
  addService() {
    const data = {
      libelle: this.service.value.serviceLibelle,
    }
    this.serviceSubs = this.serviceService.addService(data).subscribe(
      (resData: Service) => {
        this.closeServiceModal();
        this.listeService = [];
        this.service.reset();
        this.loadService();

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
 
  initForm() {
    let nom = "";
    let prenom = "";
    let genre = "true";
    let dateNaissance = "";
    let pays = "";
    let ville = "";
    let email = "";
    let contact1 = "";
    let contact2 = "";
    let username = "";
    let type = "";
    let avatar = "";
    let tiers = "";
    let service = "";
    let superieur = "";
    let adresse = "";
    let bureau = "";
    let fonction = "";
    //Chargement de la liste des tiers
    if (this.editMode) {
    }
    this.utilisateurForm = new FormGroup({
      "nom": new FormControl(nom, [Validators.required]),
      "prenom": new FormControl(prenom, [Validators.required]),
      "genre": new FormControl(genre, [Validators.required]),
      "dateNaissance": new FormControl(dateNaissance),
      "pays": new FormControl(pays),
      "ville": new FormControl(ville),
      "email": new FormControl(email, [Validators.required]),
      "contact1": new FormControl(contact1, [Validators.required]),
      "contact2": new FormControl(contact2),
      "username": new FormControl(username, [Validators.required, Validators.minLength(6)]),
      "type": new FormControl(type, [Validators.required]),
      "avatar": new FormControl(avatar),
      "tiers": new FormControl(tiers),
      "service": new FormControl(service),
      "superieur": new FormControl(superieur),
      "adresse": new FormControl(adresse),
      "bureau": new FormControl(bureau),
      "fonction": new FormControl(fonction),
    });
  }
  pathValueForm(data) {
    
    this.utilisateurForm = new FormGroup({
      "nom": new FormControl('', [Validators.required]),
      "prenom": new FormControl('', [Validators.required]),
      "genre": new FormControl('', [Validators.required]),
      "dateNaissance": new FormControl(''),
      "pays": new FormControl(''),
      "ville": new FormControl(''),
      "email": new FormControl('', [Validators.required]),
      "contact1": new FormControl('', [Validators.required]),
      "contact2": new FormControl(''),
      "username": new FormControl('', [Validators.required]),
      "type": new FormControl('', [Validators.required]),
      "avatar": new FormControl(''),
      "tiers": new FormControl(''),
      "service": new FormControl(''),
      "superieur": new FormControl(''),
      "adresse": new FormControl(''),
      "bureau": new FormControl(''),
      "fonction": new FormControl('')
    });
    this.utilisateurForm.patchValue({
      nom: data.nom,
      prenom: data.prenom,
      genre: data.genre,
      dateNaissance: new Date(data.dateNaissance),
      pays: data.pays,
      ville: data.ville,
      email:  data.email,
      contact1:  data.contact1,
      contact2:  data.contact2,
      username:  data.username,
      type:  data.type,
      avatar:  data.avatar,
      tiers:  data.tiers['@id'] != null ? data.tiers['@id'] : '',
      service:  data.service['@id'] != null? data.service['@id'] : '',
      superieur:  data.superieurHierarchique,
      adresse:  data.localisation,
      bureau:  data.bureauEtude['@id'] != null ? data.bureauEtude['@id'] : '',
      fonction:  data.fonction,
    });

    
    
    
    
  }

  onSubmit() {
    this.spinner.show("sp6");
    if (this.editMode) {
      const data = {
        nom: this.utilisateurForm.value.nom,
        email: this.utilisateurForm.value.email,
        contact1: this.utilisateurForm.value.contact1,
        username: this.utilisateurForm.value.username,
        bureauEtude: this.utilisateurForm.get('bureau').value,
        contact2: this.utilisateurForm.value.contact2,
        dateNaissance: new Date(this.utilisateurForm.value.dateNaissance),
        avatar: null,
        fonction: this.utilisateurForm.value.fonction,
        genre: this.utilisateurForm.value.genre,
        pays: this.utilisateurForm.value.pays,
        ville: this.utilisateurForm.value.ville,
        prenom: this.utilisateurForm.value.prenom,
        localisation: this.utilisateurForm.value.adresse,
        // adresse: this.utilisateurForm.value.adresse,
        service: this.utilisateurForm.value.service,
        superieurHierarchique: this.utilisateurForm.value.superieur,
        tiers: this.utilisateurForm.get('tiers').value,
        type: parseInt(this.utilisateurForm.get('type').value, 10)
      };
      this.utilisateurService.updateUtilisateur(this.userDetails.user.id,data).subscribe(
        resData => {
          if(resData) {
            this.spinner.hide("sp6");
          this.modal.close('update');
          } 
        },
        errorRes => {
          this.spinner.hide("sp6");
          console.log(errorRes, "erreur")
          this.showNotification('danger', "L'opération a échoué");
        }
      );
    }
    else {
      const data = {
        nom: this.utilisateurForm.value.nom,
        email: this.utilisateurForm.value.email,
        contact1: this.utilisateurForm.value.contact1,
        username: this.utilisateurForm.value.username,
        bureauEtude: this.utilisateurForm.get('bureau').value,
        contact2: this.utilisateurForm.value.contact2,
        dateNaissance: new Date(this.utilisateurForm.value.dateNaissance),
        avatar: null,
        fonction: this.utilisateurForm.value.fonction,
        genre: this.utilisateurForm.value.genre,
        pays: this.utilisateurForm.value.pays,
        ville: this.utilisateurForm.value.ville,
        prenom: this.utilisateurForm.value.prenom,
        localisation: this.utilisateurForm.value.adresse,
        service: this.utilisateurForm.value.service,
        superieurHierarchique: this.utilisateurForm.value.superieur,
        tiers: this.utilisateurForm.get('tiers').value,
        // adresse: this.utilisateurForm.value.adresse,
        type: parseInt(this.utilisateurForm.get('type').value, 10)
      };
      this.utilisateurService.addUtilisateur(data).subscribe(
        resData => {
          if(resData) {
            this.spinner.hide("sp6");
          this.modal.close('success');
          }  
        },
        errorRes => {
          this.spinner.hide("sp6");
          console.log(errorRes, "erreur")
          this.showNotification('danger', "L'opération a échoué");
        }
      );
    }
  }
  onCancel() {
    console.log("/pages/utilisateur/liste");
    this.router.navigate(['/pages/utilisateur/liste']);
  } 
  showNotification(type: string, message: string): void {
    this.notif.notify(type, message);
  }
  __onGetData() {
    this.userDetails = this.utilisateurService.getDataForUtilisateur();
    if(this.userDetails) {
      this.editMode = this.userDetails.type;
    } else {
      this.editMode = false;
    }
    console.log(this.userDetails);
    if(this.editMode == true) {
      
      this.spinner.show("sp6");
      this.pathValueForm(this.userDetails.user);
      setTimeout(() => {
        this.spinner.hide("sp6");
      }, 3000);
    } else {
      this.initForm();
    }
  }

  onClose(): any {
    this.userDetails = "";
    this.utilisateurService.setDataForUtilisateur("");
    this.utilisateurForm.reset();
    this.initForm();
    this.editMode = false;
    this.modal.close();
  }
  closeTiersModal(){
    this.newTiersModal.hide();
  }
  closeBureauEtudeModal(){
    this.newBureauModal.hide();
  }
  closeServiceModal(){
    this.newServiceModal.hide();
  }
}
