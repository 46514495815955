import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TacheDetailComponent } from './tache-detail/tache-detail.component';
import { TacheListComponent } from './tache-list/tache-list.component';
import { TachePagesComponent } from './tache-pages.component';


const routes: Routes = [
  {
    path:'',
    component: TachePagesComponent,
    children: [
      {
        path: "liste",
        component: TacheListComponent
      },
     {
        path: "detail/:id",
        component: TacheDetailComponent
      },

    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TachePagesRoutingModule { }
