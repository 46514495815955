import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BureauControlleDashboardComponent } from './bureau-controlle-dashboard/bureau-controlle-dashboard.component';
import { BureauControllePagesComponent } from './bureau-controlle-pages.component';


const routes: Routes = [
  {
    path:'',
    component: BureauControllePagesComponent,
    children: [
      {
        path: "dashboard",
        component: BureauControlleDashboardComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BureauControllePagesRoutingModule { }
