import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from '../pages/auth-pages/auth-interceptor.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { RouterModule } from '@angular/router';
import { ProjetPagesModule } from './projet-pages/projet-pages.module';
import { OpportunitePagesModule } from './opportunite-pages/opportunite-pages.module';
import { MissionPagesModule } from './mission-pages/mission-pages.module';
import { FournisseurPagesModule } from './fournisseur-pages/fournisseur-pages.module';
import { RessourcePagesModule } from './ressource-pages/ressource-pages.module';
import { TachePagesModule } from './tache-pages/tache-pages.module';
import { TresoreriePagesModule } from './tresorerie-pages/tresorerie-pages.module';
import { UtilisateurPagesModule } from './utilisateur-pages/utilisateur-pages.module';
import { EtudePrixPagesModule } from './etude-prix-pages/etude-prix-pages.module';
import { ChantierPagesModule } from './chantier-pages/chantier-pages.module';

import { HomePagesModule } from './home-pages/home-pages.module';
import { PlaceholderDirective } from '../shared-components/placeholder/placeholder.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingSpinnerComponent } from '../shared-components/loading-spinner/loading-spinner.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';


import { IntervenantPagesModule } from './intervenant-pages/intervenant-pages.module';
import { RemarquePagesModule } from './remarque-pages/remarque-pages.module';
import { FichierPagesModule } from './fichier-pages/fichier-pages.module';
import { ClientPagesModule } from './acteur-externe-pages/client-pages/client-pages.module';
import { BureauControllePagesModule } from './acteur-externe-pages/bureau-controlle-pages/bureau-controlle-pages.module';
import { BureauEtudePagesModule } from './acteur-externe-pages/bureau-etude-pages/bureau-etude-pages.module';
import { ArchitectPagesComponent } from './acteur-externe-pages/architect-pages/architect-pages.component';
import { ArchitectPagesModule } from './acteur-externe-pages/architect-pages/architect-pages.module';


@NgModule({
  declarations: [
    PagesComponent,PlaceholderDirective
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }],
  imports: [
    CommonModule,
    RouterModule,
    PagesRoutingModule,
    ProjetPagesModule,
    OpportunitePagesModule,
    MissionPagesModule,
    FournisseurPagesModule,
    RessourcePagesModule,
    TachePagesModule,
    TresoreriePagesModule,
    UtilisateurPagesModule,
    EtudePrixPagesModule,
    ChantierPagesModule,

    HomePagesModule,
    NgxSpinnerModule,
    SharedComponentsModule,
    IntervenantPagesModule,
    RemarquePagesModule,
    FichierPagesModule,
    ArchitectPagesModule,
    BureauControllePagesModule,
    BureauEtudePagesModule,
    ClientPagesModule,
  
  ],
  exports: [
    PlaceholderDirective,
    NgxSpinnerModule,
  ],
})
export class PagesModule { }
