import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReunionDetailComponent } from './reunion-detail/reunion-detail.component';
import { ReunionListComponent } from './reunion-list/reunion-list.component';
import { ReunionPagesComponent } from './reunion-pages.component';


const routes: Routes = [
  {
    path:'',
    component: ReunionPagesComponent,
    children: [
      {
        path: "liste",
        component: ReunionListComponent
      },
      {
        path: "detail/:id",
        component: ReunionDetailComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReunionPagesRoutingModule { }
