import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { stringify } from 'querystring';
import { BehaviorSubject,throwError } from 'rxjs';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';



interface prestationFournisseurData {
  id?: string,
  libelle: string,
}

@Injectable({
  providedIn: 'root'
})
export class PrestationFournisseurService {

  apiUrl = environment.apiUrl;
  prefix = environment.prefix;

  constructor(private http:HttpClient) { }

  listPrestationFournisseur(){
    return this.http.get<prestationFournisseurData[]>(
      `${this.apiUrl}/prestation_fournisseurs`,{
        responseType: 'json'
      }
    ).pipe(
      map((response: any) => response['hydra:member']),
      catchError(this.handleError)
    )
  }

  addPrestationFournisseur(data:prestationFournisseurData){
    let responseData:prestationFournisseurData;
        return this.http.post(`${this.apiUrl}/prestation_fournisseurs`,JSON.stringify(data)).pipe(
        tap( resData => {      
          return resData
        }),
        catchError(this.handleError)
      );//pipe   
  }

  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "Une erreur est survenue";
    return throwError(messageError);
  }
}
