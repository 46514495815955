import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjetDashboardComponent } from './projet-dashboard/projet-dashboard.component';
import { ProjetDetailComponent } from './projet-detail/projet-detail.component';
import { ProjetListComponent } from './projet-list/projet-list.component';
import { ProjetPagesComponent } from './projet-pages.component';


const routes: Routes = [
  {
    path:'',
    component: ProjetPagesComponent,
    children: [
      {
        path: "dashboard",
        component: ProjetDashboardComponent
      },
      {
        path: "liste",
        component: ProjetListComponent
      },
      {
        path: "detail/:id",
        component: ProjetDetailComponent
      },

    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjetPagesRoutingModule { }
