<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-add-user display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1 class="font-weight-bold m-0">Détail fournisseurs</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description m-0" title="Référence et et nom du fournisseur">
                    <span><i class="fas fa-file-alt"></i> ( {{fournisseur?.reference}} )</span>
                    <span class="ml-1">{{fournisseur?.nom}}</span>
                </div>
                <p class="m-0" title="Date de création">
                    <i class="fas fa-calendar-alt"></i>
                    <span class="ml-1">Créer le {{fournisseur?.dateCreation}}</span>
                </p>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button [routerLink]="['/pages/fournisseur/liste']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow" type="tabs" >

    <!-- Debut tableau de bord -->
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span><i class="fas fa-tachometer-alt"></i> Tableau de bord</span></ng-template>
        <div>
            <div class="row">
                <div class="col-md-4">
                    <div class="card card-shadow-first p-4 mb-5">
                        <div class="d-flex align-items-center">
                            <div class="d-40 rounded-circle bg-first text-white text-center font-size-lg mr-3">
                                <i class="fas fa-shopping-cart"></i>
                            </div>
                            <div class="text-black-50">Total commandes</div>
                        </div>
                        <div class="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                            <div>100</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card p-4 mb-5">
                        <div class="d-flex align-items-center">
                            <div class="d-40 rounded-circle bg-success text-white text-center font-size-lg mr-3">
                                <i class="fas fa-file-invoice"></i>
                            </div>
                            <div class="text-black-50">Total facture</div>
                        </div>
                        <div class="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                            <div>564</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card bg-premium-dark p-4 mb-5">
                        <div class="d-flex align-items-center">
                            <div class="d-40 rounded-circle bg-first text-white text-center font-size-lg mr-3">
                                <i class="fas fa-project-diagram"></i>
                            </div>
                            <div class="text-white-50">Projet</div>
                        </div>
                        <div class="display-3 text-center line-height-sm text-white-50 text-center d-flex align-items-center pt-3 justify-content-center">
                            <div>1098</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card-box border-0 mb-5">
                <div class="card-header p-3">
                    <div class="card-header--title">
                        <h6 class="font-weight-bold m-0">Statistiques de l'etat des factures de l'année en cours</h6>
                    </div>
                </div>
                <div class="card-body p-0">
                    <apx-chart
                        [chart]="{
                            type: 'bar',
                            height: '365',
                            sparkline: {enabled: false},
                            toolbar: { show: false}
                        }"
                        [colors]="['#1cc943', '#4191ff', '#f4772e', '#ff0000']"
                        [dataLabels]="{ enabled: false }"
                        [fill]="{
                            opacity: 0.85,
                            colors: ['#1cc943', '#4191ff', '#f4772e', '#ff0000']
                        }"
                        [grid]="{
                            strokeDashArray: '5',
                            borderColor: 'rgba(125, 138, 156, 0.3)'
                        }"
                        [labels]="['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aôut', 'Septembre', 'Octobre', 'Novembre', 'Decembre']"
                        [legend]="{ show: true }"
                        [series]="[
                            {
                                name: 'Payé',
                                data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                            },
                            {
                                name: 'En cours',
                                data: [1, 2, 3, 4, 5, 6, 7, 1, 2, 10, 0, 1.3]
                            },
                            {
                                name: 'En attent',
                                data: [2.1, 2.1, 2.8, 2.8, 4.3, 2.7, 1.4, 1, 2, 3, 4, 5]
                            },
                            {
                                name: 'Impayé',
                                data: [2.1, 0, 1, 0, 6, 2.7, 1.4, 7, 0, 1, 0, 5]
                            }
                        ]"
                        [stroke]="{
                            show: true,
                            width: 2,
                            colors: ['transparent']
                        }"
                    ></apx-chart>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 mb-5">
                    <div class="card rounded-0">
                        <div class="grid-menu grid-menu-2col">
                            <div class="no-gutters row">
                                <div class="col-md-6">
                                    <div class="p-3">
                                        <h5>Montant Factures</h5>
                                        <h2 class="text-right">
                                            <i class="fas fa-file-invoice float-left text-primary"></i>
                                            <span class="font-weight-bold" [countUp]="455"></span>
                                        </h2>
                                        <p class="mb-0">1000 000 000</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="p-3 bg-deep-blue text-white">
                                        <h5>Facture payées</h5>
                                        <h2 class="text-right">
                                            <i class="fas fa-file-invoice-dollar float-left"></i>
                                            <span class="font-weight-bold" [countUp]="25"></span>
                                        </h2>
                                        <p class="mb-0">1000 000 000</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="p-3 bg-sunny-morning text-white">
                                        <h5>Facture Impayées</h5>
                                        <h2 class="text-right">
                                            <i class="fas fa-times-circle float-left"></i>
                                            <span class="font-weight-bold" [countUp]="455"></span>
                                        </h2>
                                        <p class="mb-0">1000 000 000</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="p-3" title="Facture en attente de payement">
                                        <h5>Facture En attente</h5>
                                        <h2 class="text-right">
                                            <i class="fas fa-pause-circle float-left text-warning"></i>
                                            <span class="font-weight-bold" [countUp]="455"></span>
                                        </h2>
                                        <p class="mb-0">1000 000 000</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-5">
                    <div class="card rounded-0">
                        <div class="grid-menu grid-menu-2col">
                            <div class="no-gutters row">
                                <div class="col-md-6">
                                    <div class="p-3">
                                        <h5>Commandes a validées</h5>
                                        <h2 class="text-right">
                                            <i class="fas fa-file-invoice float-left text-primary"></i>
                                            <span class="font-weight-bold" [countUp]="455"></span>
                                        </h2>
                                        <p class="mb-0">1000 000 000</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="p-3">
                                        <h5>Commandes livrées</h5>
                                        <h2 class="text-right">
                                            <i class="fas fa-file-invoice-dollar float-left"></i>
                                            <span class="font-weight-bold" [countUp]="25"></span>
                                        </h2>
                                        <p class="mb-0">1000 000 000</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="p-3">
                                        <h5>Commandes nom livrées</h5>
                                        <h2 class="text-right">
                                            <i class="fas fa-times-circle float-left"></i>
                                            <span class="font-weight-bold" [countUp]="455"></span>
                                        </h2>
                                        <p class="mb-0">1000 000 000</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="p-3" title="Facture en attente de payement">
                                        <h5>Commandes en attente</h5>
                                        <h2 class="text-right">
                                            <i class="fas fa-pause-circle float-left text-warning"></i>
                                            <span class="font-weight-bold" [countUp]="455"></span>
                                        </h2>
                                        <p class="mb-0">1000 000 000</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </tab>

    <!-- Debut profile -->
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span><i class="fas fa-user"></i> Profile</span></ng-template>
        <div class="row">
            <!-- SideBar -->
            <div class="col-md-4">
                <div class="bg-midnight-bloom p-3 rounded text-white h-100">
                    <div class="d-flex align-items-start justify-content-between">
                        <div class="avatar-icon-wrapper d-100">
                            <span class="badge badge-circle badge-success">En ligne</span>
                            <div class="avatar-icon d-100">
                                <img alt="..." src="{{fournisseur?.photo}}"/>
                            </div>
                        </div>
                    </div>
                    <div class="font-weight-bold font-size-lg d-flex align-items-center mt-2 mb-0">
                        <span>Kouassi ghislain</span>
                    </div>
                    <div class="divider opacity-2 my-4"></div>
                    <a [routerLink]="" class="btn btn-sm btn-outline-light shadow-none mr-2">Envoyer un message</a>
                    <a [routerLink]="" class="btn btn-sm btn-outline-light shadow-none mr-2">Suivre</a>
                    <div class="divider opacity-2 my-4"></div>

                    <div class="font-weight-bold font-size-lg d-flex align-items-center mb-3">
                        <span>Liste de groupe pour cet utilisateur</span>
                        <div class="ml-auto font-size-xs">
                            <a [routerLink]="" class="text-white" tooltip="Ajouter">
                                <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
                            </a>
                        </div>
                    </div>
                    <div class="py-2">AGENT ACHAT</div>
                    
                    <div class="divider opacity-2 my-4"></div>
                    <div class="font-weight-bold font-size-lg d-flex align-items-center mb-3">
                        <span>Projet liés</span>
                        <div class="ml-auto font-size-xs">
                            <a [routerLink]="" class="text-white" tooltip="Ajouter">
                                <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
                            </a>
                        </div>
                    </div>
                    <div class="py-2">
                        <div class="align-box-row">
                            <a [routerLink]="" class="avatar-icon-wrapper avatar-icon-md text-white">
                                <span class="badge badge-circle badge-success">En ligne</span>
                                <div class="avatar-icon rounded-circle"><img alt="..." src="assets/images/avatars/avatar3.jpg"/></div>
                            </a>
                            <div class="pl-2">
                                <span class="d-block">
                                    <a [routerLink]="" class="text-white">Anicet Pro</a>
                                    <small class="d-block text-white-50">(znicet@example.com)</small>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="py-2">
                        <div class="align-box-row">
                            <a [routerLink]="" class="avatar-icon-wrapper avatar-icon-md text-white">
                                <span class="badge badge-circle badge-neutral-dark">Est partie</span>
                                <div class="avatar-icon rounded-circle"><img alt="..." src="assets/images/avatars/avatar3.jpg"/></div>
                            </a>
                            <div class="pl-2">
                                <span class="d-block">
                                    <a [routerLink]="" class="text-white">Roland Pro</a>
                                    <small class="d-block text-white-50">(roland@gmail.com)</small>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="py-2">
                        <div class="align-box-row">
                            <a [routerLink]="" class="avatar-icon-wrapper avatar-icon-md text-white">
                                <span class="badge badge-circle badge-success">En ligne</span>
                                <div class="avatar-icon rounded-circle"><img alt="..." src="assets/images/avatars/avatar3.jpg"/></div>
                            </a>
                            <div class="pl-2">
                                <span class="d-block">
                                    <a [routerLink]="" class="text-white">Mamas Kader</a>
                                    <small class="d-block text-white-50">(mamos@example.com)</small>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="py-2">
                        <div class="align-box-row">
                            <a [routerLink]="" class="avatar-icon-wrapper avatar-icon-md text-white">
                                <span class="badge badge-circle badge-neutral-dark">Est partie</span>
                                <div class="avatar-icon rounded-circle"><img alt="..." src="assets/images/avatars/avatar3.jpg"/></div>
                            </a>
                            <div class="pl-2">
                                <span class="d-block">
                                    <a [routerLink]="" class="text-white">John Doe</a>
                                    <small class="d-block text-white-50">(Galen@example.com)</small>
                                </span>
                            </div>
                        </div>
                    </div>
                    <a [routerLink]="" class="btn btn-sm btn-outline-light shadow-none mr-2 mt-3">Voir le groupe</a>
                    <div class="divider opacity-2 my-4"></div>
                    <div class="font-weight-bold font-size-lg d-flex align-items-center mb-3">
                        <span>Connexion</span>
                    </div>
                    <div class="py-2">
                        <div class="d-flex justify-content-between">
                            <span class="d-block"><a [routerLink]="" class="text-white">Dernière connexion</a></span>
                            <span class="text-white-50">07/04/2020  16:54</span>
                        </div>
                    </div>
                    <div class="py-2">
                        <div class="d-flex justify-content-between">
                            <span class="d-block"><a [routerLink]="" class="text-white">Connexion précedente</a></span>
                            <span class="badge badge-dark"></span>
                        </div>
                    </div>
                    <div class="divider opacity-2 my-4"></div>
                    <div class="font-weight-bold font-size-lg d-flex align-items-center mb-3">
                        <span>RH</span>
                    </div>
                    <div class="py-2">
                        <div class="d-flex justify-content-between">
                            <span class="d-block"><a [routerLink]="" class="text-white">Demande de mission</a></span>
                            <span class="badge badge-dark">22</span>
                        </div>
                    </div>
                    <div class="py-2">
                        <div class="d-flex justify-content-between">
                            <span class="d-block"><a [routerLink]="" class="text-white">Note de frais</a></span>
                            <span class="badge badge-dark">11</span>
                        </div>
                    </div>
                    <div class="py-2">
                        <div class="d-flex justify-content-between">
                            <span class="d-block"><a [routerLink]="" class="text-white">Congés</a></span>
                            <span class="badge badge-dark">33</span>
                        </div>
                    </div>
                </div>
            </div>
        
            <!-- Container -->
            <div class="col-md-8">
                <tabset type="line" class="nav-line-alt">

                    <!-- Informations -->
                    <tab>
                        <ng-template tabHeading>
                            Informations
                            <div class="divider"></div>
                        </ng-template>
                        <div class="card p-3 my-3">
                            <div class="border rounded p-2">
                                <div class="row">
                                    <div class="col-md-6">
                                        <p class="mt-2 mb-0"><b>Code:</b> <span class="ml-2">{{fournisseur?.reference}}</span></p>
                                        <hr class="my-2">
                                        <p class="m-0"><b>Type:</b> <span class="ml-2">{{fournisseur?.type}}</span></p>
                                        <hr class="my-2">
                                        <p class="m-0">
                                            <b>{{fournisseur?.type == 'Personne physique' ? 'Raison sociale' : 'Nom et prenom'}}:</b> 
                                            <span class="ml-2">{{fournisseur?.nom}}</span>
                                        </p>
                                        <hr class="my-2">
                                        <p class="m-0"><b>Corps de metier:</b> <span class="ml-2">{{fournisseur?.corpsMetier}}</span></p>
                                        <hr class="my-2">
                                        <div class="m-0 p-0" *ngIf="fournisseur?.type == 'Personne physique'">
                                            <p class="m-0"><b>Civilité:</b> <span class="ml-2">{{fournisseur?.civilite}}</span></p>
                                            <hr class="my-2">
                                            <p class="m-0"><b>Genre:</b> <span class="ml-2">{{fournisseur?.sexe}}</span></p>
                                            <hr class="my-2">
                                            <p class="m-0"><b>Nationalité:</b> <span class="ml-2">{{fournisseur?.nationalite}}</span></p>
                                            <hr class="my-2">
                                        </div>
                                        <p class="m-0">
                                            <b>{{fournisseur?.type == 'Personne physique' ? 'Date de naissance' : 'Date de création'}}:</b> 
                                            <span class="ml-2">{{fournisseur?.date}}</span>
                                        </p>
                                        <hr class="my-2">
                                        <p class="m-0"><b>Pays:</b> <span class="ml-2">{{fournisseur?.pays}}</span></p>
                                        <hr class="my-2">
                                        <p class="m-0"><b>Ville:</b> <span class="ml-2">{{fournisseur?.ville}}</span></p>
                                        <hr class="my-2">
                                        <p class="m-0"><b>Commune:</b> <span class="ml-2">{{fournisseur?.commune}}</span></p>
                                        <hr class="my-2">
                                        <p class="m-0">
                                            <b>{{fournisseur?.type == 'Personne physique' ? 'Quartier' : 'Siège social'}}:</b> 
                                            <span class="ml-2">{{fournisseur?.quartier}}</span>
                                        </p>
                                        <hr class="my-2">
                                        <p class="m-0"><b>Adresse postal:</b> <span class="ml-2">{{fournisseur?.postal}}</span></p>
                                        <hr class="my-2">
                                        <p class="m-0"><b>Adresse email:</b> <span class="ml-2">{{fournisseur?.email}}</span></p>
                                        <hr class="my-2">
                                        <p class="m-0"><b>Contact:</b> <span class="ml-2">{{fournisseur?.contact}}</span></p>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="m-0 p-0" *ngIf="fournisseur?.type == 'Personne physique'">
                                            <p class="mt-2 mb-0"><b>Nature de la pièce:</b> <span class="ml-2">{{fournisseur?.naturePiece}}</span></p>
                                            <hr class="my-2">
                                            <p class="m-0"><b>Numero de le pièce:</b> <span class="ml-2">{{fournisseur?.numPiece}}</span></p>
                                            <hr class="my-2">
                                            <p class="m-0"><b>Date de délivrance:</b> <span class="ml-2">{{fournisseur?.dateDelivrance}}</span></p>
                                            <hr class="my-2">
                                            <p class="m-0"><b>Date d'expiration:</b> <span class="ml-2">{{fournisseur?.dateExpiration}}</span></p>
                                            <hr class="my-2">
                                        </div>
                                        <div class="m-0 p-0" *ngIf="fournisseur?.type != 'Personne physique'">
                                            <p class="m-0"><b>Formejuridique:</b> <span class="ml-2">{{fournisseur?.formeJuridique}}</span></p>
                                            <hr class="my-2">
                                            <p class="m-0"><b>Régistre de commerce:</b> <span class="ml-2">{{fournisseur?.rgistre}}</span></p>
                                            <hr class="my-2">
                                            <p class="m-0"><b>Regime d'imposition:</b> <span class="ml-2">{{fournisseur?.regime}}</span></p>
                                            <hr class="my-2">
                                            <p class="m-0"><b>Compte comptable:</b> <span class="ml-2">{{fournisseur?.compte}}</span></p>
                                            <hr class="my-2">
                                            <p class="m-0"><b>Adresse géographique:</b> <span class="ml-2">{{fournisseur?.geoLocalisation}}</span></p>
                                            <hr class="my-2">
                                            <p class="m-0"><b>Prestation:</b> <span class="ml-2">{{fournisseur?.prestation}}</span></p>
                                            <hr class="my-2">
                                            <p class="m-0"><b>Répresentant:</b> <span class="ml-2">{{fournisseur?.representant}}</span></p>
                                            <hr class="my-2">
                                            <p class="m-0"><b>Contact du répresentant:</b> <span class="ml-2">{{fournisseur?.contactRepresentant}}</span></p>
                                            <hr class="my-2">
                                            <p class="m-0"><b>Email :</b> <span class="ml-2">{{fournisseur?.emailRepresentant}}</span></p>
                                            <hr class="my-2">
                                        </div>
                                        <p class="m-0"><b>Créer par:</b> <span class="ml-2">{{fournisseur?.creerPar}}</span></p>
                                        <hr class="my-2">
                                        <p class="m-0"><b>Date de création:</b> <span class="ml-2">{{fournisseur?.dateCreation}}</span></p>
                                        <hr class="my-2">
                                        <p class="m-0"><b>Modifier par:</b> <span class="ml-2">{{fournisseur?.modifierPar}}</span></p>
                                        <hr class="my-2">
                                        <p class="m-0"><b>Date de modification:</b> <span class="ml-2">{{fournisseur?.dateModification}}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="m-0">
                        <div class="card p-3 my-3">
                            <div class="border rounded">
                                <p class="my-2"><b>Experiences</b></p>
                                <perfect-scrollbar [autoPropagation]="true"  class="scroll-area-lg shadow-overflow">
                                    <div class="p-2">
                                        <div class="timeline-list">
                                            <div class="timeline-item timeline-item-icon">
                                                <div class="timeline-item--content">
                                                    <div class="timeline-item--icon-wrapper rounded-pill bg-success text-white">
                                                        <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>
                                                    </div>
                                                    <h4 class="timeline-item--label mb-2 font-weight-bold">2020</h4>
                                                    <div class="border rounded p-3 bg-light mb-3">
                                                        <p class="my-2 mb-0">
                                                            <b>Type du projet réalisé:</b> 
                                                            <span class="ml-2">Construction</span>
                                                        </p>
                                                        <p class="my-2 mb-0">
                                                            <b>Libellé: </b> 
                                                            <span class="ml-2">Construction immeuble R+3 Attoban</span>
                                                        </p>
                                                        <p class="my-2 mb-0">
                                                            <b>Année de réalisation:</b> 
                                                            <span class="ml-2">2021</span>
                                                        </p>
                                                        <p class="my-2 mb-0">
                                                            <b>Durée de realisation:</b> 
                                                            <span class="ml-2">26 mois</span>
                                                        </p>
                                                        <p class="my-2 mb-0">
                                                            <b>Maitre d'ouvrage:</b> 
                                                            <span class="ml-2">PROTIC</span>
                                                        </p>
                                                        <p class="my-2 mb-0">
                                                            <b>Budget:</b> 
                                                            <span class="ml-2">1000 000 000</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="timeline-item--content">
                                                    <div class="timeline-item--icon-wrapper rounded-pill bg-success text-white">
                                                        <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>
                                                    </div>
                                                    <h4 class="timeline-item--label mb-2 font-weight-bold">2021</h4>
                                                    <div class="border rounded p-3 bg-light mb-3">
                                                        <p class="my-2 mb-0">
                                                            <b>Type du projet réalisé:</b> 
                                                            <span class="ml-2">Construction</span>
                                                        </p>
                                                        <p class="my-2 mb-0">
                                                            <b>Libellé: </b> 
                                                            <span class="ml-2">Construction immeuble R+3 Attoban</span>
                                                        </p>
                                                        <p class="my-2 mb-0">
                                                            <b>Année de réalisation:</b> 
                                                            <span class="ml-2">2021</span>
                                                        </p>
                                                        <p class="my-2 mb-0">
                                                            <b>Durée de realisation:</b> 
                                                            <span class="ml-2">26 mois</span>
                                                        </p>
                                                        <p class="my-2 mb-0">
                                                            <b>Maitre d'ouvrage:</b> 
                                                            <span class="ml-2">PROTIC</span>
                                                        </p>
                                                        <p class="my-2 mb-0">
                                                            <b>Budget:</b> 
                                                            <span class="ml-2">1000 000 000</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="timeline-item--content">
                                                    <div class="timeline-item--icon-wrapper rounded-pill bg-success text-white">
                                                        <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>
                                                    </div>
                                                    <h4 class="timeline-item--label mb-2 font-weight-bold">2022</h4>
                                                    <div class="border rounded p-3 bg-light mb-3">
                                                        <p class="my-2 mb-0">
                                                            <b>Type du projet réalisé:</b> 
                                                            <span class="ml-2">Construction</span>
                                                        </p>
                                                        <p class="my-2 mb-0">
                                                            <b>Libellé: </b> 
                                                            <span class="ml-2">Construction immeuble R+3 Attoban</span>
                                                        </p>
                                                        <p class="my-2 mb-0">
                                                            <b>Année de réalisation:</b> 
                                                            <span class="ml-2">2021</span>
                                                        </p>
                                                        <p class="my-2 mb-0">
                                                            <b>Durée de realisation:</b> 
                                                            <span class="ml-2">26 mois</span>
                                                        </p>
                                                        <p class="my-2 mb-0">
                                                            <b>Maitre d'ouvrage:</b> 
                                                            <span class="ml-2">PROTIC</span>
                                                        </p>
                                                        <p class="my-2 mb-0">
                                                            <b>Budget:</b> 
                                                            <span class="ml-2">1000 000 000</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </perfect-scrollbar>
                            </div>
                        </div>
                    </tab>

                    <!-- Informations financieres -->
                    <tab>
                        <ng-template tabHeading>
                            Informations financieres
                            <div class="divider"></div>
                        </ng-template>
                        <div class="card p-3 my-3">
                            <div class="border rounded p-2">
                                <p class="mt-2 mb-0"><b>Capital:</b> <span class="ml-2">100 000 000 00</span></p>
                                <hr class="my-2">
                                <p class="m-0"><b>Compte bancaire:</b> <span class="ml-2">00001</span></p>
                                <hr class="my-2">
                                <p class="mb-2"><b>Chiffre d'affaire</b></p>
                                <div class="table-responsive m-0">
                                    <table class="table table-hover table-alternate text-nowrap m-0">
                                        <thead>
                                            <tr>
                                                <th>Années</th>
                                                <th>Montant</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>2008</td>
                                                <td>200 000 000</td>
                                            </tr>
                                            <tr>
                                                <td>2009</td>
                                                <td>300 000 000</td>
                                            </tr>
                                            <tr>
                                                <td>2010</td>
                                                <td>150 000 000</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Total:</th>
                                                <th><span class="font-weigth-bold">650 000 000</span></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                
                            </div>
                        </div>
                    </tab>

                    <!-- Documents -->
                    <tab>
                        <ng-template tabHeading>
                            Documents
                            <div class="divider"></div>
                        </ng-template>
                        <div class="row">
                            <div class="col-md-4" *ngFor="let item of docs">
                                <a [routerLink]="" class="card card-box-hover-rise  mt-3 mb-5">
                                    <img alt="..." class="card-img-top" src="assets/images/hero-bg/hero-6.jpg"/>
                                    <div class="card-body card-body-avatar">
                                        <h5 class="card-title font-weight-bold font-size-lg">{{item}}</h5>
                                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                        <div class="card-date mt-2">
                                            <fa-icon [icon]="['far', 'clock']" class="text-muted mr-1"></fa-icon>
                                            <small class="text-muted">
                                                added moments ago
                                            </small>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </tab>
                </tabset>

                <div class="text-right">
                    <button class="btn btn-info ml-2"><i class="fas fa-pen mr-1"></i> Modifier</button>
                    <button class="btn btn-outline-dark ml-2"><i class="fas fa-sync-alt mr-1"></i> Générer mot de passe</button>
                    <button class="btn btn-outline-dark ml-2"><i class="fas fa-sign-out-alt mr-1"></i> Desactive</button>
                    <button class="btn btn-danger ml-2"><i class="ion-md-trash mr-1"></i> Supprimer</button>
                </div>
            </div>
            <!-- Fin conteneur des informations -->
        </div>
    </tab>
    <!-- Fin profile -->

    <!-- Debut representants -->
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span><i class="fas fa-user-tie"></i> Representants</span></ng-template>
        <div class="card card-box border-0">
            <div class="card-header p-3">
                <div class="card-header--title font-weight-bold">Liste des representants</div>
                <div class="card-header--actions">
                    <div class="btn-group" dropdown>
                        <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                            dropdownToggle
                            tooltip="Action"
                            type="button">
                            <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                        </button>
                        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                            <ul class="nav nav-pills flex-column p-3">
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-3">
                <div class="d-flex justify-content-between mb-3">
                    <div class="search-wrapper">
                      <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                      <input class="form-control" placeholder="Recherche dans la liste" type="search">
                    </div>
                </div>
                <div class="table-responsive m-0">
                    <table class="table table-hover text-nowrap m-0">
                        <thead>
                            <tr>
                                <th>photo</th>
                                <th>nom et prenom</th>
                                <th>email</th>
                                <th>fonction</th>
                                <th>roles accès</th>
                                <th>status du compte</th>
                                <th>status</th>
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><img src="../../../assets/images/avatars/avatar3.jpg" alt=""></td>
                                <td><a [routerLink]=""> Kouassi ghislain</a></td>
                                <td>representant@gmail.com</td>
                                <td>Ferrayeur</td>
                                <td><span class="badge bg-neutral-dark text-dark">admin</span></td>
                                <td><span class="badge bg-neutral-success text-success">Actif</span></td>
                                <td><span class="badge bg-neutral-success text-success">En ligne</span></td>
                                <td class="text-center">
                                    <button placement="auto" tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill mr-1 btn-primary" type="button">
                                        <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                    </button>
                                    <button placement="auto" tooltip="Modifier"  class="btn d-40 p-0 btn-pill mr-1 btn-info" type="button">
                                        <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                                    </button>
                                    <button  placement="auto"  tooltip="Supprimer"  class="btn d-40 p-0 btn-pill btn-danger" type="button">
                                        <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td><img src="../../../assets/images/avatars/avatar3.jpg" alt=""></td>
                                <td><a [routerLink]=""> Kouassi ghislain</a></td>
                                <td>representant@gmail.com</td>
                                <td>Ferrayeur</td>
                                <td><span class="badge bg-neutral-dark text-dark">admin</span></td>
                                <td><span class="badge bg-neutral-success text-success">Actif</span></td>
                                <td><span class="badge bg-neutral-success text-success">En ligne</span></td>
                                <td class="text-center">
                                    <button placement="auto" tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill mr-1 btn-primary" type="button">
                                        <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                    </button>
                                    <button placement="auto" tooltip="Modifier"  class="btn d-40 p-0 btn-pill mr-1 btn-info" type="button">
                                        <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                                    </button>
                                    <button  placement="auto"  tooltip="Supprimer"  class="btn d-40 p-0 btn-pill btn-danger" type="button">
                                        <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer p-3">
                <div class="d-flex justify-content-between">
                    <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                    <div class="d-flex align-items-center">
                        <span>Show</span>
                        <select class="mx-1 form-control form-control-sm" id="" name="">
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="40">50</option>
                            <option value="40">100</option>
                        </select>
                        <span>entries</span>
                    </div>
                </div>
            </div>
        </div>
    </tab>
    
    <!-- Debut ressource -->
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading>
            <span><i class="fas fa-address-book"></i> Résources</span>
        </ng-template>
        <div class="m-0 p-0">
            <tabset type="tabs" class="tabs-animated tabs-animated-line">
                <tab>
                    <ng-template tabHeading><span>Prix d'achat</span></ng-template>
                    <div class="card p-3 mb-3 mt-2">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex">
                                <input type="searche" class="form-control mr-2" placeholder="Filtre par catégorie" style="width: 15em;">
                                <input type="searche" class="form-control mr-2" placeholder="Filtre par prix d'achat" style="width: 15em;">
                            </div>
                            <div>
                                <button class="btn btn-success" tooltip="Ajouter un prix d'achat"><i class="fas fa-plus-circle"></i> Ajouter</button>
                            </div>
                        </div>
                    </div>

                    <div class="card card-box border-0">
                        <div class="card-header p-3">
                            <div class="card-header--title font-weight-bold">Liste des differents prix d'achat</div>
                            <div class="card-header--actions">
                                <div class="btn-group" dropdown>
                                    <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                                        dropdownToggle
                                        tooltip="Action"
                                        type="button">
                                        <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                                    </button>
                                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                                        <ul class="nav nav-pills flex-column p-3">
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-3">
                            <div class="table-responsive m-0">
                                <table class="table table-hover text-nowrap m-0">
                                    <thead>
                                        <tr>
                                            <th>code</th>
                                            <th>ref ressource fouenisseur</th>
                                            <th>prix unitaire HT</th>
                                            <th>TVA(%)</th>
                                            <th>prix unitaire TTC</th>
                                            <th>remise(%)</th>
                                            <th>prix TTC avec remise</th>
                                            <th>etat</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>18</td>
                                            <td>ref128</td>
                                            <td>120 000 000</td>
                                            <td>18</td>
                                            <td>21600000</td>
                                            <td>5</td>
                                            <td>1000000</td>
                                            <td>Achat</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card-footer p-3">
                            <div class="d-flex justify-content-between">
                                <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                                <div class="d-flex align-items-center">
                                    <span>Show</span>
                                    <select class="mx-1 form-control form-control-sm" id="" name="">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="40">50</option>
                                        <option value="40">100</option>
                                    </select>
                                    <span>entries</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
                <tab>
                    <ng-template tabHeading><span>Prix de vente</span></ng-template>
                    <div class="card p-3 mb-3 mt-2">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex">
                                <input type="searche" class="form-control mr-2" placeholder="Filtre par catégorie" style="width: 15em;">
                                <input type="searche" class="form-control mr-2" placeholder="Filtre par prix de vente" style="width: 15em;">
                            </div>
                            <div>
                                <button class="btn btn-success" tooltip="Ajouter un prix de vente"><i class="fas fa-plus-circle"></i> Ajouter</button>
                            </div>
                        </div>
                    </div>

                    <div class="card card-box border-0">
                        <div class="card-header p-3">
                            <div class="card-header--title font-weight-bold">Liste des differents prix de vente</div>
                            <div class="card-header--actions">
                                <div class="btn-group" dropdown>
                                    <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                                        dropdownToggle
                                        tooltip="Action"
                                        type="button">
                                        <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                                    </button>
                                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                                        <ul class="nav nav-pills flex-column p-3">
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-3">
                            <div class="table-responsive m-0">
                                <table class="table table-hover text-nowrap m-0">
                                    <thead>
                                        <tr>
                                            <th>code</th>
                                            <th>ref ressource fouenisseur</th>
                                            <th>prix unitaire HT</th>
                                            <th>TVA(%)</th>
                                            <th>prix unitaire TTC</th>
                                            <th>remise(%)</th>
                                            <th>prix TTC avec remise</th>
                                            <th>etat</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>18</td>
                                            <td>ref128</td>
                                            <td>120 000 000</td>
                                            <td>18</td>
                                            <td>21600000</td>
                                            <td>5</td>
                                            <td>1000000</td>
                                            <td>Achat</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card-footer p-3">
                            <div class="d-flex justify-content-between">
                                <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                                <div class="d-flex align-items-center">
                                    <span>Show</span>
                                    <select class="mx-1 form-control form-control-sm" id="" name="">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="40">50</option>
                                        <option value="40">100</option>
                                    </select>
                                    <span>entries</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
            </tabset>
        </div>
    </tab>

    <!-- Fichiers joints -->
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span><i class="fas fa-file"></i> Fichiers joints</span></ng-template>
        <tabset type="tabs" class="tabs-animated tabs-animated-line" [justified]="true"></tabset>
    </tab>
    <!-- Fin fichiers joints -->
</tabset>