import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilOptions } from 'src/app/shared-components/util/util-option';

@Component({
  selector: 'app-reserve-edit',
  templateUrl: './reserve-edit.component.html',
  styleUrls: ['./reserve-edit.component.scss']
})
export class ReserveEditComponent implements OnInit {
  form: FormGroup;
  required = UtilOptions.required;
  gravite = UtilOptions.gravite;
  submit = false;

  constructor(
    private formBuild: FormBuilder,
  ) {
    this.newForm();
  }

  ngOnInit() {
  }
  newForm() {
    this.form = this.formBuild.group({
      id: [null],
      libelle: [null, [Validators.required]],
      chantier: [null, [Validators.required]],
      dateDebut: [null, [Validators.required]] ,
      dateFin: [null, [Validators.required]],
      gravite: [null, [Validators.required]] ,
      budget: [null, [Validators.required]],
      financeur: [null, [Validators.required]] ,
      document: [null, [Validators.required]],
      description: [null, [Validators.required]],
    });
  }
  onSubmit(){}

  get f() { return this.form.controls; }

}
