import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Utilisateur } from '../../utilisateur-pages/utilisateur.model';

interface ResponsableControlData {
  id?: string,
  nom?: string,
  fonction?: string,
  contact?: string,
  email?: string,
  utilisateur?: Array<Utilisateur>,
}

@Injectable({
  providedIn: 'root'
})
export class ResponsableControlService {
    private assignData;
    apiUrl = environment.apiUrl;
    prefix = environment.prefix;

    constructor(private http:HttpClient) { }
    
    setRespoControl(renponsable) {
        this.assignData = renponsable;
    }

    getRespoControl() {
        return this.assignData;
    }

    create(data:ResponsableControlData): Observable<any> {
        return this.http.post(`${this.apiUrl}/responsable_controles`,JSON.stringify(data)).pipe(
            take(1),
            tap( res => {return res}),
            catchError(this.handleError)
        );   
    }

    update(id: number, data:ResponsableControlData): Observable<any> {
        return this.http.post(`${this.apiUrl}/responsable_controles/${id}`,JSON.stringify(data)).pipe(
            take(1),
            tap( res => {return res}),
            catchError(this.handleError)
        );   
    }
    
    getList(): Observable<any>{
        return this.http.get<[ResponsableControlData]>(`${this.apiUrl}/responsable_controles`,{ responseType: 'json' }).pipe(
            map((response: any) => response['hydra:member']),
            catchError(this.handleError)
        )
    }

    onDelete(id: number): Observable<ResponsableControlData> {
        return this.http.delete(`${this.apiUrl}/responsable_controles/${id}`,{responseType: 'json'}).pipe(
            take(1),
            switchMap( res =>{return res['hydra:member'];}),
            catchError(this.handleError)
        )
    }
    
    handleError(errorMessage: HttpErrorResponse) {
        let messageError = "Une erreur est survenue";
        return throwError(messageError);
    }

}