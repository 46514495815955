<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground"
    [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-note2 display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>Démande de mission </h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">Liste des démande de
                    mission</div>
            </div>
            <div class="flex-fill text-right">
                <button (click)="addMission('', false)" class="btn btn-success mr-2"
                    tooltip="Nouvelle demande de mission" type="button"><i class="fas fa-plus-circle"></i>
                    Mission</button>
            </div>
        </div>
    </div>
</div>

<div >
    <div class="row">
        <div class="col">
            <div class="bg-secondary border p-3 text-center rounded shadow-sm mb-4" title="Nombre total des projets">
                <div>
                    <i class="fas fa-file-alt font-size-xxl mb-2 mt-1 text-first"></i>
                </div>
                <div class="mt-2 line-height-sm">
                    <b class="font-size-lg">{{listMissions.length ? listMissions.length : 0}}</b>
                    <span class="text-black-50 d-block">Mission(s)</span>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="bg-secondary border p-3 text-center rounded shadow-sm mb-4"
                title="Nombre de projet en cours d'execution">
                <div>
                    <i class="fas fa-pause-circle font-size-xxl mb-2 mt-1 text-info"></i>
                </div>
                <div class="mt-2 line-height-sm">
                    <b class="font-size-lg">12</b>
                    <span class="text-black-50 d-block">Mission(s) en cours</span>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="bg-secondary border p-3 text-center rounded shadow-sm mb-4"
                title="Nombre de projet en attente d'execution">
                <div>
                    <i class="far fa-stop-circle font-size-xxl mb-2 mt-1 text-warning"></i>
                </div>
                <div class="mt-2 line-height-sm">
                    <b class="font-size-lg">12</b>
                    <span class="text-black-50 d-block">Mission(s) en attente</span>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="bg-secondary border p-3 text-center rounded shadow-sm mb-4" title="Nombre de projet terminés">
                <div>
                    <fa-icon [icon]="['far', 'check-circle']" class="font-size-xxl text-success"></fa-icon>
                </div>
                <div class="mt-2 line-height-sm">
                    <b class="font-size-lg">12</b>
                    <span class="text-black-50 d-block">Mission(s) cloturée</span>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="bg-secondary border p-3 text-center rounded shadow-sm mb-4" title="Nombre de projet annulés">
                <div>
                    <fa-icon [icon]="['fas', 'times']" class="font-size-xxl text-danger"></fa-icon>
                </div>
                <div class="mt-2 line-height-sm">
                    <b class="font-size-lg">12</b>
                    <span class="text-black-50 d-block">Mission(s) annuler</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="listMissions.length > 0" class="card card-box border-0">
        <div class="card-header p-3">
            <div class="card-header--title font-weight-bold">Liste des demandes de missions</div>
            <div class="card-header--actions">
                <div class="btn-group" dropdown>
                    <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret" dropdownToggle
                        tooltip="Action" type="button">
                        <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                        <ul class="nav nav-pills flex-column p-3">
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i
                                        class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i
                                        class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i
                                        class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i
                                        class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
                <div class="search-wrapper">
                    <span class="icon-wrapper text-black">
                        <fa-icon [icon]="['fas', 'search']"></fa-icon>
                    </span>
                    <input class="form-control" placeholder="Recherche dans la liste" type="search">
                </div>
            </div>
            <div class="table-responsive m-0">
                <table class="table table-hover text-nowrap m-0">
                    <thead>
                        <tr>
                            <th>Demandeur</th>
                            <th>Activité</th>
                            <th>Budget</th>
                            <th>Depart</th>
                            <th>Fin</th>
                            <th>Retour</th>
                            <th>Etat facture</th>
                            <th>Etat mission</th>
                            <th>Crée par</th>
                            <th>Date création</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let mission of listMissions">
                            <td>{{mission?.demandeur}}</td>
                            <td>{{mission?.motif}}</td>
                            <td>{{mission?.budget ? mission?.budget : 0  | number:'': 'fr-FR'}} XOF</td>
                            <td>{{mission?.dateD | date: 'dd/MM/yyyy'}}</td>
                            <td>{{mission?.dateFin}}</td>
                            <td>{{mission?.dateR | date: 'dd/MM/yyyy'}}</td>
                            <td>
                                <span
                                    class="badge badge-{{mission?.etatFacture == 'Soldée'? 'success': mission?.etatFacture == 'En cours de paiement'? 'warning': 'danger'}}">{{mission?.etatFacture}}</span>
                            </td>
                            <td>
                                <span *ngIf="mission?.statut == 0" class="badge badge-primary">En attente
                                    d'approbation</span>
                                <span *ngIf="mission?.statut == 1" class="badge badge-info">En attente de validation
                                    par le DG</span>
                                <span *ngIf="mission?.statut == 2" class="badge badge-success">Mission validée</span>
                                <span *ngIf="mission?.statut == 3" class="badge badge-warning">Mission annulée</span>
                                <span *ngIf="mission?.statut == 4" class="badge badge-danger">Mission rejetée</span>
                                <span *ngIf="mission?.statut == 5" class="badge badge-info">Mission en cours</span>
                                <span *ngIf="mission?.statut == 6" class="badge badge-success">Mission cloturée</span>
                                <!-- <span class="badge badge-{{mission?.statut == 'En cours'? 'info': mission?.statut == 'Terminer'? 'success': mission?.statut == 'Annuler'? 'danger': 'warning'}}">{{mission?.statut}}</span> -->
                            </td>
                            <td>{{mission?.creerPar}}</td>
                            <td>{{mission?.published | date: 'dd/MM/yyyy'}}</td>
                            <td>
                                <button class="btn p-0 d-40 rounded-circle btn-primary mr-1" type="button"
                                    tooltip="Plus d'info" [routerLink]="['/pages/mission/detail',mission?.id]">
                                    <i class="ion-md-eye"></i>
                                </button>
                                <button class="btn p-0 d-40 rounded-circle btn-info mr-1" type="button"
                                    tooltip="Modifier">
                                    <i class="fas fa-pen"></i>
                                </button>
                                <button class="btn p-0 d-40 rounded-circle btn-success mr-1" type="button"
                                    tooltip="Clôturer" *ngIf="mission.etatMission !== 'Terminer'"
                                    (click)="closeMissionModal.show()">
                                    <i class="fas fa-sign-out-alt"></i>
                                </button>
                                <button class="btn p-0 d-40 rounded-circle btn-warning mr-1" type="button"
                                    tooltip="Imprimer">
                                    <i class="ion-md-print"></i>
                                </button>
                                <button  [swal]="deleteSwal" (confirm)="deleteFournisseur(mission.id)"
                                    class="btn p-0 d-40 rounded-circle btn-danger" type="button" tooltip="Supprimer">
                                    <i class="ion-md-trash"></i>
                                </button>

                                <swal type="warning" #deleteSwal title="Supprimer"
                                    text="Voulez vous supprimer cette mission ?" [showCancelButton]="true"
                                    [focusCancel]="true" confirmButtonColor="#d33" cancelButtonColor="#6e7881"
                                    cancelButtonText='Annuler <i class="fas fa-times"></i>'
                                    confirmButtonText='Supprimer <i class="fas fa-trash"></i>'>
                                </swal>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="font-weight-bold" colspan="2">Total: </td>
                            <td colspan="9">00000</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <div class="card-footer p-3">
            <div class="d-flex justify-content-between">
                <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary">
                </pagination>
                <div class="d-flex align-items-center">
                    <span>Show</span>
                    <select class="mx-1 form-control form-control-sm" id="" name="">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="40">50</option>
                        <option value="40">100</option>
                    </select>
                    <span>entries</span>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- LES MODALS -->
<!--MODAL AJOUT DE MISSION-->
<div #newMissionModal="bs-modal" backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Formulaire d'ajout de démande de mission</h6>
                <button type="button" class="close" aria-label="Close" (click)="newMissionModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <!-- <app-mission-edit></app-mission-edit> -->
            </div>
        </div>
    </div>
</div>

<!-- CLOTURE DE MISSION -->
<div #closeMissionModal="bs-modal" backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Formulaire de cloture de mission</h6>
                <button type="button" class="close" aria-label="Close" (click)="closeMissionModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4">
                <div class="d-flex justify-content-between mb-3">
                    <div>
                        <h4 class="font-weight-bold m-0">Informations générales</h4>
                    </div>
                </div>
                <hr>
                <form [formGroup]="closeForm" (ngSubmit)="onSubmit()">
                    <div class="form-row">
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <label for="dateRetour">Date retour: <span class="text-danger">*</span></label>
                                <div class="input-group input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon>
                                        </span>
                                    </div>
                                    <input type="text" id="dateRetour" formControlName="dateRetour" class="form-control"
                                        placeholder="Date retour"
                                        [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                                        [ngClass]="{ 'is-invalid': submit && f.dateRetour.errors, 'is-valid': submit && f.dateRetour.valid }">
                                    <div class="invalid-feedback" *ngIf="submit && f.dateRetour.errors">
                                        <div *ngIf="f.dateRetour.errors.required">{{required}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <label for="carburant">Carburant consommé (L): </label>
                                <input type="number" id="carburant" formControlName="carburant" class="form-control"
                                    placeholder="Carburant (L)">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="position-relative form-group">
                                <label for="montant">Montant du carburant </label>
                                <input type="number" id="montant" formControlName="montant" class="form-control"
                                    placeholder="Montant">
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-5">
                            <div class="position-relative form-group">
                                <label for="km">Kilometrage de retour </label>
                                <input type="number" id="km" formControlName="km" class="form-control"
                                    placeholder="Kilometrage de retour">
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="position-relative form-group">
                                <label for="fichier">Fichier </label>
                                <div class="custom-file">
                                    <input type="file" id="fichier" formControlName="fichier" class="custom-file-input"
                                        title="Joindre un fichier">
                                    <label class="custom-file-label" for="photo">Fichier</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-12">
                            <label for="rapport">Rapport de mission </label>
                            <textarea id="rapport" formControlName="rapport" class="form-control" rows="6"
                                placeholder="Entrer le texte ici"></textarea>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer px-4 py-2">
                <div class="text-right">
                    <button class="btn btn-success" (click)="onSubmit()"><i class="fas fa-sign-out-alt mr-1"></i>
                        Clôre</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- FIN DES MODALS -->