<div class="modal-header modal-dark  bg-plum-plate">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="p-4">
        <div class="d-flex justify-content-between mb-3">
            <div><h4 class="font-weight-bold m-0">Informations générales</h4></div>
        </div><hr>
        <div class="px-2">
            <div class="form-row">
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="type">Type </label>
                        <div class="input-group">
                            <select  class="form-control" id="type" formControlName="type">
                                <option *ngFor="let item of listeTypes" [ngValue]="item['@id']">{{ item?.nom }}</option>
                            </select>
                            <button tooltip="Ajouter un maitre d'ouvrage" placement="auto" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newTypeModal.show()">
                                <span class="btn-wrapper--icon">
                                    <i class="ion-md-add"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="numero">Numéro du Projet: <span class="text-danger mr-2">*</span></label>
                        <input type="text" class="form-control" id="numero" formControlName="numero" placeholder="Numero du projet" title="Numero du projet"
                        [ngClass]="{ 'is-invalid': submit && f.numero.errors, 'is-valid': submit && f.numero.valid }">
                        <div class="invalid-feedback" *ngIf="submit && f.numero.errors">
                            <div *ngIf="f.numero.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="libelle">Libellé du Projet: <span class="text-danger mr-2">*</span></label>
                        <input type="text" class="form-control" id="libelle" formControlName="libelle" placeholder="Libellé du projet" title="Libellé du projet"
                        [ngClass]="{ 'is-invalid': submit && f.libelle.errors, 'is-valid': submit && f.libelle.valid }">
                        <div class="invalid-feedback" *ngIf="submit && f.libelle.errors">
                            <div *ngIf="f.libelle.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="dateDebut">Date début: <span class="text-danger mr-2">*</span></label>
                        <div class="input-group input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                            </div>
                            <input type="text" id="dateDebut" formControlName="dateDebut" class="form-control"
                            [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                            placeholder="Date début du projet" title="Date début du projet"
                            [ngClass]="{ 'is-invalid': submit && f.dateDebut.errors, 'is-valid': submit && f.dateDebut.valid }">
                            <div class="invalid-feedback" *ngIf="submit && f.dateDebut.errors">
                                <div *ngIf="f.dateDebut.errors.required">{{required.novide}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="dateFin">Date fin: <span class="text-danger mr-2">*</span></label>
                        <div class="input-group input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                            </div>
                            <input type="text" id="dateFin" formControlName="dateFin" class="form-control"
                            [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                            placeholder="Date fin du projet" title="Date fin du projet"
                            [ngClass]="{ 'is-invalid': submit && f.dateFin.errors, 'is-valid': submit && f.dateFin.valid }">
                            <div class="invalid-feedback" *ngIf="submit && f.dateFin.errors">
                                <div *ngIf="f.dateFin.errors.required">{{required.novide}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="photo">Photo:</label>
                        <div class="custom-file">
                            <input type="file" id="photo" formControlName="photo" class="custom-file-input" title="Joindre un fichier" (change)="onSelectImg($event)" multiple>
                            <label class="custom-file-label" for="photo">Choisir une photo</label>
                        </div>
                    </div>
                </div>
            </div>
            <hr *ngIf="imgUrl.length > 0">
            <div class="d-flex">
                <a class="card-img-wrapper shadow-sm rounded-sm mr-2" *ngFor="let img of imgUrl"  style="height: 100px; width: 100px;">
                    <div class="img-wrapper-overlay">
                        <div class="overlay-btn-wrapper">
                            <button class="btn btn-danger p-0 d-50 rounded-circle" type="button" (click)="deleteImg(img)">
                                <span class="btn-wrapper--icon">
                                    <i class="ion-md-trash"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                    <img alt="..." class="card-img-top rounded-sm" [src]="img" style="height: 100px; width: 100px;">
                </a>
            </div>
            <hr *ngIf="imgUrl.length > 0">
            <div class="form-row">
                <div class="col-md-12">
                    <div class="position-relative form-group">
                        <label for="description">Description:</label>
                        <textarea formControlName="description" id="description" cols="30" rows="2" class="form-control" title="Description" placeholder="Description du projet"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <hr>
    
        <div class="my-3"><h4 class="font-weight-bold m-0">Localisation du projet</h4></div><hr>
        <div class="px-2">
            <div class="form-row">
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="pays">Pays: <span class="text-danger mr-2">*</span></label>
                        <input type="text" id="pays" formControlName="pays" class="form-control" placeholder="Pays" title="Pays"
                        [ngClass]="{ 'is-invalid': submit && f.pays.errors, 'is-valid': submit && f.pays.valid }">
                        <div class="invalid-feedback" *ngIf="submit && f.pays.errors">
                            <div *ngIf="f.pays.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="ville">Ville: <span class="text-danger mr-2">*</span></label>
                        <input type="text" id="ville" formControlName="ville" class="form-control" placeholder="Ville" title="Ville"
                        [ngClass]="{ 'is-invalid': submit && f.ville.errors, 'is-valid': submit && f.ville.valid }">
                        <div class="invalid-feedback" *ngIf="submit && f.ville.errors">
                            <div *ngIf="f.ville.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="commune">Commune: </label>
                        <input type="text" id="commune" formControlName="commune" class="form-control" placeholder="Commune" title="Commune">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="quartier">Quartier: </label>
                        <input type="text" id="quartier" formControlName="quartier" class="form-control" placeholder="Quartier" title="Quartier">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="longitude">Longitude: <span class="text-danger mr-2">*</span></label>
                        <input type="number" min="0" id="longitude" formControlName="longitude" class="form-control" placeholder="Longitude" title="Longitude"
                        [ngClass]="{ 'is-invalid': submit && f.longitude.errors, 'is-valid': submit && f.longitude.valid }">
                        <div class="invalid-feedback" *ngIf="submit && f.longitude.errors">
                            <div *ngIf="f.longitude.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="latitude">Latitude: <span class="text-danger mr-2">*</span></label>
                        <input type="number" min="0" id="latitude" formControlName="latitude" class="form-control" placeholder="Latitude" title="Latitude"
                        [ngClass]="{ 'is-invalid': submit && f.latitude.errors, 'is-valid': submit && f.latitude.valid }">
                        <div class="invalid-feedback" *ngIf="submit && f.latitude.errors">
                            <div *ngIf="f.latitude.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>    
        <div class="my-3"><h4 class="font-weight-bold m-0">Poste de responsabilités</h4></div><hr>
        <div class="px-2">
            <div class="form-row">
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="chefProjet">Chef de projet: <span class="text-danger mr-2">*</span></label>
                        <div class="input-group">
                            <select  class="form-control" id="chefProjet" formControlName="chefProjet" title="Chef du projet"
                            [ngClass]="{ 'is-invalid': submit && f.chefProjet.errors, 'is-valid': submit && f.chefProjet.valid }">
                                <option *ngFor="let item of listeChefs" [ngValue]="item['@id']">{{ item?.utilisateur['nom'] }} {{ item?.utilisateur['prenom'] }}</option>
                            </select>
                            <button tooltip="Ajouter un maitre d'ouvrage" placement="auto" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newChefModal.show()">
                                <span class="btn-wrapper--icon">
                                    <i class="ion-md-add"></i>
                                </span>
                            </button>
                        </div>
                        <div class="invalid-feedback" *ngIf="submit && f.chefProjet.errors">
                            <div *ngIf="f.chefProjet.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="architecte">Architect: </label>
                        <div class="input-group">
                            <select  class="form-control" id="architecte" formControlName="architecte" title="Architect">
                                <option *ngFor="let item of listeChefs" [ngValue]="item['@id']">{{ item?.utilisateur['nom'] }} {{ item?.utilisateur['prenom'] }}</option>
                            </select>
                            <button tooltip="Ajouter un architect" placement="auto" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newArchitectModal.show()">
                                <span class="btn-wrapper--icon">
                                    <i class="ion-md-add"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="responsableEtude">Responsable d'étude</label>
                        <div class="input-group">
                            <select  class="form-control" id="responsableEtude" formControlName="responsableEtude" title="responsable etude">
                                <option *ngFor="let item of listeResponsableEtude" [ngValue]="item['@id']">{{ item?.utilisateur['nom'] }} {{ item?.utilisateur['prenom'] }}</option>
                            </select>
                            <button tooltip="Ajouter un architect" placement="auto" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newRespoEModal.show()">
                                <span class="btn-wrapper--icon">
                                    <i class="ion-md-add"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="responsableControle">Responsable de controlle</label>
                        <div class="input-group">
                            <select  class="form-control" id="responsableControle" formControlName="responsableControle" title="responsable controlle">
                                <option *ngFor="let item of listeResponsableControl" [ngValue]="item['@id']">{{ item?.utilisateur['nom'] }} {{ item?.utilisateur['prenom'] }}</option>
                            </select>
                            <button tooltip="Ajouter un responsonble" placement="auto" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newRespoCModal.show()">
                                <span class="btn-wrapper--icon">
                                    <i class="ion-md-add"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="maitreOuvrage">Maître d'ouvrage: <span class="text-danger mr-2">*</span></label>
                        <select id="maitreOuvrage" formControlName="maitreOuvrage" class="form-control" title="Maître d'ouvrage"
                            [ngClass]="{ 'is-invalid': submit && f.maitreOuvrage.errors, 'is-valid': submit && f.maitreOuvrage.valid }">
                            <option *ngFor="let item of listeMaitre" [ngValue]="item['@id']">{{ item?.utilisateur['nom'] }} {{ item?.utilisateur['prenom'] }}</option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submit && f.maitreOuvrage.errors">
                            <div *ngIf="f.maitreOuvrage.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="membreProjet">Membres du projet: </label>
                        <div class="input-group">
                            <select  class="form-control" id="membreProjet" formControlName="membreProjet" title="Membre projet">
                                <option *ngFor="let item of listeMembre" [ngValue]="item['@id']">{{ item?.utilisateur['nom'] }} {{ item?.utilisateur['prenom'] }}</option>
                            </select>
                            <button tooltip="Ajouter un architect" placement="auto" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newMembreModal.show()">
                                <span class="btn-wrapper--icon">
                                    <i class="ion-md-add"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
    
        <div class="my-3"><h4 class="font-weight-bold m-0">Trésorerie</h4></div><hr>
        <div class="px-2">
            <div class="form-row">
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="marche">Prix du marché: <span class="text-danger mr-2">*</span></label>
                        <input type="number" min="0" class="form-control" id="marche" formControlName="marche" placeholder="Prix du marché"
                        title="Prix du marché" [ngClass]="{ 'is-invalid': submit && f.marche.errors, 'is-valid': submit && f.marche.valid }">
                        <div class="invalid-feedback" *ngIf="submit && f.marche.errors">
                            <div *ngIf="f.marche.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="banque">Banque affiliée : <span class="text-danger mr-2">*</span></label>
                        <select formControlName="banque" class="form-control" id="banque" title="Banque affiliées"
                        [ngClass]="{ 'is-invalid': submit && f.banque.errors, 'is-valid': submit && f.banque.valid }">
                            <option *ngFor="let item of listeBanques" [ngValue]="item['@id']">{{ item?.libelle }}</option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submit && f.banque.errors">
                            <div *ngIf="f.banque.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="caisse">Caisse affiliée : <span class="text-danger mr-2">*</span></label>
                        <select formControlName="caisse" class="form-control" id="caisse" title="Caisse"
                            [ngClass]="{ 'is-invalid': submit && f.caisse.errors, 'is-valid': submit && f.caisse.valid }">
                        <option *ngFor="let item of listeCaisses" [ngValue]="item['@id']">{{ item?.libelle }}</option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submit && f.caisse.errors">
                            <div *ngIf="f.caisse.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="m-0">
    <div class=" px-4 py-2">
        <div class="row">
            <div class="col-md-6">
                <p class="text-danger mt-2">L'étoile (*) signifie que cet champ est obligatoire.</p>
            </div>
            <div class="col-md-6 text-right">
                <button (click)="onClose()" type="button" class="btn btn-secondary mr-2" data-dismiss="modal">
                  <i class="fas fa-times-circle"></i> Fermer
                </button>
                <button class="btn btn-first mr-2" type="button" (click)="form.reset()">
                    <i class="fas fa-broom  mr-2"></i> Nettoyer
                </button>
                <button  class="btn btn-success" type="submit">
                    <i class="fas fa-save mr-2"></i> Enregistrer
                </button>
            </div>
        </div>
    </div>
</form>

<!-- TYPE DE PROJET -->
<div #newTypeModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded border-0">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Creer un noveau type</h6>
                <button type="button" class="close" aria-label="Close" (click)="newTypeModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="p-3">
                    <form [formGroup]="typeForm" (ngSubmit)="submitType()">
                        <div class="form-row">
                            <div class="col-md-12">
                                <div class="position-relative form-group">
                                    <label for="libelle">Libellé <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" id="libelle" formControlName="libelle"
                                    placeholder="Entrer le libelle du type" [ngClass]="{ 'is-invalid': submit && type.libelle.errors }">
                                    <div *ngIf="submit && type.libelle.errors" class="invalid-feedback">
                                        <div *ngIf="type.libelle.errors.required">{{required.novide}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="text-center px-3 py-2">
                    <button class="btn btn-secondary m-2" type="button" (click)="newTypeModal.hide()">
                        <i class="fas fa-times-circle"></i> Fermer
                    </button>
                    <button class="btn btn-first m-2" type="button" (click)="typeForm.reset()">
                        <i class="fas fa-broom  mr-2"></i> Nettoyer
                    </button>
                    <button class="btn btn-success" type="submit" (click)="submitType()">
                        <i class="fas fa-save mr-1"></i> Enregister
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- CHEF DE PROJET -->
<div #newChefModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered border-0">
        <div class="modal-content border-0">
            <div class="modal-header modal-dark bg-plum-plate">
                <h6 class="modal-title">Ajouter un chef de projet</h6>
                <button type="button" class="close" aria-label="Close" (click)="newChefModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="p-3">
                <form [formGroup]="chefForm" (ngSubmit)="submitChef()">
                    <div class="form-row">
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="utilisateur">Nom <span class="text-danger">*</span></label>
                                <select class="form-control" id="utilisateur" formControlName="utilisateur">
                                    <option *ngFor="let item of listeUser" [ngValue]="item['@id']">{{ item.nom }} {{ item.prenom }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="text-center px-3 py-2">
                        <button class="btn btn-secondary m-2" type="button" (click)="newChefModal.hide()">
                            <i class="fas fa-times-circle"></i> Fermer
                        </button>
                        <button class="btn btn-first m-2" type="button" (click)="chefForm.reset()">
                            <i class="fas fa-broom  mr-2"></i> Nettoyer
                        </button>
                        <button class="btn btn-success" type="submit">
                            <i class="fas fa-save mr-1"></i> Enregister
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- ARCHITECTE -->
<div #newArchitectModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered border-0">
        <div class="modal-content border-0">
            <div class="modal-header modal-dark bg-plum-plate">
                <h6 class="modal-title">Ajouter un architect</h6>
                <button type="button" class="close" aria-label="Close" (click)="newArchitectModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="p-3">
                <form [formGroup]="architectForm" (ngSubmit)="submitArchitect()">
                    <div class="form-row">
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="utilisateur">Nom <span class="text-danger">*</span></label>
                                <select class="form-control" id="utilisateur" formControlName="utilisateur">
                                    <option *ngFor="let item of listeUser" [ngValue]="item['@id']">{{ item.nom }} {{ item.prenom }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="text-center px-3 py-2">
                        <button class="btn btn-secondary m-2" type="button" (click)="newArchitectModal.hide()">
                            <i class="fas fa-times-circle"></i> Fermer
                        </button>
                        <button class="btn btn-first m-2" type="button" (click)="architectForm.reset()">
                            <i class="fas fa-broom  mr-2"></i> Nettoyer
                        </button>
                        <button class="btn btn-success" type="submit">
                            <i class="fas fa-save mr-1"></i> Enregister
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- RESPONSABLE D'ETUDE -->
<div #newRespoEModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered border-0">
        <div class="modal-content border-0">
            <div class="modal-header modal-dark bg-plum-plate">
                <h6 class="modal-title">Ajouter un responsable d'etude</h6>
                <button type="button" class="close" aria-label="Close" (click)="newRespoEModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="p-3">
                <form [formGroup]="respoEtudeForm" (ngSubmit)="submitRespoEtude()">
                    <div class="form-row">
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="utilisateur">Nom <span class="text-danger">*</span></label>
                                <select class="form-control" id="utilisateur" formControlName="utilisateur">
                                    <option *ngFor="let item of listeUser" [ngValue]="item['@id']">{{ item.nom }} {{ item.prenom }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="text-center px-3 py-2">
                        <button class="btn btn-secondary m-2" type="button" (click)="newRespoEModal.hide()">
                            <i class="fas fa-times-circle"></i> Fermer
                        </button>
                        <button class="btn btn-first m-2" type="button" (click)="respoEtudeForm.reset()">
                            <i class="fas fa-broom  mr-2"></i> Nettoyer
                        </button>
                        <button class="btn btn-success" type="submit">
                            <i class="fas fa-save mr-1"></i> Enregister
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- RESPONSABLE CONTROL -->
<div #newRespoCModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered border-0">
        <div class="modal-content border-0">
            <div class="modal-header modal-dark bg-plum-plate">
                <h6 class="modal-title">Ajouter un responsable de control</h6>
                <button type="button" class="close" aria-label="Close" (click)="newRespoCModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="p-3">
                <form [formGroup]="respoControlForm" (ngSubmit)="submitRespoControl()">
                    <div class="form-row">
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="utilisateur">Nom <span class="text-danger">*</span></label>
                                <select class="form-control" id="utilisateur" formControlName="utilisateur">
                                    <option *ngFor="let item of listeUser" [ngValue]="item['@id']">{{ item.nom }} {{ item.prenom }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="text-center px-3 py-2">
                        <button class="btn btn-secondary m-2" type="button" (click)="newRespoCModal.hide()">
                            <i class="fas fa-times-circle"></i> Fermer
                        </button>
                        <button class="btn btn-first m-2" type="button" (click)="respoControlForm.reset()">
                            <i class="fas fa-broom  mr-2"></i> Nettoyer
                        </button>
                        <button class="btn btn-success" type="submit">
                            <i class="fas fa-save mr-1"></i> Enregister
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- MEMBRE DU PROJET -->
<div #newMembreModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered border-0">
        <div class="modal-content border-0">
            <div class="modal-header modal-dark bg-plum-plate">
                <h6 class="modal-title">Ajouter un membre au projet</h6>
                <button type="button" class="close" aria-label="Close" (click)="newMembreModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="p-3">
                <form [formGroup]="membreForm" (ngSubmit)="submitMembre()">
                    <div class="form-row">
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="utilisateur">Nom <span class="text-danger">*</span></label>
                                <select class="form-control" id="utilisateur" formControlName="utilisateur">
                                    <option *ngFor="let item of listeUser" [ngValue]="item['@id']">{{ item.nom }} {{ item.prenom }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="text-center px-3 py-2">
                        <button class="btn btn-secondary m-2" type="button" (click)="newMembreModal.hide()">
                            <i class="fas fa-times-circle"></i> Fermer
                        </button>
                        <button class="btn btn-first m-2" type="button" (click)="membreForm.reset()">
                            <i class="fas fa-broom  mr-2"></i> Nettoyer
                        </button>
                        <button class="btn btn-success" type="submit">
                            <i class="fas fa-save mr-1"></i> Enregister
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>