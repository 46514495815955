<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-wallet display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
              <h1>Détail trésorerie </h1>
              <div *ngIf="globals.pageTitleDescription" class="app-page-title--description text-uppercase">{{ tresorerie?.type }}</div>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button [routerLink]="['/pages/tresorerie/liste']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-left"></i> Retour à la liste</button>
        </div>
    </div>
</div>
<accordion [closeOthers]="true" [isAnimated]="true" class="accordion mb-4">
    <accordion-group class="card card-box">
        <button accordion-heading class="btn btn-link btn-lg d-flex align-items-center justify-content-between"
                type="button">
            <span>Clique pour dérouler</span>
            <fa-icon [icon]="['fas', 'angle-up']" class="font-size-xl"></fa-icon>
        </button>
        <div class="m-3 border p-1">
            <div class="row">
                <!-- LA ZONE DE GAUCHE -->
                <div class="col-md-6">
                    <div class="row mt-1">
                        <div class="col-md-6 font-weight-bolder">Référence: </div>
                        <div class="col-md-6">{{ tresorerie?.reference }}</div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div class="row">
                        <div class="col-md-6 font-weight-bold">Type de trésorerie: </div>
                        <div class="col-md-6"><span class="badge badge-{{tresorerie?.type == 'Caisse' ? 'success': 'warning'}}">{{ tresorerie?.type }}</span></div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div class="row" *ngIf="tresorerie?.type !== 'Banque'">
                        <div class="col-md-6 font-weight-bolder">Désignation: </div>
                        <div class="col-md-6">{{ tresorerie?.designation }}</div>
                    </div>
                    <div class="row" *ngIf="tresorerie?.type == 'Banque'">
                        <div class="col-md-6 font-weight-bolder">Nom de la banque: </div>
                        <div class="col-md-6">{{ tresorerie?.nomBanque }}</div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div class="row">
                        <div class="col-md-6 font-weight-bolder">Dévise: </div>
                        <div class="col-md-6">{{ tresorerie?.devise }}</div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div class="row">
                        <div class="col-md-6 font-weight-bold">Etat: </div>
                        <div class="col-md-6">
                            <span class="badge badge-{{tresorerie?.etat == 'Ouvert' ? 'success': 'warning'}}">{{ tresorerie?.etat }}</span>
                        </div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div class="row">
                        <div class="col-md-6 font-weight-bolder">Gérant: </div>
                        <div class="col-md-6">{{ tresorerie?.gerant }}</div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div class="row">
                        <div class="col-md-6 font-weight-bolder">Catégorie: </div>
                        <div class="col-md-6">{{ tresorerie?.categorie }}</div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div class="row">
                        <div class="col-md-6 font-weight-bolder">Date: </div>
                        <div class="col-md-6">{{ tresorerie?.date }}</div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div class="row">
                        <div class="col-md-6 font-weight-bold">Solde initial: </div>
                        <div class="col-md-6">{{ tresorerie?.soldeInitial | number:'': 'fr-FR'}} XOF</div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div *ngIf="tresorerie?.type == 'Banque'">
                        <div class="row">
                            <div class="col-md-6 font-weight-bolder">Numero de compte: </div>
                            <div class="col-md-6">{{ tresorerie?.numCompte }}</div>
                        </div>
                        <div class="divider mt-2 mb-2"></div>
                        <div class="row">
                            <div class="col-md-6 font-weight-bolder">Code IBAN: </div>
                            <div class="col-md-6">{{ tresorerie?.codeIban }}</div>
                        </div>
                        <div class="divider mt-2 mb-2"></div>
                        <div class="row">
                            <div class="col-md-6 font-weight-bold">Code BIC/SWIFT: </div>
                            <div class="col-md-6">{{ tresorerie?.codeBicSwift }}</div>
                        </div>
                        <div class="divider mt-2 mb-2"></div>
                    </div>
                </div>

                <!-- LA ZONE DE DROITE -->
                <div class="col-md-6">
                    <div class="row mt-1">
                        <div class="col-md-6 font-weight-bolder">Solde minimum autorisé: </div>
                        <div class="col-md-6">{{ tresorerie?.soldeMinAutorise | number:'': 'fr-FR'}} XOF</div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div class="row">
                        <div class="col-md-6 font-weight-bolder">Solde minimum désiré: </div>
                        <div class="col-md-6">{{ tresorerie?.soldeMinDesire | number:'': 'fr-FR'}} XOF</div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div class="row">
                        <div class="col-md-6 font-weight-bold">Seuil maximum: </div>
                        <div class="col-md-6">{{ tresorerie?.sueilMax | number:'': 'fr-FR'}} XOF</div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div class="row">
                        <div class="col-md-6 font-weight-bold">Seuil minimum: </div>
                        <div class="col-md-6">{{ tresorerie?.sueilMin | number:'': 'fr-FR'}} XOF</div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div class="row">
                        <div class="col-md-6 font-weight-bolder">Compte comptable: </div>
                        <div class="col-md-6">{{ tresorerie?.compteComptable }}</div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div class="row">
                        <div class="col-md-6 font-weight-bold">Code journal comptable: </div>
                        <div class="col-md-6">{{ tresorerie?.codeJournal}}</div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div class="row">
                        <div class="col-md-6 font-weight-bold">Date d'enregistrement: </div>
                        <div class="col-md-6">{{ tresorerie?.dateCreation}}</div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div class="row">
                        <div class="col-md-6 font-weight-bold">Date de modification: </div>
                        <div class="col-md-6">{{ tresorerie?.dateModification}}</div>
                    </div>
                    <div class="divider mt-2 mb-2"></div>
                    <div *ngIf="tresorerie?.type == 'Banque'">
                        <div class="row">
                            <div class="col-md-6 font-weight-bolder">Domiciliation: </div>
                            <div class="col-md-6">{{ tresorerie?.domiciliation }}</div>
                        </div>
                        <div class="divider mt-2 mb-2"></div>
                        <div class="row">
                            <div class="col-md-6 font-weight-bolder">Nom du propriétaire de la banque: </div>
                            <div class="col-md-6">{{ tresorerie?.nomProprietaire }}</div>
                        </div>
                        <div class="divider mt-2 mb-2"></div>
                        <div class="row">
                            <div class="col-md-6 font-weight-bold">Adresse de la banque: </div>
                        </div>
                        <div class="divider mt-2 mb-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </accordion-group>
</accordion>

<div class="row">
    <div class="col-xl-12">
        <div class="mb-2">
            <tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow" type="tabs" >
                <!-- Debut tableau de bord -->
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span><i class="fas fa-tachometer-alt"></i>Tableau de Bord</span></ng-template>
                    <div class="row mb-4">
                        <div class="col-md-12">
                            <div class="card card-box p-4 text-center">
                                <h6 class="text-uppercase font-weight-bold mb-1 text-black">total des montants crédit et débit de la {{tresorerie?.type == 'Caisse' ? 'caisse': 'banque'}}</h6>
                                <div class="py-3">
                                    <apx-chart
                                        [chart]="{ type: 'bar', height: '325', sparkline: {enabled: false}, stacked: false, toolbar: {show: false}}"
                                        [colors]="['#f83245', '#1bc943']"
                                        [dataLabels]="{enabled: false}"
                                        [fill]="{opacity: 1}"
                                        [labels]="['Jan 2021', 'Fev 2021', 'Mar 2021', 'Avr 2021', 'Mai 2021', 'Juin 2021', 'Juil 2021', 'Aou 2021', 'Sep 2021', 'Oct 2021', 'Nov 2021', 'Dec 2021']"
                                        [legend]="{ show: false }"
                                        [plotOptions]="{bar: {horizontal: false, columnWidth: '50%'}}"
                                        [series]="[ {name: 'Montant sortie', data: [0, 0, 4.0, 3.8, 5.1, 3.6,4.0, 3.8, 5.1, 3.6, 3.2, 3.3]}, {name: 'Montant entré', data: [2.1, 2.1, 3.0, 2.8, 4.0, 3.8, 5.1, 3.6, 4.1, 2.6, 1.2, 3.9]}]"
                                        [stroke]="{show: true, width: 0, colors: ['transparent']}"
                                    ></apx-chart>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Deuxieme section contenant les cartes de DEBIT, CREDIT et SOLDE -->
                    <div class="row">
                        <div class="col-xl-4 col-md-6">
                            <div class="mb-5 card card-box card-box-border-bottom border-success">
                                <div class="card-body">
                                    <div class="text-center">
                                        <div class="mt-1">
                                            <i class="fas fa-building font-size-xxl text-success"></i>
                                            <span class="text-black-50 ml-2">DEBIT</span>
                                        </div>
                                        <div class="mt-3 line-height-sm">
                                            <b class="font-size-lg pr-1">56 000 000 <span class="text-black-50">XOF</span></b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6">
                            <div class="mb-5 card card-box card-box-border-bottom border-danger">
                                <div class="card-body">
                                    <div class="text-center">
                                        <div class="mt-1">
                                            <i class="fas fa-balance-scale-right font-size-xxl text-danger"></i>
                                            <span class="text-black-50 ml-2">CREDIT</span>
                                        </div>
                                        <div class="mt-3 line-height-sm">
                                            <b class="font-size-lg pr-1">56 000 000 <span class="text-black-50">XOF</span></b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6">
                            <div class="mb-5 card card-box card-box-border-bottom border-first">
                                <div class="card-body">
                                    <div class="text-center">
                                        <div class="mt-1">
                                            <i class=" fas fa-university font-size-xxl text-first"></i>
                                            <span class="text-black-50 ml-2">SOLDE</span>
                                        </div>
                                        <div class="mt-3 line-height-sm">
                                            <b class="font-size-lg pr-1">56 000 000 <span class="text-black-50">XOF</span></b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Fin de la deuxieme section contenant les cartes de DEBIT, CREDIT et SOLDE -->
                    
                    <!-- Historique des approvisionnement -->
                    <div class="card card-box mb-4">
                        <div class="card-header"><h5 class="mb-0 font-weight-bold">Historique des approvisionnements</h5></div>
                        <div class="card-body p-4">
                            <div class="border p-2 mb-4">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="span mt-1">MONTANT TOTAL DES APPROVISIONNEMENTS: 100 000 000 XOF</div>
                                    </div>
                                    <div class="col-md-3 text-right"><button class="btn btn-warning" tooltip="Imprimer la liste"><i class="fas fa-print mr-2"></i>Imprimer</button></div>
                                </div>
                            </div>
                            <div class="border">
                                <div class="table responsive mb-0">
                                    <table class="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Libellé</th>
                                                <th>Etat</th>
                                                <th>En charge de valider</th>
                                                <th>Montant</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Slode 1</td>
                                                <td>Valider par le DG</td>
                                                <td>Protic</td>
                                                <td>50 000 000 XOF</td>
                                                <td>
                                                    <button placement="auto" tooltip="Plus d'info" (click)="openDetailApprovisionnementModal.show()" class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                                                        <span class="btn-wrapper--icon"><i class="fas fa-eye"></i></span>
                                                    </button>
                                                    <button placement="auto" tooltip="Imprimer"  class="btn d-40 p-0 btn-pill m-2 btn-warning" type="button">
                                                        <span class="btn-wrapper--icon"><i class="fas fa-print"></i></span>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Total: </th>
                                                <th></th>
                                                <th></th>
                                                <th><h4 class="mb-0 text-success">50 000 000 XOF</h4></th>
                                                <th></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Fin des historique des approvisionnement -->
                    
                    <!-- Historique des opperations -->
                    <div class="card card-box mb-4">
                        <div class="card-header"><h5 class="mb-0 font-weight-bold">Historique des 30 dernières oppérations</h5></div>
                        <div class="card-body p-4">
                            <div class="border mb-4 p-2">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="position-relative form-group mb-0">
                                            <div class="input-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                                </div>
                                                <input [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }"
                                                    bsDatepicker class="form-control" [textMask]="{mask: maskDate}"
                                                    placeholder="Date debut" type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="position-relative form-group mb-0">
                                            <div class="input-group input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                                </div>
                                                <input [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }"
                                                    bsDatepicker class="form-control" [textMask]="{mask: maskDate}"
                                                    placeholder="Date fin" type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3"><button class="btn btn-primary" tooltip="Afficher touteles transactions"><i class="fas fa-search mr-2"></i> Recherche</button></div>
                                    <div class="col-md-3 text-right"><button class="btn btn-warning" tooltip="Imprimer la liste"><i class="fas fa-print mr-2"></i> Imprimer</button></div>
                                </div>
                            </div>
                            
                            <div class="row mb-1">
                                <div class="col-md-9"></div>
                                <div class="col-md-3 text-right">
                                    <input type="text" class="form-control" placeholder="Recherche">
                                </div>
                            </div>
                            <div class="border ">
                                <div class="table responsive mb-0">
                                    <table class="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Date</th>
                                                <th>Libellé</th>
                                                <th>Initiateur</th>
                                                <th>Validateur</th>
                                                <th>Débit</th>
                                                <th>crédit</th>
                                                <th>Solde</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>IDAppro</td>
                                                <td>01/05/2021 15:06:00</td>
                                                <td>Appro</td>
                                                <td>Ghislain</td>
                                                <td>Ghislain</td>
                                                <td>50 000 000 XOF</td>
                                                <td>50 000 000 XOF</td>
                                                <td>50 000 000 XOF</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>TOTAL:</th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th><h6 class="text-success">50 000 000 XOF</h6></th>
                                                <th><h6 class="text-danger">50 000 000 XOF</h6></th>
                                                <th><h6 class="text-first">50 000 000 XOF</h6></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-{{tresorerie?.type == 'Caisse' ? 'success': 'warning'}}" (click)="ajoutApprovisionnementModal.show()"><i class="fas fa-download"></i>
                                {{tresorerie?.type === 'Caisse' ? 'approvisionnement caisse': 'approvisionnement banque'}}
                            </button>
                        </div>
                    </div>
                    <!-- Fin des historique des opperations -->
                </tab>
                <!-- Fin tableau de bord -->
                
                <!-- Ecriture -->
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span><i class="fas fa-write"></i>Ecritures Bancaires</span></ng-template>
                    <div class="card card-box mb-4 p-3" *ngIf="tresorerie?.type === 'Caisse'">
                        <h5 class="mb-0">Filtre</h5>
                        <div class="divider mt-2 mb-2"></div>
                        <div class="border p-2">
                            <div class="row mb-2">
                                <div class="col-md-3 mb-2">
                                    <div class="custom-radio custom-control">
                                        <input class="custom-control-input" id="fp" name="customRadio" type="radio" [value]="'oui'">
                                        <label class="custom-control-label" for="fp">Fournisseur/Prestataire</label>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-2">
                                    <div class="custom-radio custom-control">
                                        <input class="custom-control-input" id="mission" name="customRadio" type="radio">
                                        <label class="custom-control-label" for="mission">Mission</label>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-2">
                                    <div class="custom-radio custom-control">
                                        <input class="custom-control-input" id="intervention" name="customRadio" type="radio">
                                        <label class="custom-control-label" for="intervention">Intervention</label>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-2">
                                    <div class="custom-radio custom-control">
                                        <input class="custom-control-input" id="location" name="customRadio" type="radio">
                                        <label class="custom-control-label" for="location">Location</label>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-2">
                                    <div class="custom-radio custom-control">
                                        <input class="custom-control-input" id="projet" name="customRadio" type="radio">
                                        <label class="custom-control-label" for="projet">Projet</label>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-2">
                                    <div class="custom-radio custom-control">
                                        <input class="custom-control-input" id="client" name="customRadio" type="radio">
                                        <label class="custom-control-label" for="client">Client</label>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-2">
                                    <div class="custom-radio custom-control">
                                        <input class="custom-control-input" id="decaissement" name="customRadio" type="radio">
                                        <label class="custom-control-label" for="decaissement">Décaissement</label>
                                    </div>
                                </div>
                            </div>
                            <div class="divider mt-1 mb-1"></div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label for="type ml-2">Filtre par type</label>
                                    <select name="" id="" class="form-control" aria-placeholder="Type">
                                        <option value="">Par date de création</option>
                                        <option value="">Par période</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="etat ml-2">Filtre par etat</label>
                                    <select name="" id="" class="form-control" aria-placeholder="Etat">
                                        <option value="">Impayer</option>
                                        <option value="">Payer</option>
                                        <option value="">En attente de paiement</option>
                                        <option value="">En cours de paiement</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="fournisseur ml-2">Filtre par fournisseur</label>
                                    <select name="" id="" class="form-control" placeholder="Fournisseur">
                                        <option value="">GHISLAIN</option>
                                        <option value="">KADER</option>
                                        <option value="">AMON</option>
                                        <option value="">PROTIC</option>
                                    </select>
                                </div>
                                <div class="col-md-3 text-right">
                                    <button placement="auto" tooltip="Recherche"  class="btn d-40 p-0 btn-pill m-2 btn-primary mt-3" type="button">
                                        <span class="btn-wrapper--icon"><i class="fas fa-search"></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card card-box mb-4 p-3" *ngIf="tresorerie?.type === 'Banque'">
                        <h5 class="mb-0">Filtre</h5>
                        <div class="divider mt-2 mb-2"></div>
                        <div class="border p-2">
                            <div class="row mb-2">
                                <div class="col-md-5 mb-2">
                                    <div class="custom-radio custom-control">
                                        <input class="custom-control-input" id="fp" name="customRadio" type="radio" [value]="'oui'">
                                        <label class="custom-control-label" for="fp">Paiement fournisseur/Prestataire</label>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-2">
                                    <div class="custom-radio custom-control">
                                        <input class="custom-control-input" id="client" name="customRadio" type="radio">
                                        <label class="custom-control-label" for="client">Client</label>
                                    </div>
                                </div>
                            </div>
                            <div class="divider mt-1 mb-1"></div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label for="type ml-2">Filtre par type</label>
                                    <select name="" id="" class="form-control" aria-placeholder="Type">
                                        <option value="">Par date de création</option>
                                        <option value="">Par période</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="etat ml-2">Filtre par etat</label>
                                    <select name="" id="" class="form-control" aria-placeholder="Etat">
                                        <option value="">Impayer</option>
                                        <option value="">Payer</option>
                                        <option value="">En attente de paiement</option>
                                        <option value="">En cours de paiement</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="fournisseur ml-2">Filtre par fournisseur</label>
                                    <select name="" id="" class="form-control" placeholder="Fournisseur">
                                        <option value="">GHISLAIN</option>
                                        <option value="">KADER</option>
                                        <option value="">AMON</option>
                                        <option value="">PROTIC</option>
                                    </select>
                                </div>
                                <div class="col-md-3 text-right">
                                    <button placement="auto" tooltip="Recherche"  class="btn d-40 p-0 btn-pill m-2 btn-primary mt-3" type="button">
                                        <span class="btn-wrapper--icon"><i class="fas fa-search"></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card card-box mb-4" *ngIf="fp">
                        <div class="row p-3">
                            <div class="col-md-7"><h5 class="mb-0 mt-2">Resultat du filtre</h5></div>
                            <div class="col-md-3">
                                <input type="text" class="form-control" placeholder="Recherche">
                            </div>
                            <div class="col-md-2 text-right">
                                <button class="btn btn-warning"><i class="fas fa-print mr-2"></i>Imprimer</button>
                            </div>
                        </div>
                        <div class="divider"></div>
                        <div class="card-body p-3">
                            <div class="border">
                                <div class="table-responsive mb-0">
                                    <table class="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Libellé</th>
                                                <th>Concerné</th>
                                                <th>Etat</th>
                                                <th>Montant</th>
                                                <th>Reste à payer</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>lifhffgffhfhf</td>
                                                <td>Ghislain</td>
                                                <td><span class="badge badge-success">Soldé</span></td>
                                                <td>50 000 000 XOF</td>
                                                <td>50 000 000 XOF</td>
                                                <td>
                                                    <button class="btn btn-sm btn-primary" (click)="filterResponseDetailModal.show()"><i class="fas fa-eye" tooltip="Plus d'info"></i></button>
                                                    <button class="btn btn-sm btn-warning ml-2"><i class="fas fa-print" tooltip="Imprimer"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>TOTAL:</th>
                                                <th></th>
                                                <th></th>
                                                <th>50 000 000 XOF</th>
                                                <th>50 000 000 XOF</th>
                                                <th></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card card-box mb-4">
                        <div class="card-header">Historique des opération  bancaires <span class="badge badge-dark ml-2">2</span></div>
                        <div class="card-body p-3">
                            <div class="filter mb-1">
                                <div class="row">
                                    <div class="col-md-2"><input type="text" class="form-control form-control-sm mb-2" placeholder="Filtre par référence"></div>
                                    <div class="col-md-2"><input type="text" class="form-control form-control-sm mb-2" placeholder="Filtre par tiers"></div>
                                    <div class="col-md-2"><input type="text" class="form-control form-control-sm mb-2" placeholder="Filtre par type"></div>
                                    <div class="col-md-2"><input type="number" class="form-control form-control-sm mb-2" placeholder="Filtre par numéro"></div>
                                    <div class="col-md-2"><input type="number" class="form-control form-control-sm mb-2" placeholder="Filtre par débit"></div>
                                    <div class="col-md-2"><input type="number" class="form-control form-control-sm mb-2" placeholder="Filtre par crédit"></div>
                                </div>
                            </div>
                            <div class="border">
                                <div class="table-responsive mb-0">
                                    <table class="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Ref</th>
                                                <th>Tiers</th>
                                                <th>Type</th>
                                                <th>Numero</th>
                                                <th>Débit</th>
                                                <th>Crédit</th>
                                                <th>Solde</th>
                                                <th>Initiateur</th>
                                                <th>Validateur</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Ref4444R</td>
                                                <td>Ghislain</td>
                                                <td>En espece</td>
                                                <td>767509</td>
                                                <td>50 000 000 XOF</td>
                                                <td>50 000 000 XOF</td>
                                                <td>50 000 000 XOF</td>
                                                <td>Ghislain</td>
                                                <td>Ghislain</td>
                                                <td>
                                                    <button class="btn btn-sm btn-primary" (click)="opeBancaireModal.show()"><i class="fas fa-eye" tooltip="Plus d'info"></i></button>
                                                    <button class="btn btn-sm btn-warning ml-2"><i class="fas fa-print" tooltip="Imprimer"></i></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Ref4444R</td>
                                                <td>Ghislain</td>
                                                <td>En espece</td>
                                                <td>767509</td>
                                                <td>50 000 000 XOF</td>
                                                <td>50 000 000 XOF</td>
                                                <td>50 000 000 XOF</td>
                                                <td>Ghislain</td>
                                                <td>Ghislain</td>
                                                <td>
                                                    <button class="btn btn-sm btn-primary" (click)="opeBancaireModal.show()"><i class="fas fa-eye" tooltip="Plus d'info"></i></button>
                                                    <button class="btn btn-sm btn-warning ml-2"><i class="fas fa-print" tooltip="Imprimer"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>TOTAL:</th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th class="text-success">50 000 000 XOF</th>
                                                <th class="text-danger">50 000 000 XOF</th>
                                                <th class="text-first">50 000 000 XOF</th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
                <!-- Fin ecriture -->

                <!-- Rapport mensuel -->
                <tab customClass="pl-0 pr-1 py-2">
                    <ng-template tabHeading><span><i class="fas fa-write"></i>Rapport Mensuel E/S</span></ng-template>
                    <div class="row mb-2">
                        <div class="col-md-12 text-right">
                            <button type="button" class="btn btn-secondary"><i class="fas fa-file-import mr-2"></i>Importer</button>
                            <button type="button" class="btn btn-secondary ml-2"><i class="fas fa-file-export mr-2"></i>Exporter</button>
                            <button type="button" class="btn btn-warning ml-2"><i class="fas fa-print mr-2"></i>Imprimer</button>
                        </div>
                    </div>
                    <div class="card card-box mb-3 p-3">
                        <div class="border">
                            <div class="table-responsive mb-0">
                                <table class="table table-hover table-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th class="text-center p-2" rowspan="3">Mois</th>
                                            <th colspan="6" class="text-center p-2">Anneé</th>
                                        </tr>
                                        <tr>
                                            <th colspan="2" class="text-center p-2">2019</th>
                                            <th colspan="2" class="text-center p-2">2020</th>
                                            <th colspan="2" class="text-center p-2">2021</th>
                                        </tr>
                                        <tr>
                                            <th class="text-center p-2">Débit</th>
                                            <th class="text-center p-2">Crédit</th>
                                            <th class="text-center p-2">Débit</th>
                                            <th class="text-center p-2">Crédit</th>
                                            <th class="text-center p-2">Débit</th>
                                            <th class="text-center p-2">Crédit</th>
                                        </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Janvier</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr *ngFor="let mois of moisRow">
                                        <td>{{mois.label}}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>TOTAL:</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <div class="card card-box mb-2">
                                <div class="card-header d-flex justify-content-between p-3 text-center">
                                    <h6 class=" mb-0 text-black opacity-6">Débit-Année: 2019 - 2020 - 2021</h6>
                                </div>
                                <div class="card-body">
                                    <div class="mb-2 p-2 bg-secondary text-center border">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="font-size-lg d-flex align-items-center justify-content-center text-second">
                                                    <span class="mr-2 badge badge-circle badge-dark">Badge dark</span>
                                                    <small class="pr-1">2019</small>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="font-size-lg d-flex align-items-center justify-content-center text-second">
                                                    <span class="mr-2 badge badge-circle badge-first">Badge first</span>
                                                    <small class="pr-1">2020</small>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="font-size-lg d-flex align-items-center justify-content-center text-second">
                                                    <span class="mr-2 badge badge-circle badge-warning">Badge warning</span>
                                                    <small class="pr-1">2021</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        
                                    <div class="d-block">
                                        <canvas [chartType]="'line'"
                                            style="height: 250px"
                                            [colors]="chartColor2"
                                            [datasets]="chartDataset2"
                                            [labels]="chartLabels2"
                                            [options]="chartOptions2"
                                            baseChart>
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card card-box mb-2 text-center">
                                <div class="card-header d-flex justify-content-between p-3">
                                    <h6 class=" mb-0 text-black opacity-6">Crédit-Année: 2019 - 2020 - 2021</h6>
                                </div>
                                <div class="card-body">
                                    <div class="mb-2 p-2 bg-secondary text-center border">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div class="font-size-lg d-flex align-items-center justify-content-center text-second">
                                                    <span class="mr-2 badge badge-circle badge-dark">Badge dark</span>
                                                    <small class="pr-1">2019</small>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="font-size-lg d-flex align-items-center justify-content-center text-second">
                                                    <span class="mr-2 badge badge-circle badge-first">Badge first</span>
                                                    <small class="pr-1">2020</small>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="font-size-lg d-flex align-items-center justify-content-center text-second">
                                                    <span class="mr-2 badge badge-circle badge-warning">Badge warning</span>
                                                    <small class="pr-1">2021</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        
                                    <div class="d-block">
                                        <canvas [chartType]="'line'"
                                            style="height: 250px"
                                            [colors]="chartColor2"
                                            [datasets]="chartDataset2"
                                            [labels]="chartLabels2"
                                            [options]="chartOptions2"
                                            baseChart>
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
                <!-- Fin rapport mensuel -->

            </tabset>
        </div>
    </div>
</div>

<!-- MODAL -->
<!-- detail approvisionnement -->
<div #openDetailApprovisionnementModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Détail de l'approvisionnement Crédit-00001</h6>
                <button type="button" class="close" aria-label="Close" (click)="openDetailApprovisionnementModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="bg-white rounded-left">
                            <div class="p-2">
                                <div class="border p-2">
                                    <div class="row">
                                        <!-- ZONE DE GAUCHE -->
                                        <div class="col-md-6">
                                            <p class="mt-1"><b class="mr-1">Identifiant: </b>Crédit-00001</p>
                                            <p><b class="mr-1">Caisse: </b>BRIDGE BANK PROTIC</p>
                                            <p><b class="mr-1">Motif: </b>appro bank</p>
                                            <p><b class="mr-1">Montant: </b>50 000 000 XOF</p>
                                            <p><b class="mr-1">Initiateur: </b>PROTIC ARCHIPRO</p>
                                            <p><b class="mr-1">Etat: </b>
                                                <span class="bg-warning text-white p-2 rounded">EN ATTENTE DE VALIDATION</span>
                                            </p>
                                        </div>
                
                                        <!-- LA ZONE DE DROITE -->
                                        <div class="col-md-6">
                                            <p class="mt-1"><b class="mr-1">Validateur: </b>PROTIC ARCHIPRO</p>
                                            <p><b class="mr-1">Remarque: </b>Un remarque</p>
                                            <p><b class="mr-1">Créer par: </b>Kouassi ghislain</p>
                                            <p><b class="mr-1">Date de création: </b>21/05/2021 18:08:09</p>
                                            <p><b class="mr-1">Modifier par: </b></p>
                                            <p><b class="mr-1">Date de modification </b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button  class="btn btn-secondary" type="button" (click)="openDetailApprovisionnementModal.hide()"><i class="fas fa-times mr-2"></i> Annuler</button>
                    <button class="btn btn-warning ml-2" type="button"><i class="fas fa-print mr-2"></i> Imprimer</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin detail approvisionnement -->

<!-- Ajout approvisionnement -->
<div #ajoutApprovisionnementModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Ajouter une nouvelle approvisionnement</h6>
                <button type="button" class="close" aria-label="Close" (click)="ajoutApprovisionnementModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="bg-white rounded-left">
                            <div class="p-2">
                                <div class="rounded border p-2">
                                    <form action="">
                                        <div class="form-group row">
                                            <div class="col-md-12 mb-2">
                                                <label for="montant">Montant <span class="text-danger">*</span></label>
                                                <input type="number" class="form-control" id="montant" name="montant">
                                            </div>
                                            <div class="col-md-12">
                                                <label for="montant">Motif <span class="text-danger">*</span></label>
                                                <textarea name="montant" id="montant" cols="30" rows="7" class="form-control" placeholder="Motif de l'approvisionnement"></textarea>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button  class="btn btn-secondary" type="button" (click)="ajoutApprovisionnementModal.hide()"><i class="fas fa-times mr-2"></i> Annuler</button>
                    <button class="btn btn-success ml-2" type="button"><i class="fas fa-save mr-2"></i> Enregistrer</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin detail approvisionnement -->

<!-- historique des operations bancaires -->
<div #opeBancaireModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Historique des opérations bancaire. Détail de l'opération Ref4444R</h6>
                <button type="button" class="close" aria-label="Close" (click)="opeBancaireModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="bg-white rounded-left">
                            <div class="p-2">
                                <div class="bg-secondary rounded border p-2">
                                    <div class="row">
                                        <!-- LA ZONE DE GAUCHE -->
                                        <div class="col-md-6">
                                            <div class="row mt-1 mb-2">
                                                <div class="col-md-6 font-weight-bolder">Référence: </div>
                                                <div class="col-md-6">Ref4444R</div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-6 font-weight-bold">Description: </div>
                                                <div class="col-md-6">une description</div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-6 font-weight-bolder">Tiers: </div>
                                                <div class="col-md-6">Protic</div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-6 font-weight-bolder">Date d'opération: </div>
                                                <div class="col-md-6">01/01.2021 00:00:00</div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-6 font-weight-bolder">Date valeur: </div>
                                                <div class="col-md-6">01/01.2021 00:00:00</div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-6 font-weight-bold">Type: </div>
                                                <div class="col-md-6">En espece</div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-6 font-weight-bolder">Numéro: </div>
                                                <div class="col-md-6">767509</div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-6 font-weight-bolder">Compte bancaire: </div>
                                                <div class="col-md-6">CAI001</div>
                                            </div>
                                        </div>
                    
                                        <!-- LA ZONE DE DROITE -->
                                        <div class="col-md-6">
                                            <div class="row mt-1 mb-2">
                                                <div class="col-md-6 font-weight-bold">Rélévé: </div>
                                                <div class="col-md-6">releve</div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-6 font-weight-bolder">Rapport: </div>
                                                <div class="col-md-6">Rapport</div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-6 font-weight-bolder">Validateur: </div>
                                                <div class="col-md-6">Ghislain</div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-6 font-weight-bolder">Initiateur: </div>
                                                <div class="col-md-6">Ghislain</div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-6 font-weight-bolder">Créer par: </div>
                                                <div class="col-md-6">Ghislain</div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-6 font-weight-bolder">Date Création: </div>
                                                <div class="col-md-6">01/01.2021 00:00:00</div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-6 font-weight-bolder">Modifier par: </div>
                                                <div class="col-md-6">Ghislain</div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md-6 font-weight-bolder">Date de modification: </div>
                                                <div class="col-md-6">01/01.2021 00:00:00</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-xl-4 col-md-6 mb-2">
                                            <div class="card card-box card-box-border-bottom border-success card-box-hover-rise">
                                                <div class="card-body p-0">
                                                    <div class="text-center m-2">
                                                        <div class="mt-1">
                                                            <fa-icon [icon]="['far', 'building']" class="font-size-sm text-success"></fa-icon>
                                                            <span class="text-black-50 ml-2">Débit</span>
                                                        </div>
                                                        <div class="mt-3 line-height-sm">
                                                            <b class="font-size-lg pr-1">10 000 000 <span class="text-black-50">XOF</span></b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-md-6 mb-2">
                                            <div class="card card-box card-box-border-bottom border-danger card-box-hover-rise">
                                                <div class="card-body p-0">
                                                    <div class="text-center m-2">
                                                        <div class="mt-1">
                                                            <i class="fas fa-balance-scale-right font-size-sm text-danger"></i>
                                                            <span class="text-black-50 ml-2">Crédit</span>
                                                        </div>
                                                        <div class="mt-3 line-height-sm">
                                                            <b class="font-size-lg pr-1">40 000 000 <span class="text-black-50">XOF</span></b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-md-6 mb-2">
                                            <div class="card card-box card-box-border-bottom border-first card-box-hover-rise">
                                                <div class="card-body p-0">
                                                    <div class="text-center m-2">
                                                        <div class="mt-1">
                                                            <i class="fas fa-university font-size-sm text-first"></i>
                                                            <span class="text-black-50 ml-2">Solde</span>
                                                        </div>
                                                        <div class="mt-3 line-height-sm">
                                                            <b class="font-size-lg pr-1">50 000 000 <span class="text-black-50">XOF</span></b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button  class="btn btn-secondary" type="button" (click)="opeBancaireModal.hide()"><i class="fas fa-times mr-2"></i> Quitter</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin historique des operations bancaires -->

<!-- détail filtre -->
<div #filterResponseDetailModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Détail des ecritures bancaires</h6>
                <button type="button" class="close" aria-label="Close" (click)="filterResponseDetailModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="bg-white rounded-left">
                            <div class="p-2">
                                <div class="rounded border p-2">
                                    <div class="mb-2 rounded">
                                        <div class="row">
                                            <div class="col-xl-3 col-md-6 mb-2">
                                                <div class="card card-box card-box-border-bottom border-first card-box-hover-rise">
                                                    <div class="card-body p-0">
                                                        <div class="text-center m-2">
                                                            <div class="mt-1">
                                                                <fa-icon [icon]="['far', 'dot-circle']" class="font-size-sm text-first"></fa-icon>
                                                                <span class="text-black-50 ml-2">Total à regler</span>
                                                            </div>
                                                            <div class="mt-3 line-height-sm">
                                                                <b class="font-size-lg pr-1">50 000 000 <span class="text-black-50">XOF</span></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-md-6 mb-2">
                                                <div class="card card-box card-box-border-bottom border-success card-box-hover-rise">
                                                    <div class="card-body p-0">
                                                        <div class="text-center m-2">
                                                            <div class="mt-1">
                                                                <fa-icon [icon]="['far', 'check-circle']" class="font-size-sm text-success"></fa-icon>
                                                                <span class="text-black-50 ml-2">Payer</span>
                                                            </div>
                                                            <div class="mt-3 line-height-sm">
                                                                <b class="font-size-lg pr-1">10 000 000 <span class="text-black-50">XOF</span></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-md-6 mb-2">
                                                <div class="card card-box card-box-border-bottom border-danger card-box-hover-rise">
                                                    <div class="card-body p-0">
                                                        <div class="text-center m-2">
                                                            <div class="mt-1">
                                                                <fa-icon [icon]="['far', 'times-circle']" class="font-size-sm text-danger"></fa-icon>
                                                                <span class="text-black-50 ml-2">Reste à payer</span>
                                                            </div>
                                                            <div class="mt-3 line-height-sm">
                                                                <b class="font-size-lg pr-1">40 000 000 <span class="text-black-50">XOF</span></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 col-md-6 mb-2">
                                                <div class="card card-box card-box-border-bottom border-warning card-box-hover-rise text-center">
                                                    <div class="card-body p-0">
                                                        <div class="text-center m-2">
                                                            <div class="mt-1">
                                                                <i class="fas fa-arrow-alt-circle-right font-size-sm text-warning"></i>
                                                                <span class="text-black-50 ml-2">En attente de paiement</span>
                                                            </div>
                                                            <div class="mt-3 line-height-sm">
                                                                <b class="font-size-lg pr-1">40 000 000 <span class="text-black-50">XOF</span></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="divider mb-2"></div>
                                    <div class="row">
                                        <div class="col-md-12 text-right mb-2">
                                            <button class="btn btn-success" tooltip="Ajouter un nouveau paiement" (click)="newPaiement()"><i class="fas fa-plus-circle mr-2"></i>Ajouter</button>
                                        </div>
                                    </div>
                                    <div class="divider mb-2"></div>
                                    <div class="bg-secondary border p-2" *ngIf="toggleNewPaiment">
                                        <form [formGroup]="form">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label for="montant">Montant à verser: <span class="text-danger">*</span></label>
                                                    <input type="number" class="form-control" id="montant" formControlName="montant">
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="remisA">Rémis à: <span class="text-danger">*</span></label>
                                                    <select formControlName="remisA" id="remisA" class="form-control">
                                                        <option *ngFor="let remis of remisA " [value]="remis.value">{{remis.label}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="modePaiement">Mode de paiement: <span class="text-danger">*</span></label>
                                                    <select formControlName="modePaiement" id="modePaiement" class="form-control">
                                                        <option value="espece" *ngIf="tresorerie.type == 'Caisse'">En espèce</option>
                                                        <option value="cheque" *ngIf="tresorerie.type == 'Banque'">Par chèque</option>
                                                        <option value="virement" *ngIf="tresorerie.type == 'Banque'">Par virement</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-4" *ngIf="f.remisA.value == 'autre'">
                                                    <label for="nom">Nom: <span class="text-danger">*</span></label>
                                                    <input type="text" formControlName="nom" id="nom" class="form-control">
                                                </div>
                                                <div class="col-md-4" *ngIf="f.remisA.value == 'autre'">
                                                    <label for="prenom">Prenom: <span class="text-danger">*</span></label>
                                                    <input type="text" formControlName="prenom" id="prenom" class="form-control">
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-md-12 mt-3 text-right">
                                                    <button class="btn btn-secondary mr-2" (click)="paiementOff()"><i class="fas fa-times mr-2"></i>Annuler</button>
                                                    <button class="btn btn-primary"><i class="fas fa-save mr-2"></i>Enregistrer</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button  class="btn btn-secondary" type="button" (click)="filterResponseDetailModal.hide()"><i class="fas fa-times mr-2"></i> Quitter</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fin détail filtre -->
<!-- FIN MODAL -->