import { Route } from '@angular/compiler/src/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { ThemeOptions } from 'src/app/theme-options';
import { Utilisateur } from '../utilisateur.model';
import { UtilisateurService } from '../utilisateur.service';
const URL = 'https://example.com';

@Component({
  selector: 'app-utilisateur-detail',
  templateUrl: './utilisateur-detail.component.html',
  styleUrls: ['./utilisateur-detail.component.scss']
})
export class UtilisateurDetailComponent implements OnInit, OnDestroy {

  status1 = false;
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;
  userDetailsReceive: any = '';
  userDetails: any;
  userSubs = new Subscription;
  private notif: NotifierService;
  sub = new Subscription;
  idUtilisateur: any;

  constructor(public globals: ThemeOptions,
    private utilisateurServive: UtilisateurService,
    private route: ActivatedRoute,
    private notifier: NotifierService) {
    this.__getDataUser();
    this.notif = this.notifier;
    this.uploader = new FileUploader({
      url: URL,
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;
    this.response = '';
    this.uploader.response.subscribe(res => this.response = res);
  }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  ngOnInit() {
  }
  ngOnDestroy(): void {
    this.userSubs.unsubscribe();
    this.sub.unsubscribe();
  }
  __getDataUser() {

    this.sub = this.route.params.subscribe(params => {
      this.idUtilisateur = +params['id']; // (+) converts string 'id' to a number

      // In a real app: dispatch action to load the details here.
   });
   console.log('this.idUtilisateur', this.idUtilisateur);


    // this.userDetailsReceive = this.utilisateurServive.getDataForUtilisateur();
    // console.log(' this.userDetailsReceive', this.userDetailsReceive);
    this.loadUserByIdentifiant(this.idUtilisateur);
  }

  loadUserByIdentifiant(data) {
    this.userSubs == this.utilisateurServive.getDetailsByUtilisateur(data).subscribe(
      (resData) => {
        this.userDetails = resData;
        console.log('this.userDetails', this.userDetails);
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
  /**
  * Show a notification
  */
  showNotification(type: string, message: string): void {
    this.notif.notify(type, message);
  }

}
