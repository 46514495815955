<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-wallet display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1 class="font-weight-bold m-0">Détail demande de mission</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description m-0" title="Référence et libellé de la mission">
                    <span><i class="fas fa-file-alt"></i> ( {{mission?.reference}} )</span>
                    <span class="ml-1">{{mission?.motif}}</span>
                </div>
                <p class="m-0" title="Date départ et date retour du demandeur">
                    <i class="fas fa-calendar-alt"></i>
                    <span class="ml-1">Du {{mission?.dateDepart}} au {{mission?.dateRetour}}</span>
                </p>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button [routerLink]="['/pages/mission/liste']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<div class="card card-box">
    <div class="border rounded m-3 p-3">
        <div class="p-2 bg-secondary">
            <h4 class="m-0 font-weight-bold text-first">Information générales</h4>
        </div>
        <hr class="m-0">
        <div class="row">
            <div class="col-md-6">
                <p class="mt-1 mb-0"><b class="mr-1">Démandeur: </b>{{mission?.nomDemandeur}}</p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">Date départ: </b>{{mission?.dateDepart}}</p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">Activité: </b>{{mission?.motif}}</p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">Chantier: </b>{{mission?.chantier}}</p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">Itineraire: </b>{{mission?.itineraire}}</p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">Etat: </b>
                    <span class="badge bg-{{
                        mission?.etatMission == 'En cours'? 'neutral-info text-info': 
                        mission?.etatMission == 'Terminer'? 'neutral-success text-success': 
                        mission?.etatMission == 'Annuler'? 'neutral-danger text-danger': 
                        'neutral-warning text-warning'
                    }}">{{mission?.etatMission}}</span>
                </p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">Retour prevue: </b>{{mission?.dateRetour}}</p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">Validateur 1: </b>{{mission?.nomDemandeur}}</p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">Validateur 2: </b>{{mission?.nomDemandeur}}</p>
                <hr class="my-2">
            </div>
            <div class="col-md-6">
                <p class="mt-1 mb-0"><b class="mr-1">Date de demande: </b>{{mission?.dateDepart}}</p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">Moyen de transport: </b>Véhicule de service</p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">Marque: </b>Toyota</p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">Modele: </b>Land cruser</p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">serie: </b>V02</p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">Immatriculation: </b>0908HB</p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">Conducteur: </b>{{mission?.nomDemandeur}}</p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">Créer par: </b>{{mission?.creerPar}}</p>
                <hr class="my-2">
                <p class="m-0"><b class="mr-1">Date de création: </b>{{mission?.dateCreation}}</p>
                <hr class="my-2">
            </div>
            <div class="col-md-12">
                <p class="mb-2"><b class="mr-1">Description:</b></p>
                <textarea class="form-control" rows="4" disabled>{{mission?.description}}</textarea>
            </div>
        </div>
        <hr class="mt-2 mb-5">

        <hr class="m-0">
        <div class="p-2 bg-secondary">
            <h4 class="m-0 font-weight-bold text-first">Participants </h4>
        </div>
        <div class="table-responsive m-0">
            <table class="table table-hover text-nowrap m-0">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Prenom</th>
                        <th>Contact</th>
                        <th>Fonction</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of mission?.participants">
                        <td>{{item?.nom}}</td>
                        <td>{{item?.prenom}}</td>
                        <td>{{item?.contact}}</td>
                        <td>{{item?.fonction}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr class="mt-2 mb-5">

        <hr class="m-0">
        <div class="p-2 bg-secondary">
            <h4 class="m-0 font-weight-bold text-first">Frais de mission </h4>
        </div>
        <div>
            <hr class="mt-0 mb-2">
            <p class="m-0"><b class="mr-1">Caisse: </b>Caisse de projet 1</p>
            <hr class="my-2">
            <div class="row mb-3">
                <div class="col-md-4">
                    <div class="card p-4">
                        <div class="row">
                            <div class="col-md-4">
                                <i class="fas fa-piggy-bank fa-3x text-dark mt-3"></i>
                            </div>
                            <div class="col-md-8 float-right text-right">
                                <p class="announcement-text">Total HT</p>
                                <h4 class="font-weight-bold text-dark m-0">{{totalTh | number:'': 'fr-FR'}} XOF</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card p-4">
                        <div class="row">
                            <div class="col-md-4">
                                <i class="fas fa-funnel-dollar fa-3x text-dark mt-3"></i>
                            </div>
                            <div class="col-md-8 float-right text-right">
                                <p class="announcement-text">Total TVA</p>
                                <h4 class="font-weight-bold text-dark m-0">{{totalTva | number:'': 'fr-FR'}} XOF</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card p-4">
                        <div class="row">
                            <div class="col-md-4">
                                <i class="fas fa-coins fa-3x text-dark mt-3"></i>
                            </div>
                            <div class="col-md-8 float-right text-right">
                                <p class="announcement-text">Total TTC</p>
                                <h4 class="font-weight-bold text-dark m-0">{{totalTtc | number:'': 'fr-FR'}} XOF</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive m-0">
                <table class="table table-hover text-nowrap m-0">
                    <thead>
                        <tr>
                            <th>Libellé</th>
                            <th>Nombre</th>
                            <th>Prix unitaire</th>
                            <th>TVA</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>un libellé</td>
                            <td>4</td>
                            <td>120 000 XOF</td>
                            <td>18</td>
                            <td>566400 XOF</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <hr class="mt-2 mb-5">

        <hr class="m-0">
        <div class="p-2 bg-secondary">
            <h4 class="m-0 font-weight-bold text-first">Rapport de mission </h4>
        </div>
        <textarea class="form-control" rows="7" disabled>{{mission?.rapport}}</textarea>
    </div>
    <hr class="m-0">
    <div class="py-2 px-3 text-right">
        <button class="btn btn-success mr-2" type="button" *ngIf="mission.etatMission !== 'Terminer'"><i class="fas fa-sign-out-alt mr-1"></i>Clôturer</button>
        <button class="btn btn-info mr-2" type="button"><i class="fas fa-pen mr-1"></i>Modifier</button>
        <button class="btn btn-warning mr-2" type="button"><i class="ion-md-print mr-1"></i>Imprimer</button>
        <button class="btn btn-danger" type="button"><i class="ion-md-trash mr-1"></i>Supprimer</button>
    </div>
</div>