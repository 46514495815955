<div class="row">
    <div class="col-md-3">
        <div class="card rounded h-100">
            <div class="d-flex bg-secondary d-lg-none p-4 order-0 justify-content-between align-items-center">
                <button (click)="toggleMobileSidebar1 = !toggleMobileSidebar1" class="btn btn-sm btn-primary px-3" type="button">
                    <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                </button>
                <button (click)="toggleMobileSidebar2 = !toggleMobileSidebar2" class="btn btn-sm btn-primary px-3" type="button">
                    <fa-icon [icon]="['fas', 'bars']"></fa-icon>
                </button>
            </div>
            <div [ngClass]="{'layout-sidebar-open' : toggleMobileSidebar1}" class="app-inner-content-layout--sidebar card rounded app-inner-content-layout--sidebar__sm h-100">
                <perfect-scrollbar [autoPropagation]="true" class="px-4">
                    <ul class="nav nav-neutral-primary nav-pills flex-column pt-4">
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link active" (click)="showMenuElement('tous')">
                                <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'file-alt']" class="mx-auto"></fa-icon></span>
                                Tous les fichiers
                            </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link" (click)="showMenuElement('recent')">
                                <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'keyboard']" class="mx-auto"></fa-icon></span>
                                Fichier récents
                            </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link" (click)="showMenuElement('supprime')">
                                <span class="nav-link-icon opacity-6"><i class="ion-md-trash mx-auto"></i></span>
                                Fichier supprimés
                            </a>
                        </li>
                        <li class="nav-header text-uppercase font-size-sm text-muted pb-2">
                            Type de fichier
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link" (click)="showMenuElement('image')">
                                <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'file-image']" class="mx-auto"></fa-icon></span>
                                Images
                            </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link" (click)="showMenuElement('audio')">
                                <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'file-audio']" class="mx-auto"></fa-icon></span>
                                Audio
                            </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link" (click)="showMenuElement('video')">
                                <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'file-video']" class="mx-auto"></fa-icon></span>
                                Video
                            </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link" (click)="showMenuElement('pdf')">
                                <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'file-pdf']" class="mx-auto"></fa-icon></span>
                                PDF
                            </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link" (click)="showMenuElement('doc')">
                                <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'file-word']" class="mx-auto"></fa-icon></span>
                                Word
                            </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link" (click)="showMenuElement('excel')">
                                <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'file-excel']" class="mx-auto"></fa-icon></span>
                                Excel
                            </a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link" (click)="showMenuElement('autre')">
                                <span class="nav-link-icon opacity-6"><fa-icon [icon]="['far', 'file-alt']" class="mx-auto"></fa-icon></span>
                                Autre fichiers
                            </a>
                        </li>
                    </ul>
                </perfect-scrollbar>
                <div class="app-content--sidebar__footer px-4 py-2">
                    <ul class="nav nav-neutral-primary nav-pills flex-column">
                        <li class="nav-header text-uppercase font-size-sm text-muted pb-2">Stockage disponible</li>
                        <li class="nav-item">
                            <div class="d-flex align-items-center">
                                <div class="display-3">
                                    <fa-icon [icon]="['far', 'object-group']"></fa-icon>
                                </div>
                                <div class="pl-3">
                                    <small class="d-block mb-2"><b>5GB</b> utilisé hors de <b>25GB</b></small>
                                    <progressbar [animate]="true" [value]="87" class="progress-bar-rounded progress-animated-alt" type="warning">87%</progressbar>
                                </div>
                            </div>
                            <small class="d-block mt-2">
                                <span class="d-block pb-2">
                                    Obtenez <b>10 GB</b> d'espace de stockage avec le forfait premium
                                </span>
                                <a [routerLink]="" class="btn p-0 font-weight-bold font-size-xs btn-link btn-link-first" title="Learn more">Détails</a>
                            </small>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-9">
        <hr class="mt-0 mb-2">
        <div class="text-right">
            <button class="btn btn-success" (click)="newFolderModal.show()"><i class="fas fa-plus-circle"></i> Ajouter</button>
        </div>
        <hr class="my-2">

        <div *ngIf="menuItem == 'tous'">
            <div class="row">
                <div class="col-md-4" *ngFor="let item of itemRow">
                    <div class="card card-box mb-5">
                        <div class="card-body">
                            <div class="card-img-wrapper">
                                <div class="btn-group card-tr-actions" dropdown placement="bottom right">
                                    <button class="btn btn-link btn-link-primary p-0 font-size-xl dropdown-toggle no-caret" dropdownToggle type="button" tooltip="Action">
                                        <fa-icon [icon]="['fas', 'ellipsis-v']"></fa-icon>
                                    </button>
                                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu rounded border-0 overflow-hidden p-0">
                                        <ul class="nav nav-pills flex-column p-3">
                                            <li class="nav-item">
                                                <a [routerLink]="['/pages/fichier/detail/1']" class="nav-link">
                                                    <span><i class="fas fa-file-alt mr-1"></i> Historique</span>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link">
                                                    <span><i class="fas fa-pen mr-1"></i> Modfier</span>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a [routerLink]="" class="nav-link">
                                                    <span><i class="ion-md-trash mr-1"></i> Supprimer</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="rounded py-5 mb-3 bg-secondary d-flex align-items-center align-content-center">
                                    <fa-icon [icon]="['far', 'file-word']" class="display-2 text-first mx-auto"></fa-icon>
                                </div>
                                <a [routerLink]="">
                                    <b>Example_WordFile.doc</b>
                                </a>
                                <div>
                                    <small class="d-block text-black-50 pb-2">15.2MB</small>
                                    <h6 class="opacity-6 mb-1">
                                        Version: <span class="text-black-50">1</span>
                                    </h6>
                                    <h6 class="opacity-6 mb-1">
                                        Type: <span class="text-black-50">Autorisation</span>
                                    </h6>
                                    <h6 class="opacity-6 mb-0">
                                        Modified le: <span class="text-black-50">12.06.2020</span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="menuItem == 'recent'">
            <div class="text-center p-5">
                <h1 class="mb-5">Aucun nouveau fichier</h1>
                <img src="assets/img/bg1.png" alt="">
            </div>
        </div>
        <div *ngIf="menuItem == 'supprime'">
            <div class="text-center p-5">
                <h1 class="mb-5">Aucun fichier supprimé</h1>
                <img src="assets/img/bg2.png" alt="">
            </div>
        </div>
        <div *ngIf="menuItem == 'image'">
            <div class="text-center p-5">
                <h1 class="mb-5">Aucune image sauvegarder</h1>
                <img src="assets/img/bg3.png" alt="">
            </div>
        </div>
        <div *ngIf="menuItem == 'audio'">
            <div class="text-center p-5">
                <h1 class="mb-5">Aucun fichier audio sauvegarder</h1>
                <img src="assets/img/bg4.png" alt="">
            </div>
        </div>
        <div *ngIf="menuItem == 'video'">
            <div class="text-center p-5">
                <h1 class="mb-5">Aucun fichier video sauvegarder</h1>
                <img src="assets/img/bg5.jpg" alt="">
            </div>
        </div>
        <div *ngIf="menuItem == 'pdf'">
            <div class="text-center p-5">
                <h1 class="mb-5">Aucun fichier PDF sauvegarder</h1>
                <img src="assets/img/pdf.png" alt="">
            </div>
        </div>
        <div *ngIf="menuItem == 'doc'">
            <div class="text-center p-5">
                <h1 class="mb-5">Aucun fichier word sauvegarder</h1>
                <img src="assets/img/bg7.png" alt="">
            </div>
        </div>
        <div *ngIf="menuItem == 'excel'">
            <div class="text-center p-5">
                <h1 class="mb-5">Aucun fichier excel sauvegarder</h1>
                <img src="assets/img/bg8.png" alt="">
            </div>
        </div>
        <div *ngIf="menuItem == 'autre'">
            <div class="text-center p-5">
                <h1 class="mb-5">Aucun autre fichier sauvegarder</h1>
                <img src="assets/img/bg9.png" alt="">
            </div>
        </div>
    </div>
</div>

<!-- Modal d'ajout de document -->
<div #newFolderModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Formulaire d'ajout d'un document</h6>
                <button type="button" class="close" aria-label="Close" (click)="newFolderModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <app-fichier-edit></app-fichier-edit>
            </div>
        </div>
    </div>
</div>

<div (click)="toggleMobileSidebar1 = !toggleMobileSidebar1" [ngClass]="{'active' : toggleMobileSidebar1}" class="sidebar-inner-layout-overlay"></div>
<div (click)="toggleMobileSidebar2 = !toggleMobileSidebar2" [ngClass]="{'active' : toggleMobileSidebar2}" class="sidebar-inner-layout-overlay"></div>