import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MissionPages } from 'src/app/models/mission-pages';
import { MissionPagesService } from 'src/app/services/mission-pages/mission-pages.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-mission-detail',
  templateUrl: './mission-detail.component.html',
  styleUrls: ['./mission-detail.component.scss']
})
export class MissionDetailComponent implements OnInit {
  mission: MissionPages = <MissionPages>{};
  rotate = true;
  totalTh = 156000;
  totalTva = 0;
  totalTtc = 0;

  constructor(
    private route: ActivatedRoute,
    public globals: ThemeOptions,
    private missionService: MissionPagesService
  )
  {
    this.mission = this.missionService.getMission();
    console.log(this.mission)
    const id: number = +this.route.snapshot.paramMap.get('id');
    this.missionService.getList().subscribe((missions: MissionPages[]) => {
      this.mission = missions.find(mission => mission.id == id);
    });
  }

  ngOnInit() {
    this.totalTva = ((this.totalTh * 18) / 100);
    this.totalTtc = (this.totalTh + this.totalTva);
  }

}
