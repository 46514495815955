import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjetPagesRoutingModule } from './projet-pages-routing.module';
import { ProjetDashboardComponent } from './projet-dashboard/projet-dashboard.component';
import { ProjetDetailComponent } from './projet-detail/projet-detail.component';
import { ProjetEditComponent } from './projet-edit/projet-edit.component';
import { ProjetListComponent } from './projet-list/projet-list.component';
import { ProjetPagesComponent } from './projet-pages.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AccordionModule, AlertModule, BsDatepickerModule, BsDropdownModule, CollapseModule, ModalModule, PaginationModule, PopoverModule, ProgressbarModule, TabsModule, TooltipModule, TypeaheadModule } from 'ngx-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { FeatherModule } from 'angular-feather';
import { ChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgSelectModule } from '@ng-select/ng-select';
import { CountUpModule } from 'countup.js-angular2';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AgmCoreModule } from '@agm/core';
import { ChantierPagesModule } from '../chantier-pages/chantier-pages.module';
import { SituationHsePagesModule } from '../situation-hse-pages/situation-hse-pages.module';
import { ProcesVerbalPagesModule } from '../proces-verbal-pages/proces-verbal-pages.module';
import { VisitePagesModule } from '../visite-pages/visite-pages.module';
import { RapportPagesModule } from '../rapport-pages/rapport-pages.module';
import { TachePagesModule } from '../tache-pages/tache-pages.module';
import { FichierPagesModule } from '../fichier-pages/fichier-pages.module';
import { ReunionPagesModule } from '../reunion-pages/reunion-pages.module';
import { CommandePagesModule } from '../commande-pages/commande-pages.module';
import { BudgetPagesModule } from '../budget-pages/budget-pages.module';
import { BulletinHsePagesModule } from '../bulletin-hse-pages/bulletin-hse-pages.module';
import { TravauxPagesModule } from '../travaux-pages/travaux-pages/travaux-pages.module';
import { ReservePagesModule } from '../travaux-pages/reserve-pages/reserve-pages.module';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'uifort',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
     easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
     easing: 'ease'
    },
    overlap: 150
  }
};


@NgModule({
  declarations: [
    ProjetPagesComponent,
    ProjetDashboardComponent,
    ProjetDetailComponent,
    ProjetEditComponent,
    ProjetListComponent,
  ],
  imports: [
    CommonModule,
    ProjetPagesRoutingModule,
    LeafletModule,
    BsDatepickerModule,
    FontAwesomeModule,
    RouterModule,
    TabsModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    TextMaskModule,
    FeatherModule,
    ProgressbarModule,
    ChartsModule,
    BsDropdownModule,
    TooltipModule,
    HttpClientModule,
    NgxGaugeModule,
    NgApexchartsModule,
    NgCircleProgressModule,
    NgSelectModule,
    CountUpModule,
    CollapseModule,
    PopoverModule,
    PerfectScrollbarModule,
    PaginationModule,
    AccordionModule.forRoot(),
    ModalModule,
    TypeaheadModule,
    NotifierModule.withConfig(customNotifierOptions),
    AgmCoreModule.forRoot({apiKey: 'AIzaSyCoVRykl39EigHTQ0wnI0ISVGR3zpV4dDM'}),

    BudgetPagesModule,
    BulletinHsePagesModule,
    ChantierPagesModule,
    CommandePagesModule,
    FichierPagesModule,
    ProcesVerbalPagesModule,
    VisitePagesModule,
    RapportPagesModule,
    ReservePagesModule,
    ReunionPagesModule,
    SituationHsePagesModule,
    TachePagesModule,
    TravauxPagesModule,
  ],
  entryComponents: [
    ProjetEditComponent,
  ],
})
export class ProjetPagesModule { }
