import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { stringify } from 'querystring';
import { BehaviorSubject,throwError } from 'rxjs';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Utilisateur } from '../utilisateur-pages/utilisateur.model';
import { Tiers } from './tiers.model';


interface TiersData {
  id?: string,
  libelle: string,
  utilisateur?: Array<Utilisateur>,
  auteur?: Utilisateur,
  localisation: string,
  published?: string,
  modified?: string,
}


@Injectable({
  providedIn: 'root'
})
export class TiersService {
  private _tiers = new BehaviorSubject<Tiers[]>([]);
  apiUrl = environment.apiUrl;
  prefix = environment.prefix;

  constructor(private http:HttpClient) { }

  get tiers(){
    return this._tiers.asObservable();
  }

  listerTiers(){
    return this.http.get<TiersData[]>(
      `${this.apiUrl}/tiers`,{
        responseType: 'json'
      }
    ).pipe(
      map((response: any) => response['hydra:member']),
      catchError(this.handleError)
    )
  }

  addTiers(data:TiersData){
    let responseData:TiersData;
        return this.http.post(`${this.apiUrl}/tiers`,JSON.stringify(data)).pipe(
        tap( resData => {      
          return resData
        }),
        catchError(this.handleError)
      );//pipe   
  }

  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "Une erreur est survenue";
    return throwError(messageError);
  }
}
