import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bureau-etude-dashboard',
  templateUrl: './bureau-etude-dashboard.component.html',
  styleUrls: ['./bureau-etude-dashboard.component.scss']
})
export class BureauEtudeDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
