<div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <aw-wizard class="wizard">
            <!-- ETAPE 1: TRAVAUX -->
            <aw-wizard-step stepTitle="Travaux" [navigationSymbol]="{ symbol: '1' }">
                <div class="p-4">
                    <h4 class="font-weight-bold">Travaux associés</h4>
                    <div class="list-group list-group-flush border rounded">
                        <li class="list-group-item rounded-top">
                            <input type="search" class="form-control" placeholder="Recherche dans la liste des travaux">
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <fa-icon [icon]="['far', 'folder']" class="font-size-lg mr-3"></fa-icon>
                            <div>
                                <div>Travaux 1</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="travaux1" type="checkbox">
                                    <label class="custom-control-label" for="travaux1">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <fa-icon [icon]="['far', 'folder-open']" class="font-size-lg mr-3"></fa-icon>
                            <div>
                                <div>Travaux 2</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="travaux2" type="checkbox">
                                    <label class="custom-control-label" for="travaux2">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <fa-icon [icon]="['far', 'folder-open']" class="font-size-lg ml-4 mr-2"></fa-icon>
                            <div>
                                <div>Travaux 2.1</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="travaux2_1" type="checkbox">
                                    <label class="custom-control-label" for="travaux2_1">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <i class="pe-7s-file font-size-lg ml-5 mr-2"></i>
                            <div>
                                <div>Travaux 2.1.1</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="travaux2_1_1" type="checkbox">
                                    <label class="custom-control-label" for="travaux2_1_1">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <i class="pe-7s-file font-size-lg ml-5 mr-2"></i>
                            <div>
                                <div>Travaux 2.1.2</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="travaux2_1_2" type="checkbox">
                                    <label class="custom-control-label" for="travaux2_1_2">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                            <div>
                                <div>Travaux 2.2</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="travaux2_2" type="checkbox">
                                    <label class="custom-control-label" for="travaux2_2">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row rounded-bottom">
                            <i class="pe-7s-file font-size-lg ml-4 mr-2"></i>
                            <div>
                                <div>Travaux 2.3</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="travaux2_3" type="checkbox">
                                    <label class="custom-control-label" for="travaux2_3">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                    </div>
                </div>
                <hr class="m-0">
                <div class="px-4 py-2 text-right">
                    <button awNextStep class="btn btn-dark" type="button">Suivant <i class="fas fa-chevron-right ml-1"></i></button>
                </div>
            </aw-wizard-step>

            <!-- ETAPE 2: VISITE CHANTIER -->
            <aw-wizard-step stepTitle="Visite chantier" [navigationSymbol]="{ symbol: '2' }">
                <div class="p-4">
                    <h4 class="font-weight-bold">Visite chantier</h4>
                    <div class="list-group list-group-flush border rounded">
                        <li class="list-group-item rounded-top">
                            <input type="search" class="form-control" placeholder="Recherche dans la liste des visite">
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <i class="pe-7s-keypad font-size-lg mr-3"></i>
                            <div>
                                <div>Visite 1</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="visite1" type="checkbox">
                                    <label class="custom-control-label" for="visite1">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <i class="pe-7s-keypad font-size-lg mr-3"></i>
                            <div>
                                <div>Visite 2</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="visite2" type="checkbox">
                                    <label class="custom-control-label" for="visite2">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row rounded-bottom">
                            <i class="pe-7s-keypad font-size-lg mr-3"></i>
                            <div>
                                <div>Visite 3</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="visite3" type="checkbox">
                                    <label class="custom-control-label" for="visite3">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                    </div>
                </div>
                <hr class="m-0">
                <div class="d-flex justify-content-between px-4 py-2">
                    <div>
                        <button awPreviousStep class="btn btn-dark" type="button"><i class="fas fa-chevron-left"></i> Précédent</button>
                    </div>
                    <div class="text-right">
                        <button awNextStep class="btn btn-dark" type="button">Suivant <i class="fas fa-chevron-right"></i></button>
                    </div>
                </div>
            </aw-wizard-step>

            <!-- ETAPE 3: HSE -->
            <aw-wizard-step stepTitle="HSE" [navigationSymbol]="{ symbol: '3' }">
                <div class="p-4">
                    <h4 class="font-weight-bold">HSE (Incident/Accident de travail)</h4>
                    <div class="list-group list-group-flush border rounded">
                        <li class="list-group-item rounded-top">
                            <input type="search" class="form-control" placeholder="Recherche dans la liste des visite">
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <i class="pe-7s-keypad font-size-lg mr-3"></i>
                            <div>
                                <div>Incident</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="incident1" type="checkbox">
                                    <label class="custom-control-label" for="incident1">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <i class="pe-7s-keypad font-size-lg mr-3"></i>
                            <div>
                                <div>Accident de travail</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="accident1" type="checkbox">
                                    <label class="custom-control-label" for="accident1">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <i class="pe-7s-keypad font-size-lg mr-3"></i>
                            <div>
                                <div>Incident 2</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="incident2" type="checkbox">
                                    <label class="custom-control-label" for="incident2">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row rounded-bottom">
                            <i class="pe-7s-keypad font-size-lg mr-3"></i>
                            <div>
                                <div>Accident de travail 2</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="accident2" type="checkbox">
                                    <label class="custom-control-label" for="accident2">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                    </div>
                </div>
                <hr class="m-0">
                <div class="d-flex justify-content-between px-4 py-2">
                    <div>
                        <button awPreviousStep class="btn btn-dark" type="button"><i class="fas fa-chevron-left"></i> Précédent</button>
                    </div>
                    <div class="text-right">
                        <button awNextStep class="btn btn-dark" type="button">Suivant <i class="fas fa-chevron-right"></i></button>
                    </div>
                </div>
            </aw-wizard-step>
    
            <!-- ETAPE 4: ENGIN -->
            <aw-wizard-step awOptionalStep stepTitle="Engin" [navigationSymbol]="{ symbol: '4' }">
                <div class="p-4">
                    <h4 class="font-weight-bold">Matériel(s) / Engin(s) affecté(s) au travaux</h4>
                    <hr>
                    <div class="m-0 p-0">
                        <div class="form-row">
                            <div class="col-md-6">
                                <div class="position-relative form-group">
                                    <label for="libelle">Matériel/Engin: </label>
                                    <div class="input-group">
                                        <select id="libelle" formControlName="libelle" class="form-control" *ngIf="inputGroup == false">
                                            <option value="Materiel">Matériel</option>
                                            <option value="Materiaux">Matériaux</option>
                                            <option value="Service">Service</option>
                                            <option value="Vehicule">Vehicule</option>
                                        </select>
                                        <input *ngIf="inputGroup == true" id="libelle" formControlName="libelle" class="form-control" placeholder="Libellé de l'engin" type="text">
                                        <button tooltip="{{inputGroup == true ? 'Clique pour choisir' : 'Clique pour saisir' }}" placement="auto" (click)="inputGroup = !inputGroup" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button">
                                            <span class="btn-wrapper--icon">
                                                <i class="{{inputGroup == true ? 'fas fa-hand-pointer' : 'fas fa-keyboard'}}"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="heureDebut">Heure début: </label>
                                    <input type="time" step="2" id="heureDebut" formControlName="heureDebut" class="form-control" placeholder="Heure debut d'utilisation">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="heureFin">Heure fin: </label>
                                    <input type="time" step="2" id="heureFin" formControlName="heureFin" class="form-control" placeholder="Heure fin d'utilisation">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="pause">Temps de pause(min): </label>
                                    <input type="time" step="2" id="pause" formControlName="pause" class="form-control" placeholder="Temps de pause(min)">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="tempsTavail">Temps de travail: </label>
                                    <input type="time" step="2" id="tempsTavail" formControlName="tempsTavail" class="form-control" placeholder="Temps de travail">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="cmptDebut">Compteur début journée (km): </label>
                                    <input type="number" id="cmptDebut" formControlName="cmptDebut" class="form-control" placeholder="Compteur début journée (km)">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="cmptFin">Compteur fin journée (km): </label>
                                    <input type="number" id="cmptFin" formControlName="cmptFin" class="form-control" placeholder="Compteur fin journée (km)">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="carburant">Carburant consommée (litre): </label>
                                    <input type="number" id="carburant" formControlName="carburant" class="form-control" placeholder="Carburant consommée (litre)">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="puCarburant">Prix/L du carburant(FCFA): </label>
                                    <input type="number" id="puCarburant" formControlName="puCarburant" class="form-control" placeholder="Prix/L du carburant(FCFA)">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="cout">Coût horaire(FCFA): </label>
                                    <input type="number" id="cout" formControlName="cout" class="form-control" placeholder="Coût horaire(FCFA)">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="depense">Montant dépensé (FCFA): </label>
                                    <input type="number" id="depense" formControlName="depense" class="form-control" placeholder="Montant dépensé (FCFA)">
                                </div>
                            </div>
                        </div>
                        <div class="text-right mb-3">
                            <button  class="btn btn-success" type="button" (click)="saveElement('engin')"><i class="fas fa-plus-circle mr-2"></i> Ajouter</button>
                        </div>
                        <div class="table-responsive m-0">
                            <table class="table table-alternate text-nowrap m-0">
                                <thead>
                                    <tr>
                                        <th>Libellé</th>
                                        <th>Heure debut</th>
                                        <th>Heure fin</th>
                                        <th>Temps de pause</th>
                                        <th>Temps de travail</th>
                                        <th>Compteur début journée</th>
                                        <th>Compteur fin journée</th>
                                        <th>Carburant consommée</th>
                                        <th>Prix/L du carburantt</th>
                                        <th>Coût horaire</th>
                                        <th>Montant dépensé </th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of enginData">
                                        <td>{{item.libelle}}</td>
                                        <td>{{item.heureDebut}}</td>
                                        <td>{{item.heureFin}}</td>
                                        <td>{{item.pause}}</td>
                                        <td>{{item.tempsTavail}}</td>
                                        <td>{{item.cmptDebut}}</td>
                                        <td>{{item.cmptFin}}</td>
                                        <td>{{item.carburant}}</td>
                                        <td>{{item.puCarburant}}</td>
                                        <td>{{item.cout}}</td>
                                        <td>{{item.depense}}</td>
                                        <td class="text-center">
                                            <button class="btn d-40 p-0 btn-pill btn-danger" placement="auto" tooltip="Supprimer" type="button" (click)="deleteElement('engin',item)">
                                                <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="enginData.length == 0">
                                        <td colspan="12">
                                            <div class="text-center">
                                                <h5 class="m-0 text-dark">
                                                    Aucune donnée trouvée; Reseigner le formulaire ci-dessus puis cliquer sur le boutton "Ajouter" pour en ajouter.
                                                </h5>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="d-flex justify-content-between px-4 py-2">
                    <div>
                        <button awPreviousStep class="btn btn-dark" type="button"><i class="fas fa-chevron-left"></i> Précédent</button>
                    </div>
                    <div class="text-right">
                        <button awNextStep class="btn btn-dark" type="button">Suivant <i class="fas fa-chevron-right"></i></button>
                    </div>
                </div>
            </aw-wizard-step>
    
            <!-- ETAPE 5: Ressource -->
            <aw-wizard-step awOptionalStep stepTitle="Inventaire des ressources" [navigationSymbol]="{ symbol: '5' }">
                <div class="p-4">
                    <h4 class="font-weight-bold">Inventaire des ressources</h4>
                    <hr>
                    <div class="m-0 p-0">
                        <div class="form-row">
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="type">Type: </label>
                                    <div class="input-group">
                                        <select id="type" formControlName="type" class="form-control" *ngIf="inputGroup == false">
                                            <option value="Materiel">Matériel</option>
                                            <option value="Materiaux">Matériaux</option>
                                            <option value="Service">Service</option>
                                            <option value="Vehicule">Vehicule</option>
                                        </select>
                                        <input *ngIf="inputGroup == true" id="type" formControlName="type" class="form-control" placeholder="Type" type="text">
                                        <button tooltip="{{inputGroup == true ? 'Clique pour choisir' : 'Clique pour saisir' }}" placement="auto" (click)="inputGroup = !inputGroup" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button">
                                            <span class="btn-wrapper--icon">
                                                <i class="{{inputGroup == true ? 'fas fa-hand-pointer' : 'fas fa-keyboard'}}"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="ressource">Ressources: </label>
                                    <select id="ressource" formControlName="ressource" class="form-control">
                                        <option value="Ressource 1">Ressource 1</option>
                                        <option value="Ressource 2">Ressource 2</option>
                                        <option value="Ressource 3">Ressource 3</option>
                                        <option value="Ressource 4">Ressource 4</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="quantite">Quantité: </label>
                                    <input type="number" min="0" id="quantite" formControlName="quantite" class="form-control" placeholder="Quantité">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="position-relative form-group">
                                    <label for="unite">Unité: </label>
                                    <input type="text" id="unite" formControlName="unite" class="form-control" placeholder="Unité">
                                </div>
                            </div>
                        </div>
                        <div class="text-right mb-3">
                            <button  class="btn btn-success" type="button" (click)="saveElement('ressources')"><i class="fas fa-plus-circle mr-2"></i> Ajouter</button>
                        </div>
                        <div class="table-responsive m-0">
                            <table class="table table-alternate text-nowrap m-0">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Ressources</th>
                                        <th>Quantité</th>
                                        <th>Unité</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of ressourceData">
                                        <td>{{item.type}}</td>
                                        <td>{{item.ressource}}</td>
                                        <td>{{item.quantite}}</td>
                                        <td>{{item.unite}}</td>
                                        <td class="text-center">
                                            <button class="btn d-40 p-0 btn-pill btn-danger" placement="auto" tooltip="Supprimer" type="button" (click)="deleteElement('ressources',item)">
                                                <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="ressourceData.length == 0">
                                        <td colspan="5">
                                            <div class="text-center">
                                                <h5 class="m-0 text-dark">
                                                    Aucune donnée trouvée; Reseigner le formulaire ci-dessus puis cliquer sur le boutton "Ajouter" pour en ajouter.
                                                </h5>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="d-flex justify-content-between px-4 py-2">
                    <div>
                        <button awPreviousStep class="btn btn-dark" type="button"><i class="fas fa-chevron-left"></i> Précédent</button>
                    </div>
                    <div class="text-right">
                        <button awNextStep class="btn btn-dark" type="button">Suivant <i class="fas fa-chevron-right"></i></button>
                    </div>
                </div>
            </aw-wizard-step>
    
            <!-- ETAPE 3: Ressources -->
            <aw-wizard-step awOptionalStep stepTitle="Ressources technique" [navigationSymbol]="{ symbol: '6' }">
                <div class="p-4">
                    <h4 class="font-weight-bold">Ressources technique</h4>
                    <hr>
                    <div class="m-0 p-0">
                        <div class="form-row">
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="fonction">Fonction: </label>
                                    <input type="text" id="fonction" formControlName="fonction" class="form-control" placeholder="Fontion">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="nbPersonne">Nombre de personne: </label>
                                    <input type="number" min="0" id="nbPersonne" formControlName="nbPersonne" class="form-control" placeholder="Nombre de personne">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="position-relative form-group">
                                    <label for="puPersonne">Prix par personne: </label>
                                    <input type="number" min="0" id="puPersonne" formControlName="puPersonne" class="form-control" placeholder="Prix par personne">
                                </div>
                            </div>
                        </div>
                        <div class="text-right mb-3">
                            <button  class="btn btn-success" type="button" (click)="saveElement('technique')"><i class="fas fa-plus-circle mr-2"></i> Ajouter</button>
                        </div>
                        <div class="table-responsive m-0">
                            <table class="table table-alternate text-nowrap m-0">
                                <thead>
                                    <tr>
                                        <th>Fonction</th>
                                        <th>Nombre de personne</th>
                                        <th>Prix par personne</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of techniqueData">
                                        <td>{{item.fonction}}</td>
                                        <td>{{item.nbPersonne}}</td>
                                        <td>{{item.puPersonne}}</td>
                                        <td class="text-center">
                                            <button class="btn d-40 p-0 btn-pill btn-danger" placement="auto" tooltip="Supprimer" type="button" (click)="deleteElement('technique',item)">
                                                <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="techniqueData.length == 0">
                                        <td colspan="5">
                                            <div class="text-center">
                                                <h5 class="m-0 text-dark">
                                                    Aucune donnée trouvée; Reseigner le formulaire ci-dessus puis cliquer sur le boutton "Ajouter" pour en ajouter.
                                                </h5>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <hr class="m-0">
                <div class="d-flex justify-content-between px-4 py-2">
                    <div>
                        <button awPreviousStep class="btn btn-dark" type="button"><i class="fas fa-chevron-left"></i> Précédent</button>
                    </div>
                    <div class="text-right">
                        <button class="btn btn-first mr-2" type="button" (click)="form.reset()"><i class="fas fa-broom mr-1"></i> Nettoyer</button>
                        <button  class="btn btn-success" type="submit"><i class="fas fa-save mr-2"></i> Enregistrer</button>
                    </div>
                </div>
            </aw-wizard-step>
        </aw-wizard>
    </form>
</div>