import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProjetPages } from 'src/app/models/projet-pages';
import { ProjetPagesService } from 'src/app/services/projet-pages/projet-pages.service';
import { UtilOptions } from 'src/app/shared-components/util/util-option';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-projet-detail',
  templateUrl: './projet-detail.component.html',
  styleUrls: ['./projet-detail.component.scss']
})
export class ProjetDetailComponent implements OnInit {
  projet: ProjetPages = <ProjetPages>{};
  budgetForm: FormGroup;
  filterForm: FormGroup;
  required = UtilOptions.required;
  typeRessource = UtilOptions.typeRessources;
  Data: any;
  budgets: any;
  listeResources:any;
  rotate = true;
  travauxWidgetView = true;
  tacheWidgetView = true;
  listView = true;
  rowExpend = false;
  checks = false;
  tQtePrevu: number;
  tQteCmd: number;
  tQteRest: number;

  selected: string;
  vehicule: string[] = ['Toyota', 'Nissan', 'Peugeo', 'Alpin', 'Renauld', '...'];

  searchRow = [
    {label: 'PAR CHANTIER', value: 'PAR CHANTIER'},
    {label: 'PAR TRAVAUX', value: 'PAR TRAVAUX'},
  ];
  chantiers = [
    { label: 'Chantier 1' },
    { label: 'Chantier 2' },
    { label: 'Chantier 3' }
  ];
  travaux = [
    { label: 'Travaux 1' },
    { label: 'Travaux 2' },
    { label: 'Travaux 3' }
  ];
  taches = [
    { label: 'Tâche 1' },
    { label: 'Tâche 2' },
    { label: 'Tâche 3' }
  ];

  // Tout les variables ou tableau ayants pour prefix "default" servent a faire des duplication dans le template.
  defaultRow1 = [
    { line: 1, etatValidation: "validée", etatLivraison: "livrée", fValidateur: "Chef projet" },
    { line: 2, etatValidation: "En attente", etatLivraison: "En attente", fValidateur: "Chef chantier" },
    { line: 3, etatValidation: "non validée", etatLivraison: "livrée", fValidateur: "Chef projet" },
  ];

  constructor(
    public globals: ThemeOptions,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private projetService: ProjetPagesService,
  )
  {
    this.projet = this.projetService.getProjet();
    // console.log(this.projet)
    const id: number = +this.route.snapshot.paramMap.get('id');
    this.projetService.getList().subscribe((projets: ProjetPages[]) => {
      this.projet = projets.find(projet => projet.id == id);
    });
    this.newForm();
    this.etablirBudgetForms();
    this.Data = [];
    this.budgets = this.getBudget();
  }
  
  ngOnInit() {
    this.tQtePrevu = this.listeResources.reduce((sum, item) => sum + item.qtePrevu, 0);
    this.tQteCmd = this.listeResources.reduce((sum, item) => sum + item.qteCmd, 0);
    this.tQteRest = this.listeResources.reduce((sum, item) => sum + item.qteRest, 0);
  }

  newForm(){
    this.filterForm = this.formBuilder.group({
      mode: ['PAR CHANTIER', [Validators.required]],
      chantier: ['Chantier 1', [Validators.required]],
      travaux: ['', [Validators.required]],
      tache: ['', [Validators.required]]
    });
  }

  etablirBudgetForms(){
    this.budgetForm = this.formBuilder.group({
      mois: [null, [Validators.required]],
      typeRessource: [null, [Validators.required]],
      ressource: [null, [Validators.required]],
      montant: [null, [Validators.required]],
    });
  }

  //Ajout et suppression de budget dans le tableau dans le formulaire de planification de budget
  saveBudget(){
    this.Data.push(this.budgetForm.value);
    this.budgetForm.reset();
  }
  deleteBudget(element){
    this.Data.forEach((value,index) => {
      if(value == element)
        this.Data.splice(index, 1);
    });
  }
  onSubmit(){}
  getBudget(){
    return [
      {
        id: 1,
        travaux: "AVANT PROJET",
        mtnPropose: 1459998788,
        mtnValide: 14566000,
        etat: "validé"
      },
      {
        id: 2,
        travaux: "GROS OEUVRES",
        mtnPropose: 454500098,
        mtnValide: 18900000,
        etat: "Ancun budget établi"
      },
      {
        id: 3,
        travaux: "SECOND OEUVRE",
        mtnPropose: 544500000,
        mtnValide: 10000009,
        etat: "validé"
      },
      {
        id: 4,
        travaux: "AVANT PROJET",
        mtnPropose: 500000000,
        mtnValide: 10000000,
        etat: "Ancun budget établi"
      }
    ]
  }

  // Pour les case a coché
  lock(event){
    if(event.target.checked == true){
      this.checks = true;
    }
    else{
      this.checks = false;
    }
  }

  get f() { return this.filterForm.controls; }

}
