export class UtilOptions{
  public static required: any = {
    novide: 'Ce champ est requis.',
    nolettre: 'Ce champ ne peut contenir de lettre.',
    nonumber: 'Ce champ ne peut contenir de chiffre.',
    noemail: 'Ce champ doit être au format E-mail.',
    min: 'Ce mot est trop court.',
    max: 'Ce mot est trop long.'
  };
  public static typeRessources: any = [
    {label: 'Matériel', value: 'matériel'},
    {label: 'Matériaux', value: 'matériaux'},
    {label: 'Service', value: 'service'},
    {label: 'Véhicule', value: 'véhicule'},
    {label: 'Technique', value: 'technique'},
    {label: 'Fourniture', value: 'fourniture'},
    {label: 'Humaine', value: 'humaine'},
    {label: 'Administrative', value: 'administrative'},
    {label: 'Autre', value: 'autre'},
  ]
  public static reponseOuiNom: any = [
    { label: "OUI", value: "OUI" },
    { label: "NON", value: "NON" }
  ]
  public static type: any = [
    {label: "Personne physique", value: "2"},
    {label: "Personne morale", value: "1"},
   
  ]
  public static gravite: any = [
    { label: "Urgente", value: "Urgente" },
    { label: "Normal", value: "Normal" },
    { label: "Haute", value: "Haute" },
    { label: "Moyenne", value: "Moyenne" },
    { label: "Faible", value: "Faible" },
    { label: "Trivial", value: "Trivial" }
  ]
  public static mois: any = [
    { label: "Janvier", value: "janvier" },
    { label: "Février", value: "février" },
    { label: "Mars", value: "mars" },
    { label: "Avril", value: "avril" },
    { label: "Mai", value: "mai" },
    { label: "Juin", value: "juin" },
    { label: "Juillet", value: "juillet" },
    { label: "Aôut", value: "aôut" },
    { label: "Septembre", value: "septembre" },
    { label: "Octobre", value: "octobre" },
    { label: "Novembre", value: "novembre" },
    { label: "Décembre", value: "décembre" },
  ]
  public static pays: any = [
    // Afrique
    'Afrique du Sud', 'Algérie', 'Angola', 'Bénin', 'Botswana', 'Burkina Faso', 'Burundi', 'Cameroun', 'Cap-Vert', 'République centrafricaine', 'Comores', 'République du Congo', 'République démocratique du Congo', 'Côte d’Ivoire', 'Djibouti',
    'Égypte', 'Érythrée', 'Eswatini', 'Éthiopie', 'Gabon', 'Gambie', 'Ghana', 'Guinée', 'Guinée-Bissau', 'Guinée équatoriale', 'Kenya', 'Lesotho', 'Liberia', 'Libye', 'Madagascar',
    'Malawi', 'Mali', 'Maroc', 'Maurice', 'Mauritanie', 'Mozambique', 'Namibie', 'Niger', 'Nigeria', 'Ouganda', 'Rwanda', 'São Tomé-et-Principe', 'Sénégal', 'Seychelles', 'Sierra Leone',
    'Somalie', 'Soudan', 'Soudan du Sud', 'Tanzanie', 'Tchad', 'Togo', 'Tunisie', 'Zambie', 'Zimbabwe',

    // Amerique
    'Antigua-et-Barbuda', 'Argentine', 'Bahamas', 'Barbade', 'Belize', 'Bolivie', 'Brésil', 'Canada', 'Chili', 'Colombie', 'Costa Rica', 'Cuba', 'Dominique', 'Equateur', 'Etats-Unis',
    'Grenade', 'Guatemala', 'Guyana', 'Haïti', 'Honduras', 'Jamaïque', 'Mexique', 'Nicaragua', 'Panama', 'Paraguay', 'Pérou', 'République Dominicaine', 'Saint-Kitts-et-Nevis', 'Saint-Vincent-et-les-Grenadines', 'Sainte-Lucie',
    'Salvador', 'Suriname', 'Trinité-et-Tobago', 'Uruguay',	 'Venezuela',

    // Asie
    'Afghanistan', 'Arabie Saoudite', 'Arménie', 'Azerbaïdjan', 'Bahreïn', 'Bangladesh', 'Bhoutan', 'Birmanie', 'Brunei', 'Cambodge', 'Chine', 'Corée du Nord', 'Corée du Sud', 'Émirats arabes unis', 'Géorgie',
    'Inde', 'Indonésie', 'Irak', 'Iran', 'Israël', 'Japon', 'Jordanie', 'Kazakhstan', 'Kirghizistan', 'Koweït', 'Laos', 'Liban', 'Malaisie', 'Maldives', 'Mongolie', 'Népal',
    'Oman', 'Ouzbékistan', 'Palestine', 'Pakistan', 'Philippines', 'Qatar', 'Singapour', 'Sri Lanka', 'Syrie', 'Tadjikistan', 'Thaïlande', 'Timor oriental', 'Turkménistan', 'Turquie', 'Viêt Nam', 'Yémen',

    // Europe
    'Albanie', 'Allemagne', 'Andorre', 'Arménie', 'Autriche', 'Azerbaïdjan', 'Belgique', 'Biélorussie', 'Bosnie-Herzégovine', 'Bulgarie', 'Chypre1', 'Croatie', 'Danemark',
    'Espagne', 'Estonie', 'Finlande', 'France', 'Géorgie', 'Grèce', 'Hongrie', 'Irlande', 'Islande', 'Italie', 'Kosovo', 'Lettonie', 'Liechtenstein', 'Lituanie',
    'Luxembourg', 'Macédoine du Nord', 'Malte', 'Moldavie', 'Monaco', 'Monténégro', 'Norvège', 'Pays-Bas', 'Pologne', 'Portugal', 'République tchèque', 'Roumanie', 'Royaume-Uni',
    'Russie', 'Saint-Marin', 'Serbie', 'Slovaquie', 'Slovénie', 'Suède', 'Suisse', 'Turquie', 'Ukraine', 'Vatican',

    // Oceanie
    'Australie', 'Îles Cook', 'Fidji', 'Kiribati', 'Îles Marshall', 'Micronésie', 'Nauru', 'Niue', 'Nouvelle-Zélande', 'Palaos', 'Papouasie-Nouvelle-Guinée', 'Salomon', 'Samoa', 'Tonga', 'Tuvalu', 'Vanuatu',
  ];
}