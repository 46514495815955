import { Component, OnInit } from '@angular/core';
import { BulletinHse } from 'src/app/models/bulletin-hse';
import { BulletinHseService } from 'src/app/services/bulletin-hse/bulletin-hse.service';

@Component({
  selector: 'app-bulletin-hse-list',
  templateUrl: './bulletin-hse-list.component.html',
  styleUrls: ['./bulletin-hse-list.component.scss']
})
export class BulletinHseListComponent implements OnInit {
  bulletins: BulletinHse[];
  errMsg = "";
  rotate = true;

  constructor(
    private bulletinService: BulletinHseService,
  ) {
    this.bulletinService.getList().subscribe({
      next: bulletin => this.bulletins = bulletin,
      error: err => this.errMsg = err
    });
  }

  ngOnInit() {
  }

}
