import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientDashboardComponent } from './client-dashboard/client-dashboard.component';
import { ClientPagesComponent } from './client-pages.component';


const routes: Routes = [
  {
    path:'',
    component: ClientPagesComponent,
    children: [
      {
        path: "dashboard",
        component: ClientDashboardComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientPagesRoutingModule { }
