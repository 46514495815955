import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FileUploader } from 'ng2-file-upload';
import { OpportunitePages } from 'src/app/models/opportunite-pages';
import { Taches } from 'src/app/models/taches';
import { OpportunitePagesService } from 'src/app/services/opportunite-pages/opportunite-pages.service';
import { TachesService } from 'src/app/services/taches/taches.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-opportunite-detail',
  templateUrl: './opportunite-detail.component.html',
  styleUrls: ['./opportunite-detail.component.scss']
})
export class OpportuniteDetailComponent implements OnInit {
  taches: Taches[];
  uneTache: Taches = <Taches>{};
  opportunite: OpportunitePages = <OpportunitePages>{};
  toggleMobileSidebar1: boolean = false;
  toggleMobileSidebar2: boolean = false;
  check: boolean;
  planifierActiviteFrom: FormGroup;
  value:any;
  inputTrx: boolean = false;
  inputLotActivite: boolean = true;
  inputActivite: boolean = true;
  travauxData: any;
  status1 = false;
  rotate = true;
  mask: Array<string | RegExp>;
  maskDate: Array<string | RegExp>;
  maskPhone: Array<string | RegExp>;
  uploader:FileUploader;
  activite = true;
  table = false;
  errMsg = "";
  tabs = [
    { title: 'First', content: 'First Tab Content' },
    { title: 'Second', content: 'Second Tab Content', active: true },
    { title: 'Third', content: 'Third Tab Content', removable: true },
    { title: 'Four', content: 'Fourth Tab Content', disabled: true }
  ];

  assigneA = [
    { label: "Kouassi", value: "Kouassi" },
    { label: "Ghislain", value: "Ghislain" },
    { label: "Amond", value: "Amond" },
    { label: "Kader", value: "Kader" }
  ];


  //Editor
  htmlContent = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Entrer le text ici...',
    translate: 'no',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  
  constructor(
    private route: ActivatedRoute,
    public globals: ThemeOptions,
    private formBuild: FormBuilder,
    private opportuniteService: OpportunitePagesService,
    private tachesService: TachesService
  )
  {
    this.travauxData = [];
    this.maskPhone = ['(', '+', '2', '2', '5', ')', ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
    this.maskDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    const id: number = +this.route.snapshot.paramMap.get('id');
    this.opportuniteService.getList().subscribe((opportunites: OpportunitePages[]) => {
      this.opportunite = opportunites.find(opportunite => opportunite.id == id);
    });
    // Afficher la liste des tâches
    this.tachesService.getList().subscribe({
      next: desTaches => this.taches = desTaches,
      error: err => this.errMsg = err
    });
  }
  // Afficher le detail des taches
  detaiTache() {
    const id: number = +this.route.snapshot.paramMap.get('id');
    this.tachesService.getList().subscribe((taches: Taches[]) => {
      this.uneTache = taches.find(tache => tache.id == id);
    })
  }

  ngOnInit() {
    this.planifierActiviteFrom = this.formBuild.group({
      tache: [null, [Validators.required]],
      SousTache: [null, [Validators.required]],
      dateEcheance: [null, [Validators.required]] ,
      contact: [null, [Validators.required]] ,
      responsableTache: [null, [Validators.required]] ,
      description: ['', [Validators.required]]
    });
  }

  addTravaux() {
    console.log(this.planifierActiviteFrom.value);
    this.travauxData.push(this.planifierActiviteFrom.value);
    this.planifierActiviteFrom.reset();
  }

  removeTravaux(element) {
    this.travauxData.forEach((value,index) => {
      if(value == element)
        this.travauxData.splice(index, 1);
    });
  }
  reset() {
    this.planifierActiviteFrom.reset();
  }
  toggleActivite() {
    this.activite = !this.activite;
  }

  showTable(tb: boolean) {
    this.table = tb;
  }
  
  get f() { return this.planifierActiviteFrom.controls; }

}
