import { Component, Input, OnInit } from '@angular/core';
import { BudgetPages } from 'src/app/models/budget-pages';
import { BudgetPagesService } from 'src/app/services/budgetPages/budget-pages.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-budget-list',
  templateUrl: './budget-list.component.html',
  styleUrls: ['./budget-list.component.scss']
})
export class BudgetListComponent implements OnInit {
  @Input() widgetView: boolean = false;
  budgets: BudgetPages[]
  rotate = true;
  errMsg = "";

  public constructor(
    public globals: ThemeOptions,
    private budgetService: BudgetPagesService
  ) {
    this.budgetService.getList().subscribe({
      next: budget => this.budgets = budget,
      error: err => this.errMsg = err
    });
  }

  ngOnInit() {
  }

}
