<div class="card card-box border-0 mb-5">
    <div class="card-header p-3">
        <div class="card-header--title">
            <h6 class="font-weight-bold m-0">Liste des chantiers</h6>
        </div>
        <div class="card-header--actions">
            <div class="btn-group" dropdown>
                <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret mr-2"
                    dropdownToggle
                    tooltip="Action"
                    type="button">
                    <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                </button>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                    <ul class="nav nav-pills flex-column p-3">
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-3">
        <div class="d-flex justify-content-between mb-3">
            <div class="search-wrapper">
              <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
              <input class="form-control" placeholder="Recherche dans la liste" type="search">
            </div>
        </div>
        <div class="table-responsive m-0">
            <table class="table table-hover text-nowrap m-0">
                <thead>
                    <tr>
                        <th class="text-left">Libellé&nbsp;<i class="fas fa-sort mr-1"></i></th>
                        <th class="text-left">Chef chantier&nbsp;<i class="fas fa-sort mr-1"></i></th>
                        <th class="text-center">Durée prévue</th>
                        <th class="text-center">Budget</th>
                        <th class="text-center">Dépense</th>
                        <th class="text-center">Travaux planifiés</th>
                        <th class="text-center">Evolution </th>
                        <th class="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let chantier of chantiers">
                        <td>
                            <div class="d-flex align-items-center">
                                <div>
                                    <a [routerLink]="['/pages/chantier/detail',chantier?.id]" class="font-weight-bold text-black" title="{{chantier?.libelle}}">
                                        {{chantier?.libelle}}
                                    </a>
                                    <span class="text-black-50 d-block">{{chantier?.reference}}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="avatar-icon-wrapper mr-2">
                                    <div class="avatar-icon">
                                        <img alt="{{chantier?.chefChantier}}" src="{{chantier?.photoChef}}"/>
                                    </div>
                                </div>
                                <div>
                                    <a [routerLink]="" class="font-weight-bold text-black" title="...">{{chantier?.chefChantier}}</a>
                                    <span class="text-black-50 d-block">{{chantier?.fonction}}</span>
                                </div>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="badge bg-neutral-warning text-warning px-4">{{chantier?.durePrevue}} Jours</div>
                        </td>
                        <td class="text-center">
                            <div class="badge bg-neutral-warning text-warning px-4">{{chantier?.budget | number:'': 'fr-FR'}}</div>
                        </td>
                        <td class="text-center">
                            <div class="badge bg-neutral-warning text-warning px-4">{{chantier?.depense | number:'': 'fr-FR'}}</div>
                        </td>
                        <td class="text-center">
                            <div class="badge bg-neutral-warning text-warning px-4">1 / {{chantier?.travaux}}</div>
                        </td>
                        <td>
                            <progressbar
                                [value]="chantier?.evolution"
                                class="progress-bar-rounded"
                                type="{{
                                    chantier?.evolution >= 0 && chantier?.evolution <= 24 ? 'danger':
                                    chantier?.evolution >= 25 && chantier?.evolution <= 49 ? 'warning':
                                    chantier?.evolution >= 50 && chantier?.evolution <= 64 ? 'info':
                                    chantier?.evolution >= 65 && chantier?.evolution <= 99 ? 'first':'success'
                                }}">{{chantier?.evolution}}%
                            </progressbar>
                        </td>
                        <td class="text-center">
                            <button class="btn d-40 p-0 btn-pill mr-2 btn-primary"placement="auto" [routerLink]="['/pages/chantier/detail',chantier?.id]" tooltip="Plus d'infos" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                            </button>
                            <button class="btn d-40 p-0 btn-pill mr-2 btn-info" placement="auto" tooltip="Modifier" type="button">
                                <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                            </button>
                            <button  placement="auto"  tooltip="Supprimer"  class="btn d-40 p-0 btn-pill btn-danger" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card-footer p-3">
        <div class="d-flex justify-content-between">
            <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
            <div class="d-flex align-items-center">
                <span>Show</span>
                <select class="mx-1 form-control form-control-sm" id="" name="">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="40">50</option>
                    <option value="40">100</option>
                </select>
                <span>entries</span>
            </div>
        </div>
    </div>
</div>