import { Component, OnInit } from '@angular/core';
import { ChantierPages } from 'src/app/models/chantier-pages';
import { ChantierPagesService } from 'src/app/services/chantier-pages/chantier-pages.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-chantier-list',
  templateUrl: './chantier-list.component.html',
  styleUrls: ['./chantier-list.component.scss']
})
export class ChantierListComponent implements OnInit {
  chantiers: ChantierPages[];
  rotate = true;
  errMsg = "";

  constructor(
    public globals: ThemeOptions,
    private chantierService: ChantierPagesService
  ) {
    this.chantierService.getList().subscribe({
      next: chantier => this.chantiers = chantier,
      error: err => this.errMsg = err
    });
  }

  ngOnInit() {
  }

}
