import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { NotifierService } from 'angular-notifier';
import { ThemeOptions } from 'src/app/theme-options';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilOptions } from 'src/app/shared-components/util/util-option';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TresorerieService } from '../tresorerie.service';

@Component({
  selector: 'app-tresorerie-edit',
  templateUrl: './tresorerie-edit.component.html',
  styleUrls: ['./tresorerie-edit.component.scss']
})
export class TresorerieEditComponent implements OnInit {
  title: string = 'Ajouter une nouvelle trésorerie';
  type = '';
  form: FormGroup
  tresoreries: any;
  rotate: boolean = true;
  submit: boolean = false;
  editMode: boolean = false;
  disponibilite: boolean = false;
  required = UtilOptions.required;
  private notifier: NotifierService;
  public mask: Array<string | RegExp>;
  public maskDate: Array<string | RegExp>;
  typeRow = [
    { label: "BANQUE", value: "BANQUE" },
    { label: "CAISSE", value: "CAISSE" }
  ];
  etatRow = [
    { label: "FERME", value: "FERME" },
    { label: "OUVERT", value: "OUVERT" }
  ]
  gerantRow = [
    { label: "AMON ROLAND", value: "AMON ROLAND" },
    { label: "DIARRA SEIDOU", value: "DIARRA SEIDOU" },
    { label: "ANICET GNAHOUA", value: "ANICET GNAHOUA" },
    { label: "KOUASSI GHISLAIN", value: "KOUASSI GHISLAIN" },
    { label: "MAMAN DIBOLE KADER", value: "MAMAN DIBOLE KADER" },
  ]

  constructor(
    notifier: NotifierService,
    private router: Router,
    public modal: NgbActiveModal,
    public globals: ThemeOptions,
    private formBuild: FormBuilder,
    private spinner: NgxSpinnerService,
    private tresorerieService: TresorerieService,
  ) { 
    this.getData();
    this.notifier = notifier;
    this.type = this.tresorerieService.type;
    this.maskDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  }

  ngOnInit() {
  }
  changeStatus(event){
    if(event.target.checked == true){
      this.f.disponibilite.value == true;
    }
    else{
      this.f.disponibilite.value == false;
    }
  }
  
  initForm(){
    this.form = new FormGroup({
      "etat": new FormControl("", [Validators.required]),
      "type" : new FormControl("", [Validators.required]),
      "devise": new FormControl("", [Validators.required]),
      "gerant": new FormControl("", [Validators.required]),
      "codeBS": new FormControl("", [Validators.required]),
      "libelle": new FormControl("", [Validators.required]),
      "seuilMin": new FormControl("",[Validators.required]),
      "codeIban": new FormControl("", [Validators.required]),
      "seuilMax": new FormControl("", [Validators.required]),
      "numCompte": new FormControl("", [Validators.required]),
      "reference": new FormControl("", [Validators.required]),
      "codeJournal": new FormControl("", [Validators.required]),
      "soldeInitial": new FormControl("", [Validators.required]),
      "disponibilite": new FormControl(false, [Validators.required]),
      "domiciliation": new FormControl("", [Validators.required]),
    });
  }
  editForm(data) {
    this.initForm();
    this.title = "Modifier la tresorerie " + data.libelle,
    this.form.patchValue({
      etat: data.etat,
      type: data.type,
      devise: data.devise,
      gerant: data.gerant,
      codeBS: data.codeBS,
      libelle: data.libelle,
      seuilMin: data.seuilMin,
      codeIban: data.codeIban,
      seuilMax: data.seuilMax,
      numCompte: data.numCompte,
      reference: data.reference,
      codeJournal: data.codeJournal,
      soldeInitial: data.soldeInitial,
      domiciliation: data.domiciliation,
      disponibilite: data.disponibilite
    });
  }

  onSubmit() {
    this.submit = true;
    this.tresorerieService.type = this.f.type.value;
    if (this.editMode) {
      this.tresorerieService.edit(
        this.tresoreries.tresorerie.id,
        {
          etat: this.form.value.etat,
          type: this.form.value.type,
          devise: this.form.value.devise,
          codeBS: this.form.value.codeBS,
          gerant: this.form.value.gerant,
          libelle: this.form.value.libelle,
          seuilMin: this.form.value.seuilMin,
          codeIban: this.form.value.codeIban,
          seuilMax: this.form.value.seuilMax,
          numCompte: this.form.value.numCompte,
          reference: this.form.value.reference,
          codeJournal: this.form.value.codeJournal,
          soldeInitial: this.form.value.soldeInitial,
          domiciliation: this.form.value.domiciliation,
          disponibilite: this.form.value.disponibilite
        }
      ).subscribe(
        resData => {
          if(resData) {
            this.spinner.hide("sp6");
            this.notifications( 'success', 'Enregistrement effectué avec success.');
            this.modal.close('update');
          }         
        },
        errorRes => {
          this.spinner.hide("sp6");
          this.notifications( 'danger', 'Enregistrement echoué!.');
        }
      );
    }
    else{
      this.tresorerieService.add(
        {
          etat: this.form.value.etat,
          type: this.form.value.type,
          devise: this.form.value.devise,
          codeBS: this.form.value.codeBS,
          gerant: this.form.value.gerant,
          libelle: this.form.value.libelle,
          seuilMin: this.form.value.seuilMin,
          codeIban: this.form.value.codeIban,
          seuilMax: this.form.value.seuilMax,
          reference: this.form.value.reference,
          numCompte: this.form.value.numCompte,
          codeJournal: this.form.value.codeJournal,
          soldeInitial: this.form.value.soldeInitial,
          domiciliation: this.form.value.domiciliation,
          disponibilite: this.form.value.disponibilite
        }
      ).subscribe(
        resData => {
          if(resData) {
            this.spinner.hide("sp6");
            this.notifications( 'success', 'Enregistrement effectué avec success.');
            this.modal.close('success');
          }         
        },
        errorRes => {
          this.spinner.hide("sp6");
          this.notifications( 'danger', 'Enregistrement echoué!.');
        }
      );
    }
  }

  getData() {
    this.tresoreries = this.tresorerieService.getTresorerie();
    if(this.tresoreries) {
      this.editMode = this.tresoreries.action;
    } else {
      this.editMode = false;
    }
    if(this.editMode == true) {
      this.editForm(this.tresoreries.tresorerie);
    } else {
      this.initForm();
    }
  }

  onClose(): any{
    this.initForm();
    this.form.reset()
    this.modal.close();
    this.editMode = false;
    this.tresoreries = "";
    this.tresorerieService.setTresorerie("")
  }
  
  notifications( type: string, message: string ): void {
    if (type == 'primary') {
      this.notifier.notify( type, message );
    } else if (type == 'success') {
      this.notifier.notify( type, message );
    } else if (type == 'warning') {
      this.notifier.notify( type, message );
    } else if (type == 'danger') {
      this.notifier.notify( type, message );
    } else if (type == 'dark') {
      this.notifier.notify( type, message );
    } else if (type == 'second') {
      this.notifier.notify( type, message );
    } else if (type == 'first') {
      this.notifier.notify( type, message );
    }
  }

  get f() { return this.form.controls; }
}
