import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SituationHse } from 'src/app/models/situation-hse';

@Injectable({
  providedIn: 'root'
})
export class SituationHseService {
  situation: SituationHse;
  private readonly url = "api/situation-hse.json";

  constructor(
    private http: HttpClient
  ) { }

  setSituation(situation: SituationHse) {
    this.situation = situation
  }

  getSituation(): SituationHse {
    return this.situation
  }

  getList(): Observable<SituationHse[]> {
    return this.http.get<SituationHse[]>(this.url).pipe();
  }
}
