<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-add-user display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>Tableau de fournisseur</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">
                    Statistique générales
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-4">
        <div class="card card-box mb-4 bg-premium-dark border-0 text-light">
            <div class="card-body">
                <div class="align-box-row align-items-start">
                    <div class="font-weight-bold">
                        <small class="text-white-50 d-block mb-1 text-uppercase">Total commandes</small>
                        <span class="font-size-xxl mt-1">556</span>
                    </div>
                    <div class="ml-auto">
                        <div class="bg-white text-center text-success font-size-xl d-50 rounded-circle">
                            <i class="fas fa-shopping-cart"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card card-box mb-4 bg-night-sky text-light">
            <div class="card-body">
                <div class="align-box-row align-items-start">
                    <div class="font-weight-bold">
                        <small class="text-white-50 d-block mb-1 text-uppercase">Total factures</small>
                        <span class="font-size-xxl mt-1">24</span>
                    </div>
                    <div class="ml-auto">
                        <div class="bg-white text-center text-primary font-size-xl d-50 rounded-circle">
                            <i class="fas fa-file-invoice"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card card-box mb-4 bg-midnight-bloom text-white">
            <div class="card-body">
                <div class="align-box-row align-items-start">
                    <div class="font-weight-bold">
                        <small class="text-white-50 d-block mb-1 text-uppercase">Total projets</small>
                        <span class="font-size-xxl mt-1">345</span>
                    </div>
                    <div class="ml-auto">
                        <div class="bg-white text-center text-danger font-size-xl d-50 rounded-circle">
                            <i class="fas fa-umbrella"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card card-box border-0 mb-4">
    <div class="card-header p-3">
        <div class="card-header--title">
            <h6 class="font-weight-bold m-0">Statistiques de l'etat des factures de l'année en cours</h6>
        </div>
    </div>
    <div class="card-body p-0">
        <apx-chart
            [chart]="{
                type: 'bar',
                height: '365',
                sparkline: {enabled: false},
                toolbar: { show: false}
            }"
            [colors]="['#1cc943', '#4191ff', '#f4772e', '#ff0000']"
            [dataLabels]="{ enabled: false }"
            [fill]="{
                opacity: 0.85,
                colors: ['#1cc943', '#4191ff', '#f4772e', '#ff0000']
            }"
            [grid]="{
                strokeDashArray: '5',
                borderColor: 'rgba(125, 138, 156, 0.3)'
            }"
            [labels]="['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aôut', 'Septembre', 'Octobre', 'Novembre', 'Decembre']"
            [legend]="{ show: true }"
            [series]="[
                {
                    name: 'Payé',
                    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                },
                {
                    name: 'En cours',
                    data: [1, 2, 3, 4, 5, 6, 7, 1, 2, 10, 0, 1.3]
                },
                {
                    name: 'En attent',
                    data: [2.1, 2.1, 2.8, 2.8, 4.3, 2.7, 1.4, 1, 2, 3, 4, 5]
                },
                {
                    name: 'Impayé',
                    data: [2.1, 0, 1, 0, 6, 2.7, 1.4, 7, 0, 1, 0, 5]
                }
            ]"
            [stroke]="{
                show: true,
                width: 2,
                colors: ['transparent']
            }"
        ></apx-chart>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <div class="d-flex flex-wrap">
            <div class="w-50 p-2">
                <button class="btn card card-box text-left d-flex justify-content-center px-4 py-3 w-100" type="button">
                    <div>
                        <h2><i class="fas fa-check d-block line-height-1 text-success"></i></h2>
                        <div class="font-weight-bold text-black">Commandes validée(s)</div>
                        <div class="font-size-md mb-1 text-black-50">33</div>
                    </div>
                </button>
            </div>
            <div class="w-50 p-2">
                <button class="btn card card-box text-left d-flex justify-content-center px-4 py-3 w-100"
                        type="button">
                    <div>
                        <h2><i class="fas fa-pause d-block line-height-1 text-warning"></i></h2>
                        <div class="font-weight-bold text-black">Commandes à validée(s)</div>
                        <div class="font-size-md mb-1 text-black-50">23</div>
                    </div>
                </button>
            </div>
            <div class="w-50 p-2">
                <button class="btn card card-box text-left d-flex justify-content-center px-4 py-3 w-100" type="button">
                    <div>
                        <h2><i class="fas fa-times d-block line-height-1 text-danger"></i></h2>
                        <div class="font-weight-bold text-black">Commandes non validée(s)</div>
                        <div class="font-size-md mb-1 text-black-50">33</div>
                    </div>
                </button>
            </div>

            <div class="w-50 p-2">
                <button class="btn card card-box text-left d-flex justify-content-center px-4 py-3 w-100" type="button">
                    <div>
                        <h2><i class="fas fa-truck d-block line-height-1 text-success"></i></h2>
                        <div class="font-weight-bold text-black">Commandes livrée(s)</div>
                        <div class="font-size-md mb-1 text-black-50">33</div>
                    </div>
                </button>
            </div>
            <div class="w-50 p-2">
                <button class="btn card card-box text-left d-flex justify-content-center px-4 py-3 w-100"
                        type="button">
                    <div>
                        <h2><i class="fas fa-pause d-block line-height-1 text-warning"></i></h2>
                        <div class="font-weight-bold text-black">Commandes à livrée(s)</div>
                        <div class="font-size-md mb-1 text-black-50">23</div>
                    </div>
                </button>
            </div>
            <div class="w-50 p-2">
                <button class="btn card card-box text-left d-flex justify-content-center px-4 py-3 w-100" type="button">
                    <div>
                        <h2><i class="fas fa-times d-block line-height-1 text-danger"></i></h2>
                        <div class="font-weight-bold text-black">Commandes non livrée(s)</div>
                        <div class="font-size-md mb-1 text-black-50">33</div>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="row">
            <div class="col-md-6">
                <a [routerLink]="" class="card card-box card-box-hover-rise card-box-hover bg-premium-dark text-white align-box-row align-items-start mb-4 mt-1 p-3">
                    <div>
                        <div class="display-4 font-weight-bold">56</div>
                        <div class="divider mt-2 mb-3 border-2 w-25 bg-first rounded border-first"></div>
                        <div class="font-weight-bold text-white-50 font-size-sm text-uppercase">Facture payée(s)</div>
                    </div>
                    <div class="ml-auto card-hover-indicator align-self-center">
                        <fa-icon [icon]="['fas', 'chevron-right']" class="font-size-xl"></fa-icon>
                    </div>
                </a>
            </div>
            <div class="col-md-6">
                <a [routerLink]="" class="card card-box card-box-hover-rise card-box-hover bg-vicious-stance text-white align-box-row align-items-start mb-4 mt-1 p-3">
                    <div>
                        <div class="display-4 font-weight-bold">56</div>
                        <div class="divider mt-2 mb-3 border-2 w-25 bg-danger rounded border-danger"></div>
                        <div class="font-weight-bold text-white-50 font-size-sm text-uppercase">Factures à payer</div>
                    </div>
                    <div class="ml-auto card-hover-indicator align-self-center">
                        <fa-icon [icon]="['fas', 'chevron-right']" class="font-size-xl"></fa-icon>
                    </div>
                </a>
            </div>
            <div class="col-md-6">
                <a [routerLink]="" class="card card-box card-box-hover-rise card-box-hover bg-midnight-bloom text-white align-box-row align-items-start mb-4 mt-1 p-3">
                    <div>
                        <div class="display-4 font-weight-bold">56</div>
                        <div class="divider mt-2 mb-3 border-2 w-25 bg-success rounded border-success"></div>
                        <div class="font-weight-bold text-white-50 font-size-sm text-uppercase">Fatures impayer</div>
                    </div>
                    <div class="ml-auto card-hover-indicator align-self-center">
                        <fa-icon [icon]="['fas', 'chevron-right']" class="font-size-xl"></fa-icon>
                    </div>
                </a>
            </div>
            <div class="col-md-6">
                <a [routerLink]="" class="card card-box card-box-hover-rise card-box-hover bg-night-sky text-white align-box-row align-items-start mb-4 mt-1 p-3">
                    <div>
                        <div class="display-4 font-weight-bold">56</div>
                        <div class="divider mt-2 mb-3 border-2 w-25 bg-warning rounded border-success"></div>
                        <div class="font-weight-bold text-white-50 font-size-sm text-uppercase">Factures validée(s)</div>
                    </div>

                    <div class="ml-auto card-hover-indicator align-self-center">
                        <fa-icon [icon]="['fas', 'chevron-right']" class="font-size-xl"></fa-icon>
                    </div>
                </a>
            </div>
            <div class="col-md-6">
                <a [routerLink]="" class="card card-box card-box-hover-rise card-box-hover bg-deep-blue text-white align-box-row align-items-start mb-4 mt-1 p-3 border-0">
                    <div>
                        <div class="display-4 font-weight-bold">56</div>
                        <div class="divider mt-2 mb-3 border-2 w-25 bg-warning rounded border-warning"></div>
                        <div class="font-weight-bold text-black-50 font-size-sm text-uppercase">A validée(s)</div>
                    </div>

                    <div class="ml-auto card-hover-indicator align-self-center">
                        <fa-icon [icon]="['fas', 'chevron-right']" class="font-size-xl"></fa-icon>
                    </div>
                </a>
            </div>
            <div class="col-md-6">
                <a [routerLink]="" class="card card-box card-box-hover-rise card-box-hover bg-premium-dark text-white align-box-row align-items-start mb-4 mt-1 p-3">
                    <div>
                        <div class="display-4 font-weight-bold">56</div>
                        <div class="divider mt-2 mb-3 border-2 w-25 bg-danger rounded border-danger"></div>
                        <div class="font-weight-bold text-white-50 font-size-sm text-uppercase">Factures non validée(s)</div>
                    </div>

                    <div class="ml-auto card-hover-indicator align-self-center">
                        <fa-icon [icon]="['fas', 'chevron-right']" class="font-size-xl"></fa-icon>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>

<div class="card card-box border-0 mt-4">
    <div class="card-header p-3">
        <div class="card-header--title font-weight-bold">Liste des projets</div>
    </div>
    <div class="card-body p-3">
        <div class="table-responsive">
            <table class="table table-hover text-left text-nowrap mb-0">
                <thead>
                    <tr>
                        <th>Libellé</th>
                        <th>Date début</th>
                        <th>Date fin</th>
                        <th>Membre</th>
                        <th>Evolution</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><span class="fant-weight-bold">Construction</span></td>
                        <td><span class="fant-weight-bold text-dark">10/10/2021</span></td>
                        <td><span class="fant-weight-bold text-dark">10/10/2021</span></td>
                        <td class="text-center">
                            <div class="avatar-wrapper-overlap d-flex justify-content-center">
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar3.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar6.jpg"/></div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ngx-gauge
                                backgroundColor="rgba(248, 50, 69, 0.15)"
                                cap="round" class="mt-1 img-fluid"
                                foregroundColor="#f83245"
                                max="100" min="0" size="55"
                                thick="4"
                                type="full" value="34">
                            </ngx-gauge>
                        </td>
                        <td>
                            <button  placement="auto" tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-list-box"></i></span>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td><span class="fant-weight-bold">Construction</span></td>
                        <td><span class="fant-weight-bold text-dark">10/10/2021</span></td>
                        <td><span class="fant-weight-bold text-dark">10/10/2021</span></td>
                        <td class="text-center">
                            <div class="avatar-wrapper-overlap d-flex justify-content-center">
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar3.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar6.jpg"/></div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ngx-gauge
                                backgroundColor="rgba(248, 50, 69, 0.15)"
                                cap="round" class="mt-1 img-fluid"
                                foregroundColor="#f83245"
                                max="100" min="0" size="55"
                                thick="4"
                                type="full" value="34">
                            </ngx-gauge>
                        </td>
                        <td>
                            <button  placement="auto" tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-list-box"></i></span>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td><span class="fant-weight-bold">Construction</span></td>
                        <td><span class="fant-weight-bold text-dark">10/10/2021</span></td>
                        <td><span class="fant-weight-bold text-dark">10/10/2021</span></td>
                        <td class="text-center">
                            <div class="avatar-wrapper-overlap d-flex justify-content-center">
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar3.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                                </div>
                                <div class="avatar-icon-wrapper avatar-icon-sm">
                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar6.jpg"/></div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ngx-gauge
                                backgroundColor="rgba(248, 50, 69, 0.15)"
                                cap="round" class="mt-1 img-fluid"
                                foregroundColor="#f83245"
                                max="100" min="0" size="55"
                                thick="4"
                                type="full" value="34">
                            </ngx-gauge>
                        </td>
                        <td>
                            <button  placement="auto" tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-list-box"></i></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
