import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule, BsDatepickerModule, BsDropdownModule, PaginationModule, ProgressbarModule, TooltipModule } from 'ngx-bootstrap';
import { CountUpModule } from 'countup.js-angular2';
import { NgxGaugeModule } from 'ngx-gauge';
import { TravauxPagesComponent } from './travaux-pages.component';
import { TravauxDetailComponent } from './travaux-detail/travaux-detail.component';
import { TravauxEditComponent } from './travaux-edit/travaux-edit.component';
import { TravauxListComponent } from './travaux-list/travaux-list.component';
import { TravauxPagesRoutingModule } from './travaux-pages-routing.module';
import { TachePagesModule } from '../../tache-pages/tache-pages.module';


@NgModule({
  declarations: [
    TravauxPagesComponent,
    TravauxDetailComponent,
    TravauxEditComponent,
    TravauxListComponent
  ],
  imports: [
    CommonModule,
    TravauxPagesRoutingModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    ArchwizardModule,
    NgSelectModule,
    BsDatepickerModule,
    TooltipModule,
    BsDropdownModule,
    PaginationModule,
    ProgressbarModule,
    AccordionModule,
    TachePagesModule,
    CountUpModule,
    NgxGaugeModule,
  ],
  exports:[
    TravauxDetailComponent,
    TravauxEditComponent,
    TravauxListComponent
  ]
})
export class TravauxPagesModule { }
