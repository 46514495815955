<div class="scrollmenu">
    <div class="card card-box bg-secondary" style="min-height: 400px;">
        <div class="card-header mb-2 bg-secondary ">
            <h5 class="mb- font-weight-bold">Nouveau</h5>
        </div>
        <div class="card-body p-0 pb-3 pt-2 ">
            <perfect-scrollbar class="scroll-area-xs shadow-overflow" [autoPropagation]="true">
                <div class="col-xl-12 p-0 pr-1 pl-1" dragula="VAMPIRES" [(dragulaModel)]="vamps" *ngFor="let vamp of vamps">
                    <a [routerLink]="['/pages/opportunite/detail/5']" class="card card-box-alt mb-2  mt-1  card-border-top border-first">
                        <div class="card-badges">
                            <span class="badge badge-pill badge-neutral-info text-success shadow-none">HAUT</span>
                        </div>
                        <div class="card-body pb-1">
                            <div class="align-box-row">
                                <div class="text-left w-100">
                                    <div class="mt-1">
                                        <small class="d-flex pt-2 align-items-center">
                                            <div class="avatar-icon-wrapper avatar-icon-xs mr-2">
                                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/>
                                                </div>
                                            </div>
                                            <span>Remi joel</span>
                                        </small>
                                    </div>
                                    <div class="mt-2 mb-2 line-height-sm">
                                        <b class="font-size-md pr-1">Construction villa R+5</b>
                                    </div>
                                    <div class="mt-1">
                                        <progressbar type="first" [value]="65" class="mb-4 progress-bar-rounded">65%</progressbar>
                                    </div>
                                    <div class="mt-1 d-flex justify-content-between"> 
                                        <div class="nav-link-icon text-muted">
                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                            <span class="text-info"> Jan 2021</span>
                                        </div>
                                        <div class="nav-link-icon text-muted">
                                            <i class="fa fa-comments" aria-hidden="true"></i>
                                            <span > 5</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
    
    <div class="card card-box   bg-secondary "   style="min-height: 400px;">
        <div class="card-header mb-2 bg-secondary ">
            <h5 class="mb-  font-weight-bold">Qualifiée</h5>
        </div>
        <div class="card-body p-0 pb-3 pt-2 ">
            <perfect-scrollbar class="scroll-area-xs shadow-overflow" [autoPropagation]="true">
                <div class="col-xl-12 p-0 pr-1  pl-1  "  dragula="VAMPIRES" [(dragulaModel)]="vamps" *ngFor="let vamp of vamps">
                    <a [routerLink]="" class="card card-box-alt mb-2  mt-1  card-border-top border-info">
                        <div class="card-badges">
                            <span class="badge badge-pill badge-neutral-info text-info shadow-none">HAUT</span>
                        </div>
                        <div class="card-body pb-1">
                            <div class="align-box-row">
                                <div class="text-left w-100">
                                    <div class="mt-1">
                                        <small class="d-flex pt-2 align-items-center">
                                            <div class="avatar-icon-wrapper avatar-icon-xs mr-2">
                                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                            </div>
                                            <span>Remi joel</span>
                                        </small>
                                    </div>
                                    <div class="mt-2 mb-2 line-height-sm">
                                        <b class="font-size-md pr-1">Construction villa R+5</b>
                                    </div>
                                    <div class="mt-1">
                                        <progressbar type="first" [value]="65" class="mb-4 progress-bar-rounded">65%</progressbar>
                                    </div>
                                    <div class="mt-1 d-flex justify-content-between"> 
                                        <div class="nav-link-icon text-muted">
                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                            <span class="text-info"> Jan 2021</span>
                                        </div>
                                        <div class="nav-link-icon text-muted">
                                            <i class="fa fa-comments" aria-hidden="true"></i>
                                            <span > 5</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
    
    <div class="card card-box   bg-secondary "   style="min-height: 400px;">
        <div class="card-header mb-2 bg-secondary ">
            <h5 class="mb-  font-weight-bold">Proposition</h5>
        </div>
        <div class="card-body p-0 pb-3 pt-2 ">
            <perfect-scrollbar class="scroll-area-xs shadow-overflow" [autoPropagation]="true">
                <div class="col-xl-12 p-0 pr-1  pl-1  "  dragula="VAMPIRES" [(dragulaModel)]="vamps" *ngFor="let vamp of vamps">
                    <a [routerLink]="" class="card card-box-alt mb-2  mt-1  card-border-top border-warning">
                        <div class="card-badges">
                            <span class="badge badge-pill badge-neutral-warning text-warning shadow-none">HAUT</span>
                        </div>
                        <div class="card-body pb-1">
                            <div class="align-box-row">
                                <div class="text-left w-100">
                                    <div class="mt-1">
                                        <small class="d-flex pt-2 align-items-center">
                                            <div class="avatar-icon-wrapper avatar-icon-xs mr-2">
                                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                            </div>
                                            <span>Remi joel</span>
                                        </small>
                                    </div>
                                    <div class="mt-2 mb-2 line-height-sm">
                                        <b class="font-size-md pr-1">Construction villa R+5</b>
                                    </div>
                                    <div class="mt-1">
                                        <progressbar type="first" [value]="65" class="mb-4 progress-bar-rounded">65%</progressbar>
                                    </div>
                                    <div class="mt-1 d-flex justify-content-between"> 
                                        <div class="nav-link-icon text-muted">
                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                            <span class="text-info"> Jan 2021</span>
                                        </div>
                                        <div class="nav-link-icon text-muted">
                                            <i class="fa fa-comments" aria-hidden="true"></i>
                                            <span > 5</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
    
    <div class="card card-box  bg-secondary "   style="min-height: 400px;">
        <div class="card-header mb-2 bg-secondary ">
            <h5 class="mb-  font-weight-bold">Gagnée</h5>
        </div>
        <div class="card-body p-0 pb-3 pt-2 ">
            <perfect-scrollbar class="scroll-area-xs shadow-overflow" [autoPropagation]="true">
                <div class="col-xl-12 p-0 pr-1  pl-1  "  dragula="VAMPIRES" [(dragulaModel)]="vamps" *ngFor="let vamp of vamps">
                    <a [routerLink]="" class="card card-box-alt mb-2  mt-1  card-border-top border-success">
                        <div class="card-badges">
                            <span class="badge badge-pill badge-neutral-success text-success shadow-none">HAUT</span>
                        </div>
                        <div class="card-body pb-1">
                            <div class="align-box-row">
                                <div class="text-left w-100">
                                    <div class="mt-1">
                                        <small class="d-flex pt-2 align-items-center">
                                            <div class="avatar-icon-wrapper avatar-icon-xs mr-2">
                                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                            </div>
                                            <span>Remi joel</span>
                                        </small>
                                    </div>
                                    <div class="mt-2 mb-2 line-height-sm">
                                        <b class="font-size-md pr-1">Construction villa R+5</b>
                                    </div>
                                    <div class="mt-1">
                                        <progressbar type="first" [value]="65" class="mb-4 progress-bar-rounded">65%</progressbar>
                                    </div>
                                    <div class="mt-1 d-flex justify-content-between"> 
                                        <div class="nav-link-icon text-muted">
                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                            <span class="text-info"> Jan 2021</span>
                                        </div>
                                        <div class="nav-link-icon text-muted">
                                            <i class="fa fa-comments" aria-hidden="true"></i>
                                            <span > 5</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </perfect-scrollbar>
        </div>
    </div>

    <div class="card card-box  bg-secondary "   style="min-height: 400px;">
        <div class="card-header mb-2 bg-secondary ">
            <h5 class="mb-  font-weight-bold">Perdu</h5>
        </div>
        <div class="card-body p-0 pb-3 pt-2 ">
            <perfect-scrollbar class="scroll-area-xs shadow-overflow" [autoPropagation]="true">
                <div class="col-xl-12 p-0 pr-1  pl-1  "  dragula="VAMPIRES" [(dragulaModel)]="vamps" *ngFor="let vamp of vamps">
                    <a [routerLink]="" class="card card-box-alt mb-2  mt-1  card-border-top border-dark">
                        <div class="card-badges">
                            <span class="badge badge-pill badge-neutral-dark text-dark shadow-none">HAUT</span>
                        </div>
                        <div class="card-body pb-1">
                            <div class="align-box-row">
                                <div class="text-left w-100">
                                    <div class="mt-1">
                                            <small class="d-flex pt-2 align-items-center">
                                                <div class="avatar-icon-wrapper avatar-icon-xs mr-2">
                                                    <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                                                </div>
                                            <span>Remi joel</span>
                                        </small>
                                    </div>
                                    <div class="mt-2 mb-2 line-height-sm">
                                        <b class="font-size-md pr-1">Construction villa R+5</b>
                                    </div>
                                    <div class="mt-1">
                                        <progressbar type="first" [value]="65" class="mb-4 progress-bar-rounded">65%</progressbar>
                                    </div>
                                    <div class="mt-1 d-flex justify-content-between"> 
                                        <div class="nav-link-icon text-muted">
                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                            <span class="text-info"> Jan 2021</span>
                                        </div>
                                        <div class="nav-link-icon text-muted">
                                            <i class="fa fa-comments" aria-hidden="true"></i>
                                            <span > 5</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</div>