<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground"
    [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-wallet display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>Détails utilisateurs </h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">{{userDetails?.nom | uppercase}} {{userDetails?.prenom}}</div>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button [routerLink]="['/pages/utilisateur/liste']" class="btn btn-secondary shadow-sm"><i class="fas fa-arrow-left"></i> Retour à la liste</button>
        </div>
    </div>
</div>

<div class="row">
    <!-- SideBar bleu de gauche -->
    <div class="col-xl-4 col-lg-6">
        <div class="bg-midnight-bloom p-3 rounded text-white h-100">
            <div class="d-flex align-items-start justify-content-between">
                <div class="avatar-icon-wrapper d-100">
                    <span class="badge badge-circle badge-success">En ligne</span>
                    <div class="avatar-icon d-100">
                        <img alt="..." src="assets/images/avatars/avatar1.jpg"/>
                    </div>
                </div>
            </div>
            <div class="font-weight-bold font-size-lg d-flex align-items-center mt-2 mb-0">
                <span>{{userDetails?.nom}} {{userDetails?.prenom}}</span>
            </div>
            <p class="mb-4 font-size-md text-white-50">kouass</p>
            <a [routerLink]="" class="btn btn-sm btn-outline-light shadow-none mr-2">Envoyer un message</a>
            <a [routerLink]="" class="btn btn-sm btn-outline-light shadow-none mr-2">Suivre</a>
            <p class="text-white-50 mb-0 py-4">
                Cet utilisateur n'est ni un prospect, ni un client, ni un fournisseur
            </p>

            <div class="pt-3 pb-4">
                <div class="row no-gutters text-center">
                    <div class="col">
                        <b class="d-block font-weight-bold font-size-lg">Administrateur</b>
                        <small class="text-uppercase text-white-50">Non</small>
                    </div>
                    <div class="col">
                        <b class="d-block font-weight-bold font-size-lg">Salarier</b>
                        <small class="text-uppercase text-white-50">Oui</small>
                    </div>
                    <div class="col">
                        <b class="d-block font-weight-bold font-size-lg">Mot de passe</b>
                        <small class="text-uppercase text-white-50">Caché</small>
                    </div>
                </div>
            </div>
            <div class="font-weight-bold font-size-lg d-flex align-items-center mb-3">
                <span>Liste de groupe pour cet utilisateur</span>
                <div class="ml-auto font-size-xs">
                    <a [routerLink]="" class="text-white" tooltip="Ajouter un groupe">
                        <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
                    </a>
                </div>
            </div>
            <div class="py-2">AGENT ACHAT</div>
            
            <div class="divider opacity-2 my-4"></div>
            <div class="font-weight-bold font-size-lg d-flex align-items-center mb-3">
                <span>Membres du groupe</span>
                <div class="ml-auto font-size-xs">
                    <a [routerLink]="" class="text-white" tooltip="Ajouter un membre">
                        <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
                    </a>
                </div>
            </div>
            <div class="py-2">
                <div class="align-box-row">
                    <a [routerLink]="" class="avatar-icon-wrapper avatar-icon-md text-white">
                        <span class="badge badge-circle badge-success">En ligne</span>
                        <div class="avatar-icon rounded-circle"><img alt="..." src="assets/images/avatars/avatar1.jpg"/></div>
                    </a>
                    <div class="pl-2">
                        <span class="d-block">
                            <a [routerLink]="" class="text-white">Anicet Pro</a>
                            <small class="d-block text-white-50">(znicet@example.com)</small>
                        </span>
                    </div>
                </div>
            </div>
            <div class="py-2">
                <div class="align-box-row">
                    <a [routerLink]="" class="avatar-icon-wrapper avatar-icon-md text-white">
                        <span class="badge badge-circle badge-neutral-dark">Est partie</span>
                        <div class="avatar-icon rounded-circle"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                    </a>
                    <div class="pl-2">
                        <span class="d-block">
                            <a [routerLink]="" class="text-white">Roland Pro</a>
                            <small class="d-block text-white-50">(roland@gmail.com)</small>
                        </span>
                    </div>
                </div>
            </div>
            <div class="py-2">
                <div class="align-box-row">
                    <a [routerLink]="" class="avatar-icon-wrapper avatar-icon-md text-white">
                        <span class="badge badge-circle badge-success">En ligne</span>
                        <div class="avatar-icon rounded-circle"><img alt="..." src="assets/images/avatars/avatar3.jpg"/></div>
                    </a>
                    <div class="pl-2">
                        <span class="d-block">
                            <a [routerLink]="" class="text-white">Mamas Kader</a>
                            <small class="d-block text-white-50">(mamos@example.com)</small>
                        </span>
                    </div>
                </div>
            </div>
            <div class="py-2">
                <div class="align-box-row">
                    <a [routerLink]="" class="avatar-icon-wrapper avatar-icon-md text-white">
                        <span class="badge badge-circle badge-neutral-dark">Est partie</span>
                        <div class="avatar-icon rounded-circle"><img alt="..." src="assets/images/avatars/avatar4.jpg"/></div>
                    </a>
                    <div class="pl-2">
                        <span class="d-block">
                            <a [routerLink]="" class="text-white">John Doe</a>
                            <small class="d-block text-white-50">(Galen@example.com)</small>
                        </span>
                    </div>
                </div>
            </div>
            <div class="divider opacity-2 my-4"></div>
            <div class="font-weight-bold font-size-lg d-flex align-items-center mb-3">
                <span>Connexion</span>
            </div>
            <div class="py-2">
                <div class="d-flex justify-content-between">
                    <span class="d-block"><a [routerLink]="" class="text-white">Dernière connexion</a></span>
                    <span class="text-white-50">07/04/2020  16:54</span>
                </div>
            </div>
            <div class="py-2">
                <div class="d-flex justify-content-between">
                    <span class="d-block"><a [routerLink]="" class="text-white">Connexion précedente</a></span>
                    <span class="badge badge-dark"></span>
                </div>
            </div>
            <div class="divider opacity-2 my-4"></div>
            <div class="font-weight-bold font-size-lg d-flex align-items-center mb-3">
                <span>RH</span>
            </div>
            <div class="py-2">
                <div class="d-flex justify-content-between">
                    <span class="d-block"><a [routerLink]="" class="text-white">Demande de mission</a></span>
                    <span class="badge badge-dark">22</span>
                </div>
            </div>
            <div class="py-2">
                <div class="d-flex justify-content-between">
                    <span class="d-block"><a [routerLink]="" class="text-white">Note de frais</a></span>
                    <span class="badge badge-dark">11</span>
                </div>
            </div>
            <div class="py-2">
                <div class="d-flex justify-content-between">
                    <span class="d-block"><a [routerLink]="" class="text-white">Congés</a></span>
                    <span class="badge badge-dark">33</span>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin de la sideBar bleu de gauche -->

    <!-- Conteneur des informations -->
    <div class="col-xl-8 col-lg-6">
        <tabset tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow" type="tabs">
            <!-- Debut information -->
            <tab customClass="pl-0 pr-1 py-2">
                <ng-template tabHeading><span>Informations</span></ng-template>
                <!-- Informations sur l'utilisateur -->
                <div class="rounded card card-box bg-white mb-3 mt-4 p-4">
                    <div class="border p-2">
                        <div class="row">
                            <!-- ZONE DE GAUCHE -->
                            <div class="col-md-6">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Nom: </label>
                                    <div class="col-md-6">{{userDetails?.nom}}</div>
                                </div><hr class="mt-1 mb-1">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Prenom: </label>
                                    <div class="col-md-6">{{userDetails?.prenom}}</div>
                                </div><hr class="mt-1 mb-1">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Genre: </label>
                                    <div *ngIf="userDetails?.genre == true" class="col-md-6">Masculin</div>
                                    <div *ngIf="userDetails?.genre == false" class="col-md-6">Féminin</div>
                                </div><hr class="mt-1 mb-1">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Date de naissance: </label>
                                    <div class="col-md-6">{{userDetails?.dateNaissance | date: 'dd/MM/yyyy'}}</div>
                                </div><hr class="mt-1 mb-1">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Pays: </label>
                                    <div class="col-md-6">{{userDetails?.pays}}</div>
                                </div><hr class="mt-1 mb-1">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Ville: </label>
                                    <div class="col-md-6">{{userDetails?.ville}}</div>
                                </div><hr class="mt-1 mb-1">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Adresse email: </label>
                                    <div class="col-md-6">{{userDetails?.email}}</div>
                                </div><hr class="mt-1 mb-1">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Contact 1: </label>
                                    <div class="col-md-6">{{userDetails?.contact1}}</div>
                                </div><hr class="mt-1 mb-1">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Contact 2: </label>
                                    <div class="col-md-6">{{userDetails?.contact2}}</div>
                                </div><hr class="mt-1 mb-1">
                            </div>
    
                            <!-- LA ZONE DE DROITE -->
                            <div class="col-md-6">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Nom d'utilisateur: </label>
                                    <div class="col-md-6">{{userDetails?.username}}</div>
                                </div><hr class="mt-1 mb-1">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Type d'utilisateur: </label>
                                    <div *ngIf="userDetails?.type == 0" class="col-md-6">Membre de l'entréprise</div>
                                    <div *ngIf="userDetails?.type == 1" class="col-md-6">Délégué de bureau d'étude</div>
                                    <div *ngIf="userDetails?.type == 2" class="col-md-6">Délégué fournisseur</div>
                                </div><hr class="mt-1 mb-1">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Tiers: </label>
                                    <div class="col-md-6">{{userDetails?.tiers.libelle}}</div>
                                </div><hr class="mt-1 mb-1">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Service: </label>
                                    <div class="col-md-6">{{userDetails?.service.libelle}}</div>
                                </div><hr class="mt-1 mb-1">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Supérieur hierachique: </label>
                                    <div class="col-md-6">{{userDetails?.superieurHierarchique}}</div>
                                </div><hr class="mt-1 mb-1">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Adresse geographique: </label>
                                    <div class="col-md-6">{{userDetails?.localisation}}</div>
                                </div><hr class="mt-1 mb-1">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Bureau d'étude: </label>
                                    <div class="col-md-6">{{userDetails?.bureauEtude.libelle}}</div>
                                </div><hr class="mt-1 mb-1">
                                <div class="form-group row mb-2 mt-3">
                                    <label for="" class="col-md-6 font-weight-bolder">Fonction occupée: </label>
                                    <div class="col-md-6">{{userDetails?.fonction}}</div>
                                </div><hr class="mt-1 mb-1">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin des informations sur l'utilisateur -->

                <!-- Information complementaires -->
                <div class="rounded card card-box bg-white mb-3 mt-4">
                    <div class="card-header pl-4 pt-2 pb-2"><b>Informations complementaires</b></div>
                    <div class="card-body p-4">
                        <div class="border p-2">
                            <div class="row">
                                <!-- ZONE DE GAUCHE -->
                                <div class="col-md-6">
                                    <div class="form-group row mb-2">
                                        <label for="" class="col-md-6 font-weight-bolder">Responsable hierachique: </label>
                                        <div class="col-md-6">Reponse</div>
                                    </div><hr class="mt-1 mb-1">
                                    <div class="form-group row mb-2 mt-3">
                                        <label for="" class="col-md-6 font-weight-bolder">Forcer le validateur des notes de frais: </label>
                                        <div class="col-md-6">Reponse</div>
                                    </div><hr class="mt-1 mb-1">
                                    <div class="form-group row mb-2 mt-3">
                                        <label for="" class="col-md-6 font-weight-bolder">Tarif moyen horaire: </label>
                                        <div class="col-md-6">Reponse</div>
                                    </div><hr class="mt-1 mb-1">
                                    <div class="form-group row mb-2 mt-3">
                                        <label for="" class="col-md-6 font-weight-bolder">Tarif journalier horaire: </label>
                                        <div class="col-md-6">Reponse</div>
                                    </div><hr class="mt-1 mb-1">
                                    <div class="form-group row mb-2 mt-3">
                                        <label for="" class="col-md-6 font-weight-bolder">Heure de travail (par semaine): </label>
                                        <div class="col-md-6">Reponse</div>
                                    </div><hr class="mt-1 mb-1">
                                </div>
        
                                <!-- LA ZONE DE DROITE -->
                                <div class="col-md-6">
                                    <div class="form-group row mb-2">
                                        <label for="" class="col-md-6 font-weight-bolder">Date d'embauche: </label>
                                        <div class="col-md-6">Reponse</div>
                                    </div><hr class="mt-1 mb-1">
                                    <div class="form-group row mb-2 mt-3">
                                        <label for="" class="col-md-6 font-weight-bolder">Forcer le validateur des congés: </label>
                                        <div class="col-md-6">Reponse</div>
                                    </div><hr class="mt-1 mb-1">
                                    <div class="form-group row mb-2 mt-3">
                                        <label for="" class="col-md-6 font-weight-bolder">Compte comptable: </label>
                                        <div class="col-md-6">Reponse</div>
                                    </div><hr class="mt-1 mb-1">
                                    <div class="form-group row mb-2 mt-3">
                                        <label for="" class="col-md-6 font-weight-bolder">Salaire: </label>
                                        <div class="col-md-6">Reponse</div>
                                    </div><hr class="mt-1 mb-1">
                                    <div class="form-group row mb-2 mt-3">
                                        <label for="" class="col-md-6 font-weight-bolder">Signature: </label>
                                        <div class="col-md-6">Reponse</div>
                                    </div><hr class="mt-1 mb-1">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Fin des informations complementaires -->

                <div class="row mt-4 mb-3">
                    <!-- Derniers fichiers joints -->
                    <div class="col-md-12 mb-3">
                        <div class="rounded card card-box bg-white">
                            <div class="card-header pl-4 pt-2 pb-2"><b>Derniers Fichiers joints</b></div>
                            <div class="card-body pl-4 pt-2 pb-2">
                                <table class="table table-sm">
                                    <thead>
                                        <th>Fichiers</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Aucun</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- fin derniers fichiers joints -->
                    
                    <!-- Les derniers évéments liés -->
                    <div class="col-md-12 mb-3">
                        <div class="rounded card card-box bg-white">
                            <div class="card-header pl-4 pt-2 pb-2"><b>Les derniers évéments liés</b></div>
                            <div class="card-body pl-4 pt-2 pb-2">
                                <table class="table table-sm">
                                    <thead>
                                        <th>Référence</th>
                                        <th>Par</th>
                                        <th>Type</th>
                                        <th>Titre</th>
                                        <th>Date</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Aucun</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- Fin des derniers évéments liés -->

                    <!-- Objets liés -->
                    <div class="col-md-12 mb-3">
                        <div class="rounded card card-box bg-white">
                            <div class="card-header pl-4 pt-2 pb-2"><b>Objets liés</b></div>
                            <div class="card-body pl-4 pt-2 pb-2">
                                <table class="table table-sm">
                                    <thead>
                                        <th>Type</th>
                                        <th>Référence</th>
                                        <th>Date</th>
                                        <th>Montant HT</th>
                                        <th>Etat</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Aucun</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- Fin objets liés -->
                </div>

                <div class="row">
                    <div class="col-md-12 text-right">
                        <button class="btn btn-info"><i class="fas fa-pen"></i> Modifier</button>
                        <button class="btn btn-primary ml-2">Régénérer mot de passe</button>
                        <button class="btn btn-primary ml-2">Régénérer et envoyer mot de passe</button>
                        <button class="btn btn-primary ml-2">Desactiver</button>
                        <button class="btn btn-danger ml-2"><i class="fas fa-trash"></i> Supprimer</button>
                    </div>
                </div>
            </tab>
            <!-- Fin information -->

            <!-- Fichiers joints -->
            <tab customClass="pl-0 pr-1 py-2">
                <ng-template tabHeading><span>Fichiers joints</span></ng-template>
                <div class="rounded card card-box bg-white mb-3 mt-4 p-4">
                    <div class="informations">
                        <div class="divider mb-2"></div>
                        <div class="form-group row">
                            <div class="col-md-6"> Code utilisateur</div>
                            <div class="col-md-6"> USER1234567</div>
                        </div>
                        <div class="divider mb-2 mt-2"></div>
                        <div class="form-group row">
                            <div class="col-md-6"> Nombre de fichiers ou documents liés</div>
                            <div class="col-md-6"> 23</div>
                        </div>
                        <div class="divider mb-2 mt-2"></div>
                        <div class="form-group row">
                            <div class="col-md-6"> Taille totale de fichiers ou documents liés</div>
                            <div class="col-md-6"> 5MB</div>
                        </div>
                        <div class="divider mb-2 mt-2"></div>
                    </div>

                    <div class="ajout mt-4 mb-4">
                        <span class="titre">Ajouter un nouveau ou document</span>
                        <div class="form-group row mb-4 mt-3">
                            <div class="col-md-12">
                                <div class="custom-file">
                                    <input class="custom-file-input" id="ajoutDoc" name="ajoutDoc" ng2FileSelect type="file">
                                    <label class="custom-file-label" for="execution">Format prise en compte: PDF, jpeg, jpg, DOC, xls</label>
                                </div>
                            </div>
                            <div class="col-md-12 mt-2 text-right">
                                <button class="btn btn-sm btn-primary">Envoyer</button>
                            </div>
                        </div>
                        <div class="divider mb-2 mt-2"></div>

                        <span class="mb-3 mt-4  mb-4 titre">Lier un nouveau ou document</span>
                        <div class="form-group row mb-4 mt-3">
                            <div class="col-md-6">
                                <label for="url">URL à lier</label>
                                <input type="text" class="form-control" placeholder="Entrer l'URL à lier">
                            </div>
                            <div class="col-md-6">
                                <label for="url">Libellé</label>
                                <input type="text" class="form-control" placeholder="Entrer le libellé du fichier ou document à lier">
                            </div>
                            <div class="col-md-12 text-right mt-2">
                                <button class="btn btn-sm btn-primary">Lier</button>
                            </div>
                        </div>
                        <div class="divider mb-2 mt-2"></div>
                    </div>

                    <!-- ZONE DES TABLEAUX -->
                    <div class="table">
                        <span class="titre">Fichiers et documents joints</span>
                        <div class="table-responsive mb-4 mt-3">
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Documents</th>
                                        <th>Taille</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Aucun document</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <span class="titre">Fichiers et documents liés</span>
                        <div class="table-responsive mt-3">
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Lens</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Aucun document</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </tab>
            <!-- Fin fichiers joints -->

            <!-- Notification -->
            <tab customClass="pl-0 pr-1 py-2">
                <ng-template tabHeading>
                    <span>Notifications </span>
                    <span class="ml-2 badge badge-success"> 625</span>
                </ng-template>
                <div class="rounded card card-box bg-white mb-3 mt-4 p-4">
                    <div class="informations">
                        <div class="divider mb-2"></div>
                        <div class="form-group row">
                            <div class="col-md-4"> Code utilisateur</div>
                            <div class="col-md-8"> USER1234567</div>
                        </div>
                        <div class="divider mb-2 mt-2"></div>
                    </div>

                    <!-- ZONE DES TABLEAUX -->
                    <div class="table">
                        <span class="titre">Souscrire à une nouvelle notification automatique</span>
                        <div class="table-responsive mb-4 mt-3">
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Cible</th>
                                        <th>Action</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Aucun document</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <span class="titre">Liste de toute les souscriptions (cibles/événemet)pour des notifications email automatique</span>
                        <div class="table-responsive mt-3">
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Cible</th>
                                        <th>Action</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Aucun document</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <span class="titre">Liste de toute les notifications automatique envoyées par e-mail (2)</span>
                        <div class="table-responsive mb-4 mt-3">
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Cible</th>
                                        <th>Action</th>
                                        <th>Type</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>abcd@gmai.com</td>
                                        <td>construction</td>
                                        <td>Email</td>
                                        <td>12/04/2021 13:48</td>
                                    </tr>
                                    <tr>
                                        <td>abcd@gmai.com</td>
                                        <td>construction</td>
                                        <td>Email</td>
                                        <td>12/04/2021 13:48</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </tab>
            <!-- Fin notification -->

            <!-- Permissions -->
            <tab customClass="pl-0 pr-1 py-2">
                <ng-template tabHeading>
                    <span>Permissions </span>
                    <span class="ml-2 badge badge-success"> 12</span>
                </ng-template>

                <div class="rounded card card-box bg-white mb-3 mt-4 p-4">
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur doloremque vitae voluptatem quos inventore ducimus perferendis repudiandae dolore,
                        magni sit numquam assumenda eius porro nihil laboriosam? Ut animi necessitatibus soluta.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur doloremque vitae voluptatem quos inventore ducimus perferendis repudiandae dolore,
                        magni sit numquam assumenda eius porro nihil laboriosam? Ut animi necessitatibus soluta.
                    </p>
                </div>
            </tab>
            <!-- Fin permissions -->
        </tabset>
    </div>
    <!-- Fin conteneur des informations -->
</div>
<div class="sidebar-inner-layout-overlay"></div>