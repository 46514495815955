import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BudgetDashboardComponent } from './budget-dashboard/budget-dashboard.component';
import { BudgetDetailComponent } from './budget-detail/budget-detail.component';
import { BudgetListComponent } from './budget-list/budget-list.component';
import { BudgetPagesComponent } from './budget-pages.component';


const routes: Routes = [
  {
    path:'',
    component: BudgetPagesComponent,
    children: [
      {
        path: "dashboard",
        component: BudgetDashboardComponent
      },
      {
        path: "liste",
        component: BudgetListComponent
      },
      {
        path: "detail/:id",
        component: BudgetDetailComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BudgetPagesRoutingModule { }
