import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-utilisateur-pages',
  templateUrl: './utilisateur-pages.component.html',
  styleUrls: ['./utilisateur-pages.component.scss']
})
export class UtilisateurPagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
