import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';
import { tileLayer, latLng } from 'leaflet';

@Component({
  selector: 'app-projet-dashboard',
  templateUrl: './projet-dashboard.component.html',
  styleUrls: ['./projet-dashboard.component.scss']
})
export class ProjetDashboardComponent implements OnInit {

  constructor(public globals: ThemeOptions) { }

  ngOnInit() {
  }

  // Carte de localisation
  options = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OpenStreetMap contributors'
      })
    ],
    zoom: 7,
    center: latLng([ 46.879966, -121.726909 ])
  };

}
