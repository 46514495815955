<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground"
    [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-add-user display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>Gestion des fournisseurs </h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">Liste des fournisseurs</div>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button (click)="addFournisseur('', false)" class="btn btn-success" tooltip="Créer un nouveau fournisseur" type="button"><i class="fas fa-plus-circle mr-2"></i>Ajouter</button>
        </div>
    </div>
</div>

<tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow" type="tabs">
    <!-- Debut fournisseurs en activité -->
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span>Fournisseurs</span></ng-template>
        <div class="card card-box border-0">
            <div class="card-header p-3">
                <div class="card-header--title font-weight-bold">Liste des fournisseurs</div>
                <div class="card-header--actions">
                    <div class="btn-group" dropdown>
                        <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                            dropdownToggle
                            tooltip="Action"
                            type="button">
                            <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                        </button>
                        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                            <ul class="nav nav-pills flex-column p-3">
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-3">
                <div class="d-flex justify-content-between mb-3">
                    <div class="search-wrapper">
                      <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                      <input class="form-control" placeholder="Recherche dans la liste" type="search">
                    </div>
                </div>
                <div class="table-responsive m-0">
                    <table class="table table-hover text-nowrap m-0">
                        <thead>
                            <tr>
                                <th>Fournisseur</th>
                                <th>Corps de metier</th>
                                <th>pays</th>
                                <th>téléphone</th>
                                <th>email</th>
                                <th>status</th>
                                <th class="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let fournisseur of fournisseurs">
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar-icon-wrapper">
                                            <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                        </div>
                                        <div>
                                            <a [routerLink]="" class="font-weight-bold text-black" title="{{fournisseur?.nom}}">{{fournisseur?.nom}}</a>
                                            <span class="d-block text-{{fournisseur?.type == 'Fournisseur' ? 'first' : 'warning'}}">{{fournisseur?.type}}</span>
                                        </div>
                                    </div>
                                </td>
                                <td>{{fournisseur?.corpsMetier}}</td>
                                <td>{{fournisseur?.pays}}</td>
                                <td>{{fournisseur?.contact}}</td>
                                <td>{{fournisseur?.email}}</td>
                                <td>
                                    <span class="badge bg-{{fournisseur?.status == 'actif' ? 'neutral-first text-first' : 'neutral-dark text-dark'}} px-4">
                                        {{fournisseur?.status}}
                                    </span>
                                </td>
                                <td class="text-center">
                                    <button placement="auto" [routerLink]="['/pages/fournisseur/detail',fournisseur?.id]" tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill mr-1 btn-primary" type="button">
                                        <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                    </button>
                                    <button placement="auto" tooltip="Modifier"  class="btn d-40 p-0 btn-pill mr-1 btn-info" type="button">
                                        <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                                    </button>
                                    <button  placement="auto"  tooltip="Supprimer"  class="btn d-40 p-0 btn-pill btn-danger" type="button">
                                        <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer p-3">
                <div class="d-flex justify-content-between">
                    <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                    <div class="d-flex align-items-center">
                        <span>Show</span>
                        <select class="mx-1 form-control form-control-sm" id="" name="">
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="40">50</option>
                            <option value="40">100</option>
                        </select>
                        <span>entries</span>
                    </div>
                </div>
            </div>
        </div>
    </tab>
    <!-- Fin fournisseurs en activité -->

    <!-- Debut démande de prestation -->
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span>Démande de prestation</span></ng-template>
        <div class="rounded card card-box bg-white mb-3 mt-4">
            <div class="card-header d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <span>Show</span>
                  <select class="mx-1 form-control form-control-sm" id="" name="">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                  </select>
                  <span>entries</span>
                </div>
                <div class="button text-center">
                    <button class="btn btn-light border"><i class="fas fa-copy"></i> Copy</button>
                    <button class="btn btn-light border ml-2"><i class="fas fa-print"></i> Print</button>
                    <button class="btn btn-light border ml-2"><i class="fas fa-file-excel"></i> Excel</button>
                    <button class="btn btn-light border ml-2"><i class="fas fa-file-csv"></i> CSV</button>
                </div>
                <div class="search-wrapper text-right">
                    <span class="icon-wrapper text-black">
                        <fa-icon [icon]="['fas', 'search']"></fa-icon>
                    </span>
                    <input class="form-control form-control-sm rounded-pill" placeholder="Search terms..." type="search">
                </div>
            </div>
            <div class="card-body p-3">
                <div class="border">

                    <!-- Tableau -->
                    <div class="table-responsive">
                        <table class="table table-hover text-nowrap mb-0">
                          <thead>
                              <tr>
                                  <th class="bg-white text-left">photo</th>
                                  <th class="bg-white text-left">dénomination sociale</th>
                                  <th class="bg-white text-left">type</th>
                                  <th class="bg-white text-left">pays</th>
                                  <th class="bg-white text-left">téléphone</th>
                                  <th class="bg-white text-left">status</th>
                                  <th class="bg-white text-center">Actions</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td class="font-weight-bold"><img src="../../../assets/images/avatars/avatar3.jpg" alt=""></td>
                                  <td class="font-weight-bold">Kouassi ghislain</td>
                                  <td class="font-weight-bold">Particulier</td>
                                  <td class="font-weight-bold">Cote d'ivoire</td>
                                  <td class="font-weight-bold">0788577695</td>
                                  <td class="font-weight-bold">
                                      <span class="badge badge-neutral-success text-success">valider</span>
                                  </td>
                                  <td class="text-center">
                                      <button placement="auto" tooltip="Accepter"  class="btn d-40 p-0 btn-pill m-2 btn-success" type="button">
                                          <span class="btn-wrapper--icon"><i class="far fa-check-circle"></i></span>
                                      </button>
                          
                                      <button  placement="auto" [routerLink]="['/pages/fournisseur/detail/5']" tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                                          <span class="btn-wrapper--icon"><i class="ion-md-list-box"></i></span>
                                      </button>
                                  </td>
                              </tr>
          
                              <tr>
                                  <td class="font-weight-bold"><img src="../../../assets/images/avatars/avatar2.jpg" alt=""></td>
                                  <td class="font-weight-bold">Kader mondesir</td>
                                  <td class="font-weight-bold">Entreprise</td>
                                  <td class="font-weight-bold">Cote d'ivoire</td>
                                  <td class="font-weight-bold">0759778877</td>
                                  <td class="font-weight-bold">
                                      <span class="badge badge-neutral-danger text-danger">validatio en attente</span>
                                  </td>
                                  <td class="text-center">
                                    <button placement="auto" tooltip="Accepter"  class="btn d-40 p-0 btn-pill m-2 btn-success" type="button">
                                        <span class="btn-wrapper--icon"><i class="far fa-check-circle"></i></span>
                                    </button>
                        
                                    <button  placement="auto" [routerLink]="['/pages/fournisseur/detail/5']" tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                                        <span class="btn-wrapper--icon"><i class="ion-md-list-box"></i></span>
                                    </button>
                                  </td>
                              </tr>
                          </tbody>
                        </table>
                    </div>
                    <!-- Fin du tableau -->
                </div>
            </div>
            <div class="card-footer py-3 d-flex justify-content-between">
              <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-second"></pagination>
              <div class="d-flex align-items-center">
                <span>Show</span>
                <select class="mx-1 form-control form-control-sm" id="" name="">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                </select>
                <span>entries</span>
              </div>
            </div>
        </div>
    </tab>
    <!-- démande de prestation -->
</tabset>
  
<!--MODAL AJOUT DE FOURNISSEUR-->
<!-- <div #newFournisseurModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
              <h6 class="modal-title">Formulaire de création d'un nouveau fournisseur</h6>
              <button type="button" class="close" aria-label="Close" (click)="newFournisseurModal.hide()">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-0">
                <app-fournisseur-edit></app-fournisseur-edit>
            </div>
        </div>
    </div>
</div> -->
<notifier-container></notifier-container>
<!--FIN MODAL AJOUT DE FOURNISSEUR-->