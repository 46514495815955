<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-wallet display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>Gestion des opportunites</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">Liste des opportunités</div>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button (click)="addOpportunite('', false)" class="btn btn-success ml-2" tooltip="Créer une opportunité" type="button"><i class="fas fa-plus-circle"></i> Ajouter</button>
        </div>
    </div>
</div>

<div class="row mb-3">
    <div class="col">
        <div class="bg-secondary border p-3 text-center rounded shadow-sm">
            <div>
                <fa-icon [icon]="['far', 'folder-open']" class="font-size-xxl text-first"></fa-icon>
            </div>
            <div class="mt-2 line-height-sm">
                <b class="font-size-lg">12</b>
                <span class="text-black-50 d-block">Nouveaux</span>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="bg-secondary border p-3 text-center rounded shadow-sm">
            <div>
                <fa-icon [icon]="['fas', 'fa-file-signature']" class="font-size-xxl text-dark-50 text-dark-50"></fa-icon>
                <i class="fas fa-file-signature font-size-xxl text-info"></i>
            </div>
            <div class="mt-2 line-height-sm">
                <b class="font-size-lg">12</b>
                <span class="text-black-50 d-block">Qualifiée</span>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="bg-secondary border p-3 text-center rounded shadow-sm">
            <div>
                <fa-icon [icon]="['far', 'chart-bar']" class="font-size-xxl text-warning"></fa-icon>
            </div>
            <div class="mt-2 line-height-sm">
                <b class="font-size-lg">12</b>
                <span class="text-black-50 d-block">Proposition</span>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="bg-secondary border p-3 text-center rounded shadow-sm">
            <div>
                <i class="ion-md-done-all font-size-xxl text-success"></i>
            </div>
            <div class="mt-2 line-height-sm">
                <b class="font-size-lg">12</b>
                <span class="text-black-50 d-block">Gagnée</span>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="bg-secondary border p-3 text-center rounded shadow-sm">
            <div>
                <fa-icon [icon]="['fas', 'times']" class="font-size-xxl text-dark"></fa-icon>
            </div>
            <div class="mt-2 line-height-sm">
                <b class="font-size-lg">12</b>
                <span class="text-black-50 d-block">Perdue</span>
            </div>
        </div>
    </div>
</div>

<div class="d-flex justify-content-end">
    <div class="btn-group btn-group-xs m-2 mb-4 " role="group" aria-label="Basic example">
    <button (click)="onSelectList('liste')" type="button" class="btn  active btn-primary"><i class="fa fa-list" aria-hidden="true"></i></button>
    <button (click)="onSelectList('kanban')" type="button" class="btn  btn-primary"><i class="fa fa-th-large" aria-hidden="true"></i></button>
    </div>
</div>

<div class="card card-box border-0" *ngIf="typeList == 'liste'">
    <div class="card-header p-3">
        <div class="card-header--title font-weight-bold">Liste des opportunités</div>
        <div class="card-header--actions">
            <div class="btn-group" dropdown>
                <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                    dropdownToggle
                    tooltip="Action"
                    type="button">
                    <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                </button>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                    <ul class="nav nav-pills flex-column p-3">
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-3">
        <div class="d-flex justify-content-between mb-3">
            <div class="search-wrapper">
                <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                <input class="form-control" placeholder="Recherche dans la liste" type="search">
            </div>
        </div>
        <div class="table-responsive m-0">
            <table class="table table-hover text-nowrap m-0">
                <thead>
                    <tr>
                        <th>Libelle</th>
                        <th>N°Ordre</th>
                        <th>N°offre</th>
                        <th>Montant</th>
                        <th>Date limite</th>
                        <th>Date lancement</th>
                        <th>Date d'ouverture plis</th>
                        <th>Maître d'ouvrage</th>
                        <th class="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let opportunite of opportunites">
                        <td><span>{{ opportunite?.libelle}}</span></td>
                        <td>
                            <span class="text-muted">{{opportunite?.numeroOrdre}}</span>
                        </td>
                        <td>
                            <span class="badge badge-neutral-secondaty">{{ opportunite?.numeroDossier }}</span>
                        </td>
                        <td>
                            <span class="badge badge-neutral-warning">{{ opportunite?.montant | number:'': 'fr-FR' }}</span>
                        </td>
                        <td>
                            <span class="badge badge-secondary">{{ opportunite?.dateLimite | date }}</span>
                        </td>
                        <td>
                            <span class="badge badge-secondary">{{opportunite?.dateLancement | date }}</span>
                        </td>
                        <td>
                            <span class="badge badge-secondary">{{ opportunite?.dateOuverture | date }}</span>
                        </td>
                        <td>
                            <span class="badge badge-secondary">{{opportunite?.maitreOuvrage}}</span>
                        </td>
                        <td class="text-center">
                            <button placement="auto"
                                (click)="showOpportunite(opportunite)"
                                tooltip="Plus d'infos" 
                                class="btn d-40 p-0 btn-pill mr-1 btn-primary" 
                                type="button">
                                <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                            </button>
                            <button placement="auto" tooltip="Modifier" class="btn d-40 p-0 btn-pill mr-1 btn-info" type="button" (click)="addOpportunite(opportunite, true)">
                                <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                            </button>
                            <button  placement="auto"  tooltip="Supprimer"  class="btn d-40 p-0 btn-pill btn-danger" type="button" (click)="deleteOpportunite(opportunite.id)">
                                <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th colspan="3">Total:</th>
                        <td colspan="9">
                            <span class="text-bold text-warning">{{ total }}</span>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div class="card-footer p-3">
        <div class="d-flex justify-content-between">
            <pagination 
                (pageChange)="currentPage = $event" id="module-appli"
                [rotate]="rotate"
                responsive="true"
                previousLabel="Précédent"
                nextLabel="Suivant"
                class="pagination-icons pagination-primary">
            </pagination>
            <div class="d-flex align-items-center">
                <span>Show</span>
                <select class="mx-1 form-control form-control-sm" id="" name="">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="40">50</option>
                    <option value="40">100</option>
                </select>
                <span>entries</span>
            </div>
        </div>
    </div>
</div>

<div *ngIf="typeList == 'kanban'">
<app-opportunite-kanban></app-opportunite-kanban>
</div>