<div [ngClass]="{
  'app-footer--shadow' : globals.footerShadow,
  'app-footer--opacity-bg' : globals.footerTransparentBg
}" class="app-footer text-black-50">
    <div class="app-footer--first">
        <ul class="nav">
            <li class="nav-item">
                <a class="nav-link" routerLink="/dashboard-analytics">Analytics</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/forms-wizard">Wizards</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/dashboard-crm-manager">CRM Manager</a>
            </li>
        </ul>
    </div>
    <div class="app-footer--second">
        <span>Application de gestion de projet BTP</span> © 2021 - Dévéloppé avec <span class="text-danger px-1">❤</span> par <a href="Lien vers archipro" target="_blank" title="Archipro.com">Archipro</a>
    </div>
</div>
