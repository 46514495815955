import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LeftSidebarComponent } from './layout-blueprints/left-sidebar/left-sidebar.component';
import { MinimalLayoutComponent } from './layout-blueprints/minimal-layout/minimal-layout.component';
import { AuthPagesComponent } from './pages/auth-pages/auth-pages.component';
import { AuthGuardGuard } from './pages/auth-pages/auth-guard.guard';

const routes: Routes = [
  {
    path: 'pages',
    component: LeftSidebarComponent,
    canActivate: [AuthGuardGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(module => module.PagesModule)
      },
    ]
  },
  {
    path: 'auth',
    component: MinimalLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/auth-pages/auth-pages.module').then(module => module.AuthPagesModule)
      },
    ]
  },
  { path: '**', redirectTo: 'pages' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
