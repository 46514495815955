import { Component, Input, OnInit } from '@angular/core';
import { SituationHse } from 'src/app/models/situation-hse';
import { SituationHseService } from 'src/app/services/situation-hse/situation-hse.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-situation-hse-list',
  templateUrl: './situation-hse-list.component.html',
  styleUrls: ['./situation-hse-list.component.scss']
})
export class SituationHseListComponent implements OnInit {
  @Input() listView: boolean = false;
  situations: SituationHse[];
  rotate = true;
  errMsg = "";
  
  constructor(
    public globals: ThemeOptions,
    private situationService: SituationHseService
  ){
    this.situationService.getList().subscribe({
      next: situation => this.situations = situation,
      error: err => this.errMsg = err
    });
  }

  ngOnInit() {
  }

}
