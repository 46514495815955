<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-umbrella display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>Gestion de projet</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">Liste des projets</div>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button (click)="addProjet('', false)" class="btn btn-success mr-2" tooltip="Créer un nouveau projet" type="button"><i class="fas fa-plus-circle"></i> Ajouter</button>
        </div>
    </div>
</div>

<div *ngIf="projets.length > 0">
    <div class="row">
        <div class="col">
            <div class="bg-secondary border p-3 text-center rounded shadow-sm mb-4" title="Nombre total des projets">
                <div>
                    <i class="fas fa-umbrella font-size-xxl mb-2 mt-1 text-first"></i>
                </div>
                <div class="mt-2 line-height-sm">
                    <b class="font-size-lg">455</b>
                    <span class="text-black-50 d-block">projet(s)</span>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="bg-secondary border p-3 text-center rounded shadow-sm mb-4" title="Nombre de projet en cours d'execution">
                <div>
                    <i class="fas fa-tools font-size-xxl mb-2 mt-1 text-info"></i>
                </div>
                <div class="mt-2 line-height-sm">
                    <b class="font-size-lg">12</b>
                    <span class="text-black-50 d-block">Projet(s) en cours</span>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="bg-secondary border p-3 text-center rounded shadow-sm mb-4" title="Nombre de projet en attente d'execution">
                <div>
                    <i class="far fa-stop-circle font-size-xxl mb-2 mt-1 text-warning"></i>
                </div>
                <div class="mt-2 line-height-sm">
                    <b class="font-size-lg">12</b>
                    <span class="text-black-50 d-block">Projet(s) en attente</span>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="bg-secondary border p-3 text-center rounded shadow-sm mb-4" title="Nombre de projet terminés">
                <div>
                    <fa-icon [icon]="['far', 'check-circle']" class="font-size-xxl text-success"></fa-icon>
                </div>
                <div class="mt-2 line-height-sm">
                    <b class="font-size-lg">12</b>
                    <span class="text-black-50 d-block">Projet(s) terminer</span>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="bg-secondary border p-3 text-center rounded shadow-sm mb-4" title="Nombre de projet annulés">
                <div>
                  <fa-icon [icon]="['fas', 'times']" class="font-size-xxl text-danger"></fa-icon>
                </div>
                <div class="mt-2 line-height-sm">
                    <b class="font-size-lg">12</b>
                    <span class="text-black-50 d-block">Projet(s) annuler</span>
                </div>
            </div>
        </div>
    </div>
    
    <div class="card card-box border-0">
        <div class="card-header p-3">
            <div class="card-header--title font-weight-bold">Liste des projets</div>
            <div class="card-header--actions">
                <div class="btn-group" dropdown>
                    <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret"
                        dropdownToggle
                        tooltip="Action"
                        type="button">
                        <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                        <ul class="nav nav-pills flex-column p-3">
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-3">
            <div class="d-flex justify-content-between mb-3">
                <div class="search-wrapper">
                  <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                  <input class="form-control" placeholder="Recherche dans la liste" type="search">
                </div>
            </div>
            <div class="table-responsive m-0">
                <table class="table table-hover text-nowrap m-0">
                    <thead>
                        <tr>
                            <th class="text-left">Projet&nbsp;<i class="fas fa-sort mr-1"></i></th>
                            <th class="text-left">Chef projet&nbsp;<i class="fas fa-sort mr-1"></i></th>
                            <th class="text-center">Status&nbsp;<i class="fas fa-sort mr-1"></i></th>
                            <th class="text-center">Evolution</th>
                            <th class="text-center">Date debut&nbsp;<i class="fas fa-sort mr-1"></i></th>
                            <th class="text-center">Date fin&nbsp;<i class="fas fa-sort mr-1"></i></th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let projet of projets">
                            <td>
                                <div class="d-flex align-items-center">
                                    <div>
                                        <a [routerLink]="['/pages/projet/detail',projet?.id]" class="font-weight-bold text-black" title="{{projet?.libelle}}">{{projet?.libelle}}</a>
                                        <span class="text-black-50 d-block">{{projet?.numero}}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex align-items-center">
                                    <div class="avatar-icon-wrapper">
                                        <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar7.jpg"/></div>
                                    </div>
                                    <div>
                                        <a [routerLink]="" class="font-weight-bold text-black" title="{{projet?.chefProjet}}">{{projet?.chefProjet}}</a>
                                        <span class="text-black-50 d-block">Ingenieur batiment</span>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                <span class="badge bg-neutral-first text-first px-4">En cours</span>
                            </td>
                            <td>
                                <progressbar [value]="67" class="progress-bar-rounded" type="first">67%</progressbar>
                            </td>
                            <td class="text-center">
                                <span class="badge bg-neutral-first text-first px-4">{{projet?.dateDebut}}</span>
                            </td>
                            <td class="text-center">
                                <span class="badge bg-neutral-success text-success px-4">{{projet?.dateFin}}</span>
                            </td>
                            <td class="text-center">
                                <button placement="auto" [routerLink]="['/pages/projet/detail',projet?.id]"  tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill mr-1 btn-primary" type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                </button>
                                <button placement="auto" tooltip="Modifier"  class="btn d-40 p-0 btn-pill mr-1 btn-info" type="button">
                                    <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                                </button>
                                <button  placement="auto"  tooltip="Dupliquer"  class="btn d-40 p-0 btn-pill mr-1 btn-dark" type="button">
                                    <span class="btn-wrapper--icon"><i class="fas fa-copy"></i></span>
                                </button>
                                <button  placement="auto"  tooltip="Supprimer"  class="btn d-40 p-0 btn-pill btn-danger" type="button">
                                    <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-footer p-3">
            <div class="d-flex justify-content-between">
                <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                <div class="d-flex align-items-center">
                    <span>Show</span>
                    <select class="mx-1 form-control form-control-sm" id="" name="">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="40">50</option>
                        <option value="40">100</option>
                    </select>
                    <span>entries</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="projets.length == 0">
    <div class="hero-wrapper bg-composed-wrapper bg-white rounded">
        <div class="flex-grow-1 w-100 d-flex align-items-center">
            <div class="bg-composed-wrapper--image bg-composed-filter-rm opacity-4 rounded" style="background-image: url('assets/img/help4.jpg');"></div>
            <div class="bg-composed-wrapper--content py-5">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-xl-6">
                            <div class="d-flex align-items-center"></div>
                            <div class="text-black mt-3">
                                <h1 class="display-3 mb-3 font-weight-bold">Aucun projet disponible dans la liste</h1>
                                <p class="font-size-lg text-black-50">
                                    Cliquez sur le boutton 'Projet' pour créer et renseigner les informations du projet ou cliquez sur le boutton 'Créer un model' pour créer un model de projet. Celles-ci apparaîtront ici.
                                </p>
                                <div class="divider border-2 border-dark my-5 border-light opacity-2 rounded-circle w-25"></div>
                                <div>
                                    <a [routerLink]="" class="btn btn-lg btn-outline-success d-block d-sm-inline-block">
                                        <span class="btn-wrapper--icon"><fa-icon [icon]="['fas', 'plus-circle']"></fa-icon></span>
                                        <span class="btn-wrapper--label">Créer un  model</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 px-0 d-none d-xl-flex align-items-center">
                            <img src="assets/images/illustrations/process.svg" class="w-100 mx-auto d-block img-fluid" alt="...">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal d'ajout de projet -->
<div #newProjetModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
                <h6 class="modal-title">Formulaire de création d'un projet</h6>
                <button type="button" class="close" aria-label="Close" (click)="newProjetModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <app-projet-edit></app-projet-edit>
            </div>
        </div>
    </div>
</div>
<!-- Modal d'ajout de projet -->