import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-reserve-detail',
  templateUrl: './reserve-detail.component.html',
  styleUrls: ['./reserve-detail.component.scss']
})
export class ReserveDetailComponent implements OnInit {

  constructor(public globals: ThemeOptions) { }

  ngOnInit() {
  }

}
