import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { ModalDirective } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { UtilOptions } from 'src/app/shared-components/util/util-option';
import { CategorieFournisseur } from '../../categorie-fournisseur/categorie-fournisseur.model';
import { CategorieFournisseurService } from '../../categorie-fournisseur/categorie-fournisseur.service';
import { CorpsMetier } from '../../corps-metier/corps-metier.model';
import { CorpsMetierService } from '../../corps-metier/corps-metier.service';
import { PrestationFournisseur } from '../../prestation-fournisseur/prestation-fournisseur.model';
import { PrestationFournisseurService } from '../../prestation-fournisseur/prestation-fournisseur.service';
import { TypeProjet } from '../../type-projet/type-projet.model';
import { TypeProjetService } from '../../type-projet/type-projet.service';

@Component({
  selector: 'app-fournisseur-edit',
  templateUrl: './fournisseur-edit.component.html',
  styleUrls: ['./fournisseur-edit.component.scss']
})

export class FournisseurEditComponent implements OnInit, OnDestroy {
  @ViewChild('newCorpsMetierModal', { static: false }) public newCorpsMetierModal: ModalDirective;
  @ViewChild('newCategorieFournisseurModal', { static: false }) public newCategorieFournisseurModal: ModalDirective;
  @ViewChild('newPrestationFournisseurModal', { static: false }) public newPrestationFournisseurModal: ModalDirective;
  @ViewChild('newTypeProjetModal', { static: false }) public newTypeProjetModal: ModalDirective;
  form: FormGroup;
  fournisseurForm: FormGroup;
  generalForm: FormGroup;
  financeForm: FormGroup;
  documentsForm: FormGroup;
  projetForm: FormGroup;
  corpsMetierForm: FormGroup;
  categorieFournisseurForm: FormGroup;
  prestationFournisseurForm: FormGroup;
  typeProjetForm: FormGroup;
  type = UtilOptions.type;
  required = UtilOptions.required;
  selected: string;
  pays: string[] = UtilOptions.pays;
  inputGroup: boolean = false;
  submit: boolean = false;
  submitCorpsMetier: boolean = false;
  submitCategorieFournisseur: boolean = false;
  submitPrestationFournisseur: boolean = false;
  submitTypeProjet: boolean = false;
  public mask: Array<string | RegExp>;
  public maskPhone: Array<string | RegExp>;
  listChiffreAffaire = [];
  listProjetRealises = [];

  addCorpsMetierSubs = new Subscription;
  listCorpsMetierSubs = new Subscription;
  addCategorieFournisseurSubs = new Subscription;
  listCategorieFournisseurSubs = new Subscription;
  addPrestationFournisseurSubs = new Subscription;
  listPrestationFournisseurSubs = new Subscription;
  listCorpsMetier: CorpsMetier[] = [];
  listCategorieFournisseur: CategorieFournisseur[] = [];
  listPrestationFournisseur: PrestationFournisseur[] = [];
  addTypeProjetSubs = new Subscription;
  listTypeProjetSubs = new Subscription;
  listTypeProjet: TypeProjet[] = [];
  private notif: NotifierService;
  nbreValidChiffreAffaire = 0;
  statutChiffreAffaire: boolean = false;
  tabLigneChiffreAffaire = []
  constructor(
    private formBuilder: FormBuilder,
    public modal: NgbActiveModal,
    private corpsMetierService: CorpsMetierService,
    private categorieFournisseurService: CategorieFournisseurService,
    private prestationFournisseurService: PrestationFournisseurService,
    private typeProjetService: TypeProjetService,
    private notifier: NotifierService
  ) {
    this.notif = this.notifier;
    this.maskPhone = ['(', '+', '2', '2', '5', ')', ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
    this.newForm();
    this.intCorpsMetierForm();
    this.intCategorieFournisseurorm();
    this.intPrestationFournisseurForm();
    this.intTypeProjetForm();
  }

  ngOnInit() {
    this.loadCorpsMetier();
    this.loadCategorieFournisseur();
    this.loadPrestationFournisseur();
   this.loadTypeProjet();
  }
  ngOnDestroy(): void {
    this.listCorpsMetierSubs.unsubscribe();
    this.addCorpsMetierSubs.unsubscribe();
    this.listCategorieFournisseurSubs.unsubscribe();
    this.addCategorieFournisseurSubs.unsubscribe();
    this.listPrestationFournisseurSubs.unsubscribe();
    this.addPrestationFournisseurSubs.unsubscribe();
  }

  intCorpsMetierForm() {
    this.corpsMetierForm = this.formBuilder.group({
      libelleCorpsMetier: [null, [Validators.required]]
    });
  }
  intCategorieFournisseurorm() {
    this.categorieFournisseurForm = this.formBuilder.group({
      libelleCategorieFournisseur: [null, [Validators.required]]
    });
  }
  intPrestationFournisseurForm() {
    this.prestationFournisseurForm = this.formBuilder.group({
      libellePrestationFournisseur: [null, [Validators.required]]
    });
  }
  intTypeProjetForm() {
    this.typeProjetForm = this.formBuilder.group({
      libelleTypeProjet: [null, [Validators.required]]
    });
  }

  get corpsMetierF() { return this.corpsMetierForm.controls; }
  get categorieFournisseurF() { return this.categorieFournisseurForm.controls; }
  get prestationFournisseurF() { return this.prestationFournisseurForm.controls; }
  get typeProjetF() { return this.typeProjetForm.controls; }


  loadCorpsMetier() {
    this.listCorpsMetierSubs = this.corpsMetierService.listCorpsMetier().subscribe(
      (resData) => {
        this.listCorpsMetier = resData;
        console.log(this.listCorpsMetier, "data listCorpsMetier")
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
  addCorpsMetier() {
    this.submitCorpsMetier = true;
    if (this.corpsMetierForm.invalid) {
      return;
    }
    const data = {
      libelle: this.corpsMetierForm.value.libelleCorpsMetier
    }
    this.addCorpsMetierSubs = this.corpsMetierService.addCorpsMetier(data).subscribe(
      (resData: CorpsMetier) => {
        this.closeCorpsMetierModal();
        this.listCorpsMetier = [];
        this.corpsMetierForm.reset();
        this.loadCorpsMetier();

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
  loadCategorieFournisseur() {
    this.listCategorieFournisseurSubs = this.categorieFournisseurService.listCategorieFournisseur().subscribe(
      (resData) => {
        this.listCategorieFournisseur = resData;
        console.log(this.listCorpsMetier, "data listCategorieFournisseur")
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
  addCategorieFournisseur() {
    this.submitCategorieFournisseur = true;
    if (this.categorieFournisseurForm.invalid) {
      return;
    }
    const data = {
      libelle: this.categorieFournisseurForm.value.libelleCategorieFournisseur
    }
    this.addCategorieFournisseurSubs = this.categorieFournisseurService.addCategorieFournisseur(data).subscribe(
      (resData: CategorieFournisseur) => {
        this.closeCategorieFournisseurModal();
        this.listCategorieFournisseur = [];
        this.categorieFournisseurForm.reset();
        this.loadCategorieFournisseur();

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
  loadPrestationFournisseur() {
    this.listCorpsMetierSubs = this.prestationFournisseurService.listPrestationFournisseur().subscribe(
      (resData) => {
        this.listPrestationFournisseur = resData;
        console.log(this.listPrestationFournisseur, "data listPrestationFournisseur")
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
  addPrestationFournisseur() {
    this.submitPrestationFournisseur = true;
    if (this.prestationFournisseurForm.invalid) {
      return;
    }
    const data = {
      libelle: this.prestationFournisseurForm.value.libellePrestationFournisseur
    }
    this.addPrestationFournisseurSubs = this.prestationFournisseurService.addPrestationFournisseur(data).subscribe(
      (resData: PrestationFournisseur) => {
        this.closePrestationFournisseurModal();
        this.listPrestationFournisseur = [];
        this.prestationFournisseurForm.reset();
        this.loadPrestationFournisseur();

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
  loadTypeProjet() {
    this.listTypeProjetSubs = this.typeProjetService.listTypeProjet().subscribe(
      (resData) => {
        this.listTypeProjet = resData;
        console.log(this.listTypeProjet, "data listTypeProjet")
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }
  addTypeProjet() {
    this.submitTypeProjet = true;
    if (this.typeProjetForm.invalid) {
      return;
    }
    const data = {
      libelle: this.typeProjetForm.value.libelleTypeProjet
    }
    this.addTypeProjetSubs = this.typeProjetService.addTypeProjet(data).subscribe(
      (resData: TypeProjet) => {
        this.closeTypeProjetModal();
        this.listTypeProjet = [];
        this.typeProjetForm.reset();
        this.loadTypeProjet();

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }

  newForm() {
    this.fournisseurForm = this.formBuilder.group({

      generalForm: this.formBuilder.group({
        id: [null],
        type: [2, [Validators.required]],
        typeFournisseur: [1, [Validators.required]],
        corpsMetier: [null, [Validators.required]],
        civilite: [null, [Validators.required]],
        sexe: [null, [Validators.required]],
        nationalite: [null, [Validators.required]],
        nom: [null, [Validators.required]],
        date: [null, [Validators.required]],
        pays: [null, [Validators.required]],
        ville: [null, [Validators.required]],
        commune: [null, [Validators.required]],
        quartier: [null, [Validators.required]],
        email: [null, [Validators.required]],
        postal: [null, [Validators.required]],
        contact: [null, [Validators.required]],
        naturePiece: [null, [Validators.required]],
        numPiece: [null, [Validators.required]],
        dateDelivrance: [null, [Validators.required]],
        dateExpiration: [null, [Validators.required]],
        // personne physique fin
        // debut personne moral
        // raison: [null, [Validators.required]],
        categorie: [null, [Validators.required]],
        formeJuridique: [null, [Validators.required]],
        registre: [null, [Validators.required]],
        regime: [null, [Validators.required]],
        compte: [null, [Validators.required]],
        geoLocalisation: [null, [Validators.required]],
        prestation: [null, [Validators.required]],
        representant: [null, [Validators.required]],
        contactRepresentant: [null, [Validators.required]],
        emailRepresentant: [null, [Validators.required]],
        photo: [null, [Validators.required]],
      }),
      financeForm: this.formBuilder.group({
        // Information financiere
        compteBancaire: [null, [Validators.required]],
        chiffreAffaire: [0, [Validators.required]],
        annees: this.formBuilder.array([]),
      }),
      documentsForm: this.formBuilder.group({

        // Document
        cnps: [null, [Validators.required]],
        regularite: [null, [Validators.required]],
        assurance: [null, [Validators.required]],
        statusEntreprise: [null, [Validators.required]],

      }),
      projetForm: this.formBuilder.group({
        // Projet réalisés
        typeProjet: [null, [Validators.required]],
        LibelleProjet: [null, [Validators.required]],
        duree: [null, [Validators.required]],
        maitreOuvrage: [null, [Validators.required]],
        budget: [null, [Validators.required]],
        bonneExecution: [null],
        anneeProjet: [null, [Validators.required]],

      })
    });

  }
  onChangeAnnee() {
    console.log('depart', this.chffreField.controls['annees'].controls.length);
    this.chffreField.controls['annees'].controls.length = 0;
    var nbr = (this.chffreField.value['chiffreAffaire'] >= 0 && this.chffreField.value['chiffreAffaire'] <= 10 ) && (this.chffreField.value['chiffreAffaire'] != null) ? this.chffreField.value['chiffreAffaire'] : 0;
    this.statutChiffreAffaire = (this.chffreField.value['chiffreAffaire'] >= 0 && this.chffreField.value['chiffreAffaire'] <= 10 ) && (this.chffreField.value['chiffreAffaire'] != null) ? false : true;
    this.nbreValidChiffreAffaire = nbr;
    // console.log('this.f.chiffreAffaire.value >= 0',  this.chffreField['annees'].controls);
    console.log('this.nbr', nbr);
    console.log('this.statutChiffreAffaire', this.statutChiffreAffaire);
    console.log('this.chffreField', this.chffreField);
    console.log('this.chffreField.controls', this.chffreField.controls['annees'].controls.length);
    console.log('this.chffreField.value', this.chffreField.value['chiffreAffaire']);
    if (this.chffreField.controls['annees'].controls.length < nbr) {
      this.listChiffreAffaire = this.chffreField.controls['annees'].controls;
      for (let i = 0; i < nbr; i++) {
        var num = i + 1;
        this.chffreField.controls['annees'].push(
          this.formBuilder.group({
            id: [null],
            numero: [{ value: num, disabled: true }, [Validators.required]],
            annee: [null, [Validators.required]],
            montant: [null, [Validators.required]],
            etat: [null, [Validators.required]],
          })
        );
      }
      return this.chffreField.controls['annees'];
    } else if (nbr === 0) {
      let i = this.chffreField.controls['annees'].controls.length - (nbr === 0 ? 1 : nbr);
      return this.chffreField.controls['annees'].removeAt(i);
    } else {
      return this.chffreField.controls['annees'].controls.splice(0, this.chffreField.controls['annees'].controls.length);
    }
  }
  onSubmit() { }

  get f() { return this.fournisseurForm.controls; }
  get chffreField() { return this.fournisseurForm.get('financeForm') as FormArray; }


  onClose(): any {
    this.modal.close();
  }
  test() {
    console.log(this.fournisseurForm);
  }

  __submitGeneralForm() {
    const generalForm = this.fournisseurForm.controls['generalForm'].value;
    if (generalForm['type'] == 2) {
      this.fournisseurForm.get('generalForm').patchValue({
        categorie: "",
        formeJuridique: "",
        registre: "",
        regime: "",
        compte: "",
        geoLocalisation: "",
        representant: "",
        contactRepresentant: "",
        emailRepresentant: "",
      });
      const data = {
        type: generalForm['type'],
        // Valeur manquante
        typeFournisseur: generalForm['typeFournisseur'],
        corpsMetier: generalForm['corpsMetier'],
        
        denomination: generalForm['nom'],
        // Valeur manquante
        sexe: generalForm['sexe'],
         // Valeur manquante
        civilite: generalForm['civilite'],
         // Valeur manquante
        nationalite: generalForm['nationalite'],
        dateCreation: new Date(generalForm['date']),
         // Valeur manquante
        pays: generalForm['pays'],
        // Valeur manquante
        ville: generalForm['ville'],
        commune: generalForm['commune'],
        siegeSociale: generalForm['quartier'],
        email: generalForm['email'],
        adressePostal: generalForm['postal'],
        contact: generalForm['contact'],
        // Valeur manquante
        naturePiece: generalForm['naturePiece'],
        // Valeur manquante
        numPiece: generalForm['numPiece'],
        prestation: generalForm['typeFournisseur'] == 2 ? generalForm['prestation'] : '',
        // Valeur manquante
        dateDelivrance: new Date(generalForm['dateDelivrance']),
        // Valeur manquante
        dateExpiration: new Date(generalForm['dateExpiration']),

      }
      console.log('data personne physique', data);
    } else {
      const generalForm = this.fournisseurForm.controls['generalForm'].value;
      this.fournisseurForm.get('generalForm').patchValue({
        sexe: "",
        nationalite: "",
        numPiece: "",
        civilite: "",
        naturePiece: "",
        dateDelivrance: "",
        dateExpiration: "",
      });
      const data = {
        type: generalForm['type'],
        // Valeur manquante
        typeFournisseur: generalForm['typeFournisseur'],
        corpsMetier: generalForm['corpsMetier'],
        denomination: generalForm['nom'],
        sexe: '',
        civilite: '',
        nationalite: '',
        dateCreation: new Date(generalForm['date']),
        // Valeur manquante
        pays: generalForm['pays'],
        // Valeur manquante
        ville: generalForm['ville'],
        commune: generalForm['commune'],
        siegeSociale: generalForm['quartier'],
        email: generalForm['email'],
        postal: generalForm['postal'],
        contact: generalForm['contact'],
        // Valeur manquante
        naturePiece: generalForm['naturePiece'],
        // Valeur manquante
        numPiece: generalForm['numPiece'],
        // Valeur manquante
        dateDelivrance: new Date(generalForm['dateDelivrance']),
        // Valeur manquante
        dateExpiration: new Date(generalForm['dateExpiration']),

        // Valeur manquante
        categorie: generalForm['categorie'],
        formeJuridique: generalForm['formeJuridique'],
        registre: generalForm['registre'],
        regimeImposition: generalForm['regime'],
        compteContribuable: generalForm['compte'],
        localisation: generalForm['geoLocalisation'],
        prestation: generalForm['typeFournisseur'] == 2 ? generalForm['prestation'] : '',
        representant: generalForm['representant'],
        contactRepresentant: generalForm['contactRepresentant'],
        emailRepresentant: generalForm['emailRepresentant'],

      }
      console.log('data personne morale', data);
    }


  }
  __submitFinancierForm() {
    const financeForm = this.fournisseurForm.controls['financeForm'].value;
    const data = {
      compteBancaire: financeForm['compteBancaire'],
      chiffreAffaire: financeForm['chiffreAffaire'],
    }
    console.log('data form finance :', data);
    const formChiffreAffaireCorrect = this.listChiffreAffaire.filter(result => result.status == 'VALID');
    formChiffreAffaireCorrect.forEach(element => this.tabLigneChiffreAffaire.push(element.value));
    console.log('data listChiffreAffaire Line :', this.listChiffreAffaire.filter(result => result.status == 'VALID'));
    console.log('this.chffreField.controls :', this.chffreField.controls['annees'].controls);
    console.log('this.tabLigneChiffreAffaire :', this.tabLigneChiffreAffaire);
  }
  closeCorpsMetierModal() {
    this.submitCorpsMetier = false;
    this.corpsMetierForm.reset();
    this.newCorpsMetierModal.hide();
  }
  closeCategorieFournisseurModal() {
    this.submitCategorieFournisseur = false;
    this.categorieFournisseurForm.reset();
    this.newCategorieFournisseurModal.hide();
  }
  closePrestationFournisseurModal() {
    this.submitPrestationFournisseur = false;
    this.prestationFournisseurForm.reset();
    this.newPrestationFournisseurModal.hide();
  }
  closeTypeProjetModal() {
    this.submitTypeProjet = false;
    this.typeProjetForm.reset();
    this.newTypeProjetModal.hide();
  }
  showNotification(type: string, message: string): void {
    this.notif.notify(type, message);
  }
  __onSelectCivilite() {
    const generalForm = this.fournisseurForm.controls['generalForm'].value;
    const civilite = generalForm['civilite'];
    if (civilite == 'M') {
      this.fournisseurForm.get('generalForm').patchValue({
        sexe: "Masculin"
      });
    } else {
      this.fournisseurForm.get('generalForm').patchValue({
        sexe: "Féminin"
      });
    }
  }
  __deleteChiffreAffaireLigne(data) {
    console.log('data', data);
    this.listChiffreAffaire.splice(this.listChiffreAffaire.indexOf(data), 1)
    const financeForm = this.fournisseurForm.controls['financeForm'].value;
    this.fournisseurForm.get('financeForm').patchValue({
      chiffreAffaire: parseInt(financeForm['chiffreAffaire'], 10) - 1
    });
    if (this.listChiffreAffaire.length == 0) {
      this.nbreValidChiffreAffaire = 0
    }

  }
  __addChiffreAffaireLigne() {
    this.listChiffreAffaire.push(
      this.formBuilder.group({
        id: [null],
        numero: [{ value: this.listChiffreAffaire.length + 1, disabled: true }, [Validators.required]],
        annee: [null, [Validators.required]],
        montant: [null, [Validators.required]],
        etat: [null, [Validators.required]],
      })
    );
    const financeForm = this.fournisseurForm.controls['financeForm'].value;
    this.fournisseurForm.get('financeForm').patchValue({
      chiffreAffaire: parseInt(financeForm['chiffreAffaire'], 10) + 1
    });
  }
  __addProjetRealise() {
    const projetForm = this.fournisseurForm.controls['projetForm'].value;
    const data = {
      typeProjet: projetForm['typeProjet'],
      libelleTypeProjet: this.__recupTypeProjetLibelle(projetForm['typeProjet']),
      libelleProjet: projetForm['LibelleProjet'],
      anneeProjet: projetForm['anneeProjet'],
      duree: projetForm['duree'],
      maitreOuvrage: projetForm['maitreOuvrage'],
      budget: projetForm['budget'],
    }
    if(!this.fournisseurForm.controls['projetForm'].invalid) {
      this.listProjetRealises.push(data);
      this.fournisseurForm.controls['projetForm'].reset();
      console.log('data projet', this.listProjetRealises);
    }
    
  }
  __deleteProjetRealise(data) {
    this.listProjetRealises.splice(this.listProjetRealises.indexOf(data), 1)
  }
  __recupTypeProjetLibelle(id) {
    const typeProjet = this.listTypeProjet.filter(x => x['@id'] == id);
    return typeProjet[0].libelle;
  }

}
