import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ressource-pages',
  templateUrl: './ressource-pages.component.html',
  styleUrls: ['./ressource-pages.component.scss']
})
export class RessourcePagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
