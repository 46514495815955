import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { stringify } from 'querystring';
import { BehaviorSubject,throwError } from 'rxjs';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';



interface ChantierData {
  id?: string,
  dateDebut: Date;
    dateFin: Date;
    libelle: string;
    longitude: number;
    latitude: number;
    description: string;
    projet: string;
    typeChantier: string;
    chefChantier: string;
    visiteChantier: [
      string
    ]
}

@Injectable({
  providedIn: 'root'
})
export class ChantierService {

  
  apiUrl = environment.apiUrl;
  prefix = environment.prefix;

  constructor(private http:HttpClient) { }

  

  listChantier(){
    return this.http.get<ChantierData[]>(
      `${this.apiUrl}/chantiers`,{
        responseType: 'json'
      }
    ).pipe(
      map((response: any) => response['hydra:member']), 
      catchError(this.handleError)
    )
  }

  addChantier(data:ChantierData){
    let responseData:ChantierData;
        return this.http.post(`${this.apiUrl}/chantiers`,JSON.stringify(data)).pipe(
        tap( resData => {      
          return resData
        }),
        catchError(this.handleError)
      );//pipe   
  }

  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "Une erreur est survenue";
    return throwError(messageError);
  }
}
