<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-graph3 display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1 class="font-weight-bold m-0">Détail chantier</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description m-0" title="Référence et libellé du chantier">
                    <span><i class="fas fa-file-alt"></i> ( {{chantier?.reference}} )</span>
                    <span class="ml-1">{{chantier?.libelle}}</span>
                </div>
                <p class="m-0" title="Date début et date fin du chantier">
                    <i class="fas fa-calendar-alt"></i>
                    <span class="ml-1">Du {{chantier?.dateDebut}} au {{chantier?.dateFin}}</span>
                </p>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button (click)="newTravauxModal.show()" class="btn btn-success mr-2" type="button" tooltip="Ajouter un travail"><i class="fas fa-plus-circle mr-2"></i>Travaux</button>
            <button [routerLink]="['/pages/projet/detail/5']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<accordion [closeOthers]="true" [isAnimated]="true" class="accordion mb-3">
    <accordion-group class="card card-box">
        <button accordion-heading class="btn btn-link btn-lg d-flex align-items-center justify-content-between"
                type="button">
            <span>Cliquer pour dérouler</span>
            <fa-icon [icon]="['fas', 'angle-up']" class="font-size-xl"></fa-icon>
        </button>
        <div class="m-3 border rounded">
            <div class="row">
                <!-- LA ZONE DE GAUCHE -->
                <div class="col-md-4">
                    <p class="mt-2 mb-0"><b>Libellé:</b> <span class="ml-2">{{chantier?.libelle}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date début:</b> <span class="ml-2">{{chantier?.dateDebut}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date fin:</b> <span class="ml-2">{{chantier?.dateFin}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Longitude:</b> <span class="ml-2">{{chantier?.longitude}} m²</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Latitude:</b> <span class="ml-2">{{chantier?.latitude}} m²</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Sous-traiter ? </b> <span class="ml-2">{{chantier?.soutraitance == 'oui' ? 'OUI' : 'NON'}}</span></p>
                    <hr class="my-2">
                    <div class="m-0" *ngIf="chantier?.soutraitance == 'oui'">
                        <p class="m-0"><b>Soutraitant:</b> <span class="ml-2">{{chantier?.soutraitant}}</span></p>
                        <hr class="my-2">
                        <p class="m-0"><b>Montant soutraitance:</b> <span class="badge badge-info ml-2">{{chantier?.mtnSoutraitance | number:'': 'fr-FR'}}</span></p>
                        <hr class="my-2">
                    </div>
                    <p class="m-0"><b>Chef chantier:</b> <span class="ml-2">{{chantier?.chefChantier}}</span></p>
                    <hr class="my-2">
                </div>

                <!-- LA ZONE DE CENTRE -->
                <div class="col-md-4">
                    <p class="mt-2 mb-0"><b>Durée prevu:</b> <span class="ml-2">{{chantier?.durePrevue}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Budget:</b> <span class="text-first ml-2">{{chantier?.budget | number:'': 'fr-FR'}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Depense:</b> <span class="text-danger ml-2">{{chantier?.depense | number:'': 'fr-FR'}}</span></p>
                    <hr class="my-2">
                    <div class="d-flex m-0">
                        <p class="m-0"><b>Evolution:</b></p>
                        <progressbar
                            [value]="chantier?.evolution"
                            type="{{
                                chantier?.evolution >= 0 && chantier?.evolution <= 24 ? 'danger':
                                chantier?.evolution >= 25 && chantier?.evolution <= 49 ? 'warning':
                                chantier?.evolution >= 50 && chantier?.evolution <= 64 ? 'info':
                                chantier?.evolution >= 65 && chantier?.evolution <= 99 ? 'first':'success'
                            }}" class="ml-2 mt-1">{{chantier?.evolution}}%
                        </progressbar>
                    </div>
                    <hr class="my-2">
                    <p class="m-0"><b>Créer par:</b> <span class="ml-2">{{chantier?.creerPar}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de création:</b> <span class="ml-2">{{chantier?.dateCreation}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Modifier par:</b> <span class="ml-2">{{chantier?.modifierPar}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de modification:</b> <span class="ml-2">{{chantier?.dateModification}}</span></p>
                    <hr class="my-2">
                </div>

                <!-- LA ZONE DE DROITE -->
                <div class="col-md-4">
                    <a [routerLink]="" class="card-img-wrapper rounded mt-2 mr-1">
                        <div class="img-wrapper-overlay">
                            <div class="overlay-btn-wrapper">
                                <button class="btn btn-first p-0 d-50 rounded-circle" type="button" tooltip="Agrandir">
                                    <span class="btn-wrapper--icon">
                                        <i class="fas fa-expand"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <img src="{{chantier?.fichier}}" alt="{{chantier?.libelle}}" class="card-img-top rounded">
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <b>Description:</b>
                    <p class="m-0">{{chantier?.description}}</p>
                </div>
            </div>
        </div>
        <hr class="m-0">
        <div class="text-right py-2 px-3">
            <button class="btn btn-info mr-2" type="button"><i class="fas fa-pen mr-1"></i>Modifier</button>
            <button class="btn btn-warning mr-2" type="button"><i class="ion-md-print mr-1"></i>Imprimer</button>
            <button class="btn btn-danger" type="button"><i class="ion-md-trash mr-1"></i>Supprimer</button>
        </div>
    </accordion-group>
</accordion>

<div>
    <app-travaux-list></app-travaux-list>
</div>

<!--MODAL AJOUT TRAVAUX-->
<div #newTravauxModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
              <h6 class="modal-title">Formulaire de création de travaux</h6>
              <button type="button" class="close" aria-label="Close" (click)="newTravauxModal.hide()">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-0">
                <app-travaux-edit></app-travaux-edit>
            </div>
        </div>
    </div>
</div>
<!--MODAL AJOUT TRAVAUX-->