<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-wallet display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
              <h1>Etude de prix</h1>
              <div *ngIf="globals.pageTitleDescription" class="app-page-title--description"> Liste des étude de prix</div>
            </div>
        </div>
        <div class="flex-fill text-right">
            <div class="btn-group m-2" dropdown>
                <button type="button" class="btn btn-secondary btn-sm">Options</button>
                <button type="button" dropdownToggle class="btn btn-sm  btn-secondary dropdown-toggle dropdown-toggle-split">
                  <span class="caret"></span>
                  <span class="sr-only">Split button!</span>
                </button>
                <ul *dropdownMenu class="dropdown-menu" role="menu">
                  <li tooltip="Exporter la liste" role="menuitem"><a class="dropdown-item" [routerLink]="">Exporter en excel</a></li>
                  <li class="divider dropdown-divider"></li>
                  <li tooltip="Télécharger un modèle" role="menuitem"><a class="dropdown-item" [routerLink]="">Charger un modèle</a>
                  </li>
                </ul>
              </div>
          <button (click)="openSelectProjet.show()"    class="btn btn-success ml-2" tooltip="Effectuer une étude" type="button"><i class="fas fa-plus-circle"></i> Ajouter</button>
        </div>
    </div>
  </div>
  
  <div class="card card-box mb-5">
    <div class="d-flex justify-content-between px-4 py-3">
      <div class="d-flex align-items-center">
        <span>Affichage</span>
        <select class="mx-1 form-control form-control-sm" id="" name="">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
        </select>
        <span>éléments</span>
      </div>
      <div class="search-wrapper">
            <span class="icon-wrapper text-black">
              <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </span>
        <input class="form-control form-control-sm rounded-pill" placeholder="Search terms..." type="search">
      </div>
    </div>
    <div class="divider"></div>
    <div class="table-responsive">
      <table class="table table-hover text-nowrap mb-0">
        <thead>
        <tr>
          <th class="bg-white text-left">ID</th>
          <th class="bg-white text-left">Projet</th>
          <th class="bg-white text-left">Type</th>
          <th class="bg-white text-left">Responsable</th>
          <th class="bg-white">Montant</th>
          <th class="bg-white text-center">Date Création</th>
          <th class="bg-white text-center">Evolution</th>
          <th class="bg-white text-center">Statut</th>
          <th class="bg-white text-center">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td class="font-weight-bold">
              ET-453
            </td>
            <td>
              <div class="d-flex align-items-center">
                <div>
                  Construction villa R+1
                </div>
              </div>
            </td>
  
            <td>
              <div class="d-flex align-items-center">
                <div>
                  Projet
                </div>
              </div>
            </td>
  
  
            <td class="font-weight-bold">
              Cisse amidou
            </td>
  
            <td class="font-weight-bold">
              120.000.000
            </td>
  
            <td class="text-center text-muted">
              12/12/2020
            </td>
  
            <td class="text-center">
              <span class="badge bg-neutral-success text-success">Terminé</span>
            </td>
  
            <td class="text-center">
              <span class="badge bg-neutral-success text-success">Validé</span>
            </td>
  
            <td class="text-center">
                <button placement="auto" [routerLink]="['/pages/etude_prix/detail/5']"  tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                    <span class="btn-wrapper--icon">
                        <i class="ion-md-list-box"></i>
                    </span>
                </button>
  
                <button  placement="auto"  tooltip="Supprimer"  class="btn d-40 p-0 btn-pill m-2 btn-danger" type="button">
                    <span class="btn-wrapper--icon">
                        <i class="ion-md-trash"></i>
                    </span>
                </button>
            </td>
        </tr>
  
        <tr>
          <td class="font-weight-bold">
            ET-453
          </td>
          <td>
            <div class="d-flex align-items-center">
              <div>
                Construction villa R+1
              </div>
            </div>
          </td>
  
          <td>
            <div class="d-flex align-items-center">
              <div>
                Projet
              </div>
            </div>
          </td>
  
  
          <td class="font-weight-bold">
            Cisse amidou
          </td>
  
          <td class="font-weight-bold">
            120.000.000
          </td>
  
          <td class="text-center text-muted">
            12/12/2020
          </td>
  
          <td class="text-center">
            <span class="badge bg-neutral-success text-success">Terminé</span>
          </td>
  
          <td class="text-center">
            <span class="badge bg-neutral-success text-success">Validé</span>
          </td>
  
          <td class="text-center">
              <button placement="auto" [routerLink]="['/pages/etude_prix/detail/5']"  tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                  <span class="btn-wrapper--icon">
                      <i class="ion-md-list-box"></i>
                  </span>
              </button>
  
              <button  placement="auto"  tooltip="Supprimer"  class="btn d-40 p-0 btn-pill m-2 btn-danger" type="button">
                  <span class="btn-wrapper--icon">
                      <i class="ion-md-trash"></i>
                  </span>
              </button>
          </td>
      </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer py-3 d-flex justify-content-between">
      <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-second"></pagination>
      <div class="d-flex align-items-center">
        <span>Affichage</span>
        <select class="mx-1 form-control form-control-sm" id="" name="">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
        </select>
        <span>éléments</span>
      </div>
    </div>
  </div>
  
  
  <!--MODAL AJOUT OPPORTUNITE-->
  <div #openEtudeModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
      <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content ">
            <div class="modal-header modal-dark  bg-plum-plate">
              <h6 class="modal-title">Effectuer une Etude de prix</h6>
              <button type="button" class="close" aria-label="Close" (click)="openEtudeModal.hide()">
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row no-gutters">
                <div class="col-lg-12">
                    <div class="bg-white rounded-left">
                        <div class="p-4">
                            <app-etude-prix-edit></app-etude-prix-edit>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
      </div>
  </div>
  <!--FIN MODAL AJOUT OPPORTUNITE-->
  
  
  
  
  <!--Formulaire ajout opportuinite-->
  <div #openFormEtude="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content ">
          <div class="modal-header modal-dark  bg-plum-plate">
            <h6 class="modal-title">Etude de prix</h6>
            <button type="button" class="close" aria-label="Close" (click)="openFormEtude.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row no-gutters">
              <div class="col-lg-12">
                  <div class="bg-white rounded-left">
                      <div class="p-4 row">
                             <!--Detail projet-->
                              <!--<div class="col-lg-3">
                                <div class="bg-midnight-bloom p-3 rounded text-white h-100">
                                  <div class="d-flex align-items-start justify-content-between">
                                      <div class="avatar-icon-wrapper d-100">
                                          <span class="badge badge-circle badge-success">Online</span>
                                          <div class="avatar-icon d-100">
                                              <img alt="..." src="assets/images/avatars/avatar7.jpg"/>
                                          </div>
                                      </div>
                                      <a [routerLink]="" class="btn btn-sm btn-first shadow-none">
                                          Valider
                                      </a>
                                  </div>
                                  <div class="font-weight-bold font-size-lg d-flex align-items-center mt-2 mb-0">
                                      <span>Construction Villa 1</span>
                                  </div>
                                  <p class="mb-4 font-size-md text-white-50">Opportunité projet</p>
  
  
                                  <div class="divider opacity-2 my-4"></div>
  
  
                                  <div class="font-weight-bold font-size-lg d-flex align-items-center mb-3">
                                      <span>Informations</span>
                                  </div>
                                  <div class="py-2">
                                      <div class="d-flex justify-content-between">
                                                  <span class="d-block">
                                                      <a [routerLink]="" class="text-white">Responsable Projet</a>
                                                  </span>
                                          <span class="badge badge-dark">
                                                      Ange Ouglou
                                                  </span>
                                      </div>
                                  </div>
                              </div>
                              </div>
                             Detail projet-->
  
                          <div class="col-lg-12">
  
                            <app-etude-prix-edit></app-etude-prix-edit>
  
  
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
      </div>
    </div>
  </div>
  <!--Formulaire ajout opportuinite-->
  
  <!--Selection du projet-->
  <div #openSelectProjet="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable  modal-dialog-centered" >
        <div class="modal-content ">
          <div class="modal-header modal-dark  bg-plum-plate">
            <h6 class="modal-title">Selectionnez un projet</h6>
            <button type="button" class="close" aria-label="Close" (click)="openSelectProjet.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row no-gutters">
              <div class="col-lg-12">
                  <div class="bg-white rounded-left">
                          <!--Selection projetprojet-->
                          <div class="card card-box mb-5">
                            <div class="d-flex justify-content-between px-4 py-3">
  
                              <div class="search-wrapper">
                                    <span class="icon-wrapper text-black">
                                      <fa-icon [icon]="['fas', 'search']"></fa-icon>
                                    </span>
                                <input class="form-control form-control-sm rounded-pill" placeholder="Search terms..." type="search">
                              </div>
                            </div>
                            <div class="divider"></div>
                            <div class="table-responsive">
                              <table class="table table-hover text-nowrap mb-0">
                                <thead>
                                <tr>
                                  <th class="bg-white text-left">Projet</th>
                                  <th class="bg-white text-left">Type</th>
                                  <th class="bg-white text-center">Statut étude</th>
                                  <th class="bg-white text-center">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                      <td>
                                        <div class="d-flex align-items-center">
                                          <div>
                                            Construction villa R+1
                                          </div>
                                        </div>
                                      </td>
  
                                      <td>
                                        <div class="d-flex align-items-center">
                                          <div>
                                            Projet
                                          </div>
                                        </div>
                                      </td>
  
  
                                      <td class="font-weight-bold">
                                        En cours
                                      </td>
  
  
                                      <td class="text-center">
                                          <button placement="auto" (click)="openFormEtude.show();openSelectProjet.hide()"   tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                                              <span class="btn-wrapper--icon">
                                                  <i class="ion-md-list-box"></i>
                                              </span>
                                          </button>
                                      </td>
                                  </tr>
  
                                  <tr>
                                    <td>
                                      <div class="d-flex align-items-center">
                                        <div>
                                          Construction villa R+1
                                        </div>
                                      </div>
                                    </td>
  
                                    <td>
                                      <div class="d-flex align-items-center">
                                        <div>
                                          Projet
                                        </div>
                                      </div>
                                    </td>
  
  
                                    <td class="font-weight-bold">
                                      En attente
                                    </td>
  
  
                                    <td class="text-center">
                                        <button placement="auto"  tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                                            <span class="btn-wrapper--icon">
                                                <i class="ion-md-list-box"></i>
                                            </span>
                                        </button>
                                    </td>
                                </tr>
  
                                <tr>
                                  <td>
                                    <div class="d-flex align-items-center">
                                      <div>
                                        Construction villa R+1
                                      </div>
                                    </div>
                                  </td>
  
                                  <td>
                                    <div class="d-flex align-items-center">
                                      <div>
                                        Projet
                                      </div>
                                    </div>
                                  </td>
  
  
                                  <td class="font-weight-bold">
                                    En cours
                                  </td>
  
  
                                  <td class="text-center">
                                      <button placement="auto"  (click)="openFormEtude.show()"   tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                                          <span class="btn-wrapper--icon">
                                              <i class="ion-md-list-box"></i>
                                          </span>
                                      </button>
                                  </td>
                              </tr>
  
                              <tr>
                                <td>
                                  <div class="d-flex align-items-center">
                                    <div>
                                      Construction villa R+1
                                    </div>
                                  </div>
                                </td>
  
                                <td>
                                  <div class="d-flex align-items-center">
                                    <div>
                                      Projet
                                    </div>
                                  </div>
                                </td>
  
  
                                <td class="font-weight-bold">
                                  En attente
                                </td>
  
  
                                <td class="text-center">
                                    <button placement="auto"  tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                                        <span class="btn-wrapper--icon">
                                            <i class="ion-md-list-box"></i>
                                        </span>
                                    </button>
                                </td>
                            </tr>
  
                            <tr>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div>
                                    Construction villa R+1
                                  </div>
                                </div>
                              </td>
  
                              <td>
                                <div class="d-flex align-items-center">
                                  <div>
                                    Projet
                                  </div>
                                </div>
                              </td>
  
  
                              <td class="font-weight-bold">
                                En cours
                              </td>
  
  
                              <td class="text-center">
                                  <button placement="auto"  tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                                      <span class="btn-wrapper--icon">
                                          <i class="ion-md-list-box"></i>
                                      </span>
                                  </button>
                              </td>
                          </tr>
  
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">
                                <div>
                                  Construction villa R+1
                                </div>
                              </div>
                            </td>
  
                            <td>
                              <div class="d-flex align-items-center">
                                <div>
                                  Projet
                                </div>
                              </div>
                            </td>
  
  
                            <td class="font-weight-bold">
                              En attente
                            </td>
  
  
                            <td class="text-center">
                                <button placement="auto"  tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill m-2 btn-primary" type="button">
                                    <span class="btn-wrapper--icon">
                                        <i class="ion-md-list-box"></i>
                                    </span>
                                </button>
                            </td>
                        </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
  
                          <!--Selection projetprojet-->
  
                  </div>
              </div>
          </div>
          </div>
      </div>
    </div>
  </div>
  <!--Selection du projet-->  