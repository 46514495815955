export class User {
    constructor(
        private username:string,
        private localId:string,
        private _token:string,
        private expirationDateToken: Date
        ){}

    get token(){
        if(!this.expirationDateToken || new Date() > this.expirationDateToken ){
            return null;
        }
        return this._token;
    }
}