import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TresoreriePages } from 'src/app/models/tresorerie-pages';

@Injectable({
  providedIn: 'root'
})
export class TresoreriePagesService {
  tresorerie: TresoreriePages
  private readonly url = "api/tresoreries.json";

  constructor(
    private http: HttpClient
  ) { }

  setTresorerie(tresorerie: TresoreriePages) {
    this.tresorerie = tresorerie
  }

  getTresorerie(): TresoreriePages {
    return this.tresorerie
  }

  getList(): Observable<TresoreriePages[]> {
    return this.http.get<TresoreriePages[]>(this.url).pipe(
      tap(tresoreries => console.log('tresorerie:', tresoreries)),
      catchError(this.handleHttpError)
    );
  }
  
  private handleHttpError(err: HttpErrorResponse) {
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${err.status}, ` +
        `body was: ${err.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
