<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="p-4">
        <div class="d-flex justify-content-between mb-3">
            <div><h5 class="font-weight-bold m-0">Informations sur le projet</h5></div>
        </div><hr>
        <div class="px-2">
            <div class="form-row">
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="projet">Projet concerné: <span class="text-danger mr-2">*</span></label>
                        <input type="text" id="projet" formControlName="projet" class="form-control"
                        placeholder="Selectionner le projet" title="Nom du proje">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="dateDebut">Date début: </label>
                        <input type="text" id="dateDebut" formControlName="dateDebut" class="form-control" 
                        placeholder="Date début du projet" title="Date début du projet" disabled>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="dateFin">Date fin: </label>
                        <input type="text" id="dateFin" formControlName="dateFin" class="form-control"
                        placeholder="Date début du projet" title="Date fin du projet" disabled>
                    </div>
                </div>
            </div>
            <hr class="mt-0 mb-4">
            <h5 class="font-weight-bold m-0">Informations sur le chantier</h5>
            <hr>
            <div class="form-row">
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="libelle">Libellé du chantier: <span class="text-danger mr-2">*</span></label>
                        <input type="text" class="form-control" id="libelle" formControlName="libelle" placeholder="Libellé du chantier" title="Libellé du chantier"
                        [ngClass]="{ 'is-invalid': submit && f.libelle.errors, 'is-valid': submit && f.libelle.valid }">
                        <div class="invalid-feedback" *ngIf="submit && f.libelle.errors">
                            <div *ngIf="f.libelle.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="type">Type de chantier: <span class="text-danger mr-2">*</span></label>
                        <input type="text" id="type" formControlName="type" class="form-control" placeholder="Type de chantier" title="Type de chantier"
                        [ngClass]="{ 'is-invalid': submit && f.type.errors, 'is-valid': submit && f.type.valid }">
                        <div class="invalid-feedback" *ngIf="submit && f.type.errors">
                            <div *ngIf="f.type.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="debutChantier">Date début: <span class="text-danger mr-2">*</span></label>
                        <div class="input-group input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                            </div>
                            <input type="text" id="debutChantier" formControlName="debutChantier" class="form-control"
                            [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                            placeholder="Date début du chantier" title="Date début du chantier"
                            [ngClass]="{ 'is-invalid': submit && f.debutChantier.errors, 'is-valid': submit && f.debutChantier.valid }">
                            <div class="invalid-feedback" *ngIf="submit && f.dateDebut.errors">
                                <div *ngIf="f.debutChantier.errors.required">{{required.novide}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="finChantier">Date fin: <span class="text-danger mr-2">*</span></label>
                        <div class="input-group input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                            </div>
                            <input type="text" id="finChantier" formControlName="finChantier" class="form-control"
                            [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                            placeholder="Date fin du chantier" title="Date fin du chantier"
                            [ngClass]="{ 'is-invalid': submit && f.finChantier.errors, 'is-valid': submit && f.finChantier.valid }">
                            <div class="invalid-feedback" *ngIf="submit && f.finChantier.errors">
                                <div *ngIf="f.finChantier.errors.required">{{required.novide}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="longitude">Longitude: <span class="text-danger mr-2">*</span></label>
                        <input type="number" min="0" id="longitude" formControlName="longitude" class="form-control" placeholder="Longitude" title="Longitude"
                        [ngClass]="{ 'is-invalid': submit && f.longitude.errors, 'is-valid': submit && f.longitude.valid }">
                        <div class="invalid-feedback" *ngIf="submit && f.longitude.errors">
                            <div *ngIf="f.longitude.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="latitude">Latitude: <span class="text-danger mr-2">*</span></label>
                        <input type="number" min="0" id="latitude" formControlName="latitude" class="form-control" placeholder="Latitude" title="Latitude"
                        [ngClass]="{ 'is-invalid': submit && f.latitude.errors, 'is-valid': submit && f.latitude.valid }">
                        <div class="invalid-feedback" *ngIf="submit && f.latitude.errors">
                            <div *ngIf="f.latitude.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-{{f.soutraitance.value == 'oui' ? 4 : 12}}">
                    <div class="position-relative form-group">
                        <label for="soutraitance">Sous-traité ?</label>
                        <select id="soutraitance" formControlName="soutraitance" class="form-control">
                            <option *ngFor="let soutraitance of soutraitanceRow" [value]="soutraitance.value">{{soutraitance.label}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="f.soutraitance.value == 'oui'">
                    <div class="position-relative form-group">
                        <label for="soutraitant">Soutraitant:</label>
                        <input type="text" id="soutraitant" formControlName="soutraitant" class="form-control" placeholder="Soutraitant">
                    </div>
                </div>
                <div class="col-md-4" *ngIf="f.soutraitance.value == 'oui'">
                    <div class="position-relative form-group">
                        <label for="mtnSoutraitance">Montant soutraitance:</label>
                        <input type="number" id="mtnSoutraitance" formControlName="mtnSoutraitance" class="form-control" placeholder="Montant soutraitance">
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="chefChantier">Chef de chantier: <span class="text-danger mr-2">*</span></label>
                        <input type="text" id="chefChantier" formControlName="chefChantier" class="form-control"
                        placeholder="Chef de chantier" title="Chef de chantier"
                        [ngClass]="{ 'is-invalid': submit && f.chefChantier.errors, 'is-valid': submit && f.chefChantier.valid }">
                        <div class="invalid-feedback" *ngIf="submit && f.chefChantier.errors">
                            <div *ngIf="f.chefChantier.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="description">Description:</label>
                        <textarea id="description" formControlName="description" cols="30" rows="1" class="form-control" placeholder="Ajouter une description"></textarea>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="position-relative form-group">
                        <label for="photo">Photo:</label>
                        <div class="custom-file">
                            <input type="file" id="photo" formControlName="photo" class="custom-file-input" title="Joindre un fichier" (change)="onSelectImg($event)" multiple>
                            <label class="custom-file-label" for="photo">Choisir une photo</label>
                        </div>
                    </div>
                </div>
            </div>
            <hr *ngIf="imgUrl.length > 0">
            <div class="d-flex">
                <a class="card-img-wrapper shadow-sm rounded-sm mr-2" *ngFor="let img of imgUrl"  style="height: 100px; width: 100px;">
                    <div class="img-wrapper-overlay">
                        <div class="overlay-btn-wrapper">
                            <button class="btn btn-danger p-0 d-50 rounded-circle" type="button" (click)="deleteImg(img)">
                                <span class="btn-wrapper--icon">
                                    <i class="ion-md-trash"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                    <img alt="..." class="card-img-top rounded-sm" [src]="img" style="height: 100px; width: 100px;">
                </a>
            </div>
        </div>
    </div>
    <hr class="m-0">
    <div class="px-4 py-2">
        <div class="row">
            <div class="col-md-6">
                <p class="text-danger mt-2">L'étoile (*) signifie que cet champ est obligatoire.</p>
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-first mr-2" type="button" (click)="form.reset()"><i class="fas fa-broom  mr-2"></i> Nettoyer</button>
                <button  class="btn btn-success" type="submit"><i class="fas fa-save mr-2"></i> Enregistrer</button>
            </div>
        </div>
    </div>
</form>