import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject,throwError } from 'rxjs';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Personne } from './personne.model';

interface PersonneData {
  id?: any;
  nom?: string;
  prenom?: string;
  fonction?: string;
  pays?: string;
  contact?: string;
  email?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PersonneService {
  private _maitre = new BehaviorSubject<Personne[]>([]);
  apiUrl = environment.apiUrl;
  prefix = environment.prefix;

  constructor(private http:HttpClient) { }

  get tiers(){
    return this._maitre.asObservable();
  }

  getListe(){
    return this.http.get<PersonneData[]>(
      `${this.apiUrl}/personnes`,{
        responseType: 'json'
      }
    ).pipe(
      take(1),
      switchMap( res =>{
        return res['hydra:member'];
      } ), 
      catchError(this.handleError)
    )
  }
  create(data:PersonneData){
    return this.http.post<PersonneData>(`${this.apiUrl}/maitre_ouvrages`,JSON.stringify(data)).pipe(
      take(1),
      tap( resData => {
          return resData
      }),
      catchError(this.handleError)
    );//pipe   
  }

  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "Une erreur est survenue";
    return throwError(messageError);
  }
}
