import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'querystring';
import { BehaviorSubject, throwError } from 'rxjs';
import { Utilisateur } from './utilisateur.model';
import { environment } from './../../../environments/environment';
import { AuthPagesService } from '../auth-pages/auth-pages.service';
import { catchError, switchMap, take, map, tap } from 'rxjs/operators';

interface UtilisateurData {
  id?: string,
  nom: string,
  prenom?: string,
  email: string,
  contact1: string,
  contact2?: string,
  genre?: boolean,
  pays?: string,
  ville?: string,
  username: string,
  type?: number,
  service?: string,
  tiers?: object | string,
  bureauEtude?: object | string,
  superieurHierarchique?: string,
  dateNaissance?: Date,
  fonction?: string,
  localisation?: string,
  adresse?: string,
  enabled?: true,
  avatar?: string,
  published?: string,
  modified?: string,
  roles?: [],
}

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {

  private _utilisateurs = new BehaviorSubject<Utilisateur[]>([]);
  apiUrl = environment.apiUrl;
  prefix = environment.prefix;

  private assignData;
  constructor(private authService: AuthPagesService, private http: HttpClient) { }

  get utilisateurs() {
    return this._utilisateurs.asObservable();
  }

  addUtilisateur(userData: UtilisateurData) {
    return this.http.post<UtilisateurData>(`${this.apiUrl}/utilisateurs`, JSON.stringify(userData)).pipe(
      take(1),
      tap(resData => {
        return resData
      }),
      catchError(this.handleError)
    );//pipe   
  }
  updateUtilisateur(id: number, userData: UtilisateurData) {
    return this.http.put<UtilisateurData>(`${this.apiUrl}/utilisateurs/` + id, JSON.stringify(userData)).pipe(
      take(1),
      tap(resData => {
        return resData
      }),
      catchError(this.handleError)
    );//pipe   
  }

  deleteUtilisateur(id: number, userData?: UtilisateurData) {
    return this.http.delete<UtilisateurData>(`${this.apiUrl}/utilisateurs/` + id).pipe(
      take(1),
      tap(resData => {
        return resData
      }),
      catchError(this.handleError)
    );//pipe   
  }
  getDetailsByUtilisateur(id: number) {
    return this.http.get<Utilisateur>(`${this.apiUrl}/utilisateurs/` + id).pipe(
      take(1),
      tap(resData => {
        return resData
      }),
      catchError(this.handleError)
    );//pipe   
  }

  listerUtilisateurs() {
    return this.http.get<UtilisateurData[]>(
      `${this.apiUrl}/utilisateurs`, {
      responseType: 'json'
    }
    ).pipe(
      take(1),
      switchMap(res => {
        return res['hydra:member'];
      }),
      catchError(this.handleError)
    )
  }

  getDataForUtilisateur() {
    return this.assignData;
  }
  setDataForUtilisateur(data) {
    this.assignData = data;
  }


  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "Une erreur est survenue";
    return throwError(messageError);
  }

}
