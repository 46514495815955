import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UtilisateurPagesRoutingModule } from './utilisateur-pages-routing.module';
import { UtilisateurPagesComponent } from './utilisateur-pages.component';
import { UtilisateurDetailComponent } from './utilisateur-detail/utilisateur-detail.component';
import { UtilisateurEditComponent } from './utilisateur-edit/utilisateur-edit.component';
import { UtilisateurListComponent } from './utilisateur-list/utilisateur-list.component';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AlertModule, BsDatepickerModule, ModalModule, PaginationModule, ProgressbarModule, TabsModule, TooltipModule} from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';
import { AngularTreeGridModule } from 'angular-tree-grid';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TextMaskModule } from 'angular2-text-mask';
import { FileUploadModule } from 'ng2-file-upload';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PaginationPipePipe } from '../pagination-pipe.pipe';
import { NgxPaginationModule } from 'ngx-pagination';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'uifort',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
     easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
     easing: 'ease'
    },
    overlap: 150
  }
};


@NgModule({
  declarations: [
    UtilisateurPagesComponent,
    UtilisateurDetailComponent,
    UtilisateurEditComponent,
    UtilisateurListComponent,
    PaginationPipePipe

  ],
  imports: [
    CommonModule,
    UtilisateurPagesRoutingModule,
    Ng2SmartTableModule,
    TabsModule,
    FontAwesomeModule,
    AngularTreeGridModule,
    ModalModule,
    BsDatepickerModule,
    TooltipModule,
    AlertModule,
    PaginationModule,
    NgxPaginationModule,
    RouterModule,
    ProgressbarModule,
    ImageCropperModule,
    TextMaskModule,
    FileUploadModule,
    NotifierModule.withConfig(customNotifierOptions),
    NgxSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    SweetAlert2Module
  ],
  exports: [
    UtilisateurDetailComponent,
   // UtilisateurEditComponent,
    UtilisateurListComponent,
  ],
  entryComponents: [
    UtilisateurEditComponent
  ]
})
export class UtilisateurPagesModule { }
