<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="m-2 p-3">
        <!-- Informations générales s1 -->
        <div class="card card-box shadow-none">
            <div class="card-header p-3 bg-light">
                <div class="card-header--title">Informations générales</div>
            </div>
            <div class="card-body p-3">
                <div class="form-row">
                    <div class="col-md-12">
                        <div class="position-relative form-group">
                            <label for="ordre">Ordre du jour: <span class="text-danger mr-2">*</span></label>
                            <input type="text" class="form-control" id="ordre" formControlName="ordre" placeholder="Ordre du jour"
                            [ngClass]="{ 'is-invalid': submit && f.ordre.errors, 'is-valid': submit && f.ordre.valid }">
                            <div class="invalid-feedback" *ngIf="submit && f.ordre.errors">
                                <div *ngIf="f.ordre.errors.required">{{required.novide}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <label for="dateDebut">Date de début: <span class="text-danger mr-2">*</span></label>
                            <div class="input-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                </div>
                                <input type="datetime-local" id="dateDebut" formControlName="dateDebut" class="form-control"
                                placeholder="Date début de la reunion"
                                [ngClass]="{ 'is-invalid': submit && f.dateDebut.errors, 'is-valid': submit && f.dateDebut.valid }">
                                <div class="invalid-feedback" *ngIf="submit && f.dateDebut.errors">
                                    <div *ngIf="f.dateDebut.errors.required">{{required.novide}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <label for="dateFin">Date de fin: <span class="text-danger mr-2">*</span></label>
                            <div class="input-group input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                                </div>
                                <input type="datetime-local" id="dateFin" formControlName="dateFin" class="form-control"
                                placeholder="Date de fin de la reunion"
                                [ngClass]="{ 'is-invalid': submit && f.dateFin.errors, 'is-valid': submit && f.dateFin.valid }">
                                <div class="invalid-feedback" *ngIf="submit && f.dateFin.errors">
                                    <div *ngIf="f.dateFin.errors.required">{{required.novide}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="position-relative form-group">
                            <label for="fichier">Joinder un fichier: </label>
                            <div class="custom-file">
                                <input type="file" id="fichier" formControlName="fichier" class="custom-file-input" title="Joindre un fichier" (change)="onSelectImg($event)" multiple>
                                <label class="custom-file-label" for="fichier">Joinder un fichier</label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr *ngIf="imgUrl.length > 0">
                <div class="d-flex">
                    <a class="card-img-wrapper shadow-sm rounded-sm mr-2" *ngFor="let img of imgUrl"  style="height: 100px; width: 100px;">
                        <div class="img-wrapper-overlay">
                            <div class="overlay-btn-wrapper">
                                <button class="btn btn-danger p-0 d-50 rounded-circle" type="button" (click)="deleteImg(img)">
                                    <span class="btn-wrapper--icon">
                                        <i class="ion-md-trash"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <img alt="..." class="card-img-top rounded-sm" [src]="img" style="height: 100px; width: 100px;">
                    </a>
                </div>
                <hr *ngIf="imgUrl.length > 0">
                <div class="form-row">
                    <div class="col-md-12">
                        <div class="position-relative form-group mt-2">
                            <div class="custom-checkbox custom-control">
                                <input class="custom-control-input" id="journee" formControlName="journee" type="checkbox">
                                <label class="custom-control-label" for="journee">Journée (A coché dans le  case ou la reunion prendra toute la journée)</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col">
                        <div class="position-relative form-group">
                            <label for="description">Description: </label>
                            <textarea id="description" formControlName="description" class="form-control" rows="4" placeholder="Description"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        
        <!-- Option d'association s2 -->
        <accordion class="accordion shadow-none" [closeOthers]="true" [isAnimated]="true">
            <accordion-group class="card card-box shadow-none">
                <button accordion-heading class="btn btn-link d-flex align-items-center justify-content-between" type="button">
                    <span><i class="pe-7s-users nav-icon-wrapper text-primary font-size-lg mr-2"></i>Associer des participants à la réunion</span>
                    <fa-icon [icon]="['fas', 'angle-up']"></fa-icon>
                </button>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item py-3">
                        <div class="d-flex justify-content-between">
                            <div>
                                <input type="search" class="form-control" placeholder="Recherche dans la liste des intervenants">
                            </div>
                            <div class="ml-3 text-right">
                                <button (click)="newParticipant.show()" 
                                    class="btn btn-transition-none btn-success" 
                                    placement="auto" type="button"
                                    tooltip="Ajouter un nouvel intervenant">
                                    <i class="fas fa-plus-circle"></i> Nouvel intervenant
                                </button>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-3">
                        <div class="d-flex align-items-center flex-column flex-sm-row">
                            <div class="avatar-icon-wrapper mr-1">
                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                            </div>
                            <div class="pl-0 pl-sm-4">
                                <div class="d-block text-center d-sm-flex align-items-center">
                                    <span class="font-size-lg">Kouassi ghislain</span>
                                </div>
                                <p class="text-black-50 mb-0">Chef de chantier</p>
                            </div>
                            <div class="mt-3 mt-sm-0 ml-sm-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="intervenantA1" type="checkbox">
                                    <label class="custom-control-label" for="intervenantA1">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-3">
                        <div class="d-flex align-items-center flex-column flex-sm-row">
                            <div class="avatar-icon-wrapper mr-1">
                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                            </div>
                            <div class="pl-0 pl-sm-4">
                                <div class="d-block text-center d-sm-flex align-items-center">
                                    <span class="font-size-lg">Kouassi ghislain</span>
                                </div>
                                <p class="text-black-50 mb-0">Chef de chantier</p>
                            </div>
                            <div class="mt-3 mt-sm-0 ml-sm-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="intervenantA2" type="checkbox">
                                    <label class="custom-control-label" for="intervenantA2">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-3">
                        <div class="d-flex align-items-center flex-column flex-sm-row">
                            <div class="avatar-icon-wrapper mr-1">
                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                            </div>
                            <div class="pl-0 pl-sm-4">
                                <div class="d-block text-center d-sm-flex align-items-center">
                                    <span class="font-size-lg">Kouassi ghislain</span>
                                </div>
                                <p class="text-black-50 mb-0">Chef de chantier</p>
                            </div>
                            <div class="mt-3 mt-sm-0 ml-sm-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="intervenantA3" type="checkbox">
                                    <label class="custom-control-label" for="intervenantA3">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item py-3">
                        <div class="d-flex align-items-center flex-column flex-sm-row">
                            <div class="avatar-icon-wrapper mr-1">
                                <div class="avatar-icon"><img alt="..." src="assets/images/avatars/avatar2.jpg"/></div>
                            </div>
                            <div class="pl-0 pl-sm-4">
                                <div class="d-block text-center d-sm-flex align-items-center">
                                    <span class="font-size-lg">Kouassi ghislain</span>
                                </div>
                                <p class="text-black-50 mb-0">Chef de chantier</p>
                            </div>
                            <div class="mt-3 mt-sm-0 ml-sm-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="intervenantA4" type="checkbox">
                                    <label class="custom-control-label" for="intervenantA4">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </accordion-group>
        </accordion>
    </div>
    <hr class="m-0">
    <div class="d-flex justify-content-between py-2 px-3 mx-2">
        <p class="text-danger mt-2">L'étoile (*) signifie que cet champ est obligatoire.</p>
        <div class="text-right">
            <button class="btn btn-first mr-2" type="button" (click)="form.reset()"><i class="fas fa-broom  mr-2"></i> Nettoyer</button>
            <button  class="btn btn-success" type="submit"><i class="fas fa-save mr-2"></i> Enregistrer</button>
        </div>
    </div>
</form>

<!-- Autre participant -->
<div #newParticipant="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered rounded">
        <div class="modal-content rounded border-0">
            <app-intervenant-edit></app-intervenant-edit>
        </div>
    </div>
</div>