import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { stringify } from 'querystring';
import { BehaviorSubject,throwError } from 'rxjs';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';



interface typeProjetData {
  id?: string,
  libelle: string,
}

@Injectable({
  providedIn: 'root'
})
export class TypeProjetService {

  apiUrl = environment.apiUrl;
  prefix = environment.prefix;

  constructor(private http:HttpClient) { }

  listTypeProjet(){
    return this.http.get<typeProjetData[]>(
      `${this.apiUrl}/type_projets`,{
        responseType: 'json'
      }
    ).pipe(
      map((response: any) => response['hydra:member']),
      catchError(this.handleError)
    )
  }

  addTypeProjet(data:typeProjetData){
    let responseData:typeProjetData;
        return this.http.post(`${this.apiUrl}/type_projets`,JSON.stringify(data)).pipe(
        tap( resData => {      
          return resData
        }),
        catchError(this.handleError)
      );//pipe   
  }

  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "Une erreur est survenue";
    return throwError(messageError);
  }
}
