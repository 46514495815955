import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BulletinHse } from 'src/app/models/bulletin-hse';

@Injectable({
  providedIn: 'root'
})
export class BulletinHseService {
  bulletin: BulletinHse;
  private readonly url = "api/bulletin-hse.json";

  constructor(
    private http: HttpClient
  ) { }

  setBulletin(bulletin: BulletinHse) {
    this.bulletin = bulletin
  }

  getBulletin(): BulletinHse {
    return this.bulletin
  }

  getList(): Observable<BulletinHse[]> {
    return this.http.get<BulletinHse[]>(this.url).pipe();
  }
}
