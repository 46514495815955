import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-ressource-dashboard',
  templateUrl: './ressource-dashboard.component.html',
  styleUrls: ['./ressource-dashboard.component.scss']
})
export class RessourceDashboardComponent implements OnInit {

  constructor(public globals: ThemeOptions) { }

  ngOnInit() {
  }

}
