import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-commande-list',
  templateUrl: './commande-list.component.html',
  styleUrls: ['./commande-list.component.scss']
})
export class CommandeListComponent implements OnInit {
  commandStatus = "liste";
  rotate = true;

  // Tout les variables ou tableau ayants pour prefix "default" servent a faire des duplication dans le template.
  defaultRow1 = [
    { line: 1, etatValidation: "validée", etatLivraison: "livrée", fValidateur: "Chef projet" },
    { line: 2, etatValidation: "En attente", etatLivraison: "En attente", fValidateur: "Chef chantier" },
    { line: 3, etatValidation: "non validée", etatLivraison: "livrée", fValidateur: "Chef projet" },
  ];

  constructor() { }

  ngOnInit() {
  }
  changeCommandStatus(status: string){
    this.commandStatus = status;
  }

}
