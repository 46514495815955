import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap,map, take } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { User } from './models/user.model';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { UserCurrent } from './models/userCurrent.model';

export interface AuthResponseData {
  token: string;
  username: string;
  //refreshToken: string;
  expiresIn: string;
  localId: string;
  //registered?: boolean;
}



@Injectable({
  providedIn: 'root'
})
export class AuthPagesService {
  userSubject = new BehaviorSubject<User>(null);
  tokenExpirationTimer: any;
  apiUrl = environment.apiUrl;

  constructor(private __http: HttpClient, private router: Router) { }


  login(username: string, password: string) {
    return this.__http.post<AuthResponseData>(this.apiUrl + "/login_check", {
      username: username,
      password: password,
    }).pipe(
      tap(resData => {
        this.handleAuthentication(resData.username, resData.token, resData.expiresIn, resData.localId)
        //this.getUserCurrent()
      }),
      catchError(this.handleError)
    );
  }

  handleAuthentication(username: string, token: string, expireToken: string, idLocal: string) {
    let expirationDateToken = new Date(new Date().getTime() + +expireToken * 1000);
    const user = new User(username, idLocal, token, expirationDateToken);
    console.log(user, "user");
    this.userSubject.next(user);
    localStorage.setItem("userData", JSON.stringify(user));
    const expirationToken = (+expireToken * 1000);
    this.autoLogout(expirationToken);
  }

  autoLogout(expirationToken) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout()
    }, expirationToken)
  }

  logout() {
    this.userSubject.next(null);
    this.router.navigate(["/auth"]);
    localStorage.removeItem("userData");
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  handleError(errorMessage: HttpErrorResponse) {
    let messageError = "An Unknown error";
    if (!errorMessage.error) {
      return throwError(messageError);
    }
    switch (errorMessage.error.message) {
      case "EMAIL_EXISTS":
        messageError = "Sorry email already exist";
        break;

      case "EMAIL_NOT_FOUND":
        messageError = "email not exist";
        break;

      case "Invalid credentials.":
        messageError = "Vos identifiants sont invalides";
        break;
      case "USER_DISABLED":
        messageError = "Utilisateur desactive";
        break;
    }
    return throwError(messageError);
  }

  autoLogin() {
    const userData: {
      email: string,
      localId: string,
      _token: string,
      expirationDateToken: string
    } = JSON.parse(localStorage.getItem("userData"));

    if (!userData) {
      return;
    }
    const loadedUser = new User(userData.email, userData.localId, userData._token, new Date(userData.expirationDateToken));
    if (loadedUser.token) {
      this.userSubject.next(loadedUser)
      let expirationToken = new Date(userData.expirationDateToken).getTime() - new Date().getTime()
      this.autoLogout(expirationToken);
    }
  }

  getUserCurrent() {
    let userData = JSON.parse(localStorage.getItem("userData"))
    return this.__http.get<UserCurrent>(this.apiUrl + "/utilisateurs/" + userData.localId).pipe(
      tap(resData => {
        console.log(resData, "resData");
        return resData;
      })
    );
  }

  get token(){
    return this.userSubject.asObservable().pipe(
      take(1),
      map(user => {
        if(user){
          return user.token
        }
        else{
          return null;
        }
      })
    );
  }


}
