import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { Console } from 'console';
import { Subscription } from 'rxjs';

import { RessourcePagesService } from 'src/app/services/ressource-pages/ressource-pages.service';
import { ThemeOptions } from 'src/app/theme-options';
import Swal from 'sweetalert2';
import { RessourceEditComponent } from '../ressource-edit/ressource-edit.component';
import { Ressource } from '../ressource.model';

import { RessourceService } from '../ressource.service';

@Component({
  selector: 'app-ressource-list',
  templateUrl: './ressource-list.component.html',
  styleUrls: ['./ressource-list.component.scss']
})
export class RessourceListComponent implements OnInit, OnDestroy {
  listRessources: any = [];
  rotate = true;
  rowExpend = false;
  errMsg = "";
  ressourceSubs = new Subscription;
  ressourceDeleteSubs = new Subscription;
  private notif: NotifierService;


  constructor(
    public globals: ThemeOptions,
    private modalService: NgbModal,
    notifierService: NotifierService,
    private ressourceService: RessourceService
  ) {
    this.notif = notifierService;
    // this.ressourceService.getList().subscribe({
    //   next: lesRessources => this.ressources = lesRessources,
    //   error: err => this.errMsg = err
    // });
  }

  ngOnInit(): void {

    // this.notifierService.notify('success', 'You are awesome! I mean it!');
    this.loadRessource();
    this.showNotification('success', "L'opération s'est bien déroulé");
  }
  ngOnDestroy(): void {
    this.ressourceSubs.unsubscribe();
    this.ressourceDeleteSubs.unsubscribe();
  }

  togglerowExpend() {
    this.rowExpend = !this.rowExpend;
  }

  sendData(donnee, bool) {
    const donneeSend = {
      ressource: donnee,
      type: bool
    };
    this.ressourceService.setDataForRessource(donneeSend);
  }

  loadRessource() {
    this.ressourceSubs = this.ressourceService.listerRessource().subscribe(
      resData => {
        if (resData) {
          this.listRessources = resData
          return this.listRessources;

        }
      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );


  }

  addRessoure(donnee?, action?): any {
    this.__test();
    // notifierService.notify('success', 'You are awesome! I mean it!');
    this.showNotification('success', "L'opération s'est bien déroulé");
    if (donnee) {
      this.sendData(donnee, action);
    }
    this.modalService.dismissAll();
    // this.chambreService.edit = false;
    this.modal(RessourceEditComponent, 'modal-basic-title', 'xl', false, 'static');
  }


  modal(component, type, size, center, backdrop): any {
    this.modalService.open(component, {
      ariaLabelledBy: type,
      size: size,
      centered: center,
      backdrop: backdrop
    }).result.then((result) => {
      console.log('result', result);
      if (result == 'success') {
        this.loadRessource();
        this.showNotification('success', "L'opération s'est bien déroulé");
        // this.loadUser();
      } else if(result == 'update') {
        this.loadRessource();
        this.showNotification('success', "L'opération s'est bien déroulé");
      }

    }, (reason) => { });
  }

  /**
    * Show a notification
    */
  showNotification(type: string, message: string): void {
    this.notif.notify(type, message);
  }

  __test() {

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      customClass: {
        popup: 'colored-toast',
        icon: 'colored-toast'
      },
      showConfirmButton: false,
      timer: 3000
    });


    Toast.fire({
      type: 'info',
      title: 'Signed in successfully'
    })

  }
  deleteRessource(id) {
    console.log('id to delete', id);
    this.ressourceDeleteSubs = this.ressourceService.deleteRessource(id).subscribe(
      resData => {
        this.showNotification('success', "L'opération s'est bien déroulé");
        this.loadRessource();
        // if(resData) {
        //   this.showNotification('success', "L'opération s'est bien déroulé");
        // } 

      },
      errorRes => {
        this.showNotification('danger', "L'opération a échoué");
      }
    );
  }

}
