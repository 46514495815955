import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-etude-prix-list',
  templateUrl: './etude-prix-list.component.html',
  styleUrls: ['./etude-prix-list.component.scss']
})
export class EtudePrixListComponent implements OnInit {
  rotate = true;

  constructor(public globals: ThemeOptions) { }

  ngOnInit() {
  }

}
