import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthPagesService } from './auth-pages.service';
import { exhaustMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private __authSrv: AuthPagesService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {


    return this.__authSrv.userSubject.pipe(
      take(1),
      exhaustMap(user => {
        console.log(user, "intercepteur");
        if (!user) {
          return next.handle(req)
        }
        const modifiedReq = req.clone({
          // params: new HttpParams().set("Bearer ", user.token),
          headers: new HttpHeaders()
                  .set("Authorization", "Bearer " + user.token)  
                  .append('Content-Type','application/json')
        })
        return next.handle(modifiedReq);
      })
    );
  }
}
