import { Component, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-situation-hse-dashboard',
  templateUrl: './situation-hse-dashboard.component.html',
  styleUrls: ['./situation-hse-dashboard.component.scss']
})
export class SituationHseDashboardComponent implements OnInit {
  visitCardHeaderTitle = true;
  reunionCardHeaderTitle = true;
  rotate = false;

  constructor(public globals: ThemeOptions) { }

  ngOnInit() {
  }

}
