import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import LocaleFr from '@angular/common/locales/fr';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule ,registerLocaleData } from '@angular/common';

import { TresoreriePagesRoutingModule } from './tresorerie-pages-routing.module';
import { TresoreriePagesComponent } from './tresorerie-pages.component';
import { TresorerieDetailComponent } from './tresorerie-detail/tresorerie-detail.component';
import { TresorerieEditComponent } from './tresorerie-edit/tresorerie-edit.component';
import { TresorerieListComponent } from './tresorerie-list/tresorerie-list.component';

import { AccordionModule, AlertModule, BsDatepickerModule, BsDropdownModule, ModalModule, PaginationModule, ProgressbarModule, TabsModule, TooltipModule } from 'ngx-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CountUpModule } from 'countup.js-angular2';
import { TextMaskModule } from 'angular2-text-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
registerLocaleData(LocaleFr, 'fr')

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'uifort',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
     easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
     easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [
    TresoreriePagesComponent,
    TresorerieDetailComponent,
    TresorerieEditComponent,
    TresorerieListComponent,
  ],
  imports: [
    CommonModule,
    CountUpModule,
    ChartsModule,
    TresoreriePagesRoutingModule,
    TabsModule,
    TextMaskModule,
    TooltipModule,
    HttpClientModule,
    FormsModule,
    FontAwesomeModule,
    RouterModule,
    ReactiveFormsModule,
    ModalModule,
    BsDatepickerModule,
    AlertModule,
    PaginationModule,
    ProgressbarModule,
    NgbModule,
    NgxGaugeModule,
    NgApexchartsModule,
    NotifierModule.withConfig(customNotifierOptions),
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    
  ],
  entryComponents: [
    TresorerieEditComponent,
  ],
})
export class TresoreriePagesModule { }
