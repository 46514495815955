import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { environment } from './../../../environments/environment';
import { catchError,switchMap, take, map,tap } from 'rxjs/operators';
import { Utilisateur } from '../utilisateur-pages/utilisateur.model';
import { Opportunite } from './opportunite.model';

interface OpportuniteData {
    id: string,
    libelle: string,
    numeroOrdre: string,
    numeroDossier: string,
    numeroOffre: string,
    maitreOuvrage?: object | string,
    bailleur: string,
    responsableSuivi: string,
    personneNotifer: string,
    dateLancement: string,
    delaiExecution: string,
    montant: number,
    groupement: string,
    adresseAutorite: string,
    etat: string,
    utilisateur?: Array<Utilisateur>,
    auteur?: Utilisateur,
    published: string,
    modified: string,
    dateOuverture?: Date,
    dateLimite: string,
}

@Injectable({
  providedIn: 'root'
})
export class OpportuniteService {
    apiUrl = environment.apiUrl;
    prefix = environment.prefix;
    opportunite: OpportuniteData;
    public edit: boolean = false;
    private assignData;

    constructor(private http:HttpClient) { }   

    setOpportunite(opportunite) {
        this.assignData = opportunite;
    }
    
    getOpportunite() {
        return this.assignData;
    }
     
    create(data:OpportuniteData){
        return this.http.post<OpportuniteData>(`${this.apiUrl}/opportunites`,JSON.stringify(data)).pipe(
            take(1),
            tap( resData => {return resData}),
            catchError(this.handleError)
        );   
    }

    update(id: number, data:OpportuniteData){
        return this.http.put<OpportuniteData>(`${this.apiUrl}/opportunites/${id}`,JSON.stringify(data)).pipe(
            take(1),
            tap( resData => {return resData}),
            catchError(this.handleError)
        );  
    }   

    getList(){
        return this.http.get<OpportuniteData[]>(`${this.apiUrl}/opportunites`,{ responseType: 'json' }).pipe(
            map((response: any) => response['hydra:member']),
            catchError(this.handleError)
        )
    }

    getDelete(id: number){
        return this.http.delete<OpportuniteData[]>(`${this.apiUrl}/opportunites/${id}`,{ responseType: 'json' }).pipe(
            take(1),
            switchMap( res =>{return res['hydra:member'];}),
            catchError(this.handleError)
        )
    }  

    handleError(errorMessage: HttpErrorResponse) {
        let messageError = "Une erreur est survenue";
        return throwError(messageError);
    }

}

