<div class="p-3">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="d-flex justify-content-between mb-3">
            <div><h4 class="font-weight-bold m-0">Informations générales</h4></div>
        </div><hr>
        <div class="form-row">
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="type">Type: <span class="text-danger ml-2">*</span></label>
                    <div class="input-group">
                        <select id="type" formControlName="type" class="form-control" *ngIf="inputGroup == false" [ngClass]="{ 'is-invalid': submit && f.type.errors, 'is-valid': submit && f.type.valid }">
                            <option *ngFor="let type of typeBulletin" [value]="type.value">{{type.label}}</option>
                        </select>

                        <input *ngIf="inputGroup == true" id="type" formControlName="type" class="form-control"
                            placeholder="Type" type="text"
                            [ngClass]="{ 'is-invalid': submit && f.type.errors, 'is-valid': submit && f.type.valid }"
                        >
                        <button tooltip="{{inputGroup == true ? 'Clique pour choisir' : 'Clique pour saisir' }}" placement="auto" (click)="inputGroup = !inputGroup" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button">
                            <span class="btn-wrapper--icon">
                                <i class="{{inputGroup == true ? 'fas fa-hand-pointer' : 'fas fa-keyboard'}}"></i>
                            </span>
                        </button>
                    </div>

                    <div class="invalid-feedback" *ngIf="submit && f.type.errors">
                        <div *ngIf="f.type.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="objet">Objet: <span class="text-danger ml-2">*</span></label>
                    <input type="text" class="form-control" id="objet" formControlName="objet" placeholder="Objet"
                    [ngClass]="{ 'is-invalid': submit && f.objet.errors, 'is-valid': submit && f.objet.valid }">

                    <div class="invalid-feedback" *ngIf="submit && f.objet.errors">
                        <div *ngIf="f.objet.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="date">Date: <span class="text-danger ml-2">*</span></label>
                    <input type="datetime-local" class="form-control" id="date" formControlName="date" placeholder="Date"
                    [ngClass]="{ 'is-invalid': submit && f.date.errors, 'is-valid': submit && f.date.valid }">

                    <div class="invalid-feedback" *ngIf="submit && f.date.errors">
                        <div *ngIf="f.date.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="projet">Projet: <span class="text-danger ml-2">*</span></label>
                    <ng-select id="projet" formControlName="projet"
                    [ngClass]="{ 'is-invalid': submit && f.projet.errors, 'is-valid': submit && f.projet.valid }">
                        <ng-option value="projet 1">Projet 1</ng-option>
                        <ng-option value="projet 2">Projet 2</ng-option>
                        <ng-option value="projet 3">Projet 3</ng-option>
                    </ng-select>

                    <div class="invalid-feedback" *ngIf="submit && f.projet.errors">
                        <div *ngIf="f.projet.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="position-relative form-group">
                    <label for="chantier">Cantiers concernés: <span class="text-danger ml-2">*</span></label>
                    <ng-select id="chantier" formControlName="chantier"
                    [ngClass]="{ 'is-invalid': submit && f.chantier.errors, 'is-valid': submit && f.chantier.valid }" [multiple]="true">
                        <ng-option value="chantier 1">Chantier 1</ng-option>
                        <ng-option value="chantier 2">Chantier 2</ng-option>
                        <ng-option value="chantier 3">Chantier 3</ng-option>
                    </ng-select>

                    <div class="invalid-feedback" *ngIf="submit && f.status.errors">
                        <div *ngIf="f.status.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-md-6">
                <div class="position-relative form-group">
                    <label for="gravite">Gravite: <span class="text-danger ml-2">*</span></label>
                    <select id="gravite" formControlName="gravite" class="form-control"
                    [ngClass]="{ 'is-invalid': submit && f.gravite.errors, 'is-valid': submit && f.gravite.valid }">
                        <option *ngFor="let gravite of graviteRow" [value]="gravite.value">{{gravite.label}}</option>
                    </select>

                    <div class="invalid-feedback" *ngIf="submit && f.gravite.errors">
                        <div *ngIf="f.gravite.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="position-relative form-group">
                    <label for="photo">Photo: </label>
                    <div class="custom-file">
                        <input type="file" id="photo" formControlName="photo" class="custom-file-input" (change)="onSelectImg($event)" multiple>
                        <label class="custom-file-label" for="photo">Photo</label>
                    </div>
                </div>
            </div>
        </div>
        <hr *ngIf="imgUrl.length > 0">
        <div class="d-flex">
            <a class="card-img-wrapper shadow-sm rounded-sm mr-2" *ngFor="let img of imgUrl"  style="height: 100px; width: 100px;">
                <div class="img-wrapper-overlay">
                    <div class="overlay-btn-wrapper">
                        <button class="btn btn-danger p-0 d-50 rounded-circle" type="button" (click)="deleteImg(img)">
                            <span class="btn-wrapper--icon">
                                <i class="ion-md-trash"></i>
                            </span>
                        </button>
                    </div>
                </div>
                <img alt="..." class="card-img-top rounded-sm" [src]="img" style="height: 100px; width: 100px;">
            </a>
        </div>
        <hr *ngIf="imgUrl.length > 0">
        <div class="form-row">
            <div class="col-md-6">
                <div class="position-relative form-group">
                    <label for="description">Description: </label>
                    <textarea id="description" formControlName="description" class="form-control" placeholder="Entrer le texte de la description" rows="4"></textarea>
                </div>
            </div>
            <div class="col-md-6">
                <div class="position-relative form-group">
                    <label for="descriptionAction">Description et action: </label>
                    <textarea id="descriptionAction" formControlName="descriptionAction" class="form-control" placeholder="Description et action" rows="4"></textarea>
                </div>
            </div>
        </div>
    </form>
</div>
<hr class="m-0">
<div class="text-right px-3 py-0">
    <button class="btn btn-first m-2" type="button" (click)="form.reset()"><i class="fas fa-broom mr-1"></i>Nettoyer</button>
    <button class="btn btn-success" type="submit" (click)="onSubmit()"><i class="fas fa-save mr-1"></i>Enregister</button>
</div>