import { Component, Input, OnInit } from '@angular/core';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'app-tache-list',
  templateUrl: './tache-list.component.html',
  styleUrls: ['./tache-list.component.scss']
})
export class TacheListComponent implements OnInit {
  @Input() widgetView: boolean = false;
  rotate = true;
  list = true;

  vamps = [
      { name: "Bad Vamp" },
      { name: "Petrovitch the Slain" },
      { name: "Bob of the Everglades" },
      { name: "The Optimistic Reaper" }
  ];

  constructor(
    private dragulaService: DragulaService
  ) {
    this.dragulaService.dropModel("VAMPIRES").subscribe(args => { console.log(args);});
  }

  ngOnDestroy(){
    console.log("destroy")
    this.dragulaService.remove;
  }

  ngOnInit() {
  }
  
  // Choix de liste tableau ou carte
  selectType(type:boolean) {
    this.list = type;
  }
}
