<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-attention display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1 class="font-weight-bold m-0">Fiche de l'{{situation?.typeHse}}</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description m-0" title="Référence et libellé de l'{{situation?.typeHse}}">
                    <span><i class="fas fa-file-alt"></i> ( {{situation?.reference}} )</span>
                    <span class="ml-1">{{situation?.libelle}}</span>
                </div>
                <p class="m-0" title="Date de cette fiche d' {{situation?.typeHse}}">
                    <i class="fas fa-calendar-alt"></i>
                    <span class="ml-1">Créer le: {{situation?.dateCreation}}</span>
                </p>
            </div>
        </div>
        <div class="flex-fill text-right">
            <button [routerLink]="['/pages/hse/liste']" class="btn btn-secondary" type="button"><i class="fas fa-arrow-circle-left mr-1"></i>Retour</button>
        </div>
    </div>
</div>

<div class="card">
    <div class="border rounded m-3 p-3">
        <!-- Information générales -->
        <div class="p-0 m-0">
            <hr class="m-0">
            <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Information générales</h4></div>
            <div class="row">
                <div class="col-md-6">
                    <p class="mt-2 mb-0"><b>Type:</b>
                        <span class="ml-2 badge bg-{{situation?.typeHse == 'Incident' ? 'neutral-warning text-warning' : 'neutral-danger text-danger'}}">{{situation?.typeHse}}</span>
                    </p>
                    <hr class="my-2">
                    <p class="m-0">
                        <b>{{situation?.typeHse == 'Incident' ? 'Type d\'incident' : 'Type d\'accident'}}:</b>
                        <span class="ml-2">{{situation?.type}}</span>
                    </p>
                    <hr class="my-2">
                    <p class="m-0"><b>Libellé:</b><span class="ml-2">{{situation?.libelle}}</span></p>
                    <hr class="my-2">
                    <p class="m-0" *ngIf="situation?.typeHse == 'Accident de travail'">
                        <b>Date de l'accident:</b>
                        <span class="ml-2">{{situation?.dateAccident}}</span>
                    </p>
                    <hr class="my-2" *ngIf="situation?.typeHse == 'Accident de travail'">
                    <p class="m-0"><b>Description:</b> <span class="ml-2">{{situation?.description}}</span></p>
                </div>
                <div class="col-md-6">
                    <p class="mt-2 mb-0" *ngIf="situation?.typeHse == 'Accident de travail'">
                        <b>Montant estimé des dommages:</b>
                        <span class="ml-2 badge bg-neutral-first text-first">{{situation?.montant | number:'': 'fr-FR'}}</span>
                    </p>
                    <hr class="my-2" *ngIf="situation?.typeHse == 'Accident de travail'">
                    <p class="m-0"><b>Créer par:</b> <span class="ml-2">{{situation?.creerPar}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de création:</b> <span class="ml-2">{{situation?.dateCreation}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Modifier par:</b> <span class="ml-2">{{situation?.modifierPar}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Date de modification: </b> <span class="ml-2">{{situation?.dateModification}}</span></p>
                </div>
            </div>
            <hr class="mt-2 mb-0">
            <div class="mt-3 border rounded" *ngIf="situation?.typeHse == 'Incident' && situation?.nbImpliquer?.length != 0">
                <div class="px-3 py-2 rounded-top bg-secondary">Personne impliqués <span>({{situation?.nbImpliquer?.length}})</span></div>
                <hr class="m-0">
                <div>
                    <div class="table-responsive m-0">
                        <table class="table table-alternate text-nowrap m-0">
                            <thead>
                                <tr>
                                    <th>N°</th>
                                    <th>Nom et prénom</th>
                                    <th>Contact</th>
                                    <th>Lien avec l'incident</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of situation?.nbImpliquer">
                                    <td>{{item?.id}}</td>
                                    <td>{{item?.nom}}</td>
                                    <td>{{item?.contact}}</td>
                                    <td>{{item?.lien}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="mt-3 border rounded" *ngIf="situation?.typeHse == 'Accident de travail' && situation?.temoin?.length != 0">
                <div class="px-3 py-2 rounded-top bg-secondary">Témoin lors de l'accident <span>({{situation?.temoin?.length}})</span></div>
                <hr class="m-0">
                <div>
                    <div class="table-responsive m-0">
                        <table class="table table-alternate text-nowrap m-0">
                            <thead>
                                <tr>
                                    <th>N°</th>
                                    <th>Nom et prénom</th>
                                    <th>Contact</th>
                                    <th>Lien avec l'incident</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of situation?.temoin">
                                    <td>{{item?.id}}</td>
                                    <td>{{item?.nom}}</td>
                                    <td>{{item?.contact}}</td>
                                    <td>{{item?.fonction}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col" *ngFor="let item of situation?.photo">
                    <div class="my-2">
                        <a class="card-img-wrapper shadow-sm rounded-sm mr-2" style="height: 300px;">
                            <div class="img-wrapper-overlay">
                                <div class="overlay-btn-wrapper">
                                    <button class="btn p-0 d-50 rounded-circle btn-outline-secondary border-2 ml-1" type="button">
                                        <i class="fas fa-expand"></i>
                                    </button>
                                </div>
                            </div>
                            <img src="{{item?.url}}" alt="..." class="card-img-top rounded-sm" style="height: 300px;">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <hr class="mt-0 mb-5">

        <!-- Information sur la victime -->
        <div class="p-0 mb-5" *ngIf="situation?.typeHse == 'Accident de travail'">
            <hr class="m-0">
            <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Information sur la victime</h4></div>
            <div class="row">
                <div class="col-md-6">
                    <p class="mt-2 mb-0"><b>Nom et prénom de la victime:</b><span class="ml-2">{{situation?.nomVictime}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Contact: </b><span class="ml-2">{{situation?.contactVictime}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Fonction: </b><span class="ml-2">{{situation?.fonctionVictime}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Est-il de quelle entreprise ?</b><span class="ml-2">{{situation?.entreprise}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Est-il de absent ?</b><span class="ml-2">{{situation?.absent}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Etait absent à parti du:</b><span class="ml-2">{{situation?.dateAbsence}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Retouner le:</b><span class="ml-2">{{situation?.dateRetour}}</span></p>
                </div>
                <div class="col-md-6">
                    <p class="mt-2 mb-0"><b>Nature de la lésion:</b><span class="ml-2">{{situation?.lesion}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Localisation de la lésion:</b> <span class="ml-2">{{situation?.lieuLesion}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Soins prodigués par:</b> <span class="ml-2">{{situation?.soins}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Nom et prenom:</b> <span class="ml-2">{{situation?.nomSoigneur}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Lieu d'habitation:</b> <span class="ml-2">{{situation?.lieuSoigneur}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Contact:</b> <span class="ml-2">{{situation?.contactSoigneur}}</span></p>
                    <hr class="my-2">
                    <p class="m-0"><b>Fonction: </b> <span class="ml-2">{{situation?.fonctionSoigneur}}</span></p>
                </div>
            </div>
            <hr class="mt-2 mb-0">
            <div class="mt-3 border rounded" *ngIf="situation?.vehicule == 'OUI'">
                <div class="px-3 py-2 rounded-top bg-secondary">Véhicule associé</div>
                <hr class="m-0">
                <div>
                    <div class="table-responsive m-0">
                        <table class="table table-alternate text-nowrap m-0">
                            <thead>
                                <tr>
                                    <th>Marque</th>
                                    <th>Description</th>
                                    <th>Photo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{situation?.marque}}</td>
                                    <td>{{situation?.descriptionVehicule}}</td>
                                    <td>
                                        <div class="d-flex">
                                            <img *ngFor="let item of situation?.photoVehicule" src="{{item?.url}}" alt="" id="td-img" class="mr-2">
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Causes ou diagnostiques -->
        <div class="p-0 m-0">
            <hr class="m-0">
            <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Causes ou diagnostiques</h4></div>
            <div class="mb-5">
                <div class="mt-2 mb-3">
                    <b>Principale règle d'or applicable:</b><br>
                    <textarea class="form-control" rows="4" disabled>{{situation?.regleOr}}</textarea>
                </div>
                <div class="mt-2 mb-3">
                    <b>Actes dangereuses:</b><br>
                    <textarea class="form-control" rows="4" disabled>{{situation?.actes}}</textarea>
                </div>
                <div class="mt-2 mb-3">
                    <b>Circonstances dangereuses:</b><br>
                    <textarea class="form-control" rows="4" disabled>{{situation?.circonstances}}</textarea>
                </div>
                <div class="mt-2 mb-3">
                    <b>Facteurs personnels:</b><br>
                    <textarea class="form-control" rows="4" disabled>{{situation?.facteurPerson}}</textarea>
                </div>
                <div class="mt-2">
                    <b>Facteurs organisationnels:</b><br>
                    <textarea class="form-control" rows="4" disabled>{{situation?.facteurOranisation}}</textarea>
                </div>
            </div>
        </div>

        <!-- Actions ou mesures -->
        <div class="p-0 m-0">
            <hr class="m-0">
            <div class="p-2 bg-secondary"><h4 class="m-0 font-weight-bold text-first">Actions ou mesures</h4></div>
            <div class="row">
                <div class="col-md-6">
                    <p class="m-0"><b>Mesure prise par:</b><span class="ml-2">{{situation?.prisePar}}</span></p>
                    <hr class="my-2">
                </div>
                <div class="col-md-6">
                    <p class="m-0"><b>Date:</b><span class="ml-2">{{situation?.dateAction}}</span></p>
                    <hr class="my-2">
                </div>
            </div>
            <div>
                <div class="mt-2 mb-3">
                    <b>Mesures prises immediatement:</b><br>
                    <textarea class="form-control" rows="4" disabled>{{situation?.immediat}}</textarea>
                </div>
                <div class="mt-2 mb-3">
                    <b>Mesures à prendre ulterieurement:</b><br>
                    <textarea class="form-control" rows="4" disabled>{{situation?.ulterieur}}</textarea>
                </div>
                <div class="mt-2 mb-3">
                    <b>Description:</b><br>
                    <textarea class="form-control" rows="4" disabled>{{situation?.descriptionAction}}</textarea>
                </div>
            </div>
        </div>
    </div>
    <hr class="m-0">
    <div class="text-right mx-3 my-2">
        <button class="btn btn-success mr-2" type="button"><i class="fas fa-check mr-1"></i>Valider</button>
        <button class="btn btn-warning mr-2" type="button"><i class="ion-md-print mr-1"></i>Imprimer</button>
        <button class="btn btn-danger" type="button"><i class="ion-md-trash mr-1"></i>Supprimer</button>
    </div>
</div>