<div [class]="'app-page-title ' + globals.pageTitleStyle + ' ' + globals.pageTitleBackground" [ngClass]="{'app-page-title--shadow' : globals.pageTitleShadow}" *ngIf="!listView">
    <div class="app-page-title--first d-flex justify-content-between">
        <div class="flex-fill d-flex">
            <div *ngIf="globals.pageTitleIconBox" class="app-page-title--iconbox d-70">
                <div class="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i class="pe-7s-attention display-3 gradient-icon bg-ripe-malin"></i>
                </div>
            </div>
            <div class="app-page-title--heading">
                <h1>HSE</h1>
                <div *ngIf="globals.pageTitleDescription" class="app-page-title--description">Hygène Securité Environnement</div>
            </div>
        </div>
        <div class="flex-fill text-right">
            <div class="btn-group" dropdown>
                <button class="btn btn-success dropdown-toggle no-caret" dropdownToggle type="button">
                    <i class="fas fa-plus-circle"></i> Ajouter
                </button>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu overflow-hidden p-0">
                    <ul class="nav nav-pills flex-column">
                        <li class="nav-item" (click)="newHseModal.show()">
                            <a [routerLink]="" class="nav-link"><span>Situation HSE</span></a>
                        </li>
                        <li class="nav-item" (click)="bulletinHseModal.show()">
                            <a [routerLink]="" class="nav-link"><span>Bulletin HSE</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="!listView">
    <div class="col">
        <div class="bg-secondary border p-3 text-center rounded shadow-sm mb-4" title="Nombre total de situation HSE">
            <div>
                <i class="fas fa-exclamation font-size-xxl mb-2 mt-1 text-dark"></i>
            </div>
            <div class="mt-2 line-height-sm">
                <b class="font-size-lg">455</b>
                <span class="text-black-50 d-block">Situation HSE</span>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="bg-secondary border p-3 text-center rounded shadow-sm mb-4" title="Nombre d'accident'">
            <div>
                <i class="fas fa-car-crash font-size-xxl mb-2 mt-1 text-danger"></i>
            </div>
            <div class="mt-2 line-height-sm">
                <b class="font-size-lg">12</b>
                <span class="text-black-50 d-block">Accident</span>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="bg-secondary border p-3 text-center rounded shadow-sm mb-4" title="Nombre d'incident'">
            <div>
                <i class="fas fa-skull-crossbones font-size-xxl mb-2 mt-1 text-warning"></i>
            </div>
            <div class="mt-2 line-height-sm">
                <b class="font-size-lg">12</b>
                <span class="text-black-50 d-block">Incident</span>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="bg-secondary border p-3 text-center rounded shadow-sm mb-4" title="Nombre de bulletin HSE">
            <div>
                <i class="fas fa-file-alt font-size-xxl mb-2 mt-1 text-first"></i>
            </div>
            <div class="mt-2 line-height-sm">
                <b class="font-size-lg">12</b>
                <span class="text-black-50 d-block">Bulletin HSE</span>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="bg-secondary border p-3 text-center rounded shadow-sm mb-4" title="Nombre de bulletin HSEvalidés">
            <div>
                <i class="fas fa-check-circle font-size-xxl mb-2 mt-1 text-success"></i>
            </div>
            <div class="mt-2 line-height-sm">
                <b class="font-size-lg">12</b>
                <span class="text-black-50 d-block">Bulletin validés</span>
            </div>
        </div>
    </div>
</div>

<div class="row mb-3" *ngIf="listView">
    <div class="col">
        <a [routerLink]="" class="card card-box border-0 rounded p-2">
            <div class="d-flex align-items-center mt-1">
                <h1 class="m-0">
                    <i class="fas fa-exclamation img-fluid mx-3 mt-1 text-dark"></i>
                </h1>
                <div>
                    <div class="text-black-50">Total Situation HSE</div>
                    <div class="font-weight-bold display-4 py-1 text-black">
                        <span [countUp]="874"></span>
                    </div>
                    <div class="text-black-50 font-size-sm">
                        <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1 text-warning"></fa-icon>
                        100%
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col">
        <a [routerLink]="" class="card card-box border-0 rounded p-2">
            <div class="d-flex align-items-center mt-1">
                <h1 class="m-0">
                    <i class="fas fa-car-crash img-fluid mx-3 mt-1 text-danger"></i>
                </h1>
                <div>
                    <div class="text-black-50">Accident</div>
                    <div class="font-weight-bold display-4 py-1 text-black">
                        <span [countUp]="874"></span>
                    </div>
                    <div class="text-black-50 font-size-sm">
                        <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1 text-warning"></fa-icon>
                        100%
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col">
        <a [routerLink]="" class="card card-box border-0 rounded p-2">
            <div class="d-flex align-items-center mt-1">
                <h1 class="m-0">
                    <i class="fas fa-exclamation-triangle img-fluid mx-3 mt-1 text-warning"></i>
                </h1>
                <div>
                    <div class="text-black-50">Incident</div>
                    <div class="font-weight-bold display-4 py-1 text-black">
                        <span [countUp]="874"></span>
                    </div>
                    <div class="text-black-50 font-size-sm">
                        <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1 text-warning"></fa-icon>
                        100%
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col">
        <a [routerLink]="" class="card card-box border-0 rounded p-2">
            <div class="d-flex align-items-center mt-1">
                <h1 class="m-0">
                    <i class="fas fa-file-alt img-fluid mx-3 mt-1 text-first"></i>
                </h1>
                <div>
                    <div class="text-black-50">Bulletin HSE</div>
                    <div class="font-weight-bold display-4 py-1 text-black">
                        <span [countUp]="874"></span>
                    </div>
                    <div class="text-black-50 font-size-sm">
                        <fa-icon [icon]="['fas', 'chevron-up']" class="font-size-sm pr-1 text-warning"></fa-icon>
                        100%
                    </div>
                </div>
            </div>
        </a>
    </div>
</div>

<tabset class="tabs-animated tabs-rm-padding tabs-responsive tabs-animated-shadow" type="tabs" >
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span>Situation HSE</span></ng-template>
        <div class="card card-box border-0">
            <div class="card-header p-3">
                <div class="card-header--title">
                    <h6 class="font-weight-bold m-0">Liste des situation HSE</h6>
                </div>
                <div class="card-header--actions">
                    <div class="btn-group" dropdown>
                        <button class="btn btn-link btn-link-second p-0 text-darck dropdown-toggle no-caret mr-2"
                            dropdownToggle
                            tooltip="Action"
                            type="button">
                            <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
                        </button>
                        <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                            <ul class="nav nav-pills flex-column p-3">
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-refresh"></i><span>Récharger</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-download"></i><span>Exporter</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-cloud-upload"></i><span>Importer</span></a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="" class="nav-link"><i class="mr-2 ion-md-print"></i><span>Imprimer</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="d-flex justify-content-between p-3">
                    <div class="search-wrapper">
                      <span class="icon-wrapper text-black"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
                      <input class="form-control" placeholder="Recherche dans la liste" type="search">
                    </div>
                    <div class="text-right">
                        <button class="btn btn-success"
                            type="button" (click)="newHseModal.show()"
                            tooltip="Ajouter une réunion"
                            *ngIf="listView">
                            <i class="fas fa-plus-circle"></i> Ajouter
                        </button>
                    </div>
                </div>
                <div class="table-responsive m-0">
                    <table class="table table-hover text-nowrap m-0">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Libellé</th>
                                <th>Description</th>
                                <th>Date enregistrement</th>
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let situation of situations">
                                <td>{{situation.typeHse}}</td>
                                <td>{{situation.libelle}}</td>
                                <td>{{situation.description}}</td>
                                <td><span class="badge bg-neutral-warning text-warning">{{situation.dateCreation}}</span></td>
                                <td class="text-center">
                                    <button placement="auto" [routerLink]="['/pages/hse/detail',situation?.id]" tooltip="Plus d'infos"  class="btn d-40 p-0 btn-pill mr-1 btn-primary" type="button">
                                        <span class="btn-wrapper--icon"><i class="ion-md-eye"></i></span>
                                    </button>
                                    <button class="btn d-40 p-0 btn-pill mr-2 btn-info" placement="auto" tooltip="Modifier" type="button">
                                        <span class="btn-wrapper--icon"><i class="fas fa-pen"></i></span>
                                    </button>
                                    <button class="btn d-40 p-0 btn-pill mr-2 btn-warning" placement="auto" tooltip="Imprimer" type="button">
                                        <span class="btn-wrapper--icon"><i class="ion-md-print"></i></span>
                                    </button>
                                    <button  placement="auto"  tooltip="Supprimer"  class="btn d-40 p-0 btn-pill btn-danger" type="button">
                                        <span class="btn-wrapper--icon"><i class="ion-md-trash"></i></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer p-3">
                <div class="d-flex justify-content-between">
                    <pagination [rotate]="rotate" [totalItems]="30" class="pagination-icons pagination-primary"></pagination>
                    <div class="d-flex align-items-center">
                        <span>Show</span>
                        <select class="mx-1 form-control form-control-sm">
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="40">50</option>
                            <option value="40">100</option>
                        </select>
                        <span>entries</span>
                    </div>
                </div>
            </div>
        </div>
    </tab>
    <tab customClass="pl-0 pr-1 py-2">
        <ng-template tabHeading><span>Bulletin HSE</span></ng-template>
        <app-bulletin-hse-list></app-bulletin-hse-list>
    </tab>
</tabset>

<!-- Modal de nouvelle incident hse -->
<div #newHseModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Formulaire de création d'incident hse / Accident de travail</h6>
                <button type="button" class="close" aria-label="Close" (click)="newHseModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <app-situation-hse-edit></app-situation-hse-edit>
            </div>
        </div>
    </div>
</div>

<!-- Modal Bulletin -->
<div #bulletinHseModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Formulaire de création de bulletin HSE</h6>
                <button type="button" class="close" aria-label="Close" (click)="bulletinHseModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <app-bulletin-hse-edit></app-bulletin-hse-edit>
            </div>
        </div>
    </div>
</div>

<!-- Modal detail bulletin hse -->
<div #singleBulletinModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content ">
            <div class="modal-header modal-dark bg-plum-plate rounded-top">
                <h6 class="modal-title">Fiche du bulletin HSE</h6>
                <button type="button" class="close" aria-label="Close" (click)="singleBulletinModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="border rounded m-3 p-3">Detail</div>
                <hr class="m-0">
                <div class="text-right mx-3 my-2">
                    <button class="btn btn-success mr-2" type="button"><i class="fas fa-check mr-1"></i>Valider</button>
                    <button class="btn btn-warning mr-2" type="button"><i class="ion-md-print mr-1"></i>Imprimer</button>
                    <button class="btn btn-danger" type="button"><i class="ion-md-trash mr-1"></i>Supprimer</button>
                </div>
            </div>
        </div>
    </div>
</div>