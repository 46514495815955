<div class="p-3">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="border rounded p-3">
            <div class="form-row">
                <div class="col-md-12">
                    <div class="position-relative form-group">
                        <label for="libelle">Libellé: <span class="text-danger mr-2">*</span></label>
                        <input type="text" id="libelle" formControlName="libelle" class="form-control" placeholder="Libellé"
                        [ngClass]="{ 'is-invalid': submit && f.libelle.errors, 'is-valid': submit && f.libelle.valid }">
                        <div class="invalid-feedback" *ngIf="submit && f.libelle.errors">
                            <div *ngIf="f.libelle.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-12">
                    <div class="position-relative form-group">
                        <label for="montant">Montant: <span class="text-danger mr-2">*</span></label>
                        <input type="number" min="0" id="montant" formControlName="montant" class="form-control" placeholder="Montant"
                        [ngClass]="{ 'is-invalid': submit && f.montant.errors, 'is-valid': submit && f.montant.valid }">
                        <div class="invalid-feedback" *ngIf="submit && f.montant.errors">
                            <div *ngIf="f.montant.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-12">
                    <div class="position-relative form-group">
                        <label for="projet">Projet: <span class="text-danger mr-2">*</span></label>
                        <select id="projet" formControlName="projet" class="form-control"
                        [ngClass]="{ 'is-invalid': submit && f.projet.errors, 'is-valid': submit && f.projet.valid }">
                            <option value="projet 1">Projet 1</option>
                            <option value="projet 2">Projet 2</option>
                            <option value="projet 3">Projet 3</option>
                        </select>
                        <div class="invalid-feedback" *ngIf="submit && f.projet.errors">
                            <div *ngIf="f.projet.errors.required">{{required.novide}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <accordion class="accordion shadow-none mb-2" [closeOthers]="true" [isAnimated]="true">
                <accordion-group class="card card-box shadow-none">
                    <button accordion-heading class="btn btn-link d-flex align-items-center justify-content-between" type="button">
                        <span>
                            <i class="pe-7s-box2 nav-icon-wrapper text-primary font-size-lg mr-2"></i>
                            Choisir le chantier
                        </span>
                        <fa-icon [icon]="['fas', 'angle-up']"></fa-icon>
                    </button>
                    <div class="list-group list-group-flush">
                        <li class="list-group-item">
                            <input type="search" class="form-control" placeholder="Recherche dans la liste des chantier">
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <div>
                                <div>Chantier 1</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="chantier1" type="checkbox">
                                    <label class="custom-control-label" for="chantier1">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <div>
                                <div>Chantier 2</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="chantier2" type="checkbox">
                                    <label class="custom-control-label" for="chantier2">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item list-group-item-action align-box-row">
                            <div>
                                <div>Chantier 3</div>
                            </div>
                            <div class="ml-auto">
                                <div class="custom-checkbox custom-control align-self-start">
                                    <input class="custom-control-input" id="chantier3" type="checkbox">
                                    <label class="custom-control-label" for="chantier3">&nbsp;</label>
                                </div>
                            </div>
                        </li>
                    </div>
                </accordion-group>
            </accordion>

            <div class="form-row">
                <div class="col-md-12">
                    <div class="position-relative form-group mb-0">
                        <label for="description">Description: </label>
                        <textarea id="description" formControlName="description" class="form-control" placeholder="Description" rows="4"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<hr class="m-0">
<div class="text-right px-3 py-0">
    <button class="btn btn-first m-2" type="button" (click)="form.reset()"><i class="fas fa-broom mr-1"></i>Nettoyer</button>
    <button class="btn btn-success" type="submit" (click)="onSubmit()"><i class="fas fa-save mr-1"></i>Enregister</button>
</div>
            