import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fichier-list',
  templateUrl: './fichier-list.component.html',
  styleUrls: ['./fichier-list.component.scss']
})
export class FichierListComponent implements OnInit {
  menuItem: string = "tous";
  toggleMobileSidebar1: boolean = false;
  toggleMobileSidebar2: boolean = false;

  itemRow = [
    'item1',
    'item2',
    'item3',
    'item4',
    'item5',
  ]

  constructor() { }

  ngOnInit() {
  }

  showMenuElement(element: string){
    this.menuItem = element;
  }

}
