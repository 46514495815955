<div class="app-sidebar--header">
    <div class="nav-logo">
        <a routerLink="/dashboard-default" title="Bamburgh Angular Admin Dashboard with NGX Bootstrap PRO">
            <i>
                <img alt="Bamburgh Angular Admin Dashboard with NGX Bootstrap PRO" src="assets/img/logo-ARCHIPRO-V3.png">
            </i>
            <span>Archipro v3.0</span>
        </a>
    </div>
    <button (click)="toggleSidebarCollapse()" class="btn btn-sm collapse-sidebar-btn" placement="right" tooltip="Collapse sidebar">
        <fa-icon [icon]="['far', 'dot-circle']" size="lg"></fa-icon>
    </button>
    <button (click)="toggleSidebarMobileOpen()" [ngClass]="{'is-active' : globals.toggleSidebarMobile}" class="navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn" type="button">
    <span class="hamburger-box">
        <span class="hamburger-inner"></span>
    </span>
    </button>
    <button (click)="toggleSidebarCollapse()" class="expand-sidebar-btn btn btn-sm" placement="right" tooltip="Expand sidebar" type="button">
        <fa-icon [icon]="['fas', 'arrows-alt-h']"></fa-icon>
    </button>
</div>
