import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tresorerie-pages',
  templateUrl: './tresorerie-pages.component.html',
  styleUrls: ['./tresorerie-pages.component.scss']
})
export class TresoreriePagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
