import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ToastUiImageEditorComponent } from 'ngx-tui-image-editor';

@Component({
  selector: 'app-images-editor',
  templateUrl: './images-editor.component.html',
  styleUrls: ['./images-editor.component.scss']
})
export class ImagesEditorComponent implements OnInit, AfterViewInit {

  @ViewChild(ToastUiImageEditorComponent, {static: false}) editorComponent: ToastUiImageEditorComponent;

  constructor() { }

  ngOnInit() {
  }
 
  ngAfterViewInit() {
    console.log(this.editorComponent.editorInstance);
  }

}
