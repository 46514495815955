<div class="modal-header modal-dark  bg-plum-plate">
    <h5 class="modal-title">{{ title }}</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="p-4">
        <!-- Informations générales -->
        <div class="d-flex justify-content-between mb-3">
            <div><h4 class="font-weight-bold m-0">Informations générales</h4></div>
        </div><hr>
        <div class="form-row">
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="libelle">Libellé <span class="text-danger">*</span></label>
                    <input class="form-control"
                    id="libelle" formControlName="libelle"
                    placeholder="Libellé de l'opportunité" type="text"
                    [ngClass]="{ 'is-invalid': submit && f.libelle.errors }">
                    <div *ngIf="submit && f.libelle.errors" class="invalid-feedback">
                        <div *ngIf="f.libelle.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="numeroOrdre">Numéro d'ordre <span class="text-danger">*</span></label>
                    <input class="form-control"
                    id="numeroOrdre" formControlName="numeroOrdre"
                    placeholder="Numéro d'ordre" type="text"
                    [ngClass]="{ 'is-invalid': submit && f.numeroOrdre.errors }">
                    <div *ngIf="submit && f.numeroOrdre.errors" class="invalid-feedback">
                        <div *ngIf="f.numeroOrdre.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="numeroDossier">Numéro de dossier <span class="text-danger">*</span></label>
                    <input class="form-control"
                    id="numeroDossier" formControlName="numeroDossier"
                    placeholder="Numéro de dossier" type="text"
                    [ngClass]="{ 'is-invalid': submit && f.numeroDossier.errors }">
                    <div *ngIf="submit && f.numeroDossier.errors" class="invalid-feedback">
                        <div *ngIf="f.numeroDossier.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="numeroOffre">Numéro appel d'offre <span class="text-danger">*</span></label>
                    <input class="form-control"
                        id="numeroOffre" formControlName="numeroOffre"
                        placeholder="Numéro appel d'offre" type="text"
                        [ngClass]="{ 'is-invalid': submit && f.numeroOffre.errors }"
                    >
                    <div *ngIf="submit && f.numeroOffre.errors" class="invalid-feedback">
                        <div *ngIf="f.numeroOffre.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="maitreOuvrage">Maitre d'ouvrage</label>
                    <div class="input-group">
                        <select  class="form-control" id="maitreOuvrage" formControlName="maitreOuvrage">
                            <option *ngFor="let item of listeMaitre" [ngValue]="item['@id']">{{ item?.utilisateur['nom'] }} {{ item?.utilisateur['prenom'] }}</option>
                        </select>
                        <button tooltip="Ajouter un maitre d'ouvrage" placement="auto" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button" (click)="newMoModal.show()">
                            <span class="btn-wrapper--icon">
                                <i class="ion-md-add"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="bailleur">Bailleur </label>
                    <input class="form-control"
                    id="bailleur" formControlName="bailleur"
                    placeholder="Bailleur du projet" type="text"
                    >
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="responsableSuivi">Responsable de suivi <span class="text-danger">*</span></label>
                    <div class="input-group">
                        <select class="form-control" id="responsableSuivi" formControlName="responsableSuivi" type="text" [ngClass]="{ 'is-invalid': submit && f.respoSuivi.errors }">                            
                            <option *ngFor="let item of listeResponsable" [ngValue]="item['@id']">{{ item?.utilisateur['nom'] }} {{ item?.utilisateur['prenom'] }}</option>
                        </select>
                        <button tooltip="Ajout de responsables" placement="auto" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" (click)="newResponsableModal.show()" type="button">
                            <span class="btn-wrapper--icon">
                                <i class="ion-md-add"></i>
                            </span>
                        </button>
                    </div>
                    <div *ngIf="submit && f.responsableSuivi.errors" class="invalid-feedback">
                        <div *ngIf="f.responsableSuivi.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="dateLancement">Date de lancement:</label>
                    <div class="input-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                        </div>
                        <input type="text" id="dateLancement" formControlName="dateLancement" class="form-control"
                        [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                        placeholder="Date lancement">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="dateLimite">Date limite de dépot:</label>
                    <div class="input-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                        </div>
                        <input type="text" id="dateLimite" formControlName="dateLimite" class="form-control"
                        [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                        placeholder="Date limite de dépot">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="dateOuverture">Date d'ouverture de plis:</label>
                    <div class="input-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                        </div>
                        <input type="text" id="dateOuverture" formControlName="dateOuverture" class="form-control"
                        [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                        placeholder="Date d'ouverture de plis">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="delaiExecution">Délais d'execution </label>
                    <div class="input-group input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><fa-icon [icon]="['far', 'calendar-alt']" class="mx-auto"></fa-icon></span>
                        </div>
                        <input type="text" id="delaiExecution" formControlName="delaiExecution" class="form-control"
                        [bsConfig]="{ containerClass: 'theme-primary', isAnimated: true }" bsDatepicker
                        placeholder="Date lancement">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="montant">Montant <span class="text-danger">*</span></label>
                    <input class="form-control" id="montant" formControlName="montant" placeholder="Montant"
                        type="number" [ngClass]="{ 'is-invalid': submit && f.montant.errors }"
                    >
                    <div *ngIf="submit && f.montant.errors" class="invalid-feedback">
                        <div *ngIf="f.montant.errors.required">{{required.novide}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="position-relative form-group">
                    <label for="groupement">Groupement </label>
                    <div class="input-group">
                        <select *ngIf="inputGroupe == false" class="form-control" id="groupement" formControlName="groupement">
                            <option value="Groupement 1">Groupement 1</option>
                            <option value="Groupement 2">Groupement 2</option>
                            <option value="Groupement 3">Groupement 3</option>
                            <option value="Groupement 4">Groupement 4</option>
                            <option value="Groupement 5">Groupement 5</option>
                        </select>
                        <input *ngIf="inputGroupe == true" class="form-control" id="groupement" formControlName="groupement" placeholder="Saisir les membres séparés par une virgule" type="text">
                        <button tooltip="Saisir" placement="auto"  (click)="inputGroupe = !inputGroupe" class="btn d-40 p-0 btn-animated-icon btn-animated-icon--primary btn-pill" type="button">
                            <span class="btn-wrapper--icon">
                                <fa-icon [icon]="['far', 'keyboard']"></fa-icon>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="position-relative form-group">
                    <label for="adresseAutorite">Adresse autorité contratante </label>
                    <textarea class="form-control" id="adresse" id="adresseAutorite" formControlName="adresseAutorite"></textarea>
                </div>
            </div>
        </div>
    </div>
    <!-- Fin personne à notifier -->
    <hr class="m-0">
    <div class=" px-4 py-2">
        <div class="row">
            <div class="col-md-6">
                <p class="text-danger mt-2">L'étoile (*) signifie que cet champ est obligatoire.</p>
            </div>
            <div class="col-md-6 text-right">
                <button (click)="onClose()" type="button" class="btn btn-secondary mr-2" data-dismiss="modal">
                  <i class="fas fa-times-circle"></i> Fermer
                </button>
                <button class="btn btn-first mr-2" type="button" (click)="form.reset()">
                    <i class="fas fa-broom  mr-2"></i> Nettoyer
                </button>
                <button  class="btn btn-success" type="submit">
                    <i class="fas fa-save mr-2"></i> Enregistrer
                </button>
            </div>
        </div>
    </div>
</form>

<!-- RESPONSABLE DE SUIVI -->
<div #newResponsableModal="bs-modal"  backdrop="false" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered border-0">
        <div class="modal-content border-0">
            <div class="modal-header modal-dark bg-plum-plate">
                <h6 class="modal-title">Ajouter un responsable de suivi</h6>
                <button type="button" class="close" aria-label="Close" (click)="newResponsableModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="p-3">
                <form [formGroup]="responsable" (ngSubmit)="addResponsable()">
                    <div class="form-row">
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="utilisateur">Nom <span class="text-danger">*</span></label>
                                <select class="form-control" id="utilisateur" formControlName="utilisateur">
                                    <option *ngFor="let item of listeUser" [ngValue]="item['@id']">{{ item.nom }} {{ item.prenom }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="text-right">
                        <button class="btn btn-first m-2" type="button" (click)="responsable.reset()">
                            <i class="fas fa-broom mr-1"></i>
                            Nettoyer
                        </button>
                        <button class="btn btn-success" type="submit">
                            <i class="fas fa-save"></i>
                            Enregistrer
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- MAITRE D'OUVRAGE -->
<div #newMoModal="bs-modal" bsModal backdrop="false" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered border-0">
        <div class="modal-content border-0">
            <div class="modal-header modal-dark bg-plum-plate">
                <h6 class="modal-title">Ajouter un maitre d'ouvrage</h6>
                <button type="button" class="close" aria-label="Close" (click)="newMoModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="p-3">
                <form [formGroup]="maitre" (ngSubmit)="addMaitres()">
                    <div class="form-row">
                        <div class="col-md-12">
                            <div class="position-relative form-group">
                                <label for="utilisateur">Nom <span class="text-danger">*</span></label>
                                <select class="form-control" id="utilisateur" formControlName="utilisateur">
                                    <option *ngFor="let item of listeUser" [ngValue]="item['@id']">{{ item.nom }} {{ item.prenom }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="text-right">
                        <button class="btn btn-first m-2" type="button" (click)="maitre.reset()">
                            <i class="fas fa-broom mr-1"></i>
                            Nettoyer
                        </button>
                        <button class="btn btn-success" type="submit">
                            <i class="fas fa-save"></i>
                            Enregistrer
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- PERSONNE A NOTIFIE -->