<div *ngIf="defaultRemarque.length > 0">
    <select class="form-control mb-3" style="width: 17em;">
        <option selected disabled>Filtre des remarques</option>
        <option value="Toute les remarques">Toute les remarques</option>
        <option value="Remarque par intervenant">Remarque par intervenant</option>
        <option value="Remarque par lot de travaux">Remarque par lot de travaux</option>
        <option value="Remarque positionner sur plan">Remarque positionner sur plan</option>
    </select>

    <div class="list-group list-group-flush">
        <li class="list-group-item list-group-item-action align-box-row" *ngFor="let item of defaultRemarque">
            <i class="fas fa-file-signature font-size-lg mr-3"></i>
            <div>{{item.libelle}}</div>
            <div class="ml-auto text-right">
                <button class="btn p-0 d-40 rounded-circle btn-primary mr-1" type="button" tooltip="Détail" [routerLink]="['/pages/remarque/detail/1']">
                    <i class="ion-md-eye"></i>
                </button>
                <button class="btn p-0 d-40 rounded-circle btn-info mr-1" type="button" tooltip="Modifier" *ngIf="item.etat != 'valider'">
                    <i class="fas fa-pen"></i>
                </button>
                <button class="btn p-0 d-40 rounded-circle btn-success mr-1" type="button" tooltip="Clôturer" *ngIf="item.etat != 'valider'">
                    <i class="fas fa-sign-out-alt"></i>
                </button>
                <button class="btn p-0 d-40 rounded-circle btn-danger" type="button" tooltip="Supprimer">
                    <i class="ion-md-trash"></i>
                </button>
            </div>
        </li>
    </div>
</div>
<div *ngIf="defaultRemarque.length == 0">
    <div class="hero-wrapper bg-composed-wrapper bg-plum-plate">
        <div class="flex-grow-1 w-100 d-flex align-items-center pb-5">
            <div class="bg-composed-wrapper--image" style="background-image: url('assets/images/hero-bg/hero-1.jpg');"></div>
            <div class="bg-composed-wrapper--bg bg-second opacity-5"></div>
            <div class="bg-composed-wrapper--content">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-xl-7">
                            <div class="text-white mt-3">
                                <h1 class="display-3 mb-3 font-weight-bold">Aucune remarque disponible.</h1>
                                <p class="font-size-lg text-white-50">
                                    Cliquer sur le boutton 'Ajouter' pour en ajouter ou cliquer sur le boutton 'Télécharger un model'. <br>
                                    Si vous procéder par l'option de téléchargement, cliquer sur l'icon " <i class="fas fa-pen"></i> " pour modifier à votre guise.
                                </p>
                                <div class="divider border-2 my-5 border-light opacity-2 rounded-circle w-25"></div>
                                <div>
                                    <a [routerLink]="" class="btn btn-lg btn-warning">
                                        <span class="btn-wrapper--icon"><i class="fas fa-download mr-1"></i></span>
                                        <span class="btn-wrapper--label">Télécharger un model</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 d-none d-xl-flex align-items-center">
                            <div class="card card-box demo-img-wrapper-discrete w-50 mx-auto h-50 shadow-none  text-center p-5">
                                <i class="fas fa-file-signature mt-2"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>